export default {
  list: {
    total_item: "バナー {{value}} 件",
    banner_title: "バナータイトル",
    banner_image: "画像",
    banner_date: "期間",
    create_banner: "バナーを作成",
    at_least_three: "バナーは3つ以上登録してください。",
  },
  form: {
    title: "タイトル",
    files: "画像 (縦 250px ✕ 横 500px)",
    upload: "ファイル選択",
    preview: "プレビュー画像",
    period: "掲載期間",
    delete_notice: "削除したバナーは復元出来ませんが、本当に削除しますか？",
    address_url: "URL",
    open_new_tab: "別タブとして開く",
  },
};