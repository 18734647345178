import React from "react";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CustomNoDataOverLay from "../../../../components/CustomNoDataOverLay";
import { fomatPrice } from "../../../../constant";

function EnhancedTableHead(props) {
  const { headCells } = props;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            className="font-bold text-sm"
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function TablePayment(props) {
  const { t } = useTranslation();

  const {
    headCells,
    rows,
    perPage,
    setPerPage,
    page,
    setPage,
    total,
    updatePay,
    handleShowpage,
  } = props;
  const [selected, setSelected] = React.useState([]);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleRowsPerPageChange = (event) => {
    setPerPage(event.target.value);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const filterStatus = (status) => {
    return status === 1;
  };

  return (
    <Paper sx={{ width: "100%" }} className="border border-slate-300">
      <TableContainer className="h-[480px]">
        <Table
          sx={{ minWidth: 750 }}
          stickyHeader
          aria-label="sticky table"
          size="medium"
        >
          <EnhancedTableHead headCells={headCells} />
          <TableBody>
            {rows.map((row, index) => {
              const isItemSelected = isSelected(row.name);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  hover
                  onClick={(event) => handleClick(event, row.name)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  key={row.user_id}
                  selected={isItemSelected}
                >
                  <TableCell
                    align="left"
                    component="th"
                    className="hover:cursor-pointer hover:underline font-bold text-xs"
                    id={labelId}
                    scope="row"
                    onClick={() => handleShowpage(row.user_id)}
                  >
                    {row.nickname}
                  </TableCell>
                  <TableCell align="center" className="font-bold text-xs">
                    {row.payment_status.some(filterStatus) === true &&
                      t("admin.payment.listPaymentInfluence.yes")}
                    {row.payment_status.some(filterStatus) === false &&
                      t("admin.payment.listPaymentInfluence.nothing")}
                  </TableCell>
                  <TableCell align="right" className="font-bold text-xs">
                    <p>
                      {t("admin.pay.list.price")}
                      {row?.fee ? fomatPrice(row?.fee) : 0}
                    </p>
                    <p>
                      ({t("admin.pay.consumptionTax")}
                      {row?.tax ? fomatPrice(row?.tax) : 0})
                    </p>
                  </TableCell>
                  <TableCell align="center">
                    {row.payment_status.some(filterStatus) === true && (
                      <button
                        type="button"
                        className="hover:underline focus:outline-none text-white bg-rose-500 hover:bg-rose-600 focus:ring-rose-300 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 "
                        onClick={() =>
                          updatePay(
                            row.user_id,
                            row.account?.paypal.account,
                            row?.nickname
                          )
                        }
                      >
                        {t("admin.payment.listPaymentInfluence.updatePaid")}
                      </button>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
            {rows.length === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  <CustomNoDataOverLay />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        classes={{ fontWeight: 700 }}
        count={rows.length === 0 ? 0 : Number(total)}
        rowsPerPage={Number(perPage)}
        page={Number(page) - 1}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        labelRowsPerPage=""
        className="border-t border-slate-300"
      />
    </Paper>
  );
}
