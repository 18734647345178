import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import i18n from "app/langs";
import useMediaQuery from "@mui/material/useMediaQuery";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { updatePersonalInfoAction } from "app/utils/page/personal_info";
import EditableForm from "app/components/EditableForm";
import UserContext from "./UserContext";

const EDIT_BIRTHDAY = "show_edit_birthday";

function BirthdaySettingField(props) {
  const { showEdit, setShowEdit, setOpenSuccess, setOpenError, setMessage } =
    props;
  const { t } = useTranslation();
  const { userInfo, setUserInfo } = useContext(UserContext);
  const locale = i18n.resolvedLanguage;
  const isPC = useMediaQuery("(min-width:768px)");

  const initialValues = {
    birthday: null,
  };

  const updateUserInfo = (values, context) => {
    const { setValues } = context;
    if (
      (dayjs(values?.birthday).isValid() &&
        !dayjs(values?.birthday).isAfter(dayjs())) ||
      !values?.birthday
    ) {
      updatePersonalInfoAction({
        userInfo,
        values,
        setUserInfo,
        setValues,
        setOpenSuccess,
        setOpenError,
        setShowEdit,
      });
    } else {
      setOpenError(true);
      setMessage(t("operator.accountSetting.personal_info.invalid_birthday"));
      setValues(userInfo);
    }
  };

  const renderForm = ({ values, setFieldValue }) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
        {isPC ? (
          <DesktopDatePicker
            inputFormat="YYYY/MM/DD"
            value={values?.birthday}
            onChange={(value) => setFieldValue("birthday", value)}
            className="date-picker"
            renderInput={(params) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <TextField {...params} />
            )}
            componentsProps={{
              actionBar: {
                actions: ["today"],
                className: "!justify-center",
              },
            }}
          />
        ) : (
          <MobileDatePicker
            inputFormat="YYYY/MM/DD"
            value={values?.birthday}
            onChange={(value) => setFieldValue("birthday", value)}
            className="date-picker"
            renderInput={(params) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <TextField {...params} />
            )}
            componentsProps={{
              actionBar: {
                actions: ["today"],
                className: "!justify-center",
              },
            }}
          />
        )}
      </LocalizationProvider>
    );
  };

  return (
    <EditableForm
      editForm={renderForm}
      onSubmit={updateUserInfo}
      initialValues={initialValues}
      label={t("operator.accountSetting.personal_info.dob_label")}
      editField={EDIT_BIRTHDAY}
      formValues={userInfo}
      displayValue={userInfo?.birthday}
      showEdit={showEdit}
      setShowEdit={setShowEdit}
    />
  );
}

export default BirthdaySettingField;
