import React from "react";
import AccountSettingsPersonalInfo from "../pages/AccountSettings/PersonalInfo";
import AccountSettings from "../pages/AccountSettings";
import Profile from "../pages/Profile";
import GlobalSetting from "../pages/AccountSettings/GlobalSetting";
import AccountSettingsLoginSecurity from "../pages/AccountSettings/LoginSecurity";
import Home from "../pages/Home/Home";
import Path from "./Path";
import Pay from "../pages/Pay/index";
import DetailList from "../pages/Pay/detail/DetailList";
import Detail from "../pages/Pay/detail";
import InfluencerDetail from "../pages/Influencer/detail";
import InfluencerList from "../pages/Influencer/InfluencerList";
import BusinessPerson from "../pages/BusinessPerson";
import BusinessPersonForm from "../pages/BusinessPerson/Form";
import BusinessOperatorDetail from "../pages/BusinessOperatorDetail";
import PayMent from "../pages/payment";
import PaymentInfluentceDetail from "../pages/payment/paymentInfluence/detail";
import NotificationList from "../pages/Notification";
import NotificationDetail from "../pages/Notification/detail";
import NotificationCreate from "../pages/Notification/create";
import BannerList from "../pages/Banner";
import BannerForm from '../pages/Banner/Form';
import AccountList from '../pages/Account';
import AccountForm from "../pages/Account/Form";
import ProjectList from '../pages/Projects'
import ProjectSocial from '../pages/Projects/social';

export default [
  { path: Path.dasboard, element: <Home /> },
  { path: Path.home, element: <Home /> },
  { path: Path.pay_list, element: <Pay /> },
  { path: Path.pay_detail_list, element: <DetailList /> },
  { path: Path.pay_detail, element: <Detail /> },
  { path: Path.influencer_list, element: <InfluencerList /> },
  { path: Path.influencer_detail, element: <InfluencerDetail /> },
  { path: Path.business_person, element: <BusinessPerson /> },
  { path: Path.business_person_edit(), element: <BusinessPersonForm /> },
  { path: Path.business_person_create, element: <BusinessPersonForm /> },
  { path: Path.management_information, element: <BusinessOperatorDetail /> },
  { path: Path.payment, element: <PayMent /> },
  {
    path: Path.payment_influentce_detail,
    element: <PaymentInfluentceDetail />,
  },
  { path: Path.notification, element: <NotificationList /> },
  { path: Path.notification_create, element: <NotificationCreate /> },
  { path: Path.notification_detail, element: <NotificationDetail /> },
  { path: Path.account_settings, element: <AccountSettings /> },
  {
    path: Path.account_settings_personal_info,
    element: <AccountSettingsPersonalInfo />,
  },
  {
    path: Path.account_settings_login_security,
    element: <AccountSettingsLoginSecurity />,
  },
  {
    path: Path.account_settings_global_setting,
    element: <GlobalSetting />,
  },
  {
    path: Path.profile,
    element: <Profile />,
  },
  {
    path: Path.banner,
    element: <BannerList />,
  },
  {
    path: Path.banner_create,
    element: <BannerForm />,
  },
  {
    path: Path.banner_detail(),
    element: <BannerForm />,
  },
  {
    path: Path.account,
    element: <AccountList />,
  },
  {
    path: Path.account_create,
    element: <AccountForm />,
  },
  {
    path: Path.account_detail(),
    element: <AccountForm />,
  },
  {
    path: Path.ad_project,
    element: <ProjectList />,
  },
  {
    path: Path.ad_project_social(),
    element: <ProjectSocial />,
  },
];
