export default {
  list: {
    find_influencer: "搜尋網紅",
    influcener_name: "顯示名稱",
    count: "網紅 {{value}}人",
    avatar: "個人資料照片",
    instagram: "Instagram追蹤人數",
    tiktok: "Tiktok追蹤人數",
    facebook: "Facebook追蹤人數",
    youtube: "Youtube追蹤人數",
    self_introduction: "自我介紹（專業領域介紹）",
    rating: "評價",
  },
  detail: {
    influencer_information: "網紅資訊",
    number_of_applications: "批准件數",
    number_of_approvals: "批准件數",
    approval_history: "批准紀錄",
    comment: "自我介紹（專業領域介紹）",
    display_name: "顯示名稱",
    full_name: "全名",
    birthday: "出生日期",
    phone_number: "電話號碼",
  },
};

// admin.influencer.detail
