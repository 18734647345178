export default {
  total_contract: "総契約事業者数",
  total_influncer: "インフルエンサー数",
  matching_commission: "マッチング手数料売上",
  number_business_change: "マッチング数",
  membership_change: "無償提供の募集案件作成数",
  reservation_quantity: "固定報酬の募集案件作成数",
  Enter_wrong_infomation: "エクスポート エラー",
  fee_consumption: "うち消費税",
  select_month: "開始/終了月を入力してください。",
  select_end_month: "終了月を入力してください。",
};
