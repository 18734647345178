export default {
  list: {
    total_item: "{{value}} Banners",
    banner_title: "Banner Title",
    banner_image: "Image",
    banner_date: "Duration",
    create_banner: "Create Banner",
    at_least_three: "Please register three or more banners.",
  },
  form: {
    title: "Title",
    files: "Image (H 250px x W 500px)",
    upload: "Select File",
    preview: "Preview image",
    period: "Post Period",
    delete_notice: "Deleted banners cannot be restored, sure to delete?",
    address_url: "URL",
    open_new_tab: "Open in New Tab",
  },
};
