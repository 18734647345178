import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Path from "../../../route/Path";
import { logout } from "../../../../utils";

function Step3({ withdrawMessage, success }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleRedirect = (e) => {
    if (success) {
      navigate("/");
      logout(e);
    } else {
      navigate(Path.home);
    }
  };

  return (
    <>
      <h1 className="text-[32px] leading-9 text-REGULAR_TEXT_COLOR font-extrabold mb-10 text-center">
        {t("operator.accountSetting.widthdraw.step_3_title")}
      </h1>

      <div className="mb-10">
        <div className="text-center text-[20px]">{withdrawMessage}</div>
      </div>

      <div className="w-full flex justify-center">
        <button
          type="button"
          onClick={(e) => handleRedirect(e)}
          className="hover:underline font-bold"
        >
          {t("operator.accountSetting.widthdraw.go_to_top")}
        </button>
      </div>
    </>
  );
}

export default Step3;
