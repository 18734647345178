export default {
  list: {
    find_influencer: "Search for Influencers",
    influcener_name: "Display Name",
    count: "{{value}} Influencers",
    avatar: "Profile Image",
    instagram: "Instagram Followers",
    tiktok: "TikTok Followers",
    facebook: "Facebook Followers",
    youtube: "YouTube Channel Subscribers",
    self_introduction: "Self-Introduction (Area of Expertise Comment)",
    rating: "Rating",
  },
  detail: {
    influencer_information: "Influencer Information",
    number_of_applications: "Number of Applications",
    number_of_approvals: "Number of Approvals",
    approval_history: "Approval History",
    comment: "Self-Introduction (Area of Expertise Comment)",
    display_name: "Display Name",
    full_name: "Full Name",
    birthday: "Date of Birth",
    phone_number: "Phone Number",
  },
};

// admin.influencer.detail
