import React from "react";
import ictlPath from "app/ictl/route/Path";
import PersonalInfoForm from "app/components/personal-info/PersonalInfoForm";
import LayoutDefault from 'app/ictl/layouts/LayoutDefault';

function PersonalInfo() {
  return (
    <LayoutDefault showFooter showHeader>
      <PersonalInfoForm path={ictlPath.account_settings} />
    </LayoutDefault>
  );
}

export default PersonalInfo;
