import { useState, useEffect, useCallback } from 'react';
import {  useNavigate, useSearchParams } from "react-router-dom";
// import debounce from 'lodash/debounce';
import { removeFieldEmptyValue } from "app/utils";
import CommonService from "../../../../services/api/CommonService";
import { ICTL_API_ENDPOINT } from "../../../../services/constant";

const useCoreComponentList = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const searchUrl = searchParams.get("search");
  const pageUrl = searchParams.get("page");
  const perPageUrl = searchParams.get("per_page");
  const serviceIdUrl = searchParams.get("service_id");
  const [items, setItems] = useState([]);
  const [search, setSearch] = useState(searchUrl || "");
  // eslint-disable-next-line no-unused-vars
  const [total, setTotal] = useState();
  const [page, setPage] = useState(pageUrl || 1);
  const [perPage, setPerPage] = useState(perPageUrl || 20);

  const [serviceId, setServiceId] = useState(
    serviceIdUrl ? serviceIdUrl.split(",").map(String) : []
  );
  const [pricePaid, setPricePaid] = useState({});
  const [listOperator, setListOperator] = useState([]);

  const params = {
    service_id: serviceId,
    page,
    search,
    per_page: perPage,
  };
  const fetchData = useCallback(async () => {
    await CommonService.getModelList(ICTL_API_ENDPOINT.LIST_WAIT_PAYMENT, {
      ...removeFieldEmptyValue(params),
    })
      .then((res) => {
        const resutl = res.data.data;
        setItems(resutl.row);
        setTotal(resutl.total);
        setPricePaid(resutl.result);
        // setloading(false);
        // setLoadingPage(false)
      })
      .catch(() => {
        // console.log(err);
      })
      .finally(() => {
        // console.log(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, search, serviceId]);

  useEffect(() => {
    const options = {
      page,
      per_page: perPage,
      search,
      service_id: serviceId?.length ? serviceId : '',
    };
    const url = new URLSearchParams(removeFieldEmptyValue(options));
    navigate(`?${url}`, { replace: true });
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage, page, serviceId, search]);

  const getListOperator = () => {
    CommonService.getModelList(ICTL_API_ENDPOINT.GET_MEMBER_BUSINESS_OPERATOR)
      .then((res) => {
        setListOperator(res.data.data);
      })
      .catch(() => {
        // console.log(err);
      })
      .finally(() => {
        // console.log(false);
      });
  };

  useEffect(() => {
    getListOperator();
  }, []);

  return [
    items,
    setSearch,
    setPage,
    setPerPage,
    setServiceId,
    total,
    search,
    perPage,
    page,
    pricePaid,
    // serviceId,
    listOperator,
    serviceId,
  ];
};

export default useCoreComponentList;
