import React from "react";
import cx from "classnames";

export const BUTTON_STYLE_TYPE = {
    GHOST: "ghost",
    PRIMARY: "primary",
    ERROR: "error"
};

function Button({
    prefixIcon,
    content,
    suffixIcon,
    className,
    onClick,
    styleType = BUTTON_STYLE_TYPE.GHOST,
    children,
    disabled }) {

    const classList = {
        [BUTTON_STYLE_TYPE.GHOST]: 'px-[16px] py-[4px] font-semibold border rounded-[8px] justify-center items-center flex gap-[8px]',
        [BUTTON_STYLE_TYPE.PRIMARY]: 'bg-dark text-[18px] font-semibold text-white py-[12px] px-[24px] rounded-[8px]',
        [BUTTON_STYLE_TYPE.ERROR]: 'bg-red-700 text-[18px] font-semibold text-white py-[12px] px-[24px] rounded-[8px]',
    };

    return (
        <button
            disabled={disabled}
            type="button"
            onClick={onClick}
            className={cx(
                {
                    [`${classList[styleType]}`]: !disabled,
                    'bg-[#DDDDDD] text-[18px] font-semibold text-white py-[12px] px-[24px] rounded-[8px]': disabled
                },
                className)}
        >
            {prefixIcon}
            {children}
            {content}
            {suffixIcon}
        </button>
    );
}

export default Button;