export default {
  header: {
    last_5_message: "您有 5 則最新消息",
    show_more_message: "查看更多信息",
  },
  message: {
    uploaded: "上傳成功",
    upload_failed: "上傳失敗",
    request_success: "我已請求翻譯。",
    request_fail: "翻譯請求失敗。請稍後再試。",
    cannot_send_to_private:
      "訊息無法傳送到處於「私人」或「已刪除」狀態的項目。",
    cannot_send_to_completed: "無法將訊息傳送到已完成的對話。",
  },
};
