import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { setAuthStateDefault } from "app/redux/authReducer";
import { setProfileEmpty } from "app/redux/profileReducer";
import { Group, Roles } from "app/constant";
import { filterCaching } from "../utils";

export default function RedirectToRegisterFacility() {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  /* eslint prefer-destructuring: ["error", {AssignmentExpression: {array: true}}] */
  const roles = user?.group_roles.roles[0];
  const group = user?.group_roles.group;

  if (user && group === Group.FACILITY && roles === Roles.REPRESENT) {
    return <Navigate to="/operator" state={{ from: location }} replace />;
  }

  filterCaching()?.clear();
  dispatch(setAuthStateDefault());
  dispatch(setProfileEmpty(null));

  return (
    <Navigate to="/register-facility" state={{ from: location }} replace />
  );
}
