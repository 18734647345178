import React, { useState } from "react";
// import { useSearchParams } from "react-router-dom";
import SignUpSocial from "./SignInSocial";
import SignUpGmail from "./SignInGmail";

function Auth() {
  const [next, setNext] = useState(false);
  if (next === false) {
    return (
      <SignUpSocial setNext={setNext} />
    )
  }

  return (
    <SignUpGmail setNext={setNext} />

  );
}

export default Auth;
