import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: 1,
};

const slice = createSlice({
  name: "headerPayment",
  initialState,
  reducers: {
    setHeaderPayment: (state, action) => {
      state.show = action.payload;
    }
  },
});

export const { setHeaderPayment } = slice.actions;

export default slice.reducer;
