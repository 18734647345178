import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { randomInt } from 'app/utils';
import Upload from "./upload";
import { FILE_ERRORS_TEXT } from "../../../../constant";

function UploadFile({
  imageList,
  setImageList,
  onUpdateImgList,
  onDeleteImg,
  isMaxItem,
  min = 0,
  handleFileUpload,
  uploadError,
  uploadingListImage,
  setUploadingListImage,
  onUpdateAllInfo,
}) {
  const { t } = useTranslation();
  const [isRearrange, setIsRearrange] = useState();
  const [isDragOver, setIsDragOver] = useState(false);

  const isDisableDelete = imageList?.length <= min;

  const listImg = useMemo(() => {
    let rearrangeList = [];
    const listWithoutRearange = imageList?.filter(({ updating }) => !updating);
    if (isRearrange?.itemOriginId && isRearrange?.itemTargetIndex) {
      rearrangeList = listWithoutRearange?.filter((item) => {
        return item?.id !== isRearrange?.itemOriginId;
      });
      rearrangeList.splice((isRearrange?.itemTargetIndex || 0) - 1, 0, {
        id:randomInt(5),
        blank: true,
      });
      return rearrangeList;
    }

    return listWithoutRearange;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageList, JSON.stringify(isRearrange)]);

  const onDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isRearrange) {
      setIsDragOver(false);
      // handle file to preview
      console.log("upload");
      handleFileUpload(e);
    }
  };

  const onDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDragOver && !isRearrange) {
      setIsDragOver(true);
    }
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isDragOver) {
      setIsDragOver(false);
    }
  };

  const callbackUploadS3Success = (data) => {
    onUpdateImgList({
      data: { ...data, updating: true },
      callbackSuccess: () => {
        setUploadingListImage((prev) =>
          prev.filter((item) => item?.id !== data?.id)
        );
      },
      callbackError: () => {
        setUploadingListImage((prev) =>
          prev.filter((item) => item?.id !== data?.id)
        );
      },
    });
  };

  const renderErrorMsg = (
    <div>
      {uploadError && (
        <div className="mb-[8px] px-[12px] py-[4px] flex items-center border rounded-[8px] text-[#c13515]">
          <i className="fa-solid fa-circle-exclamation  w-[16px] h-[16px] mr-[12px]" />
          <p className="text-[12px]">{FILE_ERRORS_TEXT(t)[uploadError]}</p>
        </div>
      )}
    </div>
  );

  const onDropRearrange = () => {
    const prevImageList = imageList;
    const originItem = imageList?.filter(
      (item) => isRearrange?.itemOriginId === item?.id
    );
    const rearrangeList = imageList?.filter(
      ({ updating, id: itemId }) =>
        !updating && isRearrange?.itemOriginId !== itemId
    );
    rearrangeList?.splice(
      (isRearrange?.itemTargetIndex || 0) - 1,
      0,
      originItem[0]
    );
    setIsRearrange();
    setImageList(rearrangeList);
    if (onUpdateAllInfo) {
      onUpdateAllInfo({
        newListImg: rearrangeList,
        callbackSuccess: () => {},
        callbackError: () => {
          setImageList(prevImageList);
        },
      });
    }
  };

  return (
    <>
      {renderErrorMsg}
      <div
        onDragOver={onDragOver}
        onDrop={onDrop}
        className="pt-6 pb-[16px] grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 gap-[16px] relative"
      >
        {listImg?.map((item, index) => {
          const { url, id, blank } = item || {};
          return (
            <Upload
              key={id}
              id={id}
              arrangeIndex={(index || 0) + 1}
              data={url}
              blank={blank}
              onDelete={onDeleteImg}
              isRearrange={isRearrange}
              setIsRearrange={setIsRearrange}
              disableDetele={isDisableDelete}
              uploaded
              onDropRearrange={onDropRearrange}
            />
          );
        })}
        {uploadingListImage?.map((item) => {
          const { file, id } = item || {};
          return (
            <Upload
              key={id}
              id={id}
              data={file}
              callbackUploadSuccess={callbackUploadS3Success}
              callbackUploadError={() => {}}
              disableDetele={isDisableDelete}
            />
          );
        })}
        <label htmlFor="facility-img-upload" className="relative pt-[60%]">
          <div className="absolute top-0 left-0 w-full h-full">
            <div
              className={cx(
                "cursor-pointer text-center font-semibold border-dark border border-dashed hover:border-2 hover:border-solid w-full h-full flex justify-center items-center",
                { hidden: isMaxItem }
              )}
            >
              <div>
                <i className="fa-regular fa-image text-[30px]" />
                <p>{t("common.action.add_more")}</p>
              </div>
            </div>
          </div>
          <input
            onChange={handleFileUpload}
            value=""
            type="file"
            name="file"
            multiple
            id="facility-img-upload"
            accept="image/png, image/jpg, image/jpeg, video/mp4"
            className="hidden"
          />
        </label>
        <div
          className={cx(
            "bg-white bg-opacity-85 border-[3px] absolute top-0 left-0 border-dark w-full h-full z-10 flex items-center justify-center",
            {
              visible: isDragOver,
              invisible: !isDragOver,
            }
          )}
        >
          <div className="text-[30px] font-semibold text-center">
            <i className="fa-regular fa-image" />
            <p>{t("operator.facility.detail.drop_and_upload")}</p>
          </div>
        </div>
        <div
          onDragLeave={onDragLeave}
          onDragOver={(e) => {
            e.preventDefault();
          }}
          className={cx(
            "absolute top-0 left-0 w-full h-full z-20 bg-transparent",
            {
              visible: isDragOver,
              invisible: !isDragOver,
            }
          )}
        />
      </div>
    </>
  );
}

export default UploadFile;
