import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import MainContent from "app/components/MainContent";
import { EResponseStatus, Roles } from "app/constant";
import SuccessDialog from "app/components/SuccessDialog";
import ErrorDialog from "app/components/ErrorDialog";
import UserService from "app/services/api/UserService";
import { formatGetPersonalInfoData } from "app/utils/page/personal_info";
import NameSettingField from "app/components/personal-info/NameSettingField";
import { UserProvider } from "app/components/personal-info/UserContext";
import GenderSettingField from "app/components/personal-info/GenderSettingField";
import BirthdaySettingField from "./BirthdaySettingField";
import PhoneNumberSettingField from "./PhoneNumberSettingField";
import GovernmentIdSettingField from "./GovernmentIdSettingField";
import AddressSettingField from "./AddressSettingField";
import { LayoutContext } from "../../utils";

function PersonalInfoForm(props) {
  const { path, checkRole } = props;
  const [showEdit, setShowEdit] = useState("");
  const role = useSelector((state) => state.auth?.user?.group_roles?.roles[0]);
  const profile = useSelector((state) => state.profile);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useContext(LayoutContext);

  const openErrorDialog = (mess) => {
    setErrorMessage(mess);
    setOpenError(true);
  };

  const handleErrorClose = () => {
    if (loadError) {
      navigate(
        checkRole && role === Roles.MEMBER ? "/staff/account-settings" : path
      );
    } else {
      setOpenError(false);
      setErrorMessage("");
    }
  };

  const getUserPersonalInfo = useCallback(() => {
    UserService.getUserPersonalInfo()
      .then((response) => {
        if (response.status === EResponseStatus.SUCCESS) {
          const { data } = response.data;
          setUserInfo(formatGetPersonalInfoData(data));
        }
      })
      .catch(() => {
        openErrorDialog(t("common.message.cannot_display_data"));
        setLoadError(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUserPersonalInfo();
  }, [getUserPersonalInfo]);

  return (
    <>
      <MainContent>
        <div className="mt-20">
          <div className="w-full px-4 max-w-[568px] md:ml-12 lg:ml-40">
            <div className="w-full pt-5 pb-10">
              <div className="flex md:hidden items-center relative h-9">
                <Link
                  to={
                    checkRole && role === Roles.MEMBER
                      ? "/staff/account-settings"
                      : path
                  }
                  className="w-7 h-7 flex items-center justify-center absolute left-0 top-[50%] translate-y-[-50%]"
                >
                  <i className="fa-solid fa-chevron-left" />
                </Link>
              </div>
              <ol className="hidden md:block text-sm leading-[18px] text-REGULAR_TEXT_COLOR font-semibold">
                <li className="inline-block hover:underline">
                  <Link
                    to={
                      checkRole && role === Roles.MEMBER
                        ? "/staff/account-settings"
                        : path
                    }
                  >
                    <span>{t("common_header.account_setting")}</span>
                  </Link>
                </li>
                <li className="inline-block">
                  <div className="inline-block mx-4">
                    <svg
                      fill="#767676"
                      viewBox="0 0 18 18"
                      style={{ height: 10, width: 10 }}
                    >
                      <path
                        fillRule="#767676"
                        d="M4.29 1.71A1 1 0 115.71.3l8 8a1 1 0 010 1.41l-8 8a1 1 0 11-1.42-1.41l7.29-7.29z"
                      />
                    </svg>
                  </div>
                  <span>
                    {t("operator.accountSetting.personal_info.personal_info")}
                  </span>
                </li>
              </ol>

              <h1 className="text-[32px] leading-9 text-REGULAR_TEXT_COLOR font-extrabold mt-3">
                {t("operator.accountSetting.personal_info.personal_info")}
              </h1>
            </div>

            <div className="w-full h-auto flex pb-10">
              <div className="w-full">
                <div className="w-full h-auto flex flex-col">
                  <UserProvider
                    value={useMemo(
                      () => ({ userInfo, setUserInfo, profile }),
                      [userInfo, setUserInfo, profile]
                    )}
                  >
                    <NameSettingField
                      showEdit={showEdit}
                      setShowEdit={setShowEdit}
                      setOpenSuccess={(value) => setOpenSuccess(value)}
                      setOpenError={(value) => setOpenError(value)}
                    />
                    <GenderSettingField
                      showEdit={showEdit}
                      setShowEdit={setShowEdit}
                      setOpenSuccess={(value) => setOpenSuccess(value)}
                      setOpenError={(value) => setOpenError(value)}
                      setMessage={(value) => setErrorMessage(value)}
                    />
                    <BirthdaySettingField
                      showEdit={showEdit}
                      setShowEdit={setShowEdit}
                      setOpenSuccess={(value) => setOpenSuccess(value)}
                      setOpenError={(value) => setOpenError(value)}
                      setMessage={(value) => setErrorMessage(value)}
                    />
                    <PhoneNumberSettingField
                      showEdit={showEdit}
                      setShowEdit={setShowEdit}
                      setOpenSuccess={(value) => setOpenSuccess(value)}
                      setOpenError={(value) => setOpenError(value)}
                    />
                    <GovernmentIdSettingField
                      showEdit={showEdit}
                      setShowEdit={setShowEdit}
                      setOpenSuccess={(value) => setOpenSuccess(value)}
                      setOpenError={(value) => setOpenError(value)}
                    />
                    <AddressSettingField
                      showEdit={showEdit}
                      setShowEdit={setShowEdit}
                      setOpenSuccess={(value) => setOpenSuccess(value)}
                      setOpenError={(value) => setOpenError(value)}
                    />
                  </UserProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainContent>
      <SuccessDialog
        message={t("common.message.update_success")}
        open={openSuccess}
        onClose={() => setOpenSuccess(false)}
        onOK={() => setOpenSuccess(false)}
      />
      <ErrorDialog
        message={errorMessage || t("common.message.update_fail")}
        open={openError}
        onClose={handleErrorClose}
        onOK={handleErrorClose}
      />
    </>
  );
}

export default PersonalInfoForm;
