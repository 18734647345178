import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
// import { useNavigate } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import AuthService from "app/services/api/AuthService";
import { useLocation, useNavigate } from "react-router-dom";
import jwt from "jwt-decode";
import { setAuthUser, setAuthToken } from "app/redux/authReducer";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import store from "../../../../redux/store";
import LayoutPageColor from "../../../../components/LayoutPageColor";
import { passwordRegex, emailRegex } from "../../../../constant";
import Popup from "../../../components/Popup";
import { setEmailForChangePass } from "../../../../redux/emailForChangePassReducer";

export default function ChangePassword() {
  const { t } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("token") ? jwt(params.get("token"))?.email : null;
  const emailForChangePassStore = token || store.getState()?.emailForChangePass;
  const [isEmail, setIsEmail] = useState(emailForChangePassStore !== "");
  console.log(emailForChangePassStore);
  const [emailForChange, setEmailForChange] = useState(
    emailForChangePassStore || ""
  );
  const [errorSV, setErrorSV] = useState("");
  return (
    <div className="flex flex-wrap sm:flex-row-reverse items-stretch h-screen overflow-hidden ">
      <div className="h-[60%] md:h-full w-full md:w-1/2 xl:w-1/2 bg-cover flex">
        {isEmail === true && (
          <FormChangePassword
            className="my-8 md:my-auto shadow-lg"
            t={t}
            emailCurrent={emailForChange}
            errorSV={errorSV}
            setErrorSV={setErrorSV}
          />
        )}
        {isEmail === false && (
          <FormEmail
            className="my-8 md:my-auto shadow-lg"
            setIsEmail={setIsEmail}
            t={t}
            setEmailForChange={setEmailForChange}
            errorSV={errorSV}
            setErrorSV={setErrorSV}
          />
        )}
      </div>
      <LayoutPageColor text={t("auth.change_password")} />
    </div>
  );
}

function FormEmail(props) {
  const { setIsEmail, t, setEmailForChange, errorSV, setErrorSV } = props;
  const [disableButton, setDisableButton] = useState(true);
  const [openSentPassToEmailSucess, setOpenSentPassToEmailSucess] =
    useState(false);

  const validationSchema = Yup.object().shape({
    login_id: Yup.string()
      .required(t("validate_message.required", { path: t("common.email") }))
      .trim(t("validate_message.required", { path: t("common.email") }))
      .max(
        64,
        t("validate_message.max_length", {
          path: t("common.email"),
          max: 64,
        })
      )
      .matches(
        emailRegex,
        t("validate_message.wrong_format", {
          path: t("common.email"),
        })
      ),
  });

  const sentPassToEmail = (data) => {
    AuthService.resetPassword(data)
      .then(() => {
        setOpenSentPassToEmailSucess(true);
      })
      .catch(() => {
        setErrorSV(t("auth.email_not_exit"));
      });
  };

  const handleClosePopupSentSuccess = () => {
    setOpenSentPassToEmailSucess(false);
    setIsEmail(true);
  };
  const onSubmit = (values) => {
    // const loginId = { login_id: values.login_id }
    AuthService.checkEmail(values)
      .then((res) => {
        const data = res?.data.data;
        if (data === false) {
          // setIsEmail(true)
          setEmailForChange(values.login_id);
          sentPassToEmail(values);
        }
        if (data === true) {
          setErrorSV(t("auth.email_not_exit"));
        }
      })
      .catch(() => {
        setErrorSV(t("auth.login.Email_format"));
      });
  };
  const { handleSubmit, errors, values, setFieldValue, touched } = useFormik({
    initialValues: {
      login_id: "",
      social_id_provider: "email_smv",
    },
    onSubmit,
    validationSchema,
  });

  useEffect(() => {
    if (!values.login_id?.trim()) {
      return setDisableButton(true);
    }
    return setDisableButton(false);
  }, [disableButton, values]);

  return (
    <form
      onSubmit={handleSubmit}
      className="m-auto w-2/3 bg-white rounded p-3 text-center"
    >
      <List component="nav" aria-label="mailbox folders" className="w-full">
        <ListItem>
          <div className="w-full">
            {errorSV ? <p className="text-rose-500">{errorSV}</p> : null}
          </div>
        </ListItem>
        <ListItem>
          <div className="w-full">
            <input
              onChange={(e) => {
                setErrorSV("");
                setFieldValue("login_id", e.target.value);
              }}
              type="text"
              value={values.login_id}
              placeholder={t("common.email")}
              className="mb-5 h-10 sm:h-14 bg-gray-50 border-2 border-slate-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none  w-full p-2.5 "
            />
            {errors.login_id && touched.login_id ? (
              <p className="text-rose-500">{errors.login_id}</p>
            ) : null}
          </div>
        </ListItem>
        <button
          className="font-bold border rounded h-8 mt-10 sm:w-1/2 w-40 colorButtonCommon textColorCommon disabled:opacity-50"
          disabled={disableButton}
          type="submit"
        >
          {t("auth.continue_change_password")}
        </button>
      </List>
      <Popup
        open={openSentPassToEmailSucess}
        handleClose={handleClosePopupSentSuccess}
        textheader={t("auth.password_reset_guide")}
      />
    </form>
  );
}

function FormChangePassword(props) {
  const { t, emailCurrent, errorSV, setErrorSV } = props;
  const [disableButton, setDisableButton] = useState(true);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
    navigate("/login");
  };
  const validationSchema = Yup.object().shape({
    current_password: Yup.string().required(t("common.message.current_password_not_found")),
    new_password: Yup.string()
      .required(t("common.message.new_password_not_found"))
      .matches(passwordRegex, t("auth.register.password_regex")),
    confirm_new_password: Yup.string()
      .required(t("common.message.password_not_found"))
      .oneOf([Yup.ref("new_password"), null], t("auth.password_do_no_match")),
  });

  const changePassword = (values) => {
    AuthService.changePassword(values).then(() => {
      setErrorSV("");
      setOpen(true);
      dispatch(setEmailForChangePass());
    });
  };

  const onSubmit = (values) => {
    if (emailCurrent) {
      const data = {
        email: emailCurrent,
        password: values.current_password,
        social_id_provider: "email_smv",
      };
      setErrorSV("");
      AuthService.login(data)
        .then((response) => {
          const user = jwt(response.data.data.accessToken);
          const token = {
            accessToken: response.data.data.accessToken,
            refreshToken: response.data.data.refreshToken,
          };

          dispatch(setAuthUser(user));
          dispatch(setAuthToken(token));
          changePassword(values);
        })
        .catch(() => {
          setErrorSV(t("common.message.wrong_current_password"));
        });
    }
  };

  const { handleSubmit, errors, values, setFieldValue, touched } = useFormik({
    initialValues: {
      current_password: "",
      new_password: "",
      confirm_new_password: "",
    },
    onSubmit,
    validationSchema,
  });

  useEffect(() => {
    if (
      !values.current_password?.trim() ||
      !values.new_password?.trim() ||
      !values.confirm_new_password?.trim()
    ) {
      return setDisableButton(true);
    }
    return setDisableButton(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableButton, values]);

  return (
    <form
      onSubmit={handleSubmit}
      className="m-auto w-2/3 bg-white rounded p-3 text-center"
    >
      <List component="nav" aria-label="mailbox folders" className="w-full">
        <ListItem>
          <div className="w-full">
            {errorSV ? <p className="text-rose-500">{errorSV}</p> : null}
          </div>
        </ListItem>
        <ListItem>
          <div className="w-full mb-20">
            <input
              onChange={(e) => {
                setErrorSV("");
                setFieldValue("current_password", e.target.value);
              }}
              type="password"
              value={values.current_password}
              placeholder={t("auth.current_password")}
              className="w-full h-10 sm:h-14 bg-gray-50 border-2 border-slate-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none  p-2.5 "
            />
            {errors.current_password && touched.current_password ? (
              <p className="text-rose-500">{errors.current_password}</p>
            ) : null}
          </div>
        </ListItem>
        <ListItem>
          <div className="w-full">
            <input
              onChange={(e) => {
                setErrorSV("");
                setFieldValue("new_password", e.target.value);
              }}
              type="password"
              value={values.email}
              placeholder={t("auth.new_password")}
              className="w-full h-10 sm:h-14 bg-gray-50 border-2 border-slate-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none  p-2.5 "
            />
            {errors.new_password && touched.new_password ? (
              <p className="text-rose-500">{errors.new_password}</p>
            ) : null}
          </div>
        </ListItem>
        <ListItem>
          <div className="w-full">
            <input
              onChange={(e) => {
                setErrorSV("");
                setFieldValue("confirm_new_password", e.target.value);
              }}
              type="password"
              value={values.confirm_new_password}
              placeholder={t("auth.confirm_new_password")}
              className="mb-5 h-10 sm:h-14 bg-gray-50 border-2 border-slate-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none  w-full p-2.5 "
            />
            {errors.confirm_new_password && touched.confirm_new_password ? (
              <p className="text-rose-500">{errors.confirm_new_password}</p>
            ) : null}
          </div>
        </ListItem>
        <button
          className="font-bold border rounded h-8 mt-10 sm:w-1/2 w-40 colorButtonCommon textColorCommon disabled:opacity-50"
          disabled={disableButton}
          type="submit"
        >
          {t("auth.continue_change_password")}
        </button>
      </List>
      <Popup
        open={open}
        handleClose={handleClose}
        textheader=""
        text={t("auth.change_password_success")}
      />
    </form>
  );
}
