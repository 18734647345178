import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Geocode from "react-geocode";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import FacilityService from "app/services/api/operatorService/FacilityService";
import DefaultLayout from "./DefaultLayout";

function Tab8(props) {
  const { tabsData, currentTab, setCurrentTab, numberOfTabs, setTabData } =
    props;
  const { t } = useTranslation();

  const [callApiToCreate, setCallApiToCreate] = useState("idle");

  const handleClickNext = () => {
    const data = {
      service_provider_name: "",
      avatar_url: "",
      registration_number: "",
      address: {
        postcode: "",
        state: "",
        city: "",
        street: "",
        address: "",
        country: "",
        longitude: "",
        latitude: "",
      },
      contact: {
        phone_number: {
          main: "",
        },
        email_address: {
          support: "",
        },
      },
    };

    data.address = {
      postcode: tabsData[1]?.resultPlace?.placeData?.postcode || "",
      state: tabsData[1]?.resultPlace?.placeData?.state || "",
      city: tabsData[1]?.resultPlace?.placeData?.city || "",
      address: tabsData[1]?.resultPlace?.placeData?.street || "",
      apartment_name: tabsData[1]?.resultPlace?.placeData?.address || "",
      country: "jp",
      country_key: (
        tabsData[1]?.resultPlace?.placeData?.country_key || "jp"
      ).toLowerCase(),
      place_id: tabsData[1]?.gMapPlace?.placeData?.place_id || "",
    };

    // eslint-disable-next-line prefer-destructuring
    data.avatar_url = [...tabsData[2]][0].thumbnail_url;
    data.service_provider_name = tabsData[3].facility_name;

    if (data?.address?.place_id) {
      setCallApiToCreate("calling");
      FacilityService.storeServiceProvider(data)
        .then((res) => {
          setTabData(res.data);
          setCurrentTab(currentTab + 1);
          setCallApiToCreate("idle");
        })
        .catch(() => {
          setCallApiToCreate("idle");
        });
    } else {
      setCallApiToCreate("calling");
      const pos = {
        lat: data.address.latitude,
        lng: data.address.longitude,
      };

      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
      Geocode.setLanguage("ja");
      Geocode.setLocationType("ROOFTOP");
      Geocode.fromLatLng(pos.lat, pos.lng)
        .then((response) => {
          data.address.place_id = response.results[0].place_id || "";
          return FacilityService.storeServiceProvider(data);
        })
        .then((res) => {
          setTabData(res.data);
          setCurrentTab(currentTab + 1);
          setCallApiToCreate("idle");
        })
        .catch(() => {
          setCallApiToCreate("idle");
        });
    }
  };

  const handleClickPrev = () => {
    setCurrentTab(currentTab - 1);
  };

  return (
    <DefaultLayout
      hidePrevNext={false}
      titleBox50vh={false}
      disabledPrevBtn={false}
      disabledNextBtn={false}
      onClickPrevBtn={handleClickPrev}
      onClickNextBtn={handleClickNext}
      titleBoxContent={
        <div className="w-full h-full flex justify-start items-end px-6 py-8 md:items-center md:px-14">
          <div>
            <h1 className="font-semibold text-[26px] leading-[30px] md:text-[48px] md:leading-[56px]">
              {t("operator.host.tab8.check_setting_tab_title")}
            </h1>
          </div>
        </div>
      }
      percentComplete={(currentTab * 100) / numberOfTabs}
      isSaveButton
    >
      <>
        <div className="w-full h-full flex items-center justify-center px-6 py-8 md:py-16">
          <div className="text-lg text-start text-[#222] px-4 font-medium">
            <div className="mb-8">
              <h2 className="mb-1">
                以下のページから次の2つの商品を購入してください。
              </h2>
              <p className="mb-1">・初期費用商品</p>
              <p className="mb-1">・月額費用商品</p>
            </div>

            <a
              target="_blank"
              href="https://resv.base.shop/"
              rel="noreferrer"
              className="text-[#393ff8] text-xl font-semibold underline mb-6"
            >
              https://resv.base.shop/
            </a>

            <div className="">
              <p className="mb-1">
                ※クリックするとショッピングサイトが別タブで表示されます。
              </p>
              <p className="mb-1">
                ※掲載されている2つのプランをご購入ください。
              </p>
              <p className="mb-1">
                ※前のページで設定頂いた「施設のタイトル」をご購入時の「備考」欄に必ず記載お願いします。
              </p>
            </div>
          </div>
        </div>
        {callApiToCreate === "calling" ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={callApiToCreate === "calling"}
            onClick={() => {}}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : null}
      </>
    </DefaultLayout>
  );
}

export default Tab8;
