export default {
  detail: {
    leftTitle: "募集案件",
    image: "写真",
    adsGeneralInfo: "募集案件の基本情報",
    slide: "枚",
    piece: "件",
    total_images: "写真{{value}}枚",
    advertisement: "募集案件 {{value}}件",
    createAdvertisement: "募集案件を作成",
    searchAdvertisement: "募集案件を検索",
    searchPlatform: "募集案件、事業者を検索",
    translateRequest: "翻訳依頼",
    uploadDueDate: "募集案件の掲載期間",
    receiveSNS: "投稿してもらうSNS",
    openTransactionDetail: "自己PR確認",
    verifySNS: "{{sns}}認証済み",
    uploadedInfo: "成果物情報",
    billingAmount: "報酬金額（税別）",
    uploadDate: "完了報告日時",
    uploadLink: "投稿先リンク",
    influencersComment: "インフルエンサーからのコメント",
    ad_project_name: "募集案件名",
    apply_quantity: "応募数",
    applied_quantity: "応募承認数",
    completed_quantity: "完了数",
    project_title: "募集案件のタイトル（日本語）",
    project_description: "施設の募集案件の説明",
    project_status: "募集案件のステータス",
    reward_type: "報酬タイプ",
    reward: "報酬",
    desired_condition: "報酬条件",
    age_group: "希望するインフルエンサーの年齢層",
    min_followers: "指定SNS最低フォロワー数 / 登録者数",
    price: "¥",
    ad_method: "PR方法",
    tagInclude: "投稿に含めるタグ",
    targetUrl: "PRして欲しいページ",
    influencer_gender: "インフルエンサー性別",
    adMedia: "指定SNS",
    targetUsername: "タグ付けアカウント",
    textInclude: "投稿に含める内容",
    video: "動画",
    "image, video": "動画 または 写真",
    recuitmentDate: "募集案件の掲載期間",
    already_applied:
      "現在インフルエンサーが「進行中」または「完了承認待ち」の為削除出来ません。",
    chat_header: "チャット",
    title_description:
      "（報酬タイプが「無償提供」の場合には、ここに無償提供する商品なども記載ください）",
    fixed_price: "固定報酬",
    free_supply: "無償提供",
    offer_price: "本来の提供価格",
    offer_price_description:
      "（報酬タイプが「無償提供」の場合には、ここに無償提供する商品の本来の提供価格を記載ください）",
    collapse_en_title: "英語",
    collapse_en_label: "募集案件のタイトル（英語）",
    collapse_cn_title: "中国語（繁体字）",
    collapse_cn_label: "募集案件のタイトル（中国語（繁体字））",
    en_descrip_label: "募集案件の説明（英語）",
    cn_descrip_label: "募集案件の説明（中国語（繁体字））",
    en_condition_label: "報酬条件（英語）",
    cn_condition_label: "報酬条件（中国語（繁体字））",
    en_include_label: "投稿に含める内容（英語）",
    cn_include_label: "投稿に含める内容（中国語（繁体字））",
    publish_status: "公開ステータス",
    cover_photo: "カバー写真",
    cover_photo_title: "カバー写真でリスティングの第一印象が決まります。",
    change_cover_photo: "写真を変更する",
    all_photos: "すべての写真",
    upload_photo_drag: "ドラッグ＆ドロップで写真の並べ替えができます。",
    upload_photo: "写真をアップロード",
    chat_notice: "※チャットが有効になっている募集案件のみ表示しています。",
    select_cover_image: "カバー写真を選択",
    upload_other: "写真をアップロードする",
    confirm_delete_image: "こちらの写真を削除しますか？",
    confirm_delete_notice: "削除すると、元に戻すことはできません。",
  },
  account_info: {
    in_progress_mess: "{{- date}} に{{from}}が応募を承認しました。",
    in_progress_special_mess:
      "{{- date}} にインフルエンサーが応募を承認しました。",
    declined_mess: "{{- date}} に{{from}}が応募を却下しました。",
    completed_mess: "{{- date}} に{{from}}が完了報告を承認しました。",
    reject_mess: "{{- date}} に{{from}}が完了報告を却下しました。",
    wait_recuitment_mess: "{{- date}} にインフルエンサーから応募がありました。",
    special_offer_mess: "{{- date}} に特別オファーを依頼しました。",
    send_deliverable_mess:
      "{{- date}} にインフルエンサーから完了報告が来ています。",
    cancel_transaction_mess_2:
      " {{- date}} にこの取引はキャンセルになりました。",
    cancel_transaction_mess: "この取引はキャンセルになりました。",
    cancel_transaction: "取引をキャンセル",
    reason: "理由",
    approve_application: "応募を承認する",
    reject_application: "応募を却下する",
    approved_report: "完了を承認する",
    reject_report: "却下",
    wait_recuitment: "インフルエンサーから応募がありました。",
    wait_finished: "インフルエンサーから完了報告が来ています。",
    completed: "完了しました。",
    download_zip: "まとめてダウンロード",
  },
  dialog: {
    reject_application_mess_1: "応募承認を却下しますか？",
    reject_application_mess_2:
      "却下する場合は「送信」ボタンをクリックしてください。",
    reject_report_mess_1: "完了承認を却下しますか?",
    reject_report_mess_2:
      " 却下する場合、理由を入力のうえ「送信」ボタンをクリックしてください。",
    reject_report_placeholder: "却下理由を入力",
    approve_report_mess_1: "完了承認をしますか?",
    approve_report_mess_2:
      " 承認する場合、インフルエンサーの評価を選択してください。",
    approve_report_mess_3: " 星をタップして、インフルエンサーを評価",
    approve_report_placeholder: "コメントをご記入ください",
  },
  social: {
    all: "全て ({{value}}件)",
    in_progress: "進行中 ({{value}}件)",
    unread_mess: "未読メッセージ ({{value}}件)",
    approve_report: "完了報告承認済み ({{value}}件)",
    ping_operator: "翻訳依頼 ({{value}}件)",
    transaction_tab: "やり取り一覧 ({{value}}件)",
    influencer_tab: "インフルエンサーを検索する",
    transaction_descrip: "インフルエンサーを絞り込んで対応する",
    count_wait_approved_complete: "完了報告承認待ち ({{value}}件)",
    count_cancel_transaction: "取引キャンセル申請 ({{value}}件)",
    count_action_required: "要対応マーク ({{value}}件)",
    pr_completed: "PR投稿が完了しました",
    insta: "Instagramフォロワー数",
    tiktok: "Tiktokフォロワー数",
    twitter: "Twitterフォロワー数",
    facebook: "Facebookフォロワー数",
    youtube: "Youtubeチャンネル登録者数",
    self_intro: "自己紹介",
    send_offer: "オファー依頼ボタン",
    send_offer_fail:
      "オファー リクエストの送信に失敗しました。 もう一度やり直してください。",
    influencer_descrip: "インフルエンサーを絞り込んで検索する",
    influencer_placeholder: "インフルエンサーを検索",
    influcener_name: "表示名",
    rating: "評価",
  },
};
