export default {
  list: {
    count_staff: "スタッフ 2名",
    search: "スタッフを検索",
    authority: "権限",
    institution: "施設",
    status: "ステータス",
    staff_name: "スタッフ名",
    task: "タスク",
    last_login_date: "最終ログイン日",
    create_staff: "スタッフを作成",
  }
}