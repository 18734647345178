import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultLayout from "./DefaultLayout";

const ImageTutorial = require("app/assets/images/img1.jpg");

const maxLengthName = 50;

function Tab5(props) {
  const { tabData, setTabData, currentTab, setCurrentTab, numberOfTabs } =
    props;
  const { t } = useTranslation();

  const [facilityName, setfacilityName] = useState("");

  useEffect(() => {
    if (tabData && tabData.facility_name) {
      setfacilityName(tabData.facility_name);
    }
  }, [tabData]);

  const handleClickNext = () => {
    if (facilityName.trim().length === 0 && facilityName.length > maxLengthName)
      return;
    setTabData({ facility_name: facilityName });
    setCurrentTab(currentTab + 1);
  };

  const handleClickPrev = () => {
    setCurrentTab(currentTab - 1);
  };

  const textAreaClasses = () => {
    const baseClasses =
      "w-full h-auto p-4 border border-[#818181] text-3xl font-semibold text-dark rounded-md outline-none";
    const shadowClasses =
      facilityName.length > maxLengthName
        ? "focus:border-[#c13515] focus:shadow-[0_0_0_2px_rgba(193,53,21,1)]"
        : "focus:border-[#222222] focus:shadow-[0_0_0_2px_rgba(34,34,34,1)]";
    return `${baseClasses} ${shadowClasses}`;
  };

  return (
    <DefaultLayout
      hidePrevNext={false}
      titleBox50vh
      disabledPrevBtn={false}
      disabledNextBtn={
        facilityName.trim().length === 0 || facilityName.length > maxLengthName
      }
      onClickPrevBtn={handleClickPrev}
      onClickNextBtn={handleClickNext}
      titleBoxContent={
        <div className="w-full h-full relative">
          <img src={ImageTutorial} className="w-full h-full " alt="" />
          <div className="absolute inset-0 w-full h-full flex justify-start items-end px-6 py-8 md:px-14">
            <h1 className="font-semibold text-[26px] leading-[30px] md:text-[48px] md:leading-[56px]">
              {t("operator.host.tab5.facility_name_tab_title")}
            </h1>
          </div>
        </div>
      }
      percentComplete={(currentTab * 100) / numberOfTabs}
    >
      <div className="w-full h-full flex items-center justify-center px-6 py-8 md:px-12 md:py-16">
        <div className="md:max-w-[560px] w-full flex flex-col items-stretch">
          <div className="text-base font-semibold text-dark mb-4">
            {t("operator.host.tab5.title_field_new")}
            <span className="text-red">*</span>
          </div>
          <div className="w-full h-auto mb-4">
            <textarea
              rows="3"
              autoComplete="off"
              placeholder=""
              className={textAreaClasses()}
               style={{WebkitAppearance: "none"}}
              value={facilityName}
              onChange={(e) =>
                setfacilityName(
                  e.currentTarget.value.length > maxLengthName
                    ? e.currentTarget.value.slice(0, maxLengthName)
                    : e.currentTarget.value
                )
              }
            />
          </div>
          <div className="w-full h-auto text-xs font-semibold text-[#717171] mb-4">
            {`${facilityName.length} / ${maxLengthName}`}
          </div>

          {facilityName.length > maxLengthName ? (
            <div className="w-full h-auto flex items-center justify-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#c13515"
                display="block"
                viewBox="0 0 16 16"
                style={{ height: 16, width: 16 }}
                className="mr-2"
              >
                <path d="M8 0a8 8 0 110 16A8 8 0 018 0zm0 10.2a1 1 0 100 2 1 1 0 000-2zm.8-6.6H7.2v5.2h1.6z" />
              </svg>
              <span className="text-[10px] font-normal text-[#c13515]">
                {t("operator.host.tab5.maximum_characters_allowed_err")}
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </DefaultLayout>
  );
}

export default Tab5;
