/* eslint-disable camelcase */

import React, { createContext, useEffect, useState } from "react";
import i18n from "i18next";
import moment from "moment";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { gapi } from "gapi-script";
import { getCountryCallingCode } from "libphonenumber-js";
import dayjs from "dayjs";
import {
  APPLIED_PROJECT_STATUS,
  DELIVERABLE_STATUS,
  EFacilityStatus,
  EProjectRequest,
  EProjectReward,
  EProjectSNS,
  EProjectStatus,
  FILTER_CACHING_KEY,
  INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS,
  isVideoRegex,
  lstLanguage,
  PAY_CATEGORY,
  PAY_STATUS,
  PROJECT_APPLIED_TYPE,
} from "../constant";
import {
  GEO_CODING_COMPONENT,
  GOOGLE_MAP_GEOCODING_API_URL,
} from "../services/constant";
import Path from "../auth/route/Path";
import {
  setProjectBatchEmpty,
  setProjectBatchEmptyForV,
} from "../redux/projectBatch";
import { setProfileEmpty } from "../redux/profileReducer";
import { setAuthStateDefault } from "../redux/authReducer";
import store from "../redux/store";

const lineImage = require("app/assets/images/icon-line.png");
const googleIcon = require("app/assets/images/icon-google.webp");
const tiktokIcon = require("app/assets/images/icon-tiktok.png");
const instagramIcon = require("app/assets/images/icon-instagram.png");
const iconTwitter = require("app/assets/images/icon-twitter.png");
const iconFacbook = require("app/assets/images/icon-facebook.png");
const iconYoutube = require("app/assets/images/icon-youtube.png");

// handle formatNumber locale
const currentLanguage = localStorage.getItem("i18nextLng");

const currencyFormat = lstLanguage.find(
  (item) => item.value === currentLanguage
)?.currencyFormat;

export const getTableColumWidth = (percent, tableWidth) => {
  return (tableWidth / 100) * percent;
};

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return { size, height: size[1], width: size[0] };
};

export const randomInt = (lenght) =>
  (Math.random() + 1).toString(36).substring(lenght);

export const facilityStatus = (status, t) => {
  switch (status) {
    case EFacilityStatus.STAFF_ONLY:
      return t("operator.facility.detail.only_staff");
    case EFacilityStatus.PRIVATE:
      return t("operator.facility.list.private");
    case EFacilityStatus.PUBLISH:
      return t("operator.facility.list.public");
    case EFacilityStatus.TRASH:
      return t("common.action.delete_2");
    default:
      return "";
  }
};

export const facilityStatusDesc = (status, t) => {
  switch (status) {
    case EFacilityStatus.STAFF_ONLY:
      return t("operator.facility.detail.visible_and_status_desc.staff_only");
    case EFacilityStatus.PRIVATE:
      return t("operator.facility.detail.visible_and_status_desc.private");
    case EFacilityStatus.PUBLISH:
      return t("operator.facility.detail.visible_and_status_desc.public");
    case EFacilityStatus.TRASH:
      return t("operator.facility.detail.visible_and_status_desc.delete");
    default:
      return "";
  }
};

export const inventoryStatusDesc = (status, t) => {
  switch (status) {
    case EFacilityStatus.STAFF_ONLY:
      return t("operator.inventory.detail.visible_and_status_desc.staff_only");
    case EFacilityStatus.PRIVATE:
      return t("operator.inventory.detail.visible_and_status_desc.private");
    case EFacilityStatus.PUBLISH:
      return t("operator.inventory.detail.visible_and_status_desc.public");
    case EFacilityStatus.TRASH:
      return t("operator.inventory.detail.visible_and_status_desc.delete");
    default:
      return "";
  }
};

export const productStatusDesc = (status, t) => {
  switch (status) {
    case EFacilityStatus.STAFF_ONLY:
      return t("operator.product.detail.visible_and_status_desc.staff_only");
    case EFacilityStatus.PRIVATE:
      return t("operator.product.detail.visible_and_status_desc.private");
    case EFacilityStatus.PUBLISH:
      return t("operator.product.detail.visible_and_status_desc.public");
    case EFacilityStatus.TRASH:
      return t("operator.product.detail.visible_and_status_desc.delete");
    default:
      return "";
  }
};

export const projectStatus = (status) => {
  switch (status) {
    case EFacilityStatus.DRAFT:
      return "下書き";
    case EFacilityStatus.PRIVATE:
      return "非公開";
    case EFacilityStatus.PUBLISH:
      return "公開";
    case EFacilityStatus.TRASH:
      return "削除";
    default:
      return "";
  }
};

export const projectRewardType = (type) => {
  switch (type) {
    case EProjectReward.FIXED_PRICE:
      return i18n.t("operator.advertisement.detail.fixed_price");
    case EProjectReward.FREE:
      return i18n.t("operator.advertisement.detail.free_supply");
    default:
      return "";
  }
};

export const facilityIconStatus = (status) => {
  switch (status) {
    case EFacilityStatus.STAFF_ONLY:
      return (
        <i className="fa-solid fa-circle text-[10px] text-blue mr-[8px]" />
      );
    case EFacilityStatus.PRIVATE:
      return (
        <i className="fa-solid fa-circle text-[10px] text-[#C13515] mr-[8px]" />
      );
    case EFacilityStatus.PUBLISH:
      return (
        <i className="fa-solid fa-circle text-[10px] text-[#008A05] mr-[8px]" />
      );
    case EFacilityStatus.TRASH:
      return (
        <i className="fa-solid fa-ban text-[10px] text-[#C13515] mr-[8px]" />
      );
    default:
      return "";
  }
};

export const tagStatus = (status, t) => {
  switch (status) {
    case EFacilityStatus.STAFF_ONLY:
      return t("operator.facility.detail.only_staff");
    case EFacilityStatus.PRIVATE:
      return t("operator.facility.list.private");
    case EFacilityStatus.PUBLISH:
      return t("operator.facility.list.public");
    case EFacilityStatus.TRASH:
      return t("common.action.delete_2");
    default:
      return "";
  }
};

export const tagIconStatus = (status) => {
  switch (status) {
    case EFacilityStatus.STAFF_ONLY:
      return (
        <i className="fa-solid fa-circle text-[10px] text-blue mr-[8px]" />
      );
    case EFacilityStatus.PRIVATE:
      return (
        <i className="fa-solid fa-circle text-[10px] text-[#C13515] mr-[8px]" />
      );
    case EFacilityStatus.PUBLISH:
      return (
        <i className="fa-solid fa-circle text-[10px] text-[#008A05] mr-[8px]" />
      );
    case EFacilityStatus.TRASH:
      return (
        <i className="fa-solid fa-ban text-[10px] text-[#C13515] mr-[8px]" />
      );
    default:
      return "";
  }
};

export const removeHTML = (htmlContent) => {
  const tmp = document.createElement("DIV");
  tmp.innerHTML = htmlContent;
  return tmp.textContent || tmp.innerText || "";
};

export const convertDynamicPath = (path, dynamicPath, queryObject) => {
  let str = path;
  Object.keys(dynamicPath)?.forEach((item) => {
    str = str.replace(`:${item}`, dynamicPath[item]);
  });

  if (queryObject) {
    Object.entries(queryObject)?.forEach(([key, value], index) => {
      const text = `${key}=${value}`;
      if (!index) {
        str += `?${text}`;
      } else {
        str += `&${text}`;
      }
    });
  }

  return str;
};

export const detailFormatDate = (date) => {
  const momentDate = moment(date);

  const duration = moment.duration(moment().diff(momentDate));
  const mins = duration.asMinutes();

  if (mins < 1) {
    return i18n.t("common.date_format.just_now");
  }

  if (mins < 60) {
    return `${Math.round(mins)} ${i18n.t("common.date_format.mins_ago")}`;
  }

  if (momentDate.isSame(moment(), "day")) {
    return `${moment(date).format("HH:mm")} ${i18n.t(
      "common.date_format.today"
    )}`;
  }

  if (momentDate.add(1, "d").isSame(moment(), "day")) {
    return `${moment(date).format("HH:mm")} ${i18n.t(
      "common.date_format.yesterday"
    )}`;
  }

  if (momentDate.year() === moment().year()) {
    return moment(date).format("MM/DD HH:mm");
  }

  return moment(date).format("YYYY/MM/DD HH:mm");
};

export const getRevertGeocoding = ({ callbackSuccess }) => {
  let revertGeocodingData = {};

  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  async function success(pos) {
    const crd = pos.coords;
    // const MAP_URL = `https://api.mapbox.com/geocoding/v5/mapbox.places/${crd?.longitude},${crd?.latitude}.json?access_token=${MAP_TOKEN}`;
    const MAP_URL = convertDynamicPath(GOOGLE_MAP_GEOCODING_API_URL, {
      lat: crd?.latitude,
      long: crd?.longitude,
      api_key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });
    revertGeocodingData = await axios.get(MAP_URL);
    callbackSuccess(revertGeocodingData, crd);
  }

  function error(err) {
    /* eslint-disable no-console */
    console.warn(`ERROR(${err.code}): ${err.message}`);
    /* eslint-disable no-console */
  }

  navigator.geolocation.getCurrentPosition(success, error, options);
};

export const checkListsHaveCommonElement = (arr1, arr2) => {
  let result = false;
  arr1.forEach((item1) => {
    arr2.forEach((item2) => {
      if (item1 === item2) {
        result = true;
      }
    });
  });

  return result;
};

export const formatGeoCodingData = (data) => {
  // this list can be modified depending on general usage/convention
  const FIELDS = {
    // COUNTRY: [GEO_CODING_COMPONENT.COUNTRY],
    POSTAL_CODE: [GEO_CODING_COMPONENT.POSTAL_CODE],
    STATE_PROVINCE: [
      GEO_CODING_COMPONENT.ADMINISTRATIVE_AREA.ADMINISTRATIVE_AREA_LEVEL_1,
    ],
    CITIES: [
      GEO_CODING_COMPONENT.ADMINISTRATIVE_AREA.ADMINISTRATIVE_AREA_LEVEL_2,
    ],
    DETAIL_ADDRESS: [
      GEO_CODING_COMPONENT.ROUTE,
      GEO_CODING_COMPONENT.STREET_NUMBER,
    ],
  };
  let postal_code = "";
  let cities = "";
  let stateOrProvince = "";
  let detailAddress = [];

  data[0]?.address_components?.forEach((componentItem) => {
    if (checkListsHaveCommonElement(componentItem?.types, FIELDS.POSTAL_CODE)) {
      postal_code = componentItem?.long_name;
    }
    if (checkListsHaveCommonElement(componentItem?.types, FIELDS.CITIES)) {
      cities = componentItem?.long_name;
    }
    if (
      checkListsHaveCommonElement(componentItem?.types, FIELDS.STATE_PROVINCE)
    ) {
      stateOrProvince = componentItem?.long_name;
    }
    if (
      checkListsHaveCommonElement(componentItem?.types, FIELDS.DETAIL_ADDRESS)
    ) {
      detailAddress = [...detailAddress, componentItem?.long_name];
    }
  });

  return {
    postal_code,
    cities,
    stateOrProvince,
    detailAddress: detailAddress?.join(" , "),
  };
};

export const scrollToElement = ({ id, yOffset }) => {
  const element = document.getElementById(id);
  if (element) {
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  }
};

export const reorderObj = (unorderedObj) => {
  return Object.keys(unorderedObj)
    .sort()
    .reduce((obj, key) => {
      obj[key] = unorderedObj[key];
      return obj;
    }, {});
};

export const checkValueChange = (
  initialValues,
  currentValues,
  allowComparisonField
) => {
  if (allowComparisonField) {
    const objA = {};
    const objB = {};

    allowComparisonField?.forEach((field) => {
      objA[field] = initialValues[field];
      objB[field] = currentValues[field];
    });
    return (
      JSON.stringify(reorderObj(objA)) !== JSON.stringify(reorderObj(objB))
    );
  }
  return (
    JSON.stringify(reorderObj(initialValues)) !==
    JSON.stringify(reorderObj(currentValues))
  );
};

export const permutation = (arr, firstIndex, secondIndex) => {
  if (arr?.length > 1) {
    const tempArr = [...arr];
    return tempArr?.map((item, index) => {
      if (parseInt(firstIndex, 10) === index) {
        return arr[secondIndex];
      }
      if (parseInt(secondIndex, 10) === index) {
        return arr[firstIndex];
      }
      return item;
    });
  }
  return arr;
};

export const permutation1 = (arr, firstIndex, secondIndex) => {
  const array = {
    tag_id_frist: "",
    tag_id_second: "",
  };

  if (arr?.length > 1) {
    const tempArr = [...arr];
    tempArr?.forEach((item, index) => {
      if (parseInt(firstIndex, 10) === index) {
        array.tag_id_frist = item?.tag_id;
      }
      if (parseInt(secondIndex, 10) === index) {
        array.tag_id_second = item?.tag_id;
      }
    });
  }

  return array;
};

export const generateSNSIcon = (value, style) => {
  return (
    <div
      className={`flex items-center justify-center mr-1 w-4 h-4 ${style || ""}`}
    >
      {
        {
          instagram: (
            <img alt="" src={instagramIcon} className="object-cover" />
          ),
          facebook: <img alt="" src={iconFacbook} className="object-cover" />,
          tiktok: <img alt="" src={tiktokIcon} className="object-cover" />,
          twitter: (
            <img alt="" src={iconTwitter} className="object-cover" />
          ),
          line: <img alt="" src={lineImage} className="object-cover" />,
          google: <img alt="" src={googleIcon} className="object-cover" />,
          youtube: <img alt="" src={iconYoutube} className="object-cover" />,
        }[value]
      }
    </div>
  );
};

// eslint-disable-next-line no-unused-vars
export const formatNumber = (price, toFixed = 3) => {
  if (Number.isNaN(price)) return price;
  // https://ncinc.backlog.jp/view/NWS_ICTL_ASPSERVICE-779#comment-179748306
  return Math.floor(Number(price)).toLocaleString(currencyFormat, {
    maximumFractionDigits: 0,
  });
};

export const dateIsValid = (dateStr) => {
  const regex = /^\d{4}-\d{2}-\d{2}$/;

  if (dateStr.match(regex) === null) {
    return false;
  }

  const date = new Date(dateStr);

  const timestamp = date.getTime();

  if (typeof timestamp !== "number" || Number.isNaN(timestamp)) {
    return false;
  }

  return date.toISOString().startsWith(dateStr);
};

export const getArrGuestInfoText = (guestInfo) => {
  const numberOfUsers = Number(guestInfo.adult) + Number(guestInfo.child);
  const numberOfInfants = Number(guestInfo.infant);
  const numberOfPets = Number(guestInfo.pet);

  const result = [];
  if (numberOfUsers > 1) {
    result.push(
      `${i18n.t(
        "common.common_booking.prefix_number_users"
      )}${numberOfUsers}${i18n.t(
        "common.common_booking.suffixes_number_users"
      )}`
    );
  } else if (numberOfUsers === 1) {
    result.push(
      `${i18n.t(
        "common.common_booking.prefix_number_user"
      )}${numberOfUsers}${i18n.t("common.common_booking.suffixes_number_user")}`
    );
  }

  if (numberOfInfants > 1) {
    result.push(
      `${i18n.t(
        "common.common_booking.prefix_number_infants"
      )}${numberOfInfants}${i18n.t(
        "common.common_booking.suffixes_number_infants"
      )}`
    );
  } else if (numberOfInfants === 1) {
    result.push(
      `${i18n.t(
        "common.common_booking.prefix_number_infant"
      )}${numberOfInfants}${i18n.t(
        "common.common_booking.suffixes_number_infant"
      )}`
    );
  }

  if (numberOfPets > 1) {
    result.push(
      `${i18n.t(
        "common.common_booking.prefix_number_pets"
      )}${numberOfPets}${i18n.t("common.common_booking.suffixes_number_pets")}`
    );
  } else if (numberOfPets === 1) {
    result.push(
      `${i18n.t(
        "common.common_booking.prefix_number_pet"
      )}${numberOfPets}${i18n.t("common.common_booking.suffixes_number_pet")}`
    );
  }

  return result.map((item, index) => {
    if (index === result.length - 1) return item;
    return `${item}${i18n.t("common.common_booking.separator_guest_info")}`;
  });
};

export const conventNumberHalfWitdhToFullWidth = (string) => {
  const numbersFullWidth = "０１２３４５６７８９";

  return string
    .replaceAll("0", numbersFullWidth.charAt(0))
    .replaceAll("1", numbersFullWidth.charAt(1))
    .replaceAll("2", numbersFullWidth.charAt(2))
    .replaceAll("3", numbersFullWidth.charAt(3))
    .replaceAll("4", numbersFullWidth.charAt(4))
    .replaceAll("5", numbersFullWidth.charAt(5))
    .replaceAll("6", numbersFullWidth.charAt(6))
    .replaceAll("7", numbersFullWidth.charAt(7))
    .replaceAll("8", numbersFullWidth.charAt(8))
    .replaceAll("9", numbersFullWidth.charAt(9));
};

export const thousandSepataror = (number = 0) => {
  const thousandRegex = /\B(?=(\d{3})+(?!\d))/g;
  return number?.toString()?.replace(thousandRegex, ",");
};

export const payStatus = (status) => {
  switch (status) {
    case PAY_STATUS.UNPAID:
      return i18n.t("operator.pay.list.unpaid");
    case PAY_STATUS.CONFIRMING:
      return i18n.t("operator.pay.list.confirming");
    case PAY_STATUS.PAID:
      return i18n.t("operator.pay.list.paid_1");
    default:
      return "";
  }
};

export const payCategory = (category) => {
  switch (Number(category)) {
    case PAY_CATEGORY.INITIAL_COST:
      return i18n.t("operator.pay.list.inital_cost");
    case PAY_CATEGORY.MONTHLY_USAGE_PRICE:
      return i18n.t("operator.pay.list.monthly_usage_price");
    case PAY_CATEGORY.AD_SERVICE_CHARGE:
      return i18n.t("operator.pay.list.ad_service_charge");
    default:
      return "";
  }
};

export const removeDuplicate = (array) => {
  const uniqueSet = new Set(array);
  return [...uniqueSet];
};

export const getFileName = (url) => {
  const folder = process.env.REACT_APP_FOLDER_NAME_S3;
  const folderIndex = url.indexOf(folder);
  const fileName = url.substring(folderIndex + folder.length);
  return fileName;
};

export const showJapanCurrencyJPY = (amount) => {
  return `¥ ${Math.floor(Number(amount)).toLocaleString("ja-JP")}`;
};

export const filterCaching = (key) => {
  return {
    getter: JSON.parse(localStorage?.getItem(key)),
    setter: (data) => {
      localStorage?.setItem(key, JSON.stringify(data));
    },
    clear: () => {
      Object.values(FILTER_CACHING_KEY)?.forEach((itemKey) => {
        localStorage.removeItem(itemKey);
      });
    },
  };
};

export const removeFieldEmptyValue = (obj) => {
  const newObj = Object.keys(obj)
    .filter((key) => !!obj[key])
    .reduce((current, key) => ({ ...current, [key]: obj[key] }), {});
  return newObj;
};

export const getNearestImg = (list) => {
  let result;
  list?.forEach((item) => {
    const isVideo = isVideoRegex?.exec(`${item?.url}`.toLowerCase());
    if (!isVideo && !result) {
      result = item;
    }
  });
  return result;
};

export const getNearestImgCreate = (list) => {
  let result;
  list?.forEach((item) => {
    const isVideo = item?.fileType?.includes("mp4");
    if (!isVideo && !result) {
      result = item;
    }
  });
  return result;
};

export const appendFormData = (model) => {
  const formData = new FormData();
  Object.keys(model).forEach((property) => {
    formData.append(property, model[property] ? model[property] : "");
  });
  return formData;
};

export const rearrangeMediaOrder = (mediaList) => {
  if (!mediaList || !Array.isArray(mediaList) || mediaList.length === 0)
    return [];

  const result = [...mediaList];
  const coverImageIndex = mediaList.findIndex((item) => item.use_for_cover);

  if (coverImageIndex > 0) {
    [result[0], result[coverImageIndex]] = [result[coverImageIndex], result[0]];
  }

  return result;
};

export const logout = (e) => {
  if (window.FB) {
    window.FB.getLoginStatus((response) => {
      if (response && response.status === "connected") {
        window.FB.logout(); // invoke fb status to prevent orveride token
      }
    });
  }
  if (gapi) {
    const auth2 = gapi.auth2?.getAuthInstance();
    auth2?.signOut().then(() => auth2.disconnect()); // invoke google status
  }
  e.preventDefault();
  filterCaching()?.clear();
  store.dispatch(setAuthStateDefault());
  store.dispatch(setProfileEmpty(null));
  store.dispatch(setProjectBatchEmpty());
  store.dispatch(setProjectBatchEmptyForV());
  i18n.changeLanguage("ja");
  dayjs.locale("ja");
  <Navigate to={Path.login} />;
};

export const formatUpdatePhoneNumberData = (phone, allowRemoveZero = true) => {
  const findFirstSpacePhoneNumber = phone?.indexOf(" "); // find first space in phone_number
  let phoneNumberWithoutPrefix = "";
  if (findFirstSpacePhoneNumber > 0) {
    phoneNumberWithoutPrefix = phone?.substring(findFirstSpacePhoneNumber + 1); // remove prefix
  } else {
    phoneNumberWithoutPrefix = "";
  }
  if (allowRemoveZero && phoneNumberWithoutPrefix[0] === String(0)) {
    phoneNumberWithoutPrefix = phoneNumberWithoutPrefix.substring(1);
  }
  return phoneNumberWithoutPrefix;
};

export const formatGetPhoneNumberWithPrefix = (phoneNumber, countryKey) => {
  const country_key = countryKey || "jp";
  const prefixPhoneNumber =
    getCountryCallingCode(country_key?.toUpperCase()) || "+81";

  if (phoneNumber) {
    if (phoneNumber[0] === "+" || phoneNumber[0] === "0") {
      phoneNumber = phoneNumber.slice(1);
    }
    // if phone number has value then add prefix, if not return value
    const formatPhoneNumber = phoneNumber
      ? `+${prefixPhoneNumber} ${phoneNumber}`
      : "";
    return formatPhoneNumber;
  }

  return "";
};

export const formatHeicFile = (file) => {
  let fileFormat = null;
  if (!file.type || file?.type === "image/heic") {
    const blob = new Blob([file], { type: "image/heic" });
    fileFormat = new File([blob], file?.name, {
      type: "image/heic",
    });
  }
  return fileFormat;
};

export const isNewFileUploading = (file) => {
  return typeof file !== "string";
};

export const checkOnlyWhiteSpace = (value, context, element) => {
  const { path } = context;
  if (value?.trim() === "") {
    return element?.createError({
      message: i18n.t("validate_message.onlyWhiteSpace", {
        path: i18n.t(`validation_field.${path}`),
      }),
    });
  }
  return true;
};

export const getProjectRequestText = (type, t) => {
  switch (type) {
    case EProjectRequest.NOT_REQUEST:
      return t("project_request_select.not_request");
    case EProjectRequest.REQUEST:
      return t("project_request_select.request");
    default:
      return "";
  }
};

export const getProjectStatusText = (type, t) => {
  switch (type) {
    case EProjectStatus.PUBLISH:
      return t("project_status_select.public");
    case EProjectStatus.PRIVATE:
      return t("project_status_select.private");
    case EProjectStatus.TRASH:
      return t("project_status_select.trash");
    default:
      return "";
  }
};

export const trimSpaceFirstCharacter = (value) => {
  let result = "";
  if (value) {
    result = value.trimStart();
  }
  return result;
};

export const handleSNSLink = (sns, username) => {
  if (sns === EProjectSNS.TWITTER) {
    return `https://twitter.com/${username}`;
  }
  if (sns === EProjectSNS.YOUTUBE) {
    return `https://www.youtube.com/channel/${username}`;
  }
  if (sns === EProjectSNS.FACEBOOK) {
    return `https://www.facebook.com/${username}`;
  }
  if (sns === EProjectSNS.TIKTOK) {
    return `https://www.tiktok.com/@${username}`;
  }
  if (sns === EProjectSNS.INSTAGRAM) {
    return `https://www.instagram.com/${username}/`;
  }
  return "";
};

export const LayoutContext = createContext();

export const customAccordionStyle = (showBorder) => {
  const style = {
    borderBottom: showBorder ? "1px solid #e5e7eb" : "",
    boxShadow: "none",
    ":before": {
      backgroundColor: "transparent",
    },
  };
  return style;
};

export const getLineUrl = (redirectUri) => {
  const rootUrl = "https://access.line.me/oauth2/v2.1/authorize";
  const csrfState = Math.random().toString(36).substring(2);

  const options = {
    client_id: process.env.REACT_APP_LINE_APP_ID,
    response_type: "code",
    scope: ["openid", "email", "profile"].join(" "),
    state: csrfState,
    redirect_uri: redirectUri,
  };

  const qs = new URLSearchParams(options);

  return `${rootUrl}?${qs.toString()}`;
};

export const getGoogleUrl = (redirectUri) => {
  const rootUrl = "https://accounts.google.com/o/oauth2/v2/auth";
  const csrfState = Math.random().toString(36).substring(2);

  const options = {
    client_id: process.env.REACT_APP_GG_APP_ID,
    response_type: "code",
    access_type: "offline",
    redirect_uri: redirectUri,
    prompt: "consent",
    scope: [
      "https://www.googleapis.com/auth/userinfo.profile",
      "https://www.googleapis.com/auth/userinfo.email",
      "openid",
    ].join(" "),
    state: csrfState,
  };

  const qs = new URLSearchParams(options);

  return `${rootUrl}?${qs.toString()}`;
};

export const assignDate = (array, condition) => {
  const itemFind = array?.findLast(
    (item) => item?.status === condition
  )?.changed_at;
  // return itemFind ? moment.utc(itemFind).format("YYYY/MM/DD HH:mm") : "";
  return itemFind ? moment(itemFind).format("YYYY/MM/DD HH:mm") : "";
};

export const getChangeLogItemByStatus = (array, condition) => {
  const itemFind = array?.findLast((item) => item?.status === condition);
  return itemFind;
};

export const getUpdatedByText = (value) => {
  switch (value) {
    case "service provider":
      return i18n.t("business_operator");
    case "platform_operator":
      return i18n.t("operator_admin");
    default:
      return "";
  }
};

export const getUpdatedBy = (array, condition) => {
  const itemFind = array?.findLast(
    (item) => item?.status === condition
  )?.ad_group;
  return getUpdatedByText(itemFind);
};

export const handleMessage = (item) => {
  const {
    applied_status,
    project_type,
    applied_status_change_log,
    deliverable_status_change_log,
  } = item || {};
  let date = "";
  let from = "";

  // influencer send application to admin
  if (applied_status === APPLIED_PROJECT_STATUS.WAIT_APPROVAL_RECRUITMENT) {
    date = assignDate(
      applied_status_change_log,
      INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.REVIEW
    );
    return i18n.t("operator.advertisement.account_info.wait_recuitment_mess", {
      date,
    });
  }

  // admin accept application from influencer
  if (
    applied_status === APPLIED_PROJECT_STATUS.IN_PROGRESS &&
    project_type === PROJECT_APPLIED_TYPE.APPLIED
  ) {
    date = assignDate(
      applied_status_change_log,
      INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.ACCEPTED
    );
    from = getUpdatedBy(
      applied_status_change_log,
      INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.ACCEPTED
    );
    return i18n.t("operator.advertisement.account_info.in_progress_mess", {
      date,
      from,
    });
  }

  // admin reject application from influencer
  if (applied_status === APPLIED_PROJECT_STATUS.DECLINED) {
    date = assignDate(
      applied_status_change_log,
      INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.DECLINED
    );
    from = getUpdatedBy(
      applied_status_change_log,
      INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.DECLINED
    );
    return i18n.t("operator.advertisement.account_info.declined_mess", {
      date,
      from,
    });
  }

  // admin request special offer for influencer
  if (!applied_status && project_type === PROJECT_APPLIED_TYPE.SPECIAL_OFFER) {
    date = assignDate(
      applied_status_change_log,
      INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.REVIEW
    );
    return i18n.t("operator.advertisement.account_info.special_offer_mess", {
      date,
    });
  }

  // influencer accept offer from admin
  if (
    applied_status === APPLIED_PROJECT_STATUS.IN_PROGRESS &&
    project_type === PROJECT_APPLIED_TYPE.SPECIAL_OFFER
  ) {
    date = assignDate(
      applied_status_change_log,
      INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.ACCEPTED
    );
    return i18n.t(
      "operator.advertisement.account_info.in_progress_special_mess",
      {
        date,
      }
    );
  }

  // influencer send deliverables to admin
  if (applied_status === APPLIED_PROJECT_STATUS.WAIT_APPROVAL_FINISHED) {
    date = assignDate(deliverable_status_change_log, DELIVERABLE_STATUS.REVIEW);
    return i18n.t("operator.advertisement.account_info.send_deliverable_mess", {
      date,
    });
  }

  // admin reject deliverables from influencer
  if (applied_status === APPLIED_PROJECT_STATUS.REJECT_REPORT) {
    date = assignDate(
      deliverable_status_change_log,
      DELIVERABLE_STATUS.REJECTED
    );
    from = getUpdatedBy(
      deliverable_status_change_log,
      DELIVERABLE_STATUS.REJECTED
    );
    return i18n.t("operator.advertisement.account_info.reject_mess", {
      date,
      from,
    });
  }

  // admin accept deliverables from influencer
  if (applied_status === APPLIED_PROJECT_STATUS.COMPLETED) {
    date = assignDate(
      applied_status_change_log,
      INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.FINISHED
    );
    from = getUpdatedBy(
      applied_status_change_log,
      INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.FINISHED
    );
    return i18n.t("operator.advertisement.account_info.completed_mess", {
      date,
      from,
    });
  }

  // cancel transaction
  if (applied_status === APPLIED_PROJECT_STATUS.CANCEL_TRANSACTION) {
    date = assignDate(
      applied_status_change_log,
      INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.CANCELED
    );
    return i18n.t(
      "operator.advertisement.account_info.cancel_transaction_mess_2",
      {
        date,
      }
    );
  }

  return `${moment(date).format("YYYY/MM/DD HH:mm")}`;
};

export const NOTIFICATION_TARGET = {
  CUSTOMER: "CUSTOMER",
  INFLUENCER: "INFLUENCER",
};

export const getGeneration = (value, language) => {
  switch (value) {
    case '10':
      if (language === 'en'){
        return i18n.t("generation1");
      }
      return value + i18n.t("generation1");
    case '60':
      return value + i18n.t("generation3");
    default:
      return value + i18n.t("generation2");
  }
}

export const changeGeneration = (value, language) => {
  switch (value) {
    case '10代':
      if (language === 'en'){
        return i18n.t("generation1");
      }
      return value.replace('代','') + i18n.t("generation1");
    case '60代以上':
      return value.replace('代以上','') + i18n.t("generation3");
    default:
      return value.replace('代','') + i18n.t("generation2");
  }
}
