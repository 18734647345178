export default {
  title: "業者資訊",
  basic_info: "基本訊息",
  service_name: "業者名稱",
  address: "地址",
  phone: "電話號碼",
  admin_basic_info: "代表經理",
  admin_name: "全名",
  invoice_info: "發票",
  invoice_number: "發票號碼",
  long_lat: "經度/緯度",
};
