import { createSlice } from "@reduxjs/toolkit";
import CheckDevice from "../utils/CheckDevice";

const initialState = false;
const isMobile = CheckDevice.checkIsMobile();

const slice = createSlice({
  name: "blockUI",
  initialState,
  reducers: {
    setActive: (state, action) => {
      if (action.payload) {
        document.body.classList.add("overflow-hidden");
        if (!isMobile) {
          document.body.classList.add("cursor-wait");
        }
      } else {
        document.body.classList.remove("overflow-hidden");
        if (!isMobile) {
          document.body.classList.remove("cursor-wait");
        }
      }
      return action.payload;
    },
  },
});

export const { setActive } = slice.actions;

export default slice.reducer;
