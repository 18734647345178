export default {
  paymentInfluence: "インフルエンサーへの支払い",
  historyPaymentInfluence: "インフルエンサーへの支払履歴",
  waitingPayment: "事業者からの入金待ち",
  warningUpdate:
    "以下のインフルエンサーのデータを「支払済み」に更新しますか？\n{{value}}",
  listPaymentInfluence: {
    totalPaymentRequest: "支払依頼 合計金額",
    CumulativeTotalAmount: "累積 合計金額",
    totalRequestPayment: "支払依頼件数 {{value}}件",
    nameInfluence: "インフルエンサー名",
    paymentRequest: "支払い依頼",
    cumulativeRewardAmount: "累積報酬金額",
    payPalAcount: "PayPalアカウント情報",
    yes: "有",
    nothing: "無",
    updatePaid: "支払済みに更新",
  },
  detail: {
    totalItem: "合計件数 {{value}}件",
    nameProjec: "募集案件名",
    CompensationAmount: "報酬金額",
    unitPrice: "単価",
    number: "件数",
    compensationDate: "報酬確定日",
  },
  listPaymentHistory: {
    paymentTotalAmount: "支払合計金額",
    numberOfPayment: "支払件数 {{value}}件",
    payment: "支払金額",
    RenewalDateToPaid: "支払済みへの更新日",
    changer: "更新者",
  },
  listRequestPayment: {
    WaitingForPaymentTotalAmount: "入金待ち 合計金額",
    searchForInfluence: "インフルエンサー名",
    businessPerson: "事業者",
    waitingAmount: "入金待ち金額",
    businessName: "事業者名",
  },
};