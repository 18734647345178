import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Path from "app/operator/route/Path";

const ImageTutorial = require("app/assets/images/img2.jpg");

function Tab9() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="w-screen h-screen overflow-hidden p-0 m-0">
      <div className="w-full h-full bg-gradient-to-r from-[#0E5CB5] to-[#ffffff]">
        <div className="w-full h-full">
          <div className="w-full h-full flex flex-col md:flex-row overflow-y-auto">
            <div className="flex-1 flex-shrink-0 md:h-full md:w-1/2 md:bg-gradient-to-b md:from-[#ffffff] md:to-[#0E5CB5] min-h-[50vh]">
              <div className="w-full h-full">
                <img src={ImageTutorial} className="w-full h-full " alt="" />
              </div>
            </div>
            <div className="w-full h-auto relative md:h-full md:w-1/2 md:overflow-y-auto bg-black min-h-[50vh]">
              <div className="absolute -top-3 inset-0 h-3 rounded-t-xl bg-black md:hidden " />

              <div
                className="w-full flex"
                style={{
                  minHeight: "calc(100% - 80px)",
                }}
              >
                <div className="w-full bg-black px-6 md:px-10 flex items-center justify-center">
                  <div className="max-w-[480px] flex flex-col ">
                    <h1 className="w-full text-[32px] md:text-[48px] leading-10 md:leading-[60px] font-semibold text-white text-start md:text-center mb-4 md:mb-9">
                      {t("operator.host.tab9.welcome")}
                    </h1>
                  </div>
                </div>
              </div>

              <div className="sticky inset-x-0 bottom-0 h-20 bg-black flex flex-col">
                <div className="w-full h-0.5 bg-[#222]" />

                <div className="w-full flex-1 px-6 md:px-10">
                  <div className="w-full h-full flex items-center justify-center md:justify-end">
                    <button
                      type="button"
                      className="w-full md:w-auto h-12 flex items-center justify-center px-7 py-0 rounded-lg text-base font-semibold text-white leading-5 colorButtonCommon"
                      onClick={() => {
                        navigate(Path.operator);
                      }}
                    >
                      {t("operator.host.tab9.save")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tab9;
