/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from "react";
import CustomModal from "app/operator/components/CustomModal";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import Button, { BUTTON_STYLE_TYPE } from "app/operator/components/Button";
import Media from "app/components/Media";
import AppMessage from "../../../../components/AppMessage";
import { getNearestImgCreate } from "../../../../utils";

function CoverImage({ coverImg, imageList, onSetCoverImg }) {
  const { t } = useTranslation();
  const [visibleModalAllocation, setVisibleModalAllocation] = useState(false);
  const [message, setMessage] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (!coverImg?.url && imageList?.length) {
      const { url, id, fileType } = getNearestImgCreate(imageList) || {};
      if (getNearestImgCreate(imageList)) {
        onSetCoverImg({
          url,
          id,
          fileType,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageList, coverImg]);

  const handleChooseCoverImage = () => {
    setVisibleModalAllocation(true);
  };

  return (
    <>
      <div className="flex flex-wrap">
        <div className="flex-[1_1_300px] pr-[16px] text-[14px] ">
          <p className="font-semibold text-[18px]">
            {t("operator.advertisement.detail.cover_photo")}
          </p>
          <p className="text-gray">
            {t("operator.advertisement.detail.cover_photo_title")}
          </p>
          <button
            type="button"
            className={cx(
              "border rounded-[8px] my-[24px] py-[8px] px-[16px] font-semibold",
              {
                "pointer-events-none text-[#DDDDDD] border-[#DDDDDD]":
                  !imageList?.length,
              },
              {
                "border-dark": imageList?.length,
              }
            )}
            onClick={handleChooseCoverImage}
          >
            {t("operator.advertisement.detail.change_cover_photo")}
          </button>
        </div>
        <div className="flex-[1_1_300px]">
          {[coverImg]?.map((item, index) => {
            const isVideoProps = `${item?.fileType}`
              ?.toLowerCase()
              ?.includes("mp4");
            return (
              <div key={index} className="relative pt-[60%]">
                <Media
                  data={item?.url && URL.createObjectURL(item?.url)}
                  controls
                  isVideoProps={isVideoProps}
                  className="absolute top-0 left-0 w-full h-full "
                />
              </div>
            );
          })}
        </div>
      </div>
      <ModalChooseCoverImg
        visibleModalAllocation={visibleModalAllocation}
        setVisibleModalAllocation={setVisibleModalAllocation}
        imageList={imageList}
        coverImg={coverImg}
        onSetCoverImg={(data) => {
          const isVideo = data?.fileType?.includes("mp4");
          if (isVideo) {
            setMessage(t("common.message.cannot_set_video_as_cover"));
          } else {
            onSetCoverImg(data);
          }
        }}
      />
      <AppMessage
        open={!!message}
        setOpen={setMessage}
        onSubmit={() => setMessage(false)}
      >
        {message}
      </AppMessage>
    </>
  );
}

function ModalChooseCoverImg({
  visibleModalAllocation,
  setVisibleModalAllocation,
  imageList,
  onSetCoverImg,
  coverImg,
}) {
  const { t } = useTranslation();
  const [tempCoverImg, setTempCoverImg] = useState(coverImg);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setTempCoverImg(coverImg);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(coverImg)]);

  const renderImgItem = ({ url, id, fileType }) => {
    const isCoverImage = tempCoverImg?.id === id;
    const isVideoProps = `${fileType}`?.toLowerCase()?.includes("mp4");

    return (
      <button
        key={id}
        type="button"
        className={cx("rounded-[8px]", {
          "p-[4px]": !isCoverImage,
          "p-[2px] border-2 border-dark": isCoverImage,
        })}
        onClick={(e) => {
          e?.preventDefault();
          setTempCoverImg({ url, id, fileType });
        }}
      >
        <div className="relative pt-[60%]">
          <i
            className={cx(
              "absolute left-[4px] top-[4px] z-10 bg-white rounded-full",
              {
                "fa-solid fa-circle-dot": isCoverImage,
                "fa-regular fa-circle": !isCoverImage,
              }
            )}
          />
          <Media
            data={url && URL.createObjectURL(url)}
            controls
            isVideoProps={isVideoProps}
            className="absolute top-0 left-0 w-full h-full rounded-[8px]"
          />
        </div>
      </button>
    );
  };

  const renderBtnGroup = () => {
    return (
      <div className="p-[24px] flex justify-between border-border border-t gap-[16px]">
        <label
          className="underline font-semibold"
          htmlFor="facility-img-upload"
        >
          {t("operator.advertisement.detail.upload_other")}
        </label>
        <div className="flex gap-[8px]">
          <Button
            className="border-dark"
            onClick={() => {
              setVisibleModalAllocation(false);
            }}
            styleType={BUTTON_STYLE_TYPE.GHOST}
          >
            {t("common.action.cancel")}
          </Button>
          <Button
            onClick={() => {
              onSetCoverImg(tempCoverImg);
              setVisibleModalAllocation(false);
            }}
            styleType={BUTTON_STYLE_TYPE.PRIMARY}
          >
            {t("common.action.save")}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <CustomModal
      visible={visibleModalAllocation}
      onClose={() => {
        setVisibleModalAllocation(false);
      }}
      cancelText={t("common.action.cancel")}
      confirmText={t("common.action.delete")}
      title={
        <div className="px-[24px] text-[16px] font-bold text-center">
          {t("operator.advertisement.detail.select_cover_image")}
        </div>
      }
      customButtonGroup={renderBtnGroup}
      maxWidth="lg"
      fullWidth
    >
      <div className="py-[24px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[4px]">
        {imageList?.map((item) => {
          return renderImgItem(item);
        })}
      </div>
    </CustomModal>
  );
}

export default CoverImage;
