/* eslint-disable react/no-array-index-key */
import React from "react";
import { useTranslation } from "react-i18next";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import CustomExpand from "app/components/CustomExpand";
import { ErrorMessage } from "formik";
import FormItem, { TYPE_INPUT } from "app/operator/components/FormItem";
import { MAX_LENGTH_100 } from "app/utils/schema/operator";
import { customAccordionStyle } from "app/utils";

function AccountName(props) {
  const { formikProps } = props;
  const { values: formValues } = formikProps;
  const { t } = useTranslation();

  return (
    <div className="mb-[16px]">
      <div className="mb-[8px] font-semibold required inline">
        {t("admin.account.list.account_name")}
      </div>
      <Accordion sx={customAccordionStyle()}>
        <AccordionSummary
          classes={{ expanded: "!transform-none", root: "gap-1" }}
          expandIcon={<CustomExpand />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="flex">
            <div className="flex flex-wrap gap-[8px]" translate="no">
              <ErrorMessage
                name="account_family_name"
                component="div"
                className="errors-msg"
              />
              {formValues?.account_family_name} {formValues?.account_first_name}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <FormItem
              touched
              required
              disableError
              name="account_family_name"
              showCharCount
              maxLength={MAX_LENGTH_100}
              typeInput={TYPE_INPUT.TEXT}
              defaultLength={formValues.account_family_name?.length}
              label={t("admin.account.form.family_name")}
              placeholder={t("common.message.no_input_yet")}
              // space
              labelClassName="font-semibold mb-2"
              inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
            />
            <FormItem
              touchedField="account_family_name"
              required
              disableError
              name="account_first_name"
              showCharCount
              maxLength={MAX_LENGTH_100}
              typeInput={TYPE_INPUT.TEXT}
              defaultLength={formValues.account_first_name?.length}
              label={t("admin.account.form.first_name")}
              placeholder={t("common.message.no_input_yet")}
              // space
              labelClassName="font-semibold mb-2"
              inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default AccountName;
