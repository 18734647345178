// v-007-005
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import SelectMenu from "app/components/SelectMenu";
import TableWaitingPayment from "./TableWaitingPayment";
import useCoreComponentList from "./useCoreComponentList";
import { fomatPrice } from "../../../../constant";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function WaitingPayment() {
  const { t } = useTranslation();

  const [
    items,
    setSearch,
    setPage,
    setPerPage,
    setServiceId,
    total,
    search,
    perPage,
    page,
    pricePaid,
    listOperator,
    serviceId,
  ] = useCoreComponentList();

  const [keySearch, setKeySearch] = useState(search || "");
  const headCells = [
    {
      id: "influenceName",
      align: "center",
      label: t("admin.payment.listPaymentInfluence.nameInfluence"),
    },
    {
      id: "waitingAmount",
      align: "right",
      label: t("admin.payment.listRequestPayment.waitingAmount"),
    },
    {
      id: "payment_type",
      align: "center",
      label: t("admin.payment.detail.compensationDate"),
    },
    {
      id: "businessName",
      align: "center",
      label: t("admin.payment.listRequestPayment.businessName"),
    },
    {
      id: "nameProjec",
      align: "center",
      label: t("admin.payment.detail.nameProjec"),
    },
  ];

  const onSubmitFilterStatus = (values) => {
    // get facility
    setServiceId(values);
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setSearch(keySearch.trim());
    }, 1000);
    return () => clearTimeout(timeOut);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keySearch]);

  return (
    <div className="flex-1">
      <div className="max-w-[450px]">
        <div className="mb-10">
          <p className="font-bold text-3xl">
            {t("admin.payment.listRequestPayment.WaitingForPaymentTotalAmount")}
          </p>
          <div>
            <span className="font-bold text-2xl">
              {t("admin.pay.list.price")} {fomatPrice(pricePaid.fee) || 0}
            </span>
            <p className="font-bold text-xs">
              ({t("admin.pay.consumptionTax")} {fomatPrice(pricePaid.tax) || 0})
            </p>
          </div>
        </div>
      </div>
      <h2 className="font-bold text-3xl mb-4">
        {t("admin.payment.detail.totalItem", { value: total })}
      </h2>
      <div className="flex flex-wrap items-center gap-4">
        <FormControl size="small" className=" max-w-lg w-[350px] font-bold ">
          <OutlinedInput
            sx={{
              borderRadius: 40,
              height: 36,
            }}
            id="outlined-adornment-weight"
            className="font-bold"
            value={keySearch}
            onChange={(e) => {
              setKeySearch(e.target.value);
            }}
            placeholder={t(
              "admin.payment.listRequestPayment.searchForInfluence"
            )}
            aria-describedby="outlined-weight-helper-text"
            startAdornment={
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            }
          />
        </FormControl>

        <SelectMenu
          optionSelected={serviceId}
          optionList={listOperator}
          onSubmit={onSubmitFilterStatus}
          label={t("admin.payment.listRequestPayment.businessPerson")}
        />
      </div>

      <div className="mt-8">
        <TableWaitingPayment
          search={search}
          getComparator={getComparator}
          headCells={headCells}
          rows={items}
          perPage={perPage}
          page={page}
          setPerPage={setPerPage}
          setPage={setPage}
          total={total}
        />
      </div>
    </div>
  );
}
