/* eslint-disable no-undef */
import React from "react";
import ProvideHost from "app/operator/pages/Operator/host";
import Path from "app/operator/route/Path";
import PayList from "../pages/Operator/Pay";
import PayDetail from "../pages/Operator/Pay/detail/index";
import AdvertisementManage from "../pages/AdvertisementManage";
import AdvertisementDetail from "../pages/AdvertisementManage/AdvertisementDetail";
import AdvertisementSocial from "../pages/AdvertisementManage/AdvertisementSocial";
import OperatorInfo from "../pages/Operator/Info";
import Dashboard from "../staff/pages/Dashboard/Dashboard";
import AdProjectEditImage from "../pages/AdvertisementManage/AdvertisementDetail/AdProjectEditImage";
import AdvertisementCreate from "../pages/AdvertisementManage/AdvertisementCreate";
import AccountSettingsPersonalInfo from "../pages/AccountSettings/PersonalInfo";
import AccountSettings from "../pages/AccountSettings";
import Profile from "../pages/Profile";
import GlobalSetting from "../pages/AccountSettings/GlobalSetting";
import Withdraw from "../pages/AccountSettings/Withdraw";
import AccountSettingsLoginSecurity from "../pages/AccountSettings/LoginSecurity";
import InfluencerDetail from "../pages/InfluencerCMS/InfluencerDetail";
import Manager from "../pages/Operator/manager";

export default [
  { path: Path.dasboard, element: <Dashboard /> }, // s-001-001
  { path: Path.operator, element: <Dashboard /> }, // s-001-001
  { path: Path.operator_provide_host, element: <ProvideHost /> },
  { path: Path.pay_list, element: <PayList /> },
  { path: Path.pay_detail, element: <PayDetail /> },
  { path: Path.ad_project, element: <AdvertisementManage /> },
  { path: Path.ad_project_create, element: <AdvertisementCreate /> },
  { path: Path.ad_project_detail(), element: <AdvertisementDetail /> },
  {
    path: Path.ad_project_detail_edit_image(),
    element: <AdProjectEditImage />,
  },
  { path: Path.ad_project_social(), element: <AdvertisementSocial /> },
  { path: Path.influencer_detail(), element: <InfluencerDetail /> },
  { path: Path.operator_info, element: <OperatorInfo /> },
  { path: Path.account_settings, element: <AccountSettings /> },
  {
    path: Path.account_settings_personal_info,
    element: <AccountSettingsPersonalInfo />,
  },
  {
    path: Path.account_settings_login_security,
    element: <AccountSettingsLoginSecurity />,
  },
  {
    path: Path.account_settings_global_setting,
    element: <GlobalSetting />,
  },
  {
    path: Path.profile,
    element: <Profile />,
  },
  { path: Path.manager, element: <Manager /> },
  { path: Path.withdraw, element: <Withdraw /> },
];
