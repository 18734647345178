export default {
  total_contract: "Total Contracted Business Operators",
  total_influncer: "Number of Influencers",
  matching_commission: "Matching Fee Sales",
  number_business_change: "Number of Matches",
  membership_change: "Number of Free Recruitment Created",
  reservation_quantity: "Number of Fixed-Reward Recruitment Cases Created",
  Enter_wrong_infomation: "エクスポート エラー",
  fee_consumption: "Included Consumption Tax",
  select_month: "Please enter start/end month.",
  select_end_month: "Please enter the end month.",
};
