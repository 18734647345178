import { SOCIAL } from "../../constant";

export const formatInfluencerList = (data) => {
  const lstTemp = [];
  data?.forEach((item, index) => {
    const SOCIAL_MEDIA = {
      INSTAGRAM: null,
      TIKTOK: null,
      YOUTUBE: null,
      FACEBOOK: null
    };

    item.social_media.forEach(val => {
      switch (val.social_media) {
        case SOCIAL.INSTAGRAM :
          SOCIAL_MEDIA.INSTAGRAM = val.subscribers_number;
          break;
        case SOCIAL.TIKTOK :
          SOCIAL_MEDIA.TIKTOK = val.subscribers_number;
          break;
        case SOCIAL.FACEBOOK:
          SOCIAL_MEDIA.FACEBOOK = val.subscribers_number;
          break;
        case SOCIAL.YOUTUBE:
          SOCIAL_MEDIA.YOUTUBE = val.subscribers_number;
          break;
        default:
          break;
      }
    });

    lstTemp[index] = {
      id: item?.user_id,
      avatar: item?.avatar_url,
      gender: item?.gender,
      nickname: item?.nickname,
      instagram: SOCIAL_MEDIA.INSTAGRAM || "",
      tiktok: SOCIAL_MEDIA.TIKTOK || "",
      facebook: SOCIAL_MEDIA.FACEBOOK || "",
      youtube: SOCIAL_MEDIA.YOUTUBE || "",
      self_introduction: item?.self_introduction,
      rating: item?.rating,
    };
  });

  return lstTemp;
};
