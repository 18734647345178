import { useEffect, useState } from "react";
import CommonService from "app/services/api/CommonService";
import { EResponseStatus } from "app/constant";
import { useTranslation } from "react-i18next";

function useCoreComponent(endpoint, params) {
  const { t } = useTranslation();
  const [errorMess, setErrorMess] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState([]);

  useEffect(() => {
    setErrorMess("");
    setIsLoading(true);
    CommonService.getModelList(endpoint, params)
      .then((res) => {
        if (res.status === EResponseStatus.SUCCESS) {
          setTotalPages(res.data.data.totalPages);
          setTotalItem(res.data.data.total);
          setResponse(res);
        }
      })
      .catch(() => {
        setErrorMess(t("common.message.cannot_display_data"));
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [endpoint, JSON.stringify(params)]);

  return { response, errorMess, isLoading, totalPages, totalItem };
}

export default useCoreComponent;
