import React from "react";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import moment from "moment";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CustomNoDataOverLay from "../../../../components/CustomNoDataOverLay";
import { FORMAT_DATE, fomatPrice } from "../../../../constant";

function EnhancedTableHead(props) {
  const { headCells } = props;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            className="font-bold"
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function TableHistoryPayment(props) {
  const { t } = useTranslation();
  const {
    headCells,
    rows,
    perPage,
    setPerPage,
    page,
    setPage,
    total,
    handleShowpage,
  } = props;
  const [selected, setSelected] = React.useState([]);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleRowsPerPageChange = (event) => {
    setPerPage(event.target.value);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Paper sx={{ width: "100%" }} className="border border-slate-300">
      <TableContainer className="h-[450px]">
        <Table
          sx={{ minWidth: 750 }}
          stickyHeader
          aria-label="sticky table"
          size="medium"
        >
          <EnhancedTableHead headCells={headCells} />
          <TableBody>
            {rows.map((row, index) => {
              const isItemSelected = isSelected(row.name);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  hover
                  onClick={(event) => handleClick(event, row.name)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={labelId}
                  selected={isItemSelected}
                >
                  <TableCell
                    align="center"
                    component="th"
                    className="hover:cursor-pointer hover:underline font-bold text-xs"
                    id={labelId}
                    scope="row"
                    onClick={() => handleShowpage(row.user_id)}
                  >
                    {row?.nickname}
                  </TableCell>
                  <TableCell align="right" className="font-bold text-xs">
                    <p>
                      {t("admin.pay.list.price")}
                      {row?.fee ? fomatPrice(row?.fee) : 0}
                    </p>
                    <p>
                      ({t("admin.pay.consumptionTax")}
                      {row?.tax ? fomatPrice(row?.tax) : 0})
                    </p>
                  </TableCell>
                  <TableCell align="center" className="font-bold text-xs">
                    {row?.point
                      ? moment(row?.point).format(
                          FORMAT_DATE.YYYY_MM_D
                        )
                      : ""}
                  </TableCell>
                  <TableCell align="center" className="font-bold text-xs">
                    {row?.account}
                  </TableCell>
                </TableRow>
              );
            })}
            {rows.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={6}
                  align="center"
                  className="font-bold text-xs"
                >
                  <CustomNoDataOverLay />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        classes={{ fontWeight: 700 }}
        count={rows.length === 0 ? 0 : Number(total)}
        rowsPerPage={Number(perPage)}
        page={Number(page) - 1}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        labelRowsPerPage=""
        className="border-t border-slate-300 font-bold text-xs"
      />
    </Paper>
  );
}
