import socketio from "socket.io-client";
import store from "app/redux/store";

const getToken = () => {
  return store.getState()?.auth.token?.accessToken || "";
};

export const SOCKET_LISTENER = {
  DISCONNECT: "disconnect",
  POST_MESSAGE: "postedMsg",
  REPLY_STATUS: "reply_status",
  UPDATE_CHECK_IN: "updatedCheckin",
  UPDATE_APPLY_PROJECT_STATUS: "updatedAppliedProjectStatus",
  UPDATED_INVENTORY_STATEMENT: "updatedInventoryStatement",
  UPDATED_CHECKIN_MACHINE: "updatedCheckinMachine",
  UPDATE_ORDER: "updatedOrder",
  SHOW_NOTIFY: "showNotification",
  CUSTOMER_CHECKIN_CHECKOUT: "customerCheckInCheckOut",
  CUSTOMER_CHANGE_INVENTORY: "customerChangeInventory",
  CANCEL_ORDER_PRODUCT: "cancelOrderProduct"
};

export const SOCKET_TYPE = {
  CHAT: "CHAT",
  ITCL: "ITCL"
};

export const SOCKET_NAMESPACE = {
  CHAT: "chats",
  BOOKING: "booking",
  GUEST_BOOKING: "guest/booking"
};

export const socketIO = (type, namespace, query) => {
  let uri = "";

  switch (type) {
    case SOCKET_TYPE.CHAT: {
      let apiUrl = process.env.REACT_APP_CHAT_API_URL;
      if (apiUrl.charAt(apiUrl.length - 1) !== "/") apiUrl += "/";
      uri = apiUrl + namespace;
      break;
    }
    case SOCKET_TYPE.ITCL: {
      let apiUrl = process.env.REACT_APP_API_URL;
      if (apiUrl.charAt(apiUrl.length - 1) !== "/") apiUrl += "/";
      uri = apiUrl + namespace;
      break;
    }
    default:
      break;
  }

  const options = {
    transports: ["websocket"],
    auth: { token: `Bearer ${getToken()}` },
    withCredentials: true
  };

  if (query) {
    options.query = query;
  }

  return socketio(uri, options);
};
