/* eslint-disable class-methods-use-this */
import client from "./client";
import CommonService from "./CommonService";

const baseUrl = "user";
const notifyUrl = "cms/notification";

class UserService {
  updateInfluencerInfo(data) {
    return client.put(`${baseUrl}/update-influencer-information`, data);
  }

  getUserPersonalInfo() {
    return client.get(`${baseUrl}/personal-information`);
  }

  updateUserPersonalInfo(data) {
    return client.post(`${baseUrl}/personal-information/update`, data);
  }

  getProfile() {
    return client.get(`${baseUrl}/profile`);
  }

  updateProfile(data) {
    return client.put(`${baseUrl}/update-profile`, data);
  }

  changePassword(data) {
    return client.post(`${baseUrl}/change-password`, data);
  }

  getUserRole() {
    return client.get(`${baseUrl}/role`);
  }

  getListNotification() {
    return CommonService.getModelList(`${notifyUrl}/get-all`);
  }

  withdrawUser(data) {
    return client.put(`${baseUrl}/inactive`, data);
  }
}

export default new UserService();
