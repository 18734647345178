export default {
  influencer_info: "Influencer Information",
  number_apply: "Number of Applications",
  number_approved: "Number of Delivery Approvals",
  approval_history: "Approval History",
  self_introduction: "Self-Introduction (Areas of Expertise Comments)",
  nickname: "Display Name",
  send_offer: "Send Special Offer",
  self_pr: "Self-PR for Projects",
  url_pr: "Self-PR URL for Projects",
  file_pr: "Self-PR File for Projects",
};
