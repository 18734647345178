import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { uploadFile } from "app/services/api";
import Popover from "@mui/material/Popover";
import Dialog from "@mui/material/Dialog";

import { formatHeicFile } from 'app/utils';
import DefaultLayout from "./DefaultLayout";

const ImageTutorial = require("app/assets/images/img1.jpg");

const maxNumberOfFiles = 10;
const maxNumberOfVideoFiles = 2;
const minFileImageSize = 50 * 1024;
const maxFileImageSize = 5 * 1024 * 1024;
const maxFileVideoSize = 50 * 1024 * 1024;

const allowedExtensions = /[^\\/.]+(?=(\.png|\.jpeg|\.jpg|\.heic)$)/;

function Tab4(props) {
  const { tabData, setTabData, currentTab, setCurrentTab, numberOfTabs } =
    props;
  const { t } = useTranslation();

  const [uploadedImages, setUploadedImages] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    if (tabData && tabData.length > 0) {
      setUploadedImages(tabData);
    }
  }, [tabData]);

  const handleClickPrev = () => {
    setCurrentTab(currentTab - 1);
  };
  const handleClickNext = () => {
    if (!uploadedImages.length) return;
    setTabData(uploadedImages);
    setCurrentTab(currentTab + 1);
  };

  return (
    <>
      <DefaultLayout
        hidePrevNext={false}
        titleBox50vh
        disabledPrevBtn={false}
        disabledNextBtn={!uploadedImages.length}
        onClickPrevBtn={handleClickPrev}
        onClickNextBtn={handleClickNext}
        titleBoxContent={
          <div className="w-full h-full relative">
            <img src={ImageTutorial} className="w-full h-full " alt="" />
            <div className="absolute inset-0 w-full h-full flex justify-start items-end px-6 py-8 md:px-14">
              <h1 className="font-semibold text-[26px] leading-[30px] md:text-[48px] md:leading-[56px]">
                {t("operator.host.tab4.upload_photo_tab_title")}
              </h1>
            </div>
          </div>
        }
        percentComplete={(currentTab * 100) / numberOfTabs}
      >
        <div className="w-full h-full flex items-center justify-center px-6 py-8 lg:px-12">
          <div className="max-w-[656px] w-full flex flex-col items-stretch">
            <div className="w-full h-auto">
              {uploadedImages.length > 0 ? (
                <ImageGallery
                  uploadedImages={uploadedImages}
                  setUploadedImages={setUploadedImages}
                  setErrorMessages={setErrorMessages}
                />
              ) : (
                <UploadImageInit
                  setUploadedImages={setUploadedImages}
                  setErrorMessages={setErrorMessages}
                />
              )}
            </div>
          </div>
        </div>
      </DefaultLayout>
      <ErrorMessageDialog
        errorMessages={errorMessages}
        setErrorMessages={setErrorMessages}
      />
    </>
  );
}

export default Tab4;

function ImageGallery(props) {
  const { uploadedImages, setUploadedImages, setErrorMessages } = props;
  const [dragEnter, setDragEnter] = useState(false);

  const { open, getRootProps, getInputProps } = useDropzone({
    // accept: {
    //   "image/png": [".png"],
    //   "image/jpg": [".jpg"],
    //   "image/jpeg": [".jpeg"],
    //   "video/mp4": [".mp4"],
    // },
    multiple: true,
    onDrop: (acceptedFiles) => {
      setDragEnter(false);
      setErrorMessages([]);
      let numberOfImages = uploadedImages.filter(
        (item) => item.media_type === 1
      ).length;
      let numberOfVideos = uploadedImages.filter(
        (item) => item.media_type === 2
      ).length;

      const newErrors = [];

      const newAcceptedFiles = acceptedFiles.filter((acceptedFile) => {
        if (numberOfImages + numberOfVideos >= maxNumberOfFiles) {
          newErrors.push({ file: acceptedFile, message: "max_file" });
          return false;
        }

        if (acceptedFile.type === "video/mp4") {
          if (numberOfVideos >= maxNumberOfVideoFiles) {
            newErrors.push({ file: acceptedFile, message: "max_file" });
            return false;
          }

          if (acceptedFile.size > maxFileVideoSize) {
            newErrors.push({ file: acceptedFile, message: "max_size_video" });
            return false;
          }

          numberOfVideos += 1;
          return true;
        }

        if (allowedExtensions.test(acceptedFile.name.toLowerCase())) {
          if (acceptedFile.size > maxFileImageSize) {
            newErrors.push({ file: acceptedFile, message: "max_size_image" });
            return false;
          }
          if (acceptedFile.size < minFileImageSize) {
            newErrors.push({ file: acceptedFile, message: "min_size_image" });
            return false;
          }

          numberOfImages += 1;
          return true;
        }

        newErrors.push({ file: acceptedFile, message: "file_type_error" });
        return false;
      });

      setErrorMessages(newErrors);

      const uploadFilesPromise = newAcceptedFiles.map((file) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", file.type === "video/mp4" ? "video" : "image");
        return uploadFile(formData);
      });

      uploadFilesPromise.forEach((uploadFilePromise, index) => {
        uploadFilePromise
          .then((res) => {
            setUploadedImages((prev) => [
              ...prev,
              {
                ...res.data.data,
                media_type:
                  newAcceptedFiles[index].type === "video/mp4" ? 2 : 1,
              },
            ]);
          })
          .catch(() => {});
      });
    },
    onDropRejected: () => {},
    onDragEnter: () => {
      setDragEnter(true);
    },
    onDragLeave: () => {
      setDragEnter(false);
    },
    noClick: true,
    noKeyboard: true,
  });

  return (
    <div
      className="max-w-[700px] w-full !h-auto md:min-h-[50%] mx-auto"
      {...getRootProps({ className: "dropzone" })}
    >
      <div className="w-full h-full flex flex-col relative">
        {/* <div className="w-full h-auto flex items-start justify-between pb-6">
          <div className="flex flex-col">
            <h2 className="text-base md:text-lg font-semibold text-dark">
              {t("operator.host.tab4.choose_at_least_5_photos")}
            </h2>
            <p className="text-sm md:text-sm font-normal text-dark">
              {t("operator.host.tab4.drag_to_reorder")}
            </p>
          </div>

          <button
            type="button"
            className={`w-auto h-10 flex items-center px-3 border border-dark rounded-md hover:bg-[#f7f7f7] ml-3 ${
              uploadedImages.length >= maxNumberOfFiles
                ? "cursor-not-allow"
                : "cursor-pointer"
            }`}
            disabled={uploadedImages.length >= maxNumberOfFiles}
            onClick={() => {
              if (uploadedImages.length >= maxNumberOfFiles) return;
              open();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="#222222"
              strokeWidth="2.667"
              display="block"
              overflow="visible"
              viewBox="0 0 32 32"
              style={{ height: 24, width: 24 }}
              className="mr-2"
            >
              <path d="M4 3h24H4zM16 7v23V7zM6 17l9.293-9.293a1 1 0 011.414 0L26 17" />
            </svg>
            <p className="text-sm font-semibold text-dark whitespace-nowrap">
              {t("operator.host.tab4.upload")}
            </p>
          </button>
        </div> */}

        <div className="w-full h-auto">
          <div className="grid grid-cols-2 gap-4">
            <div className="col-span-2">
              <ImageItem
                image={uploadedImages[0]}
                isLastImage={uploadedImages.length === 1}
                isCoverImage
                uploadedImages={uploadedImages}
                setUploadedImages={setUploadedImages}
              />
            </div>
            {uploadedImages.length >= 5 ? (
              <>
                {uploadedImages
                  .slice(5)
                  .map(
                    (img, index) =>
                      index < maxNumberOfFiles && (
                        <ImageItem
                          key={img.media_url}
                          image={img}
                          openUploadNew={open}
                          setUploadedImages={setUploadedImages}
                        />
                      )
                  )}
                {uploadedImages.length < maxNumberOfFiles ? (
                  <ImageItem openUploadNew={open} />
                ) : null}
              </>
            ) : null}
          </div>
        </div>

        {dragEnter ? (
          <div className="absolute inset-0 bg-[#ffffffeb] w-full h-full flex items-center justify-center border-2 border-dark z-20">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#222222"
              display="block"
              viewBox="0 0 64 64"
              style={{ height: 64, width: 64 }}
            >
              <path
                fill="#222"
                d="M41.636 8.404l1.017 7.237 17.579 4.71a5 5 0 013.587 5.914l-.051.21-6.73 25.114A5.002 5.002 0 0153 55.233V56a5 5 0 01-4.783 4.995L48 61H16a5 5 0 01-4.995-4.783L11 56V44.013l-1.69.239a5 5 0 01-5.612-4.042l-.034-.214L.045 14.25a5 5 0 014.041-5.612l.215-.035 31.688-4.454a5 5 0 015.647 4.256zm-20.49 39.373l-.14.131L13 55.914V56a3 3 0 002.824 2.995L16 59h21.42L25.149 47.812a3 3 0 00-4.004-.035zm16.501-9.903l-.139.136-9.417 9.778L40.387 59H48a3 3 0 002.995-2.824L51 56v-9.561l-9.3-8.556a3 3 0 00-4.053-.009zM53 34.614V53.19a3.003 3.003 0 002.054-1.944l.052-.174 2.475-9.235L53 34.614zM48 27H31.991a3.943 3.943 0 01-.862 0H16a3 3 0 00-2.995 2.824L13 30v23.084l6.592-6.59a5 5 0 016.722-.318l.182.159.117.105 9.455-9.817a5 5 0 016.802-.374l.184.162L51 43.721V30a3 3 0 00-2.824-2.995L48 27zm-37 5.548l-5.363 7.118.007.052a3 3 0 003.388 2.553L11 41.994v-9.446zm14.18-16.594l-.05.169-2.38 8.876h5.336a4 4 0 116.955 0L48 25.001a5 5 0 014.995 4.783L53 30v.88l5.284 8.331 3.552-13.253a3 3 0 00-1.953-3.624l-.169-.05L28.804 14a3 3 0 00-3.623 1.953zM21 31a4 4 0 110 8 4 4 0 010-8zm0 2a2 2 0 100 4 2 2 0 000-4zM36.443 6.11l-.175.019-31.69 4.453a3 3 0 00-2.572 3.214l.02.175 3.217 22.894 5.833-7.74a5.002 5.002 0 014.707-4.12L16 25h4.68l2.519-9.395a5 5 0 015.913-3.587l.21.051 11.232 3.01-.898-6.397a3 3 0 00-3.213-2.573zm-6.811 16.395a2 2 0 001.64 2.496h.593a2 2 0 10-2.233-2.496zM10 13a4 4 0 110 8 4 4 0 010-8zm0 2a2 2 0 100 4 2 2 0 000-4z"
              />
            </svg>
          </div>
        ) : null}

        <input {...getInputProps()} />
      </div>
    </div>
  );
}

function ImageItem(props) {
  const { image, openUploadNew, isCoverImage, setUploadedImages } = props;
  const { t } = useTranslation();

  const [showImageMenuEl, setShowImageMenuEl] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const handleDeleteImage = () => {
    setUploadedImages((prev) =>
      prev.filter((item) => item.media_url !== image.media_url)
    );
    setShowConfirmDelete(false);
  };

  return (
    <div className="w-full relative pt-[66.67%] overflow-hidden p-0 m-0">
      <div className="absolute inset-0">
        {image ? (
          <>
            <div className="w-full h-full relative bg-[#f7f7f7] flex items-center justify-center">
              <button
                type="button"
                className="absolute top-4 right-4 w-8 h-8 rounded-full bg-[#ffffffe6] hover:bg-white flex items-center justify-center cursor-pointer shadow-sm z-10"
                onClick={(e) => setShowImageMenuEl(e.currentTarget)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#222"
                  display="block"
                  viewBox="0 0 16 16"
                  style={{ height: 16, width: 16 }}
                >
                  <path d="M3 6a2 2 0 100 4 2 2 0 000-4zm5 0a2 2 0 100 4 2 2 0 000-4zm5 0a2 2 0 100 4 2 2 0 000-4z" />
                </svg>
              </button>

              {isCoverImage ? (
                <div className="absolute top-4 left-4 h-8 w-auto px-2 flex items-center justify-center bg-white rounded shadow z-10">
                  <span className="font-extrabold text-sm text-dark">
                    {t("operator.host.tab4.cover_photo")}
                  </span>
                </div>
              ) : null}

              {image.media_type === 1 ? (
                <img
                  src={image.media_url}
                  alt=""
                  className="w-full h-full object-contain"
                />
              ) : (
                <video autoPlay muted className="w-full h-full object-contain">
                  <source src={image.media_url} type="video/mp4" />
                </video>
              )}
            </div>
            <Popover
              open={Boolean(showImageMenuEl)}
              anchorEl={showImageMenuEl}
              onClose={() => setShowImageMenuEl(false)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PaperProps={{
                style: {
                  width: "200px",
                  padding: "12px 0px",
                  borderRadius: "12px",
                },
              }}
            >
              <div className="flex flex-col">
                <button
                  type="button"
                  className="w-full h-auto py-2 px-5 hover:bg-[#f7f7f7] text-start"
                  onClick={() => {
                    setShowImageMenuEl(false);
                    setShowConfirmDelete(true);
                  }}
                >
                  {t("operator.host.tab4.delete")}
                </button>
              </div>
            </Popover>
            <ConfirmDeleteImage
              open={showConfirmDelete}
              handleClose={() => setShowConfirmDelete(false)}
              handleDeleteImage={() => handleDeleteImage(image)}
            />
          </>
        ) : (
          <button
            type="button"
            className="w-full h-full border border-dashed hover:border-2 hover:border-solid hover:border-dark hover:shadow-[0_0_0_2px_rgba(34,34,34,1)]"
            onClick={openUploadNew}
          >
            <div className="w-full h-full flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#222222"
                display="block"
                viewBox="0 0 32 32"
                style={{ height: 32, width: 32 }}
              >
                <path d="M27 3a4 4 0 014 4v18a4 4 0 01-4 4H5a4 4 0 01-4-4V7a4 4 0 014-4zM8.887 19.038l-.094.083L3 24.914V25a2 2 0 001.85 1.995L5 27h13.085l-7.878-7.879a1 1 0 00-1.32-.083zm12.5-6l-.094.083-7.129 7.129L20.915 27H27a2 2 0 001.995-1.85L29 25v-5.585l-6.293-6.294a1 1 0 00-1.32-.083zM27 5H5a2 2 0 00-2 2v15.084l4.379-4.377a3 3 0 014.098-.135l.144.135 1.128 1.128 7.13-7.128a3 3 0 014.098-.135l.144.135L29 16.585V7a2 2 0 00-1.85-1.995zM8 7a3 3 0 110 6 3 3 0 010-6zm0 2a1 1 0 100 2 1 1 0 000-2z" />
              </svg>
            </div>
          </button>
        )}
      </div>
    </div>
  );
}

function ConfirmDeleteImage(props) {
  const { open, handleClose, handleDeleteImage } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      PaperProps={{ classes: { root: "rounded-lg w-full" } }}
    >
      <div className="w-full h-16 flex items-center justify-center relative border-b border-[#ebebeb]">
        <button
          type="button"
          className="absolute left-4 top-4 w-8 h-8 flex items-center justify-center rounded-full hover:bg-[#f7f7f7] cursor-pointer"
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            strokeWidth="3"
            stroke="#222222"
            display="block"
            overflow="visible"
            viewBox="0 0 32 32"
            style={{ height: 16, width: 16 }}
          >
            <path d="m6 6 20 20" />
            <path d="m26 6-20 20" />
          </svg>
        </button>
        <h2 className="text-base font-extrabold text-dark">
          {t("operator.host.tab4.delete_this_photo")}
        </h2>
      </div>
      <div className="w-full h-auto p-6 text-start text-sm leading-5 font-normal text-dark">
        {t("operator.host.tab4.delete_photo_warning")}
      </div>
      <div className="w-full h-16 flex items-center justify-between border-t border-[#ebebeb] text-sm leading-[18px] px-6 py-4">
        <button
          type="button"
          className="px-4 py-2 rounded text-dark bg-white hover:text-black hover:bg-[#f7f7f7] cursor-pointer underline"
          onClick={handleClose}
        >
          {t("operator.host.tab4.cancel")}
        </button>

        <button
          type="button"
          className="px-4 py-2 rounded text-white bg-dark hover:bg-black cursor-pointer"
          onClick={handleDeleteImage}
        >
          {t("operator.host.tab4.delete_photo")}
        </button>
      </div>
    </Dialog>
  );
}

function UploadImageInit({ setUploadedImages, setErrorMessages }) {
  const { t } = useTranslation();
  const { getRootProps, getInputProps, open } = useDropzone({
    // accept: {
    //   "image/png": [".png"],
    //   "image/jpg": [".jpg"],
    //   "image/jpeg": [".jpeg"],
    //   "video/mp4": [".mp4"],
    // },
    multiple: true,
    onDrop: (acceptedFiles) => {
      setErrorMessages([]);

      let numberOfImages = 0;
      let numberOfVideos = 0;

      const newErrors = [];

      const newAcceptedFiles = acceptedFiles.filter((acceptedFile) => {
        if (numberOfImages + numberOfVideos >= maxNumberOfFiles) {
          newErrors.push({ file: acceptedFile, message: "max_file" });
          return false;
        }

        if (acceptedFile.type === "video/mp4") {
          if (numberOfVideos >= maxNumberOfVideoFiles) {
            newErrors.push({ file: acceptedFile, message: "max_file" });
            return false;
          }

          if (acceptedFile.size > maxFileVideoSize) {
            newErrors.push({ file: acceptedFile, message: "max_size_video" });
            return false;
          }

          numberOfVideos += 1;
          return true;
        }

        if (
          allowedExtensions.test(acceptedFile.name.toLowerCase())
        ) {
          if (acceptedFile.size > maxFileImageSize) {
            newErrors.push({ file: acceptedFile, message: "max_size_image" });
            return false;
          }
          if (acceptedFile.size < minFileImageSize) {
            newErrors.push({ file: acceptedFile, message: "min_size_image" });
            return false;
          }

          numberOfImages += 1;
          return true;
        }

        newErrors.push({ file: acceptedFile, message: "file_type_error" });
        return false;
      });

      setErrorMessages(newErrors);

      const uploadFilesPromise = newAcceptedFiles.map((file) => {
        const fileFormat = formatHeicFile(file);
        
        const formData = new FormData();
        formData.append("file", fileFormat || file);
        formData.append("type", file.type === "video/mp4" ? "video" : "image");
        return uploadFile(formData);
      });

      uploadFilesPromise.forEach((uploadFilePromise, index) => {
        uploadFilePromise
          .then((res) => {
            setUploadedImages((prev) => [
              ...prev,
              {
                ...res.data.data,
                media_type:
                  newAcceptedFiles[index].type === "video/mp4" ? 2 : 1,
              },
            ]);
          })
          .catch(() => {});
      });
    },
    noClick: true,
    noKeyboard: true,
  });

  return (
    <div className="max-w-[500px] w-full h-auto md:!h-[50vh] md:min-h-[50%] border border-dashed border-[#b0b0b0] mx-auto">
      <div
        // eslint-disable jsx-props-no-spreading
        {...getRootProps({ className: "dropzone" })}
        style={{ width: "100%", height: "100%" }}
      >
        <div className="w-full h-full flex flex-col items-center justify-center p-6 ">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#222222"
              display="block"
              viewBox="0 0 64 64"
              style={{ height: 64, width: 64 }}
            >
              <path
                fill="#222"
                d="M41.636 8.404l1.017 7.237 17.579 4.71a5 5 0 013.587 5.914l-.051.21-6.73 25.114A5.002 5.002 0 0153 55.233V56a5 5 0 01-4.783 4.995L48 61H16a5 5 0 01-4.995-4.783L11 56V44.013l-1.69.239a5 5 0 01-5.612-4.042l-.034-.214L.045 14.25a5 5 0 014.041-5.612l.215-.035 31.688-4.454a5 5 0 015.647 4.256zm-20.49 39.373l-.14.131L13 55.914V56a3 3 0 002.824 2.995L16 59h21.42L25.149 47.812a3 3 0 00-4.004-.035zm16.501-9.903l-.139.136-9.417 9.778L40.387 59H48a3 3 0 002.995-2.824L51 56v-9.561l-9.3-8.556a3 3 0 00-4.053-.009zM53 34.614V53.19a3.003 3.003 0 002.054-1.944l.052-.174 2.475-9.235L53 34.614zM48 27H31.991a3.943 3.943 0 01-.862 0H16a3 3 0 00-2.995 2.824L13 30v23.084l6.592-6.59a5 5 0 016.722-.318l.182.159.117.105 9.455-9.817a5 5 0 016.802-.374l.184.162L51 43.721V30a3 3 0 00-2.824-2.995L48 27zm-37 5.548l-5.363 7.118.007.052a3 3 0 003.388 2.553L11 41.994v-9.446zm14.18-16.594l-.05.169-2.38 8.876h5.336a4 4 0 116.955 0L48 25.001a5 5 0 014.995 4.783L53 30v.88l5.284 8.331 3.552-13.253a3 3 0 00-1.953-3.624l-.169-.05L28.804 14a3 3 0 00-3.623 1.953zM21 31a4 4 0 110 8 4 4 0 010-8zm0 2a2 2 0 100 4 2 2 0 000-4zM36.443 6.11l-.175.019-31.69 4.453a3 3 0 00-2.572 3.214l.02.175 3.217 22.894 5.833-7.74a5.002 5.002 0 014.707-4.12L16 25h4.68l2.519-9.395a5 5 0 015.913-3.587l.21.051 11.232 3.01-.898-6.397a3 3 0 00-3.213-2.573zm-6.811 16.395a2 2 0 001.64 2.496h.593a2 2 0 10-2.233-2.496zM10 13a4 4 0 110 8 4 4 0 010-8zm0 2a2 2 0 100 4 2 2 0 000-4z"
              />
            </svg>
          </div>

          <h3 className="pt-4 pb-6 text-lg leading-[22px] font-semibold text-dark text-center">
            {t("operator.host.tab4.drag_your_photos_here")}
          </h3>
          <p className="pb-6 text-base leading-5 font-normal text-dark text-center">
            {t("operator.host.tab4.choose_at_least_5_photos")}
          </p>

          <button
            type="button"
            className="text-sm font-semibold text-dark underline cursor-pointer"
            onClick={open}
          >
            {t("operator.host.tab4.upload_from_your_device")}
          </button>
          <input {...getInputProps()} />
        </div>
      </div>
    </div>
  );
}

function ErrorMessageDialog({ errorMessages, setErrorMessages }) {
  const timeoutRef = useRef();
  const { t } = useTranslation();
  useEffect(() => {
    if (errorMessages.length > 0) {
      timeoutRef.current = setTimeout(() => {
        setErrorMessages([]);
      }, 3000);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [errorMessages, setErrorMessages]);

  const renderListMessages = () => {
    const uniqueMes = [];
    errorMessages.forEach((message) => {
      if (!uniqueMes.includes(message.message)) {
        uniqueMes.push(message.message);
      }
    });
    if (uniqueMes.length === 0) return null;
    return uniqueMes.map((item) => {
      return (
        <div key={item} className="text-sm font-semibold mb-1 text-dark">
          {item !== "file_type_error" ? (
            t(`operator.host.tab4.${item}`)
          ) : (
            <>
              <p>アップロード可能なファイルは以下のファイルです。</p>
              <p>PNG、JPEG、JPG、HEIC</p>
            </>
          )}
        </div>
      );
    });
  };

  return (
    <div
      className={`fixed w-full md:max-w-[500px] max-w-[86%] bottom-[100px] left-1/2 -translate-x-1/2 rounded-xl shadow-xl border border-border p-4 bg-white ${
        errorMessages.length > 0 ? "" : "hidden"
      }`}
    >
      <div className="flex item-center pr-10">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#c13515"
            viewBox="0 0 16 16"
            style={{ height: 40, width: 40 }}
          >
            <path d="M8 0a8 8 0 110 16A8 8 0 018 0zm0 10.2a1 1 0 100 2 1 1 0 000-2zm.8-6.6H7.2v5.2h1.6z" />
          </svg>
        </div>
        <div className="flex-1 ml-4">{renderListMessages()}</div>
      </div>
      <button
        type="button"
        className=" absolute top-3 right-3 w-8 h-8 flex items-center justify-center rounded-full hover:bg-secondary cursor-pointer"
        onClick={() => setErrorMessages([])}
      >
        <svg
          fill="none"
          stroke="#222222"
          strokeWidth="4"
          display="block"
          overflow="visible"
          viewBox="0 0 32 32"
          style={{ height: 16, width: 16 }}
        >
          <path d="M6 6l20 20M26 6L6 26" />
        </svg>
      </button>
    </div>
  );
}
