import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import PaymentInfluentce from './paymentInfluence';
import HistoryPaymentInfluence from './historyPaymentInfluence';
import WaitingPayment from './waitingPayment';
import PaymentInfluentceDetail from './paymentInfluence/detail';
import { setHeaderPayment } from '../../../redux/ictl/headerPaymentReducer';
import { TABPAYMENTSHOW } from '../../../constant';
import PaymentHistoryDetail from './historyPaymentInfluence/detail';

export default function HeaderItemPayment() {
  const dispatch = useDispatch()
  const [value, setValue] = useState('1');
  const pageShow = useSelector((state) => state.headerPayment.show);
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  const [userInfluenceId, setUserInfluenceId] = useState(searchParams.get('userId'))

  // const [showPaymentInfluenceDetail, setShowPaymentInfluenceDetail] = useState(false)
  const { t } = useTranslation();
  const handleChange = (event, newValue) => {
    if (newValue === '1') {
      dispatch(setHeaderPayment(1))
    }
    if (newValue === '2') {
      dispatch(setHeaderPayment(3))
    }
    if (newValue === '3') {
      dispatch(setHeaderPayment(5))
    }
    setValue(newValue);
  };
  useEffect(() => {
    if (userInfluenceId === null && pageShow === TABPAYMENTSHOW.PAYMENT_INFLUENCE_DETAIL) {
      dispatch(setHeaderPayment(TABPAYMENTSHOW.PAYMENT_INFLUENCE))
    }

    if (!userInfluenceId && pageShow === TABPAYMENTSHOW.PAYMENT_HISTORY_DETATIL) {
      dispatch(setHeaderPayment(TABPAYMENTSHOW.PAYMENT_HISTORY))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfluenceId])
  useEffect(() => {
    if (pageShow === TABPAYMENTSHOW.PAYMENT_INFLUENCE || pageShow === TABPAYMENTSHOW.PAYMENT_INFLUENCE_DETAIL) {
      setValue('1')
    }
    if (pageShow === TABPAYMENTSHOW.PAYMENT_HISTORY || pageShow === TABPAYMENTSHOW.PAYMENT_HISTORY_DETATIL) {
      setValue('2')
    }
    if (pageShow === TABPAYMENTSHOW.PAYMENT_WAITING) {
      setValue('3')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageShow])

  return (
    <Box sx={{ width: '100%', typography: 'body1' }} className="px-6 flex-1">
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile onChange={handleChange}>
            <Tab className='font-bold text-sm' label={t("admin.payment.paymentInfluence")} value="1" />
            <Tab className='font-bold text-sm' label={t("admin.payment.historyPaymentInfluence")} value="2" />
            <Tab className='font-bold text-sm' label={t("admin.payment.waitingPayment")} value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          {pageShow === TABPAYMENTSHOW.PAYMENT_INFLUENCE &&
            <PaymentInfluentce
              setUserInfluenceId={setUserInfluenceId}
              userInfluenceId={userInfluenceId}
            />}
          {pageShow === TABPAYMENTSHOW.PAYMENT_INFLUENCE_DETAIL &&
            <PaymentInfluentceDetail
              setUserInfluenceId={setUserInfluenceId}
              userInfluenceId={userInfluenceId}

            />}
        </TabPanel>
        <TabPanel value="2">
          {pageShow === TABPAYMENTSHOW.PAYMENT_HISTORY &&
            <HistoryPaymentInfluence
              setUserInfluenceId={setUserInfluenceId}
            />
          }
          {pageShow === TABPAYMENTSHOW.PAYMENT_HISTORY_DETATIL &&
            <PaymentHistoryDetail
              setUserInfluenceId={setUserInfluenceId}
              userInfluenceId={userInfluenceId}
            />
          }
        </TabPanel>
        <TabPanel value="3">
          <WaitingPayment />
        </TabPanel>
      </TabContext>
    </Box>
  );
}