export default {
  paymentInfluence: "Payments to Influencers",
  historyPaymentInfluence: "Payment History to Influencers",
  waitingPayment: "Pending Payment from Business Operator",
  warningUpdate:
    "Do you want to update the data of the following influencers to 'Paid'?\n{{value}}",
  listPaymentInfluence: {
    totalPaymentRequest: "Payment Requests Total Amount",
    CumulativeTotalAmount: "Accumulated Total Amount",
    totalRequestPayment: "Number of Payment Requests {{value}}",
    nameInfluence: "Influencer Name",
    paymentRequest: "Request Payment",
    cumulativeRewardAmount: "Accumulated Reward Amount",
    payPalAcount: "PayPal Account Information",
    yes: "有",
    nothing: "無",
    updatePaid: "Updated as Paid",
  },
  detail: {
    totalItem: "Total Number of cases {{value}}",
    nameProjec: "Recruitment Case Name",
    CompensationAmount: "Reward Amount",
    unitPrice: "Unit Price",
    number: "Case",
    compensationDate: "Payment Confirmation Date",
  },
  listPaymentHistory: {
    paymentTotalAmount: "Total Payment Amount",
    numberOfPayment: "Number of Payments {{value}}",
    payment: "Payment Amount",
    RenewalDateToPaid: "Payment Update Date",
    changer: "Updater",
  },
  listRequestPayment: {
    WaitingForPaymentTotalAmount: "Total Pending Payment Amount",
    searchForInfluence: "Influencer Name",
    businessPerson: "Business Operator",
    waitingAmount: "Pending Payment Amount ",
    businessName: "Business Name",
  },
};