// v-002-001
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { DataGrid } from "@mui/x-data-grid";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import ictlPath from "app/ictl/route/Path";
import LayoutDefault from "app/ictl/layouts/LayoutDefault";
import CommonService from "app/services/api/CommonService";
import { ICTL_API_ENDPOINT } from "app/services/constant";
import { EResponseStatus, PER_PAGE } from "app/constant";
import { formatBusinessPersonList } from "app/utils/page/business_person";
import { removeFieldEmptyValue } from "app/utils";
import {
  SortedAscendingIcon,
  SortedDescendingIcon,
  UnsortIcon,
} from "app/components/DatagridIcon";
import { Popover } from "@mui/material";
import dayjs from "dayjs";

function BusinessPerson() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const paramSearch = new URLSearchParams(location.search);
  const [total, setTotal] = useState(0);
  const [results, setResults] = useState([]);
  const [array, setArray] = useState([]);

  const initialFilterData = {
    page: paramSearch.get("page") || 1,
    per_page: PER_PAGE,
    business_operator_name: paramSearch.get("business_operator_name") || "",
  };

  const [filterValues, setFilterValues] = useState(initialFilterData);

  // popover state
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const getListData = useCallback(() => {
    // handle change url
    const url = new URLSearchParams(removeFieldEmptyValue({ ...filterValues }));
    navigate(`?${url}`, { replace: true });

    // handle get data
    CommonService.getModelList(
      ICTL_API_ENDPOINT.GET_BUSINESS_LIST,
      removeFieldEmptyValue({ ...filterValues })
    )
      .then((response) => {
        if (response.status === EResponseStatus.SUCCESS) {
          // temp set data
          setTotal(response.data.data.total);
          setResults(formatBusinessPersonList(response.data.data.row));
        }
      })
      .catch(() => {
        // setErr(t("common.message.cannot_display_data"));
      })
      .finally(() => {
        // setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues]);

  useEffect(() => {
    getListData();
  }, [getListData]);

  const debouncedChangeHandler = _.debounce((event) => {
    setFilterValues((prev) => ({
      ...prev,
      page: 1,
      business_operator_name: event.target.value,
    }));
  }, 1000);

  const handleOpen = (event) => {
    setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const exportCSV = async () => {
    // export csv
    CommonService.postModel(ICTL_API_ENDPOINT.EXPORT_BUSINESS, {
      business_operator_name: filterValues?.business_operator_name,
      array,
    })
      .then((response) => {
        if (response.status === EResponseStatus.CREATED) {
          // temp set data
          window.location.href = response.data.data.file_url;
        }
      })
      .catch(() => {
        // setErr(t('admin.home.Enter_wrong_infomation'));
      })
      .finally(() => {
        // setIsLoading(false);
      });
    handleClose();
  };

  const onPaginationModelChange = (model) => {
    const newPage = model?.page;
    setFilterValues((prev) => ({
      ...prev,
      page: newPage + 1,
    }));
  };

  const columns = [
    {
      field: "customer_name",
      headerName: t("admin.business.list.customer_name"),
      headerClassName: "custom",
      sortable: false,
      minWidth: 400,
      flex: 1,
    },
    {
      field: "address",
      headerName: t("admin.business.list.address"),
      headerClassName: "custom",
      sortable: false,
      minWidth: 400,
      flex: 2,
    },
    {
      field: "invoice_number",
      headerName: t("admin.business.list.invoice_number"),
      headerClassName: "custom",
      sortable: false,
      width: 250,
    },
    {
      field: "count_matching",
      headerName: t("admin.business.list.count_matching"),
      sortable: false,
      headerClassName: "custom",
      width: 250,
    },
    {
      field: "created",
      headerName: t("admin.business.list.created"),
      headerClassName: "custom",
      sortable: false,
      width: 200,
      valueGetter: (params) => {
        const created = params?.row?.created;
        return created
          ? dayjs(created).format(`${t("common.date_format.yyyy_mm_dd")}`)
          : null;
      },
    },
    {
      field: "setting",
      align: "center",
      headerAlign: "center",
      headerClassName: "custom",
      width: 150,
      sortable: false,
      headerName: t("admin.business.list.warning"),
      // renderHeader: () => {
      //   return (
      //     <button
      //       type="button"
      //       className="w-8 h-8 flex items-center justify-center"
      //     >
      //       <SettingsOutlinedIcon fontSize="small" />
      //     </button>
      //   );
      // },
      renderCell: (params) => {
        if (params?.row?.payment_status === 1) {
          return (
            <button
              type="button"
              className="w-8 h-8 flex items-center justify-center"
            >
              <ErrorOutlineIcon fontSize="small" className="text-red-600" />
            </button>
          );
        }
        return "";
      },
    },
  ];

  return (
    <LayoutDefault showFooter showHeader pin={2}>
      <div className="px-6 flex-1">
        <div className="flex flex-wrap justify-between items-center my-5 gap-3">
          <p className="text-3xl font-bold">
            {t("admin.business.list.total_business", { value: total })}
          </p>
          <div className="flex flex-row flex-wrap justify-between items-center gap-5">
            <button
              type="button"
              className="border border-secondary-200 rounded-[4px] px-3 py-[6px] flex items-center justify-between font-bold gap-2 relative min-w-[160px]"
            >
              <p>{t("common.btn.export")}</p>
              <i className="text-[14px] fa-solid fa-angle-down" />
              <div
                role="presentation"
                onClick={handleOpen}
                className="absolute bottom-0 right-0 left-0 top-0"
              />
            </button>
            <Button
              variant="outlined"
              color="inherit"
              sx={{ marginRight: 2 }}
              onClick={() => navigate(ictlPath.business_person_create)}
            >
              <i className="fa-solid fa-plus mr-1" />
              {t("admin.business.list.create_staff")}
            </Button>
          </div>
        </div>
        {/* Search Group */}
        <FormControl size="small" className="max-w-lg">
          <OutlinedInput
            sx={{
              borderRadius: 40,
              height: 36,
            }}
            id="outlined-adornment-weight"
            defaultValue={filterValues?.business_operator_name}
            onChange={(event) => debouncedChangeHandler(event)}
            placeholder={t("admin.business.list.find_customer")}
            aria-describedby="outlined-weight-helper-text"
            startAdornment={
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            }
          />
        </FormControl>

        <div className="w-full py-6">
          <DataGrid
            autoHeight
            rows={results}
            columns={columns}
            rowCount={total}
            paginationMode="server"
            onRowSelectionModelChange={(ids) => {
              setArray(ids);
            }}
            checkboxSelection
            disableColumnMenu
            slots={{
              columnUnsortedIcon: UnsortIcon,
              columnSortedDescendingIcon: SortedDescendingIcon,
              columnSortedAscendingIcon: SortedAscendingIcon,
            }}
            keepNonExistentRowsSelected
            getRowClassName={() => "cursor-pointer"}
            onRowClick={(params, event) => {
              event.preventDefault();
              navigate(ictlPath.business_person_edit(params?.id));
            }}
            pageSizeOptions={[PER_PAGE]}
            paginationModel={{
              pageSize: PER_PAGE,
              page: filterValues.page - 1,
            }}
            onPaginationModelChange={(model) => {
              onPaginationModelChange(model);
            }}
            localeText={{
              noResultsOverlayLabel: t("common.message.no_data_display"),
              noRowsLabel: t("common.message.no_data_display"),
            }}
          />
        </div>

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div className="min-w-[200px] py-3">
            <button
              type="button"
              onClick={exportCSV}
              className="text-left w-full px-3 py-2"
            >
              {t("common.btn.download_csv")}
            </button>
          </div>
        </Popover>
      </div>
    </LayoutDefault>
  );
}

export default BusinessPerson;
