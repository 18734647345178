import React, { useState, useEffect } from "react";
import useQuery from "app/hooks/useQuery";
import Tab0 from "./Tab0";
import Tab2 from "./Tab2";
import Tab4 from "./Tab4";
import Tab5 from "./Tab5";
import Tab8 from "./Tab8";
import Tab9 from "./Tab9";

const numberOfTabs = 8;

function ProvideHost() {
  const query = useQuery();
  const [tabsData, setTabsData] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    const tabQuery = query.get("tab");
    if (tabQuery) {
      setCurrentTab(parseInt(tabQuery, 10));
    }
  }, [query]);

  const setTabData = (tab, tabData) => {
    const newTabData = [...tabsData];
    newTabData[tab] = tabData;
    setTabsData(newTabData);
  };

  if (currentTab === 0) {
    return (
      <Tab0
        tabData={tabsData[currentTab]}
        setTabData={(tabData) => setTabData(currentTab, tabData)}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        numberOfTabs={numberOfTabs}
      />
    );
  }

  if (currentTab === 1) {
    return (
      <Tab2
        tabData={tabsData[currentTab]}
        setTabData={(tabData) => setTabData(currentTab, tabData)}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        numberOfTabs={numberOfTabs}
      />
    );
  }

  if (currentTab === 2) {
    return (
      <Tab4
        tabData={tabsData[currentTab]}
        setTabData={(tabData) => setTabData(currentTab, tabData)}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        numberOfTabs={numberOfTabs}
      />
    );
  }

  if (currentTab === 3) {
    return (
      <Tab5
        tabData={tabsData[currentTab]}
        setTabData={(tabData) => setTabData(currentTab, tabData)}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        numberOfTabs={numberOfTabs}
      />
    );
  }

  if (currentTab === 4) {
    return (
      <Tab8
        tabsData={tabsData}
        setTabData={(tabData) => setTabData(currentTab, tabData)}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        numberOfTabs={numberOfTabs}
      />
    );
  }

  if (currentTab === 5 && tabsData && tabsData[4]) {
    return (
      <Tab9
        tabData={tabsData[currentTab]}
        setTabData={(tabData) => setTabData(currentTab, tabData)}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        numberOfTabs={numberOfTabs}
      />
    );
  }
}
export default ProvideHost;
