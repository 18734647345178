import React, { useContext } from "react";
import EditableGroup, {
  EDIT_BTN_POSITION,
} from "app/operator/staff/pages/BookingDetail/EditableGroup";
import { useTranslation } from "react-i18next";
import {
  editProjectSchema,
  MAX_LENGTH_100,
  MAX_LENGTH_200,
  MAX_LENGTH_2000,
  MAX_LENGTH_5000,
} from "app/utils/schema/operator";
import { ProjectDetailContext } from "app/operator/pages/AdvertisementManage/AdvertisementDetail";
import FormItem, { TYPE_INPUT } from "app/operator/components/FormItem";
import {
  changeGeneration,
  facilityIconStatus, facilityStatus,
  formatNumber,
  generateSNSIcon,
  projectRewardType
} from "app/utils";
import moment from "moment";
import EditableCollapse from "app/components/EditableCollapse";
import {
  EProjectStatus,
  EProjectReward,
  onlyNumberRegex,
  EProjectPrMethod,
  EProjectSNS,
  FORMAT_DATE,
  GENDER,
  ageGroupLst,
} from "../../../../../constant";
import DateForm from "../../DateForm";

function CommonInfo() {
  const { t,i18n } = useTranslation();
  const { formValues, setFormValues, onEditProject } =
    useContext(ProjectDetailContext);
  const projectSNSList = Object.values(EProjectSNS)?.map((value) => ({
    value,
    optionName: (
      <div className="flex items-center">
        {generateSNSIcon(value)}
        <div className="capitalize">{ value === 'twitter' ? t("media.twitter") : value }</div>
      </div>
    ),
  }));

  const projectStatusOptionList = Object.values(EProjectStatus)?.map(
    (status) => ({
      value: status,
      optionName: (
        <>
          {facilityIconStatus(status)}
          {facilityStatus(status, t)}
        </>
      ),
    })
  );

  const lstGender = [
    {
      value: GENDER.MALE,
      optionName: t(`common.male`),
    },
    {
      value: GENDER.FEMALE,
      optionName: t(`common.female`),
    },
    {
      value: GENDER.OTHER,
      optionName: t(`common.other`),
    },
  ];

  const projectRewardOptionList = Object.values(EProjectReward)?.map(
    (type) => ({
      value: type,
      optionName: <>{projectRewardType(type)}</>,
    })
  );

  const prMethodLst = Object.values(EProjectPrMethod)?.map((type) => ({
    value: type,
    optionName: t(`operator.advertisement.detail.${type}`),
  }));

  const onSubmitValue = (e) => {
    setFormValues(e);
    onEditProject(e);
  };

  const handleDisableItem = (type, value, targetValue) => {
    if (type === "start") {
      if (targetValue && value > targetValue) {
        return true;
      }
      return false;
    }
    if (targetValue && value < targetValue) {
      return true;
    }
    return false;
  };

  const renderEditFormProjectTitle = ({ values }) => {
    return (
      <FormItem
        name="projectTitle"
        showCharCount
        maxLength={MAX_LENGTH_100}
        typeInput={TYPE_INPUT.TEXT}
        defaultLength={values.projectTitle?.length}
        description={t("operator.advertisement.detail.title_description")}
        descriptionClassName="text-gray my-2"
        label={t("operator.advertisement.detail.project_title")}
        placeholder={t("common.message.no_input_yet")}
        // space
        labelClassName="font-semibold"
        inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        containerClassName="mb-[16px]"
      />
    );
  };

  const renderEditFormProjectTitleEN = ({ values }) => {
    return (
      <FormItem
        name="projectTitleEN"
        showCharCount
        maxLength={MAX_LENGTH_100}
        typeInput={TYPE_INPUT.TEXT}
        defaultLength={values.projectTitleEN?.length}
        description={t("operator.advertisement.detail.title_description")}
        descriptionClassName="text-gray my-2"
        label={t("operator.advertisement.detail.collapse_en_label")}
        placeholder={t("common.message.no_input_yet")}
        // space
        labelClassName="font-semibold"
        inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        containerClassName="mb-[16px]"
      />
    );
  };

  const renderEditFormProjectTitleCN = ({ values }) => {
    return (
      <FormItem
        name="projectTitleCN"
        showCharCount
        maxLength={MAX_LENGTH_100}
        typeInput={TYPE_INPUT.TEXT}
        defaultLength={values.projectTitleCN?.length}
        description={t("operator.advertisement.detail.title_description")}
        descriptionClassName="text-gray my-2"
        label={t("operator.advertisement.detail.collapse_cn_label")}
        placeholder={t("common.message.no_input_yet")}
        // space
        labelClassName="font-semibold"
        inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        containerClassName="mb-[16px]"
      />
    );
  };

  const renderEditFormProjectDescription = ({ values }) => {
    return (
      <FormItem
        name="projectDescription"
        showCharCount
        maxLength={MAX_LENGTH_5000}
        typeInput={TYPE_INPUT.TEXT_AREA}
        textAreaClassName="resize-none h-[200px]"
        defaultLength={values.projectDescription?.length}
        label={t("operator.advertisement.detail.project_description")}
        placeholder={t("common.message.no_input_yet")}
        // space
        labelClassName="font-semibold mb-2"
        inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        containerClassName="mb-[16px]"
      />
    );
  };

  const renderEditFormProjectDescriptionEN = ({ values }) => {
    return (
      <FormItem
        name="projectDescriptionEN"
        showCharCount
        maxLength={MAX_LENGTH_5000}
        typeInput={TYPE_INPUT.TEXT_AREA}
        textAreaClassName="resize-none h-[200px]"
        defaultLength={values.projectDescriptionEN?.length}
        label={t("operator.advertisement.detail.en_descrip_label")}
        placeholder={t("common.message.no_input_yet")}
        // space
        labelClassName="font-semibold mb-2"
        inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        containerClassName="mb-[16px]"
      />
    );
  };

  const renderEditFormProjectDescriptionCN = ({ values }) => {
    return (
      <FormItem
        name="projectDescriptionCN"
        showCharCount
        maxLength={MAX_LENGTH_5000}
        typeInput={TYPE_INPUT.TEXT_AREA}
        textAreaClassName="resize-none h-[200px]"
        defaultLength={values.renderEditFormProjectDescriptionCN?.length}
        label={t("operator.advertisement.detail.cn_descrip_label")}
        placeholder={t("common.message.no_input_yet")}
        // space
        labelClassName="font-semibold mb-2"
        inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        containerClassName="mb-[16px]"
      />
    );
  };

  const renderEditFormPublicStatus = () => {
    return (
      <>
        <FormItem
          disabledOnChange={formValues?.is_influencer_applied}
          name="projectStatus"
          label={t("operator.advertisement.detail.project_status")}
          labelClassName="font-semibold mb-2"
          placeholder={t("common.message.no_input_yet")}
          containerClassName="mb-[16px]"
          typeInput={TYPE_INPUT.RADIO}
          componentProps={{
            defaultValue: formValues?.projectStatus,
            optionsList: projectStatusOptionList,
          }}
        />
        {formValues?.is_influencer_applied && (
          <div className="text-[14px] text-danger">
            {t("operator.advertisement.detail.already_applied")}
          </div>
        )}
      </>
    );
  };

  const renderEditFormRewardType = () => {
    return (
      <FormItem
        stringValue
        name="projectRewardType"
        label={t("operator.advertisement.detail.reward_type")}
        labelClassName="font-semibold mb-2"
        placeholder={t("common.message.no_input_yet")}
        containerClassName="mb-[16px]"
        typeInput={TYPE_INPUT.RADIO}
        componentProps={{
          defaultValue: formValues?.projectRewardType,
          optionsList: projectRewardOptionList,
        }}
      />
    );
  };

  const renderEditFormReward = ({ values }) => {
    return (
      <FormItem
        disabled={values?.projectRewardType !== EProjectReward.FIXED_PRICE}
        name="projectReward"
        label={t("operator.advertisement.detail.reward")}
        placeholder={t("common.message.no_input_yet")}
        // space
        labelClassName="font-semibold mb-2"
        inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        containerClassName="mb-[16px]"
        typeInput={TYPE_INPUT.NUMBER}
        regex={onlyNumberRegex}
        showCharCount
        maxLength={8}
        defaultLength={values.projectReward?.length}
      />
    );
  };

  const renderEditFormRewardCondition = ({ values }) => {
    return (
      <div>
        <FormItem
          name="desiredCondition"
          showCharCount
          maxLength={MAX_LENGTH_200}
          typeInput={TYPE_INPUT.TEXT_AREA}
          textAreaClassName="resize-none h-[200px]"
          defaultLength={values.desiredCondition?.length}
          label={t("operator.advertisement.detail.desired_condition")}
          placeholder={t("common.message.no_input_yet")}
          // space
          labelClassName="font-semibold mb-2"
          inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
          containerClassName="mb-[16px]"
        />
      </div>
    );
  };

  const renderEditFormRewardConditionEN = ({ values }) => {
    return (
      <div>
        <FormItem
          name="desiredConditionEN"
          showCharCount
          maxLength={MAX_LENGTH_200}
          typeInput={TYPE_INPUT.TEXT_AREA}
          textAreaClassName="resize-none h-[200px]"
          defaultLength={values.desiredConditionEN?.length}
          label={t("operator.advertisement.detail.en_condition_label")}
          placeholder={t("common.message.no_input_yet")}
          // space
          labelClassName="font-semibold mb-2"
          inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
          containerClassName="mb-[16px]"
        />
      </div>
    );
  };

  const renderEditFormRewardConditionCN = ({ values }) => {
    return (
      <div>
        <FormItem
          name="desiredConditionCN"
          showCharCount
          maxLength={MAX_LENGTH_200}
          typeInput={TYPE_INPUT.TEXT_AREA}
          textAreaClassName="resize-none h-[200px]"
          defaultLength={values.desiredConditionCN?.length}
          label={t("operator.advertisement.detail.cn_condition_label")}
          placeholder={t("common.message.no_input_yet")}
          // space
          labelClassName="font-semibold mb-2"
          inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
          containerClassName="mb-[16px]"
        />
      </div>
    );
  };

  const renderEditFormDatePicker = (context) => {
    const { values, setFieldValue } = context;

    return (
      <DateForm values={values} setFieldValue={setFieldValue} showCheckbox />
    );
  };

  const renderEditFormPRMethod = () => {
    return (
      <FormItem
        stringValue
        name="adMethod"
        label={t("operator.advertisement.detail.ad_method")}
        labelClassName="font-semibold mb-2"
        placeholder={t("common.message.no_input_yet")}
        containerClassName="mb-[16px]"
        typeInput={TYPE_INPUT.RADIO}
        componentProps={{
          defaultValue: formValues?.adMethod,
          optionsList: prMethodLst,
        }}
      />
    );
  };

  const renderEditFormTagInclude = ({ values }) => {
    return (
      <FormItem
        name="tagInclude"
        showCharCount
        maxLength={MAX_LENGTH_100}
        typeInput={TYPE_INPUT.TEXT}
        defaultLength={values.tagInclude?.length}
        label={t("operator.advertisement.detail.tagInclude")}
        placeholder={t("common.message.no_input_yet")}
        // space
        labelClassName="font-semibold mb-2"
        inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        containerClassName="mb-[16px]"
      />
    );
  };

  const renderEditFormTargetUrl = ({ values }) => {
    return (
      <FormItem
        name="targetUrl"
        showCharCount
        maxLength={MAX_LENGTH_2000}
        typeInput={TYPE_INPUT.TEXT_AREA}
        textAreaClassName="resize-none h-[200px]"
        defaultLength={values.targetUrl?.length}
        label={t("operator.advertisement.detail.targetUrl")}
        placeholder={t("common.message.no_input_yet")}
        // space
        labelClassName="font-semibold mb-2"
        inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        containerClassName="mb-[16px]"
      />
    );
  };

  const renderEditFormGender = () => {
    return (
      <FormItem
        stringValue
        name="gender"
        label={t("operator.advertisement.detail.influencer_gender")}
        labelClassName="font-semibold mb-2"
        placeholder={t("common.message.no_input_yet")}
        containerClassName="mb-[16px]"
        selectClassName="!min-w-[100px]"
        typeInput={TYPE_INPUT.RADIO}
        componentProps={{
          defaultValue: formValues?.gender,
          optionsList: lstGender,
        }}
      />
    );
  };

  const renderEditFormSNSTarget = () => {
    return (
      <FormItem
        stringValue
        name="adMedia"
        label={t("operator.advertisement.detail.adMedia")}
        labelClassName="font-semibold mb-2"
        placeholder={t("common.message.no_input_yet")}
        containerClassName="mb-[16px]"
        typeInput={TYPE_INPUT.CHECKBOX}
        componentProps={{
          defaultValue: formValues?.adMedia ,
          optionsList: projectSNSList,
        }}
      />
    );
  };

  const renderEditFormMinFollowers = ({ values }) => {
    return (
      <FormItem
        name="minFollowers"
        label={t("operator.advertisement.detail.min_followers")}
        placeholder={t("common.message.no_input_yet")}
        // space
        labelClassName="font-semibold mb-2"
        inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        containerClassName="mb-[16px]"
        typeInput={TYPE_INPUT.NUMBER}
        regex={onlyNumberRegex}
        showCharCount
        maxLength={8}
        defaultLength={values.minFollowers?.length}
      />
    );
  };

  const renderEditFormTargetUsername = ({ values }) => {
    return (
      <FormItem
        name="targetUsername"
        showCharCount
        maxLength={MAX_LENGTH_100}
        typeInput={TYPE_INPUT.TEXT}
        defaultLength={values.targetUsername?.length}
        label={t("operator.advertisement.detail.targetUsername")}
        placeholder={t("common.message.no_input_yet")}
        // space
        labelClassName="font-semibold mb-2"
        inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        containerClassName="mb-[16px]"
      />
    );
  };

  const renderEditFormTextInclude = ({ values }) => {
    return (
      <FormItem
        name="textInclude"
        showCharCount
        maxLength={MAX_LENGTH_5000}
        typeInput={TYPE_INPUT.TEXT_AREA}
        textAreaClassName="resize-none h-[200px]"
        defaultLength={values.textInclude?.length}
        label={t("operator.advertisement.detail.textInclude")}
        placeholder={t("common.message.no_input_yet")}
        // space
        labelClassName="font-semibold mb-2"
        inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        containerClassName="mb-[16px]"
      />
    );
  };

  const renderEditFormTextIncludeEN = ({ values }) => {
    return (
      <FormItem
        name="textIncludeEN"
        showCharCount
        maxLength={MAX_LENGTH_5000}
        typeInput={TYPE_INPUT.TEXT_AREA}
        textAreaClassName="resize-none h-[200px]"
        defaultLength={values.textIncludeEN?.length}
        label={t("operator.advertisement.detail.en_include_label")}
        placeholder={t("common.message.no_input_yet")}
        // space
        labelClassName="font-semibold mb-2"
        inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        containerClassName="mb-[16px]"
      />
    );
  };

  const renderEditFormTextIncludeCN = ({ values }) => {
    return (
      <FormItem
        name="textIncludeCN"
        showCharCount
        maxLength={MAX_LENGTH_5000}
        typeInput={TYPE_INPUT.TEXT_AREA}
        textAreaClassName="resize-none h-[200px]"
        defaultLength={values.textIncludeCN?.length}
        label={t("operator.advertisement.detail.cn_include_label")}
        placeholder={t("common.message.no_input_yet")}
        // space
        labelClassName="font-semibold mb-2"
        inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        containerClassName="mb-[16px]"
      />
    );
  };

  const renderEditFormAgeGroup = () => {
    return (
      <>
        <div className="font-semibold mb-2">
          {t("operator.advertisement.detail.age_group")}
        </div>
        <div className="flex flex-col md:flex-row gap-4 md:gap-10 items-baseline">
          <FormItem
            touchedField="ageGroupEnd"
            selectClassName="w-[200px] h-[39px] rounded-[8px]"
            name="ageGroupStart"
            typeInput={TYPE_INPUT.SELECT}
            componentProps={{
              defaultValue: formValues?.ageGroupStart,
              optionsList: ageGroupLst,
            }}
            containerClassName="md:mb-5"
            selectType="start"
            handleDisableSelectItem={(value) =>
              handleDisableItem("start", value, formValues?.ageGroupEnd)
            }
          />
          <div className="text-center w-[200px] md:w-[auto]">～</div>
          <FormItem
            touchedField="ageGroupStart"
            selectClassName="w-[200px] h-[39px] rounded-[8px]"
            name="ageGroupEnd"
            typeInput={TYPE_INPUT.SELECT}
            componentProps={{
              defaultValue: formValues?.ageGroupEnd,
              optionsList: ageGroupLst,
            }}
            containerClassName="md:mb-5"
            handleDisableSelectItem={(value) =>
              handleDisableItem("end", value, formValues?.ageGroupStart)
            }
          />
        </div>
      </>
    );
  };

  const renderEditFormOfferPrice = ({ values }) => {
    return (
      <FormItem
        disabled={values?.projectRewardType !== EProjectReward.FREE}
        name="offerPrice"
        label={t("operator.advertisement.detail.offer_price")}
        placeholder={t("common.message.no_input_yet")}
        // space
        labelClassName="font-semibold"
        inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        description={t("operator.advertisement.detail.offer_price_description")}
        descriptionClassName="text-gray my-2"
        containerClassName="mb-[16px]"
        typeInput={TYPE_INPUT.NUMBER}
        regex={onlyNumberRegex}
        showCharCount
        maxLength={8}
        defaultLength={values.offerPrice?.length}
      />
    );
  };

  return (
    <>
      <EditableGroup
        required
        editForm={renderEditFormProjectTitle}
        displayValues={
          <div className="pl-[8px] text-gray font-semibold">
            <div className="word-break">{formValues?.projectTitle}</div>
          </div>
        }
        validationSchema={editProjectSchema(t)?.projectTitle}
        // space
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item"
        label={t("operator.advertisement.detail.project_title")}
        containerBorder={false}
      />

      <EditableCollapse
        editForm={renderEditFormProjectTitleEN}
        validationSchema={editProjectSchema(t)?.projectTitleEN}
        // space
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item pt-0"
        collapseLabel={t("operator.advertisement.detail.collapse_en_title")}
        collapseClassName="mt-1"
        containerBorder={false}
      />

      <EditableCollapse
        editForm={renderEditFormProjectTitleCN}
        validationSchema={editProjectSchema(t)?.projectTitleCN}
        // space
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item pt-0"
        collapseLabel={t("operator.advertisement.detail.collapse_cn_title")}
        collapseClassName="mt-1"
      />

      <EditableGroup
        required
        editForm={renderEditFormProjectDescription}
        displayValues={
          <div className="pl-[8px] text-gray font-semibold">
            <div className="word-break whitespace-pre-wrap max-h-[200px] overflow-auto">
              {formValues?.projectDescription}
            </div>
          </div>
        }
        validationSchema={editProjectSchema(t)?.projectDescription}
        // space
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item"
        label={t("operator.advertisement.detail.project_description")}
        containerBorder={false}
      />

      <EditableCollapse
        editForm={renderEditFormProjectDescriptionEN}
        validationSchema={editProjectSchema(t)?.projectDescriptionEN}
        // space
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item pt-0"
        collapseLabel={t("operator.advertisement.detail.collapse_en_title")}
        collapseClassName="mt-1"
        containerBorder={false}
      />

      <EditableCollapse
        editForm={renderEditFormProjectDescriptionCN}
        validationSchema={editProjectSchema(t)?.projectDescriptionCN}
        // space
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item pt-0"
        collapseLabel={t("operator.advertisement.detail.collapse_cn_title")}
        collapseClassName="mt-1"
      />

      <EditableGroup
        required
        editForm={renderEditFormPublicStatus}
        displayValues={
          <div className="pl-[8px] text-gray font-semibold">
            {facilityIconStatus(parseInt(formValues?.projectStatus, 10))}
            {facilityStatus(parseInt(formValues?.projectStatus, 10), t)}
          </div>
        }
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        validationSchema={editProjectSchema(t)?.projectStatus}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item"
        label={t("operator.advertisement.detail.project_status")}
      />

      <EditableGroup
        required
        editForm={renderEditFormRewardType}
        displayValues={
          <div className="pl-[8px] text-gray font-semibold">
            {projectRewardType(formValues?.projectRewardType)}
          </div>
        }
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        validationSchema={editProjectSchema(t)?.projectRewardType}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item"
        label={t("operator.advertisement.detail.reward_type")}
      />

      <EditableGroup
        required
        editForm={renderEditFormReward}
        displayValues={
          <div className="pl-[8px] text-gray font-semibold">
            <div className="word-break">
              {formValues?.projectReward
                ? `${t("operator.advertisement.detail.price")} ${formatNumber(
                    formValues?.projectReward
                  )}`
                : ""}
            </div>
          </div>
        }
        validationSchema={editProjectSchema(t)?.projectReward}
        // space
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item"
        label={t("operator.advertisement.detail.reward")}
      />

      <EditableGroup
        required
        editForm={renderEditFormRewardCondition}
        displayValues={
          <div className="pl-[8px] text-gray font-semibold">
            <div className="flex items-center word-break whitespace-pre-wrap">
              {formValues.desiredCondition}
            </div>
          </div>
        }
        validationSchema={editProjectSchema(t)?.desiredCondition}
        // space
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item"
        label={t("operator.advertisement.detail.desired_condition")}
        containerBorder={false}
      />

      <EditableCollapse
        editForm={renderEditFormRewardConditionEN}
        validationSchema={editProjectSchema(t)?.desiredConditionEN}
        // space
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item pt-0"
        collapseLabel={t("operator.advertisement.detail.collapse_en_title")}
        collapseClassName="mt-1"
        containerBorder={false}
      />

      <EditableCollapse
        editForm={renderEditFormRewardConditionCN}
        validationSchema={editProjectSchema(t)?.desiredConditionCN}
        // space
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item pt-0"
        collapseLabel={t("operator.advertisement.detail.collapse_cn_title")}
        collapseClassName="mt-1"
      />

      <EditableGroup
        required
        editForm={renderEditFormDatePicker}
        displayValues={
          <div className="pl-[8px] text-gray font-semibold">
            {!formValues?.recuitmentDate?.unspecified_date &&
            formValues?.recuitmentDate?.startDate &&
            formValues?.recuitmentDate?.endDate ? (
              <div className="flex items-center">
                {moment
                  .utc(formValues?.recuitmentDate?.startDate)
                  .format(FORMAT_DATE.YYYY_MMMM_Do)}
                ~
                {moment
                  .utc(formValues?.recuitmentDate?.endDate)
                  .format(FORMAT_DATE.YYYY_MMMM_Do)}
              </div>
            ) : (
              t("common.other1")
            )}
          </div>
        }
        // space
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item"
        label={t("operator.advertisement.detail.recuitmentDate")}
      />

      <EditableGroup
        required
        editForm={renderEditFormPRMethod}
        displayValues={
          <div className="pl-[8px] text-gray font-semibold">
            {t(`operator.advertisement.detail.${formValues.adMethod}`)}
          </div>
        }
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        validationSchema={editProjectSchema(t)?.adMethod}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item"
        label={t("operator.advertisement.detail.ad_method")}
      />

      <EditableGroup
        editForm={renderEditFormTagInclude}
        displayValues={
          <div className="pl-[8px] text-gray font-semibold">
            <div className="word-break">
              {(() => {
                const tagInclude = formValues?.tagInclude?.replace(
                  /\s*,\s*/g,
                  ","
                ); // trim space after comma
                const array = tagInclude?.split(",");
                const prefix = "#";
                const arrayWithPrefix = array?.map((el) => prefix + el);
                return tagInclude ? arrayWithPrefix?.join(", ") : "";
              })()}
            </div>
          </div>
        }
        validationSchema={editProjectSchema(t)?.tagInclude}
        // space
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item"
        label={t("operator.advertisement.detail.tagInclude")}
      />

      <EditableGroup
        editForm={renderEditFormTargetUrl}
        displayValues={
          <div className="pl-[8px] text-gray font-semibold">
            <div className="word-break whitespace-pre-wrap max-h-[200px] overflow-auto">
              {formValues?.targetUrl}
            </div>
          </div>
        }
        validationSchema={editProjectSchema(t)?.targetUrl}
        // space
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item"
        label={t("operator.advertisement.detail.targetUrl")}
      />

      <EditableGroup
        required
        editForm={renderEditFormGender}
        displayValues={
          <div className="pl-[8px] text-gray font-semibold">
            <div>{t(`common.${formValues.gender}`)}</div>
          </div>
        }
        validationSchema={editProjectSchema(t)?.gender}
        // space
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item"
        label={t("operator.advertisement.detail.influencer_gender")}
      />

      <EditableGroup
        required
        editForm={renderEditFormSNSTarget}
        displayValues={
          formValues?.adMedia?.map((item) => {
            return <div key={`media-key-${item}`} className="pl-[8px] text-gray font-semibold">
            <div className="flex items-center">
              {generateSNSIcon(item)}
              <div className="capitalize">{ item === 'twitter' ? t("media.twitter") : item }</div>
            </div>
          </div>
          })
        }
        validationSchema={editProjectSchema(t)?.adMedia}
        // space
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item"
        label={t("operator.advertisement.detail.adMedia")}
      />

      <EditableGroup
        editForm={renderEditFormMinFollowers}
        displayValues={
          <div className="pl-[8px] text-gray font-semibold word-break">
            <div>{formatNumber(formValues?.minFollowers)}</div>
          </div>
        }
        validationSchema={editProjectSchema(t)?.minFollowers}
        // space
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item"
        label={t("operator.advertisement.detail.min_followers")}
      />

      <EditableGroup
        editForm={renderEditFormTargetUsername}
        displayValues={
          <div className="pl-[8px] text-gray font-semibold word-break">
            {(() => {
              const targetUsername = formValues?.targetUsername?.replace(
                /\s*,\s*/g,
                ","
              ); // trim space after comma
              const array = targetUsername?.split(",");
              const prefix = "@";
              const arrayWithPrefix = array?.map((el) => prefix + el);
              return targetUsername ? arrayWithPrefix?.join(", ") : "";
            })()}
          </div>
        }
        validationSchema={editProjectSchema(t)?.targetUsername}
        // space
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item"
        label={t("operator.advertisement.detail.targetUsername")}
      />

      <EditableGroup
        editForm={renderEditFormTextInclude}
        displayValues={
          <div className="pl-[8px] text-gray font-semibold">
            <div className="word-break whitespace-pre-wrap max-h-[200px] overflow-auto">
              {formValues?.textInclude}
            </div>
          </div>
        }
        validationSchema={editProjectSchema(t)?.textInclude}
        // space
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item"
        label={t("operator.advertisement.detail.textInclude")}
        containerBorder={false}
      />

      <EditableCollapse
        editForm={renderEditFormTextIncludeEN}
        validationSchema={editProjectSchema(t)?.textIncludeEN}
        // space
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item pt-0"
        collapseLabel={t("operator.advertisement.detail.collapse_en_title")}
        collapseClassName="mt-1"
        containerBorder={false}
      />

      <EditableCollapse
        editForm={renderEditFormTextIncludeCN}
        validationSchema={editProjectSchema(t)?.textIncludeCN}
        // space
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item pt-0"
        collapseLabel={t("operator.advertisement.detail.collapse_cn_title")}
        collapseClassName="mt-1"
      />

      <EditableGroup
        editForm={renderEditFormAgeGroup}
        displayValues={
          <div className="pl-[8px] text-gray font-semibold">
            {(() => {
              if (formValues?.ageGroupStart && formValues?.ageGroupEnd) {
                return `${changeGeneration(formValues?.ageGroupStart, i18n.language)}～${changeGeneration(formValues?.ageGroupEnd, i18n.language)}`;
              }
              return "";
            })()}
          </div>
        }
        validationSchema={editProjectSchema(t)?.ageGroup}
        // space
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item"
        label={t("operator.advertisement.detail.age_group")}
      />

      <EditableGroup
        editForm={renderEditFormOfferPrice}
        displayValues={
          <div className="pl-[8px] text-gray font-semibold">
            <div className="word-break">
              {formValues?.offerPrice
                ? `${t("operator.advertisement.detail.price")} ${formatNumber(
                    formValues?.offerPrice
                  )}`
                : ""}
            </div>
          </div>
        }
        validationSchema={editProjectSchema(t)?.offerPrice}
        // space
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item"
        label={t("operator.advertisement.detail.offer_price")}
      />
    </>
  );
}

export default CommonInfo;
