import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import { MEDIA_TYPE } from "../enum/ChatEnum";

function MessageFileIcon({ mediaType, thumbUrl, mediaUrl, fileName }) {
  const [open, setOpen] = useState(false);

  const handleThumbClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openNewTab = () => {
    const a = document.createElement("a");
    a.href = mediaUrl;
    a.setAttribute("target", "_blank");
    a.setAttribute("download", fileName);
    a.click();
    a.remove();
  };

  return (
    <>
      {mediaType === MEDIA_TYPE.IMAGE ? (
        <div className="flex justify-center items-center">
          <img
            role="presentation"
            alt="thumbnail"
            className="rounded-md w-full"
            src={thumbUrl}
            onClick={handleThumbClick}
          />
        </div>
      ) : (
        <div className="message-content bg-gray-200 rounded-md p-2">
          <i className="fa-solid text-blue-600 fa-file-pdf text-[1.3rem] py-1 pl-1 pr-2" />
          <span
            role="presentation"
            className="text-[12px] md:text-[14px] break-all whitespace-pre-wrap text-blue-600 hover:underline cursor-pointer"
            onClick={openNewTab}
          >
            {fileName}
          </span>
        </div>
      )}
      <Dialog onClose={handleClose} open={open} maxWidth="2xl">
        <img
          role="presentation"
          alt="thumbnail"
          className="max-h-[90vh]"
          src={mediaUrl}
        />
      </Dialog>
    </>
  );
}

export default MessageFileIcon;
