/* eslint-disable class-methods-use-this */
import client from "../client";

const baseUrl = "cms/facility";

class FacilityService {
  getAllFacility() {
    return client.get(`${baseUrl}`);
  }
  
  createFacility(body) {
    return client.post(`${baseUrl}/store-facility`, body);
  }

  storeServiceProviderAndFacility(body) {
    return client.post(`${baseUrl}/store-service-provider-and-facility`, body);
  }

  storeServiceProvider(body) {
    return client.post(`cms/business-operator/store-business-operator`, body);
  }
}

export default new FacilityService();
