import React, { useMemo, useState } from "react";
import Footer from "app/components/Footer";
import Header from "app/operator/components/Header";
import ChangeLanguage from "app/components/ChangeLanguage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LayoutContext } from "../../utils";

function LayoutDefault({ children, showFooter, showHeader }) {
  const [isChangeLangOpen, setIsChangeLangOpen] = useState(false);

  return (
    <LayoutContext.Provider
      value={useMemo(
        () => ({ isChangeLangOpen, setIsChangeLangOpen }),
        [isChangeLangOpen, setIsChangeLangOpen]
      )}
    >
      <div className="flex flex-col min-h-screen">
        {showHeader && <Header />}

        {children}

        {showFooter && <Footer />}

        <ChangeLanguage />

        <ToastContainer
          position="bottom-left"
          autoClose={1500}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
          limit={3}
        />
      </div>
    </LayoutContext.Provider>
  );
}

export default LayoutDefault;
