import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

function ConfirmDialog({ children, open, onClose, onOK }) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: "min-h-[450px] !rounded-[12px] !w-[500px]" }}
    >
      <DialogActions className="!px-4 !justify-center relative">
        <button
          type="button"
          className="w-8 h-8 flex items-center justify-center absolute left-0"
          onClick={onClose}
        >
          <i className="fa-solid fa-xmark" />
        </button>
        <p className="font-semibold">{t("common.action.confirm")}</p>
      </DialogActions>
      <DialogContent className="!flex flex-col">{children}</DialogContent>
      <DialogActions className="!justify-between border-t border-border !px-4">
        <button
          type="button"
          onClick={onClose}
          className="underline font-[700]"
        >
          {t("common.action.cancel")}
        </button>
        <Button
          onClick={onOK}
          className="!text-white !min-w-[70px] !rounded-[4px] !py-2"
          classes={{
            root: "!bg-black",
          }}
        >
          {t("common.action.send")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;

// 応島承認を却下しますか?
// 超下する場合「送個」ボタンをクリックしてください。
