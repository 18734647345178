/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import { Checkbox } from "@mui/material";

function CheckBoxComponent({
  componentProps,
  onChange,
  checkboxContainerClassName,
  checkboxMultipleValue,
}) {
  const { optionsList, defaultValue } = componentProps || {};
  const [selectedValue, setSelectedValue] = useState([...defaultValue]);

  // handle update selected value onclick item inline
  useEffect(() => {
    if (checkboxMultipleValue) {
      setSelectedValue(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const handleChange = (value) => {
    setSelectedValue((prev) => {
      let res;
      if (prev?.includes(value)) {
        res = selectedValue?.filter((item) => value !== item);
      } else {
        res = [...prev, value];
      }
      onChange(res);
      return res;
    });
  };

  return (
    <div className={checkboxContainerClassName}>
      {optionsList?.map((item, index) => {
        const { value, optionName, customOptionName } = item || {};
        return (
          <div key={index} className="flex gap-[16px]">
            <Checkbox
              id={String(value)}
              checked={selectedValue?.includes(value)}
              onChange={() => handleChange(value)}
            />
            {customOptionName || (
              <label
                htmlFor={String(value)}
                className="flex items-center justify-start flex-1 cursor-pointer"
              >
                {optionName}
              </label>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default CheckBoxComponent;
