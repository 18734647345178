import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useNavigate,
} from "react-router-dom";
import LayoutDefault from "app/operator/layouts/LayoutDefault";
import operatorPath from "app/operator/route/Path";
import {
  EResponseStatus,
  Roles,
} from "app/constant";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useDispatch, useSelector } from "react-redux";
import UserService from "app/services/api/UserService";
import { setProfileStore } from "app/redux/profileReducer";
import CommonService from "app/services/api/CommonService";
import { OPERATOR_API_ENDPOINT } from "app/services/constant";

/**
 * s-001-001
 * s-001-004
 * s-001-007
 */
function Dashboard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const role = useSelector((state) => state.auth?.user?.group_roles?.roles[0]);
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.profile);

  const [isBusinessOperationInfoMissing, setIsBusinessOperationInfoMissing] =
    useState(false);

  // get profile
  useEffect(() => {
    if (!profile) {
      UserService.getProfile().then((res) => {
        if (res.status === EResponseStatus.SUCCESS) {
          dispatch(setProfileStore(res.data.data));
        }
      });
      // .catch((error) => console.log(error));
    }

    CommonService.getModelList(
      OPERATOR_API_ENDPOINT.IS_BUSINESS_OPERATOR_INFO_MISSING
    )
      .then((res) => {
        setIsBusinessOperationInfoMissing(res?.data?.data);
      })
      .catch(() => {
        // setErrorMess(t("common.message.cannot_display_data"));
      })
      .finally(() => {
        // setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutDefault showFooter showHeader>
      <div className="mb-5 bg-linear py-10">
        <div className="container">
          <p className="font-bold text-[32px]">
            {t("operator_header.dashboard")}
          </p>
        </div>
      </div>
      <div className="container flex-1">
        {role !== Roles.MEMBER && (
          <div className="grid gap-4 grid-cols-1 xs:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 mb-5">
            {isBusinessOperationInfoMissing && (
              <div className="hover:bg-secondary text-[13px] p-4 min-w-[240px] border border-solid border-slate-200 rounded-[13px]">
                <div className="flex items-start justify-between h-full">
                  <div className="flex flex-col justify-between h-full items-start">
                    <div className="message">
                      <p className="truncate font-semibold">
                        {t(
                          "operator.staff.dashboard.settingInformationBusinessOperator"
                        )}
                      </p>
                      <p className="text-[12px] text-[#C13515] truncate py-1">
                        {t(
                          "operator.staff.dashboard.necessarySettingInformationBusinessOperator"
                        )}
                      </p>
                    </div>
                    <button
                      type="button"
                      className="truncate underline cursor-pointer"
                      onClick={() =>
                        navigate(
                          role === Roles.MEMBER
                            ? ""
                            : operatorPath.operator_info
                        )
                      }
                    >
                      {t("operator.staff.dashboard.start")}
                    </button>
                  </div>
                  <ErrorOutlineIcon color="error" />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </LayoutDefault>
  );
}

export default Dashboard;
