import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { Dialog } from "@mui/material";
import UploadFile from "./UploadFile";
import CoverImage from "./CoverImage";
import AppErrorMsg from "../AppErrorMsg";
import Media from "../../../components/Media";
import {
  FILE_ERRORS,
  limitFileSize,
  LIMIT_VIDEO,
  maximumFile,
  MAX_MEDIA_QUANTITY,
  mediaUploadAllowedExtensions,
  minimumFileSize,
  videoLimitFileSize,
} from "../../../constant";
import { randomInt } from "../../../utils";

function ImageForm({
  values: formValues,
  setFieldValue,
  setError,
  error,
  limit,
}) {
  const { t } = useTranslation();
  const [openImageForm, setOpenImageForm] = useState(false);
  const [errorMess, setErrorMess] = useState("");

  useEffect(() => {
    if (limit) {
      setErrorMess(t("common.message.at_least_5"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit]);

  const imageList = useMemo(() => {
    return formValues?.files?.slice(0, 4);
  }, [formValues?.files]);

  return (
    <>
      <DialogUploadImage
        limit={limit}
        open={openImageForm}
        onClose={() => {
          setOpenImageForm(false);
        }}
        values={formValues}
        setFieldValue={setFieldValue}
        setError={setError}
        setErrorMess={setErrorMess}
      />
      <div className="editable-item img-list border-b">
        <div className="flex justify-between gap-[16px] mb-[4px]">
          <div className="text-[18px] font-semibold">
            {t("operator.advertisement.detail.image")}
          </div>
          <button
            type="button"
            className="font-semibold underline align-top text-[14px]"
            onClick={() => setOpenImageForm(true)}
          >
            {t("common.action.edit")}
            <i className="ml-[16px] fa-solid fa-chevron-right" />
          </button>
        </div>

        <div className="relative grid grid-cols-4 gap-[4px] mb-[8px]">
          {imageList?.map(({ file, id }) => {
            const isVideoProps = `${file?.type}`
              ?.toLowerCase()
              ?.includes("mp4");

            return (
              <div id={file?.name} key={id} className="relative pt-[60%] ">
                <Media
                  data={file && URL.createObjectURL(file)}
                  isVideoProps={isVideoProps}
                  className="absolute top-0 left-0 w-full h-full rounded-[8px]"
                />
              </div>
            );
          })}
          {imageList?.length > 0 && (
            <button
              type="button"
              onClick={() => setOpenImageForm(true)}
              className="gradient-transparent-white absolute top-0 right-0 w-[100px] h-full flex items-center justify-end font-semibold p-[4px] underline"
            >
              {t("operator.advertisement.detail.total_images", {
                value: formValues?.files?.length,
              })}
            </button>
          )}
        </div>
        {!!error && (
          <AppErrorMsg
            msg={
              errorMess ||
              t("validate_message.required", {
                path: t("common.image"),
              })
            }
          />
        )}
      </div>
    </>
  );
}

function DialogUploadImage({
  open,
  onClose,
  setFieldValue,
  setError,
  values,
  limit,
  setErrorMess,
}) {
  const { t } = useTranslation();
  const [uploadError, setUploadError] = useState(false);

  useEffect(() => {
    if (!open) {
      setUploadError(false)
    }
  }, [open]);

  const videoQuantityExceed = useMemo(() => {
    let countVid = 0;
    values?.files?.forEach((item) => {
      if (`${item?.fileType}`?.toLowerCase()?.includes("mp4")) {
        countVid += 1;
      }
    });
    return countVid >= LIMIT_VIDEO;
  }, [values?.files]);

  const isMaxItem = useMemo(() => {
    return values?.files?.length >= MAX_MEDIA_QUANTITY;
  }, [values?.files]);

  const handleFileUpload = (e) => {
    setUploadError(false);
    const files = Object.values(e?.dataTransfer?.files || e?.target?.files);
    const allNumberOfFile = (values?.files?.length || 0) + (files.length || 0);
    if (files.length && allNumberOfFile <= maximumFile) {
      // max media is 10 items
      const arr = files
        ?.map((file) => {
          if (mediaUploadAllowedExtensions.exec(file?.name?.toLowerCase())) {
            const isVideo = file?.type?.toLowerCase()?.includes("mp4");
            if (file?.size < minimumFileSize) {
              setUploadError(FILE_ERRORS.TOO_SMALL);
              return null;
            }
            if (isVideo) {
              if (videoQuantityExceed) {
                setUploadError(FILE_ERRORS.VIDEO_QUANTITY_EXCEED);
                return null;
              }
              if (file?.size > videoLimitFileSize) {
                setUploadError(FILE_ERRORS.VIDEO_OVER_SIZE);
                return null;
              }
            }
            if (!isVideo) {
              if (file?.size > limitFileSize) {
                setUploadError(FILE_ERRORS.IMG_OVER_SIZE);
                return null;
              }
            }

            return {
              file,
              fileType: file?.type,
              id: randomInt(5),
            };
          }
          setUploadError(FILE_ERRORS.INVALID_TYPE);
          return null;
        })
        ?.filter((item) => item);
      setFieldValue("files", [...(values?.files || []), ...(arr || [])]);
      if (allNumberOfFile === 0) {
        setError(true);
        setErrorMess("");
        if (limit) {
          setErrorMess(t("common.message.at_least_5"));
        }
      } else if (limit && files.length && allNumberOfFile < limit) {
        setError(true);
        setErrorMess(t("common.message.at_least_5"));
      } else {
        setError(false);
        setErrorMess("");
      }
    } else {
      setUploadError(FILE_ERRORS.VIDEO_QUANTITY_EXCEED);
    }
  };

  return (
    <Dialog onClose={onClose} open={open} maxWidth="xl" fullWidth>
      <div className="relative">
        <div className='sticky top-0 bg-white z-[9999]'>
          <div className="flex justify-end py-2 px-4 md:px-6">
            <i
              role="presentation"
              onClick={onClose}
              className="fa-solid fa-xmark cursor-pointer p-3"
            />
          </div>
        </div>
        <div className="pb-6 px-6">
          <div className="pb-[48px] border-b ">
            <CoverImage
              imageList={(() => {
                return values?.files?.map(({ file, id, fileType }) => ({
                  url: file,
                  fileType,
                  id,
                }));
              })()}
              coverImg={values?.coverImg}
              onSetCoverImg={(img) => {
                setFieldValue("coverImg", img);
              }}
            />
          </div>

          <div className="py-[24px]">
            <div className="flex justify-between items-center flex-wrap gap-4">
              <div>
                <p className="font-semibold text-[18px]">
                  {t("operator.advertisement.detail.all_photos")}
                </p>
                <p className="text-gray">
                  {t("operator.advertisement.detail.upload_photo_drag")}
                </p>
              </div>
              <label
                htmlFor="facility-img-upload"
                type="button"
                className={cx(
                  "text-[14px] border rounded-[8px] py-[8px] px-[16px] font-semibold whitespace-nowrap",
                  {
                    "text-[#DDDDDD] border-[#DDDDDD] pointer-events-none":
                      isMaxItem,
                  },
                  {
                    "border-dark cursor-pointer ": !isMaxItem,
                  }
                )}
              >
                {t("operator.advertisement.detail.upload_photo")}
              </label>
            </div>
          </div>
          <UploadFile
            isMaxItem={isMaxItem}
            values={values}
            setFieldValue={(field, value) => {
              if (field === "files") {
                if (value?.length === 0) {
                  setError(true);
                  setErrorMess("");
                  if (limit) {
                    setErrorMess(t("common.message.at_least_5"));
                  }
                } else if (limit && value?.length && value?.length < limit) {
                  setError(true);
                  setErrorMess(t("common.message.at_least_5"));
                } else {
                  setError(false);
                  setErrorMess("");
                }
              }
              setFieldValue(field, value);
            }}
            uploadError={uploadError}
            handleFileUpload={handleFileUpload}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default ImageForm;
