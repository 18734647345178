export default {
  edit: {
    management_information: "操作資訊",
    basic_information: "基本訊息",
    company_name: "公司名稱",
    postcode: "郵遞區號",
    contact: "公司地址",
    founding: "機構",
    phone_number: "電話號碼",
    pay_pal:
      "用於轉帳的 PayPal 資訊（PayPal 中的 @ 使用者名稱/電子郵件/電話號碼）",
    bank_name: "銀行名稱",
    branch_name: "分行名稱",
    account_number: "帳號",
    date_payment: "帳單截止日期",
    term_payment: "付款到期日",
    invoice_number: "發票號碼",
    payee_information: "匯款銀行信息",
    contact_information: "聯絡方式",
    close_date: "締日情報",
    contact_basic: "服務專線",
    country_region: "国／地域",
    this_end_month: "本月底",
    last_end_month: "下個月月底",
  },
};
