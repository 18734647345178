import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";
import { randomInt, useWindowSize } from "app/utils";
import ExtenalNav from "./ExtenalNav";

function FacilityNavigate({ navList }) {
  const { width } = useWindowSize();
  const isMobile = width <= 768;
  const [tabVal, setTabVal] = useState(0);

  const handleChange = (e, newVal) => {
    setTabVal(newVal);
  };

  const renderNav = ({ title, url, extenalNav, end }, index) => {
    const renderLabel = () => {
      return (
        <div
          className="font-bold cursor-pointer text-start w-full text-[18px]"
          key={randomInt(5)}
        >
          <NavLink
            end={end}
            to={url}
            style={({ isActive }) => {
              if (isActive) {
                setTabVal(index);
              }
              return isActive
                ? {
                    background: isMobile ? "" : "#f7f7f7",
                    marginLeft: 0,
                  }
                : undefined;
            }}
            className="py-[8px] px-[16px]"
          >
            {title}
          </NavLink>
          {extenalNav && !isMobile && <ExtenalNav list={extenalNav} />}
        </div>
      );
    };

    return (
      <Tab
        key={randomInt(5)}
        label={renderLabel()}
        id={`vertical-tab-${index}`}
        onClick={() => {}}
        aria-controls={`vertical-tabpanel-${index}`}
        className="min-h-0 py-[4px] px-0"
        disableRipple
      />
    );
  };

  return (
    <div className="navigation md:sticky top-[96px]">
      <Tabs
        orientation={isMobile ? "horizontal" : "vertical"}
        value={tabVal}
        variant="scrollable"
        onChange={handleChange}
        sx={{
          ".MuiTabs-indicator": {
            display: isMobile ? "block" : "none",
            backgroundColor: "#222222",
          },
          ".MuiTabs-flexContainer": {
            width: isMobile ? "100px" : "auto",
          },
        }}
      >
        {navList?.map((item, index) => {
          return renderNav(item, index);
        })}
      </Tabs>
    </div>
  );
}

export default FacilityNavigate;
