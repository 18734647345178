// TODO-Backlog: https://ncinc.backlog.jp/view/NWS_ICTL_ASPSERVICE-776
// TODO-Backlog: https://ncinc.backlog.jp/view/NWS_ICTL_ASPSERVICE-296

import React, { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import CircularProgress from "@mui/material/CircularProgress";
import SearchPlace from "./SearchPlace";
import EditAndConfirmPlace from "./EditAndConfirmPlace";
import ConfirmGeoCode from "./ConfirmGeoCode";

export default function Tab2(props) {
  const { tabData, setTabData, currentTab, setCurrentTab, numberOfTabs } =
    props;

  const { t } = useTranslation();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });
  const [map, setMap] = useState(null);

  const [tempGMapResult, setTempGMapResult] = useState({});
  const [selectedPlace, setSelectedPlace] = useState({
    gMapPlace: null,
    resultPlace: null,
  });
  const [backupResultToEdit, setBackupResultToEdit] = useState(null);

  const [focusSearchBox, setFocusSearchBox] = useState(false);

  const center = useMemo(
    () =>
      tabData && tabData.resultPlace
        ? {
            lat: tabData.resultPlace.geoCodeData.latitude,
            lng: tabData.resultPlace.geoCodeData.longitude,
          }
        : { lat: 35.6722329, lng: 139.7223834 },
    [tabData]
  );

  useEffect(() => {
    if (tabData) {
      setSelectedPlace(tabData);
    }
  }, [tabData]);

  const getNextbtnClasses = () => {
    const baseClasses =
      "w-auto h-12 flex items-center justify-center px-7 py-0 rounded-lg text-base font-semibold text-white leading-5";

    if (selectedPlace.resultPlace)
      return `${baseClasses} bg-dark cursor-pointer`;

    return `${baseClasses} ${
      !tempGMapResult?.placeData && !tempGMapResult?.rawText
        ? "bg-[#dddddd] cursor-not-allowed"
        : "bg-dark cursor-pointer"
    }`;
  };

  const backToSearch = () => {
    setSelectedPlace({ gMapPlace: null, resultPlace: null });
    setTempGMapResult({});
    setBackupResultToEdit(null);
    setFocusSearchBox(false);
  };

  const handleClickBack = () => {
    if (selectedPlace.resultPlace) {
      setBackupResultToEdit(selectedPlace.resultPlace);
      setSelectedPlace((prev) => ({ ...prev, resultPlace: null }));
    } else if (selectedPlace.gMapPlace) backToSearch();
    else setCurrentTab(currentTab - 1);
  };

  const handleClickNext = () => {
    if (selectedPlace.resultPlace !== null) {
      setTabData(selectedPlace);
      console.log("selectedPlace", selectedPlace); // TODO: remove
      setCurrentTab(currentTab + 1);
    } else {
      setSelectedPlace({
        gMapPlace: tempGMapResult,
        resultPlace: null,
      })
    }
  };

  function renderSearchAndConfirmPlace() {
    if (!selectedPlace.gMapPlace && !selectedPlace.resultPlace)
      return (
        <SearchPlace
          tempGMapResult={tempGMapResult}
          focusSearchBox={focusSearchBox}
          setFocusSearchBox={setFocusSearchBox}
          setTempGMapResult={setTempGMapResult}
          map={map}
        />
      );

    if (selectedPlace.gMapPlace && !selectedPlace.resultPlace)
      return (
        <EditAndConfirmPlace
          gMapPlace={selectedPlace.gMapPlace}
          setSelectedPlace={setSelectedPlace}
          backToSearch={backToSearch}
          backupResultToEdit={backupResultToEdit}
        />
      );

    if (selectedPlace.gMapPlace && selectedPlace.resultPlace)
      return <ConfirmGeoCode result={selectedPlace.resultPlace} />;

    return null;
  }

  return (
    <button
      type="button"
      className="w-screen h-screen block overflow-hidden p-0 m-0 cursor-default"
      onClick={() => setFocusSearchBox(false)}
    >
      <div className="w-full h-full bg-gradient-to-r from-[#0E5CB5] to-[#ffffff]">
        <div className="w-full h-full">
          <div className="w-full h-full flex flex-col md:flex-row overflow-y-auto">
            <div className="w-full h-[30%] md:h-full md:w-1/2 md:bg-gradient-to-b md:from-[#ffffff] md:to-[#0E5CB5]">
              <div className="w-full h-full">
                <div className="w-full h-full flex justify-start items-end px-6 py-8 md:items-center md:px-14">
                  <h1 className="text-dark font-semibold text-[26px] leading-[30px] md:text-[48px] md:leading-[56px]">
                    {selectedPlace.resultPlace
                      ? t("operator.host.tab2.AreThePinPositionsCorrect")
                      : t(
                          "operator.host.tab2.where_is_your_accommodation_located"
                        )}
                  </h1>
                </div>
              </div>
            </div>
            <div className="w-full h-[70%] md:h-full md:w-1/2 md:overflow-y-auto relative">
              {isLoaded ? (
                <>
                  <div
                    className="rounded-t-xl md:rounded-none bg-white w-full h-full overflow-hidden"
                    style={{
                      paddingBottom:
                        selectedPlace.gMapPlace && !selectedPlace.resultPlace
                          ? ""
                          : "80px",
                    }}
                  >
                    <div className="w-full h-full">
                      <div className="w-full h-full">
                        <GoogleMap
                          zoom={15}
                          center={center}
                          mapContainerClassName="w-full h-full"
                          onLoad={(loadedMap) => setMap(loadedMap)}
                          options={{
                            draggable: false,
                            zoomControl: false,
                            streetViewControl: false,
                            mapTypeControl: false,
                            fullscreenControl: false,
                          }}
                          onCenterChanged={() =>
                            map &&
                            selectedPlace.gMapPlace &&
                            selectedPlace.resultPlace &&
                            setSelectedPlace((prev) => ({
                              ...prev,
                              resultPlace: {
                                ...prev.resultPlace,
                                geoCodeData: {
                                  latitude: map.center.lat(),
                                  longitude: map.center.lng(),
                                },
                              },
                            }))
                          }
                        />
                      </div>
                    </div>
                  </div>

                  {renderSearchAndConfirmPlace()}

                  {selectedPlace.gMapPlace &&
                  !selectedPlace.resultPlace ? null : (
                    <div className="absolute inset-x-0 bottom-0 h-20 bg-white flex flex-col">
                      <div className="w-full h-0.5 bg-[#EBEBEB]">
                        <div
                          className="h-full bg-dark"
                          style={{
                            width: `${(currentTab * 100) / numberOfTabs}%`,
                          }}
                        />
                      </div>

                      <div className="w-full flex-1 px-6 md:px-10">
                        <div className="w-full h-full flex items-center justify-between">
                          <button
                            type="button"
                            className="text-base font-semibold leading-5 p-2.5 rounded-md bg-white hover:bg-[#f7f7f7] underline"
                            disabled={false}
                            onClick={handleClickBack}
                          >
                            {t("operator.host.common.back")}
                          </button>

                          <button
                            type="button"
                            className={getNextbtnClasses()}
                            disabled={false}
                            onClick={handleClickNext}
                          >
                            {t("operator.host.common.next")}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="w-full h-full flex items-center justify-center">
                  <CircularProgress />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </button>
  );
}
