/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
import moment from "moment";
import { EResponseStatus, FORMAT_DATE } from "../../constant";
import CommonService from "../../services/api/CommonService";
import { ICTL_API_ENDPOINT } from "../../services/constant";
import { convertDynamicPath, NOTIFICATION_TARGET } from "../index";

const formatNotificationList = (data) => {
  const lstTemp = [];
  data?.forEach((item, index) => {
    const date = `${moment(item.delivery_date).utc().format(FORMAT_DATE.YYYY_M_Do_HH_MM)} ～ ${moment(item.end_date).utc().format(FORMAT_DATE.YYYY_M_Do_HH_MM)}`;
    let notiGroup = ``;
    if (item.group_name.length > 0) {
      notiGroup = item.group_name.filter((res) => res && res).join(" , ");
    }

    lstTemp[index] = {
      id: item.notification_id,
      title: item.title,
      date,
      notification_group: notiGroup
    };
  });

  return lstTemp;
};

export const listGroup = (setGroups, t) => {
  CommonService.getModelList(
    ICTL_API_ENDPOINT.GET_NOTIFICATION_LIST_GROUP
  )
    .then((response) => {
      if (response.status === EResponseStatus.SUCCESS) {

        // temp set data
        if (response?.data?.data.length > 0) {
          // set list group
          const groups = [];
          let name;
          response?.data?.data.forEach((value) => {
            if (value?.description === NOTIFICATION_TARGET.CUSTOMER) {
              name = t("admin.notification.detail.influencer");
            } else if (value.description === NOTIFICATION_TARGET.INFLUENCER) {
              name = t("admin.notification.detail.svm");
            } else {
              name = t("admin.notification.detail.customer");
            }

            if (value?.description !== NOTIFICATION_TARGET.CUSTOMER) {
              groups.push({
                value: value?.notification_group_id,
                optionName: name,
              });
            }
          });

          setGroups(groups);
        }
      }
    })
    .catch(() => {
      // setErr(t("common.message.cannot_display_data"));
    })
    .finally(() => {
      // setIsLoading(false);
    });
};

export const getListData = (page, PER_PAGE, setTotal , setResults) => {
  const params = {
    page,
    per_page: PER_PAGE
  };

  CommonService.getModelList(
    ICTL_API_ENDPOINT.GET_NOTIFICATION_LIST, params
  )
    .then((response) => {
      if (response.status === EResponseStatus.SUCCESS) {
        // temp set data
        setTotal(response.data.data.total);
        setResults(formatNotificationList(response.data.data.row));
      }
    })
    .catch(() => {
      // setErr(t("common.message.cannot_display_data"));
    })
    .finally(() => {
      // setIsLoading(false);
    });
};

export const getDataDetail = (id, setItems, setGroups, t) => {
  CommonService.getModelList(
    convertDynamicPath(ICTL_API_ENDPOINT.GET_NOTIFICATION_DETAIL, {
      notification_id: id
    })
  )
    .then((response) => {
      if (response.status === EResponseStatus.SUCCESS) {

        // temp set data
        setItems(response?.data?.data);

        // set list group
        const groups = [];
        let name;
        response?.data?.data?.groups.forEach((value) => {
          if (value.description === NOTIFICATION_TARGET.CUSTOMER) {
            name = t("admin.notification.detail.influencer");
          } else if (value.description === NOTIFICATION_TARGET.INFLUENCER) {
            name = t("admin.notification.detail.svm");
          } else {
            name = t("admin.notification.detail.customer");
          }

          if (value?.description !== NOTIFICATION_TARGET.CUSTOMER) {
            groups.push({
              value: value.notification_group_id,
              optionName: name,
            });
          }
        });

        setGroups(groups);
      }
    })
    .catch(() => {
      // setErrorMess(t("common.message.cannot_display_data"));
    })
    .finally(() => {
      // setIsLoading(false);
    });
};

export const formatDataStoreNotification = (payload) => {
  const {
    title,
    message,
    delivery_date,
    end_date,
    notification_group
  } = payload || {};

  return {
    title,
    message,
    delivery_date,
    end_date,
    notification_group
  }
};

export const formatDataEditNotification = (payload, id) => {
  const {
    title,
    message,
    delivery_date,
    end_date,
    notification_group
  } = payload || {};

  return {
    notification_id: id,
    title,
    message,
    delivery_date,
    end_date,
    notification_group
  }
};

export const storeNotification = ({ data, callbackSuccess, callbackError, }) => {
  CommonService.postModel(
    ICTL_API_ENDPOINT.STORE_NOTIFICATION,
    formatDataStoreNotification(data)
  )
    .then(() => {
      if (callbackSuccess) {
        callbackSuccess();
      }
    })
    .catch(() => {
      if (callbackError) {
        callbackError();
      }
      // setErrorMess(t("common.message.cannot_display_data"));
    }).finally(() => {
    // setIsLoading(false);
  });
};

export const updateNotificationDetail = ({ data, id, callbackSuccess, callbackError, }) => {
  CommonService.postModel(
    convertDynamicPath(ICTL_API_ENDPOINT.UPDATE_NOTIFICATION_DETAIL, {
      notification_id: id
    }),
    formatDataEditNotification(data, id)
  )
    .then(() => {
      if (callbackSuccess) {
        callbackSuccess();
      }
    })
    .catch(() => {
      if (callbackError) {
        callbackError();
      }
      // setErrorMess(t("common.message.cannot_display_data"));
    }).finally(() => {
    // setIsLoading(false);
  });
};

export const deleteNotificationDetail = ({ id, callbackSuccess, callbackError, }) => {
  CommonService.postModel(
    convertDynamicPath(ICTL_API_ENDPOINT.DELETE_NOTIFICATION_DETAIL, {
      notification_id: id
    })
  )
    .then(() => {
      if (callbackSuccess) {
        callbackSuccess();
      }
    })
    .catch(() => {
      if (callbackError) {
        callbackError();
      }
      // setErrorMess(t("common.message.cannot_display_data"));
    }).finally(() => {
    // setIsLoading(false);
  });
};

export const dateMinute = (data) => {
  for (let i=0; i <= 23 ; i+=1) {
    data.push({
      text: (`0${i}`).slice(-2),
      value: (`0${i}`).slice(-2)
    });
  }

  return data;
}

export const dateSecond = (data) => {
  for (let i=0; i <= 59 ; i+=1) {

    data.push({
      text: (`0${i}`).slice(-2),
      value: (`0${i}`).slice(-2)
    });
  }

  return data;
}