export default {
  header: {
    last_5_message: "最新5件のメッセージを表示しています",
    show_more_message: "メッセージをもっと見る",
  },
  message: {
    uploaded: "アップロード成功",
    upload_failed: "アップロードに失敗しました",
    request_success: "翻訳依頼を行いました。",
    request_fail: "翻訳依頼を失敗しました。時間をおいて再度お試しください。",
    cannot_send_to_private:
      "「非公開」または「削除」ステータスの案件へメッセージは送れません。",
    cannot_send_to_completed: "完了したやりとりにはメッセージは送れません。",
  },
};
