import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { OperatorInfoContext } from "app/operator/pages/Operator/Info";
import { editOperatorInfoSchema } from "app/utils/schema/operator";
import EditableGroup, {
  EDIT_BTN_POSITION,
} from "app/operator/staff/pages/BookingDetail/EditableGroup";
import FormItem, { TYPE_INPUT } from "app/operator/components/FormItem";
import { ICTL_API_ENDPOINT } from "app/services/constant";
import { onlyNumberRegex } from "app/constant";

function Invoice() {
  const { t } = useTranslation();
  const { formValues, setFormValues, onSubmitEdit, groupId } =
    useContext(OperatorInfoContext);

  const onSubmitValue = (e) => {
    onSubmitEdit(ICTL_API_ENDPOINT.UPDATE_BUSINESS_PERSON(groupId), e);
    setFormValues(e);
  };

  const renderEditRegistrationNumber = ({ values }) => {
    return (
      <FormItem
        name="registration_number"
        showCharCount
        defaultLength={values?.registration_number?.length}
        regex={onlyNumberRegex}
        typeInput={TYPE_INPUT.TEXT}
        maxLength={14}
        label={t("operator.operatorInfo.invoice_number")}
        placeholder={t("common.message.no_input_yet")}
        // space
        labelClassName="font-semibold mb-2"
        inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        containerClassName="mb-[16px]"
      />
    );
  };
  return (
    <>
      <div className="font-semibold mt-[40px] text-[18px]">
        {t("operator.operatorInfo.invoice_info")}
      </div>
      <EditableGroup
        editForm={renderEditRegistrationNumber}
        displayValues={
          <div className="pl-[8px] text-gray font-semibold">
            <div>{formValues?.registration_number}</div>
          </div>
        }
        validationSchema={editOperatorInfoSchema(t)?.registration_number}
        // space
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item text-[14px]"
        label={t("operator.operatorInfo.invoice_number")}
      />
    </>
  );
}

export default Invoice;
