import React from "react";
import { Dialog } from "@mui/material";
import Button, { BUTTON_STYLE_TYPE } from "app/operator/components/Button";
import { useTranslation } from "react-i18next";

function CustomModal({
  visible,
  onClose,
  onSubmit,
  cancelText,
  confirmText,
  title,
  children,
  hideBtnGroup,
  customButtonGroup,
  maxWidth,
  hideCloseIcon,
  fullWidth,
}) {
  const { t } = useTranslation();

  const handleCloseModal = () => {
    onClose();
  };

  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <Dialog
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      onClose={handleCloseModal}
      open={visible}
    >
      <div className="relative">
        {!hideCloseIcon && (
          <i
            role="presentation"
            onClick={handleCloseModal}
            className="cursor-pointer fa-solid fa-xmark absolute top-[24px] left-[24px]"
          />
        )}
        {title && <div className="px-[40px] py-[22px] border-b">{title}</div>}
        <div className="px-[40px]">{children}</div>

        {!hideBtnGroup && (
          <div>
            {customButtonGroup ? (
              customButtonGroup()
            ) : (
              <div className="p-[24px] flex justify-between border-border border-t gap-[16px]">
                <button
                  className="underline font-semibold text-[#646464]"
                  type="button"
                  onClick={onClose}
                >
                  {cancelText || t("common.action.cancel")}
                </button>
                <Button
                  onClick={handleSubmit}
                  styleType={BUTTON_STYLE_TYPE.PRIMARY}
                >
                  {confirmText || t("common.action.confirm")}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </Dialog>
  );
}

export default CustomModal;
