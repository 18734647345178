export default {
  edit: {
    management_information: '運営情報',
    basic_information: "基本情報",
    company_name: '会社名',
    postcode: '郵便番号',
    contact: '住所',
    founding: "設立",
    phone_number: '電話番号',
    pay_pal: '振込先PayPal情報（PayPalでの@ユーザ名/メールアドレス/電話番号）',
    bank_name: '銀行名',
    branch_name: '支店名',
    account_number: '口座番号',
    date_payment: '請求締日',
    term_payment: '支払い期限日',
    invoice_number: 'インボイス番号',
    payee_information: '振込先情報',
    contact_information : 'お問い合わせ先',
    close_date: '締日情報',
    contact_basic : "問い合わせ先",
    country_region: "国／地域",
    this_end_month: "当月末",
    last_end_month: "翌月末",
  }
};
