export default {
  list: {
    total_item: "帳戶 {{value}}名稱",
    account_name: "帳戶名稱",
    account_phone: "電話號碼",
    account_last_login: "最後登入日期",
    create_account: "建立的帳戶",
    find_account_placeholder: "搜尋工作人員",
  },
  form: {
    title: "帳號資訊",
    delete_notice: "已刪除的帳戶無法恢復，您確定要刪除嗎？",
    family_name: "姓氏",
    first_name: "名稱",
  },
};
