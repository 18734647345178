import React from "react";
import { Link } from "react-router-dom";

function MainContent({ children, alignCenter, showBackButton, path, onClick, flex }) {
  return (
    <main className="flex flex-1 mb-10">
      {showBackButton && (
        <div
          onClick={onClick}
          role="presentation"
          className="mt-[28px] px-[16px] cursor-pointer h-fit"
        >
          <Link to={path || ""} className="w-fit p-2 -m-2">
            <i className="fa-solid fa-chevron-left" />
          </Link>
        </div>
      )}

      <div
        className={`container flex-1 ${
          alignCenter ? "flex justify-center items-center" : ""
        } ${flex ? "flex flex-col" : ""} `}
      >
        {children}
      </div>
    </main>
  );
}

export default MainContent;
