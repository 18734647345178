import store from "app/redux/store";
import { setFacilityId } from "../../redux/facilityIdReducer";

export default {
  dasboard: "/",
  operator: "/operator",
  operator_room_number: "/operator/room-number",
  amenities_list: "/cms/facility/amenity/:facilityType",
  facility: "/operator/facility",
  facility_detail: "/operator/facility/:facilityId",
  facility_detail_edit_amenities: "/operator/facility/:facilityId/amenities",
  facility_detail_edit_image: "/operator/facility/:facilityId/image",
  facility_qr_display: "/operator/facility/qr/:facilityId",
  inventory_create: "/operator/facility/:facilityId/inventory/create",
  inventory: "/operator/facility/:facilityId/inventory",
  inventory_detail: "/operator/facility/:facilityId/inventory/:inventoryId",
  inventory_detail_edit_image:
    "/operator/facility/:facilityId/inventory/:inventoryId/image",
  inventory_detail_edit_amenities:
    "/operator/facility/:facilityId/inventory/:inventoryId/amenities",
  operator_provide_host: "/operator/provide-host",
  product: "/operator/facility/:facilityId/product",
  product_detail_edit_image:
    "/operator/facility/:facilityId/product/:productId/image",
  product_create: "/operator/facility/:facilityId/product/create",
  operator_title: "/operator/title",
  product_detail: "/operator/facility/:facilityId/product/:productId",
  // privacy_policy: "/operator/facility/:facilityId/privacy-policy", // m-002-301
  // term_of_use: "/operator/facility/:facilityId/term-of-use",
  embed_code: (facilityId) => {
    if (facilityId) {
      store.dispatch(setFacilityId(facilityId));
      return `/operator/facility/${facilityId}/embed-code`;
    }
    return "/operator/facility/:facilityId/embed-code";
  },
  performance_review: "/operator/performance-review",
  customer_list: "/operator/customer",
  customer_detail: (customerId) =>
    customerId
      ? `/operator/customer/${customerId}`
      : "/operator/customer/:customerId",
  staff_list: "/operator/staff",
  staff_create: "/operator/staff/create",
  staff__detail: (staffId) =>
    staffId ? `/operator/staff/${staffId}` : "/operator/staff/:staffId",
  pay_list: "/operator/pay",
  pay_detail: "/operator/pay/:payId",
  ad_project: "/operator/ad-project",
  ad_project_create: "/operator/ad-project/create",
  ad_project_detail: (projectId) =>
    projectId
      ? `/operator/ad-project/${projectId}`
      : "/operator/ad-project/:projectId",
  ad_project_detail_edit_image: (projectId) =>
    projectId
      ? `/operator/ad-project/${projectId}/image`
      : "/operator/ad-project/:projectId/image",
  ad_project_social: (projectId) =>
    projectId
      ? `/operator/ad-project-social/${projectId}`
      : "/operator/ad-project-social/:projectId",
  influencer_detail: (projectId, influencerId) =>
    projectId
      ? `/operator/ad-project-social/${projectId}/${influencerId}`
      : "/operator/ad-project-social/:projectId/:influencerId",
  operator_info: "/operator/info",
  plan: "/operator/plan",
  account_settings: "/operator/account-settings",
  account_settings_personal_info: "/operator/account-settings/personal-info",
  account_settings_login_security: "/operator/account-settings/login-security",
  account_settings_global_setting: "/operator/account-settings/global-setting",
  profile: "/operator/profile",
  tags_list: "/operator/facility/:facilityId/tags",
  manager: "/operator/manager",
  withdraw: "/operator/withdraw",
  staff_dashboard: "/staff",
  room_allocate: "/operator/staff/room-allocate/:id",
  room_usage_status: "/operator/staff/room-usage-status/:id",
  booking_list: "/operator/staff/booking",
  manually_allocation: "/operator/staff/manually-allocation/:inventoryId",
  booking_detail: (bookingId) =>
    bookingId
      ? `/operator/staff/booking/${bookingId}`
      : "/operator/staff/booking/:bookingId",
  booking_edit: (bookingId) =>
    bookingId
      ? `/operator/staff/booking/${bookingId}/edit`
      : "/operator/staff/booking/:bookingId/edit",
  create_new_booking: (facilityId) => {
    if (facilityId) {
      store.dispatch(setFacilityId(facilityId));
      return `/operator/staff/create-new-booking/${facilityId}`;
    }
    return "/operator/staff/create-new-booking/:facilityId";
  },
  menu_and_order: (inventoryId) =>
    inventoryId
      ? `/menu-and-order/iid/${inventoryId}`
      : "/menu-and-order/iid/:inventoryId",
  qr_to_menu_and_order: (inventoryId) =>
    inventoryId
      ? `/inventory-qr-to-order/${inventoryId}`
      : "/inventory-qr-to-order/:inventoryId",
};
