import React, { useContext } from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { updatePersonalInfoAction } from "app/utils/page/personal_info";
import InputText from "app/components/InputText";
import EditableForm from "app/components/EditableForm";
import UserContext from "./UserContext";

const EDIT_GOVERNMENT_ID = "show_edit_government_id";

function GovernmentIdSettingField(props) {
  const { showEdit, setShowEdit, setOpenSuccess, setOpenError } = props;
  const { t } = useTranslation();
  const { userInfo, setUserInfo } = useContext(UserContext);

  const validationSchema = Yup.object().shape({
    government_issued_id_number: Yup.string(),
  });
  const initialValues = {
    government_issued_id_number: "",
  };

  const updateUserInfo = (values, context) => {
    const { setValues } = context;
    updatePersonalInfoAction({
      userInfo,
      values,
      setUserInfo,
      setValues,
      setOpenSuccess,
      setOpenError,
      setShowEdit,
    });
  };

  const renderForm = ({ values, setFieldValue }) => {
    return (
      <InputText
        inputId="personal_info_goverment_id"
        title={t("operator.accountSetting.personal_info.goverment_label")}
        inputName="government_issued_id_number"
        setFieldValue={setFieldValue}
        inputValue={values.government_issued_id_number}
        maxLength={64}
      />
    );
  };

  return (
    <EditableForm
      editForm={renderForm}
      onSubmit={updateUserInfo}
      validationSchema={validationSchema}
      initialValues={initialValues}
      label={t("operator.accountSetting.personal_info.goverment_label")}
      editField={EDIT_GOVERNMENT_ID}
      formValues={userInfo}
      displayValue={userInfo?.government_issued_id_number}
      showEdit={showEdit}
      setShowEdit={setShowEdit}
    />
  );
}

export default GovernmentIdSettingField;
