export default {
  header: {
    last_5_message: "Displaying the latest 5 messages",
    show_more_message: "See more messages",
  },
  message: {
    uploaded: "Upload successful.",
    upload_failed: "Upload failed.",
    request_success: "A translation request has been made.",
    request_fail: "Translation request failed. Please wait a while and try again.",
    cannot_send_to_private:
      'Messages cannot be sent to projects with "Private" or "Deleted" status.',
    cannot_send_to_completed: "You cannot send messages to completed interactions.",
  },
};
