import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { randomInt, scrollToElement } from "app/utils";

function ExtenalNav({ list }) {
    const [tabVal, setTabVal] = useState(0);

    const onClickItem = (id) => {
        scrollToElement({ id, yOffset: -80 });
    };

    const handleChange = (e, newVal) => {
        setTabVal(newVal);
    };

    return (
        <Tabs
            orientation="vertical"
            value={tabVal}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className="ml-[16px] mt-[16px]"
            sx={{
                borderColor: 'divider',
                borderRight: "none",
                borderLeft: "1px solid #f1f1f1",
                ".MuiTabs-indicator": {
                    left: "0px",
                    backgroundColor: "#222222",
                    display: "block"
                },
                ".MuiTabs-flexContainerVertical": {
                    gap: "8px"
                },
                ".Mui-selected": {
                    color: "#222222 !important",
                    fontWeight: 600
                }
            }}
        >
            {list?.map((item, index) => {
                const { navTitle, sectionId } = item;
                return (
                    <Tab
                        key={randomInt(5)}
                        label={navTitle}
                        id={`vertical-tab-${index}`}
                        onClick={() => onClickItem(sectionId)}
                        aria-controls={`vertical-tabpanel-${index}`}
                        className="items-start py-[4px] min-h-0"
                    />
                );
            })}
        </Tabs>
    );
}

export default ExtenalNav;