import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  unreadAndMarkActionForM: null,
  unreadForV: null,
  pingForV: null,
};

const slice = createSlice({
  name: "projectBatch",
  initialState,
  reducers: {
    setProjectBatch: (state, action) => {
      state.unreadAndMarkActionForM = action.payload;
    },
    setProjectBatchEmpty: (state) => {
      state.unreadAndMarkActionForM = null;
    },
    setProjectBatchForV: (state, action) => {
      state.unreadForV = action.payload.unreadCount;
      state.pingForV = action.payload.pingCount;
    },
    setProjectBatchEmptyForV: (state) => {
      state.unreadForV = null;
      state.pingForV = null;
    },
  },
});

export const {
  setProjectBatch,
  setProjectBatchEmpty,
  setProjectBatchForV,
  setProjectBatchEmptyForV,
} = slice.actions;

export default slice.reducer;
