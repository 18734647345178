export default {
  consumptionTax: "其中消費稅￥ ",
  list: {
    select_month: "選擇月份",
    reservation_request: "發票合計總金額",
    deposited: "已支付",
    not_payment: "未支付",
    unpaid: "未支付",
    price: "¥ ",
    count_request: "公司業者 件數",
    placeholder_search: "搜尋業者",
    business_name: "企業（者）名稱",
    status: "狀態",
    invoice_amount: "發票金額",
    payment_type: "付款類型",
    invoice_number: "發票號碼",
    paid: "企業者已支付",
    paid_1: "確認付款操作",
    requestPayMent: "確認付款操作",
    unPricePaid: "尚未支付的金額",
    pricePaid: "業者已支付金額",
    comfirmPricePaid: "確認付款操作",
    comfirmPricePaid_1: "企業者已支付",
    numberComfirmComfirmPaid: "發票數量 {{value}}件",
    subject: "件",
    labelSearch: "業者搜尋",
    updateToPaid: "更新為已支付",
    influencerRewards: "網紅報酬",
    monthlyPlan: "每月計劃名稱",
    initialCost: "初期費用",
  },
  detail: {
    influencer_name: "網紅名稱",
    case_name: "案件名稱",
    billing_amount: "發票金額",
    price: "單位價格",
    number: "件數",
    claim_number: "發票號碼",
    nameBusiness: "企業（者）名稱",
    postalCode: "郵遞區號",
    address: "公司地址",
    phoneNumber: "電話號碼",
    nameProject: "案件名稱",
    nameMonthlyPlan: "每月計劃名稱",
    optionName: "選項名稱",
    itemName: "項目名稱",
  },
};
