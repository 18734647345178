import React, { useEffect, useState } from "react";
import { ErrorMessage, Field } from "formik";
import PhoneInput from "react-phone-input-2";
import cx from "classnames";
import { MenuItem, Select } from "@mui/material";
import {changeGeneration, formatUpdatePhoneNumberData, randomInt} from "app/utils";
import { useTranslation } from "react-i18next";
import CheckBoxComponent from "./CheckBox";
import RadioComponent from "./Radio";
import "react-phone-input-2/lib/style.css";
import { MAX_LIMIT } from "../../../constant";
import { CountInput } from "../CountInput/CountInput";

export const TYPE_INPUT = {
  SELECT: "SELECT",
  RADIO: "RADIO",
  CHECKBOX: "CHECKBOX",
  TEXT: "TEXT",
  NUMBER: "NUMBER",
  TEXT_AREA: "TEXT_AREA",
  PHONE: "PHONE",
  COUNT: "COUNT",
};

function FormItem(props) {
  const {
    component,
    name,
    errorsClassName,
    label,
    description,
    descriptionClassName,
    selectClassName,
    placeholder,
    disabled,
    innerRef,
    handleOnBlur,
    inputClassName,
    inputId,
    labelClassName,
    containerClassName,
    typeInput,
    componentProps,
    onChange,
    forceOnChange,
    type,
    prefix,
    suffix,
    required,
    stringValue,
    disableError,
    pattern,
    maxLength = MAX_LIMIT.INPUT_TEXT_AREA,
    showCharCount,
    defaultLength = 0,
    charCountClassName,
    minCount = 0,
    maxCount = 99999,
    // number input props
    min = 0,
    regex,
    checkboxContainerClassName,
    disabledOnChange,
    checkboxMultipleValue,
    touched,
    touchedField,
    updateRadio,
    selectLimitWith,
    textAreaClassName,
    handleDisableSelectItem,
    descriptionInline,
    labelTextClassName,
  } = props;
  const { t, i18n } = useTranslation();
  const [characterCount, setCharacterCount] = useState(defaultLength);

  useEffect(() => {
    setCharacterCount(defaultLength)
  }, [defaultLength]);

  const renderTextAreaInput = (context) => {
    const { field, form } = context;
    const { setFieldValue } = form;
    const { name: fieldName } = field;

    const onChangeTextArea = (e) => {
      const value = e?.target?.value;
      setCharacterCount(value?.length);
      setFieldValue(fieldName, value);
      if (onChange) {
        onChange(value);
      }
    };

    return (
      <Field
        as='textarea'
        value={form?.values[fieldName]}
        // defaultValue={form.initialValues[fieldName]}
        placeholder={placeholder}
        className={`focus:outline-none w-full py-2 px-4 ${textAreaClassName}`}
        name={fieldName}
        maxLength={maxLength}
        rows={4}
        cols={50}
        onChange={onChangeTextArea}
      />
    );
  };

  const renderSelectInput = (context) => {
    const { field, form } = context;
    const { setFieldValue, setFieldTouched, handleBlur } = form;
    const { optionsList, defaultValue } = componentProps || {};
    const { name: fieldName } = field;

    const onChangeSelect = (event) => {
      setFieldValue(field?.name, event?.target?.value);

      if (touched) {
        setFieldTouched(fieldName, true);
      }

      if (touchedField) {
        setFieldTouched(touchedField, true);
      }
    };

    return (
      <Select
        value={field.value || defaultValue}
        inputProps={{
          name: field?.name,
        }}
        defaultValue={defaultValue}
        onChange={onChangeSelect}
        onBlur={handleBlur}
        className={selectClassName}
        placeholder={placeholder}
        disabled={disabled}
      >
        {optionsList?.map(({ optionName, value }) => (
          <MenuItem
            disabled={handleDisableSelectItem ? handleDisableSelectItem(value) : false}
            key={randomInt(5)}
            value={value}
          >
            <div className={`${selectLimitWith ? "truncate" : ""}`}>
              {['ageGroupStart', 'ageGroupEnd'].includes(field?.name) === true ? changeGeneration(optionName, i18n.language) : optionName}
            </div>
          </MenuItem>
        ))}
      </Select>
    );
  };

  const renderRadioInput = (context) => {
    const { field, form } = context;
    const { setFieldValue } = form;
    const { name: fieldName } = field;

    const onChangeSelect = (event) => {
      setFieldValue(fieldName, event?.target?.value);
      if (onChange) {
        onChange(event?.target?.value);
      }
    };

    return (
      <RadioComponent
        updateRadio={updateRadio}
        disabledOnChange={disabledOnChange}
        stringValue={stringValue}
        componentProps={componentProps}
        onChange={onChangeSelect}
      />
    );
  };

  const renderCheckBoxInput = (context) => {
    const { field, form } = context;
    const { setFieldValue, setFieldTouched } = form;
    const { name: fieldName } = field;

    const onChangeSelect = (value) => {
      if (touched) {
        setFieldTouched(fieldName, true);
      }
      setFieldValue(fieldName, value);
      if (onChange) {
        onChange(value);
      }
    };

    return (
      <CheckBoxComponent
        checkboxMultipleValue={checkboxMultipleValue}
        checkboxContainerClassName={checkboxContainerClassName}
        componentProps={componentProps}
        onChange={onChangeSelect}
      />
    );
  };

  const renderTextInput = (context) => {
    const { field, form } = context;
    const { setFieldValue, setFieldTouched, handleBlur } = form;
    const { name: fieldName } = field;

    const onChangeInput = (e) => {
      if (forceOnChange) {
        forceOnChange(e);
        return;
      }

      if (touched) {
        setFieldTouched(fieldName, true);
      }

      if (touchedField) {
        setFieldTouched(touchedField, true);
      }

      const value = e?.target?.value;

      if (regex) {
        if (!value || regex?.test(value)) {
          // match Regex
          setFieldValue(fieldName, value);
          setCharacterCount(value?.length);
        } else {
          // miss Regex
          setFieldValue(fieldName, field?.value || "");
          setCharacterCount(field?.value?.length || 0);
        }
      } else {
        // none Regex
        setFieldValue(fieldName, value);
        setCharacterCount(value?.length);
      }
      if (onChange) {
        onChange(value);
      }
    };

    const onBlurInput = (e) => {
      handleBlur(e)

      if (touchedField) {
        setFieldTouched(touchedField, true);
      }
    }

    return (
      <Field
        name={field?.name}
        onChange={onChangeInput}
        onBlur={onBlurInput}
        placeholder={placeholder}
        className="focus:outline-none w-full py-2 px-4"
        maxLength={maxLength}
      />
    );
  };

  const renderNumberInput = (context) => {
    const { field, form } = context;
    const { setFieldTouched, setFieldValue } = form;
    const { name: fieldName } = field;

    const onChangeNumber = (event) => {
      const { value } = event.target;
      if (touched) {
        setFieldTouched(fieldName, true);
      }

      if (regex) {
        if (!value || regex?.test(value)) {
          // match Regex
          setFieldValue(fieldName, value);
          setCharacterCount(value?.length);
        } else {
          // miss Regex
          setFieldValue(fieldName, field?.value || "");
          setCharacterCount(field?.value?.length || 0);
        }
      } else {
        // none Regex
        setFieldValue(fieldName, value);
        setCharacterCount(value?.length);
      }
      if (onChange) {
        onChange(value);
      }
    };

    return (
      <Field
        name={field?.name}
        onChange={onChangeNumber}
        placeholder={placeholder}
        className="focus:outline-none w-full py-2 px-4"
        maxLength={maxLength}
        disabled={disabled}
      />
    );
  };

  const renderNumberPhone = (context) => {
    const { field, form } = context;
    const { setFieldValue, setFieldTouched } = form;
    const { name: fieldName, value } = field;

    const country = form?.value?.country_key ? form?.value?.country_key : "jp";
    const onChangePhoneNumber = (
      phone,
      onChangeContext,
      event,
      formatedValue
    ) => {
      if (touched) {
        setFieldTouched(fieldName, true);
      }

      if (phone?.length <= maxLength) {
        setFieldValue(fieldName, formatedValue);
        setCharacterCount(phone?.length);
      }
    };

    const handlePhoneInputBlur = () => {
      const spitPhone = (value || "").split(" ");
      const prefixNumber = spitPhone.shift();

      const formatPhoneNumber = formatUpdatePhoneNumberData(value, false);

      const lastPhoneNumber = formatPhoneNumber.replace(/ /g, "");
      setFieldValue(
        fieldName,
        `${
          prefixNumber === lastPhoneNumber ? "" : prefixNumber
        } ${lastPhoneNumber}`
      );
    };

    return (
      <PhoneInput
        inputClass="max-w-full"
        country={country}
        value={value}
        onlyCountries={[country]}
        onBlur={handlePhoneInputBlur}
        onChange={onChangePhoneNumber}
        onFocus={() => {
          if (!value) {
            setFieldValue(fieldName, `+81`);
          }
        }}
        masks={{ jp: ".. .... .... ." }}
        countryCodeEditable={false}
        disabled={disabled}
      />
    );
  };

  const renderCountInput = (context) => {
    const { field, form, meta } = context;
    const { setFieldValue } = form;
    const { name: fieldName } = field;
    const { initialValue } = meta;

    const onChangeCount = (count) => {
      setFieldValue(fieldName, count);
      if (onChange) {
        onChange(count);
      }
    };

    return (
      <CountInput
        value={
          field.value === undefined || field.value === null
            ? initialValue
            : field.value
        }
        minCount={minCount}
        maxCount={maxCount}
        onChange={onChangeCount}
      />
    );
  };

  const FORM_ITEM_BY_TYPE = {
    [TYPE_INPUT.SELECT]: renderSelectInput,
    [TYPE_INPUT.RADIO]: renderRadioInput,
    [TYPE_INPUT.CHECKBOX]: renderCheckBoxInput,
    [TYPE_INPUT.TEXT]: renderTextInput,
    [TYPE_INPUT.NUMBER]: renderNumberInput,
    [TYPE_INPUT.TEXT_AREA]: renderTextAreaInput,
    [TYPE_INPUT.PHONE]: renderNumberPhone,
    [TYPE_INPUT.COUNT]: renderCountInput,
  };

  return (
    <div className={containerClassName}>
      <div className={cx(labelClassName)}>
        <span className={cx("text-[14px]", labelTextClassName)}>{label}</span>
        {required && (
          <span className="text-red pl-1">{t("validate_mark")}</span>
        )}
        {descriptionInline && (
          <div className={cx(descriptionClassName)}>{descriptionInline}</div>
        )}
      </div>
      <div className={cx(descriptionClassName)}>{description}</div>
      <div className={cx(inputClassName)}>
        {prefix && <span className="mr-[16px]">{prefix}</span>}
        <Field
          innerRef={innerRef}
          className="focus:outline-none w-full py-2 px-4"
          component={component}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          onBlur={handleOnBlur}
          type={type}
          min={min}
          id={inputId}
          pattern={pattern}
          maxLength={maxLength}
        >
          {typeInput && FORM_ITEM_BY_TYPE[typeInput]}
        </Field>
        {suffix && <span className="ml-[16px]">{suffix}</span>}
      </div>
      {showCharCount && (
        <div
          className={cx(
            "mt-[8px] text-[#717171] font-semibold",
            charCountClassName
          )}
        >
          {characterCount}/{maxLength}
        </div>
      )}

      {disableError ? (
        ""
      ) : (
        <ErrorMessage
          name={name}
          component="div"
          className={`errors-msg break-all whitespace-pre-wrap ${errorsClassName}`}
        />
      )}
    </div>
  );
}

export default FormItem;
