import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { removeFieldEmptyValue } from "app/utils/index";
import CommonService from '../../../../../services/api/CommonService';
import { ICTL_API_ENDPOINT } from '../../../../../services/constant';

const useCoreComponentList = (userInfluenceId) => {
  const navigate = useNavigate()
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  // const pageUrl = searchParams.get('page');
  const perPageUrl = searchParams.get('per_page');
  const startDateUrl = searchParams.get('startDate') || new Date();
  const [items, setItems] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [total, setTotal] = useState()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(perPageUrl || 20)
  const [infomation, setInfomation] = useState({})
  const [openSuccess, setOpenSuccess] = useState(false)
  const [startDate] = useState(startDateUrl ? new Date(startDateUrl) : new Date());
  const params = {
    per_page: perPage,
    page,
    date: startDate
  }
  const fetchData = useCallback(async () => {
    await CommonService.getModelList(
      `${ICTL_API_ENDPOINT.DETAIL_HISTORY_PAYMENT}${userInfluenceId.userInfluenceId}/history`,
      {
        ...removeFieldEmptyValue(params),
      }
    )
      .then((res) => {
        const resutl = res.data.data
        setItems(resutl.row);
        setTotal(resutl.total)
        setInfomation(resutl.result)
        // setloading(false);
        // setLoadingPage(false)
      })
      .catch(() => {
        // console.log(err);
      })
      .finally(() => {
        // console.log(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (userInfluenceId.userInfluenceId !== null) {
      navigate(
        `?page=${page}&per_page=${perPage}&userId=${
          userInfluenceId.userInfluenceId
        }&startDate=${new Date(startDateUrl)}`,
        {
          replace: true
        }
      );
      fetchData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage, page]);

  return [
    items,
    setPage,
    setPerPage,
    total,
    perPage,
    page,
    infomation,
    // updatePay,
    openSuccess,
    setOpenSuccess
  ];
};

export default useCoreComponentList;