export default {
  list: {
    select_month: "選擇月份",
    reservation_request: "2022年9月の予約請求",
    deposited: "入金済み",
    not_payment: "未支付",
    price: "¥",
    count_request: "發票數量 {{value}}件",
    placeholder_search: "事業者を検索",
    business_name: "企業（者）名稱",
    status: "狀態",
    invoice_amount: "發票金額",
    payment_type: "付款類型",
    invoice_number: "發票號碼",
    unpaid: "未支付",
    paid_1: "運営入金確認済み",
    confirming: "已付款",
    paid: "已付款",
    inital_cost: "初期費用",
    monthly_usage_price: "システム使用料",
    ad_service_charge: "広告マッチング手数料",
    date_pay: "發票日期",
    return: "支払済み",
    updateUnpaid: "已付款",
    total_bill: "發票合計總金額",
    unpaid_amount: "尚未支付的金額",
    paid_mount: "已支付金額",
    operation_payment_confirmed: "確認付款操作",
    node: '※營運方確認付款最長需要 5 個工作天。付款確認完成後，狀態將自動變更為 "操作付款已確認"。',
  },
  detail: {
    influencer_name: "インフルエンサー名",
    case_name: "案件名",
    billing_amount: "發票金額",
    price: "単価",
    number: "件数",
    claim_number: "發票號碼",
  },
};
