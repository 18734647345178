import React, { useContext } from "react";
import EditableGroup, {
  EDIT_BTN_POSITION,
} from "app/operator/staff/pages/BookingDetail/EditableGroup";
import { useTranslation } from "react-i18next";
import FormItem, { TYPE_INPUT } from "app/operator/components/FormItem";
import { MAX_LENGTH_100 } from "app/utils/schema/platform";
import { OperatorInfoContext } from "app/operator/pages/Operator/Info";
import { ICTL_API_ENDPOINT } from "app/services/constant";
import { editOperatorInfoSchema } from "app/utils/schema/operator";

function AdminInfo() {
  const { t } = useTranslation();
  const { formValues, setFormValues, onSubmitEdit, groupId } =
    useContext(OperatorInfoContext);

  const onSubmitValue = (e) => {
    onSubmitEdit(ICTL_API_ENDPOINT.UPDATE_BUSINESS_PERSON(groupId), e);
    setFormValues(e);
  };

  const renderEditManagerName = ({ values }) => {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <FormItem
          required
          name="manager_family_name"
          label={t("operator.accountSetting.personal_info.last_name")}
          showCharCount
          maxLength={MAX_LENGTH_100}
          typeInput={TYPE_INPUT.TEXT}
          defaultLength={values?.manager_family_name?.length}
          placeholder={t("common.message.no_input_yet")}
          // space
          labelClassName="font-semibold mb-2"
          inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        />
        <FormItem
          touchedField="manager_family_name"
          required
          name="manager_first_name"
          showCharCount
          maxLength={MAX_LENGTH_100}
          typeInput={TYPE_INPUT.TEXT}
          defaultLength={values?.manager_first_name?.length}
          label={t("operator.accountSetting.personal_info.first_name")}
          placeholder={t("common.message.no_input_yet")}
          // space
          labelClassName="font-semibold mb-2"
          inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        />
      </div>
    );
  };

  const renderManagerEmail = ({ values }) => {
    return (
      <FormItem
        required
        name="email"
        showCharCount
        maxLength={MAX_LENGTH_100}
        typeInput={TYPE_INPUT.TEXT}
        defaultLength={values?.email?.length}
        label={t("common.email")}
        placeholder={t("common.message.no_input_yet")}
        // space
        labelClassName="font-semibold mb-2"
        inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        containerClassName="mb-[16px]"
      />
    );
  };

  return (
    <div>
      <div className="font-semibold mt-[40px] text-[18px]">
        {t("operator.operatorInfo.admin_basic_info")}
      </div>
      <EditableGroup
        required
        editForm={renderEditManagerName}
        validationSchema={editOperatorInfoSchema(t)?.manager}
        displayValues={
          <div className="pl-[8px] text-gray font-semibold text-[14px]">
            {formValues?.manager_family_name} {formValues?.manager_first_name}
          </div>
        }
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item text-[14px]"
        label={t("operator.operatorInfo.admin_name")}
      />

      <EditableGroup
        required
        editForm={renderManagerEmail}
        validationSchema={editOperatorInfoSchema(t)?.email}
        displayValues={
          <div className="pl-[8px] text-gray font-semibold text-[14px]">
            {formValues?.email}
          </div>
        }
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item text-[14px]"
        label={t("common.email")}
      />
    </div>
  );
}

export default AdminInfo;
