import * as React from 'react';
import { useTranslation } from "react-i18next";
import Dialog from '@mui/material/Dialog';
// import { useTranslation } from "react-i18next";
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';

export default function Popup(props) {
  const { open, text, handleClose, textheader } = props;
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className="relative py-[22px] px-[200px] text-center font-semibold border-b border-border">
        <button type="button" onClick={handleClose}>
          <i className="fa-solid fa-xmark absolute left-[24px] top-[35%]" />{textheader}
        </button>
      </div>
      <div className="p-[24px]">
        <p className='font-semibold'>{text}</p>
        {!text && (
          <div >
            <p className='font-semibold' >
              {t('auth.textSendEmail1')}

            </p>
            <p className='font-semibold'>
              {t('auth.textSendEmail2')}
            </p>
            <p className='font-semibold'>
              {t('auth.textSendEmail3')}

            </p>
          </div>
        )}
        <button
          type="submit"
          className="bg-gradient-to-r from-[#0E5CB5] to-[#0E5CB5] my-[12px] p-[10px] textColorCommon rounded-[8px] w-full font-semibold mt-10"
          onClick={handleClose}
        >
          {t('common.action.yes')}
        </button>
      </div>
    </Dialog>
  );
}