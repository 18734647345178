// v-011-002
import React, { createContext, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ictlPath from "app/ictl/route/Path";
import LayoutDefault from "app/ictl/layouts/LayoutDefault";
import { getProjectDetailData } from "app/utils/page/project_ictl";
import DetailInfo from "./DetailInfo";
import TabGroup from "./TabGroup";

export const ProjectDetailContext = createContext();

function ProjectSocial() {
  const params = useParams();
  const { projectId } = params;
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    getProjectDetailData({ setFormValues, projectId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const projectDetailContextProvider = useMemo(
    () => ({
      formValues,
      id: params?.projectId,
      setFormValues,
    }),
    [formValues, setFormValues, params?.projectId]
  );

  return (
    <ProjectDetailContext.Provider value={projectDetailContextProvider}>
      <LayoutDefault showFooter showHeader>
        <div
          id="social-detail-project"
          className="fixed left-0 top-[100px] border-b-2 border-border w-full px-[20px] md:px-[100px] py-[24px] bg-white z-10"
        >
          <Link
            to={ictlPath.ad_project}
            className="absolute left-7 -top-2 md:top-0 md:left-10"
          >
            <i className="fa-solid fa-chevron-left" />
          </Link>
          <DetailInfo />
        </div>
        <div className="pt-[226px] container flex-1 flex flex-col">
          <TabGroup />
        </div>
      </LayoutDefault>
    </ProjectDetailContext.Provider>
  );
}

export default ProjectSocial;
