import React from "react";
import MineMessage from "app/chat/components/MineMessage";
import OpponentMessage from "app/chat/components/OpponentMessage";
import { SEND_BY } from "../enum/ChatEnum";

const businessOperatorAvatar = require("app/assets/images/bo-image.png");
const platformAvatar = require("app/assets/images/operator-image.png");

function MessageList({
  messageList,
  avatarUrl,
  sendBy,
}) {
  const getOpponentAvatar = (chatDetail) => {
    if (chatDetail.send_by === SEND_BY.ADVERTISER) {
      return businessOperatorAvatar;
    }
    if (chatDetail.send_by === SEND_BY.INFLUENCER) {
      return avatarUrl;
    }
    if (chatDetail.send_by === SEND_BY.PLATFORM_OPERATOR) {
      return platformAvatar;
    }
    return "";
  };

  return (
    <div className="w-full">
      {messageList.map((chatDetail, index) =>
        chatDetail.send_by === sendBy ? (
          <MineMessage
            key={chatDetail.chat_message_id}
            id={chatDetail.chat_message_id}
            chatDetail={chatDetail}
            nextChatDetail={
              index + 1 === !messageList.length
                ? undefined
                : messageList[index + 1]
            }
          />
        ) : (
          <OpponentMessage
            avatarUrl={getOpponentAvatar(chatDetail)}
            key={chatDetail.chat_message_id}
            id={chatDetail.chat_message_id}
            chatDetail={chatDetail}
            nextChatDetail={
              index + 1 === !messageList.length
                ? undefined
                : messageList[index + 1]
            }
          />
        )
      )}
    </div>
  );
}

export default MessageList;
