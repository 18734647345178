export default {
  consumptionTax: "うち消費税￥ ",
  list: {
    select_month: "月を選択",
    reservation_request: "合計請求金額",
    deposited: "入金済み",
    not_payment: "未入金",
    unpaid: "未入金",
    price: "¥ ",
    count_request: "請求件数 2件",
    placeholder_search: "事業者を検索",
    business_name: "事業者名",
    status: "ステータス",
    invoice_amount: "請求金額",
    payment_type: "支払い種別",
    invoice_number: "請求番号",
    paid: "事業者支払い済み",
    paid_1: "入金確認済み",
    requestPayMent: "入金確認済み",
    unPricePaid: "未入金金額",
    pricePaid: "事業者支払済金額",
    comfirmPricePaid: "入金確認済み",
    comfirmPricePaid_1: "事業者支払い済み",
    numberComfirmComfirmPaid: "請求件数 {{value}}件",
    subject: "件",
    labelSearch: "事業者検索",
    updateToPaid: "入金済みに更新",
    influencerRewards: "インフルエンサー報酬",
    monthlyPlan: "月額プラン",
    initialCost: "初期費用",
  },
  detail: {
    influencer_name: "インフルエンサー名",
    case_name: "案件名",
    billing_amount: "請求金額",
    price: "単価",
    number: "件数",
    claim_number: "請求番号",
    nameBusiness: "事業者名",
    postalCode: "郵便番号",
    address: "住所",
    phoneNumber: "電話番号",
    nameProject: "案件名",
    nameMonthlyPlan: "月額プラン名",
    optionName: "オプション名",
    itemName: "項目名",
  },
};