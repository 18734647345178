export default {
  roomAllocationConfirmation: "配室の確認",
  seatAllocationConfirmation: "配席の確認",
  dontChangeStatus: "ステータスを変更しない",
  beReady: "準備完了にする",
  seatDontAllocate: "配席しない",
  seatAllocateRoom: "配席する",
  roomDontAllocate: "配室しない",
  roomAllocateRoom: "配室する",
  roomPreparingAllocateContent:
    "この在庫は、以下の日時に予約が有りますが配室しますか？",
  seatPreparingAllocateContent:
    "この在庫は、以下の日時に予約が有りますが配席しますか？",
  customerInfo: "お客様情報",
  searchForCustomerInfo: "お客様情報検索",
  customerLanguage: "話せる言語",
  furtherAdd: "更に追加",
  planSelection: "プラン選択",
  plan: "プラン",
  possessionPoints: "保有ポイント",
  usagePoints: "利用ポイント",
  unPaid: "未精算",
  paid: "精算済み",
  accountingAmount: "お会計金額",
  totalAmountCal: "総合計",
  orderHistory: "注文履歴",
  offerKey: "鍵提供",
  retriveKey: "鍵取消",
  keyOffer: "鍵提供",
  keyOffered: "鍵提供済み",
  calculate: "精算",
  unpaidAmount: "未精算金額",
  exportReceipt: "領収証発行",
  reservationInformation: "予約情報",
  reservationDateRange: "予約日時",
  showAllEmptyRoom_accomdation: "空室を全て表示",
  showAllEmptyRoom_restaurant: "座席を全て表示",
  roomNumber: "部屋番号",
  seatNumber: "座席番号",
  inventoryName: "在庫名",
  receptionTime: "受付時間",
  productName: "商品名",
  informationDay: "事業者ホーム",
  informationBusinessOperator: " 事業者情報",
  informationContract: "契約情報",
  realTime: "リアルタイム",
  reserve: "予約",
  order: "注文",
  gettingReady: "準備中",
  ready: "準備完",
  reserved: "利用中",
  hasKey: "鍵提供済み",
  weArePreparingRightNow: "只今「準備中」です。",
  doYouWantChange: "ステータスを「準備完了」に変更しますか？",
  titleConfirmChangeStatus: "ステータス変更の確認",
  checkInformation: "重要な情報を確認してください",
  necessaryPublicFacility: "施設を公開するために必要です",
  settingInformationBusinessOperator: "事業者情報を設定してください。",
  necessarySettingInformationBusinessOperator: "ご利用を継続するために必要です",
  start: "はじめる",
  checkinTime: "チェックイン可能な時刻",
  openTime: "営業開始時刻",
};
// operator.staff.dashboard
