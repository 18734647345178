import staff from "app/operator/staff/langs/StaffEN";
import facility from "../pages/Facility/translate/FacilityEN";
import host from "../pages/Operator/host/translate/HostJA";
import SaleRoomEN from "../pages/Operator/SaleRoom/translate/SaleRoomEN";
import layout from "../components/translate/en";
import customer from "../pages/Operator/customer/translate/en";
import staffList from "../pages/Operator/staff/translate/en";
import pay from "../pages/Operator/Pay/translate/en";
import advertisement from "../pages/AdvertisementManage/translate/AdvertisementEN";
import accountSetting from "../pages/AccountSettings/translate/en";
import influencerDetail from "../pages/InfluencerCMS/translate/en";
import operatorInfo from "../pages/Operator/Info/translate/en";

export default {
  layout,
  facility,
  host,
  SaleRoomEN,
  customer,
  staffList,
  pay,
  advertisement,
  staff,
  accountSetting,
  influencerDetail,
  operatorInfo,
};
