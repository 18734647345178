export default {
  list: {
    customer_name: "事業者名",
    address: "住所",
    count_matching: "募集案件数",
    created: "契約日",
    contract_date: "契約日",
    find_customer: "事業者を検索",
    total_business: "事業者 {{value}}件",
    create_staff: "事業者を作成",
    warning: "未入金アラート",
    invoice_number: "インボイス番号",
  },
  add: {
    cancel_create:
      "保存されていません。編集内容はキャンセルされますがよろしいですか？",
    confirm_create_mess:
      "新規登録の為、該当事業者の代表管理者のメールアドレスにアカウント発行メールを通知します。",
    lat_long_notice:
      "GoogleMapで事業者住所のピンの位置へ、カーソルを合わせて右クリック。緯度と経度が表示されたら、さらにクリックしてクリップボードにコピー。それを貼り付けてください。",
  },
};

// admin.business
