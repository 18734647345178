import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
// import debounce from 'lodash/debounce';
import CommonService from '../../../../services/api/CommonService';
import { ICTL_API_ENDPOINT } from '../../../../services/constant';

const useCoreComponentList = () => {
  const navigate = useNavigate()
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  const pageUrl = searchParams.get('page');
  const perPageUrl = searchParams.get('per_page');
  const [items, setItems] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [total, setTotal] = useState()
  const [page, setPage] = useState(pageUrl || 1)
  const [perPage, setPerPage] = useState(perPageUrl || 20)
  const [pricePaid, setPricePaid] = useState({})
  const startDateUrl = searchParams.get('startDate');
  const [startDate, setStartDate] = useState(startDateUrl ? new Date(startDateUrl) : new Date());
  const params = {
    page,
    per_page: perPage,
    date: startDate
  }
  const fetchData = useCallback(async () => {
    await CommonService.getModelList(ICTL_API_ENDPOINT.LIST_HISTORY_PAYMENT, params)
      .then((res) => {
        const resutl = res.data.data
        setItems(resutl.row);
        setTotal(resutl.total)
        setPricePaid(resutl.result)
        // setloading(false);
        // setLoadingPage(false)
      })
      .catch(() => {
        // console.log(err);
      })
      .finally(() => {
        // console.log(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, startDate]);

  useEffect(() => {
    navigate(`?page=${page}&per_page=${perPage}&startDate=${startDate}`, {
      replace: true,
    });
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage, page, startDate]);


  return [
    items,
    setPage,
    setPerPage,
    total,
    perPage,
    page,
    pricePaid,
    startDate,
    setStartDate
  ];
};

export default useCoreComponentList;