import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Popover } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  formatNumber,
  getFileName,
  payCategory,
  payStatus,
  removeFieldEmptyValue,
} from "app/utils";
import { EResponseStatus, PAY_STATUS, PER_PAGE } from "app/constant";
import {
  SortedAscendingIcon,
  SortedDescendingIcon,
  UnsortIcon,
} from "app/components/DatagridIcon";
import useCoreComponent from "app/hooks/useCoreComponent";
import { OPERATOR_API_ENDPOINT } from "app/services/constant";
import PayService from "app/services/api/operatorService/PayService";
import { setActive } from "app/redux/blockUIReducer";
import DatePicker from "react-datepicker";
import ja from "date-fns/locale/ja";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

function List() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [result, setResult] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const paramSearch = new URLSearchParams(location.search);

  const date =
    paramSearch.get("date") && Date.parse(new Date(paramSearch.get("date")))
      ? new Date(paramSearch.get("date"))
      : new Date();

  const initialFilterData = {
    page: paramSearch.get("page") || 1,
    per_page: PER_PAGE,
    date,
  };

  const [filterValues, setFilterValues] = useState(initialFilterData);
  const { response, errorMess, isLoading, totalItem } = useCoreComponent(
    OPERATOR_API_ENDPOINT.GET_PAYMENT_LIST,
    {
      ...removeFieldEmptyValue({
        ...filterValues,
        date: null,
        date_start: moment(filterValues?.date).format("YYYY/MM"),
        date_end: moment(filterValues?.date).format("YYYY/MM"),
      }),
    }
  );

  // popover state
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // data grid
  const [data, setData] = useState([]);

  const updatePayStatus = (item) => {
    PayService.updatePayStatus(item.invoice_number).then((res) => {
      if (res.status === EResponseStatus.CREATED) {
        const lstTemp = [...data];
        const resultTemp = { ...result };
        resultTemp.total_money_status_unpaid -= Number(item.total);
        resultTemp.total_money_status_confirming += Number(item.total);
        lstTemp.forEach((element) => {
          if (element.invoice_number === item.invoice_number) {
            element.payment_status = PAY_STATUS.CONFIRMING;
          }
        });
        setResult(resultTemp);
        setData(lstTemp);
      }
    });
  };

  const columns = [
    {
      field: "billing_month",
      headerName: t("operator.pay.list.date_pay"),
      sortable: true,
      headerClassName: "custom",
      minWidth: 300,
      flex: 1,
      valueGetter: (params) => {
        const billingMonth = params?.row?.billing_month;
        return billingMonth;
      },
    },
    {
      field: "payment_status",
      headerName: t("operator.pay.list.status"),
      sortable: true,
      headerClassName: "custom",
      width: 300,
      valueGetter: (params) => {
        const status = params?.row?.payment_status;
        return payStatus(status);
      },
    },
    {
      field: "total",
      headerName: t("operator.pay.list.invoice_amount"),
      sortable: true,
      headerClassName: "custom",
      width: 200,
      renderCell: (params) => {
        const total = params?.row?.total || 0;
        const tax = params?.row?.tax || 0;
        return (
          <div className="flex flex-col text-right gap-[2px]">
            <p>{`${t("operator.pay.list.price")} ${formatNumber(total)}`}</p>
            <p>{`(うち消費税 ${t("operator.pay.list.price")} ${formatNumber(
              tax
            )})`}</p>
          </div>
        );
      },
    },
    {
      field: "category",
      headerName: t("operator.pay.list.payment_type"),
      sortable: true,
      headerClassName: "custom",
      width: 200,
      valueGetter: (params) => {
        const category = params?.row?.category;
        return payCategory(category);
      },
    },
    {
      field: "invoice_number",
      headerName: t("operator.pay.list.invoice_number"),
      sortable: true,
      headerClassName: "custom",
      width: 200,
    },
    {
      field: "setting",
      headerName: "",
      headerClassName: "custom",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        const paymentStatus = params?.row?.payment_status;
        return (
          <div>
            {paymentStatus === PAY_STATUS.UNPAID && (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  updatePayStatus(params?.row);
                }}
                className="!text-[#fff] !min-w-[100px] !rounded-[8px] !px-4 !py-2"
                classes={{
                  root: "!bg-[#0E5CB5]",
                }}
              >
                {t("operator.pay.list.updateUnpaid")}
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    // handle change url
    const options = { ...filterValues };
    options.date = options.date ? moment(options.date).format("YYYY/MM") : "";
    const url = new URLSearchParams(removeFieldEmptyValue(options));
    navigate(`?${url}`, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues]);

  useEffect(() => {
    const money = response?.data?.data?.result;
    const list = response?.data?.data?.row?.length
      ? response?.data?.data?.row
      : [];
    setResult(money);
    if (list.length && !isLoading) {
      setData(list);
    } else {
      setData([]);
    }
  }, [response, isLoading]);

  const sumValues = (obj) => {
    if (obj) {
      return Object.values(obj).reduce((a, b) => a + b, 0);
    }
    return 0;
  };

  const handleOpen = (event) => {
    setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const exportCSV = () => {
    // export CSV
    const params = {
      date_start: moment(filterValues?.date).format("YYYY/MM"),
      date_end: moment(filterValues?.date).format("YYYY/MM"),
    };
    dispatch(setActive(true));
    PayService.exportCSV(params)
      .then((res) => {
        if (res.status === EResponseStatus.SUCCESS) {
          const { file_url: url } = res.data.data;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", getFileName(url));
          link.click();
        }
      })
      .finally(() => dispatch(setActive(false)));
    handleClose();
  };

  const onPaginationModelChange = (model) => {
    const newPage = model?.page;
    setPage(newPage + 1);
  };

  return (
    <div className="p-6 flex-1">
      <div className="max-w-[568px]">
        <div className="mb-10">
          <p className="text-3xl font-bold mb-1">
            {t("operator.pay.list.select_month")}
          </p>
          <DatePicker
            showMonthYearPicker
            selected={filterValues?.date}
            className="font-bold bg-secondary-400 border divide-slate-200 h-[35px] p-5 rounded"
            dateFormat={t("common.date_format.yyyy_MM")}
            locale={ja}
            onChange={(value) =>
              setFilterValues((prev) => ({
                ...prev,
                page: 1,
                date: value,
              }))
            }
          />
        </div>

        <div className="mb-10 font-bold">
          <p className="text-3xl"> {t("operator.pay.list.total_bill")}</p>
          <h2 className="text-2xl">
            {t("operator.pay.list.price")} {formatNumber(sumValues(result))}
          </h2>
        </div>

        <div className="mb-10">
          <div className="flex flex-wrap items-center gap-8">
            <div className="flex flex-col items-end font-bold">
              <div className="flex items-center">
                <div className="flex items-center justify-center mr-1 -top-1 relative">
                  <i className="text-[14px] fa-solid fa-sort-down" />
                </div>
                <p>{t("operator.pay.list.unpaid_amount")}</p>
              </div>
              <p>{`${t("operator.pay.list.price")} ${formatNumber(
                result?.total_money_status_unpaid || 0
              )}`}</p>
            </div>
            <div className="flex flex-col items-end font-bold">
              <div className="flex items-center">
                <div className="flex items-center justify-center mr-1 -top-1 relative">
                  <i className="text-[14px] fa-solid fa-sort-down" />
                </div>
                <p>{t("operator.pay.list.paid_mount")}</p>
              </div>
              <p>{`${t("operator.pay.list.price")} ${formatNumber(
                result?.total_money_status_confirming || 0
              )}`}</p>
            </div>
            <div className="flex flex-col items-end font-bold">
              <div className="flex items-center">
                <div className="flex items-center justify-center mr-1 -top-1 relative">
                  <i className="text-[14px] fa-solid fa-sort-down" />
                </div>
                <p>{t("operator.pay.list.operation_payment_confirmed")}</p>
              </div>
              <p>{`${t("operator.pay.list.price")} ${formatNumber(
                result?.total_money_status_paid || 0
              )}`}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-5">
        <h2 className="font-bold text-3xl">
          {t("operator.pay.list.count_request", { value: data.length })}
        </h2>
      </div>

      <div className="flex justify-end mb-6">
        <button
          type="button"
          className="border border-secondary-200 rounded-[8px] px-3 py-2 flex items-center font-bold gap-2 relative"
        >
          <p>{t("common.btn.export")}</p>
          <i className="text-[14px] fa-solid fa-angle-down" />
          <div
            role="presentation"
            onClick={handleOpen}
            className="absolute bottom-0 right-0 left-0 top-0"
          />
        </button>
      </div>

      {/* Table Group */}
      <div className="mb-8">
        {errorMess ? (
          <p className="text-[20px] font-[600] py-3">{errorMess}</p>
        ) : (
          <div className="w-full">
            <DataGrid
              autoHeight
              getRowId={(item) => item.invoice_number}
              rows={data}
              columns={columns}
              loading={isLoading}
              rowCount={totalItem}
              paginationMode="server"
              disableColumnMenu
              slots={{
                columnUnsortedIcon: UnsortIcon,
                columnSortedDescendingIcon: SortedDescendingIcon,
                columnSortedAscendingIcon: SortedAscendingIcon,
              }}
              paginationModel={{
                pageSize: PER_PAGE,
                page: page - 1,
              }}
              pageSizeOptions={[PER_PAGE]}
              onPaginationModelChange={(model) => {
                onPaginationModelChange(model);
              }}
              localeText={{
                noResultsOverlayLabel: t("common.message.no_data_display"),
                noRowsLabel: t("common.message.no_data_display"),
              }}
            />
          </div>
        )}
      </div>

      <div className="flex justify-end">
        <p className="text-[14px]">{t("operator.pay.list.node")}</p>
      </div>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="min-w-[200px] py-3">
          <button
            type="button"
            onClick={exportCSV}
            className="text-left w-full px-3 py-2"
          >
            {t("common.btn.download_csv")}
          </button>
        </div>
      </Popover>
    </div>
  );
}

export default List;
