import staff from "app/operator/staff/langs/StaffJA";
import facility from "../pages/Facility/translate/FacilityJA";
import host from "../pages/Operator/host/translate/HostJA";
import sale from "../pages/Operator/SaleRoom/translate/SaleRoomJA";
import layout from "../components/translate/ja";
import customer from "../pages/Operator/customer/translate/ja";
import pay from "../pages/Operator/Pay/translate/ja";
import advertisement from "../pages/AdvertisementManage/translate/AdvertisementJA";
import accountSetting from "../pages/AccountSettings/translate/ja";
import influencerDetail from "../pages/InfluencerCMS/translate/ja";
import manager from "../pages/Operator/manager/translate/ja";
import operatorInfo from "../pages/Operator/Info/translate/ja";

export default {
  layout,
  facility,
  host,
  sale,
  customer,
  pay,
  advertisement,
  staff,
  accountSetting,
  influencerDetail,
  manager,
  operatorInfo,
};
