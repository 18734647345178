export default {
  dasboard: "/",
  home: "/admin",
  member_list: "/admin/member",
  member_detail: "/admin/member/:id",
  pay_list: "/admin/pay",
  pay_detail_list: "/admin/pay/detail-list",
  influencer_detail: "/admin/influencer/:id",
  influencer_list: "/admin/influencer",
  business_person: "/admin/business-person",
  business_person_create: "/admin/business-person/create",
  business_person_edit: (id) =>
    id ? `/admin/business-person/${id}` : "/admin/business-person/:id",
  pay_detail: "/admin/pay/:payId/:categoryId",
  management_information: "/admin/information/",
  payment: "/admin/payment",
  payment_influentce_detail: "/admin/payment/influence/:id",
  notification: "/admin/notification",
  notification_create: "/admin/notification/create",
  notification_detail: "/admin/notification/:id",
  account_settings: "/admin/account-settings",
  account_settings_personal_info: "/admin/account-settings/personal-info",
  account_settings_login_security: "/admin/account-settings/login-security",
  account_settings_global_setting: "/admin/account-settings/global-setting",
  profile: "/admin/profile",
  banner: "/admin/banner",
  banner_create: "/admin/banner/create",
  banner_detail: (bannerId) =>
    bannerId ? `/admin/banner/${bannerId}` : "/admin/banner/:bannerId",
  account: "/admin/account",
  account_create: "/admin/account/create",
  account_detail: (accountId) =>
    accountId ? `/admin/account/${accountId}` : "/admin/account/:accountId",
  ad_project: "/admin/ad-project",
  ad_project_social: (projectId) =>
    projectId
      ? `/admin/ad-project-social/${projectId}`
      : "/admin/ad-project-social/:projectId",
};
