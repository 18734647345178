import React from "react";
import i18next from "i18next";

export default function CustomExpand({ label }) {
  return (
    <div className="text-[#222] font-semibold !underline align-top text-[14px] whitespace-nowrap">
      {label || i18next.t("common.action.edit")}
    </div>
  );
}
