import React, { useContext } from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { useDispatch } from "react-redux";
import { updatePersonalInfoAction } from "app/utils/page/personal_info";
import { setProfileStore } from "app/redux/profileReducer";
import EditableForm from "app/components/EditableForm";
import UserContext from "./UserContext";
import "react-phone-input-2/lib/style.css";
import { formatUpdatePhoneNumberData } from "../../utils";

const EDIT_PHONE = "show_edit_phone";

function PhoneNumberSettingField(props) {
  const { showEdit, setShowEdit, setOpenSuccess, setOpenError } = props;
  const { t } = useTranslation();
  const { userInfo, setUserInfo, profile } = useContext(UserContext);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    phone_number: Yup.string().strict(),
  });
  const initialValues = {
    phone_number: "",
  };

  const updateUserInfo = (values, context) => {
    const { setValues } = context;
    updatePersonalInfoAction({
      userInfo,
      values,
      setUserInfo,
      setValues,
      setOpenSuccess,
      setOpenError,
      setShowEdit,
      callbackSuccess: (data) => {
        const object = { ...profile };
        object.phone_number = data.phone_number;
        dispatch(setProfileStore(object));
      },
    });
  };

  const renderForm = ({ values, setFieldValue }) => {
    return (
      <PhoneInput
        inputClass='max-w-full'
        masks={{ jp: ".. .... .... ." }}
        country="jp"
        value={values?.phone_number}
        onlyCountries={["jp"]}
        autoFormat
        onChange={(value, data, event, formattedValue) =>
          setFieldValue("phone_number", formattedValue)
        }
        onBlur={() => {
          const spitPhone = (values?.phone_number || "").split(" ");
          const prefixNumber = spitPhone.shift();

          const formatPhoneNumber = formatUpdatePhoneNumberData(
            values?.phone_number,
            false
          );

          const lastPhoneNumber = formatPhoneNumber.replace(/ /g, "");
          setFieldValue(
            "phone_number",
            `${
              prefixNumber === lastPhoneNumber ? "" : prefixNumber
            } ${lastPhoneNumber}`
          );
        }}
        onFocus={() => {
          if (!values?.phone_number) {
            setFieldValue("phone_number", `+81`);
          }
        }}
        countryCodeEditable={false}
      />
    );
  };

  return (
    <EditableForm
      editForm={renderForm}
      onSubmit={updateUserInfo}
      validationSchema={validationSchema}
      initialValues={initialValues}
      label={t("operator.accountSetting.personal_info.phone_label")}
      editField={EDIT_PHONE}
      formValues={userInfo}
      displayValue={
        <span>
          {userInfo?.phone_number?.length > 3
            ? userInfo?.phone_number
            : t("common.message.no_input_yet")}
        </span>
      }
      showEdit={showEdit}
      setShowEdit={setShowEdit}
    />
  );
}

export default PhoneNumberSettingField;
