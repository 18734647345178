// m-007-001
import React, { useState, useEffect } from "react";
import {
  generateSNSIcon,
  getProjectStatusText,
  projectStatus,
  removeFieldEmptyValue,
  trimSpaceFirstCharacter,
} from "app/utils/index";
import { EProjectStatus } from "app/constant/index";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  UnsortIcon,
  SortedDescendingIcon,
  SortedAscendingIcon,
} from "app/components/DatagridIcon";
import { Link, useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import useCoreComponent from "../../../hooks/useCoreComponent";
import LayoutDefault from "../../layouts/LayoutDefault";
import { OPERATOR_API_ENDPOINT } from "../../../services/constant";
import { PER_PAGE } from "../../../constant";
import Path from "../../route/Path";
import ImageItem from "../../../components/ImageItem";
import SelectMenu from "../../../components/SelectMenu";

function AdvertisementManage() {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const paramSearch = new URLSearchParams(location.search);

  const initialFilterData = {
    page: paramSearch.get("page") || 1,
    per_page: PER_PAGE,
    status: paramSearch.get("status") || null,
    title: paramSearch.get("title") || null,
  };

  const lstStatus = Object.values(EProjectStatus)?.map((value) => {
    return {
      text: getProjectStatusText(value, t),
      value,
    };
  });

  const [filterValues, setFilterValues] = useState(initialFilterData);
  const { response, errorMess, isLoading, totalItem } = useCoreComponent(
    OPERATOR_API_ENDPOINT.GET_AD_PROJECT_LIST,
    {
      ...removeFieldEmptyValue(filterValues),
    }
  );

  useEffect(() => {
    // handle change url
    const url = new URLSearchParams(removeFieldEmptyValue({ ...filterValues }));
    navigate(`?${url}`, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues]);

  useEffect(() => {
    const list = response?.data?.data?.row || [];
    if (list.length && !isLoading) {
      const lstTemp = list.map((item) => {
        const startRecuit = item.start_recruitment_at
          ? moment(item.start_recruitment_at)
              .tz("Asia/Tokyo")
              .format("YY/MM/DD")
          : null;
        const endRecuit = item.end_recruitment_at
          ? moment(item.end_recruitment_at).tz("Asia/Tokyo").format("YY/MM/DD")
          : null;
        return {
          ...item,
          recruitmentDate:
            startRecuit && endRecuit
              ? `${startRecuit}~${endRecuit}`
              : t("common.other1"),
        };
      });
      setData(lstTemp);
    } else {
      setData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, isLoading]);

  const onSubmitTypeFilter = (newVal) => {
    setFilterValues((prev) => ({
      ...prev,
      page: 1,
      ...newVal,
    }));
  };

  const onChangeSearch = (event) => {
    const { value } = event.target;
    onSubmitTypeFilter({ title: value.trim() });
  };

  const debouncedChangeHandler = _.debounce((event) => {
    onChangeSearch(event);
  }, 1000);

  // const sortComparator = (current, next) => {
  //   const index = (current || next)?.indexOf(
  //     t("common.passage")
  //   );
  //   return (
  //     Number(current?.slice(0, index - 1)) - Number(next?.slice(0, index - 1))
  //   );
  // };

  const onPaginationModelChange = (model) => {
    const newPage = model?.page;
    setFilterValues((prev) => ({
      ...prev,
      page: newPage + 1,
    }));
  };

  const columns = [
    {
      field: "setting",
      headerName: t("operator.advertisement.detail.chat_header"),
      sortable: false,
      headerClassName: "custom",
      width: 100,
      renderCell: (params) => {
        const projectId = params?.row.ad_project_id;
        const countUnread = params?.row?.count_unread;
        return (
          <div className="flex items-center gap-2">
            <button
              type="button"
              className="p-2 -m-2"
              onClick={(event) => {
                event.stopPropagation();
                navigate(Path.ad_project_social(projectId));
              }}
            >
              <i className="text-[26px] fa-regular fa-comments" />
            </button>
            <div className="flex flex-col gap-1">
              {countUnread > 0 && (
                <div className="bg-[#8F0B6A] w-[18px] h-[18px] rounded-full" />
              )}
            </div>
          </div>
        );
      },
    },
    {
      field: "title",
      headerName: t("operator.advertisement.detail.ad_project_name"),
      sortable: true,
      minWidth: 300,
      headerClassName: "custom",
      flex: 1,
      valueGetter: (row) => {
        return trimSpaceFirstCharacter(row.value);
      },
      renderCell: (item) => {
        const itemImage = item?.row?.project_media?.thumbnail_url;
        const title = item?.row?.title;
        return (
          <div className="flex w-full h-full items-center">
            <div className="w-[100px] flex-shrink-0 h-full mr-2">
              <ImageItem
                lazy="true"
                containerclassname="!rounded-none"
                skeletonclassname="!h-[85%] !top-[50%] translate-y-[-50%]"
                role="presentation"
                imagesrc={itemImage}
                alt=""
                imgclassname="w-full h-full object-cover p-1"
              />
            </div>
            <div className="truncate">
              <Tooltip title={title}>
                <span>{title}</span>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
    {
      field: "visibility_and_status",
      headerName: t("operator.advertisement.detail.publish_status"),
      sortable: true,
      headerClassName: "custom",
      width: 200,
      renderCell: (item) => {
        const statuss = item?.row?.visibility_and_status;
        return (
          <div className="flex items-center">
            <i className="fa-regular fa-hourglass-half mr-4" />
            {projectStatus(statuss)}
          </div>
        );
      },
    },
    {
      field: "recruitmentDate",
      headerName: t("operator.advertisement.detail.uploadDueDate"),
      sortable: true,
      headerClassName: "custom",
      width: 250,
      renderCell: (item) => {
        const recruitmentDate = item?.row?.recruitmentDate;
        return (
          <div>
            <i className="fa-regular fa-calendar mr-[8px]" />
            {recruitmentDate}
          </div>
        );
      },
    },
    {
      field: "number_influencer_applied",
      headerName: t("operator.advertisement.detail.apply_quantity"),
      sortable: true,
      headerClassName: "custom",
      width: 200,
      valueGetter: (item) => {
        const apply = item?.row?.number_influencer_applied || 0;
        return apply;
      },
      valueFormatter: (item) => {
        const apply = item?.value || 0;
        return `${apply} ${t("common.passage")}`;
      },
    },
    {
      field: "number_influencer_approved_apply",
      headerName: t("operator.advertisement.detail.applied_quantity"),
      sortable: true,
      headerClassName: "custom",
      width: 200,
      valueGetter: (item) => {
        const applied = item?.row?.number_influencer_approved_apply || 0;
        return applied;
      },
      valueFormatter: (item) => {
        const applied = item?.value || 0;
        return `${applied} ${t("common.passage")}`;
      },
    },
    {
      field: "number_influencer_done",
      headerName: t("operator.advertisement.detail.completed_quantity"),
      sortable: true,
      headerClassName: "custom",
      width: 200,
      valueGetter: (item) => {
        const done = item?.row?.number_influencer_done || 0;
        return done;
      },
      valueFormatter: (item) => {
        const done = item?.value || 0;
        return `${done} ${t("common.passage")}`;
      },
    },
    {
      field: "ad_media",
      headerClassName: "custom",
      headerName: t("operator.advertisement.detail.receiveSNS"),
      sortable: true,
      width: 200,
      renderCell: (item) => {
        const social = item?.row?.ad_media.split(",");
        return <>
          {
            social.map(media => {
              return generateSNSIcon(media)
            })
          }
        </>;
      },
    },
  ];

  return (
    <LayoutDefault showFooter showHeader>
      <div className="px-6 flex-1">
        <div className="flex flex-wrap gap-4 justify-between my-5">
          <div className="text-3xl font-semibold">
            {t("operator.advertisement.detail.advertisement", {
              value: totalItem,
            })}
          </div>
          <Link
            to={Path.ad_project_create}
            className="text-[16px] py-[4px] px-[12px] pr-[24px] border font-semibold border-dark rounded-[8px]"
          >
            <span className="mr-[16px]">+</span>
            {t("operator.advertisement.detail.createAdvertisement")}
          </Link>
        </div>

        {/* Search Group */}
        <div className="flex items-center flex-wrap gap-4">
          <div className="flex items-center w-full max-w-[288px] px-3 py-1 rounded-3xl border border-solid border-secondary-200 bg-secondary">
            <div className="mr-2">
              <i className="fa-solid fa-magnifying-glass text-secondary-200" />
            </div>
            <input
              type="text"
              placeholder={t(
                "operator.advertisement.detail.searchAdvertisement"
              )}
              defaultValue={filterValues.title}
              className="flex-1 bg-secondary outline-none w-full"
              onChange={(event) => debouncedChangeHandler(event)}
            />
          </div>
          <SelectMenu
            optionSelected={filterValues?.status?.split(",")}
            optionList={lstStatus}
            onSubmit={(status) => {
              onSubmitTypeFilter({ status: status.join(",") });
            }}
            label={t("operator.advertisement.detail.publish_status")}
          />
        </div>

        {/* Table Group */}
        {errorMess ? (
          <p className="text-[20px] font-[600] flex-1">{errorMess}</p>
        ) : (
          <div className="w-full py-6">
            <DataGrid
              autoHeight
              getRowId={(item) => item.ad_project_id}
              getRowClassName={() => "cursor-pointer"}
              rows={data}
              columns={columns}
              loading={isLoading}
              rowCount={totalItem}
              paginationMode="server"
              disableColumnMenu
              slots={{
                columnUnsortedIcon: UnsortIcon,
                columnSortedDescendingIcon: SortedDescendingIcon,
                columnSortedAscendingIcon: SortedAscendingIcon,
              }}
              paginationModel={{
                pageSize: PER_PAGE,
                page: filterValues.page - 1,
              }}
              pageSizeOptions={[PER_PAGE]}
              onPaginationModelChange={(model) => {
                onPaginationModelChange(model);
              }}
              localeText={{
                noResultsOverlayLabel: t("common.message.no_data_display"),
                noRowsLabel: t("common.message.no_data_display"),
              }}
              onRowClick={(params, event) => {
                event.preventDefault();
                navigate(Path.ad_project_detail(params?.id));
              }}
            />
          </div>
        )}
      </div>
    </LayoutDefault>
  );
}

export default AdvertisementManage;
