// v-002-002
import React, { useEffect, useRef, useState } from "react";
import ictlPath from "app/ictl/route/Path";
import MainContent from "app/components/MainContent";
import DetailListContent from "app/components/DetailListContent";
import LayoutDefault from "app/ictl/layouts/LayoutDefault";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { checkValueChange } from "app/utils";
import { useNavigate, useParams } from "react-router-dom";
import AppMessage from "app/components/AppMessage";
import Button, { BUTTON_STYLE_TYPE } from "app/operator/components/Button";
import "dayjs/locale/ja";
import {
  COUNTRY_LIST,
  EResponseStatus,
  onlyNumberRegex,
  postCodeRegexInput,
} from "app/constant";
import SuccessDialog from "app/components/SuccessDialog";
import ErrorDialog from "app/components/ErrorDialog";
import CommonService from "app/services/api/CommonService";
import { ICTL_API_ENDPOINT } from "app/services/constant";
import FormItem, { TYPE_INPUT } from "app/operator/components/FormItem";
import { MAX_LENGTH_100, MAX_LENGTH_POSTCODE } from "app/utils/schema/operator";
import {
  businessPersonSchema,
  BUSINESS_PERSON_ACTION,
  formatGetBusinessPersonDetailData,
  initialBusinessPersonValues,
  updateOrCreateBusinessPerson,
} from "app/utils/page/business_person";
import BusinessPersonField from "./BusinessPersonField";

function BusinessPersonForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openCancel, setOpenCancel] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [message, setMessage] = useState("");
  const [action, setAction] = useState("");
  const params = useParams();
  const { id } = params;
  const formikRef = useRef(null);

  const [formValues, setFormValues] = useState(initialBusinessPersonValues);

  const addressRegex = /^[0-9.-\s]*(?:,[0-9.-\s]*)?$/;

  // get account detail
  useEffect(() => {
    if (formikRef && formikRef.current) {
      const { setValues } = formikRef.current;
      if (id) {
        CommonService.getModelList(
          ICTL_API_ENDPOINT.GET_BUSINESS_DETAIL(id)
        ).then((response) => {
          if (response.status === EResponseStatus.SUCCESS) {
            const items = response?.data?.data;
            setValues(formatGetBusinessPersonDetailData(items));
            setFormValues(formatGetBusinessPersonDetailData(items));
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCreateOrUpdate = (values) => {
    if (openCancel) {
      setOpenCancel(false);
    }
    updateOrCreateBusinessPerson({
      id,
      data: values,
      callbackSuccess: (type) => {
        setOpenSuccess(true);
        setMessage(
          type === BUSINESS_PERSON_ACTION.STORE
            ? t("common.message.createSuccess")
            : t("common.message.update_success")
        );
      },
      callbackError: (type, error) => {
        setOpenError(true);
        const errorMessage = error?.response?.data?.message;
        if (errorMessage === "ACCOUNT_EXIST") {
          setMessage(
            t(`common.message.${errorMessage}`, { email: values?.email })
          );
        } else if (type === BUSINESS_PERSON_ACTION.UPDATE) {
          setMessage(t("common.message.update_fail"));
        } else {
          setMessage(t("common.message.createFailed"));
        }
      },
    });
  };

  const onSubmit = (values) => {
    if (id) {
      onCreateOrUpdate(values);
    } else {
      setOpenCancel(true);
      setMessage(t("admin.business.add.confirm_create_mess"));
      setAction(BUSINESS_PERSON_ACTION.STORE);
    }
  };

  const onConfirm = () => {
    if (action === BUSINESS_PERSON_ACTION.CANCEL) {
      navigate(ictlPath.business_person);
    }
    if (action === BUSINESS_PERSON_ACTION.STORE) {
      if (formikRef && formikRef.current) {
        const { values } = formikRef.current;
        onCreateOrUpdate(values);
      }
    }
  };

  const renderManagerName = (props) => {
    const { values } = props;
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <FormItem
          touched
          required
          disableError
          name="manager_family_name"
          showCharCount
          maxLength={MAX_LENGTH_100}
          typeInput={TYPE_INPUT.TEXT}
          defaultLength={values?.manager_family_name?.length}
          label={t("operator.accountSetting.personal_info.last_name")}
          placeholder={t("common.message.no_input_yet")}
          // space
          labelClassName="font-semibold mb-2"
          inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        />
        <FormItem
          touchedField="manager_family_name"
          required
          disableError
          name="manager_first_name"
          showCharCount
          maxLength={MAX_LENGTH_100}
          typeInput={TYPE_INPUT.TEXT}
          defaultLength={values?.manager_first_name?.length}
          label={t("operator.accountSetting.personal_info.first_name")}
          placeholder={t("common.message.no_input_yet")}
          // space
          labelClassName="font-semibold mb-2"
          inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        />
      </div>
    );
  };

  const renderBusinessPersonAddress = (props) => {
    const { values } = props;
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-[16px]">
        <FormItem
          touched
          required
          touchedField="full_address"
          disableError
          name="post_code"
          showCharCount
          regex={postCodeRegexInput}
          maxLength={MAX_LENGTH_POSTCODE}
          typeInput={TYPE_INPUT.TEXT}
          defaultLength={values?.post_code?.length}
          label={t("operator.accountSetting.personal_info.postcode")}
          placeholder={t("common.message.no_input_yet")}
          // space
          labelClassName="font-semibold mb-2 flex-1"
          inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
          containerClassName="mb-[16px] flex flex-col"
        />
        <FormItem
          touched
          required
          touchedField="full_address"
          disableError
          name="lat_long"
          showCharCount
          maxLength={256}
          typeInput={TYPE_INPUT.TEXT}
          defaultLength={values?.lat_long?.length}
          regex={addressRegex}
          descriptionInline={t("admin.business.add.lat_long_notice")}
          descriptionClassName="text-gray mb-2 ml-3"
          label={t("operator.operatorInfo.long_lat")}
          placeholder={t("common.message.no_input_yet")}
          // space
          labelClassName="font-semibold mb-2 flex"
          labelTextClassName="flex-shrink-0"
          inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
          containerClassName="mb-[16px]"
        />
        <FormItem
          touched
          touchedField="full_address"
          required
          disableError
          name="street"
          showCharCount
          maxLength={256}
          typeInput={TYPE_INPUT.TEXT}
          defaultLength={values?.street?.length}
          label={t(
            "operator.accountSetting.personal_info.state_provine_country"
          )}
          placeholder={t("common.message.no_input_yet")}
          // space
          labelClassName="font-semibold mb-2"
          inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
          containerClassName="mb-[16px]"
        />
        <FormItem
          touched
          touchedField="full_address"
          required
          disableError
          name="city"
          showCharCount
          maxLength={256}
          typeInput={TYPE_INPUT.TEXT}
          defaultLength={values?.city?.length}
          label={t("operator.accountSetting.personal_info.municipaliites")}
          placeholder={t("common.message.no_input_yet")}
          // space
          labelClassName="font-semibold mb-2"
          inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
          containerClassName="mb-[16px]"
        />
        <FormItem
          touched
          touchedField="full_address"
          required
          disableError
          name="address"
          showCharCount
          maxLength={256}
          typeInput={TYPE_INPUT.TEXT}
          defaultLength={values?.address?.length}
          label={t("operator.accountSetting.personal_info.address")}
          placeholder={t("common.message.no_input_yet")}
          // space
          labelClassName="font-semibold mb-2"
          inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
          containerClassName="mb-[16px]"
        />
        <FormItem
          touched
          touchedField="full_address"
          disableError
          name="apartment_name"
          showCharCount
          maxLength={256}
          typeInput={TYPE_INPUT.TEXT}
          defaultLength={values?.apartment_name?.length}
          label={t("operator.accountSetting.personal_info.apartment_name")}
          placeholder={t("common.message.no_input_yet")}
          // space
          labelClassName="font-semibold mb-2"
          inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
          containerClassName="mb-[16px]"
        />

        <FormItem
          touched
          touchedField="full_address"
          name="country"
          label={t("operator.accountSetting.personal_info.country_region")}
          labelClassName="font-semibold mb-2"
          inputClassName="w-full text-[#dddddd]"
          containerClassName="mb-[16px]"
          typeInput={TYPE_INPUT.SELECT}
          selectClassName="w-full"
          required
          componentProps={{
            defaultValue: values?.country,
            optionsList: [
              {
                optionName: t("common.japan"),
                value: COUNTRY_LIST.JA,
              },
            ],
          }}
        />
      </div>
    );
  };

  const renderBusinessPersonPhone = () => {
    return (
      <FormItem
        touched
        required
        disableError
        name="phone_number"
        typeInput={TYPE_INPUT.PHONE}
        label={t("operator.operatorInfo.phone")}
        placeholder={t("common.message.no_input_yet")}
        // space
        labelClassName="font-semibold mb-2"
        inputClassName="border-border border rounded-[8px] py-2 px-4 overflow-hidden w-full"
        containerClassName="mb-[16px]"
      />
    );
  };

  const renderServiceProviderName = (props) => {
    const { values } = props;

    return (
      <FormItem
        touched
        required
        disableError
        name="service_provider_name"
        showCharCount
        maxLength={MAX_LENGTH_100}
        typeInput={TYPE_INPUT.TEXT}
        defaultLength={values?.service_provider_name?.length}
        label={t("operator.operatorInfo.service_name")}
        placeholder={t("common.message.no_input_yet")}
        // space
        labelClassName="font-semibold mb-2"
        inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        containerClassName="mb-[16px]"
      />
    );
  };

  const renderManagerEmail = (props) => {
    const { values } = props;

    return (
      <FormItem
        touched
        required
        disableError
        name="email"
        showCharCount
        maxLength={MAX_LENGTH_100}
        typeInput={TYPE_INPUT.TEXT}
        defaultLength={values?.email?.length}
        label={t("common.email")}
        placeholder={t("common.message.no_input_yet")}
        // space
        labelClassName="font-semibold mb-2"
        inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        containerClassName="mb-[16px]"
      />
    );
  };

  const renderBusinessPersonInvoice = (props) => {
    const { values } = props;

    return (
      <FormItem
        touched
        disableError
        name="invoice_number"
        showCharCount
        maxLength={14}
        typeInput={TYPE_INPUT.TEXT}
        regex={onlyNumberRegex}
        defaultLength={values?.invoice_number?.length}
        label={t("operator.operatorInfo.invoice_number")}
        placeholder={t("common.message.no_input_yet")}
        // space
        labelClassName="font-semibold mb-2"
        inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        containerClassName="mb-[16px]"
      />
    );
  };

  const renderRightContent = () => {
    return (
      <div className="account-form text-[14px] h-full flex flex-col">
        <Formik
          innerRef={formikRef}
          initialValues={initialBusinessPersonValues}
          validationSchema={businessPersonSchema(t)}
          onSubmit={onSubmit}
        >
          {(props) => {
            const { values, handleSubmit, errors } = props;
            const isValuesHasChanged = checkValueChange(
              formValues,
              values,
              false
            );
            return (
              <Form className="h-full flex flex-col">
                <div className="account-info text-[14px] mb-7">
                  <div className="font-bold text-[18px] mb-[16px]">
                    {t("operator.operatorInfo.basic_info")}
                  </div>
                  <BusinessPersonField
                    label={t("operator.operatorInfo.service_name")}
                    fieldName="service_provider_name"
                    displayValue={values?.service_provider_name}
                  >
                    {renderServiceProviderName(props)}
                  </BusinessPersonField>
                  <BusinessPersonField
                    label={t("operator.operatorInfo.address")}
                    fieldName="full_address"
                    displayValue={
                      <>
                        {(() => {
                          const {
                            post_code: postCode,
                            apartment_name: apartment,
                            address,
                            street,
                            city,
                            country,
                            lat_long: latLong,
                          } = values;
                          const requirePath = [
                            postCode,
                            street,
                            city,
                            address,
                            latLong,
                          ].filter((item) => item);
                          const path = [
                            postCode,
                            street,
                            city,
                            address,
                            apartment,
                          ]
                            .filter((item) => item)
                            .join(" ");
                          const countryPath = [t("common.japan"), country].join(
                            "-"
                          );
                          const addressFull = [path, countryPath].join(",");
                          if (
                            !errors?.full_address &&
                            requirePath?.length === 5
                          ) {
                            return addressFull;
                          }
                          return "";
                        })()}
                      </>
                    }
                  >
                    {renderBusinessPersonAddress(props)}
                  </BusinessPersonField>
                  <BusinessPersonField
                    label={t("operator.operatorInfo.phone")}
                    fieldName="phone_number"
                    displayValue={
                      !errors.phone_number &&
                      values?.phone_number?.length > 3 && (
                        <div>{values?.phone_number}</div>
                      )
                    }
                  >
                    {renderBusinessPersonPhone()}
                  </BusinessPersonField>
                </div>

                <div className="account-info text-[14px] mb-7">
                  <div className="font-bold text-[18px] mb-[16px]">
                    {t("operator.operatorInfo.admin_basic_info")}
                  </div>
                  <BusinessPersonField
                    label={t("operator.operatorInfo.admin_name")}
                    fieldName="manager_family_name"
                    displayValue={
                      <>
                        {values?.manager_family_name}{" "}
                        {values?.manager_first_name}
                      </>
                    }
                  >
                    {renderManagerName(props)}
                  </BusinessPersonField>
                  <BusinessPersonField
                    label={t("common.email")}
                    fieldName="email"
                    displayValue={!errors.email && values?.email}
                  >
                    {renderManagerEmail(props)}
                  </BusinessPersonField>
                </div>

                <div className="account-info text-[14px]">
                  <div className="font-bold text-[18px] mb-[16px]">
                    {t("operator.operatorInfo.invoice_info")}
                  </div>
                  <BusinessPersonField
                    label={t("operator.operatorInfo.invoice_number")}
                    fieldName="invoice_number"
                    displayValue={values?.invoice_number}
                    required={false}
                  >
                    {renderBusinessPersonInvoice(props)}
                  </BusinessPersonField>
                </div>

                <div className="flex-1 flex items-end mt-12">
                  <div className="w-full">
                    {/* Seperate line */}
                    {/* <div className="border-b-[2px] w-full border-b-gray-300 mt-10 mb-6" /> */}

                    <div className="flex justify-between">
                      <button
                        type="button"
                        className="font-bold underline"
                        onClick={() => {
                          if (!isValuesHasChanged) {
                            navigate(ictlPath.business_person);
                          }
                          setOpenCancel(true);
                          setMessage(t("admin.business.add.cancel_create"));
                          setAction(BUSINESS_PERSON_ACTION.CANCEL);
                        }}
                      >
                        {t("common.action.cancel")}
                      </button>
                      <Button
                        onClick={handleSubmit}
                        styleType={BUTTON_STYLE_TYPE.PRIMARY}
                        disabled={id && !isValuesHasChanged}
                      >
                        {t("common.action.save")}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  };

  return (
    <LayoutDefault showFooter showHeader pin={2}>
      <MainContent showBackButton path={ictlPath.business_person} flex>
        <DetailListContent
          rightContent={renderRightContent()}
          title={t("operator.operatorInfo.title")}
        />
      </MainContent>
      <SuccessDialog
        message={message}
        open={openSuccess}
        onClose={() => navigate(ictlPath.business_person)}
        onOK={() => navigate(ictlPath.business_person)}
      />
      <ErrorDialog
        message={message}
        open={openError}
        onClose={() => setOpenError(false)}
        onOK={() => setOpenError(false)}
      />
      <AppMessage
        open={openCancel}
        setOpen={setOpenCancel}
        onSubmit={(e) => onConfirm(e)}
      >
        {message}
      </AppMessage>
    </LayoutDefault>
  );
}

export default BusinessPersonForm;
