/* eslint-disable camelcase */
import dayjs from "dayjs";
import UserService from "app/services/api/UserService";
import { EResponseStatus } from "app/constant";
import { setActive } from "../../redux/blockUIReducer";
import store from "../../redux/store";
import {
  formatGetPhoneNumberWithPrefix,
  formatUpdatePhoneNumberData,
} from "..";

export const formatGetPersonalInfoData = (data) => {
  const {
    nickname,
    birthday,
    family_name,
    first_name,
    gender,
    government_issued_id_number,
    user_transfer_accounts,
    user_id,
  } = data || "";
  const allAddress = data?.address || null;
  const phone_number = data?.phone_number?.main || "";
  const email_address = data?.email_address?.main || "";
  const { address, city, country, postcode, street, apartment } =
    allAddress || "";
  const { account: paypal } = user_transfer_accounts?.paypal || "";
  const phoneFormat = formatGetPhoneNumberWithPrefix(phone_number);
  const formatData = {
    address: address || "",
    city: city || "jp",
    country: country || "",
    postcode: postcode || "",
    street: street || "",
    nickname: nickname || "",
    family_name: family_name || "",
    first_name: first_name || "",
    gender: gender || "other",
    government_issued_id_number: government_issued_id_number || "",
    phone_number: phoneFormat || '+81',
    birthday: birthday ? dayjs(birthday).format("YYYY/MM/DD") : null,
    paypal: paypal || "",
    email_address,
    user_id: user_id || "",
    apartment: apartment || "",
  };
  return formatData;
};

export const formatUpdatePersonalInfoData = (data) => {
  const phoneFormat = formatUpdatePhoneNumberData(data?.phone_number);

  const formatData = {
    family_name: data?.family_name,
    first_name: data?.first_name,
    phone_number: {
      main: phoneFormat,
    },
    email_address: {
      main: data?.email_address,
    },
    address: {
      city: data?.city,
      street: data?.street,
      address: data?.address,
      country: data?.country,
      postcode: data?.postcode,
      apartment: data?.apartment,
    },
    gender: data?.gender,
    government_issued_id_number: data?.government_issued_id_number,
    nickname: data?.nickname,
    user_transfer_accounts: {
      paypal: {
        account: data?.paypal,
      },
    },
    user_id: data?.user_id,
    birthday: data?.birthday
      ? dayjs(data?.birthday).format("YYYY-MM-DD[T]HH:mm:ss")
      : null,
  };
  return formatData;
};

export const updatePersonalInfoAction = ({
  userInfo,
  values,
  setUserInfo,
  setValues,
  setOpenSuccess,
  setOpenError,
  setShowEdit,
  callbackSuccess,
  callbackkError,
  callbackFinally,
}) => {
  store.dispatch(setActive(true));
  UserService.updateUserPersonalInfo(formatUpdatePersonalInfoData(values))
    .then((response) => {
      if (response.status === EResponseStatus.SUCCESS) {
        setUserInfo(formatGetPersonalInfoData(response?.data?.data));
        setOpenSuccess(true);
        if (callbackSuccess) {
          callbackSuccess(response.data.data);
        }
      }
    })
    .catch(() => {
      setOpenError(true);
      setValues(userInfo);
      if (callbackkError) {
        callbackkError();
      }
    })
    .finally(() => {
      store.dispatch(setActive(false));
      setShowEdit("");
      if (callbackFinally) {
        callbackFinally();
      }
    });
};
