import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
// import debounce from 'lodash/debounce';
import CommonService from '../../../../../services/api/CommonService';
import { ICTL_API_ENDPOINT } from '../../../../../services/constant';

const useCoreComponentList = (userInfluenceId) => {
  const navigate = useNavigate()
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  // const pageUrl = searchParams.get('page');
  const perPageUrl = searchParams.get('per_page');
  const [items, setItems] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [total, setTotal] = useState()
  const [page, setPage] = useState( 1)
  const [perPage, setPerPage] = useState(perPageUrl || 20)
  const [infomation, setInfomation] = useState({})
  const [openSuccess, setOpenSuccess] = useState(false)
  const [openWarning, setOpenWarning] = useState(false)
  const [payPal, setPayPal] = useState('')
  const [nickname, setNickName] = useState('')
  const [userId, setUserId] = useState()
  const params = {
    per_page: perPage,
    page
  }
  const fetchData = useCallback(async () => {
    await CommonService.getModelList(`${ICTL_API_ENDPOINT.DETAIL_INVOICE_INFLUENCE}${userInfluenceId.userInfluenceId}`, params)
      .then((res) => {
        const resutl = res.data.data
        setItems(resutl.row);
        setTotal(resutl.total)
        setInfomation(resutl.result)
        // setloading(false);
        // setLoadingPage(false)
      })
      .catch(() => {
        // console.log(err);
      })
      .finally(() => {
        // console.log(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  useEffect(() => {
    if (userInfluenceId.userInfluenceId !== null) {
      navigate(`?page=${page}&per_page=${perPage}&userId=${userInfluenceId.userInfluenceId}`, {
        replace: true,
      });
      fetchData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage, page]);

  const showWarning = (id, pal, name) => {
    setUserId(id)
    setPayPal(pal)
    setNickName(name)
    setOpenWarning(true)
  }

  const updatePay = () => {
    CommonService.postModel(`${ICTL_API_ENDPOINT.UPDATE_STATUS_PAY_INFLUENCE}${userId}`)
      .then(() => {
        fetchData()
        setUserId(null)
        setOpenWarning(false)
        setOpenSuccess(true)
      })
      .catch(() => {
        // setErr(t('admin.home.Enter_wrong_infomation'));
      })
      .finally(() => {
        // setIsLoading(false);
      });
  }


  return [
    items,
    setPage,
    setPerPage,
    total,
    perPage,
    page,
    infomation,
    updatePay,
    openSuccess,
    setOpenSuccess,
    showWarning,
    openWarning,
    setOpenWarning,
    nickname,
    payPal
  ];
};

export default useCoreComponentList;