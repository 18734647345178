import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

function ErrorDialog({ message, open, onOK, onClose }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      classes={{ paper: "min-h-[200px]" }}
    >
      <DialogActions className="!px-2 border-b border-border !justify-start">
        <button
          type="button"
          className="w-8 h-8 flex items-center justify-center"
          onClick={onClose}
        >
          <i className="fa-solid fa-xmark" />
        </button>
      </DialogActions>
      <DialogContent>
        <DialogContentText className="!text-[#222] !whitespace-pre-wrap">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onOK}
          className="!text-[#fff] !min-w-[65px] !rounded-[4px] !py-2"
          classes={{
            root: "!bg-[#0E5CB5]",
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ErrorDialog;
