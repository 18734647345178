import React from "react";
import Path from "app/operator/route/Path";
import PersonalInfoForm from "app/components/personal-info/PersonalInfoForm";
import LayoutDefault from 'app/operator/layouts/LayoutDefault';

function PersonalInfo() {
  return (
    <LayoutDefault showFooter showHeader>
      <PersonalInfoForm checkRole path={Path.account_settings} />
    </LayoutDefault>
  );
}

export default PersonalInfo;
