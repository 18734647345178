export default {
  list: {
    count_customer: "お客様2名",
    search: "お客様を検索",
    facilities_use: "利用施設",
    time_of_use: "利用時期",
    customer_name: "お客様名",
    number_time_of_use: "利用回数",
    presence_or_absence: "予約の有無",
    final_use_facility: "最終利用施設",
    last_use_date: "最終利用日",
    Facility_usage_times: "施設利用回数",
    number_of_reservations:"予約回数",
    customer: "お客様{{quantity}}名"
  },
  detail: {
    customer_information: "お客様情報",
    name: "氏名",
    language_you_can_speck: "話せる言語"
  }
}