import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
// import debounce from 'lodash/debounce';
import { removeFieldEmptyValue } from 'app/utils';
import CommonService from '../../../../services/api/CommonService';
import { ICTL_API_ENDPOINT } from '../../../../services/constant';

const useCoreComponentList = (userInfluenceId) => {
  const navigate = useNavigate()
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  const pageUrl = searchParams.get('page');
  const perPageUrl = searchParams.get('per_page');
  const [items, setItems] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [total, setTotal] = useState()
  const [page, setPage] = useState(pageUrl || 1)
  const [perPage, setPerPage] = useState(perPageUrl || 20)
  const [pricePaid, setPricePaid] = useState({})
  const [openSuccess, setOpenSuccess] = useState(false)
  const [openWarning, setOpenWarning] = useState(false)
  const [userId, setUserId] = useState()
  const [payPal, setPayPal] = useState('')
  const [nickname, setNickname] = useState('')

  const params = {
    page,
    per_page: perPage,
  }
  const fetchData = useCallback(async () => {
    await CommonService.getModelList(ICTL_API_ENDPOINT.GET_LIST_INFLUENCE, {
      ...removeFieldEmptyValue(params),
    })
      .then((res) => {
        const resutl = res.data.data
        setItems(resutl.row);
        setTotal(resutl.total)
        setPricePaid(resutl.result)
        // setloading(false);
        // setLoadingPage(false)
      })
      .catch(() => {
        // console.log(err);
      })
      .finally(() => {
        // console.log(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    const options = {
      page,
      per_page: perPage,
      useId: userInfluenceId?.userInfluenceId,
    };
    const url = new URLSearchParams(removeFieldEmptyValue(options));
    navigate(`?${url}`, { replace: true });
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage, page]);

  const showWarning = (id, pal, name) => {
    setUserId(id)
    setPayPal(pal)
    setNickname(name)
    setOpenWarning(true)
  }

  const updatePay = async () => {
    await CommonService.postModel(`${ICTL_API_ENDPOINT.UPDATE_STATUS_PAY_INFLUENCE}${userId}`)
    .then(() => {
      fetchData()
      setUserId(null)
      setOpenWarning(false)
      setOpenSuccess(true)
    })
    .catch(() => {
      // setErr(t('admin.home.Enter_wrong_infomation'));
    })
    .finally(() => {
      // setIsLoading(false);
    });
  }


  return [
    items,
    setPage,
    setPerPage,
    total,
    perPage,
    page,
    pricePaid,
    updatePay,
    openSuccess,
    setOpenSuccess,
    showWarning,
    openWarning,
    setOpenWarning,
    nickname,
    payPal
  ];
};

export default useCoreComponentList;