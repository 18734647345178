import moment from "moment";
import { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { EResponseStatus, PER_PAGE } from "../../../constant";
import CommonService from "../../../services/api/CommonService";
import { ICTL_API_ENDPOINT } from "../../../services/constant";
import { removeFieldEmptyValue } from "../../../utils";

const useCoreComponentList = ({ model }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const paramSearch = new URLSearchParams(location.search);

  const initDate = () => {
    if (
      paramSearch.get("date") &&
      Date.parse(new Date(paramSearch.get("date")))
    ) {
      return new Date(paramSearch.get("date"));
    }
    return new Date(moment().startOf("months").format("YYYY-MM-DD"));
  };

  const initialFilterData = {
    date: initDate(),
    page: paramSearch.get("page") || 1,
    per_page: PER_PAGE,
    status: paramSearch.get("status")
      ? paramSearch.get("status")?.split(",")
      : null,
    search: paramSearch.get("search") || "",
  };

  const [filters, setFilters] = useState(initialFilterData);
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState();
  const [pricePaid, setPricePaid] = useState({});
  const [openSuccess, setOpenSuccess] = useState(false);

  const fetchData = useCallback(async () => {
    // handle change url
    const options = { ...filters };
    options.date = options.date
      ? moment(options.date).format("YYYY-MM-DD")
      : "";
    const url = new URLSearchParams(removeFieldEmptyValue(options));
    navigate(`?${url}`, { replace: true });

    await CommonService.getModelList(model, {
      ...removeFieldEmptyValue({
        ...filters,
        date: filters?.date ? moment(filters.date).format("YYYY-MM-DD") : null,
      }),
    })
      .then((res) => {
        const resutl = res.data.data;
        setItems(resutl.row);
        setTotal(resutl.total);
        setPricePaid(resutl.result);
        // setloading(false);
        // setLoadingPage(false)
      })
      .catch(() => {
        // console.log(err);
      })
      .finally(() => {
        // console.log(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const exportListInvoice = async () => {
    await CommonService.getModelList(ICTL_API_ENDPOINT.EXPORT_LIST_PAY, filters)
      .then((response) => {
        if (response.status === EResponseStatus.SUCCESS) {
          // temp set data
          window.location.href = response.data.data.file_url;
        }
      })
      .catch(() => {
        // setErr(t('admin.home.Enter_wrong_infomation'));
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  const updateStatus = async (id, category) => {
    CommonService.postModel(
      `${ICTL_API_ENDPOINT.UPDATE_STATUS_PAY}/${id}/${category}`
    )
      .then(() => {
        fetchData();
        setOpenSuccess(true);
      })
      .catch(() => {
        // setErr(t('admin.home.Enter_wrong_infomation'));
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  return [
    items,
    filters,
    total,
    pricePaid,
    exportListInvoice,
    updateStatus,
    openSuccess,
    setOpenSuccess,
    setFilters,
  ];
};

export default useCoreComponentList;
