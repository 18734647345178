export default {
  roomAllocationConfirmation: "配室の確認",
  preparedDesc:
    "只今準備中です。</br></br>ステータスを「準備完了」に変更しますか？",
  dontChangeStatus: "ステータスを変更しない",
  beReady: "準備完了にする",
  dontAllocate: "配室しない",
  allocateRoom: "配室する",
  roomPreparingAllocateContent:
    "この在庫は、以下の日時に予約が有りますが配室しますか？",
  customerInfo: "お客様情報",
  searchForCustomerInfo: "お客様情報検索",
  customerLanguage: "話せる言語",
  furtherAdd: "更に追加",
  planSelection: "プラン選択",
  plan: "プラン",
  possessionPoints: "保有ポイント",
  usagePoints: "利用ポイント",
  unPaid: "未精算",
  paid: "精算済み",
  accountingAmount: "お会計金額",
  totalAmountCal: "総合計",
  orderHistory: "注文履歴",
  keyOffer: "鍵提供",
  keyOffered: "鍵提供済み",
  calculate: "精算",
  unpaidAmount: "未精算金額",
  exportReceipt: "領収証発行",
  reservationInformation: "予約情報",
  reservationDateRange: "予約日時",
  showAllEmptyRoom: "空室を全て表示",
  roomNumber: "部屋番号",
  seatNumber: "座席番号",
  inventoryName: "在庫名",
  receptionTime: "受付時間",
  productName: "商品名",
  informationDay: "事業者ホーム",
  informationBusinessOperator: " 事業者情報",
  informationContract: "契約情報",
  realTime: "リアルタイム",
  reserve: "予約",
  order: "注文",
  gettingReady: "準備中",
  ready: "準備完",
  reserved: "利用中",
  hasKey: "鍵提供済み",
};
  // operator.staff.dashboard
  