import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "app/redux/store";
import AppRoute from "app/route";
import "app/assets/styles/tailwind.css";
import "app/assets/styles/index.scss";
import { I18nextProvider } from "react-i18next";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import i18n from "app/langs";
import localization from "moment/locale/ja";
import moment from "moment";
import dayjs from "dayjs";
import i18next from "i18next";
import reportWebVitals from "./reportWebVitals";
import "./validation";
import BlockUI from "./components/BlockUI";

require("dayjs/locale/ja");
require("dayjs/locale/zh-cn");
require("dayjs/locale/en");

moment.updateLocale("ja", localization);

const theme = createTheme({
  typography: {
    fontFamily: ["Yu Gothic UI"].join(","),
  },
});

// eslint-disable-next-line no-undef
const root = ReactDOM.createRoot(document.getElementById("root"));
if (i18next.resolvedLanguage === "cn") {
  dayjs.locale("zh-cn");
} else {
  dayjs.locale(i18next.resolvedLanguage);
}

root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nextProvider i18n={i18n}>
          <AppRoute />
          <BlockUI />
        </I18nextProvider>
      </PersistGate>
    </Provider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
