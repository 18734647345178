export default {
  list: {
    select_month: "Select a Month",
    reservation_request: "2022年9月の予約請求",
    deposited: "Deposited",
    not_payment: "Unpaid Payment",
    price: "¥",
    count_request: "Total Number of Invoices: {{value}}",
    placeholder_search: "Search for a Business",
    business_name: "Business Name",
    status: "Status",
    invoice_amount: "Invoice Amount",
    payment_type: "Payment Type",
    invoice_number: "Invoice Number",
    unpaid: "Unpaid Payment",
    paid_1: "Operational Payment Confirmed",
    confirming: "Paid",
    paid: "Paid",
    inital_cost: "初期費用",
    monthly_usage_price: "システム使用料",
    ad_service_charge: "広告マッチング手数料",
    date_pay: "Invoice Date",
    return: "支払済み",
    updateUnpaid: "Paid",
    total_bill: "Total Invoice Amount",
    unpaid_amount: "Unpaid Amount",
    paid_mount: "Paid Amount",
    operation_payment_confirmed: "Operational Payment Confirmed",
    node: '※Please note that operational payment confirmation may take up to 5 business days. After the payment is confirmed, the status will automatically change to "Operation payment confirmed"',
  },
  detail: {
    influencer_name: "Influencer Name",
    case_name: "案件名",
    billing_amount: "Invoice Amount",
    price: "Unit Price",
    number: "件数",
    claim_number: "Invoice Number",
  },
};
