import React, { useContext } from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { updatePersonalInfoAction } from "app/utils/page/personal_info";
import { useDispatch } from "react-redux";
import { setProfileStore } from "app/redux/profileReducer";
import InputText from "app/components/InputText";
import EditableForm from "app/components/EditableForm";
import UserContext from "./UserContext";

const EDIT_NAME = "show_edit_name";

function NameSettingField(props) {
  const { showEdit, setShowEdit, setOpenSuccess, setOpenError } = props;
  const { t } = useTranslation();
  const { userInfo, setUserInfo, profile } = useContext(UserContext);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    family_name: Yup.string().max(64).required().trim(),
    first_name: Yup.string().max(64).required().trim(),
  });
  const initialValues = {
    family_name: "",
    first_name: "",
  };

  const updateUserInfo = (values, context) => {
    const { setValues } = context;
    updatePersonalInfoAction({
      userInfo,
      values,
      setUserInfo,
      setValues,
      setOpenSuccess,
      setOpenError,
      setShowEdit,
      callbackSuccess: (data) => {
        const object = { ...profile };
        object.family_name = data.family_name;
        object.first_name = data.first_name;
        dispatch(setProfileStore(object));
      },
    });
  };

  const renderForm = ({ values, setFieldValue }) => {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="w-full">
          <InputText
            required
            inputId="personal_info_last_name"
            title={t("operator.accountSetting.personal_info.last_name")}
            inputName="family_name"
            setFieldValue={setFieldValue}
            inputValue={values?.family_name}
            maxLength={64}
          />
        </div>
        <div className="w-full">
          <InputText
            required
            inputId="personal_info_first_name"
            title={t("operator.accountSetting.personal_info.first_name")}
            inputName="first_name"
            setFieldValue={setFieldValue}
            inputValue={values?.first_name}
            maxLength={64}
          />
        </div>
      </div>
    );
  };

  return (
    <EditableForm
      editForm={renderForm}
      onSubmit={updateUserInfo}
      validationSchema={validationSchema}
      initialValues={initialValues}
      label={t("operator.accountSetting.personal_info.name_label")}
      editField={EDIT_NAME}
      formValues={userInfo}
      displayValue={`${userInfo?.family_name || ""} ${
        userInfo?.first_name || ""
      } `}
      showEdit={showEdit}
      setShowEdit={setShowEdit}
      required
    />
  );
}

export default NameSettingField;
