import React, { useEffect, useState } from "react";
import MessageFileIcon from "app/chat/components/MessageFileIcon";
import moment from "moment";
// import { detailFormatDate } from "../../utils";

function MineMessage({ id, chatDetail, nextChatDetail }) {
  const [messageContents, setMessageContents] = useState([]);
  const showAppendix =
    !nextChatDetail || nextChatDetail.send_by !== chatDetail.send_by;

  const showSendAt =
    showAppendix ||
    moment(nextChatDetail.send_at).diff(chatDetail.send_at, "minute") > 1;

  useEffect(() => {
    const messageSpitCode = new Date().getTime();

    const detectUrlInMessage = (text) => {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlRegex, (url) => {
        return `${messageSpitCode}url=${url}${messageSpitCode}`;
      });
    };

    const messageWithUrl = detectUrlInMessage(chatDetail.message);
    const messageSpit = messageWithUrl.split(messageSpitCode);
    setMessageContents(messageSpit);
  }, [chatDetail.message]);

  return (
    <div className="message-box-wrapper flex justify-end mt-1" id={id}>
      <div className="message-box-layout max-w-[75%] flex flex-col items-start">
        <div className="w-full">
          <div className="message-box relative flex justify-end">
            {showAppendix &&
              (!chatDetail.mediaFiles || !chatDetail.mediaFiles.length) && (
                <i className="message-appendix absolute fa-solid fa-play text-gray-200 text-[10px] -rotate-[90deg] -right-[3.3px] -bottom-[1px] -z-10" />
              )}
            {chatDetail.mediaFiles && chatDetail.mediaFiles.length ? (
              <MessageFileIcon
                mediaType={chatDetail.mediaFiles[0].media_type}
                thumbUrl={chatDetail.mediaFiles[0].thumbnail_url}
                mediaUrl={chatDetail.mediaFiles[0].media_url}
                fileName={chatDetail.message}
              />
            ) : (
              <div className="message-content bg-indigo-100 rounded-md p-2">
                <span className="text-[12px] md:text-[14px] break-all whitespace-pre-wrap">
                  {messageContents.map((message, index) => {
                    if (message.includes("url=")) {
                      const url = message.replace("url=", "");
                      return (
                        <a
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                          className="text-blue-600 underline"
                          href={url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {url}
                        </a>
                      );
                    }
                    return message;
                  })}
                </span>
              </div>
            )}
          </div>
        </div>
        {showSendAt && (
          <div className="message-time flex items-start justify-end w-full text-[10px] mb-1">
            {moment(chatDetail.send_at).format("MM/DD HH:mm")}
          </div>
        )}
      </div>
    </div>
  );
}

export default MineMessage;
