export default {
  list: {
    title: "Subject",
    total: "{{value}} Notifications",
    date: "Post Period",
    notification_group: "Distribution Target",
    noti: "お知らせ",
    item: "件",
    create_notification: "Create Notification",
  },
  detail: {
    title: "Subject",
    message: "Text",
    date: "Post Period",
    notification_group: "Distribution Target",
    delivery_date: "開始",
    end_date: "終了",
    customer: "Business Operator",
    influencer: "利用客",
    svm: "Influencers",
  },
};
