import React from "react";
import { BrowserRouter, useRoutes } from "react-router-dom";
import authRoute from "app/auth/route";
import ictlRoute from "app/ictl/route";
import { useSelector } from "react-redux";
import operatorRoute from "app/operator/route";
import Path from "app/operator/route/Path";
import OperatorInfo from "app/operator/pages/Operator/Info";
import NotFound from "../auth/pages/404";
import AuthRouteItcl from "./AuthRouteItcl";
import AuthRouteFacility from "./AuthRouteFacility";
import AuthLogin from "./AuthLogin";
import RedirectToRegisterFacility from "./RedirectToRegisterFacility";

import { Group, Roles } from "../constant";

function Router() {
  const user = useSelector((state) => state.auth.user);
  const roles = user?.group_roles.roles[0];
  const group = user?.group_roles.group;
  if (roles === Roles.REPRESENT) {
    operatorRoute.push({ path: Path.operator_info, element: <OperatorInfo /> });
  }

  const element = useRoutes([
    {
      path: "/",
      element:
        group === Group.FACILITY ? <AuthRouteFacility /> : <AuthRouteItcl />,
      children: group === Group.FACILITY ? [...operatorRoute] : [...ictlRoute],
    },
    // {
    //   path: "/",
    //   element: <AuthRouteStaff />,
    //   children: [...staffRoute],
    // },
    {
      path: "/",
      element: <AuthLogin />,
      children: [...authRoute],
    },
    {
      path: "/redirect-to-provider-host",
      element: <RedirectToRegisterFacility />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);
  return element;
}

export default function AppRoute() {
  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );
}
