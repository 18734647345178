import operator from "../operator/langs/cn";
import admin from "../ictl/langs/IctlCN";
import chat from "../chat/langs/cn";

export default {
  admin,
  operator,
  chat,
  auth: {
    text_hearder: "ログインタイプを選択してください",
    text_login_email: "ログイン情報を入力してください",
    register_or_login: "ログインまたは登録",
    mashupWelcome: "歡迎來到Buzz Traveler",
    continue: "繼續",
    continue_change_password: "繼續",
    current_password: "目前密碼",
    new_password: "新密碼",
    confirm_new_password: "確認密碼",
    change_password: "パスワードを変更してください。",
    password_do_no_match: "密碼不符。",
    email_not_exit:
      "メールアドレスはまだ登録されていません。もう一度ご確認ください。",
    password_reset_guide: "パスワード再設定ご案内",
    textSendEmail1:
      "パスワードリセット用のメールを、登録のメールアドレスに送信しました。",
    textSendEmail2:
      "メールに記載のパスワード変更画面にアクセス頂き、メールに記載の一時パ",
    textSendEmail3: "スワードを入力し、新しいパスワードを設定してください",
    change_password_success: "更新されました。",
    login: {
      reset_password: "パスワード忘れの方はこちらをクリックして下さい",
      Password: "密碼",
      Login: "登入",
      Email_format: "電子郵件地址格式不正確。",
      Enter_wrong_infomation: "使用者名稱或密碼不正確。",
    },
    register: {
      Instagram: "以Instagram繼續",
      TikTok: "以Tiktok繼續",
      Twitter: "以Twitter繼續",
      Facebook: "以Facebook繼續",
      Google: "以Google繼續",
      Line: "以LINE繼續",
      Message: "メールアドレスで続行",
      Text_register: "事業者登録への扉を開きましょう",
      Button_register: "事業者登録をはじめる",
      Nickname: "Nickname",
      confirm_new_password: "確認密碼",
      password_regex:
        "請輸入 8 至 64 個字元的密碼，包括半角字母數字字元和符號。",
    },
    message: {
      error_login: "エラーログイン",
    },
  },
  common: {
    email: "電子郵件地址",
    male: "男性",
    female: "女性",
    other: "無指定性別",
    other1: "無特別指定",
    image: "写真",
    japan: "日本",
    currencySymbol: "¥ ",
    passage: "件",
    person: "人",
    quantity: "個数",
    followers: "追蹤人數",
    date: "當日",
    end_date: "本月最後一天",
    btn: {
      download_csv: "下載 CSV 文件",
      export: "匯出",
      filter: "篩選條件",
      select_language: "言語と地域",
    },
    message: {
      update_success: "更新完成。",
      delete_success: "削除されました。",
      update_fail: "更新失敗。請重試。",
      delete_fail: "削除に失敗しました。もう一度お試しください。",
      not_exist: "削除対象が存在しません。",
      wrong_current_password: "目前密碼不正確。",
      oversize_file: "最大文件大小為{{size}}MB。",
      oversize_image: "最大影像大小為（{{size}}MB）",
      oversize_video: "可以上傳的最大影片大小為 {{size}}MB。",
      wrong_format_file: "可以上傳以下文件。\n{{format}}",
      cannot_display_data: "無法讀取資料。請重試。 ",
      no_data_display: "無數據顯示。",
      error_upload: "上傳文件時發生錯誤。請再試一次。",
      INVALID_PASSWORD: "目前密碼不正確。",
      delete_confirm: "您確定要刪除嗎？",
      createSuccess: "註冊已完成。",
      createFailed: "註冊失敗。請重試。",
      importFailed: "フォーマットが正しくありません。",
      fieldDateMin: "不能為發布期設定過去的日期。",
      fieldDateMax: "結束時間不能設定為超過開始時間的日期。",
      fileTooSmall: "請上傳大於 50KB 的圖片。",
      invalidFileNumber: "您最多可以上傳 50 個項目。最多可以上傳 3 個影片。",
      "Request failed with status code 403": "更新失敗。請重試。",
      "Request failed with status code 401": "更新失敗。請重試。",
      cannot_set_video_as_cover: "影片無法設定為封面照片。",
      at_least_5: "請上傳至少 5 張照片。",
      ACCOUNT_EXIST: "該電子郵件地址已存在。\n{{email}}",
      cancel_create:
        "保存されていません。編集内容はキャンセルされますがよろしいですか？",
      select_lang: "使用言語と地域を選択してください",
      DATA_MAX_RECORD: "バナーは最大{{max}}個までアップロードできます。",
      upload_avatar: "更改照片",
      delete_log_out: "アカウントが削除されているためログアウトされます。",
      wrong_dimension:
        "幅{{width}}px、高さ{{height}}pxのファイルを選択してください。",
      choose_image: "請選擇圖片。",
      no_input_yet: "未設定",
      confirm_password_not_match: "密碼不符。",
      email_message: "請輸入電子郵件。",
      email_invalid_message: "電子郵件帳號格式錯誤。",
      not_found: "抱歉，我們找不到您要找的內容！",
      password_not_found: "請輸入您的密碼",
      current_password_not_found: "目前密碼为必填项。",
      new_password_not_found: "請輸入新密碼。",
    },
    action: {
      edit: "編輯",
      cancel: "取消",
      save: "儲存",
      back: "返回",
      next: "次へ",
      apply: "申請工作",
      delete: "刪除",
      add_more: "添加更多",
      clear: "清除",
      apply1: "申請",
      confirm: "確認",
      delete_2: "刪除",
      delete_3: "取消",
      yes: "儲存",
      send: "提交",
      close: "關閉",
      ping_platform: "請求翻譯",
      update: "更新",
    },
    date_format: {
      dd_mm_yyyy: "DD日MM月YYYY年",
      yyyy_mm_dd: "YYYY年MM月DD日",
      yyyy_mm: "YYYY年MM月",
      mm_dd: "MM月DD日",
      dd: "DD日",
      just_now: "vừa xong",
      mins_ago: "phút trước",
      today: "hôm nay",
      yesterday: "hôm qua",
      yyyy_MM: "yyyy年MM月",
    },
    weekDays: {
      monday: "月",
      tuesday: "火",
      wednesday: "水",
      thursday: "木",
      friday: "金",
      saturday: "土",
      sunday: "日",
    },
    weekDaysType1: {
      monday: "月曜",
      tuesday: "火曜",
      wednesday: "水曜",
      thursday: "木曜",
      friday: "金曜",
      saturday: "土曜",
      sunday: "日曜",
    },
    days: {
      today: "今日",
      yesterday: "昨日",
      thisWeek: "今週",
      lastWeek: "先週",
      thisMonth: "今月",
      lastMonth: "先月",
    },
    common_booking: {
      separator_guest_info: "、",

      prefix_number_users: "ゲスト",
      suffixes_number_users: "人",
      prefix_number_user: "ゲスト",
      suffixes_number_user: "人",

      prefix_number_infants: "乳幼児",
      suffixes_number_infants: "名",
      prefix_number_infant: "乳幼児",
      suffixes_number_infant: "名",

      prefix_number_pets: "ペット数：",
      suffixes_number_pets: "",
      prefix_number_pet: "ペット数：",
      suffixes_number_pet: "",
    },
    charge_tax_titles: {
      bathing_tax: "入湯税",
    },
  },
  validate_mark: "*",
  validate_message: {
    required: "{{path}}为必填项。",
    max_length: "{{path}}超出最大長度{{max}}。",
    min_length: "請輸入至少{{path}}{{min}}個字元。",
    wrong_format: "{{path}}格式不正確。",
    onlyWhiteSpace: "{{path}}には空白だけを含めることはできません。",
  },
  validation_field: {
    post_code: "郵遞區號",
    replyMessage: "信息",
    password: "密碼",
    confirm_password: "確認密碼",
    email: "電子郵件地址",
    projectTitle: "招募案件項目名稱",
    projectDescription: "招募案件說明",
    projectRewardType: "酬勞類型",
    projectReward: "酬勞",
    desiredCondition: "酬勞條件",
    ageGroup: "網紅的要求年齡範圍",
    gender: "性別",
    influencer_gender: "網紅性別",
    minFollowers: "指定社群媒體的最少追蹤者/追蹤者數量",
    adMethod: "廣告方式",
    tagInclude: "貼文中應包含的標籤",
    targetUrl: "您希望廣告的頁面",
    adMedia: "指定社群媒體",
    targetUsername: "標籤帳戶",
    textInclude: "投稿に含める内容",
    family_name: "姓氏",
    first_name: "名稱",
    familyName: "姓氏",
    firstName: "名稱",
    login_id: "電子郵件地址",
    phone_number: "電話號碼",
    offerMessage: "信息",
    completeReject: "拒絕完成審核的原因",
    completeApprove: "批准消息已完成",
    current_password: "目前密碼",
    new_password: "新密碼",
    confirm_new_password: "確認密碼",
    service_provider_name: "企業（者）名稱",
    address_address: "地址",
    address_city: "縣市",
    address_street: "州/省",
    memo: "メモ",
    name: "名称",
    registration_number: "インボイスナンバー",
    pricing_plans: "月額プラン",
    reason: "原因",
    postcode: "郵遞區號",
    country: "國家",
    city: "縣市",
    street: "州/省",
    address: "地址",
    banner_title: "標題",
    banner_files: "圖像",
    banner_delivery_date: "開始日",
    banner_end_date: "終了日",
    banner_address_url: "URL",
    offer_price: "原價",
    account_first_name: "帳戶名稱",
    account_family_name: "帳戶名稱",
    account_name: "帳戶名稱",
    account_email: "電子郵件地址",
    account_phone: "電話號碼",
    delivery_date: "開始",
    end_date: "終了",
    manager_first_name: "全名",
    manager_family_name: "全名",
    invoice_number: "發票號碼",
    manager_name: "全名",
    bussiness_person_email: "電子郵件地址",
    apartment_name: "單位名稱、房號等",
    full_address: "地址",
    projectTitleCN: "招募案件項目名稱",
    projectTitleEN: "招募案件項目名稱",
    projectDescriptionCN: "招募案件說明",
    projectDescriptionEN: "招募案件說明",
    desiredConditionEN: "酬勞條件",
    desiredConditionCN: "酬勞條件",
    textIncludeEN: "投稿に含める内容",
    textIncludeCN: "投稿に含める内容",
    ageGroupStart: "網紅的要求年齡範圍",
    ageGroupEnd: "網紅的要求年齡範圍",
    longitude: "經度",
    latitude: "緯度",
    lat_long: "緯度、經度",
  },
  dialog: {
    customer_search: {
      title: "お客様選択",
      placeholder: "お客様名で検索",
    },
    product_search: {
      title: "商品選択",
      placeholder: "商品名で検索",
    },
  },
  ictl_header: {
    home: "控制台",
    business_person: "業者",
    influencer: "網紅",
    operation: "營運方",
    claim: "計費",
    payment: "付款",
    notification: "通知",
    banner: "橫幅廣告",
    account: "您的帳戶",
  },
  operator_header: {
    dashboard: "業者首頁",
    other_menu: "選單",
    jobs: "招募案件",
    pay: "付款方式",
    operator_info: "企業",
  },
  common_header: {
    profile: "簡介",
    account_setting: "您的帳戶",
    log_out: "登出",
  },
  common_footer: {
    privacy_policy: "隱私權條款",
    term_service: "條款與條件",
    company_info: "公司企業資訊",
    currency: "日文",
    copyright: "© Copyright 2023 Meikatsu Inc. All Rights Reserved."
  },
  project_request_select: {
    request: "有翻譯請求",
    not_request: "無翻譯請求",
  },
  project_status_select: {
    public: "公開",
    private: "非公開",
    trash: "削除",
  },
  change_language: "語言和地區",
  or1: "或",
  or2: "是",
  or3: "",
  profile: {
    register_as: "在 {{- date}} 註冊成為企業經營者帳戶",
    register_as_operator: "註冊成為來自 {{- date}} 的營運帳戶",
  },
  gender: "性別",
  business_operator: "業者",
  operator_admin: "營運方",
  notification_popup: {
    show_more: "請確認詳細資料",
  },
  media: {
    twitter : 'X (舊Twitter)'
  },
  generation1: "幾歲",
  generation2: "幾歲",
  generation3: "歲以上"
};
