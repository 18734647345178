/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
import React, { useRef, useState, useEffect, useCallback } from "react";
import Chatbox from "app/chat/components/Chatbox";
import { useTranslation } from "react-i18next";
import ChatService from "app/services/chat/api/ChatService";
import { Button, Popover, Rating, Tooltip } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import JSZip from "jszip";
import AdProjectService from "app/services/api/operatorService/AdProjectService";
import ImageItem from "app/components/ImageItem";
import { useDispatch } from "react-redux";
import {
  socketIO,
  SOCKET_LISTENER,
  SOCKET_NAMESPACE,
  SOCKET_TYPE,
} from "app/context/socket";
import {
  formatNumber,
  getFileName,
  generateSNSIcon,
  handleSNSLink,
  getUpdatedByText,
  getChangeLogItemByStatus,
} from "app/utils/index";
import {
  APPLIED_PROJECT_STATUS,
  EFileType,
  EResponseStatus,
  GENDER,
  INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS,
  PROJECT_APPLIED_TYPE,
} from "app/constant";
import { downloadFile } from "app/services/api";
import { setActive } from "app/redux/blockUIReducer";
import { REPLY_STATUS, SEND_BY } from "app/chat/enum/ChatEnum";
import ConfirmDialog from "app/operator/pages/AdvertisementManage/AdvertisementSocial/ConfirmDialog";
import { setProjectBatchForV } from "app/redux/projectBatch";
import { STATUS } from "app/operator/pages/AdvertisementManage/AdvertisementSocial/TabGroup";

const male = require("app/assets/images/icon-male.png");
const female = require("app/assets/images/icon-female.png");
const other = require("app/assets/images/icon-other.png");

function AccountInfo(props) {
  const dispatch = useDispatch();
  const {
    data,
    onCloseDrawer,
    updateLastMessage,
    projectId,
    media,
    tabStatus,
    chatRoomIdQuery,
    influencerIdQuery,
  } = props;
  const { t } = useTranslation();
  const accountInfoRef = useRef();
  const [updatedDataBy, setUpdatedDataBy] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [replyAnchorEl, setReplyAnchorEl] = useState(null);
  const [apiMessages, setApiMessages] = useState([]);
  const [isLastPage, setIsLastPage] = useState(true);
  const [socialMedia, setSocialMedia] = useState([]);
  const [info, setInfo] = useState({});
  const open = Boolean(anchorEl);
  const [page, setPage] = useState(1);
  const openReply = Boolean(replyAnchorEl);
  const [lstFile, setLstFile] = useState([
    {
      id: 1,
      url: "",
    },
    {
      id: 2,
      url: "",
    },
    {
      id: 3,
      url: "",
    },
  ]);
  const {
    avatar_url,
    gender,
    nickname,
    influencer_id,
    chat_room_id,
    is_ping,
    reply_status,
  } = data || {};

  const [rating, setRating] = useState(0);
  const [portfolio, setPortfolio] = useState({});
  const [commentFromAdmin, setCommentFromAdmin] = useState({});
  const [projectType, setProjectType] = useState(null);
  const [minHeight, setMinHeight] = useState(500);

  // validate
  const validationSchema1 = Yup.object().shape({
    reason: Yup.string().required(),
  });
  const initialValues1 = {
    reason: "",
  };

  const validationSchema2 = Yup.object().shape({
    completeReject: Yup.string().required(),
  });
  const initialValues2 = {
    completeReject: "",
  };

  const validationSchema3 = Yup.object().shape({
    completeApprove: Yup.string().required(),
  });
  const initialValues3 = {
    completeApprove: "",
  };

  const [statusText, setStatusText] = useState("");

  const [rateForInfluencer, setRateForInfluencer] = useState(0);

  // popup open state
  const [openApplyRejected, setOpenApplyRejected] = useState(false); // DECLINED
  const [openCompleteRejected, setOpenCompleteRejected] = useState(false); // DECLINE_DELIVERABLE
  const [openCompleteApproved, setOpenCompleteApproved] = useState(false); // APPROVED_DELIVERABLE

  // get first 5 messages
  const getFiveMessages = useCallback(() => {
    setIsLoading(true);
    const limit = 5;
    ChatService.getAllMessages({
      chatRoomId: chat_room_id || chatRoomIdQuery,
      page,
      limit,
    })
      .then((res) => {
        if (res.data && res.data.items) {
          setApiMessages(res.data.items.reverse());
          setIsLastPage(res.data.meta.currentPage === res.data.meta.totalPages);
        }
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat_room_id]);

  const handleCountProjectBatch = useCallback(() => {
    ChatService.countUnreadAndMarkActionForV().then((response) => {
      if (response.status === EResponseStatus.SUCCESS) {
        const { ping_count: pingCount, unread_count: unreadCount } =
          response.data;
        dispatch(
          setProjectBatchForV({
            pingCount,
            unreadCount,
          })
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tabStatus === STATUS.UNREAD_8) {
      handleCountProjectBatch();
    }
  }, [handleCountProjectBatch, tabStatus]);

  // get deliverable
  const getDeliverable = useCallback(() => {
    AdProjectService.getInfluencerDeliverable(chat_room_id || chatRoomIdQuery)
      .then((res) => {
        if (res.status === EResponseStatus.SUCCESS) {
          setInfo(res?.data?.data || {});
          const files = res?.data?.data?.deliverable?.deliverables?.files || [];
          const lstTemp = [
            {
              id: 1,
              url: "",
            },
            {
              id: 2,
              url: "",
            },
            {
              id: 3,
              url: "",
            },
          ];
          files.forEach((img, index) => {
            lstTemp.forEach((file) => {
              if (index + 1 === file.id) {
                file.url = img;
              }
            });
          });
          setLstFile(JSON.parse(JSON.stringify(lstTemp)));
        }
      })
      .catch(() => {
        // handle error
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat_room_id]);

  useEffect(() => {
    if (reply_status === REPLY_STATUS.UNREAD) {
      ChatService.updateChatRoomReplyStatusForV(
        chat_room_id || chatRoomIdQuery
      ).then(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat_room_id]);

  // get detail influencer
  useEffect(() => {
    AdProjectService.getInfluencerByProject(
      projectId,
      influencer_id || influencerIdQuery
    ).then((res) => {
      if (res.status === EResponseStatus.SUCCESS) {
        const project_type = res?.data?.data?.project_type;
        const deliverableLog = res?.data?.data?.deliverable_status_change_log;
        const changeLog = res?.data?.data?.applied_status_change_log;
        const applied_status = res?.data?.data?.applied_status;
        const { status } = changeLog[changeLog.length - 1];
        if (
          applied_status !== APPLIED_PROJECT_STATUS.WAIT_APPROVAL_RECRUITMENT &&
          status !== INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.CANCELED
        ) {
          getFiveMessages();
        }

        if (applied_status === APPLIED_PROJECT_STATUS.WAIT_APPROVAL_FINISHED) {
          getDeliverable();
        }

        if (applied_status === APPLIED_PROJECT_STATUS.REJECT_REPORT) {
          const rejectArray = deliverableLog.filter(
            (item) =>
              item.status === INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.REJECTED
          );
          if (rejectArray.length) {
            const reject = rejectArray[rejectArray.length - 1];
            setUpdatedDataBy(reject);
          }
        } else {
          setUpdatedDataBy(changeLog[changeLog.length - 1]);
        }
        setStatusText(res?.data?.data?.applied_status);
        setRating(res?.data?.data?.rating);
        setPortfolio(res?.data?.data?.portfolio);
        setProjectType(project_type);
        setSocialMedia(res?.data?.data?.social_media_activities || []);

        let result = "";
        if (applied_status === APPLIED_PROJECT_STATUS.COMPLETED) {
          result = getChangeLogItemByStatus(
            changeLog,
            INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.FINISHED
          );
        }
        if (applied_status === APPLIED_PROJECT_STATUS.CANCEL_TRANSACTION) {
          result = getChangeLogItemByStatus(
            changeLog,
            INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.CANCELED
          );
        }
        if (applied_status === APPLIED_PROJECT_STATUS.REJECT_REPORT) {
          result = getChangeLogItemByStatus(
            deliverableLog,
            INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.REJECTED
          );
        }
        if (
          !applied_status &&
          project_type === PROJECT_APPLIED_TYPE.SPECIAL_OFFER
        ) {
          result = getChangeLogItemByStatus(
            changeLog,
            INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.REVIEW
          );
        }
        setCommentFromAdmin(result);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    const socket = socketIO(SOCKET_TYPE.ITCL, SOCKET_NAMESPACE.BOOKING, {
      room_id: chat_room_id || chatRoomIdQuery,
    });

    socket.on(SOCKET_LISTENER.UPDATE_APPLY_PROJECT_STATUS, (response) => {
      setStatusText(response.status);
      const applied_status_change_log = response?.applied_status_change_log;
      const deliverable_status_change_log =
        response?.deliverable_status_change_log;
      if (applied_status_change_log?.length) {
        setUpdatedDataBy(
          applied_status_change_log[applied_status_change_log.length - 1]
        );
      }
      if (response.status === APPLIED_PROJECT_STATUS.WAIT_APPROVAL_FINISHED) {
        getDeliverable();
      }
      let result = "";
      if (response.status === APPLIED_PROJECT_STATUS.COMPLETED) {
        result = getChangeLogItemByStatus(
          applied_status_change_log,
          INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.FINISHED
        );
      }
      if (response.status === APPLIED_PROJECT_STATUS.CANCEL_TRANSACTION) {
        result = getChangeLogItemByStatus(
          applied_status_change_log,
          INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.CANCELED
        );
      }
      if (response.status === APPLIED_PROJECT_STATUS.REJECT_REPORT) {
        result = getChangeLogItemByStatus(
          deliverable_status_change_log,
          INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.REJECTED
        );
        setUpdatedDataBy(result);
      }
      if (response.status === APPLIED_PROJECT_STATUS.IN_PROGRESS) {
        result = getChangeLogItemByStatus(
          applied_status_change_log,
          INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.ACCEPTED
        );
      }
      setCommentFromAdmin(result);
    });

    return () => {
      socket.off(SOCKET_LISTENER.UPDATE_APPLY_PROJECT_STATUS);
    };
  }, [chat_room_id, getDeliverable, chatRoomIdQuery]);

  const handleOpen = (event) => {
    setAnchorEl(event.target);
  };

  useEffect(() => {
    if (statusText === APPLIED_PROJECT_STATUS.WAIT_APPROVAL_FINISHED) {
      setMinHeight("unset");
    } else {
      setMinHeight(500);
    }
  }, [statusText]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReasonOpen = (event) => {
    setReplyAnchorEl(event.target);
  };

  const handleReasonClose = (resetForm) => {
    setReplyAnchorEl(null);
    resetForm();
  };

  const changeStatusApplied = ({ body, callbackSuccess }) => {
    AdProjectService.changeStatusApplied(
      projectId,
      influencer_id || influencerIdQuery,
      body
    )
      .then((res) => {
        if (res.status === EResponseStatus.CREATED) {
          if (
            res?.data?.data?.applied_status ===
            APPLIED_PROJECT_STATUS.REJECT_REPORT
          ) {
            const deliverableLog = res?.data?.data?.deliverable_change_log;
            const result = getChangeLogItemByStatus(
              deliverableLog,
              INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.REJECTED
            );
            setUpdatedDataBy(result);
          } else {
            const changeLog = res?.data?.data?.status_change_log;
            setUpdatedDataBy(changeLog[changeLog.length - 1]);
          }
        }
        if (callbackSuccess) {
          callbackSuccess();
        }
        handleCountProjectBatch();
      })
      .catch(() => {
        // handle error
      });
  };

  const cancelProject = (values, context) => {
    const { resetForm } = context;
    const body = {
      status: INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.CANCELED,
      message: values.reason,
    };
    changeStatusApplied({
      body,
      callbackSuccess: () => {
        handleReasonClose(resetForm);
        setLstFile([
          {
            id: 1,
            url: "",
          },
          {
            id: 2,
            url: "",
          },
          {
            id: 3,
            url: "",
          },
        ]);
        setInfo({});
      },
    });
  };

  const onApplicationApproved = () => {
    const body = {
      status: INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.ACCEPTED,
      message: "",
    };
    changeStatusApplied({ body });
  };

  const onApplicationRejected = () => {
    const body = {
      status: INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.DECLINED,
      message: "",
    };
    changeStatusApplied({
      body,
      callbackSuccess: () => {
        setOpenApplyRejected(false);
      },
    });
  };

  const onCompleteApproved = (values, context) => {
    const { resetForm } = context;
    const body = {
      status: INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.APPROVED_DELIVERABLE,
      message: values.completeApprove,
      rate_number: rateForInfluencer,
    };
    changeStatusApplied({
      body,
      callbackSuccess: () => {
        setOpenCompleteApproved(false);
        setLstFile([
          {
            id: 1,
            url: "",
          },
          {
            id: 2,
            url: "",
          },
          {
            id: 3,
            url: "",
          },
        ]);
        setInfo({});
        resetForm();
      },
    });
  };

  const onCompleteRejected = (values, context) => {
    const { resetForm } = context;
    const body = {
      status: INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.DECLINE_DELIVERABLE,
      message: values.completeReject,
    };
    changeStatusApplied({
      body,
      callbackSuccess: () => {
        setOpenCompleteRejected(false);
        setLstFile([
          {
            id: 1,
            url: "",
          },
          {
            id: 2,
            url: "",
          },
          {
            id: 3,
            url: "",
          },
        ]);
        setInfo({});
        resetForm();
      },
    });
  };

  const getAllMessages = () => {
    setIsLoading(true);
    const limit = 20;
    ChatService.getAllMessages({
      chatRoomId: chat_room_id || chatRoomIdQuery,
      page,
      limit,
    })
      .then((res) => {
        if (res.data && res.data.items) {
          setApiMessages(res.data.items.reverse());
          setIsLastPage(res.data.meta.currentPage === res.data.meta.totalPages);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const downloadImage = (url, event) => {
    // download when double click
    if (event.detail === 2) {
      const body = {
        url: getFileName(url),
      };
      dispatch(setActive(true));
      downloadFile(body)
        .then((res) => {
          if (res.status === EResponseStatus.CREATED) {
            const arrayBuffer = res?.data?.data?.data?.Body?.data;
            const bytes = new Uint8Array(arrayBuffer);
            const blob = new Blob([bytes], { type: "*" });
            const href = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = href;
            link.download = res?.data?.data?.file_name;
            link.click();
          }
        })
        .finally(() => dispatch(setActive(false)));
    }
  };

  const handleFile = (fileList) => {
    const zip = new JSZip();
    const currDate = new Date();
    const dateWithOffset = new Date(
      currDate.getTime() - currDate.getTimezoneOffset() * 60000
    );
    fileList.forEach((item) => {
      zip.file(item.name, item.blob, { date: dateWithOffset });
    });
    zip.generateAsync({ type: "blob" }).then((content) => {
      // see FileSaver.js
      const link = window.URL.createObjectURL(content);
      window.location = link;
    });
  };

  const downloadZip = () => {
    dispatch(setActive(true));
    const listPromise = []; // lst to handle upload file
    const listBuffer = []; // list buffer response from api
    const listName = lstFile
      .filter((item) => item.url)
      .map((element) => {
        return getFileName(element.url);
      });
    listName.forEach((item) => {
      const body = {
        url: item,
      };
      listPromise.push(downloadFile(body)); // handle upload file
    });
    Promise.all([...listPromise])
      .then((result) => {
        result.forEach((item) => {
          listBuffer.push({
            blob: item?.data?.data?.data?.Body?.data,
            name: item?.data?.data?.file_name,
          });
        });
        handleFile(listBuffer);
      })
      .finally(() => dispatch(setActive(false)));
  };

  const handleMessage = () => {
    let message = "";
    if (statusText === APPLIED_PROJECT_STATUS.IN_PROGRESS) {
      message = "operator.advertisement.account_info.in_progress_mess";
      if (projectType === PROJECT_APPLIED_TYPE.SPECIAL_OFFER) {
        message =
          "operator.advertisement.account_info.in_progress_special_mess";
      }
    }
    if (statusText === APPLIED_PROJECT_STATUS.DECLINED) {
      message = "operator.advertisement.account_info.declined_mess";
    }
    if (statusText === APPLIED_PROJECT_STATUS.COMPLETED) {
      message = "operator.advertisement.account_info.completed_mess";
    }
    if (statusText === APPLIED_PROJECT_STATUS.REJECT_REPORT) {
      message = "operator.advertisement.account_info.reject_mess";
    }
    return t(message, {
      date: updatedDataBy?.changed_at
        ? moment(updatedDataBy?.changed_at).format("YYYY/MM/DD HH:mm")
        : "",
      from: getUpdatedByText(updatedDataBy?.ad_group),
    });
  };

  const handleNewMessage = (newMess) => {
    if (updateLastMessage) {
      updateLastMessage(newMess);
    }

    if (newMess?.send_by !== SEND_BY.PLATFORM_OPERATOR) {
      ChatService.updateChatRoomReplyStatusForV(
        chat_room_id || chatRoomIdQuery
      ).then(() => {});
    }
  };

  return (
    <div
      ref={accountInfoRef}
      className="relative w-full max-w-[960px] h-[100vh] overflow-auto"
    >
      <div className="sticky top-0 bg-white z-20 flex justify-between px-[24px] py-4 border-b-[1px] border-border">
        <button
          type="button"
          className="flex items-center"
          onClick={onCloseDrawer}
        >
          <i className="fa-solid fa-chevron-right" />
        </button>
      </div>
      <div className="flex flex-col">
        <div className="px-[16px] md:px-6 pt-[8px] pb-[16px] border-b border-border">
          <div className="flex flex-wrap items-center justify-between mx-[20px]">
            <div className="flex flex-wrap items-center mr-2 mb-3 gap-3">
              <div className="w-[64px] h-[64px] relative flex-shrink-0">
                <ImageItem
                  loadavatar="true"
                  avatar="true"
                  containerclassname="!rounded-full"
                  role="presentation"
                  imagesrc={avatar_url}
                  alt=""
                  imgclassname="w-full h-full object-cover rounded-full border-2 border-green"
                />
                <i className="absolute text-[14px] bg-white rounded-full text-green bottom-0 right-0 fa-solid fa-circle-check" />
              </div>

              <div className="flex flex-col">
                <div className="flex items-center mb-2 text-[14px]">
                  <Tooltip title={nickname}>
                    <p className="font-semibold mr-2 max-w-[150px] truncate">
                      {nickname}
                    </p>
                  </Tooltip>
                  <div className="flex items-center">
                    {generateSNSIcon(media)}
                    <p className="font-[700]">
                      {(() => {
                        const follower = socialMedia?.find(
                          (item) => item.social_media === media
                        )?.subscribers_number;
                        return follower ? formatNumber(follower) : 0;
                      })()}
                      {t("common.person")}
                    </p>
                  </div>
                </div>

                <div className="flex items-center text-[12px] font-semibold mb-2">
                  <div
                    className={`mr-2 ${gender !== GENDER.OTHER ? "h-5" : ""}`}
                  >
                    {(() => {
                      if (gender === GENDER.MALE) {
                        return <img src={male} alt="Male" />;
                      }
                      if (gender === GENDER.FEMALE) {
                        return <img src={female} alt="Female" />;
                      }
                      if (gender === GENDER.OTHER) {
                        return (
                          <img
                            src={other}
                            alt="Other"
                            className="w-[20px] h-[28px]"
                          />
                        );
                      }
                      return null;
                    })()}
                  </div>
                  <p className="capitalize">
                    {t("operator.advertisement.detail.verifySNS", {
                      sns: media,
                    })}
                  </p>
                  <i className="fa-regular text-gray fa-circle-question mx-[4px]" />
                </div>
              </div>
            </div>

            <Rating
              size="small"
              name="read-only"
              precision={0.1}
              value={rating}
              readOnly
              className="mb-1"
            />
          </div>

          <div className="px-[20px] my-[8px] whitespace-pre-wrap text-[14px] text-gray word-break">
            {portfolio?.summary}
          </div>
          <div className="mx-[20px] px-[8px] py-[12px] mt-[20px] border rounded-[4px] border-border">
            <div className="flex flex-col gap-4 min-h-[24px]">
              {socialMedia.length ? (
                <div className="flex flex-wrap items-start gap-4">
                  <div className="font-semibold text-[14px]">SNS</div>
                  <div className="flex flex-col gap-2">
                    {socialMedia.map((item) => {
                      return (
                        <div
                          key={item.social_media}
                          className="flex items-center gap-2"
                        >
                          {generateSNSIcon(item?.social_media, "flex-shrink-0")}
                          <a
                            href={handleSNSLink(
                              item.social_media,
                              item.username
                            )}
                            target="_blank"
                            className="text-blue-400 cursor-pointer word-break"
                            rel="noreferrer"
                          >
                            {item.username}
                          </a>
                          <div className="flex items-center whitespace-nowrap">
                            <div className="flex items-center mr-4">
                              {item?.subscribers_number
                                ? formatNumber(item?.subscribers_number)
                                : 0}
                              {t("common.person")}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div
          className="px-4 md:px-[54px] pt-[24px] pb-10 border-b border-border"
          style={{ minHeight }}
        >
          {statusText !== APPLIED_PROJECT_STATUS.CANCEL_TRANSACTION &&
            statusText !== APPLIED_PROJECT_STATUS.COMPLETED && (
              <div className="flex justify-end mb-6">
                <div
                  onClick={(event) => {
                    if (
                      statusText !==
                        APPLIED_PROJECT_STATUS.WAIT_APPROVAL_RECRUITMENT &&
                      statusText !== APPLIED_PROJECT_STATUS.DECLINED
                    ) {
                      handleOpen(event);
                    }
                  }}
                  role="presentation"
                  className="cursor-pointer w-9 h-6 flex justify-end relative"
                >
                  <i className="fa-solid fa-ellipsis-vertical" />
                </div>
              </div>
            )}

          {statusText !== APPLIED_PROJECT_STATUS.CANCEL_TRANSACTION &&
            statusText !== APPLIED_PROJECT_STATUS.COMPLETED && (
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <div className="min-w-[200px] py-3">
                  <button
                    type="button"
                    onClick={(event) => handleReasonOpen(event)}
                    className="hover:bg-secondary text-left w-full px-3 py-2"
                  >
                    {t(
                      "operator.advertisement.account_info.cancel_transaction"
                    )}
                  </button>
                  <Formik
                    initialValues={initialValues1}
                    validationSchema={validationSchema1}
                    onSubmit={cancelProject}
                  >
                    {(prop) => {
                      const { resetForm, handleSubmit } = prop;
                      return (
                        <Form>
                          {/* Reply Popover */}
                          <Popover
                            open={openReply}
                            anchorEl={replyAnchorEl}
                            onClose={() => handleReasonClose(resetForm)}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                          >
                            <div className="p-4">
                              <div className="flex items-center justify-between mb-3">
                                <label htmlFor="reason">
                                  {t(
                                    "operator.advertisement.account_info.reason"
                                  )}
                                </label>
                                <i
                                  role="presentation"
                                  onClick={() => handleReasonClose(resetForm)}
                                  className="p-2 -m-2 fa-solid fa-xmark cursor-pointer"
                                />
                              </div>
                              <Field
                                as="textarea"
                                id="reason"
                                name="reason"
                                className="max-w-full w-[400px] h-[200px] border border-solid border-secondary-200 resize-none px-3 py-2"
                              />
                              <ErrorMessage
                                component="div"
                                name="reason"
                                className="error-msg"
                              />
                              <div className="flex items-center justify-between pt-3">
                                <button
                                  type="button"
                                  onClick={() => handleReasonClose(resetForm)}
                                  className="underline font-[700]"
                                >
                                  {t("common.action.cancel")}
                                </button>
                                <Button
                                  onClick={handleSubmit}
                                  className="!text-white !min-w-[70px] !rounded-[4px] !py-2"
                                  classes={{
                                    root: "!bg-black",
                                  }}
                                >
                                  {t("OK")}
                                </Button>
                              </div>
                            </div>
                          </Popover>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </Popover>
            )}

          {(() => {
            if (
              statusText === APPLIED_PROJECT_STATUS.WAIT_APPROVAL_RECRUITMENT
            ) {
              return (
                <div className="flex items-center justify-center gap-[50px]">
                  <Button
                    variant="contained"
                    onClick={onApplicationApproved}
                    className="!bg-black !text-white !py-2 !px-3 !rounded-[8px] !font-bold !min-w-[116px]"
                  >
                    {t(
                      "operator.advertisement.account_info.approve_application"
                    )}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => setOpenApplyRejected(true)}
                    className="!bg-black !text-white !py-2 !px-3 !rounded-[8px] !font-bold !min-w-[116px]"
                  >
                    {t(
                      "operator.advertisement.account_info.reject_application"
                    )}
                  </Button>
                </div>
              );
            }
            if (statusText === APPLIED_PROJECT_STATUS.WAIT_APPROVAL_FINISHED) {
              return (
                <div className="flex items-center justify-center gap-[50px]">
                  <Button
                    variant="contained"
                    onClick={() => setOpenCompleteApproved(true)}
                    className="!bg-black !text-white !py-2 !px-3 !rounded-[8px] !font-bold !min-w-[116px]"
                  >
                    {t("operator.advertisement.account_info.approved_report")}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => setOpenCompleteRejected(true)}
                    className="!bg-black !text-white !py-2 !px-3 !rounded-[8px] !font-bold !min-w-[116px]"
                  >
                    {t("operator.advertisement.account_info.reject_report")}
                  </Button>
                </div>
              );
            }
            if (
              statusText === APPLIED_PROJECT_STATUS.IN_PROGRESS ||
              statusText === APPLIED_PROJECT_STATUS.DECLINED ||
              statusText === APPLIED_PROJECT_STATUS.REJECT_REPORT ||
              statusText === APPLIED_PROJECT_STATUS.COMPLETED
            ) {
              return (
                <div className="rounded-[4px] bg-[#eee] py-[20px] px-[20px] max-w-[550px] m-auto text-center">
                  <i className="fa-solid fa-clipboard-check text-[#838383] mx-[8px]" />
                  {handleMessage()}
                </div>
              );
            }
            return null;
          })()}

          <h1 className="text-center my-[24px] font-semibold text-[22px]">
            {(() => {
              if (statusText === APPLIED_PROJECT_STATUS.CANCEL_TRANSACTION) {
                return t(
                  "operator.advertisement.account_info.cancel_transaction_mess"
                );
              }
              if (
                statusText === APPLIED_PROJECT_STATUS.WAIT_APPROVAL_RECRUITMENT
              ) {
                return t("operator.advertisement.account_info.wait_recuitment");
              }
              if (
                statusText === APPLIED_PROJECT_STATUS.WAIT_APPROVAL_FINISHED
              ) {
                return t("operator.advertisement.account_info.wait_finished");
              }
              if (statusText === APPLIED_PROJECT_STATUS.COMPLETED) {
                return t("operator.advertisement.account_info.completed");
              }
              return null;
            })()}
          </h1>

          {(statusText === APPLIED_PROJECT_STATUS.COMPLETED ||
            statusText === APPLIED_PROJECT_STATUS.CANCEL_TRANSACTION ||
            statusText === APPLIED_PROJECT_STATUS.REJECT_REPORT ||
            (!statusText &&
              projectType === PROJECT_APPLIED_TYPE.SPECIAL_OFFER)) && (
            <p className="word-break whitespace-pre-wrap">
              {commentFromAdmin?.message}
            </p>
          )}

          <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
            {statusText === APPLIED_PROJECT_STATUS.WAIT_APPROVAL_FINISHED && (
              <div className="grid grid-cols-1 custom_grid gap-5">
                {lstFile.map((item) => (
                  <div key={item.id} className="upload-file md:!block">
                    <div className="file-item relative pt-[130%]">
                      {item.url ? (
                        <button
                          type="button"
                          onClick={(event) => downloadImage(item.url, event)}
                          className="absolute top-0 w-full h-full"
                        >
                          {(() => {
                            const format = item.url.substring(
                              item.url.lastIndexOf(".") + 1
                            );
                            if (format === EFileType.MP4) {
                              return (
                                <div className="w-full h-full border border-secondary-200 bg-white flex items-center justify-center text-[24px] font-bold">
                                  MP4
                                </div>
                              );
                            }
                            if (format === EFileType.PDF) {
                              return (
                                <div className="w-full h-full border border-secondary-200 bg-white flex items-center justify-center text-[24px] font-bold">
                                  PDF
                                </div>
                              );
                            }
                            return (
                              <ImageItem
                                containerclassname="!rounded-none"
                                role="presentation"
                                imagesrc={item.url}
                                alt=""
                                imgclassname="w-full h-full object-cover"
                              />
                            );
                          })()}
                        </button>
                      ) : (
                        <div className="absolute top-0 w-full h-full border border-secondary-200 bg-white" />
                      )}
                    </div>
                  </div>
                ))}
                <div className="download-zip flex justify-center md:justify-start w-full md:w-[70%]">
                  {!lstFile.every((item) => !item.url) ? (
                    <Button
                      variant="contained"
                      onClick={downloadZip}
                      className="!bg-black !p-2 !text-white !rounded-[8px] !w-[200px] md:!w-full"
                    >
                      {t("operator.advertisement.account_info.download_zip")}
                    </Button>
                  ) : null}
                </div>
              </div>
            )}

            <div className="md:col-start-2 md:col-end-4 font-semibold">
              {(() => {
                if (
                  statusText === APPLIED_PROJECT_STATUS.WAIT_APPROVAL_FINISHED
                ) {
                  return (
                    <div className="flex-[1_0] border border-border rounded-[5px] mb-[8px] p-[8px] flex items-center min-h-[95px]">
                      <div className="flex-[2_0] flex items-center justify-center mr-[8px] text-[12px]">
                        {t("operator.advertisement.detail.uploadedInfo")}
                      </div>
                      <div className="flex-[3_0]">
                        <div>
                          {t("operator.advertisement.detail.billingAmount")}
                        </div>
                        <div>
                          {t("operator.advertisement.detail.uploadDate")}
                        </div>
                        <div>
                          {t("operator.advertisement.detail.uploadLink")}
                        </div>
                      </div>
                      <div className="flex-[3_0]">
                        <div className="word-break">
                          ¥ {formatNumber(info?.fee) || 0}
                          <i className="fa-regular text-gray fa-circle-question mx-[4px]" />
                        </div>
                        <div>
                          {(() => {
                            const list = info?.status_change_log?.filter(
                              (item) => item.status === 2
                            );
                            if (list?.length) {
                              return moment(
                                list[list.length - 1]?.changed_at
                              ).format("YYYY/M/D");
                            }
                            return null;
                          })()}
                        </div>
                        <a
                          className="text-blue-200 font-normal"
                          target="_blank"
                          href={
                            info?.deliverable?.deliverables &&
                            info?.deliverable?.deliverables?.web
                              ? info.deliverable?.deliverables?.web[0]
                              : ""
                          }
                          rel="noreferrer"
                        >
                          {t("operator.advertisement.detail.uploadLink")}
                        </a>
                      </div>
                    </div>
                  );
                }
                return null;
              })()}

              {statusText === APPLIED_PROJECT_STATUS.WAIT_APPROVAL_FINISHED && (
                <div className="flex-[1_0] border border-border rounded-[5px] p-[8px] mt-[8px] flex flex-col sm:flex-row items-center text-[12px] min-h-[95px] gap-3">
                  <div className="flex items-center justify-center text-center">
                    {t("operator.advertisement.detail.influencersComment")}
                  </div>
                  <div className="flex-1 text-[16px] font-normal whitespace-pre-wrap word-break">
                    {info?.deliverable?.message || ""}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {(() => {
          // status === APPCEPT
          if (
            statusText === APPLIED_PROJECT_STATUS.WAIT_APPROVAL_RECRUITMENT ||
            statusText === APPLIED_PROJECT_STATUS.CANCEL_TRANSACTION ||
            statusText === APPLIED_PROJECT_STATUS.DECLINED
          ) {
            return null;
          }
          return (
            <div>
              <Chatbox
                isPing={is_ping}
                avatarUrl={avatar_url}
                chatRoomId={chat_room_id || chatRoomIdQuery}
                isLoading={isLoading}
                apiMessages={apiMessages}
                scrollRef={
                  accountInfoRef.current ? accountInfoRef.current : undefined
                }
                messageInputClassName="max-w-[960px]"
                wantScrollToBottomFirstLoad={false}
                handleNewMessage={handleNewMessage}
                statusText={statusText}
                sendBy={SEND_BY.PLATFORM_OPERATOR}
                listMessageClassName="flex flex-col justify-end"
                handleGetMoreMessages={() => {
                  setPage(page + 1);
                  getAllMessages();
                }}
                isLastPage={isLastPage}
                allowHandleMarginBottom
              />
            </div>
          );
        })()}
      </div>

      {/* Open when reject application 応募を却下する */}
      <ConfirmDialog
        open={openApplyRejected}
        onClose={() => setOpenApplyRejected(false)}
        onOK={onApplicationRejected}
      >
        <div className="flex-1 flex items-center justify-center">
          <div className="block text-[14px] font-semibold">
            <p>
              {t("operator.advertisement.dialog.reject_application_mess_1")}
            </p>
            <p>
              {t("operator.advertisement.dialog.reject_application_mess_2")}
            </p>
          </div>
        </div>
      </ConfirmDialog>

      {/* Open when reject complete */}
      <Formik
        validateOnMount
        initialValues={initialValues2}
        validationSchema={validationSchema2}
        onSubmit={onCompleteRejected}
      >
        {(prop) => {
          const { handleSubmit, resetForm, values } = prop;
          return (
            <Form>
              <ConfirmDialog
                open={openCompleteRejected}
                onClose={() => {
                  setOpenCompleteRejected(false);
                  resetForm();
                }}
                onOK={handleSubmit}
              >
                <div className="flex-1">
                  <div className="mb-7 text-[14px] font-semibold">
                    <p className="pl-5">
                      {t("operator.advertisement.dialog.reject_report_mess_1")}
                    </p>
                    <p className="pl-5">
                      {t("operator.advertisement.dialog.reject_report_mess_2")}
                    </p>
                  </div>

                  <Field
                    as="textarea"
                    placeholder={t(
                      "operator.advertisement.dialog.reject_report_placeholder"
                    )}
                    name="completeReject"
                    className="w-full min-h-[200px] max-h-[300px] border border-solid border-secondary-200 resize-none px-3 py-2 !rounded-[12px]"
                    maxLength={1000}
                  />
                  <div className="text-[#717171] font-semibold flex justify-end">
                    {values.completeReject.length}/{1000}
                  </div>
                  <ErrorMessage
                    component="div"
                    name="completeReject"
                    className="error-msg"
                  />
                </div>
              </ConfirmDialog>
            </Form>
          );
        }}
      </Formik>

      {/* Open when approve complete 完了を承認する */}
      <Formik
        validateOnMount
        initialValues={initialValues3}
        validationSchema={validationSchema3}
        onSubmit={onCompleteApproved}
      >
        {(prop) => {
          const { handleSubmit, resetForm, values } = prop;
          return (
            <Form>
              <ConfirmDialog
                open={openCompleteApproved}
                onClose={() => {
                  setOpenCompleteApproved(false);
                  resetForm();
                }}
                onOK={handleSubmit}
              >
                <div className="flex-1">
                  <div className="mb-5 text-[14px] font-semibold">
                    <p>
                      {t("operator.advertisement.dialog.approve_report_mess_1")}
                    </p>
                    <p>
                      {t("operator.advertisement.dialog.approve_report_mess_2")}
                    </p>
                  </div>

                  <div className="mb-5 text-[14px] font-semibold">
                    <p>
                      {t("operator.advertisement.dialog.approve_report_mess_3")}
                    </p>
                    <Rating
                      size="large"
                      name="read-only"
                      value={rateForInfluencer}
                      onChange={(event, newValue) => {
                        setRateForInfluencer(newValue);
                      }}
                      className="mb-1"
                    />
                  </div>

                  <Field
                    as="textarea"
                    placeholder={t(
                      "operator.advertisement.dialog.approve_report_placeholder"
                    )}
                    name="completeApprove"
                    className="w-full min-h-[200px] max-h-[300px] border border-solid border-secondary-200 px-3 py-2 !rounded-[12px]"
                    maxLength={1000}
                  />
                  <div className="text-[#717171] font-semibold flex justify-end">
                    {values.completeApprove.length}/{1000}
                  </div>
                  <ErrorMessage
                    component="div"
                    name="completeApprove"
                    className="error-msg"
                  />
                </div>
              </ConfirmDialog>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default AccountInfo;
