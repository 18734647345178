export default {
  influencer_info: "インフルエンサー情報",
  number_apply: "応募件数",
  number_approved: "納品承認件数",
  approval_history: "承認履歴",
  self_introduction: "自己紹介（得意分野コメント）",
  nickname: "表示名",
  send_offer: "オファー依頼",
  self_pr: "募集案件への自己PR",
  url_pr: "募集案件への自己PR URL",
  file_pr: "募集案件への自己PR ファイル",
};