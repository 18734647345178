import React from "react";
import MainContent from "app/components/MainContent";
import LayoutDefault from "app/ictl/layouts/LayoutDefault";
import PcContent from "./PcContent";

function AccountSettings() {

  return (
    <LayoutDefault hiddenFooterMobile showFooter showHeader>
      <MainContent showMobileNav alwaysShowF>
        <PcContent />
      </MainContent>
    </LayoutDefault>
  );
}

export default AccountSettings;
