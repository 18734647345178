import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import Button, { BUTTON_STYLE_TYPE } from "app/operator/components/Button";
import { Form, Formik } from "formik";
import { checkValueChange } from "app/utils";
// import Collapse from "@mui/material/Collapse";

export const EDIT_BTN_POSITION = {
  CONTENT: "CONTENT",
  LABEL: "LABEL",
};

function EditableGroup(props) {
  const { t } = useTranslation();
  const {
    editForm,
    displayValues,
    initialValues,
    validationSchema,
    onSubmitGroup,
    label,
    labelClassName,
    disabledEdit,
    editBtnPosition = EDIT_BTN_POSITION.CONTENT,
    containerClassName,
    disableCloseIcon,
    allowComparisonField,
    required,
    handleClickEdit = () => {},
    handleDeleteEdit = () => {},
    handleCloseEdit = () => {},
    containerBorder = true,
  } = props;
  // const [isFirstTime, setIsFirstTime] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isDisplayEditBtn, setIsDisplayEditBtn] = useState(
    !!editForm && !disabledEdit && !isEditing
  );

  useEffect(() => {
    const isDisplay = !!editForm && !disabledEdit && !isEditing;
    setIsDisplayEditBtn(isDisplay);
    if (disabledEdit) {
      setIsEditing(false);
    }
  }, [editForm, disabledEdit, isEditing]);

  const onClickEdit = () => {
    setIsEditing(true);
    // setIsFirstTime(false);
    handleClickEdit();
  };

  const onDeleteEdit = () => {
    setIsEditing(false);
    handleDeleteEdit();
  };

  const onCloseEdit = () => {
    setIsEditing(false);
    handleCloseEdit();
  };

  const onSubmit = (values) => {
    onSubmitGroup(values);
    setIsEditing(false);
  };

  const renderEditForm = () => {
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(context) => {
          const { handleSubmit, values } = context;
          const isValuesHasChanged = checkValueChange(
            initialValues,
            values,
            allowComparisonField
          );
          return (
            <Form>
              <div className="relative rounded-[16px] border-boder border">
                {!disableCloseIcon && (
                  <button type="button" onClick={onCloseEdit}>
                    <i
                      role="presentation"
                      className="absolute top-[24px] right-[24px] fa-solid fa-xmark"
                    />
                  </button>
                )}
                <div className="p-[24px]">{editForm(context)}</div>
                <div className="flex p-[24px] justify-between border-border border-t">
                  <button
                    type="button"
                    onClick={onDeleteEdit}
                    className="underline"
                  >
                    {t("common.action.delete_3")}
                  </button>
                  <Button
                    type="button"
                    onClick={handleSubmit}
                    className="bg-dark text-white duration-500"
                    styleType={BUTTON_STYLE_TYPE.PRIMARY}
                    disabled={!isValuesHasChanged}
                  >
                    {t("common.action.save")}
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  };

  const renderEditButton = () => {
    return (
      <button
        type="button"
        className={cx("font-semibold underline align-top text-[14px] whitespace-nowrap", {})}
        onClick={onClickEdit}
      >
        {t("common.action.edit")}
      </button>
    );
  };

  const renderItemValue = () => {
    return (
      <>
        <div
          className={cx(
            "flex justify-between gap-[16px] mb-[4px] font-[700]",
            labelClassName
          )}
        >
          <div>
            <span className='text-[14px]'>{label}</span>
            {required && <span className="required after:!-right-3" />}
          </div>
          {isDisplayEditBtn &&
            editBtnPosition === EDIT_BTN_POSITION.LABEL &&
            renderEditButton()}
        </div>
        <div>{displayValues}</div>
      </>
    );
  };

  return (
    <div
      className={cx(
        "flex justify-between w-full md:pr-[36px] py-[16px]",
        containerClassName,
        {
          "border-b border-border": containerBorder,
        }
      )}
    >
      <div className="w-full">
        {isEditing ? renderEditForm() : renderItemValue()}
        {/* <Collapse in={isEditing} className={isEditing ? "mr-[28px]" : ""}>
          {!isFirstTime && renderEditForm()}
        </Collapse>
        <Collapse in={!isEditing}>{renderItemValue()}</Collapse> */}
      </div>
      {isDisplayEditBtn &&
        editBtnPosition === EDIT_BTN_POSITION.CONTENT &&
        renderEditButton()}
    </div>
  );
}

export default EditableGroup;
