export default {
  personal_info: {
    personal_info: "個人資料",
    name_label: "全名（真實姓名)",
    first_name: "名稱",
    last_name: "姓氏",
    edit_btn: "編輯",
    dob_label: "出生日期",
    country_region: "國家",
    phone_label: "電話號碼",
    goverment_label: "政府核發的身份證",
    address_label: "地址",
    postcode: "郵遞區號",
    state_provine_country: "州/省",
    municipaliites: "縣市",
    address: "地址",
    apartment_name: "單位名稱、房號等",
    invalid_birthday: "請輸入有效的出生日期。",
    go_to_profile: "到個人檔案",
    personal_info_notice: "輸入您的個人資料和聯絡方式",
    login_security: "登入和安全",
    login_security_notice: "您需要更改密碼以保護您的帳戶",
    global_setting: "全球設定",
    global_setting_notice: "設定預設語言、貨幣和時區",
    withdraw: "退會",
  },
  login_security: {
    auth_form: "登入",
    password: "密碼",
    password_last_update: "最終更新日: ",
    current_password: "目前密碼",
    new_password: "新密碼",
    confirm_password: "確認密碼",
    change_password: "更改密碼",
    change_email: "更改電子郵件地址",
    social_account: "社交媒體帳戶",
    connected: "已連結",
    not_connect: "未連接",
    connecting: "正在連接",
    disconnect: "解除連接",
    linked_success: "連接成功。",
    linked_fail: "連線失敗。請再試一次。",
    unlink_success: "成功解除連線。",
    unlink_fail: "解除連線失敗。請再試一次。",
    email_exist: "用戶名已經被註冊。",
    unlink_notice: "您確定要中斷與此帳戶關聯的 {{sns}} 帳戶嗎？",
    logout_notice: "已更新。由於您的登入資訊已更改，您將被登出。",
  },
  widthdraw: {
    step_1: "選擇原因",
    step_2: "確認",
    step_3: "完成",
    step_1_title: "退會原因",
    step_1_first_reason: "我有安全或隱私方面的顧慮。",
    step_1_second_reason: "我無法遵守 Buzz Traveler 的條款和條件。",
    step_1_third_reason: "服務與我的預期不符。",
    step_1_fourth_reason: "其他",
    step_2_title: "您確定要退會嗎",
    step_2_first_notice: "此帳戶上的所有資訊都將被刪除。",
    step_2_second_notice: "一旦取消會員資格，您的帳戶將無法恢復。",
    step_3_title: "退會程序已完成",
    go_to_top: "轉至首頁畫面",
    widthdraw_success: "提款過程已成功完成。",
    widthdraw_fail: "提款過程中發生錯誤。請稍後再試。",
  },
};
