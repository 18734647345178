export default {
  list: {
    find_influencer: "インフルエンサーを検索",
    influcener_name: "表示名",
    count: "インフルエンサー {{value}}人",
    avatar: "プロフィール画像",
    instagram: "Instagramフォロワー数",
    tiktok: "Tiktokフォロワー数",
    facebook: "Facebookフォロワー数",
    youtube: "Youtubeチャンネル登録者数",
    self_introduction: "自己紹介（得意分野コメント）",
    rating: "評価",
  },
  detail: {
    influencer_information: "インフルエンサー情報",
    number_of_applications: "応募件数",
    number_of_approvals: "承認件数",
    approval_history: "承認履歴",
    comment: "自己紹介（得意分野コメント）",
    display_name: "表示名",
    full_name: "氏名（本名）",
    birthday: "生年月日",
    phone_number: "電話番号",
  },
};

// admin.influencer.detail