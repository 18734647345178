import React from "react";
import { useTranslation } from "react-i18next";
import { ExpandMore } from "@mui/icons-material/";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { FORMAT_DATE } from "app/constant";
import moment from "moment";
import CustomDateRange from "app/components/CustomDateRange";
import { isToday } from "date-fns";

function DateForm({ values, setFieldValue, required, showCheckbox }) {
  const { t } = useTranslation();

  return (
    <div className="mb-[16px]">
      <div className="mb-[8px] font-semibold">
        {t("operator.advertisement.detail.recuitmentDate")}
        {required && <span className="required" />}
      </div>
      <Accordion
        sx={{
          borderBottom: "2px solid #e5e7eb",
          boxShadow: "none",
          ":before": {
            backgroundColor: "transparent",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {!values?.recuitmentDate?.unspecified_date &&
          values?.recuitmentDate?.startDate &&
          values?.recuitmentDate?.endDate ? (
            <div className="pl-[8px] text-gray font-semibold">
              {moment(values?.recuitmentDate?.startDate).format(
                FORMAT_DATE.YYYY_MMMM_Do
              )}
              ~
              {moment(values?.recuitmentDate?.endDate).format(
                FORMAT_DATE.YYYY_MMMM_Do
              )}
            </div>
          ) : (
            <div className="text-gray font-semibold">
              {t("common.other1")}
            </div>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <CustomDateRange
            showCheckbox={showCheckbox}
            innitialValue={values?.recuitmentDate}
            disabledPastDays
            onChange={(payload) => {
              // set date
              let startDate;
              const endDate = payload.endDate
                ? moment(payload.endDate).format(FORMAT_DATE.YYYY_MM_DDTE)
                : null;
              if (isToday(new Date(payload.startDate))) {
                startDate = moment().format(FORMAT_DATE.YYYY_MM_DDT);
              } else {
                startDate = payload.startDate
                  ? moment(payload.startDate).format(FORMAT_DATE.YYYY_MM_DDTS)
                  : null;
              }
              const data = {
                startDate,
                endDate,
                unspecified_date: payload?.unspecifiedDate,
              };
              setFieldValue("recuitmentDate", data);
            }}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default DateForm;
