import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "app/redux/authReducer";
import ictlReducer from "app/ictl/redux/reducer";
import operatorReducer from "app/operator/redux/reducer";
import socialReducer from "./socialReducer";
import typeFacilityReducer from "./typeFacilityReducer";
import blockUIReducer from "./blockUIReducer";
import profileReducer from "./profileReducer";
import headerPaymentReducer from "./ictl/headerPaymentReducer";
import setProjectBatchReducer from "./projectBatch";
import emailForChangePassReducer from "./emailForChangePassReducer";
import facilityIdReducer from "./facilityIdReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  social: socialReducer,
  ictl: ictlReducer,
  operator: operatorReducer,
  typeFacility: typeFacilityReducer,
  blockUI: blockUIReducer,
  profile: profileReducer,
  headerPayment: headerPaymentReducer,
  projectBatch: setProjectBatchReducer,
  emailForChangePass: emailForChangePassReducer,
  facilityId: facilityIdReducer
});

const persistedReducer = persistReducer(
  {
    key: "root",
    storage,
    whitelist: [
      "auth",
      "social",
      "typeFacility",
      "profile",
      "headerPayment",
      "projectBatch",
    ],
  },
  rootReducer
);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export default store;

export const persistor = persistStore(store);
