import React from "react";

function LayoutPageColor(props) {
  const { text } = props;
  return (
    <div className="h-[40%] md:h-full w-full flex flex-1 bg-cover p-4 backgroundColorDeskop justify-center items-center overflow-hidden">
      <h1 className="text-2xl sm:text-3xl font-semibold text-center  px-4 py-4 text-[#222]">
        {text}
      </h1>
    </div>
  );
}
export default LayoutPageColor