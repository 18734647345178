/* eslint-disable no-duplicate-case */
/* eslint-disable no-fallthrough */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const defaultGeocode = { latitude: 35.680642, longitude: 139.7668991 };

const fields = {
  postcode: { key: "postcode" },
  state: { key: "state" },
  city: { key: "city" },
  street: { key: "street" },
  address: { key: "address" },
  country: { key: "country", options: [{ key: "jp", text: "日本 - JP" }] },
};

const validationPlaceSchema = Yup.object().shape({
  postcode: Yup.string()
    .required("郵便番号を入力してください。")
    .matches(/^[0-9]+-{0,1}[0-9]+$/, "郵便番号の形式が不正です。")
    .max(15)
    .trim(),
  state: Yup.string().required("都道府県を入力してください。").max(1000).trim(),
  city: Yup.string().required("市区町村を入力してください。").max(1000).trim(),
  street: Yup.string().required("番地を入力してください。").max(1000).trim(),
  address: Yup.string().max(1000),
  country: Yup.string().required("Required").max(1000),
});

export default function EditAndConfirmPlace(props) {
  const { gMapPlace, setSelectedPlace, backToSearch, backupResultToEdit } =
    props;

  const getInitConfirmPlaceData = () => {
    if (backupResultToEdit) return backupResultToEdit.placeData;
    const init = {
      postcode: "",
      state: "",
      city: "",
      street: "",
      address: "",
      country: fields.country.options[0].key,
      country_key: "",
    };
    const addressComponents = gMapPlace?.placeData?.address_components;

    if (addressComponents) {
      for (let i = 0; i < addressComponents.length; i += 1) {
        for (let j = 0; j < addressComponents[i].types.length; j += 1) {
          // eslint-disable-next-line default-case
          switch (addressComponents[i].types[j]) {
            case "locality":
            case "administrative_area_level_2":
              init.city = addressComponents[i].long_name;
              break;
            case "administrative_area_level_1":
              init.state = addressComponents[i].long_name;
              break;
            case "country":
              init.country = addressComponents[i].long_name;
              init.country_key = addressComponents[i].short_name;
              break;
            case "street_address":
            case "presubpremisemise":
            case "premise":
              init.address = "";
              break;
            case "route":
            case "street":
              init.street = '';
              break;
            case "postal_code":
              init.postcode = addressComponents[i].long_name;
              break;
          }
        }
      }
      return init;
    }

    init.address = gMapPlace?.rawText;
    return init;
  };

  const formik = useFormik({
    initialValues: getInitConfirmPlaceData(),
    validationSchema: validationPlaceSchema,
    onSubmit: (values) => {
      setSelectedPlace((prev) => ({
        ...prev,
        resultPlace: {
          placeData: values,
          geoCodeData: prev?.gMapPlace?.geoCodeData || defaultGeocode,
        },
      }));
    },
  });
  return (
    <div className="w-full h-full absolute inset-0">
      <div className="block md:hidden absolute pb-20 overflow-hidden w-full rounded-t-lg rounded-b-none inset-x-0 bottom-0 h-[95vh] bg-white">
        <EditAndConfirmPlaceContent
          formik={formik}
          backToSearch={backToSearch}
        />
      </div>

      <div className="hidden md:block absolute overflow-hidden w-[80%] max-w-[600px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-h-[75%] bg-white rounded-lg">
        <EditAndConfirmPlaceContent
          formik={formik}
          backToSearch={backToSearch}
        />
      </div>
    </div>
  );
}

function EditAndConfirmPlaceContent(props) {
  const { formik, backToSearch } = props;
  const [currentFocus, setCurrentFocus] = useState("");
  const { t } = useTranslation();

  return (
    <div className="">
      <div className="w-full h-auto">
        <div className="w-full flex items-center justify-center px-6 h-auto pt-8 pb-2 relative ">
          <button
            type="button"
            className="absolute left-6 top-8 w-8 h-8 rounded-full mr-4 flex items-center justify-center bg-white hover:bg-[#dddddd]"
            onClick={backToSearch}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="#222222"
              strokeWidth="2.667"
              display="block"
              overflow="visible"
              viewBox="0 0 32 32"
              style={{ height: 24, width: 24 }}
            >
              <path d="M20 28L8.707 16.707a1 1 0 010-1.414L20 4" />
            </svg>
          </button>
          <h2 className="text-center left-8 font-semibold">
            {t("operator.host.tab2.confirm_your_address")}
          </h2>
        </div>
      </div>

      <div className="w-full flex-1 overflow-auto p-6">
        <div className="w-full flex flex-col">
          <div className="w-full h-auto rounded-lg border border-[#b0b0b0]">
            <InputField
              label={t("operator.host.tab2.postal_code")}
              fieldKey={fields.postcode.key}
              isCurrentFocus={currentFocus === fields.postcode.key}
              setCurrentFocus={setCurrentFocus}
              focusNext={currentFocus === fields.state.key}
              value={formik.values[fields.postcode.key]}
              setValue={(newValue) =>
                formik.setFieldValue(fields.postcode.key, newValue)
              }
              isError={formik.errors[fields.postcode.key]}
              isTop
              isBottom={false}
              formik={formik}
              maxLength={15}
              required
            />
            <InputField
              label={t("operator.host.tab2.state_or_province")}
              fieldKey={fields.state.key}
              isCurrentFocus={currentFocus === fields.state.key}
              setCurrentFocus={setCurrentFocus}
              focusNext={currentFocus === fields.city.key}
              value={formik.values[fields.state.key]}
              setValue={(newValue) =>
                formik.setFieldValue(fields.state.key, newValue)
              }
              isError={formik.errors[fields.state.key]}
              isTop={false}
              isBottom={false}
              formik={formik}
              maxLength={1000}
              required
            />
            <InputField
              label={t("operator.host.tab2.city")}
              fieldKey={fields.city.key}
              isCurrentFocus={currentFocus === fields.city.key}
              setCurrentFocus={setCurrentFocus}
              focusNext={currentFocus === fields.street.key}
              value={formik.values[fields.city.key]}
              setValue={(newValue) =>
                formik.setFieldValue(fields.city.key, newValue)
              }
              isError={formik.errors[fields.city.key]}
              isTop={false}
              isBottom={false}
              formik={formik}
              maxLength={1000}
              required
            />

            <InputField
              label={t("operator.host.tab2.street")}
              fieldKey={fields.street.key}
              isCurrentFocus={currentFocus === fields.street.key}
              setCurrentFocus={setCurrentFocus}
              focusNext={currentFocus === fields.address.key}
              value={formik.values[fields.street.key]}
              setValue={(newValue) =>
                formik.setFieldValue(fields.street.key, newValue)
              }
              isError={formik.errors[fields.street.key]}
              isTop={false}
              isBottom={false}
              formik={formik}
              maxLength={1000}
              required
            />
            <InputField
              label={t("operator.host.tab2.apartment_room_etc")}
              fieldKey={fields.address.key}
              isCurrentFocus={currentFocus === fields.address.key}
              setCurrentFocus={setCurrentFocus}
              focusNext={currentFocus === fields.country.key}
              value={formik.values[fields.address.key]}
              setValue={(newValue) =>
                formik.setFieldValue(fields.address.key, newValue)
              }
              isError={formik.errors[fields.address.key]}
              isTop={false}
              isBottom={false}
              maxLength={1000}
            />
            <InputField
              label={t("operator.host.tab2.countr_or_region")}
              fieldKey={fields.country.key}
              isCurrentFocus={currentFocus === fields.country.key}
              setCurrentFocus={setCurrentFocus}
              focusNext={false}
              value={formik.values[fields.country.key]}
              setValue={(newValue) =>
                formik.setFieldValue(fields.country.key, newValue)
              }
              isError={formik.errors[fields.country.key]}
              isTop={false}
              isBottom
              isSelecteField
              options={fields.country.options}
              required
            />
          </div>
        </div>
        <div className="pt-6 text-start">
          <button
            type="button"
            onClick={formik.handleSubmit}
            className="h-12 w-auto px-6 text-base font-semibold text-white bg-dark rounded-md"
          >
            {t("operator.host.tab2.looks_fine")}
          </button>
        </div>
      </div>
    </div>
  );
}

function InputField(props) {
  const {
    fieldKey,
    isCurrentFocus,
    setCurrentFocus,
    focusNext,
    value,
    setValue,
    isError,
    isTop,
    isBottom,
    isSelecteField,
    options,
    label,
    formik,
    maxLength,
    required,
  } = props;

  const wrapperClassName = () => {
    const baseClasses = `w-full h-auto p-[1px]`;
    const topClasses = isTop ? "rounded-t-lg" : "";
    const bottomClasses = isBottom ? "rounded-b-lg border-b-0" : "border-b";
    const hideBottomClasses =
      isCurrentFocus || focusNext ? "border-b-white" : "border-b-[#b0b0b0]";

    return `${baseClasses} ${topClasses} ${bottomClasses} ${hideBottomClasses}`;
  };

  return (
    <div
      className="w-full h-auto"
      style={{ marginBottom: !isBottom ? "-1px" : "0px" }}
    >
      <div className={wrapperClassName()}>
        <div className="w-full h-full relative">
          {!isSelecteField ? (
            <div>
              <input
                maxLength={maxLength}
                type="text"
                id={fieldKey}
                name={fieldKey}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                className={`w-full px-4 pt-[28px] pb-2 outline-none text-base leading-5 font-normal rounded-none focus:rounded-lg ${
                  !isError
                    ? "focus:shadow-[0_0_0_2px_rgba(34,34,34,1)] bg-white"
                    : "focus:shadow-[0_0_0_2px_rgba(255,0,0,1)]"
                } ${isTop ? "rounded-t-lg" : ""} ${
                  isBottom ? "rounded-b-lg" : ""
                }`}
                style={{ WebkitAppearance: "none" }}
                onFocus={() => setCurrentFocus(fieldKey)}
                onBlur={() => setCurrentFocus("")}
              />
              {formik?.errors[fieldKey] && (
                <div className="text-[#f00] text-left my-1 pl-4 text-[12px]">
                  {formik?.errors[fieldKey]}
                </div>
              )}
            </div>
          ) : (
            <>
              <div>
                <select
                  id={fieldKey}
                  name={fieldKey}
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  className={`w-full px-4 pt-[28px] pb-2 outline-none text-base leading-5 font-normal !bg-white rounded-none appearance-none focus:rounded-lg ${
                    !isError
                      ? "focus:shadow-[0_0_0_2px_rgba(34,34,34,1)]"
                      : "focus:shadow-[0_0_0_2px_rgba(255,0,0,1)]"
                  } ${isTop ? "rounded-t-lg" : ""} ${
                    isBottom ? "rounded-b-lg" : ""
                  }`}
                  style={{ WebkitAppearance: "none" }}
                  onFocus={() => setCurrentFocus(fieldKey)}
                  onBlur={() => setCurrentFocus("")}
                >
                  {options &&
                    options.map((item) => (
                      <option key={item.key} value={item.key}>
                        {item.text}
                      </option>
                    ))}
                </select>
              </div>
              <span className="absolute right-2 top-1/2 -translate-y-1/2">
                <ExpandMoreIcon />
              </span>
            </>
          )}

          <span className="absolute top-2 left-4 w-auto text-xs leading-4 text-[#717171]">
            {label}
            {required ? <span className="text-red">*</span> : null}
          </span>
        </div>
      </div>
    </div>
  );
}
