import axios from "axios";
import store from "app/redux/store";
import setAuthUser from "app/redux/authReducer";
// import authReducer from "app/redux/authReducer";

const chatClient = axios.create({
  baseURL: process.env.REACT_APP_CHAT_API_URL,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  }
});

chatClient.interceptors.request.use(
  (config) => {
    const token = store.getState()?.auth.token?.accessToken || "";
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*"
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

chatClient.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 400) {
      // handle error
      return Promise.reject(new Error("Bad Request"));
    }
    if (err.response.status === 401) {
      store.dispatch(setAuthUser(null));
      return Promise.reject(new Error("Unauthorized"));
    }
    if (err.response.status === 403) {
      // handle error
      return Promise.reject(new Error("Forbidden"));
    }
    if (err.response.status === 404) {
      // handle error
      return Promise.reject(new Error("Not Found"));
    }
    return Promise.reject(err);
  }
);

export default chatClient;
