import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Rating, Tooltip } from "@mui/material";
import { getCountryCallingCode } from "libphonenumber-js";
import MainContent from "app/components/MainContent";
import ictlPath from "app/ictl/route/Path";
import {
  convertDynamicPath,
  formatNumber,
  generateSNSIcon,
  handleSNSLink,
} from "app/utils";
import { ICTL_API_ENDPOINT } from "app/services/constant";
import { EResponseStatus, FORMAT_DATE } from "app/constant";
import ImageItem from "app/components/ImageItem";
import DetailListContent from "app/components/DetailListContent";
import CommonService from "app/services/api/CommonService";
import LayoutDefault from "app/ictl/layouts/LayoutDefault";

const tempImage = require("app/assets/images/user-default.jpg");

function Detail() {
  const { t } = useTranslation();
  const params = useParams();
  const [social, setSocial] = useState([]);
  const [values, setValues] = useState({});
  const [gender, setGender] = useState("");
  const [rating, setRating] = useState(0);

  const getDataDetail = () => {
    CommonService.getModelList(
      convertDynamicPath(ICTL_API_ENDPOINT.GET_INFLUENCER_DETAIL, {
        influencer_id: params?.id,
      })
    )
      .then((response) => {
        if (response.status === EResponseStatus.SUCCESS) {
          // temp set
          setSocial(response?.data?.data?.social_media);
          setGender(response?.data?.data?.gender);
          setRating(response?.data?.data?.rating || 0);
          setValues(response?.data?.data);
        }
      })
      .catch(() => {
        // setErrorMess(t("common.message.cannot_display_data"));
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getDataDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const prefixPhoneNumber = getCountryCallingCode("jp".toUpperCase());

  const renderLeftContent = () => {
    return (
      <div className="w-full">
        {social[0] && social[0].social_media && (
          <div className="pb-5 flex flex-col gap-4">
            {social.map((item) => {
              return (
                <div
                  key={item.social_media}
                  className="flex items-center flex-wrap"
                >
                  {generateSNSIcon(item.social_media)}
                  <a
                    href={handleSNSLink(item.social_media, item.username)}
                    target="_blank"
                    className="cursor-pointer text-[#0022FF] mr-3 ml-1 word-break"
                    rel="noreferrer"
                  >
                    {item.username}
                  </a>
                  <Tooltip
                    title={`${formatNumber(item.subscribers_number || 0)} ${t(
                      "common.followers"
                    )}`}
                  >
                    <p className="mr-5 truncate">
                      {formatNumber(item.subscribers_number || 0)}
                      {t("common.followers")}
                    </p>
                  </Tooltip>
                </div>
              );
            })}
          </div>
        )}

        <div className="flex flex-col gap-5">
          <div className="flex items-center gap-5">
            <span className="font-bold text-[18px]">
              {t("admin.influencer.detail.number_of_applications")}
            </span>
            <span className="text-[18px] text-gray font-semibold">
              {formatNumber(values?.count_ad_register)}
            </span>
          </div>
          <div className="flex items-center gap-5 mb-5">
            <span className="font-bold text-[18px]">
              {t("admin.influencer.detail.number_of_approvals")}
            </span>
            <span className="text-[18px] text-gray font-semibold">
              {formatNumber(values?.count_ad_accepted)}
            </span>
          </div>
        </div>

        <p className="font-bold text-[18px] mb-2">
          {t("admin.influencer.detail.approval_history")}
        </p>
        <div className="max-h-[580px] overflow-auto text-gray word-break">
          {values?.list_ad_accepted?.map((item, index) => {
            return (
              <div
                key={item.application_id}
                className={`flex flex-col gap-2 py-3 ${
                  index !== values.list_ad_accepted.length - 1
                    ? "border-b border-dashed border-secondary-200"
                    : ""
                }`}
              >
                <p>
                  {moment(item.update_at).format(
                    t("common.date_format.yyyy_mm_dd")
                  )}
                </p>
                <p>{item.service_provider_name}</p>
                <p>{item.title}</p>
                <p>{`${t("operator.pay.list.price")} ${formatNumber(
                  item.fee ? item.fee[0].fee : 0
                )}`}</p>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderRightContent = () => {
    return (
      <>
        <div className="flex justify-end">
          <Rating size="medium" precision={0.1} value={rating} readOnly />
        </div>
        <div className="flex justify-center mb-10">
          <div className="w-[90px] h-[90px] md:w-[120px] md:h-[120px]">
            <ImageItem
              loadavatar="true"
              avatar="true"
              containerclassname="!rounded-full"
              role="presentation"
              imagesrc={values?.avatar_url ? values?.avatar_url : tempImage}
              alt=""
              imgclassname="w-full h-full object-cover rounded-full"
            />
          </div>
        </div>
        <div className="mb-4">
          <p className="text-[18px] font-bold">
            {t("admin.influencer.detail.comment")}
          </p>
          <span className="text-sm text-gray-500">
            {values?.self_introduction ? values?.self_introduction : null}
          </span>
        </div>
        <div className="mb-4">
          <p className="text-[14px] font-bold mb-1">
            {t("admin.influencer.detail.display_name")}
          </p>
          <div>
            <p className="text-gray text-[14px] pl-2">
              {values?.nickname ? values?.nickname : null}
            </p>
            <div className="border-b-[1px] border-b-gray-300 mt-4" />
          </div>
        </div>

        <div className="mb-4">
          <p className="text-[14px] font-bold mb-1">
            {t("admin.influencer.detail.full_name")}
          </p>
          <div>
            <p className="text-gray text-[14px] pl-2">
              {values?.family_name ? values?.family_name : null}
              {"  "}
              {values?.first_name ? values?.first_name : null}
            </p>
            <div className="border-b-[1px] border-b-gray-300 mt-4" />
          </div>
        </div>

        <div className="mb-4">
          <p className="text-[14px] font-bold mb-1">{t("gender")}</p>
          <div>
            <p className="text-gray text-[14px] pl-2">
              {t(`common.${gender}`)}
            </p>
            <div className="border-b-[1px] border-b-gray-300 mt-4" />
          </div>
        </div>

        <div className="mb-4">
          <p className="text-[14px] font-bold mb-1">
            {t("admin.influencer.detail.birthday")}
          </p>
          <div>
            <p className="text-gray text-[14px] pl-2">
              {values?.birthday
                ? moment(values?.birthday).format(FORMAT_DATE.YYYY_MM_D)
                : t("common.message.no_input_yet")}
            </p>
            <div className="border-b-[1px] border-b-gray-300 mt-4" />
          </div>
        </div>

        <div className="mb-4">
          <p className="text-[14px] font-bold mb-1">{t("common.email")}</p>
          <div>
            <p className="text-gray text-[14px] pl-2">
              {values?.email_address ? values?.email_address?.main : null}
            </p>
            <div className="border-b-[1px] border-b-gray-300 mt-4" />
          </div>
        </div>

        <div className="mb-4">
          <p className="text-[14px] font-bold mb-1">
            {t("admin.influencer.detail.phone_number")}
          </p>
          <div>
            <p className="text-gray text-[14px] pl-2">
              {values?.phone_number?.main
                ? `+${prefixPhoneNumber} ${values?.phone_number?.main}`
                : t("common.message.no_input_yet")}
            </p>
            <div className="border-b-[1px] border-b-gray-300 mt-4" />
          </div>
        </div>
      </>
    );
  };

  return (
    <LayoutDefault showFooter showHeader pin={4}>
      <MainContent showBackButton path={ictlPath.influencer_list}>
        <DetailListContent
          leftContent={renderLeftContent()}
          rightContent={renderRightContent()}
          title={t("admin.influencer.detail.influencer_information")}
        />
      </MainContent>
    </LayoutDefault>
  );
}

export default Detail;
