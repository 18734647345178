/* eslint-disable camelcase */
import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import ImageEdit from "app/operator/components/ImageEdit";
import { getProjectDetailData, updateProject } from "app/utils/page/project";
import Path from "../../../../route/Path";

function AdProjectEditImage() {
  const params = useParams();
  const { projectId } = params;
  const [formValues, setFormValues] = useState({});

  const initialValues = useMemo(() => {
    const formatedFiles = formValues?.files?.map(
      ({ media_url, thumbnail_url, media_type }) => ({
        media_type: media_type || "",
        url: media_url || "",
        thumbnailUrl: thumbnail_url || "",
      })
    );
    return {
      imageList: formatedFiles || [],
      coverImg: formValues?.coverImg || "",
    };
  }, [formValues]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getProjectDetailData({ setFormValues, projectId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUpdateAllInfo = ({
    newListImg,
    newCoverImg,
    callbackSuccess,
    callbackError,
  }) => {
    const formatImgList = newListImg?.map(({ url }) => ({
      media_type: 1,
      media_url: url,
      thumbnail_url: url,
    }));
    const modifyData = {
      files: formatImgList || formValues?.files,
      coverImg: newCoverImg || formValues?.coverImg,
    };
    updateProject({
      data: {
        ...formValues,
        ...modifyData,
      },
      projectId,
      callbackSuccess,
      callbackError,
    });
  };

  return (
    <ImageEdit
      backLink={Path.ad_project_detail(projectId)}
      initialValues={initialValues}
      onUpdateAllInfo={onUpdateAllInfo}
      limit
    />
  );
}

export default AdProjectEditImage;
