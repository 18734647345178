/* eslint-disable react/no-array-index-key */
import React from "react";
import { useTranslation } from "react-i18next";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import CustomExpand from "app/components/CustomExpand";
import { ErrorMessage } from "formik";
import { customAccordionStyle } from "app/utils";
import FormItem, { TYPE_INPUT } from "app/operator/components/FormItem";
import { MAX_LENGTH_100 } from "app/utils/schema/operator";

function AccountEmail(props) {
  const { formikProps } = props;
  const { values: formValues, errors } = formikProps;
  const { t } = useTranslation();

  return (
    <div className="mb-[16px]">
      <div className="mb-[8px] font-semibold required inline">
        {t("common.email")}
      </div>
      <Accordion sx={customAccordionStyle()}>
        <AccordionSummary
          classes={{ expanded: "!transform-none", root: "gap-1" }}
          expandIcon={<CustomExpand />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="flex">
            <div className="flex flex-wrap gap-[8px]" translate="no">
              <ErrorMessage
                name="account_email"
                component="div"
                className="errors-msg"
              />
              {!errors.account_email && <div>{formValues?.account_email}</div>}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <FormItem
            touched
            required
            disableError
            name="account_email"
            showCharCount
            maxLength={MAX_LENGTH_100}
            typeInput={TYPE_INPUT.TEXT}
            defaultLength={formValues.account_email?.length}
            label={t("common.email")}
            placeholder={t("common.message.no_input_yet")}
            // space
            labelClassName="font-semibold mb-2"
            inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
            containerClassName="mb-[16px]"
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default AccountEmail;
