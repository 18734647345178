import React from "react";
import ictlPath from "app/ictl/route/Path";
import LoginSecurityForm from "app/components/login-security/LoginSecurityForm";
import LayoutDefault from 'app/ictl/layouts/LayoutDefault';


function LoginSecurity() {
  return (
    <LayoutDefault showFooter showHeader>
      <LoginSecurityForm path={ictlPath.account_settings} />
    </LayoutDefault>
  );
}

export default LoginSecurity;

