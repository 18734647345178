import React, { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import AuthService from "app/services/api/AuthService";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { setAuthUser, setAuthToken } from "app/redux/authReducer";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import jwtDecode from "jwt-decode";
import UserService from "app/services/api/UserService";
import { setProfileStore } from "app/redux/profileReducer";
import { EResponseStatus } from "app/constant";
import LayoutPageColor from "../../../../components/LayoutPageColor";
import CommonLogin from "./CommonLogin";
import { emailRegex, passwordRegex } from "../../../../constant";

const mashupLogo = require("app/assets/images/buzz-logo.png");

function SignInGmail() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-wrap sm:flex-row-reverse items-stretch h-screen overflow-hidden ">
      <div className="h-[60%] md:h-full w-full md:w-1/2 xl:w-1/2 bg-cover flex">
        <RegistrationForm className="my-8 md:my-auto shadow-lg" />
      </div>
      <LayoutPageColor text={t("auth.text_login_email")} />
    </div>
  );
}

function RegistrationForm() {
  const [err, setErr] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t("validate_message.required", { path: t("common.email") }))
      .trim(t("validate_message.required", { path: t("common.email") }))
      .matches(
        emailRegex,
        t("validate_message.wrong_format", {
          path: t("common.email"),
        })
      ),
    password: Yup.string()
      .required()
      .max(64, t("auth.register.password_regex"))
      .matches(passwordRegex, t("auth.register.password_regex")),
  });
  const onSubmit = async (values) => {
    await AuthService.login(values)
      .then((res) => {
        const accessToken = res.data?.data.accessToken;
        const user = jwtDecode(accessToken);
        user.is_first_login = false;
        dispatch(setAuthUser(user));
        dispatch(setAuthToken(res.data?.data));
        UserService.getProfile().then((response) => {
          if (response.status === EResponseStatus.SUCCESS) {
            dispatch(setProfileStore(response.data.data));
          }
        });
        if (redirect && redirect !== "/") {
          navigate(redirect);
        }
        const path = CommonLogin(user);
        navigate(path);
      })
      .catch(() => {
        setErr(t("auth.login.Enter_wrong_infomation"));
      });
  };

  const { handleSubmit, errors, values, setFieldValue, touched } = useFormik({
    initialValues: {
      email: "",
      password: "",
      social_id_provider: "email_smv",
    },
    onSubmit,
    validationSchema,
  });

  return (
    <form
      onSubmit={handleSubmit}
      className="m-auto w-2/3 bg-white rounded p-3 text-center"
    >
      <List component="nav" aria-label="mailbox folders" className="w-full">
        <ListItem style={{ maxWidth: 422, minWidth: "auto" }}>
          <img src={mashupLogo} className="w-full" alt="Logo" />
        </ListItem>
        <ListItem>
          <div className="w-full">
            {err && !errors.email ? (
              <p className="text-rose-500">{err}</p>
            ) : null}
          </div>
        </ListItem>
        <ListItem>
          <div className="w-full">
            <input
              onChange={(e) => {
                setErr("");
                setFieldValue("email", e.target.value);
              }}
              type="text"
              value={values.email}
              placeholder={t("common.email")}
              className="w-full h-10 sm:h-14 bg-gray-50 border-2 border-slate-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
            />
            {touched.email && errors.email ? (
              <p className="text-rose-500">{errors.email}</p>
            ) : null}
          </div>
        </ListItem>
        <ListItem>
          <div className="w-full">
            <input
              onChange={(e) => {
                setErr("");
                setFieldValue("password", e.target.value);
              }}
              type="password"
              value={values.password}
              placeholder={t("auth.login.Password")}
              className="h-10 sm:h-14 bg-gray-50 border-2 border-slate-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5"
            />
            {touched.password && errors.password ? (
              <p className="text-rose-500">{errors.password}</p>
            ) : null}
          </div>
        </ListItem>
        <button
          className="font-bold border rounded h-8 mt-10 sm:w-1/2 w-40 colorButtonCommon textColorCommon"
          type="submit"
        >
          {t("auth.login.Login")}
        </button>
        <p className="text-gray-800 mt-6 underline underline-offset-1 w-full">
          <Link
            to="/change-password"
            className="text-blue-600 hover:text-blue-700 focus:text-blue-700 transition duration-200 ease-in-out w-full"
          >
            {t("auth.login.reset_password")}
          </Link>
        </p>
      </List>
    </form>
  );
}

export default SignInGmail;
