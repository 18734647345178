/* eslint-disable jsx-a11y/label-has-associated-control */
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { emailRegex, EResponseStatus } from "app/constant";
import { checkValueChange } from "app/utils";
import { setActive } from "app/redux/blockUIReducer";
import AuthService from "app/services/api/AuthService";

function ChangeEmail({
  setErrorMess,
  setSuccessMess,
  setShowChangeLoginId,
  email,
  setLoginInfo,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    email: Yup.string().required(t("common.message.email_message")).trim().matches(emailRegex).max(64),
  });
  const initialValues = {
    email: email || "",
  };

  const { social_id_provider: socialIdProvider } = useSelector(
    (state) => state.auth.user
  );

  const changeEmail = (values, context) => {
    dispatch(setActive(true));
    const { resetForm } = context;
    const data = {
      email: values.email,
    };
    AuthService.changeLoginId(data)
      .then((response) => {
        if (response.status === EResponseStatus.CREATED) {
          setSuccessMess(
            socialIdProvider === "email_smv"
              ? t("operator.accountSetting.login_security.logout_notice")
              : t("common.message.update_success")
          );
          setShowChangeLoginId(false);
          resetForm();
          setLoginInfo((prev) => ({
            ...prev,
            email: values.email,
          }));
        }
      })
      .catch((error) => {
        if (error.response.status === EResponseStatus.NOT_FOUND) {
          const errorMess = error.response.data.message;
          if (errorMess === "DATA_EXIST") {
            setErrorMess(t("operator.accountSetting.login_security.email_exist"));
          } else {
            setErrorMess(t(`common.message.${errorMess}`));
          }
        } else {
          setErrorMess(t(`common.message.update_fail`));
        }
      })
      .finally(() => dispatch(setActive(false)));
  };

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={changeEmail}
    >
      {({ values }) => {
        const isValuesHasChanged = checkValueChange(
          initialValues,
          values,
          false
        );
        return (
          <Form className="w-full">
            <div className="w-full h-auto flex flex-col">
              <div className="w-full mb-2">
                <label
                  htmlFor="loginId"
                  className="text-base leading-[22px] font-normal mb-2 inline-block cursor-pointer"
                >
                  {t("common.email")}
                </label>

                <div className="w-full">
                  <Field
                    id="loginId"
                    name="email"
                    maxLength={64}
                    type="text"
                    className="leading-6 p-[11px] border border-border w-full rounded focus:border-[#008489] !outline-none"
                  />
                  <ErrorMessage
                    component="div"
                    name="email"
                    className="error-msg"
                  />
                </div>
              </div>

              <div className="w-full mt-4">
                <Button
                  disabled={!isValuesHasChanged}
                  type="submit"
                  variant="contained"
                  className="!px-6 !py-2.5 !rounded-md !bg-[#008489] !text-base !font-extrabold !text-white"
                >
                  {t("operator.accountSetting.login_security.change_email")}
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ChangeEmail;
