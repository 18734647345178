export default {
  paymentInfluence: "網紅支付款項",
  historyPaymentInfluence: "支付網紅的付款記錄",
  waitingPayment: "等待企業者付款",
  warningUpdate: "是否要更新以下網紅的資料為'已支付'？\n{{value}}",
  listPaymentInfluence: {
    totalPaymentRequest: "付款申請 總金額",
    CumulativeTotalAmount: "累計總金額",
    totalRequestPayment: "付款申請數量 {{value}}件",
    nameInfluence: "網紅名稱",
    paymentRequest: "付款申請",
    cumulativeRewardAmount: "累計付款總金額",
    payPalAcount: "PayPalアカウント情報",
    yes: "有",
    nothing: "無",
    updatePaid: "更新為 已支付",
  },
  detail: {
    totalItem: "合計件數 {{value}}件",
    nameProjec: "招募案件名稱",
    CompensationAmount: "報酬金額",
    unitPrice: "單位價格",
    number: "件數",
    compensationDate: "薪資確認日期",
  },
  listPaymentHistory: {
    paymentTotalAmount: "付款總金額",
    numberOfPayment: "付款件數 {{value}}件",
    payment: "已付金額",
    RenewalDateToPaid: "已付金額更新日",
    changer: "更新人",
  },
  listRequestPayment: {
    WaitingForPaymentTotalAmount: "待匯款 合計總金額",
    searchForInfluence: "網紅名稱",
    businessPerson: "公司業者",
    waitingAmount: "待匯款金額",
    businessName: "企業名稱",
  },
};