import React, { useContext } from "react";
import { Tooltip } from "@mui/material";
import { ProjectDetailContext } from "app/ictl/pages/Projects/social";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { generateSNSIcon, projectStatus } from "app/utils";
import ImageItem from "app/components/ImageItem";

function DetailInfo() {
  const { t } = useTranslation();
  const { formValues } = useContext(ProjectDetailContext) || {};
  const startRecuit = formValues?.start_recruitment_at
    ? moment(formValues?.start_recruitment_at)
        .tz("Asia/Tokyo")
        .format("YY/MM/DD")
    : null;
  const endRecuit = formValues?.end_recruitment_at
    ? moment(formValues?.end_recruitment_at).tz("Asia/Tokyo").format("YY/MM/DD")
    : null;
  const recruitmentDate =
    startRecuit && endRecuit
      ? `${startRecuit}~${endRecuit}`
      : t("common.other1");

  const generateCover = () => {
    let thumbnail = "";
    if (formValues?.project_media) {
      const cover = formValues?.project_media?.find(
        (item) => item.use_for_cover
      );

      if (cover) {
        thumbnail = cover?.thumbnail_url;
      } else {
        thumbnail = formValues?.project_media[0]
          ? formValues?.project_media[0]?.thumbnail_url
          : "";
      }
    }

    return thumbnail;
  };

  return (
    <div className="border-y border-border py-[12px] flex  overflow-auto">
      <div className="flex-[2_0_250px] flex w-full h-full items-center pr-[20px]">
        <div className="mr-2 w-[100px] h-[50px] flex-shrink-0">
          <ImageItem
            containerclassname="!rounded-none"
            imagesrc={generateCover()}
            alt=""
            imgclassname="w-full h-full object-cover"
          />
        </div>
        <div className="max-w-[800px] truncate">
          <div className="truncate">
            <Tooltip title={formValues?.title}>
              <span>{formValues?.title}</span>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="flex-[2_0_100px] flex items-center min-w-0 pr-[8px]">
        <i className="fa-solid fa-hourglass mr-2" />
        <div className="truncate">
          {projectStatus(formValues?.visibility_and_status)}
        </div>
      </div>
      <div className="flex-[2_0_200px] flex items-center min-w-0 pr-[8px]">
        <i className="fa-regular fa-calendar mr-2" />
        <div className="truncate">{recruitmentDate}</div>
      </div>
      <div className="flex-[1_0_100px] flex items-center">
        {formValues?.number_influencer_applied || 0} {t("common.passage")}
      </div>
      <div className="flex-[1_0_100px] flex items-center">
        {formValues?.number_influencer_approved_apply || 0}{" "}
        {t("common.passage")}
      </div>
      <div className="flex-[1_0_100px] flex items-center">
        {formValues?.number_influencer_done || 0} {t("common.passage")}
      </div>
      <div className="flex-[1_0_100px] flex items-center">
        {generateSNSIcon(formValues?.ad_media)}
      </div>
    </div>
  );
}

export default DetailInfo;
