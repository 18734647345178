import home from "../pages/Home/translate/HomeEN";
import influencer from "../pages/Influencer/translate/InfluencerEN";
import pay from "../pages/Pay/translate/en";
import business from '../pages/BusinessPerson/translate/en';
import businessInformation from '../pages/BusinessOperatorDetail/translate/en';
import notification from '../pages/Notification/translate/NotificationEN';
import banner from "../pages/Banner/translate/en";
import account from "../pages/Account/translate/en";
import payment from "../pages/payment/translate/en";

export default {
  home,
  influencer,
  pay,
  business,
  businessInformation,
  notification,
  banner,
  account,
  payment,
};
