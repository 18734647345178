import React, { useContext } from "react";
import { useTranslation } from 'react-i18next';
import { LayoutContext } from '../utils';

const link1 = "https://enjoy-japan.jp/privacy-policy/";
const link2 = "https://enjoy-japan.jp/";
const link3 = "https://enjoy-japan.jp/";

function Footer() {
  const { t } = useTranslation()
  const { isChangeLangOpen, setIsChangeLangOpen } = useContext(LayoutContext);

  return (
    <footer className="border-t border-solid border-secondary-300 bg-secondary">
      <div className="container">
        <div className="py-7 flex flex-col gap-2">
          <div className="grid grid-cols-1 sm:grid-cols-2 items-baseline gap-2">
            <div className="flex items-center text-sm sm:order-2 gap-5 sm:justify-end">
              <button
                type="button"
                onClick={() => setIsChangeLangOpen(!isChangeLangOpen)}
                className="cursor-pointer w-7 h-7 rounded-full"
              >
                <i className="fa-solid fa-globe" />
              </button>

              <p className="font-bold">{t('common_footer.currency')} (JP)</p>
              <div className="flex items-center gap-2">
                <p className="font-bold">¥</p>
                <p className="font-bold">JPY</p>
              </div>
            </div>

            <div className="text-sm">
              <div className="flex items-center flex-wrap gap-1 text-xs sm:text-sm md:text-xs">
                <p className="md:mr-2">
                  {t("common_footer.copyright")}
                </p>
                <div className="flex flex-wrap">
                  <a
                    href={link1}
                    className="hover:underline truncate cursor-pointer"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("common_footer.privacy_policy")}
                  </a>
                  <div className="mx-2">•</div>
                  <a
                    className="hover:underline truncate cursor-pointer"
                    href={link2}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("common_footer.term_service")}
                  </a>
                  <div className="mx-2">•</div>
                  <a
                    className="hover:underline truncate cursor-pointer"
                    href={link3}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("common_footer.company_info")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
