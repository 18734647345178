/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { Link } from "react-router-dom";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import MainContent from "app/components/MainContent";
import LayoutDefault from "app/ictl/layouts/LayoutDefault";
import Path from "../../../route/Path";
import Language from "./Language";
import Currency from "./Currency";
import Timezone from "./Timezone";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    border: "1px solid #161718FF",
    padding: "12px 30px 12px 10px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      borderColor: "#008489",
    },
  },
}));

function GlobalSetting() {
  return (
    <LayoutDefault showHeader>

      <MainContent>
        <div className="mt-20">
          <div className="w-full px-x max-w-[960px] mx-auto px-2">
            <div className="w-full py-10">
              <div className="flex md:hidden items-center relative h-9">
                <Link
                  to={Path.account_settings}
                  className="w-7 h-7 flex items-center justify-center absolute left-0 top-[50%] translate-y-[-50%]"
                >
                  <i className="fa-solid fa-chevron-left" />
                </Link>
              </div>
              <ol className="hidden md:block text-sm leading-[18px] text-REGULAR_TEXT_COLOR font-semibold">
                <li className="inline-block hover:underline">
                  <Link to={Path.account_settings}>
                    <span>アカウント</span>
                  </Link>
                </li>
                <li className="inline-block">
                  <div className="inline-block mx-4">
                    <svg
                      fill="#767676"
                      viewBox="0 0 18 18"
                      style={{ height: 10, width: 10 }}
                    >
                      <path
                        fillRule="#767676"
                        d="M4.29 1.71A1 1 0 115.71.3l8 8a1 1 0 010 1.41l-8 8a1 1 0 11-1.42-1.41l7.29-7.29z"
                      />
                    </svg>
                  </div>
                  <span>グローバル設定</span>
                </li>
              </ol>

              <h1 className="text-[32px] leading-9 text-REGULAR_TEXT_COLOR font-extrabold mt-3">
                グローバル設定
              </h1>
            </div>

            <div className="h-auto grid grid-cols-12 md:gap-[60px]">
              <div className="col-span-12 md:col-span-7">
                <div className="w-full h-auto">
                  <div className="!px-0">
                    <Language inputComponent={<BootstrapInput />} />
                    <Currency inputComponent={<BootstrapInput />} />
                    <Timezone inputComponent={<BootstrapInput />} />
                  </div>
                </div>
              </div>
              <div className="hidden md:block col-span-12 md:col-span-5">
                <div className="border border-border p-5 flex flex-col gap-4">
                  <svg
                    className="h-10 w-10 block fill-[#ffb400]"
                    viewBox="0 0 24 24"
                    role="presentation"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path d="m21.31 5.91a1.31 1.31 0 1 1 -1.31-1.31 1.31 1.31 0 0 1 1.31 1.31zm-8.31 9.69a1.31 1.31 0 1 0 1.31 1.31 1.31 1.31 0 0 0 -1.31-1.31zm-7-11a1.31 1.31 0 1 0 1.31 1.31 1.31 1.31 0 0 0 -1.31-1.31z" />
                    <path
                      d="m22 6.5a2.5 2.5 0 0 1 -2 2.45v13.55a.5.5 0 0 1 -1 0v-13.55a2.5 2.5 0 0 1 0-4.9v-2.55a.5.5 0 0 1 1 0v2.56a2.44 2.44 0 0 1 .33.09.5.5 0 0 1 -.33.94h-.01a1.45 1.45 0 0 0 -.99.01 1.49 1.49 0 0 0 0 2.82 1.4 1.4 0 0 0 1 0 1.5 1.5 0 0 0 1-1.41 1.48 1.48 0 0 0 -.09-.52.5.5 0 0 1 .94-.35 2.5 2.5 0 0 1 .16.87zm-7.8 9.83a.5.5 0 0 0 -.29.64 1.48 1.48 0 0 1 .09.52 1.5 1.5 0 0 1 -1 1.41 1.4 1.4 0 0 1 -1 0 1.49 1.49 0 0 1 0-2.82 1.48 1.48 0 0 1 .5-.09 1.52 1.52 0 0 1 .5.08h.01a.5.5 0 0 0 .32-.94 2.46 2.46 0 0 0 -.32-.08v-13.56a.5.5 0 0 0 -1 0v13.55a2.5 2.5 0 0 0 0 4.9v2.55a.5.5 0 0 0 1 0v-2.55a2.5 2.5 0 0 0 1.84-3.32.5.5 0 0 0 -.64-.29zm-7-11a .5.5 0 0 0 -.29.64 1.48 1.48 0 1 1 -1.41-.98 1.47 1.47 0 0 1 .49.08h.01a.5.5 0 0 0 .33-.94 2.44 2.44 0 0 0 -.33-.09v-2.56a.5.5 0 0 0 -1 0v2.55a2.5 2.5 0 0 0 0 4.9v13.55a.5.5 0 0 0 1 0v-13.55a2.5 2.5 0 0 0 1.84-3.32.5.5 0 0 0 -.64-.29z"
                      fill="#484848"
                    />
                  </svg>
                  <p className="font-bold text-[18px]">グローバル設定</p>
                  <p>
                    通貨を変更すると、表示料金が自動的に変わります。
                    支払い受取方法は、支払い＆受取設定で変更できます。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainContent>
      {/* <SuccessDialog
        message={t("common.message.update_success")}
        open={openSuccess}
        onClose={() => setOpenSuccess(false)}
        onOK={() => setOpenSuccess(false)}
      />
      <ErrorDialog
        message={t("common.message.update_fail")}
        open={openError}
        onClose={() => setOpenError(false)}
        onOK={() => setOpenError(false)}
      /> */}
    </LayoutDefault>
  );
}

export default GlobalSetting;
