/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { useTranslation } from "react-i18next";
import { MAX_FILE_SIZE } from "app/constant";
import ImageItem from "app/components/ImageItem";
import { isNewFileUploading } from "app/utils";

function AccountAvatar(props) {
  const { formikProps } = props;
  const { values, setFieldError, setFieldValue, errors } = formikProps;
  const { t } = useTranslation();

  const onFileChange = (event) => {
    // Allowing file type
    const allowedExtensions = /[^\\/.]+(?=(\.png|\.jpeg|\.jpg)$)/;

    const errorImageSizeMess = t("common.message.oversize_file", {
      size: MAX_FILE_SIZE / 1024 / 1024,
    });
    const errorFormatMess = t("common.message.wrong_format_file", {
      format: "PNG、JPEG、JPG",
    });
    const files = event.target.files || event.dataTransfer.files;
    if (!files.length) return;
    if (!allowedExtensions.test(files[0].name.toLowerCase())) {
      setFieldError("account_avatar", errorFormatMess);
    } else if (files[0].size > MAX_FILE_SIZE) {
      setFieldError("account_avatar", errorImageSizeMess);
    } else {
      setFieldValue("account_avatar", files[0]);
    }
  };

  return (
    <div className="flex flex-col mb-5">
      {errors?.account_avatar && (
        <p className="error-msg truncate text-center py-2">
          {errors?.account_avatar}
        </p>
      )}
      <div className="w-[120px] h-[120px] self-center">
        <ImageItem
          avatar="true"
          loadavatar="true"
          containerclassname="!rounded-full"
          role="presentation"
          imagesrc={
            isNewFileUploading(values?.account_avatar)
              ? URL.createObjectURL(values?.account_avatar)
              : values?.account_avatar
          }
          alt=""
          imgclassname="w-full h-full object-cover rounded-full overflow-hidden"
        />
      </div>
      <div className="upload-avatar text-center underline">
        <input
          onClick={(e) => {
            e.target.value = null;
          }}
          onChange={(event) => onFileChange(event)}
          id="account_avatar"
          name="account_avatar"
          type="file"
          className="h-0 w-0 overflow-hidden"
        />
        <label
          htmlFor="account_avatar"
          className="text-sm font-semibold hover:text-black cursor-pointer"
        >
          {t("common.message.upload_avatar")}
        </label>
      </div>
    </div>
  );
}

export default AccountAvatar;
