/* eslint-disable class-methods-use-this */
import client from "./client";
import CommonService from "./CommonService";

class AuthService {
  login(data) {
    return CommonService.postModel("/auth/sign-in", data);
  }

  loginWithSocial(provider, data) {
    return CommonService.postModel(`auth/${provider}/redirect`, data);
  }

  checkEmail(data) {
    return CommonService.postModel("/user/check-exist-email", data);
  }

  singup(data) {
    return client.post("auth/smv/sign-up", data);
  }

  resetPassword(data) {
    return client.post(`user/forgot-password`, data);
  }

  changePassword(data) {
    return client.post("/user/change-password", data);
  }

  getAllSnsConnect() {
    return CommonService.getModelList(`auth/get-all-service-provider`);
  }

  connectWithSocial(platform, data) {
    return CommonService.postModel(`auth/${platform}/link-account`, data);
  }

  disconnectWithSocial(social) {
    return client.put(`auth/unlink-account/${social}`);
  }

  getEmailToLoginUser() {
    return client.get(`auth/email-to-login`);
  }

  changeLoginId(data) {
    return client.post(`auth/email-to-login/create-or-update`, data);
  }
}

export default new AuthService()