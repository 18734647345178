import { Button } from "@mui/material";
import React, { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import { checkValueChange } from "app/utils";

function EditableForm(props) {
  const {
    validationSchema,
    initialValues,
    onSubmit,
    formValues,
    editField,
    displayValue,
    editForm,
    label,
    showEdit,
    setShowEdit,
    description,
    required,
  } = props;
  const formikRef = useRef();
  const { t } = useTranslation();

  const cancelEdit = (setValues) => {
    if (showEdit) {
      setValues(formValues);
    }
    setShowEdit(showEdit ? "" : editField);
  };

  // set formik values
  useEffect(() => {
    if (formikRef && formikRef.current) {
      const { setValues } = formikRef.current;
      if (Object.keys(formValues).length) {
        setValues({
          ...formValues,
          phone_number: formValues?.phone_number || "+81",
        });
      }
    }
  }, [formValues, showEdit, editField]);

  return (
    <Formik
      innerRef={formikRef}
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(context) => {
        const { setValues, values } = context;
        const isValuesHasChanged = checkValueChange(formValues, values, false);
        return (
          <Form
            className={`w-full border-b border-border ${
              showEdit && showEdit !== editField
                ? "cursor-not-allowed opacity-40"
                : ""
            }`}
          >
            <div className="py-6 px-0">
              <div className="flex justify-between items-start">
                <div className="flex1 w-full mr-2">
                  <h2 className="text-base leading-5 text-REGULAR_TEXT_COLOR font-normal">
                    {label}
                    {required && <span className="required" />}
                  </h2>
                  <p className="text-sm leading-[18px] text-secondary-500 mt-1">
                    {!showEdit || showEdit !== editField
                      ? displayValue ||
                        t("common.message.no_input_yet")
                      : description || ""}
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <button
                    type="button"
                    className={`text-base leading-5 text-REGULAR_TEXT_COLOR font-semibold underline cursor-pointer hover:text-black ${
                      showEdit && showEdit !== editField
                        ? "!cursor-not-allowed"
                        : ""
                    }`}
                    disabled={showEdit && showEdit !== editField}
                    onClick={() => cancelEdit(setValues)}
                  >
                    {!showEdit || showEdit !== editField
                      ? t("operator.accountSetting.personal_info.edit_btn")
                      : t("common.action.cancel")}
                  </button>
                </div>
              </div>
            </div>
            <div
              className={`pb-6 ${
                showEdit && showEdit === editField ? "block" : "hidden"
              }`}
            >
              {editForm(context)}
            </div>
            {showEdit && showEdit === editField && (
              <Button
                disabled={!isValuesHasChanged}
                type="submit"
                variant="contained"
                className="!px-6 !py-3 !text-white !bg-REGULAR_TEXT_COLOR !rounded-[8px] !mb-6"
              >
                {t("common.action.save")}
              </Button>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default EditableForm;
