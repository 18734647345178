// m-009-001
/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LayoutDefault from "app/ictl/layouts/LayoutDefault";
import MainContent from "app/components/MainContent";
import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";
import moment from "moment";

import { filterCaching } from "app/utils";
import {
  // EFacilityStatus,
  FORMAT_DATE,
  FILTER_CACHING_KEY,
  FORMAT_TIME_TO_SEND,
} from "app/constant";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import SelectComponent from "../../../../ictl/components/SelectComponent";
import { OPERATOR_API_ENDPOINT } from "../../../../services/constant";
import CommonService from "../../../../services/api/CommonService";
import { EResponseStatus, fomatPrice } from "../../../../constant";
// import Footer from "app/components/Footer";

registerLocale("ja", ja);

export default function Manager() {
  const [facilities, setFacilities] = useState([]);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const localStorageFilterCachingData = filterCaching(
    FILTER_CACHING_KEY.MANAGER
  )?.getter;
  const initStartDate = () => {
    if (params.get("date_start")) {
      return new Date(params.get("date_start"));
    }
    if (localStorageFilterCachingData?.date_start) {
      return new Date(localStorageFilterCachingData?.date_start);
    }
    return new Date();
  };

  const initEndDate = () => {
    if (params.get("date_end")) {
      return new Date(params.get("date_end"));
    }
    if (localStorageFilterCachingData?.date_end) {
      return new Date(localStorageFilterCachingData?.date_end);
    }
    return new Date();
  };

  const initialFilterData = {
    facility_id:
      params.get("facility_id") ||
      localStorageFilterCachingData?.facility_id ||
      "",
    index_facility:
      params.get("index_facility") ||
      localStorageFilterCachingData?.index_facility ||
      0,
    date_start: initStartDate(),
    date_end: initEndDate(),
  };
  // eslint-disable-next-line no-unused-vars
  const [filterValues, setFilterValues] = useState(initialFilterData);

  const [openErrExport, setOpenErrExport] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [optionSelected, setOptionSelected] = useState([t("common.btn.export")]);
  const [dateRange, setDateRange] = useState([
    initialFilterData.date_start,
    initialFilterData.date_end,
  ]);
  const [startDate, endDate] = dateRange;
  const [res, setRes] = useState({});

  const options = [
    {
      text: t("common.btn.download_csv"),
      value: "download_csv",
    },
  ];

  const getListData = () => {
    CommonService.getModelList(OPERATOR_API_ENDPOINT.GET_LIST_REPORT, {
      date_start: startDate,
      date_end: endDate,
      facility_id: filterValues.facility_id,
    })
      .then((response) => {
        if (response.status === EResponseStatus.SUCCESS) {
          // temp set data
          setRes(response.data.data);
        }
      })
      .catch(() => {
        // setErrorMess(t("common.message.cannot_display_data"));
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  // useEffect(() => {
  //   getListData();
  //   navigate(`?date_start=${startDate}&date_end=${endDate}`);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [startDate, endDate]);
  const getListFacility = () => {
    CommonService.getModelList(
      OPERATOR_API_ENDPOINT.GET_LIST_FACILITY_BY_USER
    ).then((response) => {
      const dataFacilities = response.data.data;
      setFacilities(dataFacilities);
    });
  };

  useEffect(() => {
    return getListFacility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getListData();
    const queryString = {
      facility_id: filterValues?.facility_id
        ? `facility_id=${filterValues?.facility_id}`
        : "",
      index_facility: filterValues?.index_facility
        ? `index_facility=${filterValues?.index_facility}`
        : 1,
      date_start: startDate
        ? `date_start=${moment(filterValues?.date_start).format(
            FORMAT_TIME_TO_SEND
          )}`
        : "",
      date_end: endDate
        ? `date_end=${moment(filterValues?.date_end).format(
            FORMAT_TIME_TO_SEND
          )}`
        : "",
    };
    const joinedStr = Object.values(queryString)
      ?.filter((item) => item)
      ?.join("&&");
    navigate(`?${joinedStr}`, { replace: true });
    filterCaching(FILTER_CACHING_KEY.MANAGER).setter(filterValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues]);

  useEffect(() => {
    if (facilities.length > 0 && filterValues.facility_id === "") {
      setFilterValues({
        ...filterValues,
        facility_id: facilities?.[0].facility_id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilities]);

  // export csv
  const handleChange = () => {
    CommonService.getModelList(OPERATOR_API_ENDPOINT.EXPORT_LIST_REPORT, {
      date_start: filterValues?.date_start,
      date_end: filterValues.date_end,
      facility_id: filterValues?.facility_id,
    })
      .then((response) => {
        setOpenErrExport(false);
        if (response.status === EResponseStatus.SUCCESS) {
          // temp set data
          window.location.href = response.data.data.file_url;
        }
      })
      .catch(() => {
        setOpenErrExport(true);
      })
      .finally(() => {
        // setIsLoading(false);
      });

    setOptionSelected([t("common.btn.export")]);
  };

  // newDate is array
  const changeDate = (newDate) => {
    setDateRange(newDate);
    console.log(newDate);
    if (
      (newDate[0] && newDate[1] !== null) ||
      (newDate[0] === null && newDate[1] === null)
    ) {
      setFilterValues({
        ...filterValues,
        date_start: newDate[0],
        date_end: newDate[1],
      });
      filterCaching(FILTER_CACHING_KEY.MANAGER).setter({
        ...filterValues,
        date_start: newDate[0],
        date_end: newDate[1],
      });
    }
  };

  return (
    <LayoutDefault showHeader>
      <MainContent>
        <div className="w-full mt-10">
          <Collapse in={openErrExport}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  size="small"
                  onClick={() => {
                    setOpenErrExport(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {t("admin.home.Enter_wrong_infomation")}
            </Alert>
          </Collapse>
        </div>

        <div className="flex flex-wrap items-center justify-between mb-50">
          <div className="flex items-center w-full md:w-auto md:flex-1 mb-7 md:mb-0 md:mr-4 border-solid border-secondary-300 font-bold" />
          <div>
            <div className=" px-4 py-3 mt-4 mb-10">
              <SelectComponent
                lstOption={options}
                placeholder={t("common.btn.export")}
                handleChange={handleChange}
                selectClass="w-40 ml-auto !rounded-xl h-12"
                optionSelected={optionSelected}
              />
            </div>
          </div>
        </div>

        <div className="flex items-center mb-4 relative ">
          <div className="min-w-[10px] text-sm flex items-center h-11 border border-solid border-secondary-200 border-r-0 bg-secondary-400 whitespace-nowrap rounded-l-lg" />
          <DatePicker
            dateFormat={FORMAT_DATE.YYY_M_Do}
            wrapperClassName="custom"
            className="outline-none font-bold bg-secondary-400 text-sm flex-1 "
            selectsRange
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => changeDate(update)}
            isClearable
            placeholderText="日付を選択してください"
            locale={ja}
            maxDate={moment().toDate()}
          />
          {startDate ? null : (
            <div className="absolute top-1/2 -translate-y-1/2 left-[240px]">
              <i className="fa-solid fa-caret-down" />
            </div>
          )}
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 mb-10">
          <div className="min-h-[132px] font-bold p-3 flex flex-col border border-solid border-black rounded-lg">
            <span className="mb-7 ml-3">
              {t("operator.manager.total_sales")}
            </span>
            <span className="text-3xl text-right pr-[40px]">
              {t("common.currencySymbol")}
              {res.totalAmount ? fomatPrice(res.totalAmount) : 0}
            </span>
            <span className="text-right pr-[30px]">
              （{t("admin.home.fee_consumption")}
              {t("common.currencySymbol")}
              {res.feeAmount ? fomatPrice(res.feeAmount) : 0} ）
            </span>
          </div>
          <div className="min-h-[132px] font-bold p-3 flex flex-col border border-solid border-black rounded-lg">
            <span className="mb-7 ml-3">
              {" "}
              {t("operator.manager.reservation_sales")}
            </span>
            <span className="text-3xl text-right pr-[40px]">
              {t("common.currencySymbol")}
              {res.amountBooking ? fomatPrice(res.amountBooking) : 0}
            </span>
            <span className="text-right pr-[30px]">
              （{t("admin.home.fee_consumption")}
              {t("common.currencySymbol")}
              {res.feeBooking ? fomatPrice(res.feeBooking) : 0} ）
            </span>
          </div>
          <div className="min-h-[132px] font-bold p-3 flex flex-col border border-solid border-black rounded-lg">
            <span className="mb-7 ml-3">
              {t("operator.manager.rush_sales")}
            </span>
            <span className="text-3xl text-right pr-[40px]">
              {t("common.currencySymbol")}
              {res.amountSale ? fomatPrice(res.amountSale) : 0}
            </span>
            <span className="text-right pr-[30px]">
              （{t("admin.home.fee_consumption")}
              {t("common.currencySymbol")}
              {res.feeSale ? fomatPrice(res.feeSale) : 0} ）
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 mb-10">
          <div className="min-h-[132px] font-bold p-3 flex flex-col border border-solid border-black rounded-lg">
            <span className="mb-7 ml-3">
              {t("operator.manager.number_of_passengers")}
            </span>
            <span className="text-3xl text-center">
              {res.totalUse ? res.totalUse : 0}
            </span>
          </div>
          <div className="min-h-[132px] font-bold p-3 flex flex-col border border-solid border-black rounded-lg">
            <span className="mb-7 ml-3">
              {t("operator.manager.number_of_reservations")}
            </span>
            <span className="text-3xl text-center">
              {res.countBooking ? res.countBooking : 0}
            </span>
          </div>
          <div className="min-h-[132px] font-bold p-3 flex flex-col border border-solid border-black rounded-lg">
            <span className="mb-7 ml-3">
              {t("operator.manager.number_of_dives")}
            </span>
            <span className="text-3xl text-center">
              {res.countSale ? res.countSale : 0}
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 mb-10">
          <div className="min-h-[132px] font-bold p-3 flex flex-col border border-solid border-black rounded-lg">
            <span className="mb-7 ml-3">
              {t("operator.manager.number_of_cancellations")}
            </span>
            <span className="text-3xl text-center">
              {res.countCancel ? res.countCancel : 0}
            </span>
          </div>
        </div>
      </MainContent>
      {/* <Footer /> */}
    </LayoutDefault>
  );
}
