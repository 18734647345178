import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { DataGrid } from "@mui/x-data-grid";
import DatePicker from "react-datepicker";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ja from "date-fns/locale/ja";
import { trimSpaceFirstCharacter } from "app/utils";
import SelectMenu from "app/components/SelectMenu";
import SelectCommon from "../../components/SelectCommon";
import useCoreComponentList from "./useCoreComponentList";
import {
  STATUS_PAY,
  fomatPrice,
  PER_PAGE,
  SORT,
  FEES_PAY,
} from "../../../constant";
import { ICTL_API_ENDPOINT } from "../../../services/constant";
import SuccessDialog from "../../../components/SuccessDialog";
import {
  SortedAscendingIcon,
  SortedDescendingIcon,
  UnsortIcon,
} from "../../../components/DatagridIcon";

export default function List() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const listStatus = [
    { value: STATUS_PAY.UNPAID, text: t("admin.pay.list.not_payment") },
    { value: STATUS_PAY.PAID, text: t("admin.pay.list.paid_1") },
    {
      value: STATUS_PAY.CONFIRMING,
      text: t("admin.pay.list.comfirmPricePaid_1"),
    },
  ];
  // eslint-disable-next-line no-unused-vars
  const [
    items,
    filters,
    total,
    pricePaid,
    exportListInvoice,
    updateStatus,
    openSuccess,
    setOpenSuccess,
    setFilters,
  ] = useCoreComponentList({ model: ICTL_API_ENDPOINT.GET_LIST_PAY });

  const [keySearch, setKeySearch] = useState(filters?.search || "");
  const options = [
    {
      text: t("common.btn.download_csv"),
      value: "download_csv",
    },
  ];

  const [optionSelected, setOptionSelected] = useState([options[0]?.value]);
  const [sortModel, setSortModel] = useState([]);
  const [filed, setField] = useState("");

  const handleExport = () => {
    setOptionSelected([options[0]?.value]);
    exportListInvoice();
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setFilters({ ...filters, search: keySearch.trim() });
    }, 300);
    return () => clearTimeout(timeOut);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keySearch]);

  const handleSortChange = (model) => {
    if (model.length === 0) {
      setSortModel([
        {
          field: filed,
          sort: SORT.ASC,
        },
      ]);
    } else if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
      setSortModel(model);
      setField(model[0].field);
    }
  };

  const onPaginationModelChange = (model) => {
    const newPage = model?.page;
    setFilters({ ...filters, page: newPage + 1 });
  };

  const columns = [
    {
      field: "name",
      headerName: t("admin.pay.list.business_name"),
      headerClassName: "custom",
      sortable: true,
      valueGetter: (row) => {
        return trimSpaceFirstCharacter(row.value);
      },
      minWidth: 450,
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            role="presentation"
            onClick={() =>
              navigate(`/admin/pay/${params.row.id}/${params.row.category}`)
            }
            className="flex items-center truncate cursor-pointer"
          >
            <div className="truncate">{params.row.name || ""}</div>
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: t("admin.pay.list.status"),
      headerClassName: "custom",
      width: 200,
      renderCell: (params) => {
        return (
          <div
            role="presentation"
            className="flex items-center truncate"
            onClick={() =>
              navigate(`/admin/pay/${params.row.id}/${params.row.category}`)
            }
          >
            {params.row.status === STATUS_PAY.UNPAID &&
              t("admin.pay.list.not_payment")}
            {params.row.status === STATUS_PAY.PAID &&
              t("admin.pay.list.paid_1")}
            {params.row.status === STATUS_PAY.CONFIRMING &&
              t("admin.pay.list.comfirmPricePaid_1")}
          </div>
        );
      },
    },
    {
      field: "amount",
      headerName: t("admin.pay.list.invoice_amount"),
      headerClassName: "custom",
      width: 350,
      renderCell: (params) => {
        return (
          <div
            role="presentation"
            onClick={() =>
              navigate(`/admin/pay/${params.row.id}/${params.row.category}`)
            }
          >
            <p className="text-right">
              {t("admin.pay.list.price")}
              {params.row.amount ? fomatPrice(params.row.amount) : 0}
            </p>
            <p>
              ({t("admin.pay.consumptionTax")}
              {params.row.tax ? fomatPrice(params.row.amount_tax) : 0})
            </p>
          </div>
        );
      },
    },

    {
      field: "category",
      headerName: t("admin.pay.list.payment_type"),
      headerClassName: "custom",
      width: 300,
      renderCell: (params) => {
        return (
          <div
            role="presentation"
            className="flex items-center truncate"
            onClick={() =>
              navigate(`/admin/pay/${params.row.id}/${params.row.category}`)
            }
          >
            {Number(params?.row?.category) === FEES_PAY.INITIAL_COST &&
              t("admin.pay.list.initialCost")}
            {Number(params?.row?.category) === FEES_PAY.MONTHLY_PLAN &&
              t("admin.pay.list.monthlyPlan")}
            {Number(params?.row?.category) === FEES_PAY.REWARDS &&
              t("admin.pay.list.influencerRewards")}
          </div>
        );
      },
    },
    {
      field: "invoice_number",
      headerName: t("admin.pay.list.invoice_number"),
      headerClassName: "custom",
      sortable: false,
      width: 300,
      renderCell: (params) => {
        return (
          <div
            role="presentation"
            className="flex items-center truncate"
            onClick={() =>
              navigate(`/admin/pay/${params.row.id}/${params.row.category}`)
            }
          >
            {params?.row?.invoice_number}
          </div>
        );
      },
    },
    {
      field: "id",
      headerName: "",
      headerClassName: "custom",
      width: 200,
      sortable: false,
      renderCell: (params) => {
        return (
          <div role="presentation" className="flex items-center truncate">
            {params.row.status === STATUS_PAY.UNPAID && (
              <button
                type="button"
                className="hover:underlinecolor text-[#fff] colorButtonCommon rounded-lg text-sm px-4 py-2 mr-2 "
                onClick={() => updateStatus(params.row.id, params.row.category)}
              >
                {t("admin.pay.list.updateToPaid")}
              </button>
            )}
            {params.row.status === STATUS_PAY.CONFIRMING && (
              <button
                type="button"
                className="hover:underlinecolor text-[#fff] colorButtonCommon rounded-lg text-sm px-4 py-2 mr-2 "
                onClick={() => updateStatus(params.row.id, params.row.category)}
              >
                {t("admin.pay.list.updateToPaid")}
              </button>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className="flex-1">
      <p className="text-3xl font-bold mb-1">
        {t("admin.pay.list.select_month")}
      </p>
      <div className="flex flex-col mb-10">
        <DatePicker
          showMonthYearPicker
          selected={filters.date}
          className="font-bold bg-secondary-400 border divide-slate-200 h-[35px] p-5 rounded"
          dateFormat={t("common.date_format.yyyy_MM")}
          locale={ja}
          onChange={(date) => setFilters({ ...filters, date })}
        />
      </div>

      <div className="mb-10 ">
        <p className="font-bold text-3xl">
          {t("admin.pay.list.reservation_request")}
        </p>
        <div className="sm:pr-[0]">
          <h2 className="font-semibold text-2xl ">
            {t("admin.pay.list.price")}{" "}
            {pricePaid.total_money ? fomatPrice(pricePaid.total_money) : 0}
          </h2>
          <p className="font-bold text-xs ml-[10px]">
            ({t("admin.pay.consumptionTax")} {fomatPrice(pricePaid.total_tax)})
          </p>
        </div>
      </div>

      <div className="mb-10">
        <div className="flex gap-8 flex-wrap items-center">
          <div className="flex flex-col">
            <h2 className="font-bold text-[14px]">
              {t("admin.pay.list.unPricePaid")}{" "}
            </h2>
            <div className="text-right">
              <span className="font-bold ">
                {" "}
                {t("admin.pay.list.price")}
                {pricePaid.total_money_status_unpaid
                  ? fomatPrice(pricePaid.total_money_status_unpaid)
                  : 0}
              </span>
            </div>
          </div>
          <div className="flex flex-col">
            <h2 className="font-bold text-[14px]">
              {t("admin.pay.list.pricePaid")}
            </h2>
            <div className="text-right">
              <span className="font-bold">
                {" "}
                {t("admin.pay.list.price")}
                {pricePaid.total_money_status_confirming
                  ? fomatPrice(pricePaid.total_money_status_confirming)
                  : 0}
              </span>
            </div>
          </div>
          <div className="flex flex-col">
            <h2 className="font-bold text-[14px]">
              {t("admin.pay.list.comfirmPricePaid")}{" "}
            </h2>
            <div className="text-right">
              <span className="font-bold">
                {" "}
                {t("admin.pay.list.price")}
                {pricePaid.total_money_status_paid
                  ? fomatPrice(pricePaid.total_money_status_paid)
                  : 0}
              </span>
            </div>
          </div>
        </div>
      </div>

      <h2 className="font-bold text-3xl mb-5">
        {t("admin.pay.list.numberComfirmComfirmPaid", { value: total })}
      </h2>

      <div className="mb-6">
        <div className="flex items-center gap-4 justify-between flex-wrap">
          <div className="flex items-center gap-4 flex-wrap md:flex-1">
            <FormControl
              size="small"
              className="max-w-[350px] font-bold w-full"
            >
              <OutlinedInput
                sx={{
                  borderRadius: 40,
                  height: 38,
                }}
                id="outlined-adornment-weight"
                className="font-bold"
                value={keySearch}
                onChange={(e) => {
                  setKeySearch(e.target.value);
                }}
                placeholder={t("admin.pay.list.labelSearch")}
                aria-describedby="outlined-weight-helper-text"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchOutlinedIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
            <SelectMenu
              optionSelected={filters?.status}
              optionList={listStatus}
              onSubmit={(status) => {
                setFilters({
                  ...filters,
                  status: status.length ? status : null,
                });
              }}
              label={t("admin.pay.list.status")}
            />
          </div>

          <FormControl size="small" className="min-w-[160px] rounded-lg">
            <SelectCommon
              lstOption={options}
              placeholder={t("common.btn.export")}
              handleChange={handleExport}
              selectClass="w-40 h-[35px] sm:mb-0"
              optionSelected={optionSelected}
            />
          </FormControl>
        </div>
      </div>

      <div className="w-full">
        <DataGrid
          autoHeight
          getRowClassName={() => "cursor-pointer"}
          rows={items}
          columns={columns}
          rowCount={total ? Number(total) : 0}
          paginationMode="server"
          sortModel={sortModel}
          onSortModelChange={(model) => handleSortChange(model)}
          disableColumnMenu
          slots={{
            columnUnsortedIcon: UnsortIcon,
            columnSortedDescendingIcon: SortedDescendingIcon,
            columnSortedAscendingIcon: SortedAscendingIcon,
          }}
          getRowId={(row) => row?.invoice_number}
          paginationModel={{
            pageSize: PER_PAGE,
            page: filters.page - 1,
          }}
          pageSizeOptions={[PER_PAGE]}
          onPaginationModelChange={(model) => {
            onPaginationModelChange(model);
          }}
          localeText={{
            noResultsOverlayLabel: t("common.message.no_data_display"),
            noRowsLabel: t("common.message.no_data_display"),
          }}
        />
      </div>

      <SuccessDialog
        message={t("common.message.update_success")}
        open={openSuccess}
        onClose={() => setOpenSuccess(false)}
        onOK={() => setOpenSuccess(false)}
      />
    </div>
  );
}
