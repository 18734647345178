import React, { useContext } from "react";
import FormItem, { TYPE_INPUT } from "app/operator/components/FormItem";
import {
  editOperatorInfoSchema,
  MAX_LENGTH_100,
  MAX_LENGTH_POSTCODE,
} from "app/utils/schema/operator";
import { COUNTRY_LIST, postCodeRegexInput } from "app/constant";
import EditableGroup, {
  EDIT_BTN_POSITION,
} from "app/operator/staff/pages/BookingDetail/EditableGroup";
import { useTranslation } from "react-i18next";
import { OperatorInfoContext } from "app/operator/pages/Operator/Info";
import { ICTL_API_ENDPOINT } from 'app/services/constant';

function CommonInfo() {
  const { t } = useTranslation();
  const { formValues, setFormValues, onSubmitEdit, groupId } =
    useContext(OperatorInfoContext);

  const onSubmitValue = (e) => {
    onSubmitEdit(ICTL_API_ENDPOINT.UPDATE_BUSINESS_PERSON(groupId), e);
    setFormValues(e);
  };

  const addressRegex = /^[0-9.-\s]*(?:,[0-9.-\s]*)?$/;

  const renderEditFormName = ({ values }) => {
    return (
      <FormItem
        required
        showCharCount
        maxLength={MAX_LENGTH_100}
        defaultLength={values?.service_provider_name?.length}
        name="service_provider_name"
        label={t("operator.operatorInfo.service_name")}
        placeholder={t("common.message.no_input_yet")}
        // space
        labelClassName="font-semibold mb-2"
        inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        containerClassName="mb-[16px]"
      />
    );
  };

  const renderEditFormAddress = ({ values }) => {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-[16px]">
        <FormItem
          name="post_code"
          label={t("operator.accountSetting.personal_info.postcode")}
          labelClassName="font-semibold mb-2"
          description=""
          placeholder={t("common.message.no_input_yet")}
          inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
          containerClassName="mb-[16px]"
          regex={postCodeRegexInput}
          showCharCount
          typeInput={TYPE_INPUT.TEXT}
          maxLength={MAX_LENGTH_POSTCODE}
          defaultValue={values?.post_code}
          required
          defaultLength={values?.post_code?.length}
        />
        <FormItem
          required
          name="lat_long"
          showCharCount
          maxLength={256}
          typeInput={TYPE_INPUT.TEXT}
          regex={addressRegex}
          defaultLength={values?.lat_long?.length}
          label={t("operator.operatorInfo.long_lat")}
          placeholder={t("common.message.no_input_yet")}
          // space
          labelClassName="font-semibold mb-2"
          inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
          containerClassName="mb-[16px]"
        />
        <FormItem
          name="address_street"
          label={t(
            "operator.accountSetting.personal_info.state_provine_country"
          )}
          labelClassName="font-semibold mb-2"
          description=""
          placeholder={t("common.message.no_input_yet")}
          inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
          containerClassName="mb-[16px]"
          // char count
          showCharCount
          maxLength={256}
          typeInput={TYPE_INPUT.TEXT}
          required
          defaultLength={values?.address_street?.length}
        />
        <FormItem
          name="address_city"
          label={t("operator.accountSetting.personal_info.municipaliites")}
          labelClassName="font-semibold mb-2"
          description=""
          placeholder={t("common.message.no_input_yet")}
          inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
          containerClassName="mb-[16px]"
          // char count
          showCharCount
          maxLength={256}
          typeInput={TYPE_INPUT.TEXT}
          required
          defaultLength={values?.address_city?.length}
        />
        <FormItem
          name="address_address"
          label={t("operator.accountSetting.personal_info.address")}
          labelClassName="font-semibold mb-2"
          description=""
          placeholder={t("common.message.no_input_yet")}
          inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
          containerClassName="mb-[16px]"
          // char count
          showCharCount
          maxLength={256}
          typeInput={TYPE_INPUT.TEXT}
          required
          defaultLength={values?.address_address?.length}
        />
        <FormItem
          name="apartment_name"
          label={t("operator.accountSetting.personal_info.apartment_name")}
          labelClassName="font-semibold mb-2"
          description=""
          placeholder={t("common.message.no_input_yet")}
          inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
          containerClassName="mb-[16px]"
          // char count
          showCharCount
          maxLength={256}
          typeInput={TYPE_INPUT.TEXT}
          defaultLength={values?.apartment_name?.length}
        />
        <FormItem
          name="address_country"
          label={t("operator.accountSetting.personal_info.country_region")}
          labelClassName="font-semibold mb-2"
          description=""
          placeholder={t("common.message.no_input_yet")}
          inputClassName="w-full text-[#dddddd]"
          containerClassName="mb-[16px]"
          typeInput={TYPE_INPUT.SELECT}
          selectClassName="w-full"
          required
          componentProps={{
            defaultValue: values?.address_country,
            optionsList: [
              {
                optionName: t("common.japan"),
                value: COUNTRY_LIST.JA,
              },
            ],
          }}
        />
      </div>
    );
  };

  const renderEditFormPhoneNumber = () => {
    return (
      <FormItem
        required
        name="phone_number"
        label={t("operator.operatorInfo.phone")}
        labelClassName="font-semibold mb-2"
        placeholder={t("common.message.no_input_yet")}
        inputClassName="border-border border rounded-[8px] py-2 px-4 overflow-hidden w-full"
        containerClassName="mb-[16px]"
        typeInput={TYPE_INPUT.PHONE}
      />
    );
  };

  return (
    <>
      <p className="text-[18px] font-bold">
        {t("operator.operatorInfo.basic_info")}
      </p>
      <EditableGroup
        required
        editForm={renderEditFormName}
        displayValues={
          <div className="pl-[8px] text-gray font-semibold word-break">
            <div>{formValues?.service_provider_name}</div>
          </div>
        }
        validationSchema={editOperatorInfoSchema(t)?.service_provider_name}
        // space
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item text-[14px]"
        label={t("operator.operatorInfo.service_name")}
      />
      <EditableGroup
        required
        editForm={renderEditFormAddress}
        displayValues={
          <div className="pl-[8px] text-gray font-semibold word-break">
            {(() => {
              const postCode = formValues.post_code ? formValues.post_code : "";
              const street = formValues.address_street
                ? formValues.address_street
                : "";
              const city = formValues.address_city
                ? formValues.address_city
                : "";
              const address = formValues.address_address
                ? formValues.address_address
                : "";
              const addressCountry = formValues.address_country
                ? formValues.address_country
                : "";
              const apartmentName = formValues.apartment_name
                ? formValues.apartment_name
                : "";

              const path = [
                postCode,
                street,
                city,
                address,
                apartmentName,
              ].join(" ");
              const country = [t("common.japan"), addressCountry].join("-");

              const fullAddress = [path, country].join(",");

              return fullAddress;
            })()}
          </div>
        }
        validationSchema={editOperatorInfoSchema(t)?.address}
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item text-[14px]"
        label={t("operator.operatorInfo.address")}
      />
      <EditableGroup
        required
        editForm={renderEditFormPhoneNumber}
        displayValues={
          <div className="pl-[8px] text-gray font-semibold word-break">
            {formValues?.phone_number ||
              t("common.message.no_input_yet")}
          </div>
        }
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        validationSchema={editOperatorInfoSchema(t)?.phone_number}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item text-[14px]"
        label={t("operator.operatorInfo.phone")}
      />
    </>
  );
}

export default CommonInfo;
