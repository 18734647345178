import React from 'react';

export function UnsortIcon() {
  return (
    <div className="w-5 h-5 flex items-center justify-center">
      <i className="text-[12px] text-[#DDD] fa-solid fa-sort" />
    </div>
  );
}

export function SortedDescendingIcon() {
  return (
    <div className="w-5 h-5 flex items-center justify-center">
      <i className="text-[12px] text-[#DDD] fa-solid fa-sort-down" />
    </div>
  );
}

export function SortedAscendingIcon() {
  return (
    <div className="w-5 h-5 flex items-center justify-center">
      <i className="text-[12px] text-[#DDD] fa-solid fa-sort-up" />
    </div>
  );
}