import React from "react";
import mapPlaceIcon from "app/assets/images/maps-and-flags.png";
import { useTranslation } from "react-i18next";

function ConfirmGeoCode({ result }) {
  const { t } = useTranslation();
  const { placeData } = result;
  const { address, city, country, postcode, state, street } = placeData;
  const fullAdress = [postcode, state, city, street, address];

  return (
    <>
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-[100px] -mt-10">
        <div className="a py-3 px-4 rounded-[24px] text-[14px] font-bold max-w-[350px] truncate bg-white">
          {`${fullAdress.filter((item) => item).join(" ")}, ${country}`}
        </div>
      </div>
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-full -mt-10">
        <div className="w-8 h-8 mx-auto">
          <img src={mapPlaceIcon} alt="" className="w-full h-full" />
        </div>
      </div>
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 translate-y-[24px] -mt-10">
        <div className="py-3 px-4 rounded-[24px] text-[14px] font-bold whitespace-nowrap text-white bg-black">
          {t("operator.host.common.drag_to_change")}
        </div>
      </div>
    </>
  );
}

export default ConfirmGeoCode;
