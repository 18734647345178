/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, CircularProgress, Pagination, Tab } from "@mui/material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { formatNumber, generateSNSIcon, handleMessage } from "app/utils/index";
import { EResponseStatus, GENDER } from "app/constant";
import Rating from "@mui/material/Rating";
import { ProjectDetailContext } from "app/operator/pages/AdvertisementManage/AdvertisementDetail";
import { useParams, useSearchParams } from "react-router-dom";
import ChatService from "app/services/chat/api/ChatService";
import { useTranslation } from "react-i18next";
import AdProjectService from "app/services/api/operatorService/AdProjectService";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch } from "react-redux";
import AccountInfo from "../AccountInfo";
import SearchInfluencer from "./SearchInfluencer";
import ImageItem from "../../../../../components/ImageItem";
import { setProjectBatch } from "../../../../../redux/projectBatch";

const male = require("app/assets/images/icon-male.png");
const female = require("app/assets/images/icon-female.png");
const other = require("app/assets/images/icon-other.png");

const TAB_VALUE = {
  TRANSACTION_TAB: "TRANSACTION_TAB",
  REGISTER_TAB: "REGISTER_TAB",
};

export const STATUS = {
  WAITING_FOR_APPROVAL_REPORT_5: "WAITING_FOR_APPROVAL_REPORT_5",
  REQUEST_CANCEL_TRANSACTION_6: "REQUEST_CANCEL_TRANSACTION_6",
  MARK_ACTION_REQUEST_7: "MARK_ACTION_REQUEST_7",
  ALL_8: "ALL_8",
  IN_PROCESS_8: "IN_PROGRESS_AND_REJECT_REPORT",
  UNREAD_8: "UNREAD_8",
  APPROVED_REPORT_8: "APPROVED_REPORT_8",
};

function TabGroup() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { formValues } = useContext(ProjectDetailContext);
  const params = useParams();
  const { projectId } = params;
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [errorMess, setErrorMess] = useState("");
  const [isOpenChat, setIsOpenChat] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [countMessage, setCountMessage] = useState({});
  const [tabValue, setTabValue] = useState(TAB_VALUE.TRANSACTION_TAB);
  const [status, setStatus] = useState(STATUS.ALL_8);
  const [lstChatRoom, setLstChatRoom] = useState([]);
  const [filterList, setFilterList] = useState([
    {
      id: 1,
      label: "operator.advertisement.social.all",
      status: STATUS.ALL_8,
      quantity: 0,
    },
    {
      id: 2,
      label: "operator.advertisement.social.in_progress",
      status: STATUS.IN_PROCESS_8,
      quantity: 0,
    },
    {
      id: 3,
      label: "operator.advertisement.social.unread_mess",
      status: STATUS.UNREAD_8,
      quantity: 0,
    },
    {
      id: 4,
      label: "operator.advertisement.social.approve_report",
      status: STATUS.APPROVED_REPORT_8,
      quantity: 0,
    },
  ]);
  const [chatRoom, setChatRoom] = useState({});

  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const chatRoomIdQuery = searchParams.get("chat_room_id");
  const influencerIdQuery = searchParams.get("influencer_id");

  useEffect(() => {
    if (chatRoomIdQuery && influencerIdQuery) {
      setIsOpenChat(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatRoomIdQuery]);

  const countChatRoom = useCallback(() => {
    AdProjectService.getCountNumberList(projectId).then((res) => {
      if (res.status === EResponseStatus.SUCCESS) {
        const { data } = res.data;
        const lstTemp = [...filterList];
        lstTemp[0].quantity = data.count_all;
        lstTemp[1].quantity = data.count_in_progress_and_reject_report;
        lstTemp[2].quantity = data.count_unread_message;
        lstTemp[3].quantity = data.count_completion_report_approved;
        setCountMessage(data);
        setFilterList(lstTemp);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    countChatRoom();
  }, [status, countChatRoom]);

  const countUnreadAndMarkAction = () => {
    ChatService.countUnreadAndMarkAction().then((response) => {
      if (response.status === EResponseStatus.SUCCESS) {
        const { count } = response.data;
        dispatch(setProjectBatch(count));
      }
    });
  };

  const getChatRoomByAdmin = useCallback(() => {
    setErrorMess("");
    setIsLoading(true);
    ChatService.getChatRoomByAdmin(projectId, page, 20, status)
      .then((res) => {
        if (res.status === EResponseStatus.SUCCESS) {
          if (res?.data?.row) {
            setLstChatRoom(res?.data?.row);
            setTotalPages(res?.data?.totalPages);
          } else {
            setLstChatRoom([]);
          }
        }
      })
      .catch(() => {
        setErrorMess(t("common.message.cannot_display_data"));
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, status]);

  useEffect(() => {
    getChatRoomByAdmin();
  }, [getChatRoomByAdmin]);

  const handleOnChangeTab = (event, newVal) => {
    setTabValue(newVal);
    if (newVal === TAB_VALUE.TRANSACTION_TAB) {
      getChatRoomByAdmin();
      countChatRoom();
    }
  };

  // update lastest message on socket
  const updateLastMessage = (newMess) => {
    lstChatRoom.forEach((item) => {
      if (item.chat_room_id === newMess.chat_room_id) {
        item.message = newMess.message;
      }
    });
  };

  return (
    <TabContext value={tabValue}>
      <div className="pt-[16px] md:px-[48px] advertisement-detail__tab-label-group">
        <TabList onChange={handleOnChangeTab}>
          <Tab
            label={t("operator.advertisement.social.transaction_tab", {
              value: countMessage.count_chat_room || 0,
            })}
            value={TAB_VALUE.TRANSACTION_TAB}
          />
          <Tab
            label={t("operator.advertisement.social.influencer_tab")}
            value={TAB_VALUE.REGISTER_TAB}
          />
        </TabList>
      </div>
      <TabPanel
        value={TAB_VALUE.TRANSACTION_TAB}
        className={`${
          tabValue === TAB_VALUE.TRANSACTION_TAB
            ? "flex-1 flex flex-col mr-2"
            : ""
        }`}
      >
        <div className="md:px-[24px]">
          <div className="my-[8px] font-semibold pt-[8px] border-t border-border">
            {t("operator.advertisement.social.transaction_descrip")}
          </div>
          <div className="flex flex-wrap my-[16px] gap-x-[14px] gap-y-[8px]">
            <Button
              onClick={() => setStatus(STATUS.WAITING_FOR_APPROVAL_REPORT_5)}
              variant="outlined"
              className={`py-[8px] px-[14px] mr-2 text-blue_1 border border-blue_1 rounded-[8px] font-semibold ${
                status === STATUS.WAITING_FOR_APPROVAL_REPORT_5
                  ? "bg-[#0057b7] text-white"
                  : ""
              }`}
            >
              <i className="fa-regular fa-file" />
              <p className="pl-2">
                {t(
                  "operator.advertisement.social.count_wait_approved_complete",
                  {
                    value:
                      countMessage.count_wait_approval_completed_report || 0,
                  }
                )}
              </p>
            </Button>
            <Button
              onClick={() => setStatus(STATUS.REQUEST_CANCEL_TRANSACTION_6)}
              variant="outlined"
              className={`py-[8px] px-[14px] mr-2 text-blue_1 border border-blue_1 rounded-[8px] font-semibold ${
                status === STATUS.REQUEST_CANCEL_TRANSACTION_6
                  ? "bg-[#0057b7] text-white"
                  : ""
              }`}
            >
              <i className="fa-solid fa-xmark" />
              <p className="pl-2">
                {t("operator.advertisement.social.count_cancel_transaction", {
                  value: countMessage.count_request_cancel_transaction || 0,
                })}
              </p>
            </Button>
            <Button
              onClick={() => setStatus(STATUS.MARK_ACTION_REQUEST_7)}
              variant="outlined"
              className={`py-[8px] px-[14px] text-blue_1 border border-blue_1 rounded-[8px] font-semibold ${
                status === STATUS.MARK_ACTION_REQUEST_7
                  ? "bg-[#0057b7] text-white"
                  : ""
              }`}
            >
              <i className="fa-solid fa-check" />
              <p className="pl-2">
                {t("operator.advertisement.social.count_action_required", {
                  value: countMessage.count_action_required_mark || 0,
                })}
              </p>
            </Button>
          </div>
          <div className="flex flex-wrap gap-x-[14px] gap-y-[8px] justify-between my-[8px]">
            <div className="flex flex-wrap gap-x-[14px] gap-y-[8px]">
              {filterList?.map((item) => (
                <Button
                  variant="outlined"
                  onClick={() => setStatus(item.status)}
                  key={item.id}
                  className={`!py-[4px] !px-[14px] !border !border-border !rounded-[8px] !font-semibold !text-black !text-[12px] ${
                    status === item.status ? "bg-[#D1D1D1]" : ""
                  }`}
                >
                  {t(item?.label, { value: item?.quantity })}
                </Button>
              ))}
            </div>
          </div>
        </div>
        <div className="shadow-[0_4px_2px_rgba(0,0,0,0.2)] w-full h-[4px] rounded-[8px] mb-5" />
        <div className="flex flex-1 flex-col">
          {(() => {
            if (isLoading) {
              return (
                <div className="flex justify-center flex-1 items-center">
                  <CircularProgress />
                </div>
              );
            }
            if (errorMess) {
              return (
                <p className="text-[20px] font-[600] text-center">
                  {errorMess}
                </p>
              );
            }
            if (!lstChatRoom.length) {
              return (
                <p className="text-[20px] font-[600] text-center">
                  {t("common.message.no_data_display")}
                </p>
              );
            }
            return (
              <div className="flex-1 flex flex-col">
                <div className="md:mx-[24px] mt-[20px] shadow-[0_0_4px_rgba(0,0,0,0.2)] px-[12px] md:px-[45px] py-[14px] flex-1">
                  {lstChatRoom?.map((item, index) => (
                    <div key={index}>
                      <button
                        type="button"
                        onClick={() => {
                          setIsOpenChat(!!item?.chat_room_id);
                          setChatRoom(item);
                        }}
                        className="flex max-w-[1100px] mx-auto py-[14px] gap-4 md:gap-[30px] w-full items-center"
                      >
                        <div className="h-[40px] w-[40px] md:h-[64px] md:w-[64px] flex-shrink-0 relative">
                          <ImageItem
                            lazy="true"
                            loadavatar="true"
                            avatar="true"
                            containerclassname="!rounded-full"
                            role="presentation"
                            imagesrc={item.avatar_url}
                            alt=""
                            imgclassname="w-full h-full object-cover rounded-full border-2 border-green"
                          />
                          <i className="absolute text-[14px] bg-white rounded-full text-green bottom-0 right-0 fa-solid fa-circle-check" />
                        </div>
                        <div className="flex-1 flex flex-wrap items-center justify-between truncate">
                          <div className="flex flex-col max-w-[600px] truncate">
                            <div
                              className={`flex flex-col sm:flex-row mb-2 gap-2 ${
                                item.influencer_social_media.length
                                  ? "items-start"
                                  : "items-center"
                              }`}
                            >
                              <div className="flex items-center mr-2 truncate w-full">
                                <div
                                  className={`flex-shrink-0 ${
                                    item.gender !== GENDER.OTHER
                                      ? "mr-2"
                                      : "mr-1"
                                  }`}
                                >
                                  {(() => {
                                    if (item.gender === GENDER.MALE) {
                                      return (
                                        <img
                                          src={male}
                                          alt="Male"
                                          className="w-[10px] h-[20px]"
                                        />
                                      );
                                    }
                                    if (item.gender === GENDER.FEMALE) {
                                      return (
                                        <img
                                          src={female}
                                          alt="Female"
                                          className="w-[10px] h-[20px]"
                                        />
                                      );
                                    }
                                    if (item.gender === GENDER.OTHER) {
                                      return (
                                        <img
                                          src={other}
                                          alt="Other"
                                          className="w-[20px] h-[28px]"
                                        />
                                      );
                                    }
                                    return null;
                                  })()}
                                </div>
                                <Tooltip title={item?.nickname}>
                                  <p className="font-bold text-[14px] sm:w-[150px] truncate sm:text-left">
                                    {item?.nickname}
                                  </p>
                                </Tooltip>
                              </div>
                              <div className="flex flex-col gap-1">
                                {item?.influencer_social_media.map(
                                  (social, i) => {
                                    return (
                                      <div
                                        key={i}
                                        className="mr-[8px] text-[12px] flex items-center"
                                      >
                                        {generateSNSIcon(social?.social_media)}
                                        {formatNumber(
                                          social?.subscribers_number
                                        )}
                                        {t("common.followers")}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                              <Rating
                                size="small"
                                name="read-only"
                                precision={0.1}
                                value={item.rating}
                                readOnly
                                className="mb-1"
                              />
                            </div>

                            {item?.message ? (
                              <div className="flex items-start mb-2">
                                <div>
                                  <i className="fa-solid fa-share mr-[8px] text-[#444444]" />
                                </div>
                                <Tooltip title={item?.message}>
                                  <p className="text-left word-break line-clamp-5">
                                    {item?.message}
                                  </p>
                                </Tooltip>
                              </div>
                            ) : null}
                          </div>

                          <div className="text-[14px] ml-4 lg:ml-0 text-left w-full lg:w-auto whitespace-normal">
                            {handleMessage(item)}
                          </div>
                        </div>
                      </button>
                      <div className="border border-border" />
                    </div>
                  ))}
                </div>
                <div className="flex justify-center pt-10 items-end">
                  <Pagination
                    page={page}
                    count={totalPages}
                    onChange={(event, selectedPage) => setPage(selectedPage)}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            );
          })()}
        </div>
        <SwipeableDrawer
          anchor="right"
          open={isOpenChat}
          onOpen={() => setIsOpenChat(true)}
          onClose={() => {
            setIsOpenChat(false);
            getChatRoomByAdmin();
            countChatRoom();
            countUnreadAndMarkAction();
          }}
          classes={{
            paper: "!w-[960px] !max-w-full",
          }}
        >
          <AccountInfo
            data={chatRoom}
            onCloseDrawer={() => {
              setIsOpenChat(false);
              getChatRoomByAdmin();
              countChatRoom();
              countUnreadAndMarkAction();
            }}
            updateLastMessage={updateLastMessage}
            projectId={projectId}
            media={formValues.ad_media}
            tabStatus={status}
            chatRoomIdQuery={chatRoomIdQuery}
            influencerIdQuery={influencerIdQuery}
          />
        </SwipeableDrawer>
      </TabPanel>
      <TabPanel value={TAB_VALUE.REGISTER_TAB}>
        <SearchInfluencer />
      </TabPanel>
    </TabContext>
  );
}

export default TabGroup;
