/* eslint-disable class-methods-use-this */
import CommonService from "../CommonService";

const baseUrl = "cms/influencer";

class AdProjectService {
  getLstInfluencerByProject(projectId, params) {
    return CommonService.getModelList(
      `${baseUrl}/get-by-project/${projectId}`,
      params
    );
  }

  getCountNumberList(projectId) {
    return CommonService.getModelList(
      `${baseUrl}/get-count-in-influencer-list/${projectId}`
    );
  }

  getCountNumberListForV(projectId) {
    return CommonService.getModelList(
      `${baseUrl}/get-count-in-influencer-list-for-v/${projectId}`
    );
  }

  sendSpecialOffer(projectId, userId, data) {
    return CommonService.postModel(
      `${baseUrl}/special-offer/${projectId}/${userId}`,
      data
    );
  }

  getInfluencerByProject(projectId, influencerId) {
    return CommonService.getModelList(
      `${baseUrl}/${projectId}/get-one/${influencerId}`
    );
  }

  changeStatusApplied(projectId, influencerId, data) {
    return CommonService.postModel(
      `${baseUrl}/change-status/${projectId}/${influencerId}`,
      data
    );
  }

  getInfluencerDeliverable(applicationId) {
    return CommonService.getModelList(
      `${baseUrl}/deliverable/${applicationId}`
    );
  }

  getInfluencerDetail(influencerId, params) {
    return CommonService.getModelList(
      `${baseUrl}/${influencerId}/project`,
      params
    );
  }
}

export default new AdProjectService();
