export default {
  detail: {
    leftTitle: "招募案件",
    image: "照片",
    adsGeneralInfo: "招募案件基本資訊",
    slide: "張照片",
    piece: "件",
    total_images: "照片{{value}}張照片",
    advertisement: "招募案件 {{value}}件",
    createAdvertisement: "創立招募案件",
    searchAdvertisement: "搜尋招募案件",
    searchPlatform: "招募案件、搜尋業者",
    translateRequest: "翻訳依頼",
    uploadDueDate: "發佈時間",
    receiveSNS: "請您投稿的社群網站",
    openTransactionDetail: "自我行銷確認",
    verifySNS: "{{sns}}已驗證",
    uploadedInfo: "交付品資訊",
    billingAmount: "報酬金額（不含稅）",
    uploadDate: "完成報告的日期和時間",
    uploadLink: "發布貼文連結",
    influencersComment: "網紅的評論",
    ad_project_name: "招募案件名",
    apply_quantity: "招募案件數量",
    applied_quantity: "核准的招募案件數量",
    completed_quantity: "已完成數量",
    project_title: "招募案件項目名稱（日語）",
    project_description: "招募案件說明",
    project_status: "廣告狀態",
    reward_type: "酬勞類型",
    reward: "酬勞",
    desired_condition: "酬勞條件",
    age_group: "網紅的要求年齡範圍",
    min_followers: "指定社群媒體的最少追蹤者/追蹤者數量",
    price: "¥",
    ad_method: "廣告方式",
    tagInclude: "貼文中應包含的標籤",
    targetUrl: "您希望廣告的頁面",
    influencer_gender: "網紅性別",
    adMedia: "指定社群媒體",
    targetUsername: "標籤帳戶",
    textInclude: "貼文內容",
    video: "動画",
    "image, video": "影片・照片",
    recuitmentDate: "發佈期間",
    already_applied:
      "目前網紅為「進行中」或「等待完成批准中」所以無法進行刪除。 ",
    chat_header: "與我們聊天",
    title_description: '(如果報酬類型為 "免費"，請在此註明免費提供的產品）',
    fixed_price: "固定報酬",
    free_supply: "免費提供",
    offer_price: "原價",
    offer_price_description:
      '(如果獎勵類型為 "免費提供"，請在此說明免費提供產品的原始報價）',
    collapse_en_title: "英文",
    collapse_en_label: "招募案件項目名稱（英文）",
    collapse_cn_title: "繁體中文",
    collapse_cn_label: "招募案件項目名稱（繁體中文）",
    en_descrip_label: "招募案件說明（英文）",
    cn_descrip_label: "招募案件說明（繁體中文）",
    en_condition_label: "酬勞條件（英文）",
    cn_condition_label: "酬勞條件（繁體中文）",
    en_include_label: "貼文內容",
    cn_include_label: "貼文內容（繁體中文）",
    publish_status: "發布狀態",
    cover_photo: "封面照片",
    cover_photo_title: "封面照片在清單裡決定了第一印象。",
    change_cover_photo: "更改照片",
    all_photos: "所有照片",
    upload_photo_drag: "拖放可重新排列照片。",
    upload_photo: "上傳照片",
    chat_notice: "※チャットが有効になっている募集案件のみ表示しています。",
    select_cover_image: "選擇封面照片",
    upload_other: "上傳照片",
    confirm_delete_image: "要刪除這張照片嗎？",
    confirm_delete_notice: "如果刪除，則無法恢復。",
  },
  account_info: {
    in_progress_mess: "您的申請已於 {{- date}} 核准。",
    in_progress_special_mess: "您的申請已於 {{- date}} 被網紅批准。",
    declined_mess: "您的申請於 {{- date}} 被拒絕。",
    completed_mess: "{{from}} {{- date}} 批准了您的完成報告。",
    reject_mess: "{{- date}} {{from}}拒絕了完成報告。",
    wait_recuitment_mess: "網紅於 {{- date}} 提交了申請。",
    special_offer_mess: "已在 {{- date}} 申請特別提案。",
    send_deliverable_mess: "{{- date}} 已收到網紅提交的完成報告。",
    cancel_transaction_mess_2: "在 {{- date}}，此交易已取消。",
    cancel_transaction_mess: "此交易已取消。",
    cancel_transaction: "取消交易",
    reason: "原因",
    approve_application: "批准網紅的申請",
    reject_application: "拒絕網紅的申請",
    approved_report: "批准完成報告",
    reject_report: "拒絕網紅的報告",
    wait_recuitment: "網紅已提交您的申請。",
    wait_finished: "已收到網紅提交的完成報告。",
    completed: "已完成。",
    download_zip: "一次全部下載",
  },
  dialog: {
    reject_application_mess_1: "您要拒絕批准申請嗎？",
    reject_application_mess_2: '如果您想拒絕申請，請點選 "提交" 按鈕。',
    reject_report_mess_1: "您是否希望拒絕批准完成報告？",
    reject_report_mess_2: '如果要拒絕，請輸入理由並點擊 "提交 "按鈕。',
    reject_report_placeholder: "輸入拒絕原因",
    approve_report_mess_1: "您是否希望批准完成？",
    approve_report_mess_2: "如果您批准，請選擇網紅的評分。",
    approve_report_mess_3: " 點一顆星給網紅評分",
    approve_report_placeholder: "請輸入您的評論",
  },
  social: {
    all: "全部 ({{value}}件)",
    in_progress: "進行中 ({{value}}件)",
    unread_mess: "未讀訊息 ({{value}}件)",
    approve_report: "已批准的完成報告 ({{value}}件)",
    ping_operator: "翻訳依頼 ({{value}}件)",
    transaction_tab: "交易名單 ({{value}}件)",
    influencer_tab: "搜尋網紅",
    transaction_descrip: "縮小網紅範圍以便做出回應",
    count_wait_approved_complete: "等待完成報告批准 ({{value}}件)",
    count_cancel_transaction: "取消交易請求 ({{value}}件)",
    count_action_required: "標記需要回复 ({{value}}件)",
    pr_completed: "PR投稿が完了しました",
    insta: "Instagramフォロワー数",
    tiktok: "Tiktokフォロワー数",
    twitter: "Twitterフォロワー数",
    facebook: "Facebookフォロワー数",
    youtube: "Youtubeチャンネル登録者数",
    self_intro: "自我介紹",
    send_offer: "案件申請按鈕",
    send_offer_fail:
      "オファー リクエストの送信に失敗しました。 もう一度やり直してください。",
    influencer_descrip: "縮小搜尋網紅範圍",
    influencer_placeholder: "搜尋網紅",
    influcener_name: "顯示名稱",
    rating: "評價",
  },
};
