import { Group, Roles } from "app/constant";

const idIstagram = process.env.REACT_APP_INSTA_APP_ID;
const idLine = process.env.REACT_APP_LINE_APP_ID;
const secretLine = process.env.REACT_APP_LINE_APP_SECRET;
const secretInstagram = process.env.REACT_APP_INSTA_APP_SECRET;
const idTwitter = process.env.REACT_APP_TWITTER_CLIENT_ID;
const keyTiktok = process.env.REACT_APP_TIKTOK_CLIENT_KEY;
const secretTiktok = process.env.REACT_APP_TIKTOK_CLIENT_SECRET;

export default function CommonLogin(user) {
  /* eslint prefer-destructuring: ["error", {AssignmentExpression: {array: true}}] */
  const groupRoles = user.group_roles;
  const group = groupRoles.group;
  const role = groupRoles.roles?.[0];
  if (group === Group.ITCL) {
    return "/admin";
  }

  if (
    (group === Group.FACILITY && role === Roles.REPRESENT) ||
    (group === Group.FACILITY && role === Roles.ADMIN)
  ) {
    return "/operator";
  }
  if (group === Group.FACILITY && role === Roles.MEMBER) {
    return "/staff";
  }
}

export function mapDataFacebook(result) {
  return {
    login_id: result._profile.id /* eslint no-underscore-dangle: 0 */,
    firstName: result._profile.firstName || "",
    lastName: result._profile.lastName || "",
    picture: result._profile.profilePicURL || "",
    displayName: result._profile.name || "",
    accessToken: result._token.accessToken,
    social_id_provider: `${result.provider}_smv`,
  };
}

export function mapDataGoogle(result) {
  return {
    login_id: result.profileObj.email,
    firstName: result.profileObj.fa || "",
    lastName: result.profileObj.familyName || "",
    picture: result.profileObj.givenName || "",
    displayName: result.profileObj.name || "",
    accessToken: result.accessToken,
    social_id_provider: `google_smv`,
  };
}

export function mapDataInsta(codes, redirectUri) {
  return {
    client_id: idIstagram,
    client_secret: secretInstagram,
    grant_type: "authorization_code",
    code: codes,
    redirect_uri: redirectUri,
    social_id_provider: `instagram_smv`,
  };
}

export function mapDataTwitter(codes, encoded, redirectUri) {
  const data = {
    grant_type: "authorization_code",
    client_id: idTwitter,
    redirect_uri: redirectUri,
    code_verifier: "challenge",
    code: codes,
    encodedData: encoded,
    social_id_provider: `twitter_smv`,
  };
  return data;
}

export function mapDataLine(codes, redirectUri) {
  return {
    grant_type: "authorization_code",
    code: codes,
    redirect_uri: redirectUri,
    client_id: idLine,
    client_secret: secretLine,
    social_id_provider: `line_smv`,
  };
}

export function mapDataTiktok(codes) {
  return {
    client_key: keyTiktok,
    client_secret: secretTiktok,
    grant_type: "authorization_code",
    code: codes,
    social_id_provider: "tiktok_smv",
  };
}
