// v-006-002
import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ja from "date-fns/locale/ja";
import { getCountryCallingCode } from "libphonenumber-js";
// import SettingsIcon from "@mui/icons-material/Settings";
import DatePicker from "react-datepicker";
import LayoutDefault from "app/ictl/layouts/LayoutDefault";
import TableDetailPay from "./TableDetailPay";
import useCoreComponetDetail from "./useCoreComponetDetail";
import { FEES_PAY, fomatPrice } from "../../../../constant";
import Path from "../../../route/Path";

export default function Detail() {
  const { t } = useTranslation();
  const [headTile, setHeadTile] = useState([]);
  const navigate = useNavigate();
  const prefixPhoneNumber = getCountryCallingCode("jp".toUpperCase());
  const [
    item,
    rows,
    page,
    total,
    totalPage,
    setPage,
    setStartDate,
    startDate,
    paramUrl,
    setPerPage,
    perPage,
  ] = useCoreComponetDetail();

  const category = Number(paramUrl.category_id);

  const headerOne = [
    {
      id: "nameInfluencer",
      label: t("admin.pay.detail.itemName"),
      align: "center",
    },
    {
      id: "paymentAmount",
      label: t("admin.pay.detail.billing_amount"),
      align: "right",
    },
    {
      id: "price",
      label: t("admin.pay.detail.price"),
      align: "right",
    },
    {
      id: "numbers",
      label: t("admin.pay.detail.number"),
      align: "center",
    },
  ];
  const headerTwo = [
    {
      id: "nameInfluencer",
      label: t("admin.pay.detail.nameMonthlyPlan"),
      align: "center",
    },
    {
      id: "nameProject",
      label: t("admin.pay.detail.optionName"),
      align: "left",
    },
    {
      id: "paymentAmount",
      label: t("admin.pay.detail.billing_amount"),
      align: "right",
    },
    {
      id: "price",
      label: t("admin.pay.detail.price"),
      align: "right",
    },
    {
      id: "numbers",
      label: t("admin.pay.detail.number"),
      align: "center",
    },
    {
      id: "requestNumbers",
      label: t("admin.pay.detail.claim_number"),
      align: "left",
    },
  ];

  const headerThree = [
    {
      id: "nameInfluencer",
      label: t("admin.pay.detail.influencer_name"),
      align: "center",
    },
    {
      id: "nameProject",
      label: t("admin.pay.detail.nameProject"),
      align: "left",
    },
    {
      id: "paymentAmount",
      label: t("admin.pay.detail.billing_amount"),
      align: "right",
    },
    {
      id: "price",
      label: t("admin.pay.detail.price"),
      align: "right",
    },
    {
      id: "numbers",
      label: t("admin.pay.detail.number"),
      align: "center",
    },
    {
      id: "requestNumbers",
      label: t("admin.pay.detail.claim_number"),
      align: "left",
    },
  ];
  const nameHead = () => {
    if (category === FEES_PAY.INITIAL_COST) {
      return setHeadTile(headerOne);
    }
    if (category === FEES_PAY.MONTHLY_PLAN) {
      return setHeadTile(headerTwo);
    }
    return setHeadTile(headerThree);
  };

  useEffect(() => {
    nameHead();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const headCells = [
  //   {
  //     id: 'nameInfluencer',
  //     label: headTile.col1,
  //     align: "center"
  //   },
  //   headTile.col2 !== "" &&
  //   {
  //     id: 'nameProject',
  //     label: headTile.col2,
  //     align: "left"
  //   }
  //   ,
  //   {
  //     id: 'paymentAmount',
  //     label: t("admin.pay.detail.billing_amount"),
  //     align: "right"
  //   },
  //   {
  //     id: 'price',
  //     label: t("admin.pay.detail.price"),
  //     align: "right"
  //   },
  //   {
  //     id: 'numbers',
  //     label: t("admin.pay.detail.number"),
  //     align: "left"
  //   },
  //   category === FEES_PAY.INITIAL_COST ? "" :
  //     {
  //       id: 'requestNumbers',
  //       label: t("admin.pay.detail.claim_number"),
  //       align: "left"
  //     },
  // ];

  return (
    <LayoutDefault showFooter showHeader pin={6}>
      <div className="px-6 flex-1">
        <div className="mt-5 grid grid-cols-12 gap-x-[24px] gap-y-[40px]">
          <div className="col-span-12 lg:col-span-6">
            <div className="lg:w-[70%]">
              <div className="mb-5">
                <button
                  type="button"
                  className="rounded"
                  onClick={() => navigate(Path.pay_list)}
                >
                  <i className="fa-solid fa-chevron-left" />
                </button>
              </div>
              <p className="text-3xl font-bold mb-1">
                {t("admin.pay.list.select_month")}
              </p>
              <div className="flex flex-col mb-10">
                <DatePicker
                  showMonthYearPicker
                  selected={startDate}
                  className="font-bold bg-secondary-400 border divide-slate-200 h-[35px] p-5 rounded"
                  dateFormat={t('common.date_format.yyyy_MM')}
                  locale={ja}
                  onChange={(date) => setStartDate(date)}
                />
              </div>

              <div className="mb-10">
                <p className="text-3xl font-bold">
                  {t("admin.pay.list.reservation_request")}
                </p>
                <h2 className="font-bold text-2xl">
                  {t("admin.pay.list.price")}{" "}
                  {item.total ? fomatPrice(item.total) : 0}
                </h2>
                <p className="font-bold text-xs">
                  ({t("admin.pay.consumptionTax")}{" "}
                  {item.total_amount ? fomatPrice(item.total_amount) : 0})
                </p>
              </div>

              <h2 className="font-bold text-3xl">
                {t("admin.pay.list.numberComfirmComfirmPaid", { value: total })}
              </h2>
            </div>
          </div>
          <div className="col-span-12 lg:col-span-6 mb-4">
            <div className="flex justify-between w-full border-b border-border md:pr-[36px] py-[16px]  editable-item">
              <div className="flex-1 max-w-full">
                <div className="flex justify-between gap-[14px] mb-[4px] text-[14px] font-bold">
                  {t("admin.pay.detail.nameBusiness")}
                </div>
                <div>
                  <div className="pl-[8px] text-gray text-[14px] font-semibold">
                    {item?.service_provider_name}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="flex justify-between w-full border-b border-border md:pr-[36px] py-[16px]  editable-item">
              <div className="flex-1 max-w-full">
                <div className="flex justify-between gap-[14px] mb-[4px] text-[14px] font-bold">
                  {t("admin.pay.detail.postalCode")}
                </div>
                <div>
                  <div className="pl-[8px] text-gray text-[14px] font-semibold">
                    {item.address?.postcode}
                  </div>
                </div>
              </div>
            </div> */}
            <div className="flex justify-between w-full border-b border-border md:pr-[36px] py-[16px]  editable-item">
              <div className="flex-1 max-w-full">
                <div className="flex justify-between gap-[14px] mb-[4px] text-[14px] font-bold">
                  {t("operator.operatorInfo.address")}
                </div>
                <div>
                  <div className="pl-[8px] text-gray text-[14px] font-semibold">
                    {/* {item.address?.address} */}
                    {[
                      item?.address?.postcode,
                      item?.address?.street,
                      item?.address?.city,
                      item?.address?.address,
                      item?.address?.apartment,
                    ]
                      .filter((itemFilter) => itemFilter && itemFilter)
                      .join(" ")}
                    {item?.address?.country === "jp"
                      ? " , 日本-JP"
                      : ` , ${item?.address?.country}`}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between w-full border-b border-border md:pr-[36px] py-[16px]  editable-item">
              <div className="flex-1 max-w-full">
                <div className="flex justify-between gap-[14px] mb-[4px] text-[14px] font-bold">
                  {t("operator.operatorInfo.phone")}
                </div>
                <div>
                  <div className="pl-[8px] text-gray text-[14px] font-semibold">
                    {item.contact?.phone_number?.main
                      ? `+${prefixPhoneNumber} ${item.contact?.phone_number?.main}`
                      : t("common.message.no_input_yet")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-6 mb-10">
          <TableDetailPay
            // getComparator={getComparator}
            // stableSort={stableSort}
            headCells={headTile}
            rows={rows}
            page={page}
            setPage={setPage}
            total={total}
            totalPage={totalPage}
            category={category}
            setPerPage={setPerPage}
            perPage={perPage}
          />
        </div>
      </div>
    </LayoutDefault>
  );
}
