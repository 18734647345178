import React from "react";
import { useTranslation } from "react-i18next";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ExpandMore } from "@mui/icons-material/";
import FormItem, { TYPE_INPUT } from "app/operator/components/FormItem";
import { EProjectReward } from "app/constant";
import { projectRewardType } from "app/utils";

function RewardTypeForm({ values: formValues, setFieldValue }) {
  const { t } = useTranslation();
  const projectRewardOptionList = Object.values(EProjectReward)?.map(
    (type) => ({
      value: type,
      optionName: <>{projectRewardType(type)}</>,
    })
  );

  const onRewardTypeChange = (value) => {
    if (value === EProjectReward.FREE) {
      setFieldValue("projectReward", "0");
    } else {
      setFieldValue("projectReward", "");
      setFieldValue("offerPrice", "");
    }
  };

  return (
    <div className="mb-[16px]">
      <div className="mb-[8px] font-semibold">
        {t("operator.advertisement.detail.reward_type")}
        <span className="required" />
      </div>
      <Accordion
        sx={{
          borderBottom: "2px solid #e5e7eb",
          boxShadow: "none",
          ":before": {
            backgroundColor: "transparent",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className=" text-gray font-semibold">
            {projectRewardType(formValues?.projectRewardType)}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <FormItem
            onChange={(value) => onRewardTypeChange(value)}
            stringValue
            name="projectRewardType"
            containerClassName="mb-[20px]"
            typeInput={TYPE_INPUT.RADIO}
            componentProps={{
              defaultValue: formValues?.projectRewardType,
              optionsList: projectRewardOptionList,
            }}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default RewardTypeForm;
