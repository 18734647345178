/* eslint-disable camelcase */
// v-010-001
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import ictlPath from "app/ictl/route/Path";
import { PER_PAGE } from "app/constant";
import useCoreComponent from "app/hooks/useCoreComponent";
import LayoutDefault from "app/ictl/layouts/LayoutDefault";
import { ICTL_API_ENDPOINT } from "app/services/constant";
import _ from "lodash";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import {
  formatGetPhoneNumberWithPrefix,
  removeFieldEmptyValue,
} from "app/utils";
import {
  SortedAscendingIcon,
  SortedDescendingIcon,
  UnsortIcon,
} from "app/components/DatagridIcon";
import ImageItem from "app/components/ImageItem";
import dayjs from "dayjs";

function AccountList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);

  // handle search params url
  const paramSearch = new URLSearchParams(location.search);
  const initialFilterData = {
    page: paramSearch.get("page") || 1,
    per_page: PER_PAGE,
    search: paramSearch.get("search") || "",
  };
  const [filterValues, setFilterValues] = useState(initialFilterData);

  // eslint-disable-next-line no-unused-vars
  const { response, errorMess, isLoading, totalItem } = useCoreComponent(
    ICTL_API_ENDPOINT.GET_ACCOUNT_LIST,
    {
      ...removeFieldEmptyValue(filterValues),
    }
  );

  const accountColumns = [
    {
      field: "full_name",
      headerName: t("admin.account.list.account_name"),
      headerClassName: "custom",
      sortable: false,
      width: 400,
      renderCell: (params) => {
        const avatar = params?.row?.avatar_url;
        const name = params?.row?.full_name;
        return (
          <div className="flex items-center truncate py-2">
            <div className="w-10 h-10 flex-shrink-0 mr-3">
              <ImageItem
                lazy="true"
                loadavatar="true"
                avatar="true"
                containerclassname="!rounded-full"
                role="presentation"
                imagesrc={avatar}
                alt=""
                imgclassname="w-full h-full object-cover rounded-full"
              />
            </div>
            <div className="truncate font-[700] mr-2">{name}</div>
          </div>
        );
      },
    },
    {
      field: "email_address",
      headerName: t("common.email"),
      headerClassName: "custom",
      sortable: false,
      width: 400,
    },
    {
      field: "phone_number",
      headerName: t("admin.account.list.account_phone"),
      headerClassName: "custom",
      sortable: false,
      minWidth: 300,
      flex: 1,
      valueGetter: (params) => {
        const phone = params?.row?.phone_number;
        return formatGetPhoneNumberWithPrefix(phone);
      },
    },
    {
      field: "logged_in_at",
      headerName: t("admin.account.list.account_last_login"),
      headerClassName: "custom",
      minWidth: 200,
      flex: 1,
      valueGetter: (params) => {
        const lastLogin = params?.row?.logged_in_at;
        return lastLogin
          ? dayjs(lastLogin).format(
              `${t("common.date_format.yyyy_mm_dd")} HH:mm`
            )
          : null;
      },
    },
  ];

  useEffect(() => {
    // handle change url
    const url = new URLSearchParams(removeFieldEmptyValue({ ...filterValues }));
    navigate(`?${url}`, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues]);

  // format data from response
  useEffect(() => {
    const list = response?.data?.data?.row || [];
    if (list?.length && !isLoading) {
      setData(list);
    } else {
      setData([]);
    }
  }, [response, isLoading]);

  const onChangeSearch = (event) => {
    const { value } = event.target;
    setFilterValues((prev) => ({
      ...prev,
      page: 1,
      search: value,
    }));
  };

  const debouncedChangeHandler = _.debounce((event) => {
    onChangeSearch(event);
  }, 1000);

  const onPaginationModelChange = (model) => {
    const newPage = model?.page;
    setFilterValues((prev) => ({
      ...prev,
      page: newPage + 1,
    }));
  };

  return (
    <LayoutDefault showFooter showHeader pin={10}>
      <div className="px-6 flex-1">
        <div className="flex flex-wrap justify-between items-center my-5 gap-3">
          <p className="text-3xl font-bold">
            {t("admin.account.list.total_item", { value: totalItem })}
          </p>
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => navigate(ictlPath.account_create)}
          >
            <i className="fa-solid fa-plus mr-1" />
            {t("admin.account.list.create_account")}
          </Button>
        </div>

        {/* Search Group */}
        <FormControl size="small" className="max-w-lg">
          <OutlinedInput
            sx={{
              borderRadius: 40,
              height: 36,
            }}
            id="outlined-adornment-weight"
            defaultValue={filterValues?.search}
            onChange={(event) => debouncedChangeHandler(event)}
            placeholder={t("admin.account.list.find_account_placeholder")}
            aria-describedby="outlined-weight-helper-text"
            startAdornment={
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            }
          />
        </FormControl>

        {errorMess ? (
          <p className="text-[20px] font-[600] flex-1 py-3">{errorMess}</p>
        ) : (
          <div className="w-full py-6">
            <DataGrid
              autoHeight
              disableColumnMenu
              rows={data}
              columns={accountColumns}
              rowCount={totalItem}
              loading={isLoading}
              paginationMode="server"
              slots={{
                columnUnsortedIcon: UnsortIcon,
                columnSortedDescendingIcon: SortedDescendingIcon,
                columnSortedAscendingIcon: SortedAscendingIcon,
              }}
              getRowHeight={() => "auto"}
              getRowClassName={() => "cursor-pointer"}
              getRowId={(row) => row?.user_id}
              onRowClick={(params, event) => {
                event.preventDefault();
                navigate(ictlPath.account_detail(params?.id));
              }}
              paginationModel={{
                pageSize: PER_PAGE,
                page: filterValues.page - 1,
              }}
              pageSizeOptions={[PER_PAGE]}
              localeText={{
                noResultsOverlayLabel: t("common.message.no_data_display"),
                noRowsLabel: t("common.message.no_data_display"),
              }}
              onPaginationModelChange={(model) => {
                onPaginationModelChange(model);
              }}
            />
          </div>
        )}
      </div>
    </LayoutDefault>
  );
}

export default AccountList;
