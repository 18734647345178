import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import Button, { BUTTON_STYLE_TYPE } from "../operator/components/Button";

export default function ConfirmDialog({
  open,
  title,
  titleClass,
  childrenWrapClass,
  footerBorder,
  cancelLabel,
  confirmLabel,
  onClose,
  onSubmit,
  children
}) {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle
        id="alert-dialog-title"
        className={
          titleClass ||
          "flex justify-between items-center text-[17px] font-semibold"
        }
      >
        <CloseIcon className="cursor-pointer" onClick={onClose} />
        {title}
        {!titleClass && <div className="h-[24px] w-[24px]" />}
      </DialogTitle>
      <div className={childrenWrapClass}>{children}</div>
      <div
        className={`p-[24px] flex justify-between gap-[16px] ${
          footerBorder ? "border-border border-t" : ""
        }`}
      >
        <button
          className="underline font-semibold text-[#646464]"
          type="button"
          onClick={onClose}
        >
          {cancelLabel || t("common.action.cancel")}
        </button>
        <Button onClick={onSubmit} styleType={BUTTON_STYLE_TYPE.PRIMARY}>
          {confirmLabel || t("common.action.confirm")}
        </Button>
      </div>
    </Dialog>
  );
}
