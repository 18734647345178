import { Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function Step2({ handleSubmit, setStep }) {
  const { t } = useTranslation();
  const { profile } = useSelector((state) => state);
  const email = profile?.email_address?.main || "";
  const fullName =
    `${profile?.first_name || ""} ${profile?.family_name || ""}` || "";

  return (
    <>
      <h1 className="text-[32px] leading-9 text-REGULAR_TEXT_COLOR font-extrabold mb-10">
        {t("operator.accountSetting.widthdraw.step_2_title")}
      </h1>

      <div className="mb-10">
        <div className="mb-6">{`${fullName} ${
          fullName && email ? "-" : ""
        } ${email}`}</div>
        <div className="flex items-center gap-3 py-8 border-b border-border">
          <i className="text-[32px] fa-regular fa-circle-check" />
          <span>
            {t("operator.accountSetting.widthdraw.step_2_first_notice")}
          </span>
        </div>
        <div className="flex items-center gap-3 py-8 border-b border-border">
          <i className="text-[32px] fa-regular fa-circle-check" />
          <span>
            {t("operator.accountSetting.widthdraw.step_2_second_notice")}
          </span>
        </div>
      </div>

      <div className="w-full flex justify-between items-center">
        <button
          type="button"
          onClick={() => setStep(1)}
          className="hover:underline text-[#008489] font-bold"
        >
          <i className="fa-solid fa-chevron-left mr-1" />
          <span>{t("common.action.back")}</span>
        </button>

        <Button
          variant="contained"
          className="!px-6 !py-2.5 !rounded-md !bg-[#008489] !text-base !font-extrabold !text-white"
          onClick={handleSubmit}
        >
          {t("auth.continue")}
        </Button>
      </div>
    </>
  );
}

export default Step2;
