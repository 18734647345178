/* eslint-disable camelcase */
import {
  emailRegex,
  EResponseStatus,
  MIN_LIMIT,
} from "app/constant";
import i18next from "i18next";
import { setActive } from "app/redux/blockUIReducer";
import store from "app/redux/store";
import CommonService from "app/services/api/CommonService";
import { ICTL_API_ENDPOINT } from "app/services/constant";
import { object, string } from "yup";
import {
  checkOnlyWhiteSpace,
  formatGetPhoneNumberWithPrefix,
  formatUpdatePhoneNumberData,
  removeFieldEmptyValue,
} from "..";

export const BUSINESS_PERSON_ACTION = {
  STORE: "STORE",
  UPDATE: "UPDATE",
  CANCEL: "CANCEL",
  DELETE: "DELETE",
};

export const initialBusinessPersonValues = {
  service_provider_name: "",
  manager_first_name: "",
  manager_family_name: "",
  bussiness_person_email: "",
  post_code: "",
  full_address: "",
  country: "jp",
  city: "",
  street: "",
  address: "",
  apartment_name: "",
  phone_number: "",
  email: "",
  invoice_number: "",
  user_id: "",
  lat_long: "",
};

export const businessPersonSchema = (t) => {
  return object({
    manager_family_name: string().when(["manager_first_name"], {
      is: (manager_first_name) => {
        return !manager_first_name?.trim();
      },
      then: string().required().trim(),
    }),
    manager_first_name: string(),
    // bussiness_person_email: string()
    //   .required()
    //   .trim()
    //   .matches(emailRegex)
    //   .max(64),
    post_code: string()
      .required()
      .min(
        MIN_LIMIT.POST_CODE,
        t("validate_message.min_length", {
          min: MIN_LIMIT.POST_CODE,
          path: t("validation_field.post_code")
        })
      ),
    full_address: string()
      .when(["lat_long"], {
        is: (lat_long) => {
          return !lat_long?.trim();
        },
        then: string().required(
          t("validate_message.required", {
            path: t("validation_field.lat_long"),
          })
        ),
      })
      .when("lat_long", (lat_long, schema) => {
        if (!lat_long?.trim()) {
          return schema;
        }
        return schema.test(
          "lat_long",
          t("validate_message.required", {
            path: t("validation_field.lat_long"),
          }),
         () => {
           if (lat_long) {
             const array = lat_long.split(',');
             if (array.length === 2 && array[array.length - 1] !== '') {
                return lat_long
             }
           }

           return true;
          }
        );
      })
      .when(["post_code"], {
        is: (post_code) => {
          return !post_code?.trim();
        },
        then: string().required(
          t("validate_message.required", {
            path: t("validation_field.post_code"),
          })
        ),
      })
      .when(["post_code"], {
        is: (post_code) => {
          return post_code?.length < 5;
        },
        then: string().required(
          t("validate_message.min_length", {
            min: MIN_LIMIT.POST_CODE,
            path: t("validation_field.post_code")
          })
        ),
      })
      .when(["address", "street", "city", "country"], {
        is: (address, street, city, country) => {
          return (
            !address?.trim() ||
            !street?.trim() ||
            !city?.trim() ||
            !country?.trim()
          );
        },
        then: string().required().trim(),
      }),
    address: string().required().trim(),
    street: string().required().trim(),
    city: string().required().trim(),
    country: string().required(),
    phone_number: string()
      .required()
      .trim()
      .min(
        4,
        t("validate_message.required", {
          path: t("validation_field.phone_number"),
        })
      ),
    service_provider_name: string().required().trim(),
    email: string().required().trim().matches(emailRegex).max(64),
    invoice_number: string().test({
      test(value, context) {
        return checkOnlyWhiteSpace(value, context, this);
      },
    }),
  });
};

export const formatGetBusinessPersonDetailData = (data) => {
  const {
    address: fullAdress,
    contact,
    manager,
    registration_number,
    service_provider_name,
  } = data;

  const email_address = contact?.email_address?.support;
  const phone_number = contact?.phone_number?.main;
  const {
    address,
    apartment_name,
    city,
    country,
    postcode,
    street,
    latitude,
    longitude,
  } = fullAdress || {};

  const phoneFormat = formatGetPhoneNumberWithPrefix(phone_number);

  const formatData = {
    manager_first_name: manager[0]?.first_name || "",
    manager_family_name: manager[0]?.family_name || "",
    bussiness_person_email: email_address || "",
    post_code: postcode || "",
    full_address: "",
    country: country || "jp",
    city: city || "",
    street: street || "",
    address: address || "",
    apartment_name: apartment_name || "",
    phone_number: phoneFormat,
    service_provider_name: service_provider_name || "",
    email: manager[0]?.email_address?.main || "",
    invoice_number: registration_number || "",
    user_id: manager[0]?.user_id || "",
    lat_long: [latitude, longitude].join(","),
  };

  return formatData;
};

export const updateOrCreateBusinessPerson = async ({
  id,
  data,
  callbackSuccess,
  callbackError,
}) => {
  const {
    manager_first_name,
    manager_family_name,
    bussiness_person_email,
    post_code,
    country,
    city,
    street,
    address,
    phone_number,
    service_provider_name,
    email,
    invoice_number,
    apartment_name,
    user_id,
    lat_long,
  } = data;

  const phoneFormat = formatUpdatePhoneNumberData(phone_number);

  const latLongArray = lat_long?.split(",");

  const payload = {
    service_provider_name,
    avatar_url: "",
    registration_number: invoice_number,
    address: {
      postcode: post_code,
      city,
      street,
      address,
      country,
      apartment_name,
      longitude: latLongArray[1]?.trim() || "",
      latitude: latLongArray[0]?.trim() || "",
    },
    contact: {
      phone_number: {
        main: phoneFormat,
      },
      email_address: {
        support: bussiness_person_email,
      },
    },
    family_name: manager_family_name,
    first_name: manager_first_name,
    email_address: {
      main: email,
    },
  };

  if (id) {
    payload.user_id = user_id;
  }

  // set on block ui
  store.dispatch(setActive(true));

  // store or update business person
  CommonService.postModel(
    id
      ? ICTL_API_ENDPOINT.UPDATE_BUSINESS_PERSON(id)
      : ICTL_API_ENDPOINT.STORE_BUSINESS_PERSON,
    removeFieldEmptyValue(payload)
  )
    .then((response) => {
      if (response.status === EResponseStatus.CREATED && callbackSuccess) {
        callbackSuccess(
          id ? BUSINESS_PERSON_ACTION.UPDATE : BUSINESS_PERSON_ACTION.STORE
        );
      }
    })
    .catch((error) => {
      if (callbackError) {
        callbackError(
          id ? BUSINESS_PERSON_ACTION.UPDATE : BUSINESS_PERSON_ACTION.STORE,
          error
        );
      }
    })
    .finally(() => {
      store.dispatch(setActive(false));
    });
};

export const formatBusinessPersonList = (data) => {
  const lstTemp = [];
  data?.forEach((item, index) => {
    const { apartment_name, address, street, city, country } =
      item.address || "";
    const path = [street, city, address, apartment_name]
      .filter((element) => element)
      .join(" ");
    const countryPath = [i18next.t("common.japan"), country].join("-");
    const addressFull = [path, countryPath].join(",");

    lstTemp[index] = {
      id: item?.service_provider_id,
      service_id: item?.service_provider_id,
      customer_name: item?.service_provider_name,
      count_matching: item?.count_ad_project,
      count_facility: item?.count_facility,
      plan: item?.plan_name,
      created: item?.created_at,
      setting: item?.payment,
      payment_status: item.payment_status,
      invoice_number: item?.registration_number,
      address: addressFull,
    };
  });

  return lstTemp;
};
