// m-007-002 detail/edit
import React, {
  useEffect,
  useState,
  useMemo,
  createContext,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  formatDataProjectDetail,
  getProjectDetailData,
  updateProject,
} from "app/utils/page/project";
import Media from "app/components/Media";
import DetailListContent from "app/components/DetailListContent";
import MainContent from "app/components/MainContent";
import LayoutDefault from "../../../layouts/LayoutDefault";
import CommonInfo from "./CommonInfo";
import Path from "../../../route/Path";

export const ProjectDetailContext = createContext();

function AdvertisementDetail() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const { projectId } = params;
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getProjectDetailData({ setFormValues, projectId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEditProject = useCallback(
    (data) =>
      updateProject({
        data,
        projectId,
        callbackSuccess: (response) => {
          setFormValues(formatDataProjectDetail(response));
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, formValues]
  );

  const projectDetailContextProvider = useMemo(
    () => ({
      formValues,
      id: params?.projectId,
      setFormValues,
      onEditProject,
    }),
    [formValues, setFormValues, params?.projectId, onEditProject]
  );

  const renderRightContent = () => {
    return (
      <>
        <div className="editable-item img-list border-b">
          <div className="flex justify-between gap-[16px] mb-[4px]">
            <div className="text-[18px] font-semibold">
              {t("operator.advertisement.detail.image")}
            </div>
            <button
              type="button"
              className="font-semibold underline align-top text-[14px]"
              onClick={() =>
                navigate(Path.ad_project_detail_edit_image(projectId))
              }
            >
              {t("common.action.edit")}
              <i className="ml-[16px] fa-solid fa-chevron-right" />
            </button>
          </div>

          <div className="relative grid grid-cols-4 gap-[4px] mb-1">
            {formValues?.files?.slice(0, 4)?.map((item, index) => {
              const { media_url: url } = item || {};
              const isVideoProps = `${url}`?.toLowerCase()?.includes(".mp4");

              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} id={url} className="relative pt-[60%]">
                  <Media
                    data={url}
                    isVideoProps={isVideoProps}
                    className="absolute top-0 left-0 w-full h-full rounded-[8px]"
                  />
                </div>
              );
            })}
            {formValues?.files?.length > 0 && (
              <button
                type="button"
                className="gradient-transparent-white absolute top-0 right-0 w-[100px] h-full flex items-center justify-end font-semibold p-[4px] underline"
                onClick={() =>
                  navigate(Path.ad_project_detail_edit_image(projectId))
                }
              >
                {t("operator.advertisement.detail.total_images", {
                  value: formValues.files?.length,
                })}
              </button>
            )}
          </div>
        </div>

        <div className="facility-info mb-10 text-[14px]">
          <p className="font-bold text-[18px]">
            {t("operator.advertisement.detail.adsGeneralInfo")}
          </p>
          <CommonInfo />
        </div>
      </>
    );
  };

  return (
    <ProjectDetailContext.Provider value={projectDetailContextProvider}>
      <LayoutDefault showFooter showHeader>
        <MainContent showBackButton path={Path.ad_project}>
          <DetailListContent
            rightContent={renderRightContent()}
            title={t("operator.advertisement.detail.leftTitle")}
          />
        </MainContent>
      </LayoutDefault>
    </ProjectDetailContext.Provider>
  );
}

export default AdvertisementDetail;
