import React, { useContext } from "react";
import EditableGroup, {
  EDIT_BTN_POSITION,
} from "app/operator/staff/pages/BookingDetail/EditableGroup";
import FormItem, { TYPE_INPUT } from "app/operator/components/FormItem";
import { useTranslation } from "react-i18next";
import { editFacilitySchema } from "app/utils/schema/operator";
import { randomInt } from "app/utils";
import { PlatFormDetailContext } from "app/ictl/pages/BusinessOperatorDetail";
import {
  BILLING_CUTOFF_DATE,
  BILLING_CUTOFF_DATE_TEXT,
  PAYMENT_CUTOFF_DATE,
  PAYMENT_CUTOFF_DATE_TEXT,
} from "../../../../utils/page/platform";

function SettingFee({ sectionId }) {
  const { t } = useTranslation();
  const { formValues, setFormValues, onEditPlatForm } = useContext(
    PlatFormDetailContext
  );

  const onSubmitValue = (e) => {
    onEditPlatForm(e);
    setFormValues(e);
  };

  const billingCutoffDate = Object.values(BILLING_CUTOFF_DATE)?.map((value) => ({
    value,
    optionName: <div key={randomInt(5)}>{BILLING_CUTOFF_DATE_TEXT(t)[value]}</div>,
  }));

  const paymentCutoffDate = Object.values(PAYMENT_CUTOFF_DATE)?.map((value) => ({
    value,
    optionName: <div key={randomInt(5)}>{PAYMENT_CUTOFF_DATE_TEXT(t)[value]}</div>,
  }));

  const renderEditFormBillingCutoffDate = () => {
    return (
      <FormItem
        name="billing_cutoff_date"
        label={t("admin.businessInformation.edit.date_payment")}
        labelClassName="font-semibold mb-2"
        description=""
        placeholder="Please select"
        containerClassName="mb-[16px]"
        typeInput={TYPE_INPUT.RADIO}
        componentProps={{
          defaultValue: formValues?.billing_cutoff_date,
          optionsList: billingCutoffDate,
        }}
      />
    );
  };

  const renderEditFormPaymentCutoffDate = () => {
    return (
      <FormItem
        name="payment_cutoff_date"
        label={t("admin.businessInformation.edit.term_payment")}
        labelClassName="font-semibold mb-2"
        description=""
        placeholder="Please select"
        containerClassName="mb-[16px]"
        typeInput={TYPE_INPUT.RADIO}
        componentProps={{
          defaultValue: formValues?.payment_cutoff_date,
          optionsList: paymentCutoffDate,
        }}
      />
    );
  };

  return (
    <div id={sectionId}>
      <p className="font-bold text-[18px] mt-[20px]">
        {t("admin.businessInformation.edit.close_date")}
      </p>

      <EditableGroup
        editForm={renderEditFormBillingCutoffDate}
        displayValues={
          <div className="pl-[8px] text-gray">
            {BILLING_CUTOFF_DATE_TEXT(t)[formValues?.billing_cutoff_date]}
          </div>
        }
        validationSchema={editFacilitySchema(t)?.settingFee}
        // space
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item"
        label={t("admin.businessInformation.edit.date_payment")}
        labelClassName=""
        required
      />

      <EditableGroup
        editForm={renderEditFormPaymentCutoffDate}
        displayValues={
          <div className="pl-[8px] text-gray">
            {PAYMENT_CUTOFF_DATE_TEXT(t)[formValues?.payment_cutoff_date]}
          </div>
        }
        validationSchema={editFacilitySchema(t)?.settingFee}
        // space
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item"
        label={t("admin.businessInformation.edit.term_payment")}
        labelClassName=""
        required
      />
    </div>
  );
}

export default SettingFee;
