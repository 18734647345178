import React from "react";
import { useTranslation } from "react-i18next";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ExpandMore } from "@mui/icons-material/";
import { facilityIconStatus, facilityStatus, randomInt } from "app/utils";
import { EProjectStatus } from "app/constant";
import FormItem, { TYPE_INPUT } from "app/operator/components/FormItem";

function StatusForm({ values: formValues }) {
  const { t } = useTranslation();
  const inventoryStatusOptionList = Object.values(EProjectStatus)?.map(
    (status) => ({
      value: status,
      optionName: (
        <div key={randomInt(5)}>
          {facilityIconStatus(status)}
          {facilityStatus(status, t)}
        </div>
      ),
    })
  );

  return (
    <div className="mb-[16px]">
      <div className="mb-[8px] font-semibold">
        {t("operator.advertisement.detail.project_status")}
        <span className="required" />
      </div>
      <Accordion
        sx={{
          borderBottom: "2px solid #e5e7eb",
          boxShadow: "none",
          ":before": {
            backgroundColor: "transparent",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="pl-[8px] text-gray font-semibold">
            {facilityIconStatus(
              parseInt(formValues?.visibility_and_status, 10)
            )}
            {facilityStatus(parseInt(formValues?.visibility_and_status, 10), t)}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <FormItem
            name="visibility_and_status"
            // label={t("operator.inventory.detail.status")}
            // labelClassName="font-semibold"
            // description=""
            // descriptionClassName="text-xs text-gray mb-[24px] mt-[4px]"
            placeholder="Please enter"
            containerClassName="mb-[20px]"
            typeInput={TYPE_INPUT.RADIO}
            componentProps={{
              defaultValue: formValues?.visibility_and_status,
              optionsList: inventoryStatusOptionList,
            }}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default StatusForm;
