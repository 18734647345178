export default {
  list: {
    total_item: "橫幅廣告 {{value}}件數",
    banner_title: "橫幅標題",
    banner_image: "圖像",
    banner_date: "持續時間",
    create_banner: "新建橫幅",
    at_least_three: "請註冊三個或以上的橫幅。",
  },
  form: {
    title: "標題",
    files: "照片 (長 250px ✕ 寬 500px)",
    upload: "選擇文件",
    preview: "預覽圖像",
    period: "發布期間",
    delete_notice: "已刪除的橫幅無法恢復，確定刪除？",
    address_url: "URL",
    open_new_tab: "用新標籤打開",
  },
};
