/* eslint-disable camelcase */
import moment from "moment";
import { formatHeicFile, removeFieldEmptyValue } from "..";
import { FILE_UPLOAD_TYPE } from "../../chat/enum/ChatEnum";
import {
  ageGroupLst,
  EProjectReward,
  EResponseStatus,
  FORMAT_DATE,
} from "../../constant";
import { setActive } from "../../redux/blockUIReducer";
import store from "../../redux/store";
import CommonService from "../../services/api/CommonService";
import { OPERATOR_API_ENDPOINT } from "../../services/constant";

// format when get data
export const formatDataProjectDetail = (resData) => {
  const coverImg = () => {
    let url = "";
    if (resData?.project_media?.length > 0) {
      url = resData?.project_media?.find(
        ({ use_for_cover }) => use_for_cover
      )?.media_url;
    }
    return url;
  };

  const ageGroup = resData.desired_conditions.age_group || [""];

  const format = {
    ...resData,
    is_change_media: 1,
    projectTitle: resData?.title || "",
    projectTitleEN: resData?.title_en || "",
    projectTitleCN: resData?.title_cn || "",
    projectDescription: resData?.description || "",
    projectDescriptionEN: resData?.description_en || "",
    projectDescriptionCN: resData?.description_cn || "",
    projectStatus: resData?.visibility_and_status,
    projectFacilityId: resData?.facility_id || "",
    projectFacilityType: resData?.facility_type || "",
    projectRewardType: resData?.fee?.length ? resData.fee[0].contract_type : "",
    projectReward: resData?.fee?.length ? resData.fee[0].fee : "",
    offerPrice: resData?.price || "",
    desiredCondition: resData?.fee?.length ? resData.fee[0].payment_terms : "",
    desiredConditionEN: resData?.fee_en?.length
      ? resData?.fee_en[0]?.payment_terms
      : "",
    desiredConditionCN: resData?.fee_cn?.length
      ? resData?.fee_cn[0]?.payment_terms
      : "",
    ageGroupStart: ageGroup[0],
    ageGroupEnd: ageGroup[ageGroup.length - 1],
    gender: resData?.desired_conditions?.gender,
    minFollowers: resData?.desired_conditions?.min_followers,
    adMethod: resData?.ad_method[0],
    tagInclude: resData?.tags_to_include || "",
    targetUrl: resData?.ad_target_url || "",
    adMedia: resData?.ad_media,
    targetUsername: resData?.ad_target_username || "",
    textInclude: resData?.text_to_include || "",
    textIncludeEN: resData?.text_to_include_en || "",
    textIncludeCN: resData?.text_to_include_cn || "",
    files: resData?.project_media,
    recuitmentDate: {
      startDate: resData?.start_recruitment_at
        ? moment(resData?.start_recruitment_at)
            .tz("Asia/Tokyo")
            .format(FORMAT_DATE.YYYY_MM_DDT)
        : moment().tz("Asia/Tokyo").format(FORMAT_DATE.YYYY_MM_DDT),
      endDate: resData?.end_recruitment_at
        ? moment(resData?.end_recruitment_at)
            .tz("Asia/Tokyo")
            .format(FORMAT_DATE.YYYY_MM_DDTE)
        : moment().tz("Asia/Tokyo").format(FORMAT_DATE.YYYY_MM_DDTE),
      unspecified_date: !!(
        !resData?.start_recruitment_at && !resData?.end_recruitment_at
      ),
    },
    coverImg: coverImg(),
  };

  return format;
};

export const formatEditProjectDetail = (payload) => {
  const formatImgFileList = () => {
    return payload?.files?.map(({ media_url, thumbnail_url }) => {
      return {
        media_type: 1,
        media_url,
        thumbnail_url,
        use_for_cover: media_url === payload.coverImg,
      };
    });
  };
  delete payload.project_media;

  const feeObject = {
    contract_type: payload.projectRewardType,
    fee: payload.projectReward,
    payment_terms: payload.desiredCondition,
  };

  if (feeObject.contract_type === EProjectReward.FREE) {
    feeObject.fee = "0";
  }

  const feeObjectEN = {
    ...feeObject,
    payment_terms: payload.desiredConditionEN,
  };
  const feeObjectCN = {
    ...feeObject,
    payment_terms: payload.desiredConditionCN,
  };

  const ageGroupArrayValues = ageGroupLst
    .filter((item) => {
      if (payload.ageGroupStart && payload.ageGroupEnd) {
        return (
          item.value >= payload.ageGroupStart &&
          item.value <= payload.ageGroupEnd
        );
      }
      return null;
    })
    .map((item) => item.value);

  const format = {
    ...payload,
    files: formatImgFileList(),
    title: payload.projectTitle,
    title_en: payload.projectTitleEN,
    title_cn: payload.projectTitleCN,
    description: payload.projectDescription,
    description_en: payload.projectDescriptionEN,
    description_cn: payload.projectDescriptionCN,
    visibility_and_status: payload.projectStatus,
    facility_id: payload.projectFacilityId,
    facility_type: payload.projectFacilityType,
    desired_conditions: {
      age_group: ageGroupArrayValues,
      gender: payload.gender,
      min_followers: payload.minFollowers,
    },
    fee: [feeObject],
    fee_en: [feeObjectEN],
    fee_cn: [feeObjectCN],
    ad_method: [payload.adMethod],
    tags_to_include: payload.tagInclude,
    ad_target_url: payload.targetUrl,
    ad_media: payload.adMedia.length > 0 ? payload.adMedia.join(",") : "instagram",
    ad_target_username: payload.targetUsername,
    text_to_include: payload.textInclude,
    text_to_include_en: payload.textIncludeEN,
    text_to_include_cn: payload.textIncludeCN,
    start_recruitment_at:
      payload.recuitmentDate.startDate &&
      !payload.recuitmentDate.unspecified_date
        ? payload.recuitmentDate.startDate
        : null,
    end_recruitment_at:
      payload.recuitmentDate.endDate && !payload.recuitmentDate.unspecified_date
        ? payload.recuitmentDate.endDate
        : null,
    price: payload.offerPrice,
  };

  return format;
};

export const updateProject = ({
  data,
  projectId,
  callbackSuccess,
  callbackError,
}) => {
  CommonService.postModel(
    OPERATOR_API_ENDPOINT.UPDATE_AD_PROJECT(projectId),
    removeFieldEmptyValue(formatEditProjectDetail(data))
  )
    .then((response) => {
      if (response.status === EResponseStatus.CREATED) {
        if (callbackSuccess) {
          callbackSuccess(response?.data?.data);
        }
      }
    })
    .catch(() => {
      if (callbackError) {
        callbackError();
      }
      // setErrorMess(t("common.message.cannot_display_data"));
    })
    .finally(() => {
      // setIsLoading(false);
    });
};

export const getProjectDetailData = ({
  setFormValues,
  projectId,
  callbackSuccess,
  callbackError,
}) => {
  CommonService.getModelList(
    OPERATOR_API_ENDPOINT.GET_AD_PROJECT_DETAIL(projectId)
  )
    .then((response) => {
      if (response.status === EResponseStatus.SUCCESS) {
        const resData = response?.data?.data || {};
        setFormValues(formatDataProjectDetail(resData));
        if (callbackSuccess) {
          callbackSuccess(resData);
        }
      }
    })
    .catch(() => {
      if (callbackError) {
        callbackError();
      }
      // setErrorMess(t("common.message.cannot_display_data"));
    })
    .finally(() => {
      // setIsLoading(false);
    });
};

export const createAdProject = async ({
  data,
  callbackSuccess,
  callbackError,
}) => {
  const {
    projectTitle,
    projectTitleEN,
    projectTitleCN,
    projectDescription,
    projectDescriptionEN,
    projectDescriptionCN,
    projectFacilityId,
    projectFacilityType,
    projectRewardType,
    projectReward,
    desiredCondition,
    desiredConditionEN,
    desiredConditionCN,
    ageGroupStart,
    ageGroupEnd,
    gender,
    minFollowers,
    projectFacilityName,
    adMethod,
    tagInclude,
    targetUrl,
    adMedia,
    targetUsername,
    textInclude,
    textIncludeEN,
    textIncludeCN,
    files,
    coverImg,
    recuitmentDate,
    otherFacilityInfo,
    visibility_and_status,
    offerPrice,
  } = data;
  store.dispatch(setActive(true));
  const imgPromiseList = files?.map(({ file, id, fileType }) => {
    const fileFormat = formatHeicFile(file);
    const isVideoProps = `${fileType}`?.toLowerCase()?.includes("mp4");
    return CommonService.uploadFile(
      fileFormat || file,
      isVideoProps ? FILE_UPLOAD_TYPE.VIDEO : FILE_UPLOAD_TYPE.IMAGE
    )
      .then((res) => {
        return { ...res?.data?.data, use_for_cover: coverImg?.id === id };
      })
      .catch(() => {
        // handle error
        store.dispatch(setActive(false));
        if (callbackError) {
          callbackError();
        }
        return Promise.reject();
      })
      .finally(() => {});
  });

  const filesList = await Promise.all(imgPromiseList).then((resList) => {
    return resList;
  });

  const formatedFiles = filesList?.map(
    ({ media_url, thumbnail_url, use_for_cover }) => ({
      media_type: 1,
      media_url,
      thumbnail_url,
      use_for_cover,
    })
  );

  const ageGroupArrayValues = ageGroupLst
    .filter((item) => {
      if (ageGroupStart && ageGroupEnd) {
        return item.value >= ageGroupStart && item.value <= ageGroupEnd;
      }
      return null;
    })
    .map((item) => item.value);

  const createData = {
    title: projectTitle,
    title_en: projectTitleEN,
    title_cn: projectTitleCN,
    description: projectDescription,
    description_en: projectDescriptionEN,
    description_cn: projectDescriptionCN,
    facility_type: projectFacilityType,
    start_recruitment_at:
      recuitmentDate.startDate && !recuitmentDate.unspecified_date
        ? moment(recuitmentDate.startDate).format(FORMAT_DATE.YYYY_MM_DDT)
        : null,
    end_recruitment_at:
      recuitmentDate.endDate && !recuitmentDate.unspecified_date
        ? moment(recuitmentDate.endDate).format(FORMAT_DATE.YYYY_MM_DDTE)
        : null,
    visibility_and_status,
    fee: [
      {
        contract_type: projectRewardType,
        fee: projectReward,
        payment_terms: desiredCondition,
      },
    ],
    fee_en: [
      {
        contract_type: projectRewardType,
        fee: projectReward,
        payment_terms: desiredConditionEN,
      },
    ],
    fee_cn: [
      {
        contract_type: projectRewardType,
        fee: projectReward,
        payment_terms: desiredConditionCN,
      },
    ],
    ad_method: [adMethod],
    ad_media: adMedia && adMedia.length > 0 ? adMedia.join(",") : "instagram",
    ad_target_username: targetUsername,
    ad_target_url: targetUrl,
    text_to_include: textInclude,
    text_to_include_en: textIncludeEN,
    text_to_include_cn: textIncludeCN,
    tags_to_include: tagInclude,
    desired_conditions: {
      age_group: ageGroupArrayValues,
      gender,
      min_followers: minFollowers,
    },
    files: formatedFiles,
    facility_id: projectFacilityId,
    facility_name: projectFacilityName,
    other_facility_information: otherFacilityInfo,
    price: offerPrice,
  };
  CommonService.postModel(
    OPERATOR_API_ENDPOINT.CREATE_AD_PROJECT,
    removeFieldEmptyValue(createData)
  )
    .then((response) => {
      if (response.status === EResponseStatus.CREATED) {
        if (callbackSuccess) {
          callbackSuccess();
        }
      }
    })
    .catch(() => {
      if (callbackError) {
        callbackError();
      }
    })
    .finally(() => {
      store.dispatch(setActive(false));
      // setIsLoading(false);
    });
};
