export default {
  consumptionTax: "Included Consumption Tax￥ ",
  list: {
    select_month: "Select a Month",
    reservation_request: "Total Invoice Amount",
    deposited: "Deposited",
    not_payment: "Unpaid Payment",
    unpaid: "Unpaid Payment",
    price: "¥",
    count_request: "Total Number of Invoices: 2",
    placeholder_search: "Search for a Business",
    business_name: "Business Name",
    status: "Status",
    invoice_amount: "Invoice Amount",
    payment_type: "Payment Type",
    invoice_number: "Invoice Number",
    paid: "Business Operator Paid Payment",
    paid_1: "Operational Payment Confirmed",
    requestPayMent: "Operational Payment Confirmed",
    unPricePaid: "Unpaid Amount",
    pricePaid: "Business Operator Paid Payment",
    comfirmPricePaid: "Operational Payment Confirmed",
    comfirmPricePaid_1: "Amount Paid by Business",
    numberComfirmComfirmPaid: "Total Number of Invoices: {{value}}",
    subject: "件",
    labelSearch: "Business Operator Search",
    updateToPaid: "Update as Paid",
    influencerRewards: "Influencer Rewards",
    monthlyPlan: "Monthly Plan",
    initialCost: "Initial Fees",
  },
  detail: {
    influencer_name: "Influencer Name",
    case_name: "Project Name",
    billing_amount: "Invoice Amount",
    price: "Unit Price",
    number: "Case",
    claim_number: "Invoice Number",
    nameBusiness: "Business Name",
    postalCode: "Postal Code",
    address: "Address",
    phoneNumber: "Phone Number",
    nameProject: "Project Name",
    nameMonthlyPlan: "Monthly Plan Name",
    optionName: "Option Name",
    itemName: "Item Name",
  },
};