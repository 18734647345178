// m-005-001
import React from "react";
import LayoutDefault from "../../../layouts/LayoutDefault";
import List from "./List";

function Pay() {
  return (
    <LayoutDefault showFooter showHeader>
      <List />
    </LayoutDefault>
  );
}
export default Pay;
