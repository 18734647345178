import home from "../pages/Home/translate/HomeJA";
import influencer from "../pages/Influencer/translate/InfluencerJA";
import pay from "../pages/Pay/translate/ja";
import payment from "../pages/payment/translate/ja";
import business from '../pages/BusinessPerson/translate/ja';
import businessInformation from '../pages/BusinessOperatorDetail/translate/ja';
import notification from '../pages/Notification/translate/NotificationJA';
import banner from "../pages/Banner/translate/ja";
import account from "../pages/Account/translate/ja";

export default {
  home,
  influencer,
  pay,
  business,
  businessInformation,
  payment,
  notification,
  banner,
  account,
};
