import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Popover from "@mui/material/Popover";
import { setShowNotify } from "app/redux/authReducer";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import operatorPath from "app/operator/route/Path";
import Snackbar from "@mui/material/Snackbar";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import {
  socketIO,
  SOCKET_LISTENER,
  SOCKET_NAMESPACE,
  SOCKET_TYPE,
} from "app/context/socket";
import LanguageIcon from "@mui/icons-material/Language";
import HeaderDrawer from "./HeaderDrawer";
import ImageItem from "../../components/ImageItem";
import ChatService from "../../services/chat/api/ChatService";
import { EResponseStatus, Roles } from "../../constant";
import { setProjectBatch } from "../../redux/projectBatch";
import UserService from "../../services/api/UserService";
import { LayoutContext, logout } from "../../utils";
import PopupNotify from "./PopupNotify";

const pageLogo = require("app/assets/images/buzz-logo.png");

function Header() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const avatar = useSelector((state) => state.profile?.avatar_url);
  const { user } = useSelector((state) => state.auth);
  const role = useSelector((state) => state.auth?.user?.group_roles?.roles[0]);
  const { user_id: userId } = user;
  const location = useLocation();
  const dispatch = useDispatch();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
  const { unreadAndMarkActionForM } = useSelector(
    (state) => state?.projectBatch
  );
  const [open, setOpen] = useState(false);
  const [lstNotify, setListNotify] = useState([]);
  const [currentNotify, setCurrentNotfy] = useState(0);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const { isChangeLangOpen, setIsChangeLangOpen } = useContext(LayoutContext);

  const lstNaviagtion = [
    {
      id: 1,
      name: t("operator_header.dashboard"),
      path: role === Roles.MEMBER ? "/staff" : operatorPath.operator,
      className:
        "relative group truncate hover:bg-secondary hover:rounded-3xl -mb-2 px-4 py-2 mx-2 cursor-pointer",
    },
  ];

  const handleDrawerOpen = () => {
    if (window.innerWidth >= 1024) {
      setIsDrawerOpen(false);
    } else {
      setSubMenuAnchorEl(null);
      setAnchorEl(null);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleDrawerOpen);
    return () => window.removeEventListener("resize", handleDrawerOpen);
  }, []);

  useEffect(() => {
    ChatService.countUnreadAndMarkAction().then((response) => {
      if (response.status === EResponseStatus.SUCCESS) {
        const { count } = response.data;
        dispatch(setProjectBatch(count));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    UserService.getListNotification().then((res) => {
      if (res.status === EResponseStatus.SUCCESS) {
        const data = res?.data?.data;
        setListNotify(data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handle open notification
  useEffect(() => {
    if (lstNotify?.length) {
      setOpen(true);
      dispatch(setShowNotify(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lstNotify]);

  useEffect(() => {
    if (open) {
      const element = document.getElementById("social-detail-project");
      if (element) {
        element.style.cssText = "top: 160px !important";
      }
    }
  }, [open]);

  const handleItemPosition = () => {
    const style = {
      top: -1 * currentNotify * 56,
    };
    return style;
  };

  const setDrawerState = (state) => {
    setIsDrawerOpen(state);
  };

  // event socket listen to new notification
  useEffect(() => {
    const socket = socketIO(SOCKET_TYPE.ITCL, SOCKET_NAMESPACE.BOOKING, {
      room_id: userId,
    });

    socket.on(SOCKET_LISTENER.SHOW_NOTIFY, (response) => {
      if (response?.data?.length) {
        dispatch(setShowNotify(true));
        const listTemp = [...response.data];
        setListNotify(listTemp);
      }
    });

    return () => {
      socket.off(SOCKET_LISTENER.SHOW_NOTIFY);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, lstNotify]);

  const showDetail = (notification) => {
    setSelectedNotification(notification);
    setOpenPopup(true);
  };

  return (
    <div className="flex flex-col sticky top-0 left-0 z-[1030] w-full">
      <ClickAwayListener
        onClickAway={() => {
          setAnchorEl(null);
          setSubMenuAnchorEl(null);
        }}
      >
        <header
          style={{ boxShadow: "rgba(0, 0, 0, 0.08) 0 1px 0" }}
          className="order-2 bg-white "
        >
          <div className="px-4">
            <div className="flex items-center justify-between h-20 ">
              <div
                role="presentation"
                className="logo flex-shrink-0 cursor-pointer relative"
              >
                <Link to={operatorPath.operator}>
                  <img src={pageLogo} alt="" className="h-[50px] lg:h-[60px]" />
                </Link>
              </div>

              <HeaderDrawer
                isDrawerOpen={isDrawerOpen}
                setDrawerState={setDrawerState}
              />

              <div className="hidden lg:flex items-center text-sm mx-3">
                {lstNaviagtion.map((item) => {
                  return (
                    <NavLink
                      key={item.id}
                      to={item.path}
                      className={item.className}
                    >
                      <span>{item.name}</span>
                      {location.pathname === item.path ? (
                        <div className="group-hover:hidden absolute left-1/2 -translate-x-1/2 bg-black w-7 h-0.5 bottom-0" />
                      ) : null}
                    </NavLink>
                  );
                })}

                <button
                  type="button"
                  onClick={(event) =>
                    setAnchorEl(anchorEl ? null : event.currentTarget)
                  }
                  className={`flex items-center rounded-full hover:bg-secondary hover:rounded-3xl hover:!border-none -mb-2 px-4 py-2 mx-2 cursor-pointer ${
                    anchorEl
                      ? "outline outline-2 outline-black bg-secondary"
                      : ""
                  }`}
                >
                  <div className="mr-2 whitespace-nowrap">
                    {t("operator_header.other_menu")}
                  </div>
                  <div>
                    <i className="text-xs fa-solid fa-chevron-down" />
                  </div>
                </button>
                <Popover
                  id={anchorEl ? "menu-popover" : undefined}
                  open={!!anchorEl}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(false)}
                  className="z-[1032] top-1"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  PaperProps={{
                    classes: {
                      root: "sub-menu absolute transition z-60 w-72 text-left font-bold rounded-3xl py-6 bg-white shadow-2xl",
                    },
                  }}
                >
                  <Link
                    to={role === Roles.MEMBER ? "" : operatorPath.ad_project}
                    className={
                      role === Roles.MEMBER
                        ? "py-3 px-4 bg-secondary text-[14px] cursor-not-allowed"
                        : "py-3 px-4 hover:bg-secondary text-[#222] text-[14px]"
                    }
                  >
                    <div className="flex">
                      <span className="mr-[2px]">
                        {t("operator_header.jobs")}
                      </span>
                      {unreadAndMarkActionForM > 0 && (
                        <div className="text-[10px] bg-[#8F0B6A] text-white min-w-[20px] min-h-[20px] px-1 flex items-center justify-center rounded-full self-start">
                          {unreadAndMarkActionForM}
                        </div>
                      )}
                    </div>
                  </Link>
                  <Link
                    to={role === Roles.MEMBER ? "" : operatorPath.pay_list}
                    className={
                      role === Roles.MEMBER
                        ? "py-3 px-4 bg-secondary text-[14px] cursor-not-allowed"
                        : "py-3 px-4 hover:bg-secondary text-[#222] text-[14px]"
                    }
                  >
                    {t("operator_header.pay")}
                  </Link>
                  <Link
                    to={
                      role === Roles.MEMBER || role === Roles.ADMIN
                        ? ""
                        : operatorPath.operator_info
                    }
                    className={
                      role === Roles.MEMBER || role === Roles.ADMIN
                        ? "py-3 px-4 bg-secondary text-[14px] cursor-not-allowed"
                        : "py-3 px-4 hover:bg-secondary text-[#222] text-[14px]"
                    }
                  >
                    {t("operator_header.operator_info")}
                  </Link>
                </Popover>
              </div>

              <div className="flex items-center">
                <button
                  type="button"
                  onClick={() => setIsChangeLangOpen(!isChangeLangOpen)}
                  className="hover:bg-secondary flex items-center justify-center h-10 w-10 mr-3 rounded-full cursor-pointer transition-all duration-200 ease-linear"
                >
                  <LanguageIcon fontSize="small" />
                </button>

                <div className="hidden lg:flex items-center">
                  <div className="hover:bg-secondary w-10 h-10 rounded-full border border-solid border-secondary-300 flex items-center justify-center mr-4 cursor-pointer">
                    <i className="fa-regular fa-bell" />
                  </div>
                  <button
                    type="button"
                    onClick={(event) =>
                      setSubMenuAnchorEl(
                        subMenuAnchorEl ? null : event.currentTarget
                      )
                    }
                    className="w-12 h-12 rounded-full border border-solid border-secondary-300 flex items-center justify-center p-0.5 cursor-pointer"
                  >
                    <ImageItem
                      avatar="true"
                      containerclassname="!rounded-full"
                      role="presentation"
                      imagesrc={avatar}
                      loadavatar="true"
                      alt=""
                      imgclassname="w-full h-full object-cover rounded-full overflow-hidden"
                    />
                    {/* Sub-menu */}
                    <Popover
                      id={subMenuAnchorEl ? "menu-popover" : undefined}
                      open={!!subMenuAnchorEl}
                      anchorEl={subMenuAnchorEl}
                      onClose={() => setAnchorEl(null)}
                      className="z-[1032]"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      PaperProps={{
                        classes: {
                          root: "sub-menu z-10 w-56 text-left font-bold !rounded-3xl py-4 bg-white shadow-2xl",
                        },
                      }}
                    >
                      <Link
                        to={
                          role === Roles.MEMBER
                            ? "/staff/profile"
                            : operatorPath.profile
                        }
                        className="py-2 px-4 hover:bg-secondary"
                      >
                        {t("common_header.profile")}
                      </Link>
                      <Link
                        to={
                          role === Roles.MEMBER
                            ? "/staff/account-settings"
                            : operatorPath.account_settings
                        }
                        className="py-2 px-4 hover:bg-secondary"
                      >
                        {t("common_header.account_setting")}
                      </Link>
                      <button
                        type="button"
                        onClick={(e) => {
                          navigate("/");
                          logout(e);
                        }}
                        className="py-2 px-4 hover:bg-secondary w-full text-left"
                      >
                        {t("common_header.log_out")}
                      </button>
                    </Popover>
                  </button>
                </div>

                <button
                  type="button"
                  onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                  className="lg:hidden hover:bg-secondary w-12 h-12 rounded-full border border-solid border-secondary-300 flex items-center justify-center cursor-pointer"
                >
                  <i
                    className={`fa-solid ${
                      isDrawerOpen ? "fa-xmark" : "fa-bars"
                    }`}
                  />
                </button>
              </div>
            </div>
          </div>
        </header>
      </ClickAwayListener>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        classes={{
          root: "!order-1 !top-0 !left-0 !right-0 md:!right-[auto] md:!mx-auto !translate-x-0 !w-full !bg-[#F7F7F7] !h-[56px] !relative !rounded-[8px] !overflow-hidden",
        }}
      >
        <div
          className="w-full bg-[#F7F7F7] text-[#222] h-full absolute"
          style={handleItemPosition()}
        >
          {lstNotify?.map((item) => {
            return (
              <div
                key={item.notification_id}
                className="h-full bg-[#F7F7F7] px-4 py-1"
              >
                <div className="flex justify-between items-center h-full">
                  <div className="flex items-center gap-3 mr-4 w-[calc(100%-122px)] font-bold">
                    <div className="flex-shrink-0">
                      {t("ictl_header.notification")}
                    </div>
                    <div className="truncate">{item.title}</div>
                  </div>

                  <div className="flex items-center gap-3">
                    <button
                      type="button"
                      onClick={() => showDetail(item)}
                      className="text-[14px] whitespace-nowrap"
                    >
                      {t("notification_popup.show_more")}
                    </button>
                    <div className="flex flex-col">
                      <PlayArrowOutlinedIcon
                        className="!-rotate-90 cursor-pointer"
                        onClick={() => {
                          if (currentNotify > 0) {
                            setCurrentNotfy((prev) => prev - 1);
                          }
                        }}
                      />
                      <PlayArrowIcon
                        className="!rotate-90 cursor-pointer"
                        onClick={() => {
                          if (currentNotify < lstNotify.length - 1) {
                            setCurrentNotfy((prev) => prev + 1);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Snackbar>
      <PopupNotify
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        notification={selectedNotification}
      />
    </div>
  );
}

export default Header;
