/* eslint-disable camelcase */
import moment from "moment";
import CommonService from "app/services/api/CommonService";
import { ICTL_API_ENDPOINT } from "app/services/constant";
import { EResponseStatus, FORMAT_DATE } from "app/constant";

// format when get data
export const formatDataProjectDetail = (resData) => {
  const coverImg = () => {
    return resData?.project_media?.filter(
      ({ use_for_cover }) => use_for_cover
    )[0]?.media_url;
  };

  const ageGroup = resData.desired_conditions.age_group || [""];

  const format = {
    ...resData,
    is_change_media: 1,
    projectTitle: resData.title,
    projectTitleEN: resData.title_en,
    projectTitleCN: resData.title_cn,
    projectDescription: resData.description,
    projectDescriptionEN: resData.description_en,
    projectDescriptionCN: resData.description_cn,
    projectStatus: resData.visibility_and_status,
    projectFacilityId: resData.facility_id,
    projectFacilityType: resData.facility_type,
    projectRewardType: resData.fee[0].contract_type,
    projectReward: resData.fee[0].fee,
    offerPrice: resData?.price || "",
    desiredCondition: resData.fee[0].payment_terms,
    desiredConditionEN: resData.fee_en[0].payment_terms,
    desiredConditionCN: resData.fee_cn[0].payment_terms,
    ageGroupStart: ageGroup[0],
    ageGroupEnd: ageGroup[ageGroup.length - 1],
    gender: resData.desired_conditions.gender,
    minFollowers: resData.desired_conditions.min_followers,
    adMethod: resData.ad_method[0],
    tagInclude: resData.tags_to_include || "",
    targetUrl: resData.ad_target_url || "",
    adMedia: resData.ad_media,
    targetUsername: resData.ad_target_username || "",
    textInclude: resData.text_to_include || "",
    textIncludeEN: resData.text_to_include_en || "",
    textIncludeCN: resData.text_to_include_cn || "",
    files: resData.project_media,
    recuitmentDate: {
      startDate: resData.start_recruitment_at
        ? moment(resData.start_recruitment_at)
            .tz("Asia/Tokyo")
            .format(FORMAT_DATE.YYYY_MM_DDT)
        : "",
      endDate: resData.end_recruitment_at
        ? moment(resData.end_recruitment_at)
            .tz("Asia/Tokyo")
            .format(FORMAT_DATE.YYYY_MM_DDTE)
        : "",
    },
    coverImg: coverImg(),
  };

  return format;
};

export const getProjectDetailData = ({
  setFormValues,
  projectId,
  callbackSuccess,
  callbackError,
}) => {
  CommonService.getModelList(ICTL_API_ENDPOINT.GET_AD_PROJECT_DETAIL(projectId))
    .then((response) => {
      if (response.status === EResponseStatus.SUCCESS) {
        const resData = response?.data?.data || {};
        setFormValues(formatDataProjectDetail(resData));
        if (callbackSuccess) {
          callbackSuccess(resData);
        }
      }
    })
    .catch(() => {
      if (callbackError) {
        callbackError();
      }
      // setErrorMess(t("common.message.cannot_display_data"));
    })
    .finally(() => {
      // setIsLoading(false);
    });
};
