import React from "react";
import { useTranslation } from "react-i18next";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ExpandMore } from "@mui/icons-material/";
import FormItem, { TYPE_INPUT } from "app/operator/components/FormItem";
import { ErrorMessage } from "formik";
import { ageGroupLst } from 'app/constant';

function AgeGroupForm({ values: formValues, errors }) {
  const { t } = useTranslation();

  const handleDisableItem = (type, value, targetValue) => {
    if (type === "start") {
      if (targetValue && value > targetValue) {
        return true;
      }
      return false;
    }
    if (targetValue && value < targetValue) {
      return true;
    }
    return false;
  };

  return (
    <div className="mb-[16px]">
      <div className="mb-[8px] font-semibold">
        {t("operator.advertisement.detail.age_group")}
      </div>
      <Accordion
        sx={{
          borderBottom: "2px solid #e5e7eb",
          boxShadow: "none",
          ":before": {
            backgroundColor: "transparent",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className=" text-gray font-semibold">
            {(() => {
              if (errors?.ageGroupEnd) {
                return (
                  <ErrorMessage
                    name="ageGroupEnd"
                    component="div"
                    className="errors-msg !font-normal"
                  />
                );
              }
              if (errors?.ageGroupStart) {
                return (
                  <ErrorMessage
                    name="ageGroupStart"
                    component="div"
                    className="errors-msg !font-normal"
                  />
                );
              }
              if (formValues?.ageGroupStart && formValues?.ageGroupEnd) {
                return `${formValues?.ageGroupStart}～${formValues?.ageGroupEnd}`;
              }
              return null;
            })()}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="flex flex-col md:flex-row gap-4 md:gap-10 items-baseline">
            <FormItem
              touchedField="ageGroupEnd"
              disableError
              selectClassName="w-[200px] h-[39px] rounded-[8px]"
              name="ageGroupStart"
              typeInput={TYPE_INPUT.SELECT}
              componentProps={{
                defaultValue: formValues?.ageGroupStart,
                optionsList: ageGroupLst,
              }}
              containerClassName="md:mb-5"
              selectType="start"
              handleDisableSelectItem={(value) =>
                handleDisableItem("start", value, formValues?.ageGroupEnd)
              }
            />
            <div className="text-center w-[200px] md:w-[auto]">～</div>
            <FormItem
              touchedField="ageGroupStart"
              disableError
              selectClassName="w-[200px] h-[39px] rounded-[8px]"
              name="ageGroupEnd"
              typeInput={TYPE_INPUT.SELECT}
              componentProps={{
                defaultValue: formValues?.ageGroupEnd,
                optionsList: ageGroupLst,
              }}
              containerClassName="md:mb-5"
              handleDisableSelectItem={(value) =>
                handleDisableItem("end", value, formValues?.ageGroupStart)
              }
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default AgeGroupForm;
