import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import Button, { BUTTON_STYLE_TYPE } from "app/operator/components/Button";
import { Form, Formik } from "formik";
import { checkValueChange } from "app/utils";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

export const EDIT_BTN_POSITION = {
  CONTENT: "CONTENT",
  LABEL: "LABEL",
};

function EditableCollapse(props) {
  const { t } = useTranslation();
  const {
    editForm,
    initialValues,
    validationSchema,
    onSubmitGroup,
    collapseLabel,
    disabledEdit,
    editBtnPosition = EDIT_BTN_POSITION.CONTENT,
    containerClassName,
    disableCloseIcon,
    allowComparisonField,
    handleClickEdit = () => {},
    handleDeleteEdit = () => {},
    handleCloseEdit = () => {},
    collapseClassName,
    containerBorder = true,
  } = props;
  // const [isFirstTime, setIsFirstTime] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isDisplayEditBtn, setIsDisplayEditBtn] = useState(
    !!editForm && !disabledEdit && !isEditing
  );

  useEffect(() => {
    const isDisplay = !!editForm && !disabledEdit && !isEditing;
    setIsDisplayEditBtn(isDisplay);
    if (disabledEdit) {
      setIsEditing(false);
    }
  }, [editForm, disabledEdit, isEditing]);

  const onClickEdit = () => {
    setIsEditing(!isEditing);
    // setIsFirstTime(false);
    handleClickEdit();
  };

  const onDeleteEdit = () => {
    setIsEditing(false);
    handleDeleteEdit();
  };

  const onCloseEdit = () => {
    setIsEditing(false);
    handleCloseEdit();
  };

  const onSubmit = (values) => {
    onSubmitGroup(values);
    setIsEditing(false);
  };

  const renderEditForm = () => {
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(context) => {
          const { handleSubmit, values } = context;
          const isValuesHasChanged = checkValueChange(
            initialValues,
            values,
            allowComparisonField
          );
          return (
            <Form>
              <div className="relative rounded-[16px] border-boder border mt-4">
                {!disableCloseIcon && (
                  <button type="button" onClick={onCloseEdit}>
                    <i
                      role="presentation"
                      className="absolute top-[24px] right-[24px] fa-solid fa-xmark"
                    />
                  </button>
                )}
                <div className="p-[24px]">{editForm(context)}</div>
                <div className="flex p-[24px] justify-between border-border border-t">
                  <button
                    type="button"
                    onClick={onDeleteEdit}
                    className="underline"
                  >
                    {t("common.action.delete_3")}
                  </button>
                  <Button
                    type="button"
                    onClick={handleSubmit}
                    className="bg-dark text-white duration-500"
                    styleType={BUTTON_STYLE_TYPE.PRIMARY}
                    disabled={!isValuesHasChanged}
                  >
                    {t("common.action.save")}
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  };

  const renderEditButton = () => {
    return (
      <div
        role="presentation"
        onClick={onClickEdit}
        className={`p-[7px] font-bold border border-solid border-[#aaa] rounded inline-flex items-center ${collapseClassName}`}
      >
        <PlayArrowIcon
          fontSize="15"
          className={`w-4 h-[19px] relative -left-1 ${
            isEditing ? "rotate-90" : ""
          }`}
        />
        {collapseLabel}
      </div>
    );
  };

  const renderItemValue = () => {
    return <>{renderEditButton()}</>;
  };

  return (
    <div
      className={cx(
        "flex justify-between w-full md:pr-[36px] py-[16px] ",
        containerClassName,
        {
          "border-b border-border": containerBorder,
        }
      )}
    >
      <div className="w-full">
        {renderItemValue()}
        {isEditing && renderEditForm()}
      </div>
      {isDisplayEditBtn &&
        editBtnPosition === EDIT_BTN_POSITION.CONTENT &&
        renderEditButton()}
    </div>
  );
}

export default EditableCollapse;
