// v-007-001
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import TablePayment from "./TablePayment";
import useCoreComponentList from "./useCoreComponentList";
import SuccessDialog from "../../../../components/SuccessDialog";
import { setHeaderPayment } from "../../../../redux/ictl/headerPaymentReducer";
import { fomatPrice } from "../../../../constant";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function PaymentInfluentce(props) {
  const { t } = useTranslation();
  const { userInfluenceId, setUserInfluenceId } = props;
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [
    items,
    setPage,
    setPerPage,
    total,
    perPage,
    page,
    pricePaid,
    updatePay,
    openSuccess,
    setOpenSuccess,
    showWarning,
    openWarning,
    setOpenWarning,
    nickname,
  ] = useCoreComponentList({ userInfluenceId });
  const headCells = [
    {
      id: "nickname",
      align: "left",
      label: t("admin.payment.listPaymentInfluence.nameInfluence"),
    },
    {
      id: "status",
      align: "center",
      label: t("admin.payment.listPaymentInfluence.paymentRequest"),
    },
    {
      id: "fee",
      align: "right",
      label: t("admin.payment.listPaymentInfluence.cumulativeRewardAmount"),
    },
    {
      id: "update",
      align: "center",
      label: "          ",
    },
  ];

  const totalAmount = pricePaid.total_amount || 0;
  const totalAmountTax = pricePaid.total_amount_tax || 0;
  const totalUnpaid = pricePaid.total_amount_unpaid || 0;
  const totalUnpaidTax = pricePaid.total_amount_unpaid_tax || 0;

  const handleShowpage = (userId) => {
    dispatch(setHeaderPayment(2));
    setUserInfluenceId(userId);
  };
  return (
    <div className="flex-1">
      <div className="max-w-[300px]">
        <div className="mb-10 ">
          <p className="font-bold text-3xl">
            {t("admin.payment.listPaymentInfluence.totalPaymentRequest")}
          </p>
          <div>
            <h2 className="font-bold text-2xl">
              {t("admin.pay.list.price")} {fomatPrice(totalUnpaid)}
            </h2>
            <p className="font-bold text-xs">
              ({t("admin.pay.consumptionTax")}
              {fomatPrice(totalUnpaidTax)})
            </p>
          </div>
        </div>

        <div className="mb-10">
          <p className="font-bold text-3xl">
            {t("admin.payment.listPaymentInfluence.CumulativeTotalAmount")}
          </p>
          <div>
            <h2 className="font-bold text-2xl">
              {t("admin.pay.list.price")} {fomatPrice(totalAmount)}
            </h2>
            <p className="font-bold text-xs">
              ({t("admin.pay.consumptionTax")} {fomatPrice(totalAmountTax)})
            </p>
          </div>
        </div>
      </div>
      <p className="font-bold text-3xl mb-4">
        {t("admin.payment.listPaymentInfluence.totalRequestPayment", {
          value: pricePaid?.count_total_unpaid,
        })}
      </p>
      <TablePayment
        getComparator={getComparator}
        headCells={headCells}
        rows={items}
        perPage={perPage}
        page={page}
        setPerPage={setPerPage}
        setPage={setPage}
        total={total}
        updatePay={showWarning}
        handleShowpage={handleShowpage}
      />
      <SuccessDialog
        message={t("common.message.update_success")}
        open={openSuccess}
        onClose={() => setOpenSuccess(false)}
        onOK={() => setOpenSuccess(false)}
      />
      <SuccessDialog
        message={`${t("admin.payment.warningUpdate", { value: nickname })}`}
        open={openWarning}
        onClose={() => setOpenWarning(false)}
        onOK={() => updatePay()}
      />
    </div>
  );
}
