import { CircularProgress } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import CheckDevice from "app/utils/CheckDevice";

function BlockUI() {
  const active = useSelector((state) => state.blockUI);
  const isMobile = CheckDevice.checkIsMobile();

  return (
    <div>
      {active ? (
        <div
          className={`flex items-center justify-center h-full w-full z-[1500] top-0 left-0 right-0 transition fixed ${
            isMobile ? "bg-black/[.5]" : ""
          } `}
        >
          {isMobile ? <CircularProgress size={50} /> : null}
        </div>
      ) : null}
    </div>
  );
}

export default BlockUI;
