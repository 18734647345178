// v-007-001, v-007-002, v-007-003, v-007-004, v-007-005
import React from 'react'
import LayoutDefault from 'app/ictl/layouts/LayoutDefault';

import HeaderItemPayment from './HeaderItemPayment';

export default function PayMent() {
  return (
    <LayoutDefault showFooter showHeader pin={7}>
      <div className="my-8 w-full">
        <HeaderItemPayment />
      </div>
    </LayoutDefault>
  );
}