export default {
  total_contract: "簽約營運商總數",
  total_influncer: "網紅人數",
  matching_commission: "匹配佣金銷售額",
  number_business_change: "匹配數量",
  membership_change: "免費提供的招募案件數",
  reservation_quantity: "固定收費招募案件數",
  Enter_wrong_infomation: "エクスポート エラー",
  fee_consumption: "其中消費稅",
  select_month: "請輸入開始/結束月份。",
  select_end_month: "請輸入結束月份",
};
