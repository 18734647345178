import React from "react";
import Path from "app/operator/route/Path";
import LoginSecurityForm from 'app/components/login-security/LoginSecurityForm'
import LayoutDefault from 'app/operator/layouts/LayoutDefault';

function LoginSecurity() {
  return (
    <LayoutDefault showFooter showHeader>
      <LoginSecurityForm checkRole path={Path.account_settings} />
    </LayoutDefault>
  );
}

export default LoginSecurity;
