import { createSlice } from "@reduxjs/toolkit";

const initialState = null;

const slice = createSlice({
  name: "facilityId",
  initialState,
  reducers: {
    setFacilityId: (state, action) => {
      return action.payload;
    },
  },
});

export const {setFacilityId } = slice.actions;

export default slice.reducer;
