import { EResponseStatus } from "../../constant";
import { setActive } from "../../redux/blockUIReducer";
import store from "../../redux/store";
import UserService from "../../services/api/UserService";

export const formatUpdateProfileData = (profile, values) => {
  const formatData = {
    family_name: profile?.family_name,
    first_name: profile?.first_name,
    nickname: profile?.nickname,
    avatar_url: values?.avatar_url,
    self_introduction: profile?.self_introduction,
    address: profile?.address,
    spoken_languages: profile?.spoken_languages,
  };
  return formatData;
};

export const updateProfileAction = ({
  profile,
  values,
  callbackSuccess,
  callbackError,
  callbackFinally,
}) => {
  store.dispatch(setActive(true));
  UserService.updateProfile(formatUpdateProfileData(profile, values))
    .then((response) => {
      if (response.status === EResponseStatus.SUCCESS) {
        if (callbackSuccess) {
          callbackSuccess();
        }
      }
    })
    .catch(() => {
      if (callbackError) {
        callbackError();
      }
    })
    .finally(() => {
      store.dispatch(setActive(false));
      if (callbackFinally) {
        callbackFinally();
      }
    });
};
