import React, { useCallback, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Rating, Tooltip } from "@mui/material";
import _ from "lodash";
import LayoutDefault from "app/ictl/layouts/LayoutDefault";
import {
  formatNumber,
  removeFieldEmptyValue,
  trimSpaceFirstCharacter,
} from "app/utils";
import { EResponseStatus, GENDER_VALUE, PER_PAGE } from "../../../constant";
import CommonService from "../../../services/api/CommonService";
import { ICTL_API_ENDPOINT } from "../../../services/constant";
import { formatInfluencerList } from "../../../utils/page/influencer";
import SelectMenu from "../../../components/SelectMenu";
import {
  SortedAscendingIcon,
  SortedDescendingIcon,
  UnsortIcon,
} from "../../../components/DatagridIcon";

const IMG_AVATAR_DEFAULT = require("app/assets/images/default_avatar.png");
const male = require("app/assets/images/icon-male.png");
const female = require("app/assets/images/icon-female.png");
const other = require("app/assets/images/icon-other.png");
const instagram = require("app/assets/images/icon-instagram.png");
const tiktok = require("app/assets/images/icon-tiktok.png");
const facebook = require("app/assets/images/facebook.svg.webp");
const youtube = require("app/assets/images/icon-youtube.png");

function InfluencerList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const paramSearch = new URLSearchParams(location.search);
  const [totalInfluencer, setTotalInfluencer] = useState(0);
  const [data, setData] = useState([]);

  const initialFilterData = {
    page: paramSearch.get("page") || 1,
    per_page: PER_PAGE,
    gender: paramSearch.get("gender")
      ? paramSearch.get("gender").split(",")
      : null,
    nick_name: paramSearch.get("nick_name") || "",
  };

  const [filterValues, setFilterValues] = useState(initialFilterData);

  const getListData = useCallback(() => {
    // handle change url
    const url = new URLSearchParams(removeFieldEmptyValue({ ...filterValues }));
    navigate(`?${url}`, { replace: true });

    // handle get data
    CommonService.getModelList(
      ICTL_API_ENDPOINT.GET_INFLUENCER_LIST,
      removeFieldEmptyValue({ ...filterValues })
    )
      .then((response) => {
        if (response.status === EResponseStatus.SUCCESS) {
          // temp set data
          setTotalInfluencer(response.data.data.total);
          setData(formatInfluencerList(response.data.data.row));
        }
      })
      .catch(() => {
        // setErr(t("common.message.cannot_display_data"));
      })
      .finally(() => {
        // setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues]);

  useEffect(() => {
    getListData();
  }, [getListData]);

  const onChangeSearchGroup = (newVal) => {
    setFilterValues((prev) => ({
      ...prev,
      page: 1,
      gender: newVal?.length ? newVal : null,
    }));
  };

  const columns = [
    {
      field: "avatar",
      headerName: t("admin.influencer.list.avatar"),
      headerClassName: "custom",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <div className="w-10 h-10 flex-shrink-0 mr-3">
            <img
              className="w-full h-full object-cover"
              src={params?.row?.avatar || IMG_AVATAR_DEFAULT}
              alt=""
            />
          </div>
        );
      },
    },
    {
      field: "nickname",
      headerName: t("admin.influencer.list.influcener_name"),
      headerClassName: "custom",
      width: 250,
      sortable: true,
      valueGetter: (row) => {
        return trimSpaceFirstCharacter(row.value);
      },
      renderCell: (params) => {
        const id = params?.row?.id;
        return (
          <div
            role="presentation"
            className="flex items-center truncate cursor-pointer"
            onClick={() => navigate(`/admin/influencer/${id}`)}
          >
            <div className="truncate font-[700]">{params?.value}</div>
          </div>
        );
      },
    },
    {
      field: "rating",
      headerName: t("admin.influencer.list.rating"),
      headerClassName: "custom",
      width: 150,
      sortable: true,
      renderCell: (params) => {
        const rating = Number(params?.row?.rating) || 0;
        return <Rating size="small" precision={0.1} value={rating} readOnly />;
      },
    },
    {
      field: "gender",
      headerName: t("gender"),
      headerClassName: "custom",
      sortable: true,
      width: 100,
      renderCell: (params) => {
        return (
          <div className="flex items-center truncate h-full">
            {params?.row?.gender === "male" && (
              <img className="h-[32px]" src={male} alt="" />
            )}
            {params?.row?.gender === "female" && (
              <img className="h-[32px]" src={female} alt="" />
            )}
            {params?.row?.gender === "other" && (
              <img src={other} alt="" className="relative -left-2 h-11" />
            )}
          </div>
        );
      },
    },
    {
      field: "instagram",
      headerName: t("admin.influencer.list.instagram"),
      headerClassName: "custom",
      sortable: true,
      width: 220,
      valueGetter: (item) => {
        const follower = item.value ? Number(item.value) : "";
        return follower;
      },
      renderCell: (params) => {
        if (params?.row?.instagram) {
          return (
            <div className="flex items-center truncate gap-2">
              <div className="flex items-center justify-center w-7 h-7 flex-shrink-0">
                <img
                  src={instagram}
                  alt=""
                  className="w-full h-full object-contain"
                />
              </div>
              <Tooltip
                title={`${formatNumber(params?.row?.instagram)} ${t(
                  "common.followers"
                )}`}
              >
                <div className="truncate">
                  {formatNumber(params?.row?.instagram)}
                  {t("common.followers")}
                </div>
              </Tooltip>
            </div>
          );
        }

        return <div />;
      },
    },
    {
      field: "tiktok",
      headerName: t("admin.influencer.list.tiktok"),
      headerClassName: "custom",
      sortable: true,
      width: 220,
      valueGetter: (item) => {
        const follower = item.value ? Number(item.value) : "";
        return follower;
      },
      renderCell: (params) => {
        if (params?.row?.tiktok) {
          return (
            <div className="flex items-center truncate gap-2">
              <div className="flex items-center justify-center w-7 h-7 flex-shrink-0">
                <img
                  src={tiktok}
                  alt=""
                  className="w-full h-full object-contain"
                />
              </div>
              <Tooltip
                title={`${formatNumber(params?.row?.tiktok)} ${t(
                  "common.followers"
                )}`}
              >
                <div className="truncate">
                  {formatNumber(params?.row?.tiktok)}
                  {t("common.followers")}
                </div>
              </Tooltip>
            </div>
          );
        }

        return <div />;
      },
    },
    {
      field: "facebook",
      headerName: t("admin.influencer.list.facebook"),
      headerClassName: "custom",
      sortable: true,
      width: 220,
      valueGetter: (item) => {
        const follower = item.value ? Number(item.value) : "";
        return follower;
      },
      renderCell: (params) => {
        if (params?.row?.facebook) {
          return (
            <div className="flex items-center truncate gap-2">
              <div className="flex items-center justify-center w-7 h-7 flex-shrink-0">
                <img
                  src={facebook}
                  alt=""
                  className="w-full h-full object-contain"
                />
              </div>
              <Tooltip
                title={`${formatNumber(params?.row?.facebook)} ${t(
                  "common.followers"
                )}`}
              >
                <div className="truncate">
                  {formatNumber(params?.row?.facebook)}
                  {t("common.followers")}
                </div>
              </Tooltip>
            </div>
          );
        }

        return <div />;
      },
    },
    {
      field: "youtube",
      headerName: t("admin.influencer.list.youtube"),
      headerClassName: "custom",
      sortable: true,
      width: 220,
      valueGetter: (item) => {
        const follower = item.value ? Number(item.value) : "";
        return follower;
      },
      renderCell: (params) => {
        if (params?.row?.youtube) {
          return (
            <div className="flex items-center truncate gap-2">
              <div className="flex items-center justify-center w-7 h-7 flex-shrink-0">
                <img
                  src={youtube}
                  alt=""
                  className="w-full h-full object-contain"
                />
              </div>
              <Tooltip
                title={`${formatNumber(params?.row?.youtube)} ${t(
                  "common.followers"
                )}`}
              >
                <div className="truncate">
                  {formatNumber(params?.row?.youtube)}
                  {t("common.followers")}
                </div>
              </Tooltip>
            </div>
          );
        }

        return <div />;
      },
    },
    {
      field: "self_introduction",
      headerName: t("admin.influencer.list.self_introduction"),
      headerClassName: "custom",
      minWidth: 250,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip title={params?.value}>
            <div className="flex items-center truncate">
              <div className="truncate font-[700]">{params?.value}</div>
            </div>
          </Tooltip>
        );
      },
    },
  ];

  const lstGender = [
    {
      text: t("common.male"),
      value: GENDER_VALUE.MALE,
    },
    {
      text: t("common.female"),
      value: GENDER_VALUE.FEMALE,
    },
    {
      text: t("common.other"),
      value: GENDER_VALUE.OTHER,
    },
  ];

  const debouncedChangeHandler = _.debounce((event) => {
    setFilterValues((prev) => ({
      ...prev,
      page: 1,
      nick_name: event.target.value,
    }));
  }, 1000);

  const onPaginationModelChange = (model) => {
    const newPage = model?.page;
    setFilterValues((prev) => ({
      ...prev,
      page: newPage + 1,
    }));
  };

  return (
    <LayoutDefault showFooter showHeader pin={4}>
      <div className="px-6 flex-1">
        <p className="text-3xl font-bold my-5">
          {t("admin.influencer.list.count", { value: totalInfluencer })}
        </p>
        <div className="flex items-center flex-wrap gap-4">
          <div className="flex items-center max-w-[288px] w-full px-3 py-1 rounded-3xl border border-solid border-secondary-200 bg-secondary">
            <div className="mr-2">
              <i className="fa-solid fa-magnifying-glass text-secondary-200" />
            </div>
            <input
              onChange={(event) => debouncedChangeHandler(event)}
              type="text"
              defaultValue={filterValues?.nick_name}
              placeholder={t("admin.influencer.list.find_influencer")}
              className="flex-1 bg-secondary outline-none w-full"
            />
          </div>
          <SelectMenu
            optionList={lstGender}
            optionSelected={filterValues?.gender}
            onSubmit={(tag) => {
              onChangeSearchGroup(tag);
            }}
            label={t("gender")}
          />
        </div>

        <div className="w-full py-6">
          <DataGrid
            autoHeight
            rows={data}
            columns={columns}
            rowCount={totalInfluencer}
            getRowClassName={() => "cursor-pointer"}
            onRowClick={(params, event) => {
              event.preventDefault();
              navigate(`/admin/influencer/${params?.id}`);
            }}
            paginationMode="server"
            disableColumnMenu
            slots={{
              columnUnsortedIcon: UnsortIcon,
              columnSortedDescendingIcon: SortedDescendingIcon,
              columnSortedAscendingIcon: SortedAscendingIcon,
            }}
            paginationModel={{
              pageSize: PER_PAGE,
              page: filterValues.page - 1,
            }}
            pageSizeOptions={[PER_PAGE]}
            localeText={{
              noResultsOverlayLabel: t("common.message.no_data_display"),
              noRowsLabel: t("common.message.no_data_display"),
            }}
            onPaginationModelChange={(model) => {
              onPaginationModelChange(model);
            }}
          />
        </div>
      </div>
    </LayoutDefault>
  );
}

export default InfluencerList;
