import React from "react";

export function CountInput({ value = 0, minCount, maxCount, onChange }) {
  const isDisabledIncrementBtn = () => value >= maxCount;

  const isDisabledDecrementBtn = () => value <= minCount;

  const onClickIncrementBtn = () => {
    if (isDisabledIncrementBtn()) return;
    onChange(value + 1);
  };

  const onClickDecrementBtn = () => {
    if (isDisabledDecrementBtn()) return;
    onChange(value - 1);
  };
  return (
    <div className="shrink-0 flex items-center justify-end">
      <button
        type="button"
        className={`group w-8 h-8 border rounded-full flex justify-center items-center ${
          isDisabledDecrementBtn()
            ? "cursor-not-allowed border-[#ebebeb]"
            : "cursor-pointer hover:border-dark border-[#888888]"
        } `}
        onClick={onClickDecrementBtn}
      >
        <svg
          fill="none"
          strokeWidth="5.333"
          display="block"
          overflow="visible"
          viewBox="0 0 32 32"
          style={{ height: 12, width: 12 }}
          className={
            isDisabledDecrementBtn()
              ? "stroke-[#ebebeb]"
              : "stroke-[#888888] group-hover:stroke-dark"
          }
        >
          <path d="M2 16h28" />
        </svg>
      </button>
      <span className="text-base font-normal text-dark leading-8 mx-2">
        {value}
      </span>
      <button
        type="button"
        className={`group w-8 h-8 border rounded-full flex justify-center items-center ${
          isDisabledIncrementBtn()
            ? "cursor-not-allowed border-[#ebebeb]"
            : "cursor-pointer hover:border-dark border-[#888888]"
        } `}
        onClick={onClickIncrementBtn}
      >
        <svg
          fill="none"
          strokeWidth="5.333"
          display="block"
          overflow="visible"
          viewBox="0 0 32 32"
          style={{ height: 12, width: 12 }}
          className={
            isDisabledIncrementBtn()
              ? "stroke-[#ebebeb]"
              : "stroke-[#888888] group-hover:stroke-dark"
          }
        >
          <path d="M2 16h28M16 2v28" />
        </svg>
      </button>
    </div>
  );
}
