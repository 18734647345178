/* eslint-disable react/no-array-index-key */
import React from "react";
import { useTranslation } from "react-i18next";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import CustomExpand from "app/components/CustomExpand";
import { ErrorMessage } from "formik";
import FormItem, { TYPE_INPUT } from "app/operator/components/FormItem";
import { customAccordionStyle } from "app/utils";

function AccountPhone(props) {
  const { formikProps } = props;
  const { values: formValues, errors } = formikProps;
  const { t } = useTranslation();

  return (
    <div className="mb-[16px]">
      <div className="mb-[8px] font-semibold required inline">
        {t("admin.account.list.account_phone")}
      </div>
      <Accordion sx={customAccordionStyle()}>
        <AccordionSummary
          classes={{ expanded: "!transform-none", root: "gap-1" }}
          expandIcon={<CustomExpand />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="flex">
            <div className="flex flex-wrap gap-[8px]" translate="no">
              <ErrorMessage
                name="account_phone"
                component="div"
                className="errors-msg"
              />
              {!errors.account_phone &&
                formValues?.account_phone?.length > 3 && (
                  <div>{formValues?.account_phone}</div>
                )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <FormItem
            touched
            required
            disableError
            name="account_phone"
            typeInput={TYPE_INPUT.PHONE}
            label={t("admin.account.list.account_phone")}
            placeholder={t("common.message.no_input_yet")}
            // space
            labelClassName="font-semibold mb-2"
            inputClassName="border-border border rounded-[8px] py-2 px-4 overflow-hidden w-full"
            containerClassName="mb-[16px]"
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default AccountPhone;
