import React, { useContext } from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Radio } from "@mui/material";
import { updatePersonalInfoAction } from "app/utils/page/personal_info";
import EditableForm from "app/components/EditableForm";
import UserContext from "./UserContext";

const EDIT_GENDER = "show_edit_gender";

const listGender = ["male", "female", "other"];

function GenderSettingField(props) {
  const { showEdit, setShowEdit, setOpenSuccess, setOpenError } = props;
  const { t } = useTranslation();
  const { userInfo, setUserInfo } = useContext(UserContext);

  const validationSchema = Yup.object().shape({
    gender: Yup.string(),
  });
  const initialValues = {
    gender: "",
  };

  const updateUserInfo = (values, context) => {
    const { setValues } = context;
    updatePersonalInfoAction({
      userInfo,
      values,
      setUserInfo,
      setValues,
      setOpenSuccess,
      setOpenError,
      setShowEdit,
    });
  };

  const renderForm = ({ values, setFieldValue }) => {
    return (
      <div className="w-full">
        {listGender.map((item) => {
          return (
            <div key={item} className="py-2">
              <div className="flex justify-between items-center ">
                <label htmlFor={item}>
                  {t(`common.${item}`)}
                </label>
                <Radio
                  name="gender"
                  id={item}
                  checked={values?.gender === item}
                  onChange={() => {
                    setFieldValue("gender", item);
                  }}
                  value={item}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <EditableForm
      editForm={renderForm}
      onSubmit={updateUserInfo}
      validationSchema={validationSchema}
      initialValues={initialValues}
      label={t("gender")}
      editField={EDIT_GENDER}
      formValues={userInfo}
      displayValue={
        userInfo?.gender
          ? t(`common.${userInfo?.gender}`)
          : ""
      }
      showEdit={showEdit}
      setShowEdit={setShowEdit}
    />
  );
}

export default GenderSettingField;
