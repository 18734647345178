import React from "react";
import { useTranslation } from "react-i18next";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ExpandMore } from "@mui/icons-material/";
import FormItem, { TYPE_INPUT } from "app/operator/components/FormItem";
import { GENDER } from '../../../../constant';

function GenderForm({ values: formValues }) {
  const { t } = useTranslation();

  const lstGender = [
    {
      value: GENDER.MALE,
      optionName: t(`common.male`),
    },
    {
      value: GENDER.FEMALE,
      optionName: t(`common.female`),
    },
    {
      value: GENDER.OTHER,
      optionName: t(`common.other`),
    },
  ];

  return (
    <div className="mb-[16px]">
      <div className="mb-[8px] font-semibold">
        {t("operator.advertisement.detail.influencer_gender")}
        <span className="required" />
      </div>
      <Accordion
        sx={{
          borderBottom: "2px solid #e5e7eb",
          boxShadow: "none",
          ":before": {
            backgroundColor: "transparent",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className=" text-gray font-semibold">
            <div>{t(`common.${formValues.gender}`)}</div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <FormItem
            stringValue
            name="gender"
            containerClassName="mb-[20px]"
            typeInput={TYPE_INPUT.RADIO}
            componentProps={{
              defaultValue: formValues?.gender,
              optionsList: lstGender,
            }}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default GenderForm;
