import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Geocode from "react-geocode";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { CircularProgress } from "@mui/material";

export default function SearchPlace(props) {
  const {
    tempGMapResult,
    setTempGMapResult,
    focusSearchBox,
    setFocusSearchBox,
    map,
  } = props;

  const [loadingAddressByGeocode, setLoadingAddressByGeocode] = useState(false);

  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      language: "ja"
    },
  });

  const { t } = useTranslation();

  const handleSelectPlace = async (address) => {
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);

    setTempGMapResult({
      placeData: results[0],
      geoCodeData: { latitude: lat, longitude: lng },
    });
    setValue(results[0].formatted_address);

    // setSelectedPlace({
    //   gMapPlace: {
    //     placeData: results[0],
    //     geoCodeData: { latitude: lat, longitude: lng },
    //   },
    //   resultPlace: null,
    // });
    setFocusSearchBox(false);

    if (map) {
      map.panTo({ lat, lng });
      map.setZoom(15);
    }
  };

  const onSearchTextChange = (e) => {
    if (loadingAddressByGeocode || tempGMapResult?.placeData) return;

    setValue(e.target.value);
    setTempGMapResult({
      rawText: e.target.value,
    });
  };

  const handleUseMyLocation = () => {
    if (navigator.geolocation) {
      setLoadingAddressByGeocode(true);

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);

          // set response language. Defaults to english.
          Geocode.setLanguage("ja");
          // Geocode.setRegion("es");
          Geocode.setLocationType("ROOFTOP");

          Geocode.fromLatLng(pos.lat, pos.lng).then(
            (response) => {
              console.log(response.results[0].formatted_address);

              setTempGMapResult({
                placeData: response.results[0],
                geoCodeData: { latitude: pos.lat, longitude: pos.lng },
              });
              setValue(response.results[0].formatted_address);

              setLoadingAddressByGeocode(false);
            },
            (error) => {
              console.error(error);
              setLoadingAddressByGeocode(false);
            }
          );
        },
        (err) => {
          console.log(err);
          setLoadingAddressByGeocode(false);
        }
      );
    } else {
      console.log("Browser doesn't support Geolocation");
    }
  };

  const handleResetSearchText = () => {
    setValue("");
    setTempGMapResult({
      rawText: "",
    });
  };

  return (
    <>
      <div
        className={`block md:hidden absolute transition-all duration-500 pb-20 overflow-hidden w-full rounded-t-xl rounded-b-none inset-x-0 bottom-0 ${
          focusSearchBox ? "h-[95vh]" : "h-full"
        }`}
      >
        <button
          type="button"
          className={`w-full h-full flex flex-col transition-colors duration-500 ${
            focusSearchBox ? "bg-white" : "bg-transparent"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="w-full h-auto">
            <div
              className={`w-full flex md:hidden items-center px-8 overflow-hidden transition-all ${
                focusSearchBox
                  ? "h-auto pt-8 pb-2 opacity-100"
                  : "!h-0 !p-0 m-0 opacity-0"
              }  `}
            >
              <button
                type="button"
                onClick={() => setFocusSearchBox(false)}
                className="w-8 h-8 rounded-full mr-4 flex items-center justify-center bg-white hover:bg-[#dddddd]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  stroke="#222222"
                  strokeWidth="2.667"
                  display="block"
                  overflow="visible"
                  viewBox="0 0 32 32"
                  style={{ height: 24, width: 24 }}
                >
                  <path d="M20 28L8.707 16.707a1 1 0 010-1.414L20 4" />
                </svg>
              </button>
              <h2>{t("operator.host.tab2.enter_your_address")}</h2>
            </div>

            <div className="pt-6 md:pt-0 px-12">
              <div className="h-12 md:h-16 relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#222222"
                  display="block"
                  viewBox="0 0 16 16"
                  style={{ height: 16, width: 16 }}
                  className="absolute top-1/2 left-4 -translate-y-1/2"
                >
                  <path d="M8 .5C4.963.5 2.5 3 2.5 6s1.833 6.084 5.5 9.25C11.666 12.084 13.5 9 13.5 6A5.5 5.5 0 008 .5zM8 8a2 2 0 110-4 2 2 0 010 4z" />
                </svg>

                <input
                  value={value}
                  onChange={onSearchTextChange}
                  disabled={
                    !ready ||
                    loadingAddressByGeocode ||
                    tempGMapResult?.placeData
                  }
                  className="w-full h-full pl-12 pr-[52px] outline-none focus:shadow-[0_0_0_2px_rgba(34,34,34,1)] rounded-[24px] md:round-[32px]"
                   style={{WebkitAppearance: "none"}}
                  placeholder={t("operator.host.tab2.enter_your_address")}
                  onFocus={() => {
                    setFocusSearchBox(true);
                  }}
                />
                {value && (
                  <button
                    type="button"
                    onClick={handleResetSearchText}
                    className="absolute top-1/2 right-4 -translate-y-1/2 bg-[#F7F7F7] flex items-center justify-center p-2 w-7 h-7 rounded-full"
                  >
                    <i className="fa-solid fa-xmark" />
                  </button>
                )}
              </div>
            </div>
          </div>

          <div
            className={`w-full overflow-auto mt-6 ${
              focusSearchBox
                ? "flex-1 h-auto opacity-100"
                : "!h-0 !overflow-hidden opacity-0"
            }`}
          >
            {status === "OK" ? (
              data.map((searchItemData) => (
                <SearchResultItem
                  key={searchItemData.place_id}
                  searchItemData={searchItemData}
                  handleSelectPlace={handleSelectPlace}
                />
              ))
            ) : (
              <UseMyLocation
                handleUseMyLocation={handleUseMyLocation}
                loadingAddressByGeocode={loadingAddressByGeocode}
              />
            )}
          </div>
        </button>
      </div>
      <div className="hidden md:block w-full h-full absolute inset-0">
        <button
          type="button"
          className={`absolute overflow-hidden w-[80%] max-w-[600px] top-1/4 left-1/2 -translate-x-1/2 max-h-[50%] bg-white ${
            focusSearchBox ? "h-auto rounded-xl" : "h-[68px] rounded-full"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <div>
            <div className="w-full pt-0.5 px-0.5">
              <div className="h-12 md:h-16 relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#222222"
                  display="block"
                  viewBox="0 0 16 16"
                  style={{ height: 16, width: 16 }}
                  className="absolute top-1/2 left-4 -translate-y-1/2"
                >
                  <path d="M8 .5C4.963.5 2.5 3 2.5 6s1.833 6.084 5.5 9.25C11.666 12.084 13.5 9 13.5 6A5.5 5.5 0 008 .5zM8 8a2 2 0 110-4 2 2 0 010 4z" />
                </svg>

                <input
                  value={value}
                  onChange={onSearchTextChange}
                  disabled={
                    !ready ||
                    loadingAddressByGeocode ||
                    tempGMapResult?.placeData
                  }
                  className={`w-full h-full pl-12 pr-[52px] outline-none focus:shadow-[0_0_0_2px_rgba(34,34,34,1)] ${
                    focusSearchBox ? "rounded-[12px]" : "rounded-full"
                  } `}
                  placeholder={t("operator.host.tab2.enter_your_address")}
                  onFocus={() => {
                    setFocusSearchBox(true);
                  }}
                  // onBlur={() => {
                  //   setFocusSearchBox(false);
                  // }}
                />
                {value && (
                  <button
                    type="button"
                    onClick={handleResetSearchText}
                    className="absolute top-1/2 right-4 -translate-y-1/2 bg-[#F7F7F7] flex items-center justify-center p-2 w-7 h-7 rounded-full"
                  >
                    <i className="fa-solid fa-xmark" />
                  </button>
                )}
              </div>
            </div>

            <div
              className={`w-full overflow-auto mt-6 ${
                focusSearchBox
                  ? "flex-1 h-auto opacity-100"
                  : "!h-0 !overflow-hidden opacity-0"
              }`}
            >
              {status === "OK" ? (
                data.map((searchItemData) => (
                  <SearchResultItem
                    key={searchItemData.place_id}
                    searchItemData={searchItemData}
                    handleSelectPlace={handleSelectPlace}
                  />
                ))
              ) : (
                <UseMyLocation
                  handleUseMyLocation={handleUseMyLocation}
                  loadingAddressByGeocode={loadingAddressByGeocode}
                />
              )}
            </div>
          </div>
        </button>
      </div>
    </>
  );
}

function SearchResultItem(props) {
  const { searchItemData, handleSelectPlace } = props;
  return (
    <button
      type="button"
      onClick={() => handleSelectPlace(searchItemData.description)}
      className="w-full h-auto px-8 py-4 hover:bg-[#dddddd] cursor-pointer"
    >
      <div className="w-full h-full flex items-center justify-between">
        <div className="w-10 h-10 rounded-full overflow-hidden bg-[#f7f7f7] flex items-center justify-center mr-4 flex-shrink-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#222222"
            display="block"
            viewBox="0 0 32 32"
            style={{ height: 16, width: 16 }}
          >
            <path d="M28 7h-8V4c0-1.103-.897-2-2-2H4c-1.103 0-2 .897-2 2v24c0 1.103.897 2 2 2h24c1.103 0 2-.897 2-2V9c0-1.103-.897-2-2-2zM8 7a1 1 0 110 2 1 1 0 010-2zm0 4a1 1 0 110 2 1 1 0 010-2zm0 4a1 1 0 110 2 1 1 0 010-2zm7 13H7v-5c0-1.103.897-2 2-2h4c1.103 0 2 .897 2 2zm-1-11a1 1 0 110-2 1 1 0 010 2zm0-4a1 1 0 110-2 1 1 0 010 2zm0-4a1 1 0 110-2 1 1 0 010 2zm8 19h-2V9h2zm4-11a1 1 0 110-2 1 1 0 010 2zm0-4a1 1 0 110-2 1 1 0 010 2z" />
          </svg>
        </div>
        <div className="flex-1" style={{ width: "calc(100% - 56px)" }}>
          <div className="w-full flex flex-col text-start">
            <h4 className="w-full truncate text-base leading-5 text-dark">
              {searchItemData?.structured_formatting?.main_text}
            </h4>
            <p className="w-full truncate text-sm leading-[18px] text-dark">
              {searchItemData.description}
            </p>
          </div>
        </div>
      </div>
    </button>
  );
}

function UseMyLocation(props) {
  const { handleUseMyLocation, loadingAddressByGeocode } = props;
  const { t } = useTranslation();

  return (
    <button
      type="button"
      onClick={handleUseMyLocation}
      className="w-full h-auto px-8 py-4 hover:bg-[#dddddd] cursor-pointer border-b border-[#dddddd]"
    >
      <div className="w-full h-full flex items-center justify-between relative">
        <div className="w-10 h-10 rounded-full overflow-hidden bg-[#f7f7f7] flex items-center justify-center mr-4 flex-shrink-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#222222"
            display="block"
            viewBox="0 0 18 18"
            style={{ height: 18, width: 18 }}
          >
            <path d="M10.843 16.349c-.173.41-.614.724-1.054.636C9.35 16.897 9 16.446 9 16V9l-7.058-.007a.936.936 0 01-.364-1.8l13.11-5.117a.945.945 0 011.036.198c.272.271.351.68.2 1.032z" />
          </svg>
        </div>
        <div className="flex-1" style={{ width: "calc(100% - 56px)" }}>
          <div className="w-full flex flex-col text-start">
            <h4 className="w-full truncate text-base leading-5 text-dark">
              {t("operator.host.tab2.use_my_current_location")}
            </h4>
          </div>
        </div>

        {loadingAddressByGeocode ? (
          <div className="w-10 h-10 flex absolute right-2 top-1/2 -translate-y-1/2">
            <CircularProgress size={40} />
          </div>
        ) : null}
      </div>
    </button>
  );
}
