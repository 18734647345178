export const SEND_BY = {
  INFLUENCER: 1,
  ADVERTISER: 2,
  PLATFORM_OPERATOR: 3
};

export const MEDIA_TYPE = {
  IMAGE: 1,
  VIDEO: 2
};

export const FILE_UPLOAD_TYPE = {
  IMAGE: "image",
  VIDEO: "video",
  SVG: "svg",
  PDF: "pdf"
};

export const REPLY_STATUS = {
  UNREAD: 1,
  READ: 2,
  REPLIED: 3
};
