/* eslint-disable camelcase */

import {
  formatGetPhoneNumberWithPrefix,
  formatUpdatePhoneNumberData,
  removeFieldEmptyValue,
} from "..";

export const formatAdminPersonalInfo = (data) => {
  const { service_provider, pricing_plans, agencies } = data || {};
  const { service_provider_name, address, contact, registration_number } =
    service_provider || {};
  const { email_address, family_name, first_name, user_id } = agencies[0] || {};
  const email = email_address?.main || "";
  const {
    address: address_address,
    city: address_city,
    street: address_street,
    postcode,
    country: address_country,
    apartment_name,
    longitude,
    latitude,
  } = address || {};
  const { main: phone_number } = contact.phone_number || {};

  const { plan_name } = pricing_plans[0] || "";

  const phoneFormat = formatGetPhoneNumberWithPrefix(phone_number);

  const formatData = {
    service_provider_name,
    address_address,
    address_city,
    address_street,
    phone_number: phoneFormat,
    post_code: postcode,
    registration_number,
    pricing_plans: plan_name,
    address_country,
    apartment_name,
    manager_family_name: family_name,
    manager_first_name: first_name,
    email,
    user_id,
    lat_long: [latitude, longitude].join(","),
  };

  return formatData;
};

export const formatEditAdminPersonalInfo = (data, rawData) => {
  const {
    service_provider_name,
    address_address,
    address_city,
    address_street,
    post_code,
    phone_number,
    registration_number,
    address_country,
    apartment_name,
    manager_first_name,
    manager_family_name,
    user_id,
    email,
    lat_long,
  } = data || {};
  const { service_provider } = rawData || {};
  const { contact } = service_provider || {};
  const { support } = contact.email_address || {};

  const phoneFormat = formatUpdatePhoneNumberData(phone_number);

  const latLongArray = lat_long?.split(",");

  const formatData = {
    service_provider_name,
    registration_number,
    address: {
      postcode: post_code,
      city: address_city,
      street: address_street,
      address: address_address,
      apartment_name,
      country: address_country,
      longitude: latLongArray[1]?.trim() || "",
      latitude: latLongArray[0]?.trim() || "",
    },
    contact: {
      phone_number: {
        main: phoneFormat,
      },
      email_address: {
        support,
      },
    },
    family_name: manager_family_name,
    first_name: manager_first_name,
    user_id,
    email_address: {
      main: email,
    },
  };

  return removeFieldEmptyValue(formatData);
};
