// v-007-003
import React from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import i18next from "i18next";
import TableHistoryPayment from "./TableHistoryPayment";
import useCoreComponentList from "./useCoreComponentList";
import { ICTL_API_ENDPOINT } from "../../../../services/constant";
import { setHeaderPayment } from "../../../../redux/ictl/headerPaymentReducer";
import { TABPAYMENTSHOW, fomatPrice } from "../../../../constant";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function HistoryPaymentInfluence(props) {
  const { t } = useTranslation();
  const { setUserInfluenceId } = props;
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [
    items,
    setPage,
    setPerPage,
    total,
    perPage,
    page,
    pricePaid,
    startDate,
    setStartDate,
  ] = useCoreComponentList({ model: ICTL_API_ENDPOINT.GET_LIST_PAY });

  const headCells = [
    {
      id: "nameInfluence",
      align: "center",
      label: t("admin.payment.listPaymentInfluence.nameInfluence"),
    },
    {
      id: "payment",
      align: "right",
      label: t("admin.payment.listPaymentHistory.payment"),
    },
    {
      id: "RenewalDateToPaid",
      align: "center",
      label: t("admin.payment.listPaymentHistory.RenewalDateToPaid"),
    },
    {
      id: "changer",
      align: "center",
      label: t("admin.payment.listPaymentHistory.changer"),
    },
  ];

  const handleShowpage = (userId) => {
    dispatch(setHeaderPayment(TABPAYMENTSHOW.PAYMENT_HISTORY_DETATIL));
    setUserInfluenceId(userId);
  };

  return (
    <div className="flex-1">
      <div className="max-w-[300px]">
        <p className="text-3xl font-bold mb-1">
          {t("admin.pay.list.select_month")}
        </p>
        <div className="flex flex-col mb-10">
          <DatePicker
            showMonthYearPicker
            selected={startDate}
            className="font-bold bg-secondary-400 border divide-slate-200 h-[35px] p-5 rounded"
            dateFormat={t("common.date_format.yyyy_MM")}
            locale={i18next.language}
            onChange={(date) => setStartDate(date)}
          />
        </div>

        <div className="mb-10">
          <p className="font-bold text-3xl">
            {t("admin.payment.listPaymentHistory.paymentTotalAmount")}
          </p>
          <div>
            <h2 className="font-bold text-2xl">
              {t("admin.pay.list.price")}{" "}
              {pricePaid.fee ? fomatPrice(pricePaid.fee) : 0}
            </h2>
            <p className="font-bold text-xs">
              ({t("admin.pay.consumptionTax")}{" "}
              {pricePaid.tax ? fomatPrice(pricePaid.tax) : 0})
            </p>
          </div>
        </div>
      </div>
      <h2 className="font-bold text-3xl mb-4">
        {t("admin.payment.listPaymentHistory.numberOfPayment", { value: total })}
      </h2>

      <TableHistoryPayment
        getComparator={getComparator}
        headCells={headCells}
        rows={items}
        perPage={perPage}
        page={page}
        setPerPage={setPerPage}
        setPage={setPage}
        total={total}
        handleShowpage={handleShowpage}
      />
    </div>
  );
}
