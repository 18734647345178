import operator from "../operator/langs/OperatorEN";
import admin from "../ictl/langs/IctlEN";
import chat from "../chat/langs/ChatEN";

export default {
  admin,
  operator,
  chat,
  auth: {
    text_hearder: "ログインタイプを選択してください",
    text_login_email: "ログイン情報を入力してください",
    register_or_login: "Login or Register",
    mashupWelcome: "Welcome to Buzz Traveler",
    continue: "Continue",
    continue_change_password: "Continue",
    current_password: "Current Password",
    new_password: "New Password",
    confirm_new_password: "Confirm Password",
    change_password: "Please change your password.",
    password_do_no_match: "The confirm password and password do not match.",
    email_not_exit:
      "メールアドレスはまだ登録されていません。もう一度ご確認ください。",
    password_reset_guide: "Password Reset ",
    textSendEmail1:
      "We have sent an email with password reset instructions to the registered email address. ",
    textSendEmail2:
      "Please access the password change screen provided in the email,",
    textSendEmail3: "enter the temporary password specified in the email, and set a new password.",
    login: {
      reset_password: "Forgot your password? Click here.",
      Password: "Password",
      Login: "Login",
      Email_format: "Email Address format is incorrect",
      Enter_wrong_infomation: "The username or password is incorrect.",
    },
    register: {
      Instagram: "Continue with Instagram",
      TikTok: "Continue with Tiktok",
      Twitter: "Continue with Twitter",
      Facebook: "Continue with Facebook",
      Google: "Continue with Google",
      Line: "Continue with LINE",
      Message: "メールアドレスで続行",
      Text_register: "事業者登録への扉を開きましょう",
      Button_register: "事業者登録をはじめる",
      Nickname: "Nickname",
      confirm_new_password: "Confirm Password",
      password_regex:
        "Please enter your password between 8 and 64 characters, including half-width alphanumeric characters and symbols.",
    },
    message: {
      error_login: "エラーログイン",
    },
  },
  common: {
    email: "Email Address",
    male: "Male",
    female: "Female",
    other: "Not Specified",
    other1: "Not Specified",
    image: "Photos",
    japan: "Japan",
    currencySymbol: "¥ ",
    passage: "",
    person: " Followers",
    quantity: "個数",
    followers: " Followers",
    date: " Day",
    end_date: "Last Day",
    btn: {
      download_csv: "Download CSV File",
      export: "Export",
      filter: "Filter",
      select_language: "Language and Region",
    },
    message: {
      update_success: "Update successful.",
      delete_success: "Deleted successfully.",
      update_fail: "Update failed. Please try again.",
      delete_fail: "Delete failed. Please try again.",
      not_exist: "The deletion target does not exist.",
      wrong_current_password: "Current password is incorrect.",
      oversize_file: "The maximum file size is {{size}}MB.",
      oversize_image: "The maximum image size is {{size}}MB.",
      oversize_video: "The maximum video size that can be uploaded is {{size}}MB.",
      wrong_format_file: "The following files can be uploaded.\n{{format}}",
      cannot_display_data: "Data cannot be loaded. Please try again.",
      no_data_display: "No data to display.",
      error_upload:
        "An error occurred during file upload. Please try again.",
      INVALID_PASSWORD: "Current password is incorrect.",
      delete_confirm: "Are you sure you want to delete?",
      createSuccess: "Registration successful.",
      createFailed: "Registration failed. Please try again.",
      fieldDateMin: "Start Date cannot be set after End Date.",
      fieldDateMax: "The end time cannot be set before the start time.",
      fileTooSmall: "Please upload images larger than 50KB.",
      invalidFileNumber:
        "Up to 50 items can be uploaded. Up to 3 videos can be uploaded.",
      "Request failed with status code 403": "Update failed. Please try again.",
      "Request failed with status code 401": "Update failed. Please try again.",
      cannot_set_video_as_cover: "Videos cannot be set as cover photos.",
      at_least_5: "Please upload a minimum of 5 photos.",
      ACCOUNT_EXIST: "This email address already exists.\n{{email}}",
      cancel_create:
        "保存されていません。編集内容はキャンセルされますがよろしいですか？",
      select_lang: "Please select your language and region",
      DATA_MAX_RECORD: "バナーは最大{{max}}個までアップロードできます。",
      upload_avatar: "Change Profile Picture",
      delete_log_out: "You will be logged out as the account has been deleted.",
      wrong_dimension:
        "幅{{width}}px、高さ{{height}}pxのファイルを選択してください。",
      choose_image: "Please select an image.",
      no_input_yet: "Not set",
      confirm_password_not_match:
        "The confirm password and password do not match.",
      email_message: "Please enter your email address.",
      email_invalid_message: "Invalid email format.",
      not_found: "Sorry, what you're looking for couldn't be found!",
      password_not_found: "Please enter your password.",
      current_password_not_found: "Please enter current password.",
      new_password_not_found: "Please enter a new password.",
    },
    action: {
      edit: "Edit",
      cancel: "Cancel",
      save: "Save",
      back: "Back",
      next: "Next",
      apply: "Apply",
      delete: "Delete",
      add_more: "Add More",
      clear: "Clear",
      apply1: "Apply",
      confirm: "Confirm",
      delete_2: "Delete",
      delete_3: "Cancel",
      yes: "Yes",
      send: "Send",
      close: "Close",
      ping_platform: "Translation Request",
      update: "Update",
    },
    date_format: {
      dd_mm_yyyy: "DD/MM/YYYY",
      yyyy_mm_dd: "YYYY/MM/DD",
      yyyy_mm: "YYYY/MM",
      mm_dd: "MM/DD",
      dd: "DD",
      just_now: "just now",
      mins_ago: "ago",
      today: "today",
      yesterday: "yesterday",
      yyyy_MM: "yyyy/MM",
    },
    weekDays: {
      monday: "月",
      tuesday: "火",
      wednesday: "水",
      thursday: "木",
      friday: "金",
      saturday: "土",
      sunday: "日",
    },
    weekDaysType1: {
      monday: "月曜",
      tuesday: "火曜",
      wednesday: "水曜",
      thursday: "木曜",
      friday: "金曜",
      saturday: "土曜",
      sunday: "日曜",
    },
    days: {
      today: "今日",
      yesterday: "昨日",
      thisWeek: "今週",
      lastWeek: "先週",
      thisMonth: "今月",
      lastMonth: "先月",
    },
    common_booking: {
      separator_guest_info: "、",

      prefix_number_users: "ゲスト",
      suffixes_number_users: "人",
      prefix_number_user: "ゲスト",
      suffixes_number_user: "人",

      prefix_number_infants: "乳幼児",
      suffixes_number_infants: "名",
      prefix_number_infant: "乳幼児",
      suffixes_number_infant: "名",

      prefix_number_pets: "ペット数：",
      suffixes_number_pets: "",
      prefix_number_pet: "ペット数：",
      suffixes_number_pet: "",
    },
    charge_tax_titles: {
      bathing_tax: "入湯税",
    },
  },
  validate_mark: "*",
  validate_message: {
    required: "{{- path}} is required.",
    max_length: "{{- path}} exceeds maximum length{{max}}.",
    min_length: "Please input minimum of {{min}} characters for {{path}}",
    wrong_format: "{{- path}} format is incorrect.",
    onlyWhiteSpace: "{{- path}}cannot contain only spaces.",
  },
  validation_field: {
    post_code: "Postal Code",
    replyMessage: "Message",
    password: "Password",
    confirm_password: "Confirm Password",
    email: "Email Address",
    projectTitle: "Recruitment Cases Title",
    projectDescription: "Recruitment Cases Description",
    projectRewardType: "Reward Type",
    projectReward: "Reward",
    desiredCondition: "Reward Conditions",
    ageGroup: "Preferred Influencers Age Range",
    gender: "Gender",
    influencer_gender: "Influencer Gender",
    minFollowers: "Minimum Followers/Subscribers on Designated SNS",
    adMethod: "PR Method",
    tagInclude: "Tags to Include in Posts",
    targetUrl: "Pages for PR",
    adMedia: "Designated SNS",
    targetUsername: "Tagging Account",
    textInclude: "Tagged Accounts",
    family_name: "Surname",
    first_name: "Given name",
    familyName: "Surname",
    firstName: "Given name",
    login_id: "Email Address",
    phone_number: "Phone Number",
    offerMessage: "Message",
    completeReject: "Reasons for denying approval",
    completeApprove: "Completion of approval message",
    current_password: "Current Password",
    new_password: "New Password",
    confirm_new_password: "Confirm Password",
    service_provider_name: "Business Name",
    address_address: "Street Address",
    address_city: "City, Town, Village",
    address_street: "Prefecture",
    memo: "メモ",
    name: "名称",
    registration_number: "Registration Number",
    pricing_plans: "Pricing Plans",
    reason: "Reason",
    postcode: "Postal Code",
    country: "Country",
    city: "City, Town, Village",
    street: "Prefecture",
    address: "Street Address",
    banner_title: "Title",
    banner_files: "Image",
    banner_delivery_date: "Start Date",
    banner_end_date: "End Date",
    banner_address_url: "URL",
    offer_price: "Original Offering Price",
    account_first_name: "Account Name",
    account_family_name: "Account Name",
    account_name: "Account Name",
    account_email: "Email Address",
    account_phone: "Phone Number",
    delivery_date: "Start Date",
    end_date: "End Date",
    manager_first_name: "Full Name",
    manager_family_name: "Full Name",
    invoice_number: "Invoice Number",
    manager_name: "Full Name",
    bussiness_person_email: "Email Address",
    apartment_name: "Apartment Name, Room Number, etc.",
    full_address: "Address",
    projectTitleCN: "Recruitment Cases Title",
    projectTitleEN: "Recruitment Cases Title",
    projectDescriptionCN: "Recruitment Cases Description",
    projectDescriptionEN: "Recruitment Cases Description",
    desiredConditionEN: "Reward Conditions",
    desiredConditionCN: "Reward Conditions",
    textIncludeEN: "Tagged Accounts",
    textIncludeCN: "Tagged Accounts",
    ageGroupStart: "Preferred Influencers Age Range",
    ageGroupEnd: "Preferred Influencers Age Range",
    longitude: "Longitude",
    latitude: "Latitude",
    lat_long: "Latitude/Longitude",
  },
  dialog: {
    customer_search: {
      title: "お客様選択",
      placeholder: "お客様名で検索",
    },
    product_search: {
      title: "商品選択",
      placeholder: "商品名で検索",
    },
  },
  ictl_header: {
    home: "Dashboard",
    business_person: "Business Operator",
    influencer: "Influencers",
    operation: "Management",
    claim: "Billing",
    payment: "Payments",
    notification: "Notifications",
    banner: "Banners",
    account: "Account",
  },
  operator_header: {
    dashboard: "Business Operator Home",
    other_menu: "Menu",
    jobs: "Recruitment Cases",
    pay: "Payments",
    operator_info: "Business Operator",
  },
  common_header: {
    profile: "Profile",
    account_setting: "Account",
    log_out: "Logout",
  },
  common_footer: {
    privacy_policy: "Privacy",
    term_service: "Terms of Use",
    company_info: "About the Company",
    currency: "Japanese",
    copyright: "© Copyright 2023 Meikatsu Inc. All Rights Reserved."
  },
  project_request_select: {
    request: "Translation Request Yes",
    not_request: "Translation Request No",
  },
  project_status_select: {
    public: "Public",
    private: "Private",
    trash: "Deleted",
  },
  change_language: "Language and Region",
  or1: "ま",
  or2: "た",
  or3: "は",
  profile: {
    register_as: "Registered as a business account from {{- date}}",
    register_as_operator: "Registered as an administrator from {{- date}}",
  },
  gender: "Gender",
  business_operator: "The business operator",
  operator_admin: "The administrator",
  notification_popup: {
    show_more: "Check details",
  },
  media: {
    twitter : 'X (FormerTwitter)'
  },
  generation1: "Teens",
  generation2: "s",
  generation3: "s and above"
};
