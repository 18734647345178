import React, { useState } from "react";
import { Checkbox, Menu } from "@mui/material";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { randomInt } from "app/utils";

export default function SelectMenu({
  optionList,
  onSubmit,
  label,
  flg,
  optionSelected,
  buttonClassName,
  roundedCustom,
  singleSelect,
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectValues, setSelectValues] = useState(optionSelected || []);
  const isOpen = !!anchorEl;
  const isEmptyValue = selectValues?.length === 0;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSubmit = () => {
    onSubmit(selectValues);
    setAnchorEl(null);
  };

  const onCheck = (e) => {
    const value = e?.target?.value;
    setSelectValues((prev) => {
      if (prev?.includes(value)) {
        return prev?.filter((item) => item !== value);
      }
      if (singleSelect) {
        return [value];
      }
      return [...prev, value];
    });
  };

  return (
    <>
      <button
        id="status-select"
        type="button"
        disabled={flg}
        aria-controls={isOpen ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? "true" : undefined}
        onClick={handleClick}
        className={cx(
          { "border-dark": isOpen },
          buttonClassName,
          `flex w-full justify-between max-h-[100%] items-center px-[16px] mr-[10px] py-[8px] gap-[16px] ${
            roundedCustom || "rounded-[50px]"
          } border flex-[0_0_200px]`
        )}
      >
        <div className="text-[14px]">{label}</div>
        <i className="fa-solid fa-chevron-down" />
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleSubmit}
        MenuListProps={{
          "aria-labelledby": "status-select",
        }}
      >
        {/* Scroll when having > 5 items */}
        <div className="max-h-[290px] overflow-auto w-[350px] max-w-full">
          {optionList?.map(({ text, value }) => {
            return (
              <div key={randomInt(5)} className="flex items-center p-[8px]">
                <Checkbox
                  id={String(value)}
                  checked={selectValues?.includes(`${value}`)}
                  value={value}
                  onChange={onCheck}
                />
                <label
                  htmlFor={String(value)}
                  className="block w-full cursor-pointer truncate"
                >
                  {text}
                </label>
              </div>
            );
          })}
        </div>
        <div className="flex justify-between gpa-[16px] border-t px-[16px] py-[16px] min-w-[300px]">
          <button
            type="button"
            onClick={() => {
              if (!isEmptyValue) {
                setSelectValues([]);
              }
            }}
            className={cx(
              "underline text-[#222222] font-semibold text-[14px] p-[8px] rounded-[12px]",
              {
                "text-[#DDDDDD]": isEmptyValue,
                "hover:bg-[#f7f7f7] hover:text-[#000000]": !isEmptyValue,
              }
            )}
          >
            {t("common.action.clear")}
          </button>
          <button
            type="button"
            onClick={handleSubmit}
            className="rounded-[8px] bg-dark text-[16px] font-semibold text-white py-[8px] px-[16px]"
          >
            {t("common.action.apply1")}
          </button>
        </div>
      </Menu>
    </>
  );
}
