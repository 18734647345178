import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  //
};

const slice = createSlice({
  name: "ictl",
  initialState,
  reducers: {
    //
  },
});

// export const {} = slice.actions;

export default slice.reducer;
