// v-006-001
import React from 'react'
import LayoutDefault from 'app/ictl/layouts/LayoutDefault';
import List from './List';

function Pay() {
  return (
    <LayoutDefault showFooter showHeader pin={6}>
      <div className="p-6 flex-1">
        <List />
      </div>
    </LayoutDefault>
  );
}
export default Pay