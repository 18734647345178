import { createSlice } from "@reduxjs/toolkit";

const initialState = "";

const slice = createSlice({
  name: "emailForChangePass",
  initialState,
  reducers: {
    setEmailForChangePass: (state, action) => {
      return action.payload;
    },
  },
});

export const { setEmailForChangePass} = slice.actions;

export default slice.reducer;
