export default {
  list: {
    customer_name: "企業（者）名稱",
    address: "地址",
    count_matching: "招募案件數",
    created: "合約日期",
    contract_date: "合約日期",
    find_customer: "搜尋業者",
    total_business: "公司業者 {{value}}件數",
    create_staff: "新建業者",
    warning: "未付款提醒",
    invoice_number: "發票號碼",
  },
  add: {
    cancel_create:
      "保存されていません。編集内容はキャンセルされますがよろしいですか？",
    confirm_create_mess:
      "新規登録の為、該当事業者の代表管理者のメールアドレスにアカウント発行メールを通知します。",
    lat_long_notice:
      "請將遊標放在 Google 地圖上公司地址的圖釘位置上，然後按一下滑鼠右鍵。一旦顯示緯度和經度，請再次單擊以將其複製到剪貼簿中。",
  },
};

// admin.business
