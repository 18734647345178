import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import { APPLIED_PROJECT_STATUS, MAX_FILE_SIZE } from "../../constant";

function UploadButton({
  isUpload,
  openUploadedMessage,
  isOpenUploadedMessageError,
  uploadedMessage,
  handleUploadTooltipOpen,
  statusText,
  onUploadFile
}) {
  const { t } = useTranslation();

  const handleFileUpload = async (event) => {
    const files = event.target.files || event.dataTransfer.files;
    if (!files.length || isUpload) return;

    const uploadFile = Object.values(files)[0];

    // Allowing file type
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;
    const pdfType = /(\.pdf)$/i;

    if (!allowedExtensions.exec(uploadFile?.name?.toLowerCase())) {
      handleUploadTooltipOpen({
        message: t("common.message.wrong_format_file", {
          format: "PNG、JPEG、JPG、PDF"
        }),
        isToolTipError: true,
        closeTime: 5000 
      });
    } else if (uploadFile.size > MAX_FILE_SIZE) {
      handleUploadTooltipOpen({
        message: t("common.message.oversize_file", {
          size: MAX_FILE_SIZE / 1024 / 1024
        }),
        isToolTipError: true,
        closeTime: 5000
      });
    } else {
      const isPdf = pdfType.exec(uploadFile?.name?.toLowerCase());
      onUploadFile(uploadFile, isPdf);
      handleUploadTooltipOpen("");
    }
  };

  return (
    <div
      className={cx(
        "flex flex-none justify-center items-center h-12 w-12 rounded-full bg-gray-200 mr-2",
        {
          "hover:bg-gray-300":
            !isUpload && statusText !== APPLIED_PROJECT_STATUS.COMPLETED,
          "!bg-red-100 border border-red-400": isOpenUploadedMessageError
        }
      )}
    >
      <div className="relative flex flex-none justify-center items-center h-12 w-12">
        <Tooltip
          PopperProps={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [40, 10]
                }
              }
            ]
          }}
          open={openUploadedMessage}
          title={<h1 className="text-[14px]">{uploadedMessage}</h1>}
          placement="top"
          arrow
        >
          <i className="fa-solid fa-arrow-up-from-bracket" />
        </Tooltip>

        <input
          disabled={isUpload || statusText === APPLIED_PROJECT_STATUS.COMPLETED}
          onClick={(event) => {
            event.target.value = null;
          }}
          onChange={handleFileUpload}
          type="file"
          name="file"
          accept="image/png, image/jpg, image/jpeg, application/pdf"
          className={`absolute h-full w-full top-0 start-0 opacity-0 ${
            statusText === APPLIED_PROJECT_STATUS.COMPLETED
              ? ""
              : "cursor-pointer"
          }`}
        />
      </div>
    </div>
  );
}

export default UploadButton;
