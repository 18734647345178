import React, { useState, useEffect } from "react";
import { ErrorMessage, Field } from "formik";

function InputText(props) {
  const {
    inputId,
    title,
    inputValue,
    inputName,
    setFieldValue,
    maxLength,
    required,
    regex,
  } = props;
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(inputValue);
  }, [inputValue]);

  const handleChange = (e) => {
    if (regex) {
      if (e.target.value === "" || regex.test(e.target.value)) {
        setValue(e.target.value);
        setFieldValue(inputName, e.target.value);
      }
    } else {
      setValue(e.target.value);
      setFieldValue(inputName, e.target.value);
    }
  };

  return (
    <div className="w-full">
      <label htmlFor={inputId} className="w-full inline-block relative">
        <div className="text-sm leading-5 text-danger-DESCRIPTION mb-4 font-semibold">
          {title}
          {required && <span className="required" />}
        </div>
        <div className="border border-border px-4 py-2 rounded-[8px] text-REGULAR_TEXT_COLOR">
          <Field
            value={value}
            id={inputId}
            name={inputName}
            className="block w-full focus:outline-none leading-[21px]"
            onChange={(e) => handleChange(e)}
            maxLength={maxLength}
          />
        </div>
        <div className="mt-[8px] text-[#717171] font-semibold text-[14px]">
          {inputValue?.length}/{maxLength}
        </div>
      </label>
      <ErrorMessage
        component="div"
        name={inputName}
        className="error-msg !pt-1"
      />
    </div>
  );
}

export default InputText;
