export default {
  list: {
    select_month: "月を選択",
    reservation_request: "2022年9月の予約請求",
    deposited: "入金済み",
    not_payment: "未入金",
    price: "¥",
    count_request: "請求件数 {{value}}件",
    placeholder_search: "事業者を検索",
    business_name: "事業者名",
    status: "ステータス",
    invoice_amount: "請求金額",
    payment_type: "支払い種別",
    invoice_number: "請求番号",
    unpaid: "未入金",
    paid_1: "運営入金確認済み",
    confirming: "支払い済",
    paid: "支払い済",
    inital_cost: "初期費用",
    monthly_usage_price: "システム使用料",
    ad_service_charge: "広告マッチング手数料",
    date_pay: "請求日",
    return: "支払済み",
    updateUnpaid: "支払い済",
    total_bill: "合計請求金額",
    unpaid_amount: "未入金金額",
    paid_mount: "支払済金額",
    operation_payment_confirmed: "運営入金確認済み",
    node: "※運営側の入金確認は最大５営業日かかります。入金確認完了後にステータスが「運営入金確認済み」に自動で変わります。",
  },
  detail: {
    influencer_name: "インフルエンサー名",
    case_name: "案件名",
    billing_amount: "請求金額",
    price: "単価",
    number: "件数",
    claim_number: "請求番号",
  },
};