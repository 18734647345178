/* eslint-disable camelcase */
import { array, number, object, string, mixed } from "yup";
import {
  EFacilityType,
  emailRegex,
  MIN_LIMIT,
} from "../../constant";
import { MIN_LENGTH_POSTCODE } from "./platform";
import { checkOnlyWhiteSpace } from "..";

export const MAX_LENGTH_100 = 100;
export const MAX_LENGTH_5000 = 5000;
export const MAX_LENGTH_50 = 50;
export const MAX_LENGTH_500 = 500;
export const MAX_LENGTH_200 = 200;
export const MAX_LENGTH_1000 = 1000;
export const MAX_LENGTH_2000 = 2000;
export const MAX_LENGTH_PRODUCT_TAG = 20;
export const MAX_LENGTH_POSTCODE = 15;
export const CREATE_TAG_IS_DUPLICATE = "DATA_EXIST";

export const editFacilitySchema = (t) => ({
  facilityType: object({
    facility_type: number().required(),
  }),
  facilityName: object({
    facility_name: string().required(
      t("validate_message.required", {
        path: t("operator.facility.detail.title"),
      })
    ),
  }),
  description: object({
    description: string(),
    // .required(
    //   t("validate_message.required", {
    //     field: t("operator.facility.detail.description"),
    //   })
    // ),
  }),
  address: object({
    address_postcode: string()
      .required(
        t("validate_message.required", {
          path: t("operator.facility.detail.post_code"),
        })
      )
      .min(
        MIN_LENGTH_POSTCODE,
        t("validate_message.min_length", {
          path: t("operator.facility.detail.post_code"),
          min: MIN_LENGTH_POSTCODE,
        })
      ),
    address_state: string().required(
      t("validate_message.required", {
        path: t("operator.facility.detail.state_province"),
      })
    ),
    address_city: string().required(
      t("validate_message.required", {
        path: t("operator.facility.detail.city"),
      })
    ),
    address_address: string().required(
      t("validate_message.required", {
        path: t("operator.facility.detail.location"),
      })
    ),
    address_country: string().required(
      t("validate_message.required", {
        path: t("operator.facility.detail.country"),
      })
    ),
  }),
  email_address: object({
    email_address: string()
      .required(
        t("validate_message.required", {
          path: t("common.email"),
        })
      )
      .max(64, t("common.message.exceeds_maximum_length"))
      .matches(
        emailRegex,
        t("validate_message.wrong_format", {
          path: t("common.email"),
        })
      ),
  }),
  language: object({
    commonly_used_language: string().required(),
    // phone_number: string().required(
    //   t("validate_message.required", {
    //     field: t("operator.facility.detail.phone_number"),
    //   })
    // ),
  }),
  payment_method: object({
    payment_method_type: array().required(
      t("validate_message.required", {
        path: t("operator.facility.detail.payment_method"),
      })
    ),
  }),
  settingFee: object({
    service_charge: number().max(
      100,
      t("common.message.max_limit", {
        field: t("operator.staff.booking.serviceFee"),
        max: 100,
      })
    ),
  }),
  bathing_tax: object({
    bathing_tax: number().max(
      10000,
      t("common.message.max_limit", {
        field: t("operator.facility.detail.bath_tax"),
        max: 10000,
      })
    ),
  }),
  openingHour: object({
    opened_at: string()
      .test(
        "tag-not-empty",
        t("validate_message.required", {
          path: t("operator.facility.detail.checkInTime"),
        }),
        (value, context) => {
          if (context?.parent?.facility_type === EFacilityType.ACCOMMODATION) {
            return !!value;
          }
          return true;
        }
      )
      .test(
        "tag-not-empty",
        t("validate_message.required", {
          path: t("operator.facility.detail.restaurantCheckInTime"),
        }),
        () => {
          // if (context?.parent?.facility_type === EFacilityType.RESTAURANT) {
          //   return !!value;
          // }
          return true;
        }
      ),
    closed_at: string()
      .test(
        "tag-not-empty",
        t("validate_message.required", {
          path: t("operator.facility.detail.checkOutTime"),
        }),
        (value, context) => {
          if (context?.parent?.facility_type === EFacilityType.ACCOMMODATION) {
            return !!value;
          }
          return true;
        }
      )
      .test(
        "tag-not-empty",
        t("validate_message.required", {
          field: t("operator.facility.detail.restaurantCheckOutTime"),
        }),
        () => {
          // if (context?.parent?.facility_type === EFacilityType.RESTAURANT) {
          //   return !!value;
          // }
          return true;
        }
      ),
  }),
});

export const editProjectSchema = () => ({
  projectTitle: object({
    projectTitle: string().required().trim(),
  }),
  projectDescription: object({
    projectDescription: string().required().trim(),
  }),
  projectStatus: object({
    projectStatus: mixed().required(),
  }),
  projectRewardType: object({
    projectRewardType: mixed().required(),
  }),
  projectReward: object({
    projectReward: mixed().required(),
  }),
  desiredCondition: object({
    desiredCondition: string().required().trim(),
  }),
  adMethod: object({
    adMethod: mixed().required(),
  }),
  tagInclude: object({
    tagInclude: string().test({
      test(value, context) {
        return checkOnlyWhiteSpace(value, context, this);
      },
    }),
  }),
  targetUrl: object({
    targetUrl: string().test({
      test(value, context) {
        return checkOnlyWhiteSpace(value, context, this);
      },
    }),
  }),
  targetUsername: object({
    targetUsername: string().test({
      test(value, context) {
        return checkOnlyWhiteSpace(value, context, this);
      },
    }),
  }),
  textInclude: object({
    textInclude: string().test({
      test(value, context) {
        return checkOnlyWhiteSpace(value, context, this);
      },
    }),
  }),
  gender: object({
    gender: mixed().required(),
  }),
  adMedia: object({
    adMedia: mixed().required(),
  }),
  offerPrice: object({
    offerPrice: mixed(),
  }),
  projectTitleCN: object({
    projectTitleCN: string().test({
      test(value, context) {
        return checkOnlyWhiteSpace(value, context, this);
      },
    }),
  }),
  projectTitleEN: object({
    projectTitleEN: string().test({
      test(value, context) {
        return checkOnlyWhiteSpace(value, context, this);
      },
    }),
  }),
  projectDescriptionCN: object({
    projectDescriptionCN: string().test({
      test(value, context) {
        return checkOnlyWhiteSpace(value, context, this);
      },
    }),
  }),
  projectDescriptionEN: object({
    projectDescriptionEN: string().test({
      test(value, context) {
        return checkOnlyWhiteSpace(value, context, this);
      },
    }),
  }),
  desiredConditionEN: object({
    desiredConditionEN: string().test({
      test(value, context) {
        return checkOnlyWhiteSpace(value, context, this);
      },
    }),
  }),
  desiredConditionCN: object({
    desiredConditionCN: string().test({
      test(value, context) {
        return checkOnlyWhiteSpace(value, context, this);
      },
    }),
  }),
  textIncludeEN: object({
    textIncludeEN: string().test({
      test(value, context) {
        return checkOnlyWhiteSpace(value, context, this);
      },
    }),
  }),
  textIncludeCN: object({
    textIncludeCN: string().test({
      test(value, context) {
        return checkOnlyWhiteSpace(value, context, this);
      },
    }),
  }),
  ageGroup: object().shape(
    {
      ageGroupStart: string().when(["ageGroupEnd"], {
        is: (ageGroupEnd) => {
          return ageGroupEnd;
        },
        then: string().required(),
      }),
      ageGroupEnd: string().when(["ageGroupStart"], {
        is: (ageGroupStart) => {
          return ageGroupStart;
        },
        then: string().required(),
      }),
    },
    ["ageGroupStart", "ageGroupEnd"]
  ),
});

export const editOperatorInfoSchema = (t) => ({
  service_provider_name: object({
    service_provider_name: string().required().max(256).trim(),
  }),
  post_code: object({
    post_code: string()
      .required()
      .min(
        MIN_LIMIT.POST_CODE,
        t("validate_message.min_length", {
          min: MIN_LIMIT.POST_CODE,
        })
      ),
  }),
  phone_number: object({
    phone_number: string()
      .required()
      .trim()
      .min(
        4,
        t("validate_message.required", {
          path: t("validation_field.phone_number"),
        })
      ),
  }),
  registration_number: object({
    registration_number: string().required().max(14).trim(),
  }),
  pricing_plans: object({
    pricing_plans: string().required().trim(),
  }),
  address: object({
    post_code: string()
      .required()
      .min(
        MIN_LIMIT.POST_CODE,
        t("validate_message.min_length", {
          min: MIN_LIMIT.POST_CODE,
        })
      ),
    lat_long: string().required().trim(),
    address_address: string().required().trim(),
    address_street: string().required().trim(),
    address_city: string().required().trim(),
    address_country: string().required(),
  }),
  manager: object({
    manager_family_name: string().when(["manager_first_name"], {
      is: (manager_first_name) => {
        return !manager_first_name?.trim();
      },
      then: string().required().trim(),
    }),
    manager_first_name: string(),
  }),
  email: object({
    email: string().required().trim().matches(emailRegex).max(64),
  }),
});

export const createProjectSchema = () => {
  return object().shape(
    {
      projectTitle: string().required().trim(),
      projectDescription: string().required().trim(),
      projectReward: string().required(),
      desiredCondition: string().required().trim(),
      gender: mixed().required(),
      adMedia: mixed().required(),
      tagInclude: string().test({
        test(value, context) {
          return checkOnlyWhiteSpace(value, context, this);
        },
      }),
      targetUrl: string().test({
        test(value, context) {
          return checkOnlyWhiteSpace(value, context, this);
        },
      }),
      targetUsername: string().test({
        test(value, context) {
          return checkOnlyWhiteSpace(value, context, this);
        },
      }),
      textInclude: string().test({
        test(value, context) {
          return checkOnlyWhiteSpace(value, context, this);
        },
      }),
      projectTitleCN: string().test({
        test(value, context) {
          return checkOnlyWhiteSpace(value, context, this);
        },
      }),
      projectTitleEN: string().test({
        test(value, context) {
          return checkOnlyWhiteSpace(value, context, this);
        },
      }),
      projectDescriptionCN: string().test({
        test(value, context) {
          return checkOnlyWhiteSpace(value, context, this);
        },
      }),
      projectDescriptionEN: string().test({
        test(value, context) {
          return checkOnlyWhiteSpace(value, context, this);
        },
      }),
      desiredConditionEN: string().test({
        test(value, context) {
          return checkOnlyWhiteSpace(value, context, this);
        },
      }),
      desiredConditionCN: string().test({
        test(value, context) {
          return checkOnlyWhiteSpace(value, context, this);
        },
      }),
      textIncludeEN: string().test({
        test(value, context) {
          return checkOnlyWhiteSpace(value, context, this);
        },
      }),
      textIncludeCN: string().test({
        test(value, context) {
          return checkOnlyWhiteSpace(value, context, this);
        },
      }),
      offerPrice: string(),
      ageGroupStart: string().when(["ageGroupEnd"], {
        is: (ageGroupEnd) => {
          return ageGroupEnd;
        },
        then: string().required(),
      }),
      ageGroupEnd: string().when(["ageGroupStart"], {
        is: (ageGroupStart) => {
          return ageGroupStart;
        },
        then: string().required(),
      }),
    },
    ["ageGroupStart", "ageGroupEnd"]
  );
};
