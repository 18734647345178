import React, { useState, useMemo } from "react";
import Footer from "app/components/Footer";
import Header from "app/ictl/components/Header";
import ChangeLanguage from "app/components/ChangeLanguage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LayoutContext } from '../../utils';

function LayoutDefault({ children, showFooter, showHeader, pin }) {
  const [isChangeLangOpen, setIsChangeLangOpen] = useState(false);

  return (
    <LayoutContext.Provider
      value={useMemo(
        () => ({ isChangeLangOpen, setIsChangeLangOpen }),
        [isChangeLangOpen, setIsChangeLangOpen]
      )}
    >
      <div className="flex flex-col min-h-screen">
        {showHeader && (
          <Header
            isChangeLangOpen={isChangeLangOpen}
            setIsChangeLangOpen={setIsChangeLangOpen}
            pin={pin}
          />
        )}

        {children}

        {showFooter && (
          <Footer
            isChangeLangOpen={isChangeLangOpen}
            setIsChangeLangOpen={setIsChangeLangOpen}
          />
        )}

        <ChangeLanguage
          isChangeLangOpen={isChangeLangOpen}
          setIsChangeLangOpen={setIsChangeLangOpen}
        />

        <ToastContainer
          position="bottom-left"
          autoClose={1500}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
          limit={3}
        />
      </div>
    </LayoutContext.Provider>
  );
}

export default LayoutDefault;
