import React, { useEffect, useState } from "react";
import imgDefault from "app/assets/images/no-available-image.svg";
import { isVideoRegex } from "../constant";

function Media({ data, controls, className, isVideoProps }) {
  const [isError, setIsError] = useState(false);
  const isVideo =
    isVideoRegex?.exec(`${data}`.toLowerCase()) ||
    isVideoRegex?.exec(`${data?.name}`.toLowerCase()) ||
    isVideoProps;

  useEffect(() => {
    setIsError(false);
  }, [data]);

  return (
    <div className={className}>
      <div className="w-full h-full">
        {isError ? (
          <img className="object-cover h-full w-full" src={imgDefault} alt="" />
        ) : null}

        {!isError && isVideo ? (
          <video
            controls={controls}
            className="h-full w-full object-cover"
            onError={() => {
              setIsError(true);
            }}
          >
            <track kind="captions" />
            <source src={data} type="video/mp4" />
          </video>
        ) : null}

        {!isError && !isVideo ? (
          <img
            className="object-cover h-full w-full"
            src={data}
            alt=""
            onError={() => {
              setIsError(true);
            }}
          />
        ) : null}
      </div>
    </div>
  );
}

export default Media;
