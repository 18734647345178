/* eslint-disable camelcase */

import { EResponseStatus } from "../../constant";
import CommonService from "../../services/api/CommonService";
import { convertDynamicPath, formatGetPhoneNumberWithPrefix, formatUpdatePhoneNumberData } from "../index";
import { ICTL_API_ENDPOINT } from "../../services/constant";

export const BILLING_CUTOFF_DATE = {
  DATE_1: 10,
  DATE_2: 15,
  DATE_3: 20,
  DATE_4: 25,
  DATE_5: 0,
};

export const BILLING_CUTOFF_DATE_TEXT = (t) => ({
  [BILLING_CUTOFF_DATE.DATE_1]: 10 + t("common.date"),
  [BILLING_CUTOFF_DATE.DATE_2]: 15 + t("common.date"),
  [BILLING_CUTOFF_DATE.DATE_3]: 20 + t("common.date"),
  [BILLING_CUTOFF_DATE.DATE_4]: 25 + t("common.date"),
  [BILLING_CUTOFF_DATE.DATE_5]: t("common.end_date"),
});

export const PAYMENT_CUTOFF_DATE = {
  DATE_1: 1,
  DATE_2: 2,
};

export const PAYMENT_CUTOFF_DATE_TEXT = (t) => ({
  [PAYMENT_CUTOFF_DATE.DATE_1]: t("admin.businessInformation.edit.this_end_month"),
  [PAYMENT_CUTOFF_DATE.DATE_2]: t("admin.businessInformation.edit.last_end_month"),
});

export const PLATFORM_DETAIL_COMMON_INFO_KEY_LIST = {
  COMMON_INFO: 1,
  SETTING_FEE: 2
};

export const PLATFORM_DETAIL_SECTION_ID_LIST = (t) => ({
  [PLATFORM_DETAIL_COMMON_INFO_KEY_LIST.COMMON_INFO]: {
    navTitle: t("admin.business_information.edit.basic_information"),
    sectionId: "company-name"
  },
  [PLATFORM_DETAIL_COMMON_INFO_KEY_LIST.SETTING_FEE]: {
    navTitle: t("admin.business_information.edit.close_date"),
    sectionId: "company-name"
  }
});

// format detail data

export const formatDataPlatformDetail = (resData) => {
  const phoneFormat = formatGetPhoneNumberWithPrefix(
    resData?.contact?.phone_number.main
  );

  return {
    company_name: resData?.platform_operator_name || '',
    postcode: resData?.address?.postcode || '',
    phone_number: phoneFormat || '',
    email: resData?.contact?.email_address?.main || '',
    email_support: resData?.contact?.email_address?.support || '',
    date_established: resData?.date_established || '',
    address_city: resData?.address?.city || '',
    address_street: resData?.address?.street || '',
    address_address: resData?.address?.address || '',
    apartment_name: resData?.address?.apartment || '',
    address_country: resData?.address?.country || '',
    registration_number: resData?.registration_number || '',
    paypal: resData?.accounts?.paypal?.account || '',
    bank_name: resData?.accounts?.bank?.bank_name || '',
    branch_name: resData?.accounts?.bank?.branch_name || '',
    account_number: resData?.accounts?.bank?.account_number || '',
    account_name: resData?.accounts?.bank?.account_name || '',
    billing_cutoff_date:
      resData?.billing_cutoff_date || BILLING_CUTOFF_DATE.DATE_5,
    payment_cutoff_date:
      resData?.payment_cutoff_date || PAYMENT_CUTOFF_DATE.DATE_2,
  };
};

export const getPlatformDetailData = ({ setFormValues, id, callbackSuccess, callbackError }) => {
  CommonService.getModelList(
    convertDynamicPath(ICTL_API_ENDPOINT.GET_PLATFORM_DETAIL, {
      id
    })
  )
    .then((response) => {
      if (response.status === EResponseStatus.SUCCESS) {
        const resData = response?.data?.data || {};
       setFormValues(formatDataPlatformDetail(resData));
        if (callbackSuccess) {
          callbackSuccess(resData);
        }
      }
    })
    .catch(() => {
      if (callbackError) {
        callbackError();
      }

      setFormValues({});
      // setErrorMess(t("common.message.cannot_display_data"));
    })
    .finally(() => {
      // setIsLoading(false);
    });
};

export const formatDataEditPlatform = (payload, id) => {
  const {
    company_name,
    billing_cutoff_date,
    date_established,
    payment_cutoff_date,
    registration_number,
    account_name,
    account_number,
    address_address,
    address_city,
    address_country,
    address_street,
    bank_name,
    branch_name,
    email,
    paypal,
    phone_number,
    postcode,
    apartment_name,
    email_support
  } = payload || {};

  const phoneFormat = formatUpdatePhoneNumberData(phone_number);

  return {
    platform_operator_id: id,
    platform_operator_name: company_name,
    billing_cutoff_date: Number(billing_cutoff_date),
    date_established,
    payment_cutoff_date: Number(payment_cutoff_date),
    registration_number,
    address: {
      address: address_address,
      city: address_city,
      country: address_country,
      postcode,
      street: address_street,
      apartment: apartment_name,
    },
    accounts: {
      paypal: {
        account: paypal
      },
      bank: {
        account_name,
        account_number,
        bank_name,
        branch_name
      }
    },
    contact: {
      phone_number: {
        main: phoneFormat
      },
      email_address: {
        main: email,
        support: email_support
      }
    }
  }
};

export const updatePlatformDetail = ({
  data,
  id,
  callbackSuccess,
  callbackError,
}) => {
  CommonService.postModel(
    ICTL_API_ENDPOINT.UPDATE_PLATFORM_DETAIL,
    formatDataEditPlatform(data, id)
  )
  .then((response) => {
    if (response.status === EResponseStatus.SUCCESS) {
      const resData = response?.data?.data || {};
      if (callbackSuccess) {
        callbackSuccess(resData);
      }
    }
  })
  .catch(() => {
    if (callbackError) {
      callbackError();
    }
    // setErrorMess(t("common.message.cannot_display_data"));
  }).finally(() => {
    // setIsLoading(false);
  });
};