import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import {
  SortedAscendingIcon,
  SortedDescendingIcon,
  UnsortIcon,
} from "app/components/DatagridIcon";
import { formatNumber, trimSpaceFirstCharacter } from "app/utils";
import { PER_PAGE } from "app/constant";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

function DetailList() {
  const { t } = useTranslation();
  const isLoading = false;
  const errorMess = false;
  const [page, setPage] = useState(1);

  // data grid
  const [data, setData] = useState([]);
  const columns = [
    {
      field: "influencerName",
      headerName: "インフルエンサー名",
      sortable: true,
      headerClassName: "custom",
      minWidth: 300,
      flex: 1,
      valueGetter: (row) => {
        return trimSpaceFirstCharacter(row.value);
      },
    },
    {
      field: "projectTitle",
      headerName: "案件名",
      sortable: true,
      headerClassName: "custom",
      width: 300,
      valueGetter: (row) => {
        return trimSpaceFirstCharacter(row.value);
      },
    },
    {
      field: "billingAmount",
      headerName: "請求金額",
      sortable: true,
      headerClassName: "custom",
      width: 200,
      valueGetter: (params) => {
        const price = params?.row?.billingAmount;
        return `${t("operator.pay.list.price")} ${price}`;
      },
    },
    {
      field: "unitPrice",
      headerName: "単価",
      sortable: true,
      headerClassName: "custom",
      width: 200,
      valueGetter: (params) => {
        const price = params?.row?.unitPrice;
        return `${t("operator.pay.list.price")} ${price}`;
      },
    },
    {
      field: "no",
      headerName: "件数",
      headerClassName: "custom",
      sortable: false,
      width: 150,
    },
    {
      field: "claimNumber",
      headerName: "請求番号",
      headerClassName: "custom",
      sortable: false,
      width: 250,
    },
    {
      field: "setting",
      headerName: "",
      sortable: false,
      headerClassName: "custom",
      width: 100,
      renderCell: () => {
        return (
          <button
            onClick={() => {}}
            type="button"
            className="w-8 h-8 flex items-center justify-center"
          >
            <i className="text-xl fa-solid fa-gear" />
          </button>
        );
      },
    },
  ];

  useEffect(() => {
    const list = [];
    for (let i = 1; i < 10; i += 1) {
      list.push({
        detail_id: i,
        influencerName: `インフルエンサー${i}`,
        projectTitle: `９月限定プラン紹介${i}`,
        billingAmount: i * 10000,
        unitPrice: i * 100,
        no: i,
        claimNumber: `B-M202209-I00${i}`,
      });
    }
    setData(list);
  }, []);

  const [value, setValue] = useState(null);

  const onPaginationModelChange = (model) => {
    const newPage = model?.page;
    setPage(newPage + 1);
  };

  return (
    <div className="px-4 py-4 flex-1">
      <div className="max-w-[568px] ml-3 mt-5">
        <div className="my-10">
          <p className="text-[17px] font-bold mb-1">
            {t("operator.pay.list.select_month")}
          </p>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="w-full">
              <DatePicker
                views={["year", "month"]}
                inputFormat="YYYY/MM"
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </LocalizationProvider>
        </div>

        <div className="mb-10">
          <h2 className="text-2xl font-bold">
            {t("operator.pay.list.price")} 22,000
          </h2>
          {/* <p>合計請求金額</p> */}
          <p>2022年9月の予約請求</p>
        </div>

        <div className="mb-10">
          <div className="flex flex-wrap items-center gap-8">
            <div className="flex flex-col font-bold">
              <p>{`${t("operator.pay.list.price")} ${formatNumber(0)}`}</p>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label={t("operator.pay.list.deposited")}
                />
              </FormGroup>
            </div>
            <div className="flex flex-col font-bold">
              <p>{`${t("operator.pay.list.price")} ${formatNumber(1200)}`}</p>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label={t("operator.pay.list.deposited")}
                />
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-8">
        <h2 className="font-bold text-2xl">
          {t("operator.pay.list.count_request")}
        </h2>
      </div>

      {/* Table Group */}
      <div className="mb-8">
        {errorMess ? (
          <p className="text-[20px] font-[600] py-3">{errorMess}</p>
        ) : (
          <div className="w-full">
            <DataGrid
              autoHeight
              getRowId={(item) => item.detail_id}
              rows={data}
              columns={columns}
              loading={isLoading}
              rowCount={data.length}
              paginationMode="server"
              checkboxSelection
              disableColumnMenu
              slots={{
                columnUnsortedIcon: UnsortIcon,
                columnSortedDescendingIcon: SortedDescendingIcon,
                columnSortedAscendingIcon: SortedAscendingIcon,
              }}
              paginationModel={{
                pageSize: PER_PAGE,
                page: page - 1,
              }}
              pageSizeOptions={[PER_PAGE]}
              onPaginationModelChange={(model) => {
                onPaginationModelChange(model);
              }}
              localeText={{
                noResultsOverlayLabel: t("common.message.no_data_display"),
                noRowsLabel: t("common.message.no_data_display"),
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default DetailList;
