export default {
  list: {
    title: "案件名稱",
    total: "通知 {{value}}件數",
    date: "發布期間",
    notification_group: "發布對象",
    noti: "通知",
    item: "名稱",
    create_notification: "建立通知",
  },
  detail: {
    title: "案件名稱",
    message: "內容",
    date: "發佈時間",
    notification_group: "發布對象",
    delivery_date: "開始",
    end_date: "終了",
    customer: "業者",
    influencer: "利用客",
    svm: "網紅",
  },
};
