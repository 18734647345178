import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "./en";
import translationJA from "./ja";
import translationCN from "./cn";

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  ja: {
    translation: translationJA,
  },
  cn: {
    translation: translationCN,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem("i18nextLng") || "ja",
    resources,
    detection: { caches: ["localStorage"] }, // set where to save user language
    fallbackLng: "ja",
  });

export default i18n;
