// v-005-001
import React, {
  createContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SettingFee from "app/ictl/pages/BusinessOperatorDetail/SettingFee";
import CommonInfo from "app/ictl/pages/BusinessOperatorDetail/CommonInfo";
import MainContent from "app/components/MainContent";
import LayoutDefault from "app/ictl/layouts/LayoutDefault";
import {
  formatDataPlatformDetail,
  getPlatformDetailData,
  PLATFORM_DETAIL_COMMON_INFO_KEY_LIST,
  PLATFORM_DETAIL_SECTION_ID_LIST,
  updatePlatformDetail,
} from "../../../utils/page/platform";
import SuccessDialog from "../../../components/SuccessDialog";
import ErrorDialog from "../../../components/ErrorDialog";
import Loading from "../../../operator/components/Loading";
import DetailListContent from "../../../components/DetailListContent";

export const PlatFormDetailContext = createContext();

function BusinessOperatorDetail() {
  const groupId = useSelector((state) => state?.auth?.user?.group_id);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [message, setMessage] = useState("");
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    getPlatformDetailData({ setFormValues, id: groupId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openSuccessDialog = (mess) => {
    setOpenSuccess(true);
    setMessage(mess || t("common.message.update_success"));
  };

  const openErrorDialog = (mess) => {
    setOpenError(true);
    setMessage(mess || t("common.message.update_fail"));
  };

  const onEditPlatForm = useCallback(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    (data) => {
      setLoading(true);
      updatePlatformDetail({
        data,
        id: groupId,
        callbackSuccess: (resData) => {
          setLoading(false);
          openSuccessDialog();
          setFormValues(formatDataPlatformDetail(resData));
        },
        callbackError: () => {
          openErrorDialog();
          getPlatformDetailData({ setFormValues, id: groupId });
          setLoading(false);
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [groupId]
  );

  const platFormDetailContextProvider = useMemo(
    () => ({
      formValues,
      id: groupId,
      setFormValues,
      onEditPlatForm,
    }),
    [formValues, setFormValues, onEditPlatForm, groupId]
  );

  const renderRightContent = () => {
    return (
      <div className="w-full">
        {Object.keys(formValues).length > -1 && (
          <div className="facility-info mb-10 text-[14px]">
            <p
              id={
                PLATFORM_DETAIL_SECTION_ID_LIST(t)[
                  PLATFORM_DETAIL_COMMON_INFO_KEY_LIST.COMMON_INFO
                ]?.sectionId
              }
              className="text-[18px] font-bold"
            >
              {t("admin.businessInformation.edit.basic_information")}
            </p>
            <CommonInfo
              childSectionId={
                PLATFORM_DETAIL_SECTION_ID_LIST(t)[
                  PLATFORM_DETAIL_COMMON_INFO_KEY_LIST.COMMON_INFO
                ]?.childSectionId
              }
            />

            <SettingFee
              sectionId={
                PLATFORM_DETAIL_SECTION_ID_LIST(t)[
                  PLATFORM_DETAIL_COMMON_INFO_KEY_LIST.SETTING_FEE
                ]?.sectionId
              }
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <PlatFormDetailContext.Provider value={platFormDetailContextProvider}>
      <LayoutDefault showFooter showHeader pin={5}>
        <Loading open={!!loading} />
        <MainContent>
          <DetailListContent
            rightContent={renderRightContent()}
            title={t("admin.businessInformation.edit.management_information")}
          />
          <SuccessDialog
            message={message}
            open={openSuccess}
            onClose={() => setOpenSuccess(false)}
            onOK={() => setOpenSuccess(false)}
          />
          <ErrorDialog
            message={message}
            open={openError}
            onClose={() => setOpenError(false)}
            onOK={() => setOpenError(false)}
          />
        </MainContent>
      </LayoutDefault>
    </PlatFormDetailContext.Provider>
  );
}

export default BusinessOperatorDetail;
