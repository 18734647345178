export const MIN_LIMIT = {
  // default
  DEFAULT: 0,
  ADULT: 0,
  POST_CODE: 5,
};

export const ITEM_PER_SCROLL = 20;

export const MAX_LIMIT = {
  // default
  INPUT_TEXT: 100,
  INPUT_TEXT_AREA: 5000,

  // other
  FAMILY_NAME: 64,
  PHONE_NUMBER: 20,
  EMAIL: 64,
  NUMBER_OF_USERS: 100,
  INFANT: 10,
  PET: 10,
};

export const GENDER = {
  MALE: "male",
  FEMALE: "female",
  OTHER: "other",
};

export const GENDER_TEXT = (t) => {
  return {
    [GENDER.MALE]: t("common.male"),
    [GENDER.FEMALE]: t("common.female"),
    [GENDER.OTHER]: t("common.other"),
  };
};

export const FORMAT_DATE = {
  MM_DD: "MMMMDo",
  YYYY_MMMM_Do: "YYYY年MMMMDo",
  YYYY_MMMM_Do1: "YYYY年MMMMDo HH:mm:ss",
  YYYY_MM_DD: "YYYY-MM-DD",
  YYYY_M_D_HH_MM: "YYYY年M月D HH:mm",
  YYYY_M_Do_HH_MM: "YYYY年M月D日 HH:mm",
  YYYY_M_D: "YYYY年M月D",
  YYYY_MM_D: "YYYY年M月D日",
  YYYY_M_Do: "YYYY年M月D日",
  HH_mm: "HH:mm",
  dd_DAY_OF_WEEK: "(dd)",
  HH: "HH",
  MM: "mm",
  YYYY_MM_DDT: "YYYY-MM-DD[T]HH:mm:ss",
  YYYY_MM_DDTM: "YYYY-MM-DD[T]HH:mm",
  YYYY_MM_DDTS: "YYYY-MM-DD[T]00:00:00",
  YYYY_MM_DDTE: "YYYY-MM-DD[T]23:59:59",
  YYY_M_Do: "yyy年MM月dd日",
};

export const ETabValue = {
  REALTIME: 0,
  BOOKING: 1,
  ORDER: 2,
};

export const Roles = {
  REPRESENT: 1,
  ADMIN: 2,
  MEMBER: 3,
  INFLUENCER: 4,
};

export const Group = {
  ITCL: 1,
  FACILITY: 2,
  CUSTOMER: 3,
  INFLUENCER: 4,
};

export const EResponseStatus = {
  SUCCESS: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
};

export const EFacilityStatus = {
  PUBLISH: 2,
  PRIVATE: 4,
  STAFF_ONLY: 3,
  DRAFT: 1,
  TRASH: 5,
};

export const EProjectRequest = {
  REQUEST: 1,
  NOT_REQUEST: 0,
};

export const EProjectStatus = {
  PUBLISH: 2,
  PRIVATE: 4,
  TRASH: 5,
};

export const APPLIED_PROJECT_STATUS = {
  WAIT_APPROVAL_RECRUITMENT: "WAIT_APPROVAL_RECRUITMENT",
  IN_PROGRESS: "IN_PROGRESS",
  WAIT_APPROVAL_FINISHED: "WAIT_APPROVAL_FINISHED",
  COMPLETED: "COMPLETED",
  DECLINED: "DECLINED",
  REJECT_REPORT: "REJECT_REPORT",
  CANCEL_TRANSACTION: "CANCEL_TRANSACTION",
};

export const INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS = {
  REVIEW: 1,
  DECLINED: 2,
  ACCEPTED: 3,
  REJECTED: 4,
  CANCELED: 5,
  FINISHED: 6,
  APPROVED_DELIVERABLE: 7,
  DECLINE_DELIVERABLE: 8,
};

export const DELIVERABLE_STATUS = {
  WIP: 1,
  REVIEW: 2,
  APPROVED: 3,
  REJECTED: 4,
};

export const PROJECT_APPLIED_TYPE = {
  APPLIED: 1,
  SPECIAL_OFFER: 2,
};

export const EProjectReward = {
  FIXED_PRICE: "fixed_price",
  FREE: "instead_of_fee",
};

export const EProjectPrMethod = {
  VIDEO: "video",
  IMAGE: "image",
  ALL: "image, video",
};

export const EProjectSNS = {
  INSTAGRAM: "instagram",
  TIKTOK: "tiktok",
  TWITTER: "twitter",
  FACEBOOK: "facebook",
  YOUTUBE: "youtube",
};

export const EFacilityType = {
  RESTAURANT: 1,
  ACCOMMODATION: 2,
};

export const GENDER_VALUE = {
  MALE: "male",
  FEMALE: "female",
  OTHER: "other",
};

export const EFileType = {
  IMAGE: "image",
  VIDEO: "video",
  PDF: "pdf",
  SVG: "svg",
  MP4: "mp4",
};

export const PAY_STATUS = {
  UNPAID: 1,
  CONFIRMING: 3,
  PAID: 2,
};

export const PAY_CATEGORY = {
  INITIAL_COST: 1,
  MONTHLY_USAGE_PRICE: 2,
  AD_SERVICE_CHARGE: 3,
};

export const ROOM_USAGE_STATUS = {
  IN_USE: "IN_USE",
  EXPIRED: "EXPIRED",
  INCOMING: "INCOMING",
};

export const COUNTRY_LIST = {
  JA: "jp",
};

export const languageText = (t) => ({
  [COUNTRY_LIST.JA]: t("common.japan"),
});

export const PAYMENT_METHOD = {
  CASH: 1,
  CARD: 2,
  QR: 3,
};

export const RolesEnum = {
  AGENT: 1,
  MANAGER: 2,
  STAFF: 3,
};

export const SOCIAL = {
  INSTAGRAM: "instagram",
  TIKTOK: "tiktok",
  YOUTUBE: "youtube",
  FACEBOOK: "facebook",
  TWITTER: "twitter",
};

export const ORDER_STATUS = {
  GETTING_READY: 1,
  PROVIDED: 2,
  CANCEL: 3,
};

export const CALENDER_LOCALE = (t) => ({
  Today: t("common.days.today"),
  Yesterday: t("common.days.yesterday"),
  "This Week": t("common.days.thisWeek"),
  "Last Week": t("common.days.lastWeek"),
  "This Month": t("common.days.thisMonth"),
  "Last Month": t("common.days.lastMonth"),
});

export const PER_PAGE = 20;

export const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

export const emailRegex =
  /^((?=[^@._+-])(([A-Za-z0-9._+-]))*@((([A-Za-z0-9])*([._-])?)([._-]))+((([A-Za-z0-9])){1,}))$/;
// /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,64}))$/;

// eslint-disable-next-line
export const postCodeRegexInput = /^[0-9-]+$/;
export const onlyNumberRegexNoChar = /^[0-9]*$/;
export const onlyNumberRegex = /^[0-9]+$/;
export const integerRegex = /^-?\d+$/;
export const decimalRegex = /^-?\d*\.?\d*$/;
export const accountNumberRegex = /^[0-9]{1,7}$/;
export const invoiceNumberRegex = /^[0-9]{1,14}$/;

export const postcodeRegex =
  /^(?:(?=[a-z0-9-]{8}$)[a-z0-9]*-[a-z0-9]*|[a-z0-9]{7})$/;

export const passwordRegex =
  /^(?=.*[0-9])(?=.*[A-Za-z])(?=.*[!%@#&\-()_[\]{}:;`'",.+*?\\/~$^=<>|\\])[0-9A-Za-z!%@#&\-()_[\]{}:;`'",.+*?\\/~$^=<>|\\]{8,64}$/;
// /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#+?&])[A-Za-z\d@$!%*#?&]{8,64}$/; // minimum 8 characters, at least one letter, one number and one special characters

export const INVENTORY_AVAILABILITY = {
  GETTING_READY: 1,
  READY: 2,
  RESERVED: 3,
};

export const INVENTORY_TYPE = {
  SEAT: 1,
  ROOM: 2,
  CHECKIN_DEVICE: 3,
};

export const STATEMENT_STATUS = {
  RESERVED: 1,
  NOT_GIVE_KEY: 2,
  STAYING: 3,
  OVER_TIME: 4,
  LEFT: 5,
  CANCELED: 6,
};

export const FOCUSED_INPUT_KEYS = {
  START_DATE: "startDate",
  END_DATE: "endDate",
};

export const CALL_API_STATUS = {
  IDLE: "idle",
  LOADING: "loading",
  ERROR: "error",
  SUCCESS: "success",
};

export const allLanguages = [
  {
    code: "ja",
    title: "日本語",
    detail_title: "日本",
  },
  {
    code: "en",
    title: "English",
    detail_title: "United States",
  },
  {
    code: "ko",
    title: "한국어",
    detail_title: "대한민국",
  },
  {
    code: "zh",
    title: "繁體中文",
    detail_title: "台灣",
  },
];

export const languages = [
  {
    code: "en",
    title: "English",
  },
  {
    code: "zh",
    title: "中文",
  },
  {
    code: "ja",
    title: "日本語",
  },
  {
    code: "ko",
    title: "한국어",
  },
];

export const INVENTORY_AVAILABILITY_COLOR = {
  GETTING_READY: "#6E6D6D",
  READY: "#BFBFBF",
  RESERVED: "#8EB7FF",
  OVER_TIME: "#F8A5F2",
};

export const FACILITY_TYPE = {
  RESTAURANT: 1,
  ACCOMMODATION: 2,
};

export const SALES_SLIPS_STATUS = {
  ORDERABLE: 1,
  CHECK: 2,
  PAID: 3,
  ISSUED: 4,
};

export const STATUS_PAY = {
  UNPAID: 1,
  CONFIRMING: 3,
  PAID: 2,
};

export const maximumFile = 50;
export const limitFileSize = 5 * 1024 * 1024; // 5mb
export const videoLimitFileSize = 50 * 1024 * 1024; // 50mb
export const minimumFileSize = 51200;
export const mediaUploadAllowedExtensions =
  /(\.jpg|\.jpeg|\.png|\.heic|\.mp4)$/i;
export const isVideoRegex = /(\.mp4)$/i;

export const LIMIT_VIDEO = 3;

export const MAX_MEDIA_QUANTITY = 50;

export const MAX_BANNER_QUANTITY = 10;

export const FILE_ERRORS = {
  INVALID_TYPE: 1,
  IMG_OVER_SIZE: 2,
  TOO_SMALL: 3,
  INVALID_TYPE_ONLY_IMAGE: 4,
  VIDEO_OVER_SIZE: 5,
  VIDEO_QUANTITY_EXCEED: 6,
  LIMIT: 7,
};

export const FILE_ERRORS_TEXT = (t) => ({
  [FILE_ERRORS.INVALID_TYPE]: t("common.message.wrong_format_file", {
    format: "PNG、JPEG、JPG、HEIC、MP4",
  }),
  [FILE_ERRORS.INVALID_TYPE_ONLY_IMAGE]: t("common.message.wrong_format_file", {
    format: "PNG、JPEG、JPG、HEIC",
  }),
  [FILE_ERRORS.IMG_OVER_SIZE]: t("common.message.oversize_image", {
    size: limitFileSize / 1024 / 1024,
  }),
  [FILE_ERRORS.TOO_SMALL]: t("common.message.fileTooSmall"),
  [FILE_ERRORS.VIDEO_OVER_SIZE]: t("common.message.oversize_video", {
    size: videoLimitFileSize / 1024 / 1024,
  }),
  [FILE_ERRORS.VIDEO_QUANTITY_EXCEED]: t("common.message.invalidFileNumber"),
  [FILE_ERRORS.LIMIT]: t("common.message.at_least_5"),
});
export const VISIBILITY_AND_STATUS = {
  DRAFT: 1,
  PUBLISH: 2,
  STAFF_ONLY: 3,
  PRIVATE: 4,
  TRASH: 5,
};

export const FEES_PAY = {
  INITIAL_COST: 1,
  MONTHLY_PLAN: 2,
  REWARDS: 3,
};

export const TABPAYMENTSHOW = {
  PAYMENT_INFLUENCE: 1,
  PAYMENT_INFLUENCE_DETAIL: 2,
  PAYMENT_HISTORY: 3,
  PAYMENT_HISTORY_DETATIL: 4,
  PAYMENT_WAITING: 5,
};

export const FORMAT_TIME_TO_SEND = "YYYY-MM-DD HH:mm:ss";
export const DATE_TIME_FORMAT_API = "YYYY-MM-DD HH:mm:ss";
export const TIME_FORMAT_API = "HH:mm:ss";

export const NUMBER_GUESTS_RANGE = {
  adult: { min: 0, max: 100 },
  child: { min: 0, max: 100 },
  infant: { min: 0, max: 10 },
  pet: { min: 0, max: 10 },
};

export const DAYJS_STATUS = {
  DAY_FORMAT_INVALID: "Invalid Date",
};

export const fomatPrice = (amount) => {
  return Math.trunc(Number(amount)).toLocaleString("ja-JP");
};

export const FILTER_CACHING_KEY = {
  FACILITY_LIST: "facility-list-filter",
  INVENTORY_LIST: "inventory-list-filter",
  PRODUCT_LIST: "product-list-filter",
  TAG_LIST: "tag-list-filter",
  MEMBER_LIST: "member-list-filter",
  INFLUENCER_LIST: "influencer-list-filter",
  AD_PROJECT_LIST: "ad-project-filter",
  STAFF_LIST: "staff-filter",
  CUSTOMER_LIST: "customer-list",
  MANAGER: "manager",
  BOOKING_TIMELINE_LIST: "booking-timeline-list-filter",
  DASHBOARD: "dash-board",
  PERFORMANCE_REVIEW: "performance_review",
  ICTL_PAY: "ictl_pay",
};

export const SORT = {
  DESC: "desc",
  ASC: "asc",
};

export const ORDER_PRODUCT_STATUS = {
  GETTING_READY: 1,
  PROVIDED: 2,
  CANCELED: 3,
};

export const toastDefaultOptions = {
  position: "bottom-left",
  autoClose: 2000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
  theme: "light",
};

export const LIST_SNS_CONNECTED = [
  {
    name: "Facebook",
    value: "facebook_influencer",
  },
  {
    name: "Google",
    value: "google_influencer",
  },
  {
    name: "Tiktok",
    value: "tiktok_influencer",
  },
  {
    name: "LINE",
    value: "line_influencer",
  },
];

export const ageGroupLst = [
  {
    value: "10代",
    optionName: "10代",
  },
  {
    value: "20代",
    optionName: "20代",
  },
  {
    value: "30代",
    optionName: "30代",
  },
  {
    value: "40代",
    optionName: "40代",
  },
  {
    value: "50代",
    optionName: "50代",
  },
  {
    value: "60代以上",
    optionName: "60代以上",
  },
];

export const lstLanguage = [
  {
    id: 1,
    title: "日本語",
    note: "日本",
    value: "ja",
    locale: "ja",
    currencyFormat: "ja-JP",
  },
  {
    id: 2,
    title: "English",
    note: "United Kingdom",
    value: "en",
    locale: "en",
    currencyFormat: "en-US",
  },
  {
    id: 3,
    title: "繁體中文",
    note: "美國",
    value: "cn",
    locale: "zh-cn",
    currencyFormat: "zh-CN",
  },
  // {
  //   id: 4,
  //   title: "한국어",
  //   note: "대한민국",
  //   value: "ko-KR",
  // },
];
