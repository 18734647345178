// v-007-002
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useCoreComponentList from "./useCoreComponentDetail";
import TableDetail from "./TableDetail";
import SuccessDialog from "../../../../../components/SuccessDialog";
import { setHeaderPayment } from "../../../../../redux/ictl/headerPaymentReducer";
import { TABPAYMENTSHOW, fomatPrice } from "../../../../../constant";
import Path from "../../../../route/Path";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function PaymentInfluentceDetail(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userInfluenceId, setUserInfluenceId } = props;
  const [
    items,
    setPage,
    setPerPage,
    total,
    perPage,
    page,
    infomation,
    updatePay,
    openSuccess,
    setOpenSuccess,
    showWarning,
    openWarning,
    setOpenWarning,
    nickname,
  ] = useCoreComponentList({ userInfluenceId });

  const headCells = [
    {
      id: "nameProjec",
      align: "left",
      label: t("admin.payment.detail.nameProjec"),
    },
    {
      id: "CompensationAmount",
      align: "right",
      label: t("admin.payment.detail.CompensationAmount"),
    },
    {
      id: "unitPrice",
      align: "right",
      label: t("admin.payment.detail.unitPrice"),
    },
    {
      id: "number",
      align: "center",
      label: t("admin.payment.detail.number"),
    },
    {
      id: "compensationDate",
      align: "center",
      label: t("admin.payment.detail.compensationDate"),
    },
  ];
  const filterStatus = (status) => {
    return status === 1;
  };

  const changeTabToList = () => {
    setUserInfluenceId(null);
    dispatch(setHeaderPayment(TABPAYMENTSHOW.PAYMENT_INFLUENCE));
  };

  const handeRedirect = (e) => {
    e.preventDefault();
    setUserInfluenceId(null);
    dispatch(setHeaderPayment(TABPAYMENTSHOW.PAYMENT_INFLUENCE));
    navigate(`${Path.influencer_list}/${userInfluenceId}`);
  };
  return (
    <div className="flex-1">
      <div
        className={
          infomation?.payment_status?.some(filterStatus) === true
            ? "max-w-[580px]"
            : "max-w-[300px]"
        }
      >
        <div className="mb-10">
          <button
            type="button"
            className="rounded"
            onClick={() => changeTabToList()}
          >
            <i className="fa-solid fa-chevron-left" />
          </button>
        </div>
        <div className="mb-10 ">
          <a
            className="font-bold text-2xl underline text-blue-600/100"
            onClick={(e) => handeRedirect(e)}
            href="#"
          >
            {infomation?.nickname || ""}
          </a>
        </div>

        <div className="mb-10">
          <p className="font-bold">
            {t("admin.payment.listPaymentInfluence.CumulativeTotalAmount")}
          </p>
          <div>
            <div
              className={
                infomation?.payment_status?.some(filterStatus) === true
                  ? "columns-3xs ..."
                  : "columns-2xs ..."
              }
            >
              <span className="font-bold mb-4 text-3xl">
                {t("admin.pay.list.price")}{" "}
                {infomation?.fee ? fomatPrice(infomation.fee) : 0}
              </span>
              <p className="font-bold text-xs">
                ({t("admin.pay.consumptionTax")}{" "}
                {infomation?.tax ? fomatPrice(infomation.tax) : 0})
              </p>
              {infomation?.payment_status?.some(filterStatus) === true && (
                <button
                  type="button"
                  className="sm:ml-[100px] hover:underline focus:outline-none text-white bg-rose-500 hover:bg-rose-600 focus:ring-rose-300 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 "
                  onClick={() =>
                    showWarning(
                      userInfluenceId,
                      infomation?.accounts?.paypal.account,
                      infomation?.nickname
                    )
                  }
                >
                  {t("admin.payment.listPaymentInfluence.updatePaid")}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mb-10">
        <p className="font-bold mb-4">
          {t("admin.payment.detail.totalItem", { value: total })}
        </p>
      </div>
      <TableDetail
        getComparator={getComparator}
        headCells={headCells}
        rows={items}
        perPage={perPage}
        page={page}
        setPerPage={setPerPage}
        setPage={setPage}
        total={total}
      />
      <SuccessDialog
        message={t("common.message.update_success")}
        open={openSuccess}
        onClose={() => setOpenSuccess(false)}
        onOK={() => setOpenSuccess(false)}
      />
      <SuccessDialog
        message={`${t("admin.payment.warningUpdate", { value: nickname })}`}
        open={openWarning}
        onClose={() => setOpenWarning(false)}
        onOK={() => updatePay()}
      />
    </div>
  );
}
