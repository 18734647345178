import { createSlice } from "@reduxjs/toolkit";

const initialState = null;

const slice = createSlice({
  name: "social",
  initialState,
  reducers: {
    setSocial: (state, action) => {
      return action.payload;
    },

    setSocialNull: (state, action) => {
      return action.payload;
    },
  },
});

export const { setSocial, setSocialNull } = slice.actions;

export default slice.reducer;
