import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { ja } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { CALENDER_LOCALE, FORMAT_DATE } from "app/constant";
import moment from "moment";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

function CustomDateRange({
  showCheckbox,
  onChange,
  innitialValue,
  disabledPastDays,
  disabledSetting,
  preview,
}) {
  const { t } = useTranslation();

  // set date
  const startDate = innitialValue?.startDate
    ? moment.utc(innitialValue?.startDate).format(FORMAT_DATE.YYYY_MM_DDT)
    : null;
  const endDate = innitialValue?.endDate
    ? moment.utc(innitialValue?.endDate).format(FORMAT_DATE.YYYY_MM_DDT)
    : null;
  const unspecifiedDate = innitialValue?.unspecified_date;
  const initValue = {
    startDate: new Date(startDate),
    endDate: new Date(endDate),
  };
  const [dateRange, setDateRange] = useState(
    initValue || {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    }
  );

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if ((!startDate && !endDate) || unspecifiedDate) {
      setIsChecked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = (ranges) => {
    const { selection, range1 } = ranges || {};
    const payload = { ...(selection || range1), key: "selection" };
    onChange(payload);
    setDateRange(payload);
  };

  useEffect(() => {
    const list = document.querySelector(
      ".custom-date-range>.rdrDefinedRangesWrapper>.rdrStaticRanges"
    )?.childNodes;
    const myArray = Array.from(list);
    myArray?.forEach((node) => {
      const nodeChild = node?.querySelector(".rdrStaticRangeLabel");
      nodeChild.innerHTML =
        CALENDER_LOCALE(t)[nodeChild.innerHTML] || nodeChild.innerHTML;
    });
  }, [t]);

  const handleChange = (event) => {
    const { checked } = event.target;
    setIsChecked(checked);
    const payload = {
      startDate: new Date(),
      endDate: new Date(),
      unspecifiedDate: checked,
      key: "selection",
    };
    setDateRange(payload);
    onChange(payload);
  };

  return (
    <div>
      {preview && (
        <div className="pl-[8px] text-gray font-semibold mb-[8px]">
          {disabledSetting
            ? t("common.other1")
            : `${moment(dateRange?.startDate).format(FORMAT_DATE.MM_DD)}~
          ${moment(dateRange?.endDate).format(FORMAT_DATE.MM_DD)}`}
        </div>
      )}
      <div className="flex flex-wrap">
        <DateRangePicker
          className="custom-date-range w-[300px] !mr-5"
          ranges={disabledSetting ? [{ key: "selection" }] : [dateRange]}
          onChange={handleSelect}
          locale={ja}
          startDatePlaceholder={t("開始日")}
          endDatePlaceholder={t("終了日")}
          minDate={new Date()}
          // disable past days
          disabledDay={(current) => {
            const yesterday = moment().subtract(1, "days");
            if (
              disabledSetting ||
              isChecked ||
              (moment(current).isBefore(yesterday) && disabledPastDays)
            ) {
              return true;
            }
            return false;
          }}
        />
        {showCheckbox && (
          <FormGroup className="w-[120px]">
            <FormControlLabel
              checked={isChecked}
              onChange={(event) => handleChange(event)}
              control={<Checkbox />}
              label={t("common.other1")}
              className="whitespace-nowrap"
            />
          </FormGroup>
        )}
      </div>
    </div>
  );
}

export default CustomDateRange;
