import React from "react";

function DetailListContent(props) {
  const { leftContent, rightContent, title } = props || null;
  return (
    <div className="mt-[24px] flex-1">
      <div className="grid grid-cols-12 gap-x-[24px] gap-y-[40px] facility-detail h-full">
        {/* left content */}
        <div className="col-span-12 lg:col-span-5 xl:col-span-4">
          <div className="lg:w-[70%]">
            {title && (
              <p className="text-3xl font-bold mb-5">{title}</p>
            )}
            {leftContent && (
              <div className="relative h-full">{leftContent}</div>
            )}
          </div>
        </div>

        {/* Right content */}
        {rightContent && (
          <div className="col-span-12 lg:col-span-7 xl:col-span-8 flex flex-col">
            {rightContent}
          </div>
        )}
      </div>
    </div>
  );
}

export default DetailListContent;
