// v-010-002
import React, { useEffect, useRef, useState } from "react";
import ictlPath from "app/ictl/route/Path";
import MainContent from "app/components/MainContent";
import DetailListContent from "app/components/DetailListContent";
import LayoutDefault from "app/ictl/layouts/LayoutDefault";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { checkValueChange, logout } from "app/utils";
import { useNavigate, useParams } from "react-router-dom";
import AppMessage from "app/components/AppMessage";
import Button, { BUTTON_STYLE_TYPE } from "app/operator/components/Button";
import "dayjs/locale/ja";
import { EResponseStatus } from "app/constant";
import SuccessDialog from "app/components/SuccessDialog";
import ErrorDialog from "app/components/ErrorDialog";
import CommonService from "app/services/api/CommonService";
import { ICTL_API_ENDPOINT } from "app/services/constant";
import { useDispatch, useSelector } from "react-redux";
import { setActive } from "app/redux/blockUIReducer";
import {
  formatGetAccountDetailData,
  updateOrCreateAccount,
  ACCOUNT_ACTION,
  accountSchema,
} from "app/utils/page/account_ictl";
import StaffService from "app/services/api/operatorService/StaffService";
import cx from "classnames";
import AccountPhone from "./AccountPhone";
import AccountEmail from "./AccountEmail";
import AccountName from "./AccountName";
import AccountAvatar from "./AccountAvatar";

function AccountForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openCancel, setOpenCancel] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [message, setMessage] = useState("");
  const [action, setAction] = useState("");
  const params = useParams();
  const { accountId } = params;
  const formikRef = useRef(null);
  const user = useSelector((state) => state.auth.user);

  const initialAccountValues = {
    account_family_name: "",
    account_first_name: "",
    account_email: "",
    account_phone: "",
    account_avatar: "",
  };

  const [formValues, setFormValues] = useState(initialAccountValues);

  // get account detail
  useEffect(() => {
    if (formikRef && formikRef.current) {
      const { setValues } = formikRef.current;
      if (accountId) {
        CommonService.getModelList(
          ICTL_API_ENDPOINT.GET_ACCOUNT_DETAIL(accountId)
        ).then((response) => {
          if (response.status === EResponseStatus.SUCCESS) {
            const items = response?.data?.data;
            setValues(formatGetAccountDetailData(items));
            setFormValues(formatGetAccountDetailData(items));
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteAccount = () => {
    dispatch(setActive(true));
    const data = {
      user_id: accountId,
    };
    StaffService.deleteStaff(data)
      .then((res) => {
        if (res.status === EResponseStatus.SUCCESS) {
          if (user?.user_id !== accountId) {
            navigate(ictlPath.account);
          } else {
            setAction(ACCOUNT_ACTION.DELETE_OWN);
            setMessage(t("common.message.delete_log_out"));
          }
        }
      })
      .catch(() => {
        setOpenError(true);
        setMessage(t("common.message.delete_fail"));
      })
      .finally(() => dispatch(setActive(false)));
  };

  const onAccountCreateOrUpdate = (values) => {
    updateOrCreateAccount({
      accountId,
      data: values,
      callbackSuccess: (type) => {
        setOpenSuccess(true);
        setMessage(
          type === ACCOUNT_ACTION.STORE
            ? t("common.message.createSuccess")
            : t("common.message.update_success")
        );
      },
      callbackError: (type, error) => {
        setOpenError(true);
        const errorMessage = error?.response?.data?.message;
        if (errorMessage === "ACCOUNT_EXIST") {
          setMessage(
            t(`common.message.${errorMessage}`, {
              email: values?.account_email,
            })
          );
        } else if (type === ACCOUNT_ACTION.UPDATE) {
          setMessage(t("common.message.update_fail"));
        } else {
          setMessage(t("common.message.createFailed"));
        }
      },
    });
  };

  const onConfirm = (e) => {
    if (action === ACCOUNT_ACTION.CANCEL) {
      navigate(ictlPath.account);
    }
    if (action === ACCOUNT_ACTION.DELETE) {
      deleteAccount();
    }
    if (action === ACCOUNT_ACTION.DELETE_OWN) {
      navigate("/");
      logout(e);
    }
  };

  const renderRightContent = () => {
    return (
      <div className="account-form text-[14px] h-full flex flex-col">
        <Formik
          innerRef={formikRef}
          initialValues={initialAccountValues}
          validationSchema={accountSchema(t)}
          onSubmit={onAccountCreateOrUpdate}
        >
          {(props) => {
            const { values, handleSubmit } = props;
            const isValuesHasChanged = checkValueChange(
              formValues,
              values,
              false
            );
            return (
              <Form className="h-full flex flex-col">
                {/* delete account */}
                {accountId && (
                  <div className="flex justify-end">
                    <Button
                      onClick={() => {
                        setOpenCancel(true);
                        setAction(ACCOUNT_ACTION.DELETE);
                        setMessage(t("admin.account.form.delete_notice"));
                      }}
                      disabled={formValues.is_seeding_admin}
                      variant="contained"
                      className={cx(
                        "!px-6 !py-2.5 !rounded-md !text-base !font-extrabold !text-white !h-[44px] min-w-[90px] flex items-center justify-center",
                        {
                          "!bg-[#D90000]": !formValues.is_seeding_admin,
                        }
                      )}
                    >
                      {t("common.action.delete_2")}
                    </Button>
                  </div>
                )}

                <AccountAvatar formikProps={props} />
                <div className="account-info text-[14px]">
                  <AccountName formikProps={props} />
                  <AccountEmail formikProps={props} />
                  <AccountPhone formikProps={props} />
                </div>

                <div className="flex-1 flex items-end mt-12">
                  <div className="w-full">
                    {/* Seperate line */}
                    {/* <div className="border-b-[2px] w-full border-b-gray-300 mt-10 mb-6" /> */}

                    <div className="flex justify-between">
                      <button
                        type="button"
                        className="font-bold underline"
                        onClick={() => {
                          if (!isValuesHasChanged) {
                            navigate(ictlPath.account);
                          }
                          setOpenCancel(true);
                          setMessage(t("common.message.cancel_create"));
                          setAction(ACCOUNT_ACTION.CANCEL);
                        }}
                      >
                        {t("common.action.cancel")}
                      </button>
                      <Button
                        onClick={handleSubmit}
                        styleType={BUTTON_STYLE_TYPE.PRIMARY}
                        disabled={accountId && !isValuesHasChanged}
                      >
                        {t("common.action.save")}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  };

  return (
    <LayoutDefault showFooter showHeader pin={10}>
      <MainContent showBackButton path={ictlPath.account} flex>
        <DetailListContent
          rightContent={renderRightContent()}
          title={t("admin.account.form.title")}
        />
      </MainContent>
      <SuccessDialog
        message={message}
        open={openSuccess}
        onClose={() => navigate(ictlPath.account)}
        onOK={() => navigate(ictlPath.account)}
      />
      <ErrorDialog
        message={message}
        open={openError}
        onClose={() => setOpenError(false)}
        onOK={() => setOpenError(false)}
      />
      <AppMessage
        open={openCancel}
        setOpen={setOpenCancel}
        onSubmit={(e) => onConfirm(e)}
      >
        {message}
      </AppMessage>
    </LayoutDefault>
  );
}

export default AccountForm;
