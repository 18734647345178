import client from "./client";
import { OPERATOR_API_ENDPOINT } from "../constant";

export const login = (email, password) => {
  return client.post("/users/sign_in", { user: { email, password } });
};

export const register = (email, password) => {
  return client.post("/users", { email, password });
};

export const requestResetPassword = (body) => {
  return client.post("/reset-password/request", body);
};

export const resetPassword = (body) => {
  return client.post("/reset-password", body);
};

export const changePassword = (body) => {
  return client.post("/change-password", body);
};

export const uploadFile = (body) => {
  return client.post("/common/upload-file", body);
};

export const uploadCsv = (body) => {
  return client.post(OPERATOR_API_ENDPOINT.IMPORT_CSV_PRODUCT, body);
};

export const downloadFile = (body) => {
  return client.post("/common/upload-file/download", body);
};

export const getFacilityAmenities = (facilityType) => {
  return client.get(`/cms/facility/amenity/${facilityType}`);
};

export const getFacilityKeyWords = (facilityType) => {
  return client.get(`/cms/facility/keyword/${facilityType}`);
};
