// m-005-002
import React from "react";
import LayoutDefault from "app/operator/layouts/LayoutDefault"
import DetailList from "./DetailList";

function DetailPay() {
  return (
    <LayoutDefault showHeader>
      <DetailList />
    </LayoutDefault>
  );
}
export default DetailPay;
