import { COUNTRY_LIST } from "../../constant";

export const OPERATOR_API_ENDPOINT = {
  CREATE_FACILITY: "cms/facility/store-facility",
  GET_FACILITY_LIST: "cms/facility", // m-002-001,
  GET_FACILITY_DETAIL: "/cms/facility/:facility_id", // m-002-002
  EDIT_FACILITY_DETAIL: "/cms/facility/update-facility/:facility_id", // m-002-002

  EDIT_FACILITY_SCHEDULE_DETAIL:
    "/cms/facility/update-facility-schedule/:facility_id", // m-002-002

  EDIT_FACILITY_IMAGE_DETAIL:
    "/cms/facility/update-facility-image/:facility_id", // m-002-002

  EDIT_FACILITY_AMENITY_DETAIL:
    "/cms/facility/update-facility-amenity/:facility_id", // m-002-002

  VALIDATE_WEEKLY_HOLIDAY:
    "/cms/facility/check-exist-booking-by-date-of-week/:facility_id/:day_of_week",
  VALIDATE_OPEN_TIME:
    "/cms/facility/:facility_id/check-exist-booking-by-check-in-out",
  // inventory
  STORE_INVENTORY: "/cms/inventory/:facility_id/store-inventory",
  GET_INVENTORIES_LIST: "/cms/inventory/:facility_id",
  GET_INVENTORY_DETAIL: "/cms/inventory/detail/:inventory_id", // m-002-102
  EDIT_INVENTORY_DETAIL: "/cms/inventory/update-inventory/:inventory_id", // m-002-102
  GET_INVENTORY_TAG_LIST: "/cms/inventory/tag/:facility_type",
  CHECK_TYPE_TAG: "/cms/tags/check-type/:tag_id",
  // customer
  GET_CUSTOMER_LIST: "/cms/customer", // m-003-001
  GET_CUSTOMER_DETAIL: (customerId) =>
    customerId ? `cms/customer/${customerId}` : "cms/customer/:customerId", // m-003-002
  // product
  STORE_PRODUCT: "/cms/product/:facility_id/store-product",
  EDIT_PRODUCT_DETAIL: "/cms/product/update-product/:productId",
  GET_PRODUCT_DETAIL: "/cms/product/get-one/:product_id",
  GET_PRODUCT_LIST: "/cms/product/:facility_id",
  GET_PRODUCT_KEYWORD_LIST: "/cms/product/keyword/:facilityType",
  GET_PRODUCT_TAG_LIST: "/cms/product/tag/:facilityType/:productType",
  UPDATE_PRODUCT_POSITION: "/cms/product/update-position",
  EXPORT_CSV_PRODUCT: "cms/product/export/:facility_id",
  IMPORT_CSV_PRODUCT: "cms/product/import/",
  // admin
  GET_ADMIN_INFO: "/cms/business-operator/me/account-information",
  IS_BUSINESS_OPERATOR_INFO_MISSING:
    "/cms/business-operator/check/is-info-missing",
  UPADTE_ADMIN_SERVICE_PROVIDER_INFO:
    "/cms/business-operator/me/update-service-provider",
  // amenity
  GET_FACILITY_AMENITY: "/cms/facility/amenity/:facilityType", // m-002-002
  GET_INVENTORY_AMENITY: "/cms/inventory/amenity/:facilityType", // m-002-102
  GET_AD_PROJECT_LIST: "cms/project/get-all", // m-007-001
  CREATE_AD_PROJECT: "cms/project/store", // m-007-002 create
  GET_AD_PROJECT_DETAIL: (projectId) =>
    projectId
      ? `cms/project/get-one/${projectId}`
      : "cms/project/get-one/:projectId",
  UPDATE_AD_PROJECT: (projectId) =>
    projectId
      ? `cms/project/update/${projectId}`
      : "cms/project/update/:projectId",
  GET_PAYMENT_LIST: "cms/pay",
  // tags
  GET_TAGS_LIST: "/cms/tags/:facility_type",
  UPDATE_TAGS_POSITION: "/cms/tags/update-position",
  CREATE_TAG: "/cms/tags",
  UPDATE_TAG: "/cms/tags",
  //
  GET_BOOKING_BY_FACILITY:
    "/cms/facility-booking/booking-by-facility/:facilityId",
  GET_STAFF_LIST: "cms/business-operator/list-staff",
  GET_LIST_FACILITY_BY_USER: "cms/facility-booking/list-facility-by-user",
  GET_LIST_GOOGLE_REVIEW: (facilityId) =>
    facilityId ? `cms/facility/${facilityId}/get-google-map-review` : "",
  GET_LIST_REPORT: "cms/report/get-all",
  EXPORT_LIST_REPORT: "cms/report/export/get-all",
  EXPORT_LIST_GOOGLE_REVIEW: (facilityId) =>
    facilityId ? `cms/facility/${facilityId}/google-map-review-export` : "",
  GET_TERM_OF_USE: (facilityId) =>
    facilityId ? `/cms/facility/${facilityId}/get-term-of-use` : "",
  TERM_OF_USE: "/cms/facility/store-term-of-use", // m-002-401
  GET_PRIVACI_POLICY: (facilityId) =>
    facilityId ? `/cms/facility/${facilityId}/get-privacy-policy` : "",
  PRIVACI_POLICY: "/cms/facility/store-privacy-policy", // m-002-301
};

export const ICTL_API_ENDPOINT = {
  HOME: "cms/report", // v-001-001
  EXPORT_CSV_HOME: "cms/report/export", // v-001-001

  // business_person
  GET_BUSINESS_LIST: "/cms/business-operator", // v-002-001
  GET_BUSINESS_DETAIL: (id) =>
    id ? `/cms/business-operator/${id}` : "cms/business-operator/:id", // v-002-002
  STORE_BUSINESS_PERSON: "cms/business-operator/store-business-operator", // v-002-002
  UPDATE_BUSINESS_PERSON: (id) =>
    id
      ? `/cms/business-operator/update-business-operator/${id}`
      : "/cms/business-operator/update-business-operator/:id", // v-002-002

  EXPORT_BUSINESS: "/cms/business-operator/export",
  // member
  GET_MEMBER_LIST: "/cms/member", // v-003-001
  GET_MEMBER_BUSINESS_OPERATOR: "/cms/member/business-operator", // v-003-001
  GET_MEMBER_FACILITY: "/cms/member/facility", // v-003-001
  GET_MEMBER_DETAIL: "/cms/member/:member_id", // v-003-002

  // influencer
  GET_INFLUENCER_LIST: "/cms/influencer", // v-004-001
  GET_INFLUENCER_DETAIL: "/cms/influencer/:influencer_id", // v-004-002

  // platform
  GET_PLATFORM_DETAIL: "/cms/platform-operator/:id", // v-005-001
  UPDATE_PLATFORM_DETAIL: "/cms/platform-operator/update", // v-005-001

  // pay
  GET_LIST_PAY: "cms/invoice", // v-006-001
  EXPORT_LIST_PAY: "cms/invoice/export", // v-006-001 v-006-002
  UPDATE_STATUS_PAY: "cms/invoice/update-status", // v-006-001
  GET_LIST_INFLUENCE: "cms/invoice/influencers", // v-007-001
  UPDATE_STATUS_PAY_INFLUENCE: "cms/invoice/update-status-influencer/user/", // V-007-001
  DETAIL_INVOICE_INFLUENCE: "cms/invoice/influencer/", // V-007-002
  LIST_HISTORY_PAYMENT: "cms/invoice/influencers/history", // V-007-003
  DETAIL_HISTORY_PAYMENT: "/cms/invoice/influencer/", // V-007-004
  LIST_WAIT_PAYMENT: "cms/invoice/influencer/wait-pay", // V-007-005

  // notification
  GET_NOTIFICATION_LIST: "/cms/notification", // v-008-001
  GET_NOTIFICATION_LIST_GROUP: "/cms/notification/group", // v-008-001
  GET_NOTIFICATION_DETAIL: "/cms/notification/:notification_id", // v-008-002
  STORE_NOTIFICATION: "/cms/notification/store-notification", // v-008-002
  UPDATE_NOTIFICATION_DETAIL:
    "/cms/notification/update-notification/:notification_id", // v-008-002
  DELETE_NOTIFICATION_DETAIL:
    "/cms/notification/delete-notification/:notification_id", // v-008-002

  // banner
  GET_BANNER_LIST: "cms/banner", // v-009-001
  STORE_BANNER: "cms/banner/store-banner", // v-009-002
  GET_BANNER_DETAIL: (bannerId) =>
    bannerId ? `cms/banner/${bannerId}` : "cms/banner/:bannerId", // v-009-002
  UPDATE_BANNER: (bannerId) =>
    bannerId
      ? `cms/banner/update-banner/${bannerId}`
      : "cms/banner/update-banner/:bannerId", // v-009-002
  DELETE_BANNER: (bannerId) =>
    bannerId
      ? `cms/banner/delete-banner/${bannerId}`
      : "cms/banner/delete-banner/:bannerId", // v-009-002

  // account
  GET_ACCOUNT_LIST: "cms/staff", // v-010-001
  STORE_ACCOUNT: "cms/staff/store", // v-010-002
  GET_ACCOUNT_DETAIL: (id) => (id ? `cms/staff/${id}` : "cms/staff/:id"), // v-010-002
  UPDATE_ACCOUNT: (id) =>
    id ? `cms/staff/update/${id}` : "cms/staff/update/:id", // v-010-002
  DELETE_ACCOUNT: (id) =>
    id ? `cms/staff/delete/${id}` : "cms/staff/delete/:id", // v-010-002

  // projects
  GET_AD_PROJECT_LIST: "/cms/project/get-all-for-v", // v-011-001
  GET_AD_PROJECT_DETAIL: (projectId) =>
    projectId
      ? `cms/project/get-one-for-v/${projectId}`
      : `cms/project/get-one-for-v/:projectId`, // v-011-002
};

export const GOOGLE_MAP_GEOCODING_API_URL =
  "https://maps.googleapis.com/maps/api/geocode/json?latlng=:lat,:long&key=:api_key";

// this data is refered in https://developers.google.com/maps/documentation/geocoding/requests-reverse-geocoding
export const GEO_CODING_COMPONENT = {
  STREET_ADDRESS: "street_address ",
  ROUTE: "route",
  INTERSECTION: "intersection",
  POLITICAL: "political",
  COUNTRY: "country",
  ADMINISTRATIVE_AREA: {
    ADMINISTRATIVE_AREA_LEVEL_1: "administrative_area_level_1",
    ADMINISTRATIVE_AREA_LEVEL_2: "administrative_area_level_2",
    ADMINISTRATIVE_AREA_LEVEL_3: "administrative_area_level_3",
    ADMINISTRATIVE_AREA_LEVEL_4: "administrative_area_level_4",
    ADMINISTRATIVE_AREA_LEVEL_5: "administrative_area_level_5",
    ADMINISTRATIVE_AREA_LEVEL_6: "administrative_area_level_6",
    ADMINISTRATIVE_AREA_LEVEL_7: "administrative_area_level_7",
  },
  COLLOQUIAL_AREA: "colloquial_area",
  LOCALITY: "locality",
  SUBLOCALITY_AREA: {
    SUBLOCALITY: "sublocality",
    SUBLOCALITY_1: "sublocality_1",
    SUBLOCALITY_2: "sublocality_2",
    SUBLOCALITY_3: "sublocality_3",
    SUBLOCALITY_4: "sublocality_4",
    SUBLOCALITY_5: "sublocality_5",
  },
  NEIGHBORHOOD: "neighborhood",
  PREMISE: "premise",
  SUBPREMISE: "subpremise",
  PLUS_CODE: "plus_code",
  POSTAL_CODE: "postal_code",
  NATURAL_FEATURE: "natural_feature",
  AIRPORT: "airport",
  PARK: "park",
  POINT_OF_INTEREST: "point_of_interest",
  // none in documentation but exist in response
  STREET_NUMBER: "street_number",
};

export const GEO_CODING_LOCATION_TYPE = {
  ROOFTOP: "ROOFTOP",
  RANGE_INTERPOLATED: "RANGE_INTERPOLATED",
  GEOMETRIC_CENTER: "GEOMETRIC_CENTER",
  APPROXIMATE: "APPROXIMATE",
};

export const CURRENT_SUPPORT_COUNTRY = [COUNTRY_LIST.JA];
