/* eslint-disable react/no-array-index-key */
import React from "react";
import Drawer from "@mui/material/Drawer";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import operatorPath from "app/operator/route/Path";
import { logout } from "app/utils";

import { Roles } from "../../constant";

function HeaderDrawer({ isDrawerOpen, setDrawerState }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { unreadAndMarkActionForM } = useSelector(
    (state) => state?.projectBatch
  );
  const role = useSelector((state) => state.auth?.user?.group_roles?.roles[0]);

  const lstMenu = [
    {
      path: role === Roles.MEMBER ? "/staff" : operatorPath.operator,
      name: t("operator_header.dashboard"),
      className: "hover:bg-secondary flex items-center py-2",
    },
    {
      path: role === Roles.MEMBER ? "" : operatorPath.ad_project,
      name: t("operator_header.jobs"),
      className:
        role === Roles.MEMBER
          ? "flex items-center py-2 bg-secondary cursor-not-allowed"
          : "hover:bg-secondary flex items-center py-2",
    },
    {
      path: role === Roles.MEMBER ? "" : operatorPath.pay_list,
      name: t("operator_header.pay"),
      className:
        role === Roles.MEMBER
          ? "flex items-center py-2 bg-secondary cursor-not-allowed"
          : "hover:bg-secondary flex items-center py-2",
    },
    {
      path:
        role === Roles.MEMBER || role === Roles.ADMIN
          ? ""
          : operatorPath.operator_info,
      name: t("operator_header.operator_info"),
      className:
        role === Roles.MEMBER || role === Roles.ADMIN
          ? "flex items-center py-2 bg-secondary cursor-not-allowed"
          : "hover:bg-secondary flex items-center py-2",
    },
  ];

  const lstAccountMenu = [
    {
      path: role === Roles.MEMBER ? "/staff/profile" : operatorPath.profile,
      name: t("common_header.profile"),
    },
    {
      path:
        role === Roles.MEMBER
          ? "/staff/account-settings"
          : operatorPath.account_settings,
      name: t("common_header.account_setting"),
    },
  ];

  return (
    <Drawer
      anchor="top"
      open={isDrawerOpen}
      onClose={() => setDrawerState(false)}
      className="!z-[1029]"
      classes={{ paper: "mt-20 !h-full" }}
      transitionDuration={600}
      hideBackdrop
    >
      <div className="container px-6">
        <div className="py-10 border-b border-solid border-secondary-300">
          <p className="text-sm text-secondary-200 font-bold mb-4">
            {t("operator_header.other_menu")}
          </p>
          {lstMenu.map((item, index) => {
            return (
              <NavLink key={index} to={item.path} className={item.className}>
                {item.path === operatorPath.ad_project ? (
                  <div className="flex">
                    <span className="mr-[2px] font-bold">{item.name}</span>
                    {unreadAndMarkActionForM > 0 && (
                      <div className="text-[10px] bg-[#8F0B6A] text-white min-w-[20px] min-h-[20px] px-1 flex items-center justify-center rounded-full self-start">
                        {unreadAndMarkActionForM}
                      </div>
                    )}
                  </div>
                ) : (
                  <span className="font-bold">{item.name}</span>
                )}
              </NavLink>
            );
          })}
        </div>

        <div className="py-10 border-b border-solid border-secondary-300">
          <p className="text-sm text-secondary-200 font-bold mb-4">
            {t("common_header.account_setting")}
          </p>
          {lstAccountMenu.map((item, index) => {
            return (
              <NavLink
                key={index}
                to={item.path}
                className="hover:bg-secondary flex items-center py-2"
              >
                <span className="font-bold">{item.name}</span>
              </NavLink>
            );
          })}
        </div>

        <div className="pt-10 mb-40 font-bold">
          <button
            type="button"
            className="w-full border border-solid border-secondary-200 rounded-lg p-2"
          >
            <NavLink
              to=""
              onClick={(e) => {
                navigate("/");
                logout(e);
              }}
            >
              {t("common_header.log_out")}
            </NavLink>
          </button>
        </div>
      </div>
    </Drawer>
  );
}

export default HeaderDrawer;
