import home from "../pages/Home/translate/HomeCN";
import influencer from "../pages/Influencer/translate/InfluencerCN";
import pay from "../pages/Pay/translate/cn";
import payment from "../pages/payment/translate/cn";
import business from '../pages/BusinessPerson/translate/cn';
import businessInformation from '../pages/BusinessOperatorDetail/translate/cn';
import notification from '../pages/Notification/translate/NotificationCN';
import banner from "../pages/Banner/translate/cn";
import account from "../pages/Account/translate/cn";

export default {
  home,
  influencer,
  pay,
  business,
  businessInformation,
  payment,
  notification,
  banner,
  account,
};
