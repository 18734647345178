export default {
  edit: {
    management_information: "Management Information",
    basic_information: "Basic Information",
    company_name: "Company Name",
    postcode: "Postal Code",
    contact: "Address",
    founding: "Establishment",
    phone_number: "Phone Number",
    pay_pal:
      "PayPal Remittance Information (PayPal @Username/Email Address/Phone Number)",
    bank_name: "Bank Name",
    branch_name: "Branch Name",
    account_number: "Account Number",
    date_payment: "Billing Closing Date",
    term_payment: "Payment Due Date",
    invoice_number: "Invoice Number",
    payee_information: "Remittance Information",
    contact_information: "お問い合わせ先",
    contact_basic: "Contact Information",
    close_date: "Closing Date Information",
    country_region: "国／地域",
    this_end_month: "End of the Current Month",
    last_end_month: "End of the Next Month",
  },
};