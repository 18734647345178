/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Rating from "@mui/material/Rating";
import AdProjectService from "app/services/api/operatorService/AdProjectService";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  SortedAscendingIcon,
  SortedDescendingIcon,
  UnsortIcon,
} from "../../../../../components/DatagridIcon";
import { EResponseStatus, GENDER, PER_PAGE } from "../../../../../constant";
import { formatNumber } from "../../../../../utils";
import { ProjectDetailContext } from "../../AdvertisementDetail";
import ConfirmDialog from "../ConfirmDialog";
import ImageItem from "../../../../../components/ImageItem";
import Path from "../../../../route/Path";
import SelectMenu from "../../../../../components/SelectMenu";

const male = require("app/assets/images/icon-male.png");
const female = require("app/assets/images/icon-female.png");
const other = require("app/assets/images/icon-other.png");
const instagram = require("app/assets/images/icon-instagram.png");
const tiktok = require("app/assets/images/icon-tiktok.png");
// const twitter = require("app/assets/images/icon-twitter.png");
const facebook = require("app/assets/images/icon-facebook.png");
const youtube = require("app/assets/images/icon-youtube.png");

function SearchInfluencer() {
  const { t } = useTranslation();
  const { formValues } = useContext(ProjectDetailContext);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [errorMess, setErrorMess] = useState("");
  const [userId, setUserId] = useState("");
  const [totalItem, setTotalItem] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const paramSearch = new URLSearchParams(location.search);
  const [page, setPage] = useState(1);
  const [lstInfluencers, setLstInfluencers] = useState([]);
  const [filterValues, setFilterValues] = useState({
    gender: paramSearch.get("gender") || null,
    influencer_name: paramSearch.get("influencer_name") || null,
  });
  const lstGender = [
    {
      text: t("common.male"),
      value: GENDER.MALE,
    },
    {
      text: t("common.female"),
      value: GENDER.FEMALE,
    },
    {
      text: t("common.other"),
      value: GENDER.OTHER,
    },
  ];

  // validate
  const validationSchema = Yup.object().shape({
    offerMessage: Yup.string().required(),
  });
  const initialValues = {
    offerMessage: "",
  };

  const getListInfluencer = useCallback(() => {
    setIsLoading(true);
    const projectId = formValues.ad_project_id;
    const params = {
      page,
      per_page: PER_PAGE,
      ...filterValues,
    };
    AdProjectService.getLstInfluencerByProject(projectId, params)
      .then((res) => {
        if (res.status === EResponseStatus.SUCCESS) {
          if (res?.data?.data?.row?.length) {
            const lstTemp = res?.data?.data?.row;
            lstTemp.forEach((element) => {
              const instagramFollowers = element.social_media_activities.find(
                (item) => item.social_media === "instagram"
              )?.subscribers_number;
              element.instagramFollowers = instagramFollowers;

              const tiktokFollowers = element.social_media_activities.find(
                (item) => item.social_media === "tiktok"
              )?.subscribers_number;
              element.tiktokFollowers = tiktokFollowers;

              const twitterFollowers = element.social_media_activities.find(
                (item) => item.social_media === "twitter"
              )?.subscribers_number;
              element.twitterFollowers = twitterFollowers;

              const facebookFollowers = element.social_media_activities.find(
                (item) => item.social_media === "facebook"
              )?.subscribers_number;
              element.facebookFollowers = facebookFollowers;

              const youtubeFollowers = element.social_media_activities.find(
                (item) => item.social_media === "youtube"
              )?.subscribers_number;
              element.youtubeFollowers = youtubeFollowers;

              setTotalItem(res.data.data.total);
              setLstInfluencers(lstTemp);
            });
          } else {
            setLstInfluencers([]);
          }
        }
      })
      .catch(() => {
        setErrorMess(t("common.message.cannot_display_data"));
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.ad_project_id, page, filterValues]);

  useEffect(() => {
    let gender = ``;
    let name = ``;
    if (filterValues.gender) {
      gender = `&gender=${filterValues.gender}`;
    }
    if (filterValues.influencer_name) {
      name = `&influencer_name=${filterValues.influencer_name}`;
    }
    navigate(`?page=${page}&per_page=${PER_PAGE}${gender}${name}`, {
      replace: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, page]);

  useEffect(() => {
    getListInfluencer();
  }, [getListInfluencer]);

  const onConfirmSend = (e, id) => {
    setOpen(true);
    setUserId(id);
  };

  const columns = [
    {
      field: "nickname",
      headerName: t("operator.advertisement.social.influcener_name"),
      headerClassName: "custom",
      width: 400,
      renderHeader: () => (
        <div className="font-bold truncate ml-[52px]">
          {t("operator.advertisement.social.influcener_name")}
        </div>
      ),
      renderCell: (params) => {
        const avatar = params?.row?.avatar_url;
        const name = params?.row?.nickname;
        const influencerId = params?.row?.user_id;
        return (
          <Link
            to={Path.influencer_detail(formValues.ad_project_id, influencerId)}
            className="flex items-center truncate"
          >
            <div className="w-10 h-10 flex-shrink-0 mr-3">
              <ImageItem
                lazy="true"
                loadavatar="true"
                avatar="true"
                containerclassname="!rounded-full"
                role="presentation"
                imagesrc={avatar}
                alt=""
                imgclassname="w-full h-full object-cover rounded-full"
              />
            </div>
            <div className="truncate font-[700] mr-2">{name}</div>
          </Link>
        );
      },
    },
    {
      field: "rating",
      headerName: t("operator.advertisement.social.rating"),
      headerClassName: "custom",
      width: 150,
      renderCell: (params) => {
        const rating = params?.row?.rating;
        return (
          <Rating
            size="small"
            name="read-only"
            precision={0.1}
            value={rating}
            readOnly
          />
        );
      },
    },
    {
      field: "gender",
      headerName: t("gender"),
      headerClassName: "custom",
      width: 100,
      renderCell: (params) => {
        const genderInfluencer = params?.row?.gender;
        return (
          <div className="flex items-center truncate h-full">
            {(() => {
              if (genderInfluencer === GENDER.MALE) {
                return <img src={male} alt="Male" />;
              }
              if (genderInfluencer === GENDER.FEMALE) {
                return <img src={female} alt="Female" />;
              }
              if (genderInfluencer === GENDER.OTHER) {
                return (
                  <img src={other} alt="Other" className="relative -left-2" />
                );
              }
              return null;
            })()}
          </div>
        );
      },
    },
    {
      field: "instagramFollowers",
      renderHeader: () => (
        <div className="flex items-center justify-center w-8 h-8 flex-shrink-0">
          <img
            src={instagram}
            alt=""
            className="w-full h-full object-contain"
          />
        </div>
      ),
      headerClassName: "custom",
      width: 200,
      renderCell: (params) => {
        const instagramm = params?.row?.instagramFollowers || 0;
        return (
          <div className="flex items-center truncate">
            <div className="flex items-center truncate  gap-2">
              <div className="flex items-center justify-center w-7 h-7 flex-shrink-0">
                <img
                  src={instagram}
                  alt=""
                  className="w-full h-full object-contain"
                />
              </div>
              <div className="truncate">
                {formatNumber(instagramm)}
                {t("common.followers")}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      field: "tiktokFollowers",
      renderHeader: () => (
        <div className="flex items-center justify-center w-7 h-7 flex-shrink-0">
          <img src={tiktok} alt="" className="w-full h-full object-contain" />
        </div>
      ),
      headerClassName: "custom",
      width: 200,
      renderCell: (params) => {
        const tiktokk = params?.row?.tiktokFollowers || 0;
        return (
          <div className="flex items-center truncate">
            <div className="flex items-center truncate gap-2">
              <div className="flex items-center justify-center w-7 h-7 flex-shrink-0">
                <img
                  src={tiktok}
                  alt=""
                  className="w-full h-full object-contain"
                />
              </div>
              <div className="truncate">
                {formatNumber(tiktokk)}
                {t("common.followers")}
              </div>
            </div>
          </div>
        );
      },
    },
    // {
    //   field: "twitterFollowers",
    //   headerName: t("operator.advertisement.social.twitter"),
    //   headerClassName: "custom",
    //   width: 200,
    //   renderCell: (params) => {
    //     const twitterr = params?.row?.twitterFollowers;
    //     return (
    //       <div className="flex items-center truncate">
    //         {twitterr ? (
    //           <div className="flex items-center truncate">
    //             <div className="flex items-center justify-center w-7 h-7 flex-shrink-0">
    //               <img
    //                 src={twitter}
    //                 alt=""
    //                 className="w-full h-full object-contain"
    //               />
    //             </div>
    //             <div className="truncate">
    //               {formatNumber(twitterr)}
    //               {t("common.followers")}
    //             </div>
    //           </div>
    //         ) : null}
    //       </div>
    //     );
    //   },
    // },
    {
      field: "facebookFollowers",
      renderHeader: () => (
        <div className="flex items-center justify-center w-7 h-7 flex-shrink-0">
          <img src={facebook} alt="" className="w-full h-full object-contain" />
        </div>
      ),
      headerClassName: "custom",
      width: 200,
      renderCell: (params) => {
        const facebookk = params?.row?.facebookFollowers || 0;
        return (
          <div className="flex items-center truncate">
            <div className="flex items-center truncate gap-2">
              <div className="flex items-center justify-center w-7 h-7 flex-shrink-0">
                <img
                  src={facebook}
                  alt=""
                  className="w-full h-full object-contain"
                />
              </div>
              <div className="truncate">
                {formatNumber(facebookk)}
                {t("common.followers")}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      field: "youtubeFollowers",
      renderHeader: () => (
        <div className="flex items-center justify-center w-7 h-7 flex-shrink-0">
          <img src={youtube} alt="" className="w-full h-full object-contain" />
        </div>
      ),
      headerClassName: "custom",
      width: 250,
      renderCell: (params) => {
        const youtubee = params?.row?.youtubeFollowers || 0;
        return (
          <div className="flex items-center truncate">
            <div className="flex items-center truncate gap-2">
              <div className="flex items-center justify-center w-7 h-7 flex-shrink-0">
                <img
                  src={youtube}
                  alt=""
                  className="w-full h-full object-contain"
                />
              </div>
              <div className="truncate">
                {formatNumber(youtubee)}
                {t("common.followers")}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      field: "self_introduction",
      headerName: t("operator.advertisement.social.self_intro"),
      headerClassName: "custom",
      minWidth: 300,
      flex: 1,
    },
    {
      field: "setting",
      headerAlign: "right",
      headerClassName: "custom",
      width: 220,
      sortable: false,
      renderHeader: () => {
        return "";
      },
      renderCell: (params) => {
        const isApplied = params?.row?.is_applied;
        const userIdd = params?.row?.user_id;
        return (
          <Button
            disabled={isApplied}
            onClick={(e) => {
              e.preventDefault();
              onConfirmSend(e, userIdd);
            }}
            variant="contained"
            className="!bg-black text-white !p-2 !rounded-[12px]"
          >
            {t("operator.advertisement.social.send_offer")}
          </Button>
        );
      },
    },
  ];

  const onSubmitTypeFilter = (newVal) => {
    setPage(1);
    setFilterValues((prev) => ({
      ...prev,
      ...newVal,
    }));
  };

  const onChangeSearch = (event) => {
    const { value } = event.target;
    onSubmitTypeFilter({ influencer_name: value.trim() });
  };

  const debouncedChangeHandler = _.debounce((event) => {
    setPage(1);
    onChangeSearch(event);
  }, 1000);

  const sendSpecialOffer = (values, context) => {
    const { resetForm, setFieldError } = context;
    // send offer
    const data = {
      request: {
        message: values.offerMessage,
      },
    };
    const projectId = formValues.ad_project_id;
    AdProjectService.sendSpecialOffer(projectId, userId, data)
      .then((res) => {
        if (res.status === EResponseStatus.CREATED) {
          resetForm();
          setOpen(false);
          lstInfluencers.forEach((item) => {
            if (item.user_id === userId) {
              item.is_applied = true;
            }
          });
        }
      })
      .catch(() => {
        setFieldError(
          "offerMessage",
          t("operator.advertisement.social.send_offer_fail")
        );
      })
      .finally(() => {
        setUserId("");
      });
  };

  const onPaginationModelChange = (model) => {
    const newPage = model?.page;
    setPage(newPage + 1);
  };

  return (
    <div className="px-6 flex-1">
      <div className="my-[8px] font-semibold pt-[8px] border-t border-border">
        {t("operator.advertisement.social.influencer_descrip")}
      </div>
      <div className="flex flex-col sm:flex-row sm:items-center mb-8">
        <div className="flex items-center max-w-[288px] w-full h-[44px] mb-4 sm:mb-0 sm:mr-5 px-3 py-1 rounded-3xl border border-solid border-secondary-200 bg-secondary">
          <div className="mr-2">
            <i className="fa-solid fa-magnifying-glass text-secondary-200" />
          </div>
          <input
            onChange={(event) => debouncedChangeHandler(event)}
            type="text"
            defaultValue={filterValues.influencer_name}
            placeholder={t(
              "operator.advertisement.social.influencer_placeholder"
            )}
            className="flex-1 bg-secondary outline-none w-full"
          />
        </div>
        <SelectMenu
          optionSelected={filterValues?.gender?.split(",")}
          optionList={lstGender}
          onSubmit={(gender) => {
            onSubmitTypeFilter({ gender: gender.join(",") });
          }}
          label={t("gender")}
        />
      </div>

      {/* Table Group */}
      {errorMess ? (
        <p className="text-[20px] font-[600] py-3">{errorMess}</p>
      ) : (
        <div className="w-full">
          <DataGrid
            autoHeight
            getRowId={(item) => item.user_id}
            rows={lstInfluencers}
            columns={columns}
            loading={isLoading}
            rowCount={totalItem}
            paginationMode="server"
            disableColumnMenu
            slots={{
              columnUnsortedIcon: UnsortIcon,
              columnSortedDescendingIcon: SortedDescendingIcon,
              columnSortedAscendingIcon: SortedAscendingIcon,
            }}
            paginationModel={{
              pageSize: PER_PAGE,
              page: page - 1,
            }}
            pageSizeOptions={[PER_PAGE]}
            onPaginationModelChange={(model) => {
              onPaginationModelChange(model);
            }}
            localeText={{
              noResultsOverlayLabel: t("common.message.no_data_display"),
              noRowsLabel: t("common.message.no_data_display"),
            }}
          />
        </div>
      )}

      <Formik
        validateOnMount
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={sendSpecialOffer}
      >
        {(prop) => {
          const { handleSubmit, resetForm } = prop;
          return (
            <Form>
              <ConfirmDialog
                open={open}
                onClose={() => {
                  setOpen(false);
                  resetForm();
                }}
                onOK={handleSubmit}
              >
                <div className="flex-1">
                  <Field
                    as="textarea"
                    placeholder={t(
                      "operator.advertisement.dialog.approve_report_placeholder"
                    )}
                    name="offerMessage"
                    className="w-full h-[200px] border border-solid border-secondary-200 resize-none px-3 py-2 !rounded-[12px]"
                  />
                  <ErrorMessage
                    component="div"
                    name="offerMessage"
                    className="error-msg"
                  />
                </div>
              </ConfirmDialog>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default SearchInfluencer;
