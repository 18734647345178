import { number, object, string } from "yup";
import {
  accountNumberRegex,
  // DAYJS_STATUS,
  emailRegex,
  invoiceNumberRegex,
} from "../../constant";

export const MAX_LENGTH_100 = 100;
export const MAX_LENGTH_256 = 256;
export const MAX_LENGTH_7 = 7;
export const MAX_LENGTH_14 = 14;
export const MAX_LENGTH_POSTCODE = 15;
export const MIN_LENGTH_POSTCODE = 5;
export const MAX_LENGTH_EMAIL = 64;
export const MAX_LENGTH_PHONE_NUMBER = 20;

export const editPlatformSchema = (t) => ({
  companyName: object({
    company_name: string()
      .required(
        t("validate_message.required", {
          path: t("admin.businessInformation.edit.company_name"),
        })
      )
      .trim(),
  }),
  dateEstablished: object({
    date_established: string().required(
      t("validate_message.required", {
        path: t("admin.businessInformation.edit.founding"),
      })
    ),
    // .test(
    //   "is-valid-date",
    //   t("common.message.wrong_format_validate", {
    //     field: t("admin.businessInformation.edit.founding"),
    //   }),
    //   (value) => {
    //     if (value === DAYJS_STATUS.DAY_FORMAT_INVALID) {
    //       return false;
    //     }
    //     return true;
    //   }
    // ),
  }),
  postCode: object({
    postcode: string()
      .required(
        t("validate_message.required", {
          path: t("operator.accountSetting.personal_info.postcode"),
        })
      )
      .min(
        MIN_LENGTH_POSTCODE,
        t("validate_message.min_length", {
          path: t("operator.accountSetting.personal_info.postcode"),
          min: MIN_LENGTH_POSTCODE,
        })
      )
      .trim(),
  }),
  address: object({
    address_address: string()
      .required(
        t("validate_message.required", {
          path: t("operator.accountSetting.personal_info.address"),
        })
      )
      .max(256, t("common.message.exceeds_maximum_length"))
      .trim(),
    address_city: string()
      .required(
        t("validate_message.required", {
          path: t("operator.accountSetting.personal_info.municipaliites"),
        })
      )
      .max(256, t("common.message.exceeds_maximum_length"))
      .trim(),
    address_street: string()
      .required(
        t("validate_message.required", {
          path: t(
            "operator.accountSetting.personal_info.state_provine_country"
          ),
        })
      )
      .max(256, t("common.message.exceeds_maximum_length"))
      .trim(),
    address_country: string()
      .required(
        t("validate_message.required", {
          path: t("operator.accountSetting.personal_info.country_region"),
        })
      )
      .max(256, t("common.message.exceeds_maximum_length"))
      .trim(),
    postcode: string()
      .required(
        t("validate_message.required", {
          path: t("operator.accountSetting.personal_info.postcode"),
        })
      )
      .min(
        MIN_LENGTH_POSTCODE,
        t("validate_message.min_length", {
          path: t("operator.accountSetting.personal_info.postcode"),
          min: MIN_LENGTH_POSTCODE,
        })
      )
      .trim(),
  }),
  phone_number: object({
    phone_number: string().required().max(20).trim(),
  }),
  email: object({
    email: string()
      .required(t("common.message.email_message"))
      .matches(
        emailRegex,
        t("validate_message.wrong_format", {
          path: t("common.email"),
        })
      ),
  }),
  settingFee: object({
    payment_cutoff_date: number().required(
      t("validate_message.required", {
        path: t("admin.businessInformation.edit.term_payment"),
      })
    ),
    billing_cutoff_date: number().required(
      t("validate_message.required", {
        path: t("admin.businessInformation.edit.term_payment"),
      })
    ),
  }),
  registrationNumber: object({
    registration_number: string()
      .required(
        t("validate_message.required", {
          path: t("admin.businessInformation.edit.invoice_number"),
        })
      )
      .max(14, t("common.message.exceeds_maximum_length"))
      .matches(
        invoiceNumberRegex,
        t("validate_message.wrong_format", {
          path: t("admin.businessInformation.edit.invoice_number"),
        })
      )
      .trim(),
  }),
  bankName: object({
    bank_name: string()
      .max(256, t("common.message.exceeds_maximum_length"))
      .trim(),
  }),
  branchName: object({
    branch_name: string()
      .max(256, t("common.message.exceeds_maximum_length"))
      .trim(),
  }),
  accountNumber: object({
    account_number: string()
      .max(7, t("common.message.exceeds_maximum_length"))
      .matches(
        accountNumberRegex,
        t("validate_message.wrong_format", {
          path: t("admin.businessInformation.edit.account_number"),
        })
      )
      .trim(),
  }),
});
