import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import "dayjs/locale/ja";
import moment from "moment";
import dayjs from "dayjs";
import { ErrorMessage } from "formik";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  COUNTRY_LIST,
  DAYJS_STATUS,
  onlyNumberRegexNoChar,
  postCodeRegexInput,
} from "app/constant";
import FormItem, { TYPE_INPUT } from "app/operator/components/FormItem";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import EditableGroup, {
  EDIT_BTN_POSITION,
} from "app/operator/staff/pages/BookingDetail/EditableGroup";
import { PlatFormDetailContext } from "app/ictl/pages/BusinessOperatorDetail";
import { FORMAT_DATE } from "../../../../constant";
import {
  editPlatformSchema,
  MAX_LENGTH_100,
  MAX_LENGTH_14,
  MAX_LENGTH_7,
  MAX_LENGTH_256,
  MAX_LENGTH_EMAIL,
  MAX_LENGTH_PHONE_NUMBER,
  MAX_LENGTH_POSTCODE,
} from "../../../../utils/schema/platform";
import i18n from "../../../../langs";

function CommonInfo() {
  const { t } = useTranslation();
  const locale = i18n.resolvedLanguage;
  const { formValues, setFormValues, onEditPlatForm } = useContext(
    PlatFormDetailContext
  );

  const [date, setDate] = useState(formValues?.date_established);

  const onSubmitValue = (e) => {
    onEditPlatForm(e);
    setFormValues(e);
  };

  const renderEditFormCompanyName = ({ values }) => {
    const fieldName = "company_name";
    return (
      <FormItem
        name={fieldName}
        label={t("admin.businessInformation.edit.company_name")}
        description=""
        placeholder={t("admin.businessInformation.edit.company_name")}
        // space
        labelClassName="font-semibold mb-2"
        inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        containerClassName="mb-[16px]"
        showCharCount
        maxLength={MAX_LENGTH_256}
        typeInput={TYPE_INPUT.TEXT}
        defaultLength={values?.company_name?.length}
        required
      />
    );
  };

  const renderEditFormDateEstablished = ({ setFieldValue }) => {
    const fieldName = "date_established";
    return (
      <>
        <div className="mb-[16px] font-semibold text-sm">
          {t("admin.businessInformation.edit.founding")}
        </div>
        <DesktopDatePicker
          inputFormat={FORMAT_DATE.YYYY_MM_DD}
          className="date-picker mr-2 mb-2"
          value={date}
          name={fieldName}
          onChange={(value) => {
            const fieldVal = dayjs(value).format(FORMAT_DATE.YYYY_MM_DDT);
            setFieldValue(
              fieldName,
              fieldVal === DAYJS_STATUS.DAY_FORMAT_INVALID ? "" : fieldVal
            );
            setDate(dayjs(value).format(FORMAT_DATE.YYYY_MM_DDT));
          }}
          renderInput={(res) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <TextField {...res} size="small" sx={{ width: "50%" }} />
          )}
          componentsProps={{
            actionBar: {
              actions: ["today"],
              className: "!justify-center",
            },
          }}
        />
        <ErrorMessage name={fieldName} component="div" className="errors-msg" />
      </>
    );
  };

  const renderEditFormRegistrationNumber = ({ values }) => {
    return (
      <FormItem
        name="registration_number"
        label={t("admin.businessInformation.edit.invoice_number")}
        labelClassName="font-semibold mb-2"
        description=""
        placeholder={t("admin.businessInformation.edit.invoice_number")}
        containerClassName="mb-[16px]"
        inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        regex={onlyNumberRegexNoChar}
        // char count
        showCharCount
        typeInput={TYPE_INPUT.TEXT}
        maxLength={MAX_LENGTH_14}
        defaultValue={values?.registration_number}
        defaultLength={values?.registration_number?.length}
      />
    );
  };

  const renderEditFormAddress = ({ values }) => {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-[16px]">
        <FormItem
          name="postcode"
          label={t("operator.accountSetting.personal_info.postcode")}
          labelClassName="font-semibold mb-2"
          description=""
          placeholder={t("common.message.no_input_yet")}
          inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
          containerClassName="mb-[16px]"
          regex={postCodeRegexInput}
          showCharCount
          typeInput={TYPE_INPUT.TEXT}
          maxLength={MAX_LENGTH_POSTCODE}
          defaultValue={values?.postcode}
          required
          defaultLength={values?.postcode?.length}
        />
        <div className="hidden md:block" />
        <FormItem
          name="address_street"
          label={t(
            "operator.accountSetting.personal_info.state_provine_country"
          )}
          labelClassName="font-semibold mb-2"
          description=""
          placeholder={t("common.message.no_input_yet")}
          inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
          containerClassName="mb-[16px]"
          // char count
          showCharCount
          maxLength={MAX_LENGTH_100}
          typeInput={TYPE_INPUT.TEXT}
          required
          defaultLength={values?.address_street?.length}
        />
        <FormItem
          name="address_city"
          label={t("operator.accountSetting.personal_info.municipaliites")}
          labelClassName="font-semibold mb-2"
          description=""
          placeholder={t("common.message.no_input_yet")}
          inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
          containerClassName="mb-[16px]"
          // char count
          showCharCount
          maxLength={MAX_LENGTH_100}
          typeInput={TYPE_INPUT.TEXT}
          required
          defaultLength={values?.address_city?.length}
        />
        <FormItem
          name="address_address"
          label={t("operator.accountSetting.personal_info.address")}
          labelClassName="font-semibold mb-2"
          description=""
          placeholder={t("common.message.no_input_yet")}
          inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
          containerClassName="mb-[16px]"
          // char count
          showCharCount
          maxLength={MAX_LENGTH_100}
          typeInput={TYPE_INPUT.TEXT}
          required
          defaultLength={values?.address_address?.length}
        />
        <FormItem
          name="apartment_name"
          label={t("operator.accountSetting.personal_info.apartment_name")}
          labelClassName="font-semibold mb-2"
          description=""
          placeholder={t("common.message.no_input_yet")}
          inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
          containerClassName="mb-[16px]"
          // char count
          showCharCount
          maxLength={MAX_LENGTH_100}
          typeInput={TYPE_INPUT.TEXT}
          defaultLength={values?.apartment_name?.length}
        />
        <FormItem
          name="address_country"
          label={t("operator.accountSetting.personal_info.country_region")}
          labelClassName="font-semibold mb-2"
          description=""
          inputClassName="w-full text-[#dddddd]"
          containerClassName="mb-[16px]"
          typeInput={TYPE_INPUT.SELECT}
          selectClassName="w-full"
          required
          componentProps={{
            defaultValue: values?.address_country,
            optionsList: [
              {
                optionName: t("common.japan"),
                value: COUNTRY_LIST.JA,
              },
            ],
          }}
        />
      </div>
    );
  };

  const renderEditFormPhoneNumber = ({ values }) => {
    return (
      <FormItem
        name="phone_number"
        label={t("admin.businessInformation.edit.phone_number")}
        labelClassName="font-semibold mb-2"
        description=""
        placeholder={t("admin.businessInformation.edit.phone_number")}
        // inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        showCharCount
        containerClassName="mb-[16px]"
        maxLength={MAX_LENGTH_PHONE_NUMBER}
        typeInput={TYPE_INPUT.PHONE}
        defaultLength={values?.phone_number?.length}
        required
      />
    );
  };

  const renderEditFormEmail = ({ values }) => {
    return (
      <FormItem
        name="email"
        label={t("common.email")}
        labelClassName="font-semibold mb-2"
        description=""
        placeholder={t("common.email")}
        inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        containerClassName="mb-[16px]"
        // char count
        showCharCount
        maxLength={MAX_LENGTH_EMAIL}
        typeInput={TYPE_INPUT.TEXT}
        defaultLength={values?.email?.length}
      />
    );
  };

  const renderEditFormPaypal = ({ values }) => {
    const fieldName = "paypal";
    return (
      <FormItem
        name={fieldName}
        label={t("admin.businessInformation.edit.pay_pal")}
        description=""
        placeholder={t("admin.businessInformation.edit.pay_pal")}
        // space
        labelClassName="font-semibold mb-2"
        inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        containerClassName="mb-[16px]"
        showCharCount
        maxLength={MAX_LENGTH_100}
        typeInput={TYPE_INPUT.TEXT}
        defaultLength={values?.paypal?.length}
      />
    );
  };

  const renderEditFormBankName = ({ values }) => {
    const fieldName = "bank_name";
    return (
      <FormItem
        name={fieldName}
        label={t("admin.businessInformation.edit.bank_name")}
        description=""
        placeholder={t("admin.businessInformation.edit.bank_name")}
        // space
        labelClassName="font-semibold mb-2"
        inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        containerClassName="mb-[16px]"
        showCharCount
        maxLength={MAX_LENGTH_256}
        typeInput={TYPE_INPUT.TEXT}
        defaultLength={values?.bank_name?.length}
      />
    );
  };

  const renderEditFormBranchName = ({ values }) => {
    const fieldName = "branch_name";
    return (
      <FormItem
        name={fieldName}
        label={t("admin.businessInformation.edit.branch_name")}
        description=""
        placeholder={t("admin.businessInformation.edit.branch_name")}
        // space
        labelClassName="font-semibold mb-2"
        inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        containerClassName="mb-[16px]"
        showCharCount
        maxLength={MAX_LENGTH_256}
        typeInput={TYPE_INPUT.TEXT}
        defaultLength={values?.branch_name?.length}
      />
    );
  };

  const renderEditFormAccountNumber = ({ values }) => {
    const fieldName = "account_number";
    return (
      <FormItem
        name={fieldName}
        label={t("admin.businessInformation.edit.account_number")}
        labelClassName="font-semibold mb-2"
        description=""
        placeholder={t("admin.businessInformation.edit.account_number")}
        containerClassName="mb-[16px]"
        inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
        // showCharCount
        maxLength={MAX_LENGTH_7}
        defaultValue={values?.account_number}
        typeInput={TYPE_INPUT.TEXT}
        defaultLength={values?.account_number?.length}
      />
    );
  };

  return (
    <>
      <EditableGroup
        editForm={renderEditFormCompanyName}
        displayValues={
          <div className="pl-[8px] text-gray font-semibold">
            <div className="truncate">{formValues?.company_name}</div>
          </div>
        }
        validationSchema={editPlatformSchema(t)?.companyName}
        // space
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item"
        label={t("admin.businessInformation.edit.company_name")}
        required
      />

      <EditableGroup
        editForm={renderEditFormAddress}
        displayValues={
          <div className="pl-[8px] text-gray font-semibold max-h-[60px] break-words overflow-y-auto">
            {(() => {
              const postCode = formValues.postcode ? formValues.postcode : "";
              const street = formValues.address_street
                ? formValues.address_street
                : "";
              const city = formValues.address_city
                ? formValues.address_city
                : "";
              const address = formValues.address_address
                ? formValues.address_address
                : "";
              const addressCountry = formValues.address_country
                ? formValues.address_country
                : "";
              const apartmentName = formValues.apartment_name
                ? formValues.apartment_name
                : "";

              const path = [
                postCode,
                street,
                city,
                address,
                apartmentName,
              ].join(" ");
              const country = [t("common.japan"), addressCountry].join("-");

              const fullAddress = [path, country].join(",");

              return fullAddress;
            })()}
          </div>
        }
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        validationSchema={editPlatformSchema(t)?.address}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item"
        label={t("admin.businessInformation.edit.contact")}
        required
      />

      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
        <EditableGroup
          editForm={renderEditFormDateEstablished}
          displayValues={
            <div className="pl-[8px] text-gray font-semibold max-h-[60px] break-words overflow-y-auto">
              {formValues?.date_established &&
                moment(formValues?.date_established).format(
                  FORMAT_DATE.YYYY_MM_D
                )}
            </div>
          }
          editBtnPosition={EDIT_BTN_POSITION.LABEL}
          initialValues={formValues}
          validationSchema={editPlatformSchema(t)?.dateEstablished}
          onSubmitGroup={onSubmitValue}
          containerClassName="editable-item"
          label={t("admin.businessInformation.edit.founding")}
          required
        />
      </LocalizationProvider>

      <EditableGroup
        editForm={renderEditFormRegistrationNumber}
        displayValues={
          <div className="pl-[8px] text-gray font-semibold">
            <div>{formValues?.registration_number}</div>
          </div>
        }
        validationSchema={editPlatformSchema(t)?.registrationNumber}
        // space
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item"
        label={t("admin.businessInformation.edit.invoice_number")}
      />

      <p className="font-bold text-[18px] mt-[20px]">
        {t("admin.businessInformation.edit.contact_basic")}
      </p>

      <EditableGroup
        editForm={renderEditFormPhoneNumber}
        displayValues={
          <div className="pl-[8px] text-gray font-semibold">
            <p>{formValues?.phone_number}</p>
          </div>
        }
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        validationSchema={editPlatformSchema(t)?.phoneNumber}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item"
        label={t("admin.businessInformation.edit.phone_number")}
        required
      />

      <EditableGroup
        editForm={renderEditFormEmail}
        displayValues={
          <div className="pl-[8px] text-gray font-semibold">
            {formValues?.email}
          </div>
        }
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        validationSchema={editPlatformSchema(t)?.email}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item"
        label={t("common.email")}
        required
      />

      <p className="font-bold text-[18px] mt-[20px]">
        {t("admin.businessInformation.edit.payee_information")}
      </p>

      <EditableGroup
        editForm={renderEditFormPaypal}
        displayValues={
          <div className="pl-[8px] text-gray font-semibold">
            <div>{formValues?.paypal}</div>
          </div>
        }
        validationSchema={editPlatformSchema(t)?.paypal}
        // space
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item"
        label={t("admin.businessInformation.edit.pay_pal")}
      />

      <EditableGroup
        editForm={renderEditFormBankName}
        displayValues={
          <div className="pl-[8px] text-gray font-semibold">
            <div>{formValues?.bank_name}</div>
          </div>
        }
        validationSchema={editPlatformSchema(t)?.bankName}
        // space
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item"
        label={t("admin.businessInformation.edit.bank_name")}
      />

      <EditableGroup
        editForm={renderEditFormBranchName}
        displayValues={
          <div className="pl-[8px] text-gray font-semibold">
            <div className="truncate">{formValues?.branch_name}</div>
          </div>
        }
        validationSchema={editPlatformSchema(t)?.branchName}
        // space
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item"
        label={t("admin.businessInformation.edit.branch_name")}
      />

      <EditableGroup
        editForm={renderEditFormAccountNumber}
        displayValues={
          <div className="pl-[8px] text-gray font-semibold">
            <div>{formValues?.account_number}</div>
          </div>
        }
        validationSchema={editPlatformSchema(t)?.accountNumber}
        // space
        editBtnPosition={EDIT_BTN_POSITION.LABEL}
        initialValues={formValues}
        onSubmitGroup={onSubmitValue}
        containerClassName="editable-item"
        label={t("admin.businessInformation.edit.account_number")}
      />
    </>
  );
}

export default CommonInfo;
