import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {  useNavigate, useSearchParams } from "react-router-dom";
import AuthService from "app/services/api/AuthService";
import { useDispatch, useSelector } from "react-redux";
import jwt from "jwt-decode";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useTranslation } from "react-i18next";
// import { OldSocialLogin as SocialLogin } from "react-social-login";

import { gapi } from 'gapi-script';
import Alert from '@mui/material/Alert';
// import { GoogleLogin } from 'react-google-login';
import { setAuthUser, setAuthToken } from "app/redux/authReducer";
// import shareViaFacebook from "app/assets/svg/share_via_facebook.svg";
// import LineIcon from "app/assets/images/line_logo.png";
// import googleIcon from "app/assets/images/google.png";
import UserService from 'app/services/api/UserService';
import { setProfileStore } from 'app/redux/profileReducer';
import { EResponseStatus } from 'app/constant';
// import authPath from 'app/auth/route/Path';
import { setSocial } from '../../../../redux/socialReducer';
import Loading from '../../../../components/Loading';
import LayoutPageColor from "../../../../components/LayoutPageColor"
import CommonLogin, { mapDataInsta, mapDataTwitter, mapDataLine, mapDataTiktok } from './CommonLogin';
import Path from '../../../../operator/route/Path';

const mashupLogo = require('app/assets/images/buzz-logo.png');
// const tiktokIcon = require('app/assets/images/png-transparent-tiktok-tiktok-logo-tiktok-icon-thumbnail.png');
// const instagramIcon = require('app/assets/images/icon-instagram-thumbnail.png');

// const idFB = process.env.REACT_APP_FB_APP_ID;
const idGG = process.env.REACT_APP_GG_APP_ID;
// const idIstagram = process.env.REACT_APP_INSTA_APP_ID;
// const idLine = process.env.REACT_APP_LINE_APP_ID;
const idTwitter = process.env.REACT_APP_TWITTER_CLIENT_ID
const secretTwitter = process.env.REACT_APP_TWITTER_CLIENT_SECRET
// const idTiktok = process.env.REACT_APP_TIKTOK_CLIENT_KEY

// const csrfState = Math.random().toString(36).substring(2);
const redirectUri = `${window.location.origin}/login`;
// const lineUrl = `https://access.line.me/oauth2/v2.1/authorize?client_id=${idLine}&scope=openid+profile+email&state=${csrfState}&response_type=code&redirect_uri=${redirectUri}`;
// const twitterUrl = `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=${idTwitter}&redirect_uri=${redirectUri}&scope=tweet.read%20follows.read%20follows.write%20users.read%20offline.access&state=${csrfState}&code_challenge=challenge&code_challenge_method=plain`;
// const tiktokUrl = `https://www.tiktok.com/auth/authorize/?client_key=${idTiktok}&response_type=code&scope=user.info.basic&redirect_uri=${redirectUri}&state=${csrfState}`;
// const instagramUrl = `https://www.instagram.com/oauth/authorize/?app_id=${idIstagram}&redirect_uri=${redirectUri}&scope=user_profile%2Cuser_media&response_type=code&logger_id=958db70b-456e-40b6-91ee-1a492503d43c`

function SignInSocial(prop) {
  const { setNext } = prop
  const { t } = useTranslation();
  return (
    <div className="flex flex-wrap sm:flex-row-reverse items-stretch h-screen overflow-hidden ">
      <div
        className="h-[60%] md:h-full w-full md:w-1/2 xl:w-1/2 bg-cover flex"
      >
        <RegistrationForm className="my-8 md:my-auto shadow-lg" setNext={setNext} />
      </div>
      <LayoutPageColor text={t("auth.text_hearder")} />
    </div>
  );
}

function RegistrationForm(props) {
  const { t } = useTranslation();
  const { setNext } = props
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get('code');
  const redirect = searchParams.get('redirect');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const social = useSelector((state) => state.social);

  // init google auth
  useEffect(() => {
    const initClient = () => {
      window?.gapi?.client?.init({
        client_id: idGG,
        scope: "openid profile email",
        plugin_name: "ictl",
      });
    };
    window?.gapi?.load("client:auth2", initClient);
  }, []);

  useEffect(() => {
    if (redirect === '/?') {
      navigate('/login')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getSystemAccessToken = (provider, data) => {
    AuthService.loginWithSocial(provider, data)
      .then((response) => {
        const user = jwt(response.data.data.accessToken);
        user.is_first_login = response.data.data.is_first_login
        const token = {
          accessToken: response.data.data.accessToken,
          refreshToken: response.data.data.refreshToken,
        }

        dispatch(setSocial(null))
        dispatch(setAuthUser(user));
        dispatch(setAuthToken(token));
        UserService.getProfile().then((res) => {
          if (res.status === EResponseStatus.SUCCESS) {
            dispatch(setProfileStore(res.data.data));
          }
        });
        setLoading(false)
        if (response.data.data.is_first_login === true) {
          return navigate(Path.operator_provide_host)
        }
        if (redirect && redirect !== '/') {
          return navigate(redirect)
        }
        const path = CommonLogin(user)
        return navigate(path);
      }
      )
      .catch(() => {
        if (window.FB) {
          window.FB.getLoginStatus((response) => {
            if (response && response.status === "connected") {
              window.FB.logout(); // invoke fb status to prevent orveride token
            }
          });
        }
        const auth2 = gapi.auth2?.getAuthInstance();
        auth2?.signOut().then(() => auth2.disconnect()); // invoke google status
        dispatch(setSocial(null))
        setLoading(false)
        setError(true)
      })
    // .finally(() => {
    //   dispatch(setSocial(null))
    //   setLoading(false)
    //   setError(true)
    // });
  };
  // const LoginFaceBook = (result, err) => {
  //   if (!err) {
  //     setLoading(true)
  //     const data = mapDataFacebook(result)
  //     getSystemAccessToken(result.provider, data)
  //   }
  //   setError(true)
  // };
  // const onSuccessGoogle = (result) => {
  //   setLoading(true)
  //   const data = mapDataGoogle(result)
  //   return getSystemAccessToken('google', data)
  // };
  // const onFailureGoogle = () => {
  //   setError(true)
  // };

  const responseInstagram = (codes) => {
    setLoading(true)
    const data = mapDataInsta(codes, redirectUri)
    return getSystemAccessToken('instagram', data)
  }

  const getTwitterAccessToken = (codes) => {
    setLoading(true)
    const encodedData = window.btoa(
      `${idTwitter}:${secretTwitter}`
    );
    const data = mapDataTwitter(codes, encodedData, redirectUri)
    setLoading(true)
    return getSystemAccessToken("twitter", data);
  };

  const getLineAccessToken = (codes) => {
    setLoading(true)
    const data = mapDataLine(codes, redirectUri)
    return getSystemAccessToken("line", data, redirectUri);
  };

  const loginByTiktok = (codes) => {
    setLoading(true)
    const data = mapDataTiktok(codes)
    return getSystemAccessToken("tiktok", data);

  }

  useEffect(() => {
    if (social === "twitter" && code !== null) {
      getTwitterAccessToken(code);
    }
    if (social === "line" && code !== null) {
      getLineAccessToken(code)
    }
    if (social === "tiktok" && code !== null) {
      loginByTiktok(code);
    }
    if (social === "instagram" && code !== null) {
      responseInstagram(code)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <Loading />
    )
  }
  return (
    <form className='m-auto w-2/3 rounded-md bg-white p-3' style={{ textAlign: '-webkit-center' }}>
      <List component="nav" aria-label="mailbox folders" className='w-full'>
        <ListItem style={{ maxWidth: 422, minWidth: 'auto' }}>
          <img src={mashupLogo} className="w-full" alt="Logo" />
        </ListItem>
        {/* <button
          className="w-full py-[13px] px-[23px] border-2 flex items-center rounded-[8px] my-[16px] hover:bg-secondary "
          color="inherit"
          type="button"
          onClick={() => {
            setLoading(true)
            dispatch(setSocial('instagram'));
            window.location.href = instagramUrl;
          }}
        >
          <img className="h-[20px] w-[25px] rounded-full" src={instagramIcon} alt="" />
          <div className="flex-1 font-semibold sm:text-lg text-xs">{t('auth.register.Instagram')}</div>
        </button> */}
        {/* <button
          className="w-full py-[13px] px-[23px] border-2 flex items-center rounded-[8px] my-[16px] hover:bg-secondary"
          color="inherit"
          type="button"
          onClick={() => {
            dispatch(setSocial("tiktok"));
            window.location.href = tiktokUrl;
          }}
        >
          <img className="h-[20px] w-[20px] rounded-full" src={tiktokIcon} alt="" />
          <div className="flex-1 font-semibold sm:text-lg text-xs">{t('auth.register.TikTok')}</div>
        </button> */}
        {/* <button
          className="w-full py-[13px] px-[23px] border-2 flex items-center rounded-[8px] my-[16px] hover:bg-secondary"
          color="inherit"
          type="button"
          onClick={() => {
            setLoading(true)
            dispatch(setSocial("twitter"));
            window.location.href = twitterUrl;
          }}
        >
          <i className="text-xl fa-brands fa-twitter h-[20px] w-[20px] rounded-full" style={{ color: '#2563eb' }} />
          <div className="flex-1 font-semibold sm:text-lg text-xs">  {t('auth.register.Twitter')}</div>
        </button> */}
        {/* <SocialLogin
          provider="facebook"
          appId={idFB}
          callback={LoginFaceBook}
        >
          <button className="w-full py-[13px] px-[23px] border-2 flex items-center rounded-[8px] my-[16px] hover:bg-secondary" color="inherit" type="button">
            <img className="h-[20px] w-[20px] rounded-full" src={shareViaFacebook} alt="" />
            <div className="flex-1 font-semibold sm:text-lg text-xs"> {t('auth.register.Facebook')}</div>
          </button>

        </SocialLogin> */}
        {/* <button
          className="w-full py-[13px] px-[23px] border-2 flex items-center rounded-[8px] my-[16px] hover:bg-secondary"
          color="inherit"
          type="button"
          onClick={() => {
            dispatch(setSocial("line"));
            window.location.href = lineUrl;
          }}
        >
          <img className="h-[20px] w-[20px] rounded-full" src={LineIcon} alt="" />
          <div className="flex-1 font-semibold sm:text-lg text-xs"> {t('auth.register.Line')}</div>
        </button> */}

        {/* <GoogleLogin
          clientId={idGG}
          buttonText={t('auth.register.Google')}
          onSuccess={onSuccessGoogle}
          onFailure={onFailureGoogle}
          isSignedIn={false}
          render={renderProps => (
            <button onClick={renderProps.onClick} className="w-full py-[13px] px-[23px] border-2 flex items-center rounded-[8px] my-[16px] hover:bg-secondary" color="inherit" type="button">
              <img className="h-[20px] w-[20px] rounded-full" src={googleIcon} alt="" />
              <div className="flex-1 font-semibold sm:text-lg text-xs"> {t('auth.register.Google')}</div>
            </button>
          )}
        /> */}

        <button className="w-full py-[13px] px-[23px] border-2 flex items-center rounded-[8px] my-[16px] hover:bg-secondary" color="inherit" type="button" onClick={() => setNext(true)}>
          <MailOutlineIcon className="h-[20px] w-[20px] rounded-full" />
          <div className="flex-1 font-semibold sm:text-lg text-xs ">{t('auth.register.Message')}</div>
        </button>

        {/* <div className='my-[16px] underline underline-offset-1'>
          <Link
            to={authPath.register_facility}
            className="text-blue-600 hover:text-blue-700 focus:text-blue-700 transition duration-200 ease-in-out w-full"
          >
             {t('auth.register.Button_register')}
          </Link>
        </div> */}
        
        {error ?
          <ListItem >
            <Alert severity="error" className='w-full'>{t('auth.message.error_login')}</Alert>
          </ListItem> : ""
        }
      </List>
    </form>


  );
}

export default SignInSocial
