import React from "react";
import { useTranslation } from "react-i18next";

function DefaultLayout(props) {
  const {
    hidePrevNext,
    titleBox50vh,
    disabledPrevBtn,
    disabledNextBtn,
    onClickPrevBtn,
    onClickNextBtn,
    titleBoxContent,
    children,
    percentComplete,
    isSaveButton,
  } = props;
  const { t } = useTranslation();

  const getNextbtnClasses = () => {
    const baseClasses =
      "w-auto h-12 flex items-center justify-center px-7 py-0 rounded-lg text-base font-semibold text-white leading-5";

    if (isSaveButton) return `${baseClasses} text-white colorButtonCommon`;
    return `${baseClasses} ${
      disabledNextBtn
        ? "bg-[#dddddd] cursor-not-allowed"
        : "bg-dark cursor-pointer"
    }`;
  };

  return (
    <div className="w-screen h-screen overflow-hidden p-0 m-0">
      <div className="w-full h-full bg-gradient-to-r from-[#0E5CB5] to-[#ffffff]">
        <div className="w-full h-full">
          <div className="w-full h-full flex flex-col md:flex-row overflow-y-auto">
            <div
              className={`flex-1 flex-shrink-0 md:h-full md:w-1/2 md:bg-gradient-to-b md:from-[#ffffff] md:to-[#0E5CB5] ${
                titleBox50vh
                  ? "min-h-[50vh] text-white"
                  : "min-h-[30vh] text-dark"
              }`}
            >
              <div className="w-full h-full">{titleBoxContent}</div>
            </div>
            <div
              className={`w-full h-auto relative md:h-full md:w-1/2 md:overflow-y-auto bg-white ${
                titleBox50vh ? "min-h-[50vh]" : "min-h-[70vh]"
              }`}
            >
              <div className="absolute -top-3 inset-0 h-3 rounded-t-xl bg-white md:hidden " />

              <div
                className={`w-full flex items-stretch ${
                  hidePrevNext ? "pb-0" : "pb-[90px]"
                })`}
                style={{
                  minHeight: `calc(100% - ${hidePrevNext ? "0" : "90px"})`,
                }}
              >
                <div className="w-full bg-white">{children}</div>
              </div>

              {!hidePrevNext ? (
                <div className="sticky inset-x-0 bottom-0 h-20 bg-white flex flex-col z-50">
                  <div className="w-full h-0.5 bg-[#EBEBEB]">
                    <div
                      className="h-full bg-dark"
                      style={{ width: `${percentComplete}%` }}
                    />
                  </div>

                  <div className="w-full flex-1 px-6 md:px-10">
                    <div className="w-full h-full flex items-center justify-between">
                      <button
                        type="button"
                        className="text-base font-semibold leading-5 p-2.5 rounded-md bg-white hover:bg-[#f7f7f7] underline"
                        disabled={disabledPrevBtn}
                        onClick={onClickPrevBtn}
                      >
                        {t("operator.host.common.back")}
                      </button>

                      <button
                        type="button"
                        className={getNextbtnClasses()}
                        disabled={disabledNextBtn}
                        onClick={onClickNextBtn}
                      >
                        {t(
                          isSaveButton
                            ? "operator.host.tab8.save"
                            : "operator.host.common.next"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DefaultLayout;
