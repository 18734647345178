export default {
  list: {
    facility_name: "施設名",
    status: "公開ステータス",
    task: "タスク",
    edit: "編集する",
    booking: "今すぐ予約",
    on: "オン",
    off: "オフ",
    inventories: "在庫数",
    products: "商品数",
    area: "住所",
    last_updated: "最終更新日",
    total_facility: "施設 {{quantity}}件",
    create_facility: "施設を作成",
    find_facility: "施設の検索",
    facility_type: "施設タイプ",
    public_status: "公開ステータス",
    public: "Public",
    private: "Private",
    productSearch: "商品の検索",
  },
  detail: {
    facility_detail: "施設の詳細",
    facility_open_hours: "施設の営業時間",
    preview: "プレビューする",
    photos: "写真",
    photoQuantity: "写真{{quantity}}枚",
    basic_info: "施設の基本情報",
    title: "施設のタイトル",
    description: "施設の説明",
    language: "言語",
    status: "施設のステータス",
    equip: "アメニティ・設備",
    location: "番地",
    location2:"所在地",
    phone_number: "電話番号",
    accessible: "アクセシビリティ対応",
    facility_type: "施設の種類",
    use_my_location: "現在地を使用",
    post_code: "郵便番号",
    state_province: "都道府県",
    country: "国",
    apartment_name: "アパート名、部屋番号など",
    city: "市区町村",
    open_hours: "営業時間",
    menu_layout: "メニューレイアウト",
    payment_method: "支払い方法",
    setting_fee: "料金設定",
    bath_tax: "入湯税",
    consumption_tax_rate: "消費税率",
    searchTagPlaceholder: "タグを検索",
    all_photo: "すべての写真",
    upload_image: "写真をアップロード",
    drop_and_upload: "ドロップしてアップロード",
    enter_caption: "キャプションを入力",
    upload_img_desc: "ドラッグ＆ドロップで写真の並べ替えができます。",
    accommodation: "宿泊施設",
    restaurant: "飲食店",
    coverImgTitle: "カバー写真でリスティングの第一印象が決まります。",
    checkInTime: "チェックイン時刻",
    checkOutTime: "チェックアウト時刻",
    restaurantCheckInTime: "営業開始時刻",
    restaurantCheckOutTime: "営業終了時刻",
    regularHoliday: "定休日",
    changeImg: "写真を変更する",
    keyword: "施設のキーワード",
    only_staff: "従業員にのみ公開",
    setting_time: "時間設定",
    title_desc: "施設のタイトルを設定しましょう。",
    desc_desc: "施設の説明を設定しましょう。",
    email_desc: "施設のメールアドレスを設定しましょう。",
    open_time_desc: "施設の営業時間を設定しましょう。",
    regular_holiday_desc: "施設の定休日を設定しましょう。",
    payment_method_desc: "支払い方法を設定しましょう。",
    serivce_charge_desc: "サービス料を徴収する場合には設定しましょう。",
    bathing_tax_desc: "サービス料を徴収する場合には設定しましょう。",
    tax_desc: "サービス料を徴収する場合には設定しましょう。",
    visible_and_status_desc: {
      public: "ポータルサイトに公開され、予約を受け付ける事が出来ます。",
      private: "ポータルサイトに公開されず、予約を受け付ける事が出来ません。",
      staff_only: "",
      delete: "Buzz Travelerから削除されます。",
    },
    phone_number_desc: "施設の電話番号を設定しましょう。",
    keyword_desc: "施設のキーワードを設定しましょう。",
    already_booking: "予約データがある為、削除出来ません。"
  },
};

// operator.facility.detail
