import React from "react";
import Auth from "app/auth/pages/Auth/login";
// import RegisterFacility from "app/auth/pages/Auth/register/facility";
import ChangePassword from "../pages/Auth/change-password";
import NotFound from "../pages/404";
import Path from "./Path";

export default [
  { path: Path.login, element: <Auth /> },
  // { path: Path.register_facility, element: <RegisterFacility /> },
  { path: Path.change_password, element: <ChangePassword /> },
  { path: Path.notFound, element: <NotFound /> },
];
