// m-008-001
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import LayoutDefault from "app/operator/layouts/LayoutDefault";
import MainContent from "app/components/MainContent";
import { useTranslation } from "react-i18next";
import { randomInt } from "app/utils";
import CommonInfo from "app/operator/pages/Operator/Info/CommonInfo";
import AdminInfo from "app/operator/pages/Operator/Info/AdminInfo";
import Invoice from "app/operator/pages/Operator/Info/Invoice";
import { OPERATOR_API_ENDPOINT } from "app/services/constant";
import CommonService from "app/services/api/CommonService";
import { EResponseStatus } from "app/constant";
import {
  formatAdminPersonalInfo,
  formatEditAdminPersonalInfo,
} from "app/utils/page/operatorInfo";
import { useSelector } from "react-redux";
import DetailListContent from "app/components/DetailListContent";
import ErrorDialog from "app/components/ErrorDialog";

export const OperatorInfoContext = createContext();

let rawData;

function OperatorInfo() {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState({});
  const user = useSelector((state) => state.auth.user);
  const listAdmin = rawData?.agencies || [];
  const [openError, setOpenError] = useState(false);
  const [message, setMessage] = useState("");

  // get detail info

  const getDetailData = () => {
    CommonService.getModelList(OPERATOR_API_ENDPOINT.GET_ADMIN_INFO)
      .then((response) => {
        if (response.status === EResponseStatus.SUCCESS) {
          rawData = response?.data?.data;
          setFormValues(formatAdminPersonalInfo(rawData));
        }
      })
      .catch(() => {
        // setErrorMess(t("common.message.cannot_display_data"));
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getDetailData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitEdit = useCallback((endpoint, data) => {
    CommonService.postModel(
      endpoint,
      formatEditAdminPersonalInfo(data, rawData)
    )
      .then((response) => {
        if (response.status === EResponseStatus.CREATED) {
          getDetailData();
        }
      })
      .catch((error) => {
        setOpenError(true);
        const errorMessage = error?.response?.data?.message;
        if (errorMessage === "ACCOUNT_EXIST") {
          setMessage(
            t(`common.message.${errorMessage}`, { email: data?.email })
          );
        } else {
          setMessage(t("common.message.update_fail"));
        }
        setFormValues(formatAdminPersonalInfo(rawData));
      })
      .finally(() => {
        // setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const operatorContextProvider = useMemo(
    () => ({
      formValues,
      setFormValues,
      onSubmitEdit,
      groupId: user?.group_id,
    }),
    [formValues, setFormValues, onSubmitEdit, user?.group_id]
  );

  const renderRightContent = () => {
    return (
      <>
        <CommonInfo />
        <div className="flex flex-col">
          {listAdmin?.length > 0 &&
            listAdmin
              ?.slice(0, 1)
              .map((data, index) => (
                <AdminInfo key={randomInt(5)} data={data} index={index + 1} />
              ))}
        </div>
        <Invoice />
      </>
    );
  };

  return (
    <OperatorInfoContext.Provider value={operatorContextProvider}>
      <LayoutDefault showFooter showHeader>
        <MainContent>
          <DetailListContent
            rightContent={renderRightContent()}
            title={t("operator.operatorInfo.title")}
          />
        </MainContent>
        <ErrorDialog
          message={message}
          open={openError}
          onClose={() => setOpenError(false)}
          onOK={() => setOpenError(false)}
        />
      </LayoutDefault>
    </OperatorInfoContext.Provider>
  );
}

export default OperatorInfo;
