// m-007-003
import React, { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getProjectDetailData } from "../../../../utils/page/project";
import LayoutDefault from "../../../layouts/LayoutDefault";
import Path from "../../../route/Path";
import { ProjectDetailContext } from "../AdvertisementDetail";
import GeneralInfo from "./GeneralInfoGroup";
import TabGroup from "./TabGroup";

function AdvertisementSocial() {
  const params = useParams();
  const { projectId } = params;
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getProjectDetailData({ setFormValues, projectId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const projectDetailContextProvider = useMemo(
    () => ({
      formValues,
      id: params?.projectId,
      setFormValues,
      // onEditProject,
    }),
    [formValues, setFormValues, params?.projectId]
  );

  return (
    <ProjectDetailContext.Provider value={projectDetailContextProvider}>
      <LayoutDefault showFooter showHeader>
        <div
          id="social-detail-project"
          className="fixed left-0 top-[100px] border-b-2 border-border w-full px-[20px] md:px-[100px] py-[24px] bg-white z-10"
        >
          <Link
            to={Path.ad_project}
            className="absolute left-7 -top-2 md:top-0 md:left-10"
          >
            <i className="fa-solid fa-chevron-left" />
          </Link>
          <GeneralInfo />
        </div>
        <div className="pt-[226px] container flex-1 flex flex-col">
          <TabGroup />
        </div>
      </LayoutDefault>
    </ProjectDetailContext.Provider>
  );
}
export default AdvertisementSocial;
