import React, { useEffect, useState } from "react";
import cx from "classnames";
import CircularProgress from "@mui/material/CircularProgress";
import CommonService from "app/services/api/CommonService";
import { Popover } from "@mui/material";
import { t } from "i18next";
import CustomModal from "app/operator/components/CustomModal";
import { formatHeicFile } from "app/utils";
import { isVideoRegex } from "../../../../constant";
import { FILE_UPLOAD_TYPE } from "../../../../chat/enum/ChatEnum";
import Media from "../../../../components/Media";

function Upload({
  data,
  id,
  onDelete,
  callbackUploadSuccess,
  callbackUploadError,
  uploaded,
  disableDetele,
  isRearrange,
  setIsRearrange,
  blank,
  arrangeIndex,
  onDropRearrange,
}) {
  const defaultImg = !uploaded && URL.createObjectURL(data);
  const [anchorEl, setAnchorEl] = useState(false);
  const [visibleModalConfirmDelete, setVisibleModalConfirmDelete] =
    useState(false);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? false : event.currentTarget);
  };

  const isVideo = uploaded
    ? isVideoRegex?.exec(`${data}`.toLowerCase())
    : isVideoRegex?.exec(`${data?.name}`.toLowerCase());

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (data && !uploaded) {
      const fileFormat = formatHeicFile(data)

      CommonService.uploadFile(
        fileFormat || data,
        isVideo ? FILE_UPLOAD_TYPE.VIDEO : FILE_UPLOAD_TYPE.IMAGE
      )
        .then((res) => {
          if (callbackUploadSuccess) {
            callbackUploadSuccess({ url: res?.data?.data?.media_url, id });
          }
        })
        .catch((err) => {
          if (callbackUploadError) {
            callbackUploadError(err);
          }
        })
        .finally(() => {});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDrop = () => {
    if (onDropRearrange) {
      onDropRearrange();
    }
  };

  const onDragLeave = () => {
    if (blank) {
      setIsRearrange((prev) => ({
        itemOriginId: prev?.itemOriginId,
      }));
    }
  };

  const onDragOver = () => {
    const { itemTargetIndex, itemOriginId } = isRearrange || {};
    if (!blank && itemOriginId && itemTargetIndex !== arrangeIndex) {
      setIsRearrange((prev) => ({
        itemOriginId: prev?.itemOriginId,
        itemTargetIndex: arrangeIndex,
      }));
    }
  };

  if (blank) {
    return <div onDrop={onDrop} className="border-dashed h-full" />;
  }

  return (
    <>
      <button
        type="button"
        draggable={uploaded}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        onDragStart={() => {}}
        onDragEnd={() => {
          setIsRearrange();
        }}
        onMouseDown={() => {
          setIsRearrange({ itemOriginId: id });
        }}
      >
        <div>
          <div className={cx("relative pt-[60%]")}>
            {uploaded ? (
              <i
                role="presentation"
                onClick={handleClick}
                className="absolute cursor-pointer top-0 right-0 fa-solid fa-ellipsis bg-white bg-opacity-85 rounded-full m-[8px] p-[8px] z-10 shadow-xl"
              />
            ) : (
              <div className="absolute bg-white bg-opacity-85 top-0 left-0 w-full h-full z-10">
                <CircularProgress className="p-[8px] text-dark" />
              </div>
            )}
            <Media
              data={uploaded ? data : defaultImg}
              controls
              className="absolute top-0 left-0 h-full w-full bg-secondary"
            />
          </div>
        </div>
      </button>
      <Popover
        id={anchorEl ? "filter-popper" : undefined}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="bg-white p-[16px] shadow-[0_0_4px_rgba(0,0,0,0.2)]">
          {/* <button
            type="button"
            className="p-[8px] cursor-pointer w-full text-start"
          >
            {t("common.action.edit")}
          </button> */}
          <button
            type="button"
            disabled={disableDetele}
            onClick={() => setVisibleModalConfirmDelete(true)}
            className={cx("p-[8px] cursor-pointer w-full text-start", {
              "text-gray": disableDetele,
            })}
          >
            {t("common.action.delete_2")}
          </button>
        </div>
      </Popover>
      <CustomModal
        visible={visibleModalConfirmDelete}
        onSubmit={() => {
          if (onDelete) {
            onDelete(data);
            setAnchorEl(false);
            setVisibleModalConfirmDelete(false);
          }
        }}
        onClose={() => {
          setAnchorEl(false);
          setVisibleModalConfirmDelete(false);
        }}
        cancelText={t("common.action.cancel")}
        confirmText={t("common.action.delete")}
        title={
          <div className="px-[24px] text-[16px] font-bold">
            {t("operator.advertisement.detail.confirm_delete_image")}
          </div>
        }
      >
        <div className="py-[24px]">
          {t("operator.advertisement.detail.confirm_delete_notice")}
        </div>
      </CustomModal>
    </>
  );
}

export default Upload;
