export default {
  title: "事業者情報",
  basic_info: "基本情報",
  service_name: "事業者名",
  address: "住所",
  phone: "電話番号",
  admin_basic_info: "代表管理者",
  admin_name: "氏名",
  invoice_info: "インボイス",
  invoice_number: "インボイス番号",
  long_lat: "緯度/経度",
};