export default {
  detail: {
    leftTitle: "Recruitment Cases",
    image: "Photos",
    adsGeneralInfo: "Basic Information of Recruitment Cases",
    slide: "",
    piece: "",
    total_images: "{{value}} Photos",
    advertisement: "Recruitment Cases: {{value}} Available",
    createAdvertisement: "Create Recruitment Cases",
    searchAdvertisement: "Search Recruitment Cases",
    searchPlatform: "Search for Projects and Businesses",
    translateRequest: "Translation Request",
    uploadDueDate: "Publication Period",
    receiveSNS: "SNS Platforms for Posting",
    openTransactionDetail: "Self PR Confirmation",
    verifySNS: "{{sns}} Authenticated",
    uploadedInfo: "Results Information",
    billingAmount: "Reward amount (excluding tax)",
    uploadDate: "Completion report date and time",
    uploadLink: "Link to the posting location",
    influencersComment: "Comments from the influencer",
    ad_project_name: "Recruitment Cases Name",
    apply_quantity: "Number of Applications",
    applied_quantity: "Number of Approvals",
    completed_quantity: "Number of Completions",
    project_title: "Recruitment Cases Title (Japanese)",
    project_description: "Recruitment Cases Description",
    project_status: "Recruitment Cases Description",
    recuitmentDate: "Post Period",
    reward_type: "Reward Type",
    reward: "Reward",
    desired_condition: "Reward Conditions",
    age_group: "Preferred Influencers Age Range",
    min_followers: "Minimum Followers/Subscribers on Designated SNS",
    price: "¥",
    ad_method: "PR Method",
    tagInclude: "Tags to Include in Posts",
    targetUrl: "Pages for PR",
    influencer_gender: "Influencer Gender",
    adMedia: "Designated SNS",
    targetUsername: "Tagged Accounts",
    textInclude: "Content to Include in Posts",
    video: "Videos",
    "image, video": "Videos ・ Photos",
    already_applied:
      "The influencer is currently in 'progress' or 'awaiting completion approval,' so it cannot be deleted.",
    chat_header: "Chat",
    title_description:
      '(If the reward type is "Free Offer", please also specify the items or products offered for free)',
    fixed_price: "Fixed Compensation",
    free_supply: "Free Offer",
    offer_price: "Original Offering Price",
    offer_price_description:
      '(If the reward type is "Free Offer", please specify the original offering price of the items or products offered for free)',
    collapse_en_title: "ENGLISH",
    collapse_en_label: "Recruitment Cases Title（ENGLISH）",
    collapse_cn_title: "Traditional Chinese",
    collapse_cn_label: "Recruitment Cases Title（Traditional Chinese）",
    en_descrip_label: "Recruitment Cases Description（ENGLISH）",
    cn_descrip_label: "Recruitment Cases Description（Traditional Chinese）",
    en_condition_label: "Reward Conditions（ENGLISH）",
    cn_condition_label: "Reward Conditions（Traditional Chinese）",
    en_include_label: "Content to Include in Posts（ENGLISH）",
    cn_include_label: "Content to Include in Posts（Traditional Chinese）",
    publish_status: "Publication Status",
    cover_photo: "Cover Photo",
    cover_photo_title:
      "The cover photo determines the first impression of your listing.",
    change_cover_photo: "Change Photo",
    all_photos: "All Photos",
    upload_photo_drag: "You can rearrange photos by dragging and dropping.",
    upload_photo: "Upload Photo",
    chat_notice: "※チャットが有効になっている募集案件のみ表示しています。",
    select_cover_image: "Choose a Cover Photo",
    upload_other: "Upload a Photo",
    confirm_delete_image: "Do you want to delete this photo?",
    confirm_delete_notice: "Once deleted, it cannot be undone.",
  },
  account_info: {
    in_progress_mess: "{{from}} approved the application on {{- date}}.",
    in_progress_special_mess:
      "The influencer approved the application on {{- date}}.",
    declined_mess: "{{from}} rejected the application on {{- date}}.",
    completed_mess: "{{from}} approved the completion report on {{- date}}.",
    reject_mess: "{{from}} rejected the completion report on {{- date}}.",
    wait_recuitment_mess:
      "You received an application from an influencer on {{- date}}.",
    special_offer_mess: "You sent a special offer on {{- date}}.",
    send_deliverable_mess:
      "You received a completion report from an influencer on {{- date}}.",
    cancel_transaction_mess_2: "This transaction was canceled on {{- date}}.",
    cancel_transaction_mess: "The transaction has been canceled.",
    cancel_transaction: "Cancel Transaction",
    reason: "Reason",
    approve_application: "Approve the application",
    reject_application: "Reject the application",
    approved_report: "Approve the completion",
    reject_report: "Reject",
    wait_recuitment: "You received an application from an influencer.",
    wait_finished: "You received a completion report from an influencer.",
    completed: "Completion done.",
    download_zip: "Download All",
  },
  dialog: {
    reject_application_mess_1:
      "Do you want to reject the application approval?",
    reject_application_mess_2: 'If so, click the "Send" button.',
    reject_report_mess_1: "Do you want to reject the completion approval?",
    reject_report_mess_2:
      'If you do, please enter the reason and click "Send."',
    reject_report_placeholder: "Enter the reason for rejection",
    approve_report_mess_1: "Do you want to approve the completion?",
    approve_report_mess_2: "If you do, select the influencer's rating.",
    approve_report_mess_3: "Tap the stars to rate the influencer.",
    approve_report_placeholder: "Please enter your comments.",
  },
  social: {
    all: "All ({{value}})",
    in_progress: "In Progress ({{value}})",
    unread_mess: "Unread Messages ({{value}})",
    approve_report: "Completion Report Approved ({{value}})",
    ping_operator: "Translation Request ({{value}})",
    transaction_tab: "Exchange History ({{value}})",
    influencer_tab: "Influencers Search",
    transaction_descrip: "Filter Influencers to Match",
    count_wait_approved_complete:
      "Awaiting Completion Report Approval ({{value}})",
    count_cancel_transaction:
      "Transaction Cancellation Applications ({{value}})",
    count_action_required: "Needs Attention Mark ({{value}})",
    pr_completed: "PR投稿が完了しました",
    insta: "Instagram Followers",
    tiktok: "TikTok Followers",
    twitter: "Twitterフォロワー数",
    facebook: "Facebook Followers",
    youtube: "YouTube Channel Subscribers",
    self_intro: "Self-Introduction",
    send_offer: "Request Offer Button",
    send_offer_fail:
      "Failed to send the offer request. Please try again.",
    influencer_descrip: "Narrow down influencers by search",
    influencer_placeholder: "Influencers Search",
    influcener_name: "Display Name",
    rating: "Rating",
  },
};
