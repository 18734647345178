import React, { useRef, useState, useEffect, useCallback } from "react";
import {
  socketIO,
  SOCKET_LISTENER,
  SOCKET_NAMESPACE,
  SOCKET_TYPE,
} from "app/context/socket";
import MessageInput from "app/chat/components/MessageInput";
import MessageList from "app/chat/components/MessageList";
import ChatService from "app/services/chat/api/ChatService";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import {
  APPLIED_PROJECT_STATUS,
  EProjectRequest,
  EResponseStatus,
  toastDefaultOptions,
} from "app/constant";
import { toast } from "react-toastify";

function Chatbox({
  chatBoxRef,
  listMessageRef,
  scrollRef,
  chatRoomId,
  isLoading,
  apiMessages,
  handleLoadMore,
  handleNewMessage,
  handleUnreadChatRoom,
  listMessageClassName,
  messageInputClassName,
  wantScrollToBottomFirstLoad,
  statusText,
  avatarUrl,
  chatboxClassName,
  sendBy,
  showPing,
  allowHandleMarginBottom,
  isLastPage,
  handleGetMoreMessages,
  isPing,
}) {
  // context
  // const socket = useContext(SocketContext)(
  //   SOCKET_TYPE.CHAT,
  //   SOCKET_NAMESPACE.CHAT
  // );

  // ref
  const messageInputRef = useRef({ clientHeight: 0 });
  const { t } = useTranslation();
  const [markIsPing, setMarkIsPing] = useState(isPing);

  // socket
  const [newMessageRecept, setNewMessageRecept] = useState();

  // state
  const [messageList, setMessageList] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [showScrollToBottom, setShowScrollToBottom] = useState(false);
  const [listMessageMarginBottom, setListMessageMarginBottom] = useState(
    allowHandleMarginBottom ? 76 : 0
  );
  const [scrollBottomOffset, setScrollBottomOffset] = useState(0);

  const handleMarkIsPing = useCallback(() => {
    if (markIsPing) {
      const body = {
        is_ping: EProjectRequest.NOT_REQUEST,
      };
      ChatService.updatePingToOperator(chatRoomId, body).then((response) => {
        if (response.status === EResponseStatus.CREATED) {
          setMarkIsPing(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markIsPing]);

  /**
   * call api to send message with uploaded file (url)
   */
  const handleCallUploadFile = useCallback(
    ({ fileName, mediaUrl, thumbnailUrl, mediaType }) => {
      handleMarkIsPing();
      ChatService.sendMessage({
        chat_room_id: chatRoomId,
        message: fileName,
        mediaFiles: [
          {
            media_url: mediaUrl,
            thumbnail_url: thumbnailUrl,
            media_type: mediaType,
            display_order: 0,
          },
        ],
      });
    },
    [chatRoomId, handleMarkIsPing]
  );

  /**
   * call api to send new message
   */
  const handleCallSendMessage = useCallback(
    (message) => {
      handleMarkIsPing();
      ChatService.sendMessage({
        chat_room_id: chatRoomId,
        message,
      });
    },
    [chatRoomId, handleMarkIsPing]
  );

  /**
   * call back to scroll to bottom (mean scroll to newsest message)
   */
  const handleScrollToBottom = useCallback(() => {
    if (scrollRef) {
      const height = scrollRef.scrollHeight;
      scrollRef.scrollTo({
        top: height,
        left: 0,
        behavior: "smooth",
      });
      setShowScrollToBottom(false);
    }
  }, [scrollRef]);

  /**
   * callback to handle recipted new message from socket
   */
  const handleNewMessageReceipted = (newMess) => {
    if (!newMess) return;

    if (handleNewMessage) {
      handleNewMessage(newMess);
    }

    if (newMess.chat_room_id !== chatRoomId) {
      if (handleUnreadChatRoom) {
        handleUnreadChatRoom(newMess.chat_room_id);
      }
      return;
    }

    const isReachBottom =
      scrollRef.scrollTop + scrollRef.clientHeight >
      scrollRef.scrollHeight - 100; // 100 is offset. to make UX much better

    if (isReachBottom || newMess.send_by === sendBy) {
      const timeout = setTimeout(() => {
        handleScrollToBottom();
        setShowScrollToBottom(false);

        clearTimeout(timeout);
      }, 1);
    } else {
      setShowScrollToBottom(true);
    }

    setMessageList([...messageList, newMess]);
  };

  useEffect(() => {
    setIsFirstLoad(true);
    setMessageList([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatRoomId]);

  /**
   * @watch scrollRef
   *
   * if scrollRef exist add event listener to it
   * when scroll scrollRef, set value for reactBottom state
   * when scroll scrollRef, check offset top to preloading message
   */
  useEffect(() => {
    const handleScroll = () => {
      const isReachBottom =
        scrollRef.scrollTop + scrollRef.clientHeight >
        scrollRef.scrollHeight - 50; // 50 is offset. to make UX much better

      if (isReachBottom) {
        setShowScrollToBottom(false);
      }

      // offset 1/4 scrollRef.scrollHeight to preloading
      if (scrollRef.scrollTop <= scrollRef.scrollHeight * 0.25) {
        setScrollBottomOffset(scrollRef.scrollHeight - scrollRef.scrollTop);
        if (handleLoadMore) {
          handleLoadMore();
        }
      }
    };

    if (scrollRef) {
      scrollRef.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollRef) {
        scrollRef.removeEventListener("scroll", handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollRef, messageList, isLoading]);

  /**
   * @watch isFirstLoad
   * @watch isLoading
   *
   * if isLoading false and first isFirstLoad, scroll to bottom
   */
  useEffect(() => {
    if (scrollRef && !isLoading && isFirstLoad && wantScrollToBottomFirstLoad) {
      const timeout = setTimeout(() => {
        const height = scrollRef.scrollHeight + 100;
        scrollRef.scrollTo({
          top: height,
          left: 0,
          behavior: "auto",
        });
        clearTimeout(timeout);
        setIsFirstLoad(false);
      }, 1);
    }

    if (!wantScrollToBottomFirstLoad) {
      setIsFirstLoad(false);
    }
  }, [isFirstLoad, isLoading, scrollRef, wantScrollToBottomFirstLoad]);

  /**
   * @watch isLoading
   *
   * if apiMessages change, set state newMessages by apiMessages
   */
  useEffect(() => {
    if (!isLoading) {
      const lstIds = messageList.map((item) => item.chat_message_id);
      const lstTemp = apiMessages.filter(
        (item) => !lstIds.includes(item.chat_message_id)
      );
      setMessageList([...lstTemp, ...messageList]);
      setTimeout(() => {
        if (scrollRef && wantScrollToBottomFirstLoad) {
          scrollRef.scrollTo({
            top: scrollRef.scrollHeight - scrollBottomOffset,
            left: 0,
            behavior: "auto",
          });
        }
      }, 0.1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  /**
   * @watch socket
   * @watch handleNewMessageRecipted
   *
   */
  useEffect(() => {
    const socket = socketIO(SOCKET_TYPE.CHAT, SOCKET_NAMESPACE.CHAT, {
      room_id: chatRoomId,
    });

    socket.on(SOCKET_LISTENER.DISCONNECT, () => {
      socket.connect();
    });

    socket.on(SOCKET_LISTENER.POST_MESSAGE, (newMessage) => {
      setNewMessageRecept(newMessage);
    });
    return () => {
      socket.off(SOCKET_LISTENER.DISCONNECT);
      socket.off(SOCKET_LISTENER.POST_MESSAGE);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @watch socket
   * @watch handleNewMessageRecipted
   *
   */
  useEffect(() => {
    handleNewMessageReceipted(newMessageRecept);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMessageRecept]);

  const handleListMessageMarginBottom = (marginBottom) => {
    if (allowHandleMarginBottom) {
      setListMessageMarginBottom(marginBottom);
    }
  };

  const updatePingToOperator = () => {
    const body = {
      is_ping: EProjectRequest.REQUEST,
    };
    ChatService.updatePingToOperator(chatRoomId, body)
      .then((response) => {
        if (response.status === EResponseStatus.CREATED) {
          toast.success(t("chat.message.request_success"), {
            bodyClassName: "text-sm",
            ...toastDefaultOptions,
          });
        }
      })
      .catch(() => {
        toast.error(t("chat.message.request_fail"), {
          bodyClassName: "text-sm",
          ...toastDefaultOptions,
        });
      });
  };

  return (
    <div
      ref={chatBoxRef}
      className={`chat-box relative flex flex-col h-full ${chatboxClassName}`}
    >
      {/* {isLoading && !isFirstLoad && (
        <div className="absolute top-0 w-full flex justify-center z-10 py-5">
          <div className="flex justify-center items-center h-10 w-10 drop-shadow-lg rounded-full bg-purple">
            <CircularProgress
              style={{ color: "white" }}
              size={19}
              thickness={6}
            />
          </div>
        </div>
      )} */}

      <div className="flex flex-col items-center justify-center self-start w-full">
        <div className="flex flex-wrap justify-center items-center py-6 px-4 gap-y-1 gap-x-3">
          {!messageList?.length && <div>{t("chat.header.last_5_message")}</div>}
          {messageList?.length > 0 && messageList?.length <= 5 && (
            <div>{t("chat.header.last_5_message")}</div>
          )}
          {!isLastPage && (
            <button
              type="button"
              onClick={handleGetMoreMessages}
              className="text-gray-400 text-[0.75rem] cursor-pointer hover:text-blue-400"
            >
              {t("chat.header.show_more_message")}
            </button>
          )}
        </div>
      </div>

      <div
        ref={listMessageRef}
        className={`chat-list-detail grow p-3 md:px-10 ${listMessageClassName}`}
        style={{ marginBottom: listMessageMarginBottom }}
      >
        {messageList.length > 0 && (
          <>
            <MessageList
              messageList={messageList}
              avatarUrl={avatarUrl}
              sendBy={sendBy}
            />
            {showPing && (
              <div className="mt-4 mb-2">
                <Button
                  onClick={updatePingToOperator}
                  disabled={statusText === APPLIED_PROJECT_STATUS.COMPLETED}
                  type="button"
                  className="h-10 !px-[16px] !py-[4px] !rounded-[8px] !text-[#3B7DC5] !border !border-solid !border-[#3B7DC5]"
                >
                  {t("common.action.ping_platform")}
                </Button>
              </div>
            )}
          </>
        )}
      </div>
      <MessageInput
        statusText={statusText}
        messageInputClassName={messageInputClassName}
        messageInputRef={messageInputRef}
        showScrollToBottom={showScrollToBottom}
        handleScrollToBottom={handleScrollToBottom}
        handleCallSendMessage={handleCallSendMessage}
        handleCallUploadFile={handleCallUploadFile}
        handleListMessageMarginBottom={handleListMessageMarginBottom}
      />
    </div>
  );
}

export default Chatbox;
