export default {
  list: {
    total_item: "{{value}} Accounts",
    account_name: "Account Name",
    account_phone: "Phone Number",
    account_last_login: "Last Login Date",
    create_account: "Create Account",
    find_account_placeholder: "Search Staff",
  },
  form: {
    title: "Account Information",
    delete_notice:
      "The deleted account cannot be recovered. Are you sure you want to delete it?",
    family_name: "Surname",
    first_name: "Given name",
  },
};
