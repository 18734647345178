import operator from "../operator/langs/OperatorJA";
import admin from "../ictl/langs/IctlJA";
import chat from "../chat/langs/ChatJA";

export default {
  admin,
  operator,
  chat,
  auth: {
    text_hearder: "ログインタイプを選択してください",
    text_login_email: "ログイン情報を入力してください",
    register_or_login: "ログインまたは登録",
    mashupWelcome: "Buzz Travelerへようこそ",
    continue: "続行",
    continue_change_password: "続行する",
    current_password: "現在のパスワード",
    new_password: "新しいパスワード",
    confirm_new_password: "新しいパスワード（確認用）",
    change_password: "パスワードを変更してください。",
    password_do_no_match: "パスワードが一致していません。",
    email_not_exit:
      "メールアドレスはまだ登録されていません。もう一度ご確認ください。",
    password_reset_guide: "パスワード再設定ご案内",
    textSendEmail1:
      "パスワードリセット用のメールを、登録のメールアドレスに送信しました。",
    textSendEmail2:
      "メールに記載のパスワード変更画面にアクセス頂き、メールに記載の一時パ",
    textSendEmail3: "スワードを入力し、新しいパスワードを設定してください",
    change_password_success: "更新されました。",
    login: {
      reset_password: "パスワード忘れの方はこちらをクリックして下さい",
      Password: "パスワード",
      Login: " ログイン",
      Email_format: "メールアドレス形式が不正です。",
      Enter_wrong_infomation:
        "ID（メールアドレス）、パスワードのどちらかが間違っています。",
    },
    register: {
      Instagram: "Instagramで続行",
      TikTok: "TikTokで続行",
      Twitter: "Twitterで続行",
      Facebook: "Facebookで続行",
      Google: "Googleで続行",
      Line: "LINEで続行",
      Message: "メールアドレスで続行",
      Text_register: "事業者登録への扉を開きましょう",
      Button_register: "事業者登録をはじめる",
      Nickname: "Nickname",
      confirm_new_password: "新しいパスワード（確認用）",
      password_regex:
        "パスワードは半角英数字記号を含む8文字以上64文字以内で入力してください。",
    },
    message: {
      error_login: "エラーログイン",
    },
  },
  common: {
    email: "メールアドレス",
    male: "男性",
    female: "女性",
    other: "指定なし",
    other1: "指定なし",
    image: "写真",
    japan: "日本",
    currencySymbol: "¥ ",
    passage: "件",
    person: "人",
    quantity: "個数",
    followers: "フォロワー",
    date: "日",
    end_date: "末日",
    btn: {
      download_csv: "CSVファイルダウンロード",
      export: "エクスポート",
      filter: "フィルター",
      select_language: "言語と地域",
    },
    message: {
      update_success: "更新が完了しました。",
      delete_success: "削除されました。",
      update_fail: "更新に失敗しました。もう一度お試しください。",
      delete_fail: "削除に失敗しました。もう一度お試しください。",
      not_exist: "削除対象が存在しません。",
      wrong_current_password: "現在のパスワードが間違っています。",
      oversize_file: "アップロードできるファイルの最大サイズは{{size}}MBです。",
      oversize_image: "アップロード可能な画像サイズは最大{{size}}MBです。",
      oversize_video: "アップロード可能な動画サイズは最大{{size}}MBです。",
      wrong_format_file:
        "アップロード可能なファイルは以下のファイルです。\n{{format}}。",
      cannot_display_data:
        "データを読み込めません。 もう一度やり直してください。",
      no_data_display: "表示するデータがありません。",
      error_upload:
        "ファイルのアップロード中にエラーが発生しました。 もう一度やり直してください。",
      INVALID_PASSWORD: "現在のパスワードが正しくありません。",
      delete_confirm: "本当に削除しますか？",
      createSuccess: "登録が完了しました。",
      createFailed: "登録が失敗した。もう一度お試しください。",
      importFailed: "フォーマットが正しくありません。",
      fieldDateMin: "掲載期間には、過去の日付を設定出来ません。",
      fieldDateMax: "終了時間は開始時間より過去の日付を設定出来ません。",
      fileTooSmall: "50KBよりサイズの大きい画像をアップロードしてください。",
      invalidFileNumber:
        "アップロードできるのは最大50個です。そのうち動画は最大3個までアップロード可能です。",
      "Request failed with status code 403": "更新に失敗しました。",
      "Request failed with status code 401": "更新に失敗しました。",
      cannot_set_video_as_cover: "動画は、カバー写真へ設定できません。",
      at_least_5: "写真は最低5枚アップロードしてください。",
      ACCOUNT_EXIST: "このメールアドレスは既に登録されています。\n{{email}}",
      cancel_create:
        "保存されていません。編集内容はキャンセルされますがよろしいですか？",
      select_lang: "使用言語と地域を選択してください",
      DATA_MAX_RECORD: "バナーは最大{{max}}個までアップロードできます。",
      upload_avatar: "顔写真を変更",
      delete_log_out: "アカウントが削除されているためログアウトされます。",
      wrong_dimension:
        "幅{{width}}px、高さ{{height}}pxのファイルを選択してください。",
      choose_image: "画像を選択してください。",
      no_input_yet: "未設定",
      confirm_password_not_match:
        "パスワード(確認)とパスワードの入力が一致しません。",
      email_message: "メールアドレスを入力してください。",
      email_invalid_message: "メールアドレスの形式が不正です。",
      not_found: "申し訳ありませんが、お探しのものが見つかりません！",
      password_not_found: "パスワードを入力してください",
      current_password_not_found: "現在のパスワードを入力してください。",
      new_password_not_found: "新しいパスワードを入力してください。",
    },
    action: {
      edit: "編集",
      cancel: "キャンセル",
      save: "保存",
      back: "戻る",
      next: "次へ",
      apply: "適用",
      delete: "削除する",
      add_more: "さらに追加",
      clear: "クリア",
      apply1: "適用する",
      confirm: "確認",
      delete_2: "削除",
      delete_3: "取り消す",
      yes: "はい",
      send: "送信",
      close: "閉じる",
      ping_platform: "翻訳依頼",
      update: "更新する",
    },
    date_format: {
      dd_mm_yyyy: "DD日MM月YYYY年",
      yyyy_mm_dd: "YYYY年MM月DD日",
      yyyy_mm: "YYYY年MM月",
      mm_dd: "MM月DD日",
      dd: "DD日",
      just_now: "vừa xong",
      mins_ago: "phút trước",
      today: "hôm nay",
      yesterday: "hôm qua",
      yyyy_MM: "yyyy年MM月",
    },
    weekDays: {
      monday: "月",
      tuesday: "火",
      wednesday: "水",
      thursday: "木",
      friday: "金",
      saturday: "土",
      sunday: "日",
    },
    weekDaysType1: {
      monday: "月曜",
      tuesday: "火曜",
      wednesday: "水曜",
      thursday: "木曜",
      friday: "金曜",
      saturday: "土曜",
      sunday: "日曜",
    },
    days: {
      today: "今日",
      yesterday: "昨日",
      thisWeek: "今週",
      lastWeek: "先週",
      thisMonth: "今月",
      lastMonth: "先月",
    },
    common_booking: {
      separator_guest_info: "、",

      prefix_number_users: "ゲスト",
      suffixes_number_users: "人",
      prefix_number_user: "ゲスト",
      suffixes_number_user: "人",

      prefix_number_infants: "乳幼児",
      suffixes_number_infants: "名",
      prefix_number_infant: "乳幼児",
      suffixes_number_infant: "名",

      prefix_number_pets: "ペット数：",
      suffixes_number_pets: "",
      prefix_number_pet: "ペット数：",
      suffixes_number_pet: "",
    },
    charge_tax_titles: {
      bathing_tax: "入湯税",
    },
  },
  validate_mark: "*",
  validate_message: {
    required: "{{path}}を入力してください。",
    max_length: "{{path}}は{{max}}文字以内で入力してください。",
    min_length: "{{path}}{{min}}文字以上で入力してください。",
    wrong_format: "{{path}}の形式が不正です。",
    onlyWhiteSpace: "{{path}}には空白だけを含めることはできません。",
  },
  validation_field: {
    post_code: "郵便番号",
    replyMessage: "返信メッセージ",
    password: "パスワード",
    confirm_password: "新しいパスワード（確認用）",
    email: "メールアドレス",
    projectTitle: "募集案件のタイトル",
    projectDescription: "施設の募集案件の説明",
    projectRewardType: "報酬タイプ",
    projectReward: "報酬",
    desiredCondition: "報酬条件",
    ageGroup: "希望するインフルエンサーの年齢層",
    gender: "性別",
    influencer_gender: "インフルエンサー性別",
    minFollowers: "指定SNS最低フォロワー数 / 登録者数",
    adMethod: "PR方法",
    tagInclude: "投稿に含めるタグ",
    targetUrl: "PRして欲しいページ",
    adMedia: "指定SNS",
    targetUsername: "タグ付けアカウント",
    textInclude: "投稿に含める内容",
    family_name: "姓",
    first_name: "名",
    familyName: "姓",
    firstName: "名",
    login_id: "メールアドレス",
    phone_number: "電話番号",
    offerMessage: "メッセージ",
    completeReject: "承認の完了を拒否する理由",
    completeApprove: "承認メッセージの完了",
    current_password: "現在のパスワード",
    new_password: "新しいパスワード",
    confirm_new_password: "パスワードを認証する",
    service_provider_name: "事業者名",
    address_address: "番地",
    address_city: "市区町村",
    address_street: "都道府県",
    memo: "メモ",
    name: "名称",
    registration_number: "インボイスナンバー",
    pricing_plans: "月額プラン",
    reason: "理由",
    postcode: "郵便番号",
    country: "国",
    city: "市区町村",
    street: "都道府県",
    address: "番地",
    banner_title: "タイトル",
    banner_files: "画像",
    banner_delivery_date: "開始日",
    banner_end_date: "終了日",
    banner_address_url: "URL",
    offer_price: "本来の提供価格",
    account_first_name: "アカウント名",
    account_family_name: "アカウント名",
    account_email: "メールアドレス",
    account_phone: "電話番号",
    delivery_date: "開始",
    end_date: "終了",
    manager_first_name: "氏名",
    manager_family_name: "氏名",
    invoice_number: "インボイス番号",
    manager_name: "氏名",
    bussiness_person_email: "メールアドレス",
    apartment_name: "アパート名、部屋番号など",
    full_address: "住所",
    projectTitleCN: "募集案件のタイトル",
    projectTitleEN: "募集案件のタイトル",
    projectDescriptionCN: "募集案件の説明",
    projectDescriptionEN: "募集案件の説明",
    desiredConditionEN: "報酬条件",
    desiredConditionCN: "報酬条件",
    textIncludeEN: "投稿に含める内容",
    textIncludeCN: "投稿に含める内容",
    ageGroupStart: "希望するインフルエンサーの年齢層",
    ageGroupEnd: "希望するインフルエンサーの年齢層",
    longitude: "経度",
    latitude: "緯度",
    lat_long: "緯度・経度",
  },
  dialog: {
    customer_search: {
      title: "お客様選択",
      placeholder: "お客様名で検索",
    },
    product_search: {
      title: "商品選択",
      placeholder: "商品名で検索",
    },
  },
  ictl_header: {
    home: "ダッシュボード",
    business_person: "事業者",
    influencer: "インフルエンサー",
    operation: "運営",
    claim: "請求",
    payment: "支払",
    notification: "お知らせ",
    banner: "バナー",
    account: "アカウント",
  },
  operator_header: {
    dashboard: "事業者ホーム",
    other_menu: "メニュー",
    jobs: "募集案件",
    pay: "支払い",
    operator_info: "事業者",
  },
  common_header: {
    profile: "プロフィール",
    account_setting: "アカウント",
    log_out: "ログアウト",
  },
  common_footer: {
    privacy_policy: "プライバシー",
    term_service: "利用規約",
    company_info: "企業情報",
    currency: "日本語",
    copyright: "© Copyright 2023 株式会社明勝 All Rights Reserved."
  },
  project_request_select: {
    request: "翻訳依頼 有",
    not_request: "翻訳依頼 無",
  },
  project_status_select: {
    public: "公開",
    private: "非公開",
    trash: "削除",
  },
  change_language: "言語と地域",
  or1: "ま",
  or2: "た",
  or3: "は",
  profile: {
    register_as: "{{- date}}から事業者アカウントとして登録",
    register_as_operator: "{{- date}}から運営アカウントとして登録",
  },
  gender: "性別",
  business_operator: "事業者",
  operator_admin: "運営",
  notification_popup: {
    show_more: "詳細を確認",
  },
  media: {
    twitter : 'X (旧Twitter)'
  },
  generation1: "代",
  generation2: "代",
  generation3: "代以上"
};
