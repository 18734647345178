import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import MainContent from "app/components/MainContent";
import LayoutDefault from "app/operator/layouts/LayoutDefault";
import { EDIT_IMAGE_ID_LIST } from "app/utils/page/facility";
import FacilityNavigate from "app/operator/pages/Facility/FacilityNavigate";
import UploadFile from "./UploadFile";
import CoverImage from "./UploadFile/CoverImage";
import {
  FILE_ERRORS,
  limitFileSize,
  LIMIT_VIDEO,
  maximumFile,
  MAX_MEDIA_QUANTITY,
  mediaUploadAllowedExtensions,
  minimumFileSize,
  videoLimitFileSize,
} from "../../../constant";
import { getNearestImg, randomInt } from "../../../utils";
import DetailListContent from "../../../components/DetailListContent";

function ImageEdit({
  topContent,
  initialValues,
  onUpdateAllInfo,
  backLink,
  min,
  limit,
}) {
  const { t } = useTranslation();
  const [imageList, setImageList] = useState(initialValues?.imageList || []);
  const [coverImg, setCoverImg] = useState(initialValues?.coverImg || "");
  const [uploadingListImage, setUploadingListImage] = useState([]);
  const [uploadError, setUploadError] = useState(false);

  const videoQuantityExceed = useMemo(() => {
    let countVid = 0;
    imageList.forEach((item) => {
      if (`${item?.url}`?.toLowerCase()?.includes("mp4")) {
        countVid += 1;
      }
    });
    return countVid >= LIMIT_VIDEO;
  }, [imageList]);

  const handleFileUpload = (e) => {
    setUploadError(false);
    const files = Object.values(e?.dataTransfer?.files || e?.target?.files);
    const allNumberOfFile = (imageList?.length || 0) + (files.length || 0);

    if (files.length && allNumberOfFile <= maximumFile) {
      const arr = files
        ?.map((file) => {
          if (mediaUploadAllowedExtensions.exec(file?.name?.toLowerCase())) {
            const isVideo = file?.type?.toLowerCase()?.includes("mp4");
            if (file?.size < minimumFileSize) {
              setUploadError(FILE_ERRORS.TOO_SMALL);
              return null;
            }

            if (isVideo) {
              if (videoQuantityExceed) {
                setUploadError(FILE_ERRORS.VIDEO_QUANTITY_EXCEED);
                return null;
              }
              if (file?.size > videoLimitFileSize) {
                setUploadError(FILE_ERRORS.VIDEO_OVER_SIZE);
                return null;
              }
            }

            if (!isVideo) {
              if (file?.size > limitFileSize) {
                setUploadError(FILE_ERRORS.IMG_OVER_SIZE);
                return null;
              }
            }

            return {
              file,
              id: randomInt(5),
            };
          }
          setUploadError(FILE_ERRORS.INVALID_TYPE);
          return null;
        })
        ?.filter((item) => item);
      setUploadingListImage((prev) => [...prev, ...arr]);
    } else {
      setUploadError(FILE_ERRORS.VIDEO_QUANTITY_EXCEED);
    }
  };

  useEffect(() => {
    setImageList(
      initialValues?.imageList?.map((item) => ({
        ...item,
        id: randomInt(5),
      }))
    );
    setCoverImg(initialValues?.coverImg);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(initialValues)]);

  const EDIT_IMAGE_NAV_LIST = [
    {
      key: 1,
      title: (
        <div>
          <i className="fa-solid fa-chevron-left mr-[12px]" />
          {t("operator.advertisement.detail.image")}
        </div>
      ),
      url: backLink,
      end: true,
      extenalNav: Object.values(EDIT_IMAGE_ID_LIST(t)),
    },
  ];

  const onUpdateImgList = ({ data, callbackSuccess, callbackError }) => {
    setImageList((prev) => {
      const tempList = [...(prev || []), data];
      onUpdateAllInfo({
        newListImg: tempList,
        callbackSuccess: () => {
          setImageList((imgPrevList) => {
            return imgPrevList?.map((imgItem) => {
              if (imgItem?.url === data?.url) {
                return {
                  ...imgItem,
                  updating: false,
                };
              }
              return imgItem;
            });
          });
          callbackSuccess();
        },
        callbackError: () => {
          setImageList((imgPrevList) => {
            return imgPrevList?.filter((imgItem) => {
              return imgItem?.url !== data?.url;
            });
          });
          callbackError();
        },
      });
      return tempList;
    });
  };

  const onDeleteImg = (url) => {
    const newListImg = imageList?.filter((item) => {
      if (item?.url === url) {
        return false;
      }
      return true;
    });
    const newCoverImg =
      coverImg === url ? getNearestImg(newListImg)?.url : coverImg;
    if (limit && imageList.length <= 5) {
      setUploadError(FILE_ERRORS.LIMIT);
    } else {
      onUpdateAllInfo({
        newListImg,
        newCoverImg,
        callbackSuccess: () => {
          setImageList(newListImg);
          setCoverImg(newCoverImg);
        },
      });
    }
  };

  const onSetCoverImg = (url) => {
    onUpdateAllInfo({
      newListImg: imageList,
      newCoverImg: url,
      callbackSuccess: () => setCoverImg(url),
    });
  };

  const isMaxItem = useMemo(() => {
    return imageList?.length >= MAX_MEDIA_QUANTITY;
  }, [imageList]);

  const renderRightContent = () => {
    return (
      <div className="lg:pt-2">
        {topContent}
        <div
          id={EDIT_IMAGE_ID_LIST(t)[0].sectionId}
          className="pb-[48px] border-b"
        >
          <CoverImage
            imageList={imageList?.filter(({ updating }) => !updating)}
            coverImg={coverImg}
            onSetCoverImg={onSetCoverImg}
          />
        </div>

        <div className="py-[24px]" id={EDIT_IMAGE_ID_LIST(t)[1].sectionId}>
          <div className="flex justify-between items-center flex-wrap gap-4">
            <div>
              <p className="font-semibold text-[18px]">
                {t("operator.advertisement.detail.all_photos")}
              </p>
              <p className="text-gray">
                {t("operator.advertisement.detail.upload_photo_drag")}
              </p>
            </div>
            <label
              htmlFor="facility-img-upload"
              type="button"
              className={cx(
                "text-[14px] border rounded-[8px] py-[8px] px-[16px] font-semibold whitespace-nowrap",
                {
                  "text-[#DDDDDD] border-[#DDDDDD] pointer-events-none":
                    isMaxItem,
                },
                {
                  "border-dark cursor-pointer": !isMaxItem,
                }
              )}
            >
              {t("operator.advertisement.detail.upload_photo")}
            </label>
          </div>
        </div>
        <UploadFile
          isMaxItem={isMaxItem}
          imageList={imageList}
          setImageList={setImageList}
          onUpdateImgList={onUpdateImgList}
          onDeleteImg={onDeleteImg}
          handleFileUpload={handleFileUpload}
          uploadError={uploadError}
          uploadingListImage={uploadingListImage}
          setUploadingListImage={setUploadingListImage}
          onUpdateAllInfo={onUpdateAllInfo}
          min={min}
        />
      </div>
    );
  };

  const renderLeftContent = () => {
    return <FacilityNavigate navList={EDIT_IMAGE_NAV_LIST} />;
  };

  return (
    <LayoutDefault showFooter showHeader>
      <MainContent>
        <DetailListContent
          rightContent={renderRightContent()}
          leftContent={renderLeftContent()}
        />
      </MainContent>
    </LayoutDefault>
  );
}

export default ImageEdit;
