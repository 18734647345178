import React, { useState } from "react";

function DetailCollapse(props) {
  const { children, collapseLabel, containerClassName } = props;
  const [open, setOpen] = useState(false);

  return (
    <div className={`mt-5 ${containerClassName}`}>
      <details
        onToggle={() => setOpen(!open)}
        open={open}
        className={`form-detail-collapse ${open ? "block" : ""}`}
      >
        <summary>{collapseLabel}</summary>
        {children}
      </details>
    </div>
  );
}

export default DetailCollapse;
