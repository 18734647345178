/* eslint-disable react/no-array-index-key */
import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";

// Style applies for item in Menu
const getStyles = (option, optionSelected, theme) => {
  return {
    fontWeight:
      optionSelected?.indexOf(option.value) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightBold,
    padding: "8px 12px",
  };
};

function SelectComponent({
  lstOption,
  placeholder,
  optionSelected,
  handleChange,
  selectClass,
  menuClass,
  disabled,
  customValue,
}) {
  const theme = useTheme();

  return (
    <FormControl>
      <Select
        disabled={disabled}
        className={selectClass}
        displayEmpty
        value={optionSelected}
        onChange={(event) => handleChange(event.target.value)}
        input={
          <OutlinedInput
            classes={{ notchedOutline: "!border-secondary-200" }}
          />
        }
        renderValue={(selected) => {
          if (selected) {
            if (customValue) {
              return <em className="">{placeholder}</em>;
            }
            return <em className="">{selected}</em>;
          }
          return <em>{placeholder}</em>;
        }}
        MenuProps={{
          classes: { paper: menuClass || "!min-w-[160px] !max-h-[216px] w-60" },
        }}
      >
        {lstOption.map((option, index) => (
          <MenuItem
            key={index}
            value={option?.value}
            style={getStyles(option, optionSelected, theme)}
            disabled={option?.disabled}
          >
            {option.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectComponent;
