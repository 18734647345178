export default {
  list: {
    title: "件名",
    total: "お知らせ {{value}}件",
    date: "掲載期間",
    notification_group: "配信対象",
    noti: "お知らせ",
    item: "件",
    create_notification: "お知らせ作成",
  },
  detail: {
    title: "件名",
    message: "本文",
    date: "掲載期間",
    notification_group: "配信対象",
    delivery_date: "開始",
    end_date: "終了",
    customer: "事業者",
    influencer: "利用客",
    svm: "インフルエンサー",
  },
};
