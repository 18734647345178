// v-001-001
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LayoutDefault from "app/ictl/layouts/LayoutDefault";
import MainContent from "app/components/MainContent";
import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";
import moment from "moment";
import { removeFieldEmptyValue } from "app/utils";
import SelectComponent from "../../components/SelectComponent";
import { ICTL_API_ENDPOINT } from "../../../services/constant";
import CommonService from "../../../services/api/CommonService";
import { EResponseStatus, fomatPrice, FORMAT_DATE } from "../../../constant";

registerLocale("ja", ja);

function Home() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [err, setErr] = useState("");
  const options = [
    {
      text: t("common.btn.download_csv"),
      value: "download_csv",
    },
  ];

  const [optionSelected, setOptionSelected] = useState([options[0]?.value]);
  const [errorDate, setErrorDate] = useState("");

  const startMonthDate = moment(
    moment().startOf("month").format(FORMAT_DATE.YYYY_MM_DDTS)
  ).toDate();
  const endMonthDate = moment(
    moment().endOf("month").format(FORMAT_DATE.YYYY_MM_DDTE)
  ).toDate();
  const [dateRange, setDateRange] = useState([
    params.get("date_start")
      ? moment(
          moment(params.get("date_start"))
            .startOf("month")
            .format(FORMAT_DATE.YYYY_MM_DDTS)
        ).toDate()
      : startMonthDate,
    params.get("date_end")
      ? moment(
          moment(params.get("date_end"))
            .endOf("month")
            .format(FORMAT_DATE.YYYY_MM_DDTE)
        ).toDate()
      : endMonthDate,
  ]);
  const [startDate, endDate] = dateRange;
  const [res, setRes] = useState({});

  const getListData = () => {
    CommonService.getModelList(ICTL_API_ENDPOINT.HOME, {
      date_start: moment(startDate).format(FORMAT_DATE.YYYY_MM_DDTS),
      date_end: moment(endDate).format(FORMAT_DATE.YYYY_MM_DDTE),
    })
      .then((response) => {
        if (response.status === EResponseStatus.SUCCESS) {
          // temp set data
          setRes(response.data.data);
        }
      })
      .catch(() => {
        // setErrorMess(t("common.message.cannot_display_data"));
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!errorDate) {
      getListData();

      // handle change url
      const urlOptions = {
        date_start: moment(startDate).format(FORMAT_DATE.YYYY_MM_DD),
        date_end: moment(endDate).format(FORMAT_DATE.YYYY_MM_DD),
      };
      const url = new URLSearchParams(removeFieldEmptyValue(urlOptions));
      navigate(`?${url}`, { replace: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, errorDate]);

  const handleChange = async () => {
    // export csv

    CommonService.getModelList(ICTL_API_ENDPOINT.EXPORT_CSV_HOME, {
      date_start: moment(startDate).format(FORMAT_DATE.YYYY_MM_DDTS),
      date_end: moment(endDate).format(FORMAT_DATE.YYYY_MM_DDTE),
    })
      .then((response) => {
        if (response.status === EResponseStatus.SUCCESS) {
          // temp set data
          window.location.href = response.data.data.file_url;
        }
      })
      .catch(() => {
        setErr(t("admin.home.Enter_wrong_infomation"));
      })
      .finally(() => {
        // setIsLoading(false);
      });

    setOptionSelected([options[0]?.value]);
  };

  const onDateChange = (date) => {
    setErrorDate("");
    const startDateValue = date[0]
      ? moment(
          moment(date[0]).startOf("month").format(FORMAT_DATE.YYYY_MM_DDTS)
        ).toDate()
      : "";
    const endDateValue = date[1]
      ? moment(
          moment(date[1]).endOf("month").format(FORMAT_DATE.YYYY_MM_DDTS)
        ).toDate()
      : "";
    setDateRange([startDateValue, endDateValue]);

    if (date?.length) {
      const isDateHaveValue = date?.filter((item) => item);
      if (!isDateHaveValue?.length) {
        setErrorDate(t("admin.home.select_month"));
      } else if (isDateHaveValue?.length < 2) {
        setErrorDate(t("admin.home.select_end_month"));
      } else {
        setErrorDate("");
      }
    }
  };

  return (
    <LayoutDefault showFooter showHeader pin={1}>
      <div className="mb-5 bg-linear py-10">
        <div className="container">
          <p className="font-bold text-[32px]">{t("ictl_header.home")}</p>
        </div>
      </div>
      <MainContent>
        <div className="w-full">
          {err && <p className="text-[20px] font-[600]">{err}</p>}
        </div>
        <div className="flex justify-end mb-5">
          <div className="flex flex-col">
            <SelectComponent
              lstOption={options}
              placeholder={t("common.btn.export")}
              handleChange={handleChange}
              selectClass="w-40 ml-auto !rounded-xl h-12"
              optionSelected={optionSelected}
              disabled={!!errorDate}
              customValue
            />
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 mb-10">
          <div className="min-h-[132px] font-bold p-3 flex flex-col gap-5 border border-solid border-black rounded-lg">
            <span className="ml-3">{t("admin.home.total_contract")}</span>
            <span className="text-[28px] text-center word-break">
              {res.totalBusinesses ? res.totalBusinesses : 0}
            </span>
          </div>
          <div className="min-h-[132px] font-bold p-3 flex flex-col gap-5 border border-solid border-black rounded-lg">
            <span className="ml-3">{t("admin.home.total_influncer")}</span>
            <span className="text-[28px] text-center word-break">
              {res.totalInfluencers ? res.totalInfluencers : 0}
            </span>
          </div>
        </div>

        <div className="flex flex-wrap items-center gap-4 mb-4">
          <div className="flex items-center relative ">
            <div className="min-w-[24px] text-sm flex items-center h-11 border border-solid border-secondary-200 border-r-0 bg-secondary-400 whitespace-nowrap rounded-l-lg" />
            <DatePicker
              dateFormat={t('common.date_format.yyyy_MM')}
              wrapperClassName="customCalendar"
              className="outline-none font-bold bg-secondary-400 text-sm flex-1"
              selectsRange
              startDate={startDate}
              endDate={endDate}
              onChange={(date) => onDateChange(date)}
              isClearable
              placeholderText={t("common.other1")}
              showMonthYearPicker
              locale={ja}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
            {startDate ? null : (
              <div className="absolute top-1/2 -translate-y-1/2 left-[230px]">
                <i className="fa-solid fa-caret-down" />
              </div>
            )}
          </div>
          {errorDate && <div className="error-msg">{errorDate}</div>}
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 mb-10">
          <div className="min-h-[132px] font-bold p-3 flex flex-col gap-5 border border-solid border-black rounded-lg">
            <p className="ml-3">{t("admin.home.matching_commission")}</p>
            <div className="flex flex-col items-center gap-2">
              <p className="text-[28px] text-center flex items-baseline gap-1">
                <span>{t("common.currencySymbol")}</span>
                <span className="word-break leading-[36px]">
                  {res.matchingFeeRevenue
                    ? fomatPrice(res.matchingFeeRevenue)
                    : 0}
                </span>
              </p>
              <div className="text-center">
                <span className="whitespace-nowrap mr-1">{`（${t(
                  "admin.home.fee_consumption"
                )}`}</span>
                <div className="inline-flex items-baseline gap-1">
                  <span>{t("common.currencySymbol")}</span>
                  <span className="word-break">
                    {`${
                      res.matchingFeeRevenueTax
                        ? fomatPrice(res.matchingFeeRevenueTax)
                        : 0
                    }）`}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 mb-10">
          <div className="min-h-[132px] font-bold p-3 flex flex-col gap-5 border border-solid border-black rounded-lg">
            <span className="ml-3">
              {t("admin.home.number_business_change")}
            </span>
            <span className="text-[28px] text-center word-break">
              {res.totalMatching ? res.totalMatching : 0}
            </span>
          </div>
          <div className="min-h-[132px] font-bold p-3 flex flex-col gap-5 border border-solid border-black rounded-lg">
            <span className="ml-3">{t("admin.home.membership_change")}</span>
            <span className="text-[28px] text-center word-break">
              {res.totalSaleFree ? res.totalSaleFree : 0}
            </span>
          </div>
          <div className="min-h-[132px] font-bold p-3 flex flex-col gap-5 border border-solid border-black rounded-lg">
            <span className="ml-3">{t("admin.home.reservation_quantity")}</span>
            <span className="text-[28px] text-center word-break">
              {res.totalSaleReward ? res.totalSaleReward : 0}
            </span>
          </div>
        </div>
      </MainContent>
    </LayoutDefault>
  );
}

export default Home;
