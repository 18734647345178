/* eslint-disable camelcase */
// v-009-001
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import ictlPath from "app/ictl/route/Path";
import { FORMAT_DATE, PER_PAGE } from "app/constant";
import useCoreComponent from "app/hooks/useCoreComponent";
import LayoutDefault from "app/ictl/layouts/LayoutDefault";
import { ICTL_API_ENDPOINT } from "app/services/constant";
import moment from "moment";
import {
  SortedAscendingIcon,
  SortedDescendingIcon,
  UnsortIcon,
} from "app/components/DatagridIcon";
import ImageItem from "app/components/ImageItem";

function BannerList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const paramSearch = new URLSearchParams(location.search);
  const [page, setPage] = useState(paramSearch.get("page") || 1);
  const [data, setData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const { response, errorMess, isLoading, totalItem } = useCoreComponent(
    ICTL_API_ENDPOINT.GET_BANNER_LIST,
    {
      page,
      per_page: PER_PAGE,
    }
  );

  const bannerColumns = [
    {
      field: "title",
      headerName: t("admin.banner.list.banner_title"),
      headerClassName: "custom",
      cellClassName: "items-start pt-2",
      sortable: false,
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        const title = params?.row?.title || "";
        return <div className="truncate word-break">{title}</div>;
      },
    },
    {
      field: "thumbnail_url",
      headerName: t("admin.banner.list.banner_image"),
      headerClassName: "custom",
      sortable: false,
      minWidth: 550,
      flex: 2,
      renderCell: (params) => {
        const url = params?.row?.thumbnail_url;
        return (
          <div className="p-2 w-[516px] flex-shrink-0">
            <ImageItem
              lazy="true"
              containerclassname="!rounded-lg !relative !pt-[50%]"
              skeletonclassname="!absolute !top-0 !left-0 !rounded-lg"
              role="presentation"
              imagesrc={url}
              alt=""
              imgclassname="w-full h-full object-cover !absolute !top-0 !left-0 !rounded-lg"
            />
          </div>
        );
      },
    },
    {
      field: "period",
      headerName: t("admin.banner.list.banner_date"),
      cellClassName: "items-start pt-2",
      headerClassName: "custom",
      sortable: false,
      width: 400,
    },
  ];

  useEffect(() => {
    navigate(`?page=${page}&per_page=${PER_PAGE}`, {
      replace: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  // format data from response
  useEffect(() => {
    const list = response?.data?.data?.row || [];
    if (list?.length && !isLoading) {
      const lstTemp = list?.map((item) => {
        const startDate = item?.delivery_date
          ? moment.utc(item?.delivery_date).format(FORMAT_DATE.YYYY_M_Do_HH_MM)
          : null;
        const endDate = item?.end_date
          ? moment.utc(item?.end_date).format(FORMAT_DATE.YYYY_M_Do_HH_MM)
          : null;
        return {
          ...item,
          period:
            startDate && endDate
              ? // eslint-disable-next-line no-irregular-whitespace
                `${startDate}　～　${endDate}`
              : t("common.other1"),
        };
      });
      setData(lstTemp);
    } else {
      setData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, isLoading]);

  const onPaginationModelChange = (model) => {
    const newPage = model?.page;
    setPage(newPage + 1);
  };

  return (
    <LayoutDefault showFooter showHeader pin={9}>
      <div className="px-6 flex-1">
        <div className="flex flex-col xs:flex-row justify-between xs:items-center mt-5 gap-3">
          <p className="text-3xl font-bold">
            {t("admin.banner.list.total_item", { value: totalItem })}
          </p>
          <div className="flex items-center gap-4">
            <div className="order-2 xs:order-1 error-msg">
              {t("admin.banner.list.at_least_three")}
            </div>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => navigate(ictlPath.banner_create)}
              disabled={data?.length === 10}
              className="!whitespace-nowrap order-1 xs:order-2"
            >
              <i className="fa-solid fa-plus mr-1" />
              {t("admin.banner.list.create_banner")}
            </Button>
          </div>
        </div>

        {errorMess ? (
          <p className="text-[20px] font-[600] flex-1 py-3">{errorMess}</p>
        ) : (
          <div className="w-full py-6">
            <DataGrid
              autoHeight
              disableColumnMenu
              rows={data}
              columns={bannerColumns}
              rowCount={totalItem}
              loading={isLoading}
              paginationMode="server"
              slots={{
                columnUnsortedIcon: UnsortIcon,
                columnSortedDescendingIcon: SortedDescendingIcon,
                columnSortedAscendingIcon: SortedAscendingIcon,
              }}
              getRowHeight={() => "auto"}
              getRowClassName={() => "cursor-pointer"}
              getRowId={(row) => row?.banner_id}
              onRowClick={(params, event) => {
                event.preventDefault();
                navigate(ictlPath.banner_detail(params?.id));
              }}
              pageSizeOptions={[PER_PAGE]}
              paginationModel={{
                pageSize: PER_PAGE,
                page: page - 1,
              }}
              onPaginationModelChange={(model) => {
                onPaginationModelChange(model);
              }}
              localeText={{
                noResultsOverlayLabel: t("common.message.no_data_display"),
                noRowsLabel: t("common.message.no_data_display"),
              }}
            />
          </div>
        )}
      </div>
    </LayoutDefault>
  );
}

export default BannerList;
