/* eslint-disable react/no-array-index-key */
// v-006-002
import React from "react";
import Table from "@mui/material/Table";
import { useTranslation } from "react-i18next";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FEES_PAY, fomatPrice } from "../../../../constant";
import CustomNoDataOverLay from "../../../../components/CustomNoDataOverLay";

function EnhancedTableHead(props) {
  const { headCells } = props;
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
            className="font-bold text-xm"
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function TableDetailPay(props) {
  const { t } = useTranslation();
  const {
    headCells,
    rows,
    setPage,
    category,
    total,
    perPage,
    setPerPage,
    page,
  } = props;

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleRowsPerPageChange = (event) => {
    setPerPage(event.target.value);
  };

  return (
    <Paper sx={{ width: "100%" }} className="border border-slate-300">
      <TableContainer className="h-[450px]">
        <Table
          sx={{ minWidth: 750 }}
          stickyHeader
          aria-label="sticky table"
          size="medium"
        >
          <EnhancedTableHead rowCount={rows.length} headCells={headCells} />
          <TableBody>
            {total !== 0 &&
              rows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      align="center"
                      className="font-bold text-xs"
                    >
                      {category === FEES_PAY.INITIAL_COST &&
                        row.item_name}
                      {category === FEES_PAY.MONTHLY_PLAN &&
                        row.plan_name}
                      {category === FEES_PAY.REWARDS && row.influencer_name}
                    </TableCell>
                    {category !== FEES_PAY.INITIAL_COST && (
                      <TableCell align="left" className="font-bold text-xs">
                        {category === FEES_PAY.MONTHLY_PLAN &&
                          row.option_name}
                        {category === FEES_PAY.REWARDS &&
                          row.name_project}
                      </TableCell>
                    )}

                    <TableCell align="right" className="font-bold text-xs">
                      <p>
                        {t("admin.pay.list.price")}
                        {row?.fee ? fomatPrice(row?.fee) : 0}
                      </p>
                      <p>
                        ({t("admin.pay.consumptionTax")}
                        {row?.tax ? fomatPrice(row?.tax) : 0})
                      </p>
                    </TableCell>
                    <TableCell align="right" className="font-bold text-xs">
                      {" "}
                      {t("admin.pay.list.price")}
                      {row?.price ? fomatPrice(row?.price) : 0}
                    </TableCell>
                    <TableCell align="center" className="font-bold text-xs">
                      1
                    </TableCell>
                    {category !== FEES_PAY.INITIAL_COST && (
                      <TableCell align="left" className="font-bold text-xs">
                        {row.invoice_number}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            {total === 0 && (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <CustomNoDataOverLay />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        classes={{ fontWeight: 700 }}
        count={total || -1}
        rowsPerPage={perPage}
        page={rows.length === 1 ? 0 : Number(page) - 1}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        labelRowsPerPage=""
        className="border-t border-slate-300"
      />
    </Paper>
  );
}

export default TableDetailPay;
