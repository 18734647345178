/* eslint-disable react/no-array-index-key */
import React, { useContext, useState, useEffect } from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { updatePersonalInfoAction } from "app/utils/page/personal_info";
import { MenuItem, Select } from "@mui/material";
import { useDispatch } from "react-redux";
import { COUNTRY_LIST, postCodeRegexInput } from "app/constant";
import { setProfileStore } from "app/redux/profileReducer";
import InputText from "app/components/InputText";
import EditableForm from "app/components/EditableForm";
import UserContext from "./UserContext";

const EDIT_ADDRESS = "show_edit_address";

function AddressSettingField(props) {
  const dispatch = useDispatch();
  const { showEdit, setShowEdit, setOpenSuccess, setOpenError } = props;
  const { t } = useTranslation();
  const { userInfo, setUserInfo, profile } = useContext(UserContext);
  const [fullAddress, setFullAddress] = useState("");

  const countryList = [
    {
      optionName: t("common.japan"),
      value: COUNTRY_LIST.JA,
    },
  ];

  const validationSchema = Yup.object().shape({
    postcode: Yup.string(),
    country: Yup.string(),
    city: Yup.string(),
    street: Yup.string(),
    address: Yup.string(),
    apartment: Yup.string(),
  });
  const initialValues = {
    postcode: "",
    country: "jp",
    city: "",
    street: "",
    address: "",
    apartment: "",
  };

  useEffect(() => {
    if (Object.keys(userInfo).length) {
      const { postcode, apartment, address, street, city, country } = userInfo;
      const path = [postcode, street, city, address, apartment]
        .filter((item) => item)
        .join(" ");
      const countryPath = [t("common.japan"), country].join("-");
      const addressFull = [path, countryPath].join(",");
      setFullAddress(addressFull);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const updateUserInfo = (values, context) => {
    const { setValues } = context;
    updatePersonalInfoAction({
      userInfo,
      values,
      setUserInfo,
      setValues,
      setOpenSuccess,
      setOpenError,
      setShowEdit,
      callbackSuccess: (data) => {
        const { address: full } = data;
        const { postcode, apartment, address, street, city, country } = full;
        const path = [postcode, street, city, address, apartment]
          .filter((item) => item)
          .join(" ");
        const countryPath = [t("common.japan"), country].join("-");
        const addressFull = [path, countryPath].join(",");
        setFullAddress(addressFull);
        const object = { ...profile };
        object.address = data.address;
        dispatch(setProfileStore(object));
      },
    });
  };

  const renderForm = ({ values, setFieldValue }) => {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-y-8 gap-x-4">
        <InputText
          inputId="postcode"
          title={t("operator.accountSetting.personal_info.postcode")}
          inputName="postcode"
          setFieldValue={setFieldValue}
          inputValue={values?.postcode}
          regex={postCodeRegexInput}
          maxLength={15}
        />
        <div className="hidden md:block" />
        <InputText
          inputId="address_state"
          title={t(
            "operator.accountSetting.personal_info.state_provine_country"
          )}
          inputName="street"
          setFieldValue={setFieldValue}
          inputValue={values?.street}
          maxLength={256}
        />
        <InputText
          inputId="address_city"
          title={t("operator.accountSetting.personal_info.municipaliites")}
          inputName="city"
          setFieldValue={setFieldValue}
          inputValue={values?.city}
          maxLength={256}
        />
        <InputText
          inputId="address_street"
          title={t("operator.accountSetting.personal_info.address")}
          inputName="address"
          setFieldValue={setFieldValue}
          inputValue={values?.address}
          maxLength={256}
        />
        <InputText
          inputId="address_apartment"
          title={t("operator.accountSetting.personal_info.apartment_name")}
          inputName="apartment"
          setFieldValue={setFieldValue}
          inputValue={values?.apartment}
          maxLength={256}
        />
        <div>
          <div className="text-sm leading-5 text-danger-DESCRIPTION mb-4 font-semibold">
            {t("operator.accountSetting.personal_info.country_region")}
          </div>
          <Select
            value={values?.country}
            inputProps={{
              name: "country",
            }}
            onChange={(e) => setFieldValue("country", e.target.value)}
            className="w-full"
            placeholder={t(
              "operator.accountSetting.personal_info.country_region"
            )}
          >
            {countryList?.map(({ optionName, value }, index) => (
              <MenuItem key={index} value={value}>
                <div>{optionName}</div>
              </MenuItem>
            ))}
          </Select>
        </div>
        <div />
      </div>
    );
  };

  return (
    <EditableForm
      editForm={renderForm}
      onSubmit={updateUserInfo}
      validationSchema={validationSchema}
      initialValues={initialValues}
      label={t("operator.accountSetting.personal_info.address_label")}
      editField={EDIT_ADDRESS}
      formValues={userInfo}
      displayValue={fullAddress}
      showEdit={showEdit}
      setShowEdit={setShowEdit}
    />
  );
}

export default AddressSettingField;
