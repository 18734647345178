/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AccordionDetails } from "@mui/material";
import TextField from "@mui/material/TextField";
import FormItem, { TYPE_INPUT } from "app/operator/components/FormItem";
import MainContent from "app/components/MainContent";
import DetailListContent from "app/components/DetailListContent";
import { useNavigate } from "react-router-dom";
import LayoutDefault from "app/ictl/layouts/LayoutDefault";
import Button, { BUTTON_STYLE_TYPE } from "app/operator/components/Button";
import SelectComponent from "../../components/SelectComponent";
import { FORMAT_DATE } from "../../../constant";
import i18n from "../../../langs";
import {
  dateMinute,
  dateSecond,
  listGroup,
  storeNotification,
} from "../../../utils/page/notification";
import SuccessDialog from "../../../components/SuccessDialog";
import ErrorDialog from "../../../components/ErrorDialog";
import Loading from "../../../operator/components/Loading";
import ictlPath from "../../route/Path";

const dropdownTime = dateMinute([]);
const dropdownSecond = dateSecond([]);

function NotificationCreate() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [message, setMessage] = useState("");
  const formikRef = useRef();
  const initialValues = {
    title: "",
    message: "",
    delivery_date: dayjs(new Date()).format(FORMAT_DATE.YYYY_MM_DDT),
    end_date: dayjs(new Date()).format(FORMAT_DATE.YYYY_MM_DDT),
    notification_group: [],
    start_minute: dayjs(new Date()).format(FORMAT_DATE.HH),
    end_minute: dayjs(new Date()).format(FORMAT_DATE.HH),
    start_second: dayjs(new Date()).format(FORMAT_DATE.MM),
    end_second: dayjs(new Date()).format(FORMAT_DATE.MM),
  };

  // validate
  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required(
        t("validate_message.required", {
          path: t("admin.notification.detail.title"),
        })
      )
      .trim(),
    message: Yup.string()
      .required(
        t("validate_message.required", {
          path: t("admin.notification.detail.message"),
        })
      )
      .trim(),
    notification_group: Yup.array()
      .required()
      .min(
        1,
        t("validate_message.required", {
          path: t("admin.notification.detail.notification_group"),
        })
      ),
    delivery_date: Yup.date()
      .required(
        t("validate_message.required", {
          path: t("admin.notification.detail.delivery_date"),
        })
      )
      .min(new Date(), t("common.message.fieldDateMin"))
      .typeError(
        i18n.t("validate_message.wrong_format", {
          path: i18n.t(`validation_field.delivery_date`),
        })
      ),
    end_date: Yup.date()
      .required(
        t("validate_message.required", {
          path: t("admin.notification.detail.end_date"),
        })
      )
      .min(Yup.ref("delivery_date"), t("common.message.fieldDateMax"))
      .typeError(
        i18n.t("validate_message.wrong_format", {
          path: i18n.t(`validation_field.end_date`),
        })
      ),
  });

  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState({});
  const locale = i18n.resolvedLanguage;

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    listGroup(setGroups, t);
  }, [t]);

  const openSuccessDialog = (mess) => {
    setOpenSuccess(true);
    setMessage(mess || t("common.message.createSuccess"));
  };

  const openErrorDialog = (mess) => {
    setOpenError(true);
    setMessage(mess || t("common.message.createFailed"));
  };

  const submitHandle = (values) => {
    storeNotification({
      data: values,
      callbackSuccess: () => {
        setLoading(false);
        openSuccessDialog();
      },
      callbackError: () => {
        openErrorDialog();
        setLoading(false);
      },
    });
  };

  const renderRightContent = (prop) => {
    const { setFieldValue, values, resetForm, handleBlur, handleSubmit } = prop;

    return (
      <>
        <FormItem
          required
          typeInput={TYPE_INPUT.TEXT}
          name="title"
          label={t("admin.notification.detail.title")}
          labelClassName="font-semibold mb-[12px]"
          placeholder={t("common.message.no_input_yet")}
          containerClassName="mb-[24px]"
          inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
          errorsClassName="mt-1"
        />

        <FormItem
          required
          typeInput={TYPE_INPUT.TEXT_AREA}
          name="message"
          label={t("admin.notification.detail.message")}
          labelClassName="font-semibold mb-[12px]"
          placeholder={t("common.message.no_input_yet")}
          textAreaClassName='resize-none h-[200px]'
          containerClassName="mb-[24px]"
          inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
          errorsClassName="mt-1"
        />

        <div className="notification-period mb-6">
          <div className="font-semibold mb-[12px]">
            <span className="text-[14px]">
              {t("admin.notification.detail.date")}
            </span>
            <span className="text-red pl-1">{t("validate_mark")}</span>
          </div>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={locale}
          >
            <div className="flex flex-col xl:flex-row xl:items-baseline gap-2 xl:gap-4">
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-4">
                  <DesktopDatePicker
                    inputFormat={FORMAT_DATE.YYYY_MM_DD}
                    value={values.delivery_date}
                    onChange={(value) => {
                      if (value) {
                        const date = dayjs(value).format(
                          `YYYY-MM-DD[T]${values?.start_minute}:${values?.start_second}`
                        );
                        setFieldValue("delivery_date", date);
                      } else {
                        setFieldValue("delivery_date", "");
                      }
                    }}
                    className="date-picker xl:flex-1"
                    renderInput={(res) => (
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      <TextField
                        size="small"
                        sx={{ width: "50%" }}
                        {...res}
                        name="delivery_date"
                        onBlur={handleBlur}
                      />
                    )}
                    componentsProps={{
                      actionBar: {
                        actions: ["today"],
                        className: "!justify-center",
                      },
                    }}
                  />
                  <SelectComponent
                    lstOption={dropdownTime}
                    name="start_minute"
                    handleChange={(e) => {
                      setFieldValue("start_minute", e);
                      const date = dayjs(values?.delivery_date).format(
                        `YYYY-MM-DD[T]${e}:${values?.start_second}`
                      );
                      setFieldValue("delivery_date", date);
                    }}
                    selectClass="!w-[72px] !max-w-full !rounded-xl h-10"
                    optionSelected={values?.start_minute}
                  />
                  <SelectComponent
                    lstOption={dropdownSecond}
                    name="start_second"
                    handleChange={(e) => {
                      setFieldValue("start_second", e);
                      const date = dayjs(values?.delivery_date).format(
                        `YYYY-MM-DD[T]${values?.start_minute}:${e}`
                      );
                      setFieldValue("delivery_date", date);
                    }}
                    selectClass="!w-[72px] !max-w-full !rounded-xl h-10"
                    optionSelected={values?.start_second}
                  />
                </div>
                <ErrorMessage
                  name="delivery_date"
                  component="div"
                  className="errors-msg"
                />
              </div>

              <p className="text-center">～</p>

              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-4">
                  <DesktopDatePicker
                    inputFormat={FORMAT_DATE.YYYY_MM_DD}
                    className="date-picker xl:flex-1"
                    value={values.end_date}
                    onChange={(value) => {
                      if (value) {
                        const date = dayjs(value).format(
                          `YYYY-MM-DD[T]${values?.end_minute}:${values?.end_second}`
                        );
                        setFieldValue("end_date", date);
                      } else {
                        setFieldValue("end_date", "");
                      }
                    }}
                    renderInput={(res) => (
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      <TextField
                        {...res}
                        size="small"
                        sx={{ width: "50%" }}
                        name="end_date"
                        onBlur={handleBlur}
                      />
                    )}
                    componentsProps={{
                      actionBar: {
                        actions: ["today"],
                        className: "!justify-center",
                      },
                    }}
                  />
                  <SelectComponent
                    lstOption={dropdownTime}
                    name="end_minute"
                    handleChange={(e) => {
                      setFieldValue("end_minute", e);
                      const date = dayjs(values?.end_date).format(
                        `YYYY-MM-DD[T]${e}:${values?.end_second}`
                      );
                      setFieldValue("end_date", date);
                    }}
                    selectClass="!w-[72px] !max-w-full !rounded-xl h-10"
                    optionSelected={values?.end_minute}
                  />
                  <SelectComponent
                    lstOption={dropdownSecond}
                    name="end_second"
                    handleChange={(e) => {
                      setFieldValue("end_second", e);
                      const date = dayjs(values?.end_date).format(
                        `YYYY-MM-DD[T]${values?.end_minute}:${e}`
                      );
                      setFieldValue("end_date", date);
                    }}
                    selectClass="!w-[72px] !max-w-full !rounded-xl h-10"
                    optionSelected={values?.end_second}
                  />
                </div>
                <ErrorMessage
                  name="end_date"
                  component="div"
                  className="errors-msg"
                />
              </div>
            </div>
          </LocalizationProvider>
        </div>

        <div className="notification-group">
          <div className="font-semibold">
            <span className="text-[14px]">
              {t("admin.notification.detail.notification_group")}
            </span>
            <span className="text-red pl-1">{t("validate_mark")}</span>
          </div>
          {groups.length > 0 && (
            <AccordionDetails>
              <FormItem
                stringValue
                key={values?.notification_group}
                name="notification_group"
                checkboxContainerClassName="flex flex-wrap gap-6"
                typeInput={TYPE_INPUT.CHECKBOX}
                componentProps={{
                  defaultValue: values?.notification_group,
                  optionsList: groups,
                }}
              />
            </AccordionDetails>
          )}
        </div>

        {/* <div className="border-b-[1px] w-full border-b-gray-300 mt-4" /> */}

        <div className="flex items-center justify-between mt-10">
          <button
            type="button"
            className="underline font-semibold"
            onClick={() => resetForm()}
          >
            {t("common.action.cancel")}
          </button>
          <Button onClick={handleSubmit} styleType={BUTTON_STYLE_TYPE.PRIMARY}>
            {t("common.action.save")}
          </Button>
        </div>
      </>
    );
  };

  return (
    <LayoutDefault showFooter showHeader pin={8}>
      <Loading open={!!loading} />
      <MainContent showBackButton path={ictlPath.notification}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitHandle}
        >
          {(prop) => {
            return (
              <Form>
                <DetailListContent rightContent={renderRightContent(prop)} />
              </Form>
            );
          }}
        </Formik>
        <SuccessDialog
          message={message}
          open={openSuccess}
          onClose={() => navigate(ictlPath.notification)}
          onOK={() => navigate(ictlPath.notification)}
        />
        <ErrorDialog
          message={message}
          open={openError}
          onClose={() => setOpenError(false)}
          onOK={() => setOpenError(false)}
        />
      </MainContent>
    </LayoutDefault>
  );
}

export default NotificationCreate;
