// m-007-010, m-007-020
import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import LayoutDefault from "app/operator/layouts/LayoutDefault";
import MainContent from "app/components/MainContent";
import { useParams } from "react-router-dom";
import { formatNumber, generateSNSIcon, handleSNSLink } from "app/utils";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment/moment";
import { EResponseStatus } from "app/constant";
import AdProjectService from "app/services/api/operatorService/AdProjectService";
import DetailListContent from "app/components/DetailListContent";
import ProfileContent from "./ProfileContent";
import Path from "../../../route/Path";

export const InfluencerDetailContext = createContext({});

function InfluencerDetail() {
  const params = useParams();
  const { projectId, influencerId } = params;
  const { t } = useTranslation();
  const [data, setData] = useState({});
  const [social, setSocial] = useState([]);
  const [historyAccept, setHistoryAccept] = useState([]);

  const getDetailData = useCallback(() => {
    AdProjectService.getInfluencerDetail(influencerId, {
      ad_project_id: projectId,
    })
      .then((response) => {
        if (response.status === EResponseStatus.SUCCESS) {
          setSocial(response?.data?.data?.social_media || []);
          setHistoryAccept(response?.data?.data?.list_ad_accepted || []);
          setData(response?.data?.data || {});
        }
      })
      .catch(() => {
        // setErrorMess(t("common.message.cannot_display_data"));
      })
      .finally(() => {
        // setIsLoading(false);
      });
  }, [influencerId, projectId]);

  useEffect(() => {
    getDetailData();
  }, [getDetailData]);

  const influencerDetailContextProvider = useMemo(
    () => ({
      data,
    }),
    [data]
  );

  const renderLeftContent = () => {
    return (
      <div className="w-full">
        {social[0] && social[0].social_media && (
          <div className="pb-5 flex flex-col gap-4">
            {social.map((item) => {
              return (
                <div
                  key={item.social_media}
                  className="flex items-center flex-wrap"
                >
                  {generateSNSIcon(item.social_media)}
                  <a
                    href={handleSNSLink(item.social_media, item.username)}
                    target="_blank"
                    className="cursor-pointer text-[#0022FF] mr-3 ml-1 word-break"
                    rel="noreferrer"
                  >
                    {item.username}
                  </a>
                  <Tooltip
                    title={`${formatNumber(item.subscribers_number || 0)} ${t(
                      "common.followers"
                    )}`}
                  >
                    <p className="mr-5 truncate">
                      {formatNumber(item.subscribers_number || 0)}
                      {t("common.followers")}
                    </p>
                  </Tooltip>
                </div>
              );
            })}
          </div>
        )}

        <div className="flex flex-col gap-5">
          <div className="flex items-center gap-5">
            <span className="font-bold text-[18px]">
              {t("operator.influencerDetail.number_apply")}
            </span>
            <span className="text-[18px] text-gray font-semibold">
              {formatNumber(data.count_ad_register)}
            </span>
          </div>
          <div className="flex items-center gap-5 mb-5">
            <span className="font-bold text-[18px]">
              {t("operator.influencerDetail.number_approved")}
            </span>
            <span className="text-[18px] text-gray font-semibold">
              {formatNumber(data.count_ad_accepted)}
            </span>
          </div>
        </div>

        <p className="font-bold text-[18px] mb-2">
          {t("operator.influencerDetail.approval_history")}
        </p>
        <div className="max-h-[580px] overflow-auto text-gray word-break">
          {historyAccept.map((item, index) => {
            return (
              <div
                key={item.application_id}
                className={`flex flex-col gap-2 py-3 ${
                  index !== historyAccept.length - 1
                    ? "border-b border-dashed border-secondary-200"
                    : ""
                }`}
              >
                <p>
                  {moment(item.update_at).format(
                    t("common.date_format.yyyy_mm_dd")
                  )}
                </p>
                <p>{item.service_provider_name}</p>
                <p>{item.title}</p>
                <p>{`${t("operator.pay.list.price")} ${formatNumber(
                  item.fee ? item.fee[0].fee : 0
                )}`}</p>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderRightContent = () => {
    return <ProfileContent projectId={projectId} influencerId={influencerId} />;
  };

  return (
    <InfluencerDetailContext.Provider value={influencerDetailContextProvider}>
      <LayoutDefault showFooter showHeader>
        <MainContent showBackButton path={Path.ad_project_social(projectId)}>
          <DetailListContent
            leftContent={renderLeftContent()}
            rightContent={renderRightContent()}
            title={t("operator.influencerDetail.influencer_info")}
          />
        </MainContent>
      </LayoutDefault>
    </InfluencerDetailContext.Provider>
  );
}

export default InfluencerDetail;
