/* eslint-disable camelcase */
import i18n from "i18next";
import { object, string, mixed, date } from "yup";
import moment from "moment";
import { EResponseStatus, FORMAT_DATE } from "app/constant";
import * as Yup from "yup";
import store from "app/redux/store";
import { setActive } from "app/redux/blockUIReducer";
import CommonService from "app/services/api/CommonService";
import { FILE_UPLOAD_TYPE } from "app/chat/enum/ChatEnum";
import { ICTL_API_ENDPOINT } from "app/services/constant";
import { isNewFileUploading, removeFieldEmptyValue } from "..";

export const BANNER_ACTION = {
  STORE: "STORE",
  UPDATE: "UPDATE",
  CANCEL: "CANCEL",
  DELETE: "DELETE",
};



export const createBannerSchema = () => {
  return object({
    banner_title: string().required().trim(),
    banner_delivery_date: date()
      .required()
      .typeError(
        i18n.t("validate_message.wrong_format", {
          path: i18n.t(`validation_field.banner_delivery_date`),
        })
      ),
    banner_end_date: date()
      .typeError(
        i18n.t("validate_message.wrong_format", {
          path: i18n.t(`validation_field.banner_end_date`),
        })
      )
      .required()
      .min(
        Yup.ref("banner_delivery_date"),
        i18n.t("common.message.fieldDateMax")
      ),
    banner_files: mixed().required(i18n.t("common.message.choose_image")),
    banner_address_url: string().url().required().trim(),
  });
};

export const formatGetBannerDetailData = (data) => {
  const {
    delivery_date,
    end_date,
    title,
    thumbnail_url,
    media_url,
    address_url,
    is_new_tab,
  } = data;

  const formatData = {
    banner_title: title || "",
    banner_files: thumbnail_url || "",
    banner_media_url: media_url || "",
    banner_delivery_date: delivery_date
      ? moment(delivery_date).utc().format(FORMAT_DATE.YYYY_MM_DDTM)
      : moment().format(FORMAT_DATE.YYYY_MM_DDTM),
    banner_end_date: end_date
      ? moment(end_date).utc().format(FORMAT_DATE.YYYY_MM_DDTM)
      : moment().format(FORMAT_DATE.YYYY_MM_DDTM),
    start_minute: delivery_date
      ? moment(delivery_date).utc().format(FORMAT_DATE.HH)
      : "00",
    end_minute: end_date ? moment(end_date).utc().format(FORMAT_DATE.HH) : "00",
    start_second: end_date
      ? moment(delivery_date).utc().format(FORMAT_DATE.MM)
      : "00",
    end_second: end_date ? moment(end_date).utc().format(FORMAT_DATE.MM) : "00",
    banner_address_url: address_url || "",
    banner_is_new_tab: is_new_tab,
  };

  return formatData;
};

export const updateOrCreateBanner = async ({
  bannerId,
  data,
  callbackSuccess,
  callbackError,
}) => {
  const {
    banner_title,
    banner_files, // thumbnail_url
    banner_delivery_date,
    banner_end_date,
    banner_media_url, // media_url
    banner_address_url,
    banner_is_new_tab,
  } = data;

  const payload = {
    title: banner_title,
    delivery_date: banner_delivery_date,
    end_date: banner_end_date,
    address_url: banner_address_url,
    is_new_tab: banner_is_new_tab,
  };

  // set on block ui
  store.dispatch(setActive(true));

  // upload file
  if (isNewFileUploading(banner_files)) {
    const uploadResponse = await CommonService.uploadFile(
      banner_files,
      FILE_UPLOAD_TYPE.IMAGE
    ).catch(() => {
      store.dispatch(setActive(false));
      if (callbackError) {
        callbackError();
      }
      return Promise.reject();
    });
    payload.thumbnail_url = uploadResponse?.data?.data?.thumbnail_url;
    payload.media_url = uploadResponse?.data?.data?.media_url;
  } else {
    payload.thumbnail_url = banner_files;
    payload.media_url = banner_media_url;
  }

  // store or update banner
  CommonService.postModel(
    bannerId
      ? ICTL_API_ENDPOINT.UPDATE_BANNER(bannerId)
      : ICTL_API_ENDPOINT.STORE_BANNER,
    removeFieldEmptyValue(payload)
  )
    .then((response) => {
      if (response.status === EResponseStatus.CREATED && callbackSuccess) {
        callbackSuccess(bannerId ? BANNER_ACTION.UPDATE : BANNER_ACTION.STORE);
      }
    })
    .catch((error) => {
      if (callbackError) {
        callbackError(
          bannerId ? BANNER_ACTION.UPDATE : BANNER_ACTION.STORE,
          error
        );
      }
    })
    .finally(() => {
      store.dispatch(setActive(false));
    });
};
