import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import CustomExpand from "app/components/CustomExpand";
import { ErrorMessage } from "formik";
import { customAccordionStyle } from "app/utils";

function BusinessPersonField(props) {
  const { children, label, fieldName, displayValue, required = true } = props;

  return (
    <div className="mb-[16px]">
      <div
        className={`mb-[8px] font-semibold inline ${
          required ? "required" : ""
        }`}
      >
        {label}
      </div>
      <Accordion sx={customAccordionStyle(true)}>
        <AccordionSummary
          classes={{ expanded: "!transform-none", root: "gap-1" }}
          expandIcon={<CustomExpand />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="flex">
            <div className="flex flex-wrap gap-[8px]" translate="no">
              <ErrorMessage
                name={fieldName}
                component="div"
                className="errors-msg"
              />
              {displayValue}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
}

export default BusinessPersonField;
