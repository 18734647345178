import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Popover } from "@mui/material";
import cx from "classnames";
import { randomInt } from "app/utils";
import CustomModal from "../../CustomModal";
import { FILE_ERRORS_TEXT, isVideoRegex } from "../../../../constant";
import Media from "../../../../components/Media";

function UploadFile({
  values,
  setFieldValue,
  isMaxItem,
  handleFileUpload,
  uploadError,
}) {
  const { t } = useTranslation();
  const [isDragOver, setIsDragOver] = useState(false);
  const [isRearrange, setIsRearrange] = useState();

  const formatedList = useMemo(() => {
    let rearrangeList = [];
    if (isRearrange?.itemOriginId && isRearrange?.itemTargetIndex) {
      rearrangeList = [...(values?.files || [])]?.filter((item) => {
        return item?.id !== isRearrange?.itemOriginId;
      });
      rearrangeList.splice((isRearrange?.itemTargetIndex || 0) - 1, 0, {
        id: randomInt(5),
        blank: true,
      });
      return rearrangeList;
    }

    return values?.files;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.files, JSON.stringify(isRearrange)]);

  const onDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isRearrange) {
      setIsDragOver(false);
      // handle file to preview
      handleFileUpload(e);
    }
  };

  const onDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDragOver && !isRearrange) {
      setIsDragOver(true);
    }
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isDragOver) {
      setIsDragOver(false);
    }
  };

  const onDeleteImg = (data) => {
    const newList = values?.files?.filter(({ id }) => data?.id !== id);
    const isDeleteCoverImg = values?.coverImg?.id === data?.id;
    setFieldValue("files", newList);
    if (isDeleteCoverImg) {
      setFieldValue("coverImg", "");
    }
  };

  const renderErrorMsg = (
    <div>
      {uploadError && (
        <div className="mb-[8px] px-[12px] py-[4px] flex items-center border rounded-[8px] text-[#c13515]">
          <i className="fa-solid fa-circle-exclamation  w-[16px] h-[16px] mr-[12px]" />
          <p className="text-[12px]">{FILE_ERRORS_TEXT(t)[uploadError]}</p>
        </div>
      )}
    </div>
  );

  const onDropRearrange = () => {
    const originItem = values?.files?.filter(
      (item) => isRearrange?.itemOriginId === item?.id
    );
    const rearrangeList = values?.files?.filter(
      ({ id: itemId }) => isRearrange?.itemOriginId !== itemId
    );
    rearrangeList?.splice(
      (isRearrange?.itemTargetIndex || 0) - 1,
      0,
      originItem[0]
    );
    setIsRearrange();
    setFieldValue("files", rearrangeList);
  };

  return (
    <>
      {renderErrorMsg}
      <div
        onDragOver={onDragOver}
        onDrop={onDrop}
        className="pt-6 pb-[16px] grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 gap-[16px] relative"
      >
        {formatedList?.map((item, index) => {
          return (
            <ImageItem
              key={item?.id}
              arrangeIndex={(index || 0) + 1}
              data={item}
              onDelete={onDeleteImg}
              isRearrange={isRearrange}
              setIsRearrange={setIsRearrange}
              onDropRearrange={onDropRearrange}
            />
          );
        })}
        <label
          htmlFor="facility-img-upload"
          className={cx("relative pt-[60%]", { hidden: isMaxItem })}
        >
          <div className="absolute top-0 left-0 w-full h-full">
            <div className="cursor-pointer text-center font-semibold border-dark border border-dashed hover:border-2 hover:border-solid w-full h-full flex justify-center items-center">
              <div>
                <i className="fa-regular fa-image text-[30px]" />
                <p>{t("common.action.add_more")}</p>
              </div>
            </div>
          </div>
          <input
            onChange={handleFileUpload}
            type="file"
            name="file"
            value=""
            multiple
            id="facility-img-upload"
            accept="image/png, image/jpg, image/jpeg ,video/mp4"
            className="hidden absolute top-0"
          />
        </label>
        <div
          className={cx(
            "bg-white bg-opacity-85 border-[3px] absolute top-0 left-0 border-dark w-full h-full z-10 flex items-center justify-center",
            {
              visible: isDragOver,
              invisible: !isDragOver,
            }
          )}
        >
          <div className="text-[30px] font-semibold text-center">
            <i className="fa-regular fa-image" />
            <p>{t("operator.facility.detail.drop_and_upload")}</p>
          </div>
        </div>
        <div
          onDragLeave={onDragLeave}
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          className={cx(
            "absolute top-0 left-0 w-full h-full z-20 bg-transparent",
            {
              visible: isDragOver,
              invisible: !isDragOver,
            }
          )}
        />
      </div>
    </>
  );
}

function ImageItem({
  data,
  onDelete,
  isRearrange,
  setIsRearrange,
  arrangeIndex,
  onDropRearrange,
}) {
  const { file, id, blank } = data || {};
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(false);
  const [visibleModalConfirmDelete, setVisibleModalConfirmDelete] =
    useState(false);

  const isVideo =
    isVideoRegex?.exec(`${file}`.toLowerCase()) ||
    isVideoRegex?.exec(`${file?.name}`.toLowerCase());

  const onDragLeave = () => {
    if (blank) {
      setIsRearrange((prev) => {
        return {
          itemOriginId: prev?.itemOriginId,
        };
      });
    }
  };

  const onDragOver = () => {
    const { itemTargetIndex, itemOriginId } = isRearrange || {};
    if (!blank && itemOriginId && itemTargetIndex !== arrangeIndex) {
      setIsRearrange((prev) => {
        return {
          itemOriginId: prev?.itemOriginId,
          itemTargetIndex: arrangeIndex,
        };
      });
    }
  };

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? false : event.currentTarget);
  };

  if (blank) {
    return (
      <div
        onDrop={() => {
          if (onDropRearrange) {
            onDropRearrange();
          }
        }}
        className="border-2 border-dashed h-full"
      />
    );
  }

  return (
    <>
      <button
        type="button"
        draggable
        onDragOver={onDragOver}
        onDragEnd={() => {
          setIsRearrange();
        }}
        onDragLeave={onDragLeave}
        onMouseDown={() => {
          setIsRearrange({ itemOriginId: id });
        }}
        key={id}
        className="relative pt-[60%]"
      >
        <i
          role="presentation"
          onClick={handleClick}
          className="absolute cursor-pointer top-0 right-0 fa-solid fa-ellipsis bg-white bg-opacity-85 rounded-full m-[8px] p-[8px] z-10 shadow-xl"
        />
        <Media
          data={URL.createObjectURL(file)}
          controls
          isVideoProps={isVideo}
          className="absolute top-0 left-0 w-full h-full "
        />
      </button>
      <Popover
        id={anchorEl ? "filter-popper" : undefined}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="bg-white p-[16px] shadow-[0_0_4px_rgba(0,0,0,0.2)]">
          {/* <button
            type="button"
            className="p-[8px] cursor-pointer w-full text-start"
          >
            {t("common.action.edit")}
          </button> */}
          <button
            type="button"
            onClick={() => setVisibleModalConfirmDelete(true)}
            className="p-[8px] cursor-pointer w-full text-start"
          >
            {t("common.action.delete_2")}
          </button>
        </div>
      </Popover>
      <CustomModal
        visible={visibleModalConfirmDelete}
        onSubmit={() => {
          if (onDelete) {
            onDelete(data);
            setAnchorEl(false);
            setVisibleModalConfirmDelete(false);
          }
        }}
        onClose={() => {
          setAnchorEl(false);
          setVisibleModalConfirmDelete(false);
        }}
        cancelText={t("common.action.cancel")}
        confirmText={t("common.action.delete")}
        title={
          <div className="px-[24px] text-[16px] font-bold">
            {t("operator.advertisement.detail.confirm_delete_image")}
          </div>
        }
      >
        <div className="py-[24px]">
          {t("operator.advertisement.detail.confirm_delete_notice")}
        </div>
      </CustomModal>
    </>
  );
}

export default UploadFile;
