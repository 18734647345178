/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import cx from "classnames";
import { Skeleton } from "@mui/material";
import { useSelector } from "react-redux";

import { LazyLoadImage } from "react-lazy-load-image-component";

const NO_AVAILABLE_IMG = require("app/assets/images/no-available-image.svg");
const IMG_AVATAR_DEFAULT = require("app/assets/images/default_avatar.png");
const IMG_DEFAULT = require("app/assets/images/default_image.png");

function ImageItem(props) {
  const {
    imagesrc,
    imgclassname,
    containerclassname,
    avatar,
    loadavatar,
    accessible,
    skeletonclassname,
    lazy,
  } = props;
  const avatarUrl = useSelector((state) => state.profile?.avatar_url);
  const [imageSoure, setImageSource] = useState(imagesrc);
  const [isLoading, setIsLoading] = useState(!(avatarUrl && loadavatar));

  useEffect(() => {
    if (imagesrc) {
      setImageSource(imagesrc);
    } else if (avatar) {
      setImageSource(IMG_AVATAR_DEFAULT);
    } else {
      setImageSource(IMG_DEFAULT);
    }
  }, [imagesrc, isLoading, avatar]);

  return (
    <div
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={accessible ? 0 : -1}
      className={`relative h-full w-full overflow-hidden rounded-[12px] ${containerclassname}`}
    >
      <Skeleton
        animation="wave"
        variant="rectangular"
        className={cx(
          `!absolute !w-full !h-full top-0 left-0 z-10 ${
            avatar ? "!rounded-full" : ""
          } ${skeletonclassname}`,
          {
            block: isLoading,
            "!hidden": !isLoading,
          }
        )}
      />
      {lazy ? (
        <LazyLoadImage
          className={cx("lazy-image", imgclassname, {
            visible: !isLoading,
            invisible: isLoading,
          })}
          src={imageSoure}
          onLoad={() => {
            setTimeout(() => setIsLoading(false), 500);
          }}
          onError={() => setImageSource(NO_AVAILABLE_IMG.default)}
          alt=""
          {...props}
        />
      ) : (
        <img
          className={cx("normal-image", imgclassname, {
            visible: !isLoading,
            invisible: isLoading,
          })}
          src={imageSoure}
          onLoad={() => {
            setTimeout(() => setIsLoading(false), 500);
          }}
          onError={() => setImageSource(NO_AVAILABLE_IMG.default)}
          alt=""
          {...props}
        />
      )}
    </div>
  );
}

export default ImageItem;
