export default {
  tab1: {
    watch_video: '全編動画を視聴する',
    create_title: "タイトルを作成",
    introduce_video: "宿泊施設について紹介しましょう",
  },
  tab2: {
    select_keyword: "キーワードを２つまで選んでください",
    quiet: "静か",
    unique: "ユニーク",
    for_family: "ファミリー向け",
    stylish: "スタイリッシュ",
    center: "中心地",
    wide: "広い",
    price: "￥",
    average_price: "1泊あたりの平均宿泊料金",
    check_the_listing: "リスティングパ＝時をご確認ください！",
    confirm_before_pubhlishing: "保存の完了後、公開前にいくつか詳細を確認していただきます。",
    beautiful: "千代田区の美しいホテル",
    apartment_condominium: "志慶真さんのまるまる貸切のアパート・マンション",
    detail_guests: "ゲスト4人・1寝室・ベッド1台・1バスルーム",
    spoiled_choice: "こちらの快適なホテルなら、素敵なひとときをお過ごしいただけます。",
    welcome: "志慶真さん、ようこそ！",
  }
};
