/* eslint-disable camelcase */
import { FILE_UPLOAD_TYPE } from "app/chat/enum/ChatEnum";
import { emailRegex, EResponseStatus } from "app/constant";
import { setActive } from "app/redux/blockUIReducer";
import store from "app/redux/store";
import CommonService from "app/services/api/CommonService";
import { ICTL_API_ENDPOINT } from "app/services/constant";
import { object, string } from "yup";
import UserService from "app/services/api/UserService";
import { setProfileStore } from "app/redux/profileReducer";
import {
  formatGetPhoneNumberWithPrefix,
  formatUpdatePhoneNumberData,
  isNewFileUploading,
  removeFieldEmptyValue,
} from "..";

export const ACCOUNT_ACTION = {
  STORE: "STORE",
  UPDATE: "UPDATE",
  CANCEL: "CANCEL",
  DELETE: "DELETE",
  DELETE_OWN: 'DELETE_OWN'
};

export const accountSchema = (t) => {
  return object({
    account_family_name: string().when(["account_first_name"], {
      is: (account_first_name) => {
        return !account_first_name?.trim();
      },
      then: string().required().trim(),
    }),
    account_first_name: string(),
    account_phone: string()
      .required()
      .trim()
      .min(
        4,
        t("validate_message.required", {
          path: t("validation_field.account_phone"),
        })
      ),
    account_email: string().required(t("common.message.email_message")).trim().matches(emailRegex, { message: t("common.message.email_invalid_message")}).max(64),
  });
};



export const formatGetAccountDetailData = (data) => {
  const {
    email_address,
    family_name,
    first_name,
    phone_number,
    avatar_url,
    is_seeding_admin,
  } = data;

  const phoneFormat = formatGetPhoneNumberWithPrefix(phone_number?.main);

  const formatData = {
    account_family_name: family_name || "",
    account_first_name: first_name || "",
    account_email: email_address?.main || "",
    account_phone: phoneFormat,
    account_avatar: avatar_url || "",
    is_seeding_admin,
  };

  return formatData;
};

export const updateOrCreateAccount = async ({
  accountId,
  data,
  callbackSuccess,
  callbackError,
}) => {
  const {
    account_family_name,
    account_first_name,
    account_email,
    account_phone,
    account_avatar,
  } = data;

  const phoneFormat = formatUpdatePhoneNumberData(account_phone);

  const payload = {
    family_name: account_family_name,
    first_name: account_first_name,
    phone_number: {
      main: phoneFormat,
    },
    email_address: {
      main: account_email,
    },
  };

  // set on block ui
  store.dispatch(setActive(true));

  // upload file
  if (isNewFileUploading(account_avatar)) {
    const uploadResponse = await CommonService.uploadFile(
      account_avatar,
      FILE_UPLOAD_TYPE.IMAGE
    ).catch(() => {
      store.dispatch(setActive(false));
      if (callbackError) {
        callbackError();
      }
      return Promise.reject();
    });
    payload.avatar_url = uploadResponse?.data?.data?.thumbnail_url;
  } else {
    payload.avatar_url = account_avatar;
  }

  // store or update account
  CommonService.postModel(
    accountId
      ? ICTL_API_ENDPOINT.UPDATE_ACCOUNT(accountId)
      : ICTL_API_ENDPOINT.STORE_ACCOUNT,
    removeFieldEmptyValue(payload)
  )
    .then((response) => {
      if (response.status === EResponseStatus.CREATED && callbackSuccess) {
        const dataResponse = response?.data?.data;
        const { user_id } = store.getState().auth.user;
        if (dataResponse?.user_id === user_id) {
          UserService.getProfile().then((res) => {
            if (res.status === EResponseStatus.SUCCESS) {
              store.dispatch(setProfileStore(res.data.data));
            }
          });
        }
        callbackSuccess(
          accountId ? ACCOUNT_ACTION.UPDATE : ACCOUNT_ACTION.STORE
        );
      }
    })
    .catch((error) => {
      if (callbackError) {
        callbackError(
          accountId ? ACCOUNT_ACTION.UPDATE : ACCOUNT_ACTION.STORE,
          error
        );
      }
    })
    .finally(() => {
      store.dispatch(setActive(false));
    });
};
