import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import ImageItem from "app/components/ImageItem";
import SuccessDialog from "app/components/SuccessDialog";
import ErrorDialog from "app/components/ErrorDialog";
import { updateProfileAction } from "app/utils/page/profile";
import UploadAvatar from "app/components/UploadAvatar";
import MainContent from "app/components/MainContent";
import Path from "../../route/Path";
import LayoutDefault from "../../layouts/LayoutDefault";
import { setProfileStore } from "../../../redux/profileReducer";
import { Roles } from "../../../constant";

function Profile() {
  const matches = useMediaQuery("(min-width:1280px)");
  const { profile } = useSelector((state) => state);
  const role = useSelector((state) => state.auth?.user?.group_roles?.roles[0]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formikRef = useRef();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [message, setMessage] = useState("");
  const [uploaded, setUploaded] = useState(false);
  const validationSchema = Yup.object().shape({
    avatar_url: Yup.string().nullable(),
  });
  const initialValues = {
    avatar_url: "",
  };

  const openSuccessDiaglog = (mess) => {
    setOpenSuccess(true);
    setMessage(mess || t("common.message.update_success"));
  };

  const openErrorDiaglog = (mess) => {
    setOpenError(true);
    setMessage(mess || t("common.message.update_fail"));
  };

  // set init formik values from profile store
  useEffect(() => {
    const { setValues } = formikRef.current;
    setValues(profile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const updateProfile = (values) => {
    // const { setValues } = context;
    updateProfileAction({
      profile,
      values,
      callbackSuccess: () => {
        dispatch(setProfileStore(values));
        openSuccessDiaglog();
      },
      callbackError: () => {
        // setValues(formValues);
        openErrorDiaglog();
      },
      callbackFinally: () => {
        setUploaded(false);
      },
    });
  };
  return (
    <LayoutDefault showFooter showHeader>
      <header className="md:hidden px-4">
        <div className="flex items-center relative h-16">
          <Link
            to={
              role === Roles.MEMBER
                ? "/staff/account-settings"
                : Path.account_settings
            }
            className="w-7 h-7 flex items-center justify-center absolute left-0 top-[50%] translate-y-[-50%]"
          >
            <i className="fa-solid fa-chevron-left" />
          </Link>
        </div>
      </header>

      <MainContent showMobileNav noScreenHieght>
        <Formik
          innerRef={formikRef}
          validateOnMount
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={updateProfile}
        >
          {(props) => {
            const { setFieldValue, values } = props;

            return (
              <Form className="w-full">
                <div className="md:mt-20">
                  <div className="w-full px-4 py-9 md:max-w-[680px] xl:max-w-[1080px] mx-auto text-REGULAR_TEXT_COLOR">
                    <div className="w-full flex">
                      <div className="hidden xl:block w-1/3 pr-10">
                        <div className="w-full h-[400px] p-6 pb-10 rounded-xl border border-border">
                          <div className="w-full flex flex-col items-center h-full border-b border-border">
                            <div className="w-[128px] h-[128px] mb-3">
                              <ImageItem
                                avatar="true"
                                loadavatar="true"
                                containerclassname="!rounded-full"
                                role="presentation"
                                imagesrc={values?.avatar_url}
                                alt=""
                                imgclassname="w-full h-full object-cover rounded-full overflow-hidden"
                              />
                            </div>
                            {matches && (
                              <UploadAvatar
                                setFieldValue={setFieldValue}
                                openErrorDiaglog={openErrorDiaglog}
                                updateProfile={updateProfile}
                                values={values}
                                uploaded={uploaded}
                                setUploaded={setUploaded}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="w-full xl:w-2/3 flex flex-col gap-6">
                        <div className="flex items-center justify-between gap-2">
                          <div className="flex-1">
                            {/* <h1 className="text-[26px] leading-9 font-semibold mb-2">{`こんにちは、${profile.first_name}です`}</h1> */}

                            <p className="text-sm font-normal leading-[18px] text-danger-DESCRIPTION">
                              {t("profile.register_as", {
                                date: dayjs(profile?.created_at).format(
                                  t("common.date_format.yyyy_mm_dd")
                                ),
                              })}
                            </p>
                          </div>
                          <div className="xl:hidden flex flex-col items-center">
                            <div className="shrink-0 block">
                              <div className="w-[88px] h-[88px]">
                                <ImageItem
                                  loadavatar="true"
                                  avatar="true"
                                  containerclassname="!rounded-full"
                                  role="presentation"
                                  imagesrc={values?.avatar_url}
                                  alt=""
                                  imgclassname="w-full h-full object-cover rounded-full overflow-hidden"
                                />
                              </div>
                            </div>
                            {!matches && (
                              <UploadAvatar
                                setFieldValue={setFieldValue}
                                openErrorDiaglog={openErrorDiaglog}
                                updateProfile={updateProfile}
                                values={values}
                                uploaded={uploaded}
                                setUploaded={setUploaded}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </MainContent>
      <SuccessDialog
        message={message}
        open={openSuccess}
        onClose={() => setOpenSuccess(false)}
        onOK={() => setOpenSuccess(false)}
      />
      <ErrorDialog
        message={message}
        open={openError}
        onClose={() => setOpenError(false)}
        onOK={() => setOpenError(false)}
      />
    </LayoutDefault>
  );
}

export default Profile;
