export default {
  personal_info: {
    personal_info: "個人情報",
    name_label: "氏名（本名）",
    first_name: "名",
    last_name: "姓",
    edit_btn: "編集する",
    dob_label: "生年月日",
    country_region: "国",
    phone_label: "電話番号",
    goverment_label: "政府発行ID",
    address_label: "住所",
    postcode: "郵便番号",
    state_provine_country: "都道府県",
    municipaliites: "市区町村",
    address: "番地",
    apartment_name: "アパート名、部屋番号など",
    invalid_birthday: "生年月日に有効な日付を入力してください。",
    go_to_profile: "プロフィールへ",
    personal_info_notice: "個人情報と連絡先を入力してください",
    login_security: "ログインとセキュリティ",
    login_security_notice: "アカウント保護のためパスワード変更が必要です",
    global_setting: "グローバル設定",
    global_setting_notice: "デフォルトの言語、通貨、タイムゾーンの設定を行う",
    withdraw: "退会する",
  },
  login_security: {
    auth_form: "ログイン",
    password: "パスワード",
    password_last_update: "最終更新日: ",
    current_password: "現在のパスワード",
    new_password: "新しいパスワード",
    confirm_password: "パスワードを確認",
    change_password: "パスワードを変更する",
    change_email: "メールアドレスを変更",
    social_account: "SNSアカウント",
    connected: "接続済み",
    not_connect: "接続されていません",
    connecting: "接続する",
    disconnect: "接続を解除",
    linked_success: "接続成功。",
    linked_fail: "接続に失敗しました。もう一度お試しください。",
    unlink_success: "正常に切断されました。",
    unlink_fail: "切断に失敗しました。 もう一度お試しください。",
    email_exist: "アカウントはすでに存在しています。",
    unlink_notice:
      "このアカウントにリンクされている {{sns}} アカウントを切断してもよろしいですか?",
    logout_notice:
      "更新されました。ログイン情報を変更したためログアウトします。",
  },
  widthdraw: {
    step_1: "理由を選択",
    step_2: "確認",
    step_3: "完了",
    step_1_title: "退会理由",
    step_1_first_reason: "安全またはプライバシーに関する懸念があります。",
    step_1_second_reason: "Buzz Travelerの利用規約を遵守する事ができません。",
    step_1_third_reason: "思ったサービスと違いました。",
    step_1_fourth_reason: "その他",
    step_2_title: "本当に退会しますか?",
    step_2_first_notice: "こちらのアカウントの情報は全て削除されます。",
    step_2_second_notice: "退会したアカウントは復旧する事ができません。",
    step_3_title: "退会処理完了",
    go_to_top: "TOP画面へ",
    widthdraw_success: "正常に退会処理は行われました。",
    widthdraw_fail:
      "退会処理でエラーが発生しました。時間を置いて再度お試しください。",
  },
};
