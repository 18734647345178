import React from "react";
import { useTranslation } from "react-i18next";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ExpandMore } from "@mui/icons-material/";
import FormItem, { TYPE_INPUT } from "app/operator/components/FormItem";
import { ErrorMessage } from "formik";
import { EProjectSNS } from "../../../../constant";
import { generateSNSIcon } from "../../../../utils";

function AdMedia({ values: formValues }) {
  const { t } = useTranslation();

  const projectSNSList = Object.values(EProjectSNS)?.map((value) => ({
    value,
    optionName: (
      <div className="flex items-center">
        {generateSNSIcon(value)}
        <div className="capitalize">{ value === 'twitter' ? t("media.twitter") : value }</div>
      </div>
    ),
  }));
  return (
    <div className="mb-[16px]">
      <div className="mb-[8px] font-semibold">
        {t("operator.advertisement.detail.adMedia")}
        <span className="required" />
      </div>
      <Accordion
        sx={{
          borderBottom: "2px solid #e5e7eb",
          boxShadow: "none",
          ":before": {
            backgroundColor: "transparent",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className=" text-gray font-semibold">
            <div className="flex items-center">
              {console.log(formValues?.adMedia)}
              {formValues?.adMedia ? (
                formValues?.adMedia?.map(item => (
                  <>
                  {generateSNSIcon(item)}
                  <div className="capitalize">{ item === 'twitter' ? t("media.twitter") : item}</div>
                  </>
                 )
                )
              ) : (
                <ErrorMessage
                  name="adMedia"
                  component="div"
                  className="errors-msg !font-normal"
                />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <FormItem
            disableError
            stringValue
            name="adMedia"
            containerClassName="mb-[20px]"
            typeInput={TYPE_INPUT.CHECKBOX}
            componentProps={{
              defaultValue: formValues?.adMedia,
              optionsList: projectSNSList,
            }}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default AdMedia;
