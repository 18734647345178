/* eslint-disable no-undef */
import React from "react";
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import SettingsIcon from "@mui/icons-material/Settings";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MainContent from "app/components/MainContent";
import LayoutDefault from 'app/ictl/layouts/LayoutDefault';
import TableDetailListPay from "./TableDetailListPay";

const BootstrapSelect = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 10,
    border: '1px solid #161718FF',
    padding: '6px 30px 6px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      borderRadius: 10,
      borderColor: '#575d64',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

function createData(nameInfluencer, nameProject, paymentAmount, price, numbers, requestNumbers, setting) {
  return {
    nameInfluencer,
    nameProject,
    paymentAmount,
    price,
    numbers,
    requestNumbers,
    setting
  };
}

const rows = [
  createData('インフルエンサーA', '９月限定プラン紹介', false, '¥2, 000', false, '¥100', false),
  createData('インフルエンサーB', 'カップルプラン紹介', false, ' ¥10,000', false, '¥10,000', false),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'nameInfluencer',
    label: 'インフルエンサー名',
  },
  {
    id: 'nameProject',
    label: '案件名',
  },
  {
    id: 'paymentAmount',
    label: '請求金額',
  },
  {
    id: 'price',
    label: '単価',
  },
  {
    id: 'numbers',
    label: '件数',
  },
  {
    id: 'requestNumbers',
    label: '請求番号',
  },
  {
    id: 'setting',
    label: <SettingsIcon />
  }
];


function DetailList() {

  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <LayoutDefault showFooter showHeader pin={6}>
      <MainContent>
        <div className="grid grid-cols-2">
          <form className='max-w-[568px] ml-3 mt-28'>
            <div className='mt-10'>
              <p className='text-[17px] font-bold'>月を選択</p>
              <div className='flex flex-col mb-10'>
                <FormControl size="small" className="xl:max-w-2xl 2xl:max-w-2xl lg:max-w-2xl max-w-sm rounded-lg">
                  <Select
                    value={age}
                    onChange={handleChange}
                    displayEmpty
                    input={<BootstrapSelect />}
                  >
                    <MenuItem value=""><em>2022年9月（現在）</em></MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className='mb-10'>
              <h2 className="font-bold text-2xl">¥ 22,000</h2>
              <p>2022年9月の予約請求</p>
            </div>

            <div className='mb-10'>
              <div className='flex'>
                <div>
                  <h2 className="font-bold">¥ 10,000</h2>
                  <div className='flex'>
                    <FormGroup>
                      <FormControlLabel control={<Checkbox defaultChecked />} label="入金済み" />
                    </FormGroup>
                  </div>
                </div>
                <div className='ml-5'>
                  <h2 className="font-bold">¥ 12,000</h2>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox />} label="入金済み" />
                  </FormGroup>
                </div>
              </div>
            </div>
          </form>
          <div className='max-w-180 ml-3 mt-28'>
            <div className='mt-9'>
              <div className='flex flex-col'>
                <FormControl size="small" className="xl:max-w-2xl 2xl:max-w-2xl lg:max-w-2xl max-w-sm rounded-lg">
                  <p className='text-[17px] font-bold'>事業者名</p>
                  <div className="flex items-center border-b  py-2">
                    <input
                      id="name"
                      className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                      type="text"
                      value="ABC"
                      aria-label="Full name"
                      disabled
                    />
                  </div>
                </FormControl>
              </div>
            </div>
            <div className='mt-5'>
              <div className='flex flex-col'>
                <FormControl size="small" className="xl:max-w-2xl 2xl:max-w-2xl lg:max-w-2xl max-w-sm rounded-lg">
                  <p className='text-[17px] font-bold'>郵便番号</p>
                  <div className="flex items-center border-b  py-2">
                    <input
                      id="name"
                      className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                      type="text"
                      value="123456"
                      aria-label="Full name"
                      disabled
                    />
                  </div>
                </FormControl>
              </div>
            </div>
            <div className='mt-5'>
              <div className='flex flex-col'>
                <FormControl size="small" className="xl:max-w-2xl 2xl:max-w-2xl lg:max-w-2xl max-w-sm rounded-lg">
                  <p className='text-[17px] font-bold'>住所</p>
                  <div className="flex items-center border-b  py-2">
                    <input
                      id="name"
                      className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                      type="text"
                      value="ABCXYZ"
                      aria-label="Full name"
                      disabled
                    />
                  </div>
                </FormControl>
              </div>
            </div>
            <div className='mt-5'>
              <div className='flex flex-col'>
                <FormControl size="small" className="xl:max-w-2xl 2xl:max-w-2xl lg:max-w-2xl max-w-sm rounded-lg">
                  <p className='text-[17px] font-bold'>電話番号</p>
                  <div className="flex items-center border-b  py-2">
                    <input
                      id="name"
                      className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                      type="text"
                      value="123456"
                      aria-label="Full name"
                      disabled
                    />
                  </div>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <div className='mb-10'>
          <h2 className="font-bold text-2xl mb-4">請求件数 2件</h2>
        </div>

        <div className='mb-10'>
          <TableDetailListPay getComparator={getComparator} stableSort={stableSort} headCells={headCells} rows={rows} />
        </div>

      </MainContent>
    </LayoutDefault>
  )
}

export default DetailList;