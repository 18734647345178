import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import { Group, Roles } from "app/constant";
import Path from "../operator/route/Path";

export default function AuthLogin() {
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  /* eslint prefer-destructuring: ["error", {AssignmentExpression: {array: true}}] */
  const roles = user?.group_roles.roles[0];
  const group = user?.group_roles.group;

  if (user && location.pathname === '/login') {
    let redirect = new URLSearchParams(location.search || "").get("redirect");
    redirect = redirect && redirect !== "/" ? redirect : "";
    if (group === Group.FACILITY && roles === Roles.REPRESENT) {
      if (user.is_first_login === true) {
        return (
          <Navigate
            to={redirect || Path.operator_provide_host}
            state={{ from: location }}
            replace
          />
        )
      }
      return (
        <Navigate
          to={redirect || Path.operator}
          state={{ from: location }}
          replace
        />
      )
    }
    if (group === Group.FACILITY && roles === Roles.MEMBER) {
      return (
        <Navigate
          to={redirect || "/staff"}
          state={{ from: location }}
          replace
        />
      )
    }
    if (group === Group.ITCL) {
      return (
        <Navigate
          to={redirect || "/admin"}
          state={{ from: location }}
          replace
        />
      )
    }
  }
  // Redirect them to the /login page, but save the current location they were
  // trying to go to when they were redirected. This allows us to send them
  // along to that page after they login, which is a nicer user experience
  // than dropping them off on the home page.
  return <Outlet />;
}
