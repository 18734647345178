/* eslint-disable react/no-array-index-key */
import React, { useContext, useState, useEffect } from "react";
import { Rating, Button } from "@mui/material";
import { InfluencerDetailContext } from "app/operator/pages/InfluencerCMS/InfluencerDetail";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import ImageItem from "app/components/ImageItem";
import AdProjectService from "app/services/api/operatorService/AdProjectService";
import { EFileType, EResponseStatus } from "app/constant";
import ConfirmDialog from "app/operator/pages/AdvertisementManage/AdvertisementSocial/ConfirmDialog";
import { useTranslation } from "react-i18next";

function ProfileContent(props) {
  const { t } = useTranslation();
  const { projectId, influencerId } = props;
  const { data } = useContext(InfluencerDetailContext);
  const [isApplied, setIsApplied] = useState(false);
  const [open, setOpen] = useState(false);
  const applicationDetail =
    data?.application_details &&
    data?.application_details[0] &&
    data?.application_details[0].portfolio;

  useEffect(() => {
    setIsApplied(data?.is_apply_project);
  }, [data]);

  // validate
  const validationSchema = Yup.object().shape({
    offerMessage: Yup.string().required(),
  });
  const initialValues = {
    offerMessage: "",
  };

  const onConfirmSend = () => {
    setOpen(true);
  };

  const sendSpecialOffer = (values, context) => {
    const { resetForm } = context;
    // send offer
    const body = {
      request: {
        message: values.offerMessage,
      },
    };
    AdProjectService.sendSpecialOffer(projectId, influencerId, body).then(
      (res) => {
        if (res.status === EResponseStatus.CREATED) {
          resetForm();
          setOpen(false);
          setIsApplied(true);
        }
      }
    );
  };

  return (
    <div className="w-full">
      <div className="flex justify-end">
        <Rating
          size="medium"
          name="read-only"
          precision={0.1}
          value={data?.rating || 0}
          readOnly
        />
      </div>
      <div className="flex justify-center">
        <div className="w-[90px] h-[90px] md:w-[120px] md:h-[120px]">
          <ImageItem
            loadavatar="true"
            avatar="true"
            containerclassname="!rounded-full"
            role="presentation"
            imagesrc={data?.avatar_url}
            alt=""
            imgclassname="w-full h-full object-cover rounded-full"
          />
        </div>
      </div>

      <div className="py-6">
        <p className="mb-2 font-bold text-[14px]">
          {t("operator.influencerDetail.self_introduction")}
        </p>
        <p className="text-[14px] pl-2 whitespace-pre-wraptext-gray">
          {data?.self_introduction}
        </p>
      </div>

      <div className="mb-10">
        <p className="mb-2 font-bold text-[14px]">
          {" "}
          {t("operator.influencerDetail.nickname")}
        </p>
        <p className="text-[14px] pl-2 text-gray">{data?.nickname}</p>
      </div>

      {(() => {
        if (!isApplied || (isApplied && !applicationDetail)) {
          return (
            <div className="flex justify-center">
              <Button
                onClick={onConfirmSend}
                variant="contained"
                disabled={isApplied}
                className="!px-6 !py-3 !text-white !bg-REGULAR_TEXT_COLOR !rounded-[8px] !mt-4"
              >
                {t("operator.influencerDetail.send_offer")}
              </Button>
            </div>
          );
        }
        if (isApplied && applicationDetail) {
          return (
            <>
              <div className="py-6">
                <p className="mb-2 font-bold">
                  {" "}
                  {t("operator.influencerDetail.self_pr")}
                </p>
                <p className="text-[14px] whitespace-pre-wrap word-break">
                  {applicationDetail?.summary}
                </p>
              </div>

              <div className="py-6">
                <p className="mb-2 font-bold">
                  {t("operator.influencerDetail.url_pr")}
                </p>
                {applicationDetail?.works.web.map((item, index) => {
                  return (
                    <a
                      key={index}
                      href={item}
                      className="text-[#3B7CFF] underline mb-2"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item}
                    </a>
                  );
                })}
              </div>

              <div className="py-6">
                <p className="mb-2 font-bold">
                  {t("operator.influencerDetail.file_pr")}
                </p>
                <div className="flex flex-col gap-5">
                  {applicationDetail?.files.map((item, index) => {
                    const format = item?.pr_file?.substring(
                      item.pr_file.lastIndexOf(".") + 1
                    );
                    const imageFormat = ["jpg", "png", "jpeg", "heic"];
                    return (
                      <div
                        key={index}
                        className="flex flex-wrap sm:flex-nowrap items-end gap-2"
                      >
                        <div
                          className={`w-full relative sm:pt-[25%] ${
                            !imageFormat.includes(format) ? "pt-[60%]" : ""
                          }`}
                        >
                          {(() => {
                            if (format === EFileType.MP4) {
                              return (
                                <div className="absolute max-h-[300px] sm:max-h-[unset] top-0 w-full h-full border border-secondary-200 bg-white flex items-center justify-center text-[24px] font-bold">
                                  MP4
                                </div>
                              );
                            }
                            if (format === EFileType.PDF) {
                              return (
                                <button
                                  type="button"
                                  onClick={() =>
                                    window.open(item?.pr_file, "_blank")
                                  }
                                  className="absolute max-h-[300px] sm:max-h-[unset] top-0 w-full h-full border border-secondary-200 bg-white flex items-center justify-center text-[24px] font-bold"
                                >
                                  PDF
                                </button>
                              );
                            }
                            return (
                              <ImageItem
                                containerclassname="!rounded-none sm:!absolute sm:!top-0 !w-full max-h-[300px] sm:max-h-[unset]"
                                role="presentation"
                                imagesrc={item?.pr_file}
                                alt=""
                                imgclassname="w-full h-full object-cover"
                              />
                            );
                          })()}
                        </div>
                        <p className="word-break w-full sm:w-[164%]">
                          {item?.name_file}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          );
        }
        return null;
      })()}

      <Formik
        validateOnMount
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={sendSpecialOffer}
      >
        {(prop) => {
          const { handleSubmit, resetForm } = prop;
          return (
            <Form>
              <ConfirmDialog
                open={open}
                onClose={() => {
                  setOpen(false);
                  resetForm();
                }}
                onOK={handleSubmit}
              >
                <div className="flex-1">
                  <Field
                    as="textarea"
                    placeholder={t(
                      "operator.advertisement.dialog.approve_report_placeholder"
                    )}
                    name="offerMessage"
                    className="w-full h-[200px] border border-solid border-secondary-200 resize-none px-3 py-2 !rounded-[12px]"
                  />
                  <ErrorMessage
                    component="div"
                    name="offerMessage"
                    className="error-msg"
                  />
                </div>
              </ConfirmDialog>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ProfileContent;
