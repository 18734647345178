import React from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  Outlet,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { Group } from "app/constant";
import NotFound from "../auth/pages/404";

export default function AuthRouteFacility() {
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [param, setParam] = useSearchParams();
  const user = useSelector((state) => state.auth.user);
  /* eslint prefer-destructuring: ["error", {AssignmentExpression: {array: true}}] */
  // const roles = user?.group_roles.roles[0];
  const group = user?.group_roles.group;
  if (group === Group.FACILITY && location.pathname !== "/") {
    return <Outlet />;
  }

  // Redirect them to the /login page, but save the current location they were
  // trying to go to when they were redirected. This allows us to send them
  // along to that page after they login, which is a nicer user experience
  // than dropping them off on the home page.
  if (location.pathname === "/") {
    if (!user) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
    return <Navigate to="/operator" />;
  }

  if (location.pathname && param) {
    const name = encodeURIComponent(`${location.pathname}?${param.toString()}`);
    if (!user) {
      return (
        <Navigate
          to={`login?redirect=${name}`}
          state={{ from: location }}
          replace
        />
      );
    }
  }

  return <NotFound />;
}
