import Tooltip from "@mui/material/Tooltip";
import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import UploadButton from "app/chat/components/UploadButton";
import CheckDevice from "app/utils/CheckDevice";
import CloseIcon from "@mui/icons-material/Close";
import CommonService from "app/services/api/CommonService";
import CircularProgress from "@mui/material/CircularProgress";
import { APPLIED_PROJECT_STATUS, MAX_LIMIT } from "../../constant";
import { FILE_UPLOAD_TYPE, MEDIA_TYPE } from "../enum/ChatEnum";

function MessageInput({
  messageInputRef,
  messageInputClassName,
  showScrollToBottom,
  handleScrollToBottom,
  handleCallSendMessage,
  handleCallUploadFile,
  handleListMessageMarginBottom,
  statusText,
}) {
  // other import
  const { t } = useTranslation();
  const inputMessage = useRef();
  const isMobile = CheckDevice.checkIsMobile();

  // state
  const [isFocus, setIsFocus] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [messageError, setMessageError] = useState("");

  // upload
  const [uploadFile, setUploadFile] = useState(null);
  const [isUpload, setIsUpload] = useState(false);
  const [isPdf, setIsPdf] = useState(null);
  const [openUploadedMessage, setOpenUploadedMessage] = useState(false);
  const [isOpenUploadedMessageError, setIsOpenUploadedMessageError] =
    useState(false);
  const [uploadedMessage, setUploadedMessage] = useState("");

  // open and close upload tooltip
  const handleUploadTooltipOpen = ({ message, isToolTipError, closeTime }) => {
    if (!closeTime) closeTime = 2000;
    if (message) {
      setOpenUploadedMessage(true);
      setIsOpenUploadedMessageError(isToolTipError);
      setUploadedMessage(message);
    }
    const timeOut = setTimeout(() => {
      setOpenUploadedMessage(false);
      setIsOpenUploadedMessageError(false);
      setUploadedMessage("");
      clearTimeout(timeOut);
    }, closeTime);
  };

  const handleUploadFile = (file, pdf) => {
    setUploadFile(file);
    setIsPdf(pdf);

    // reset message
    inputMessage.current.value = "";
    setIsEmpty(true);
  };

  const messageInputCalHeight = () => {
    if (inputMessage.current) {
      inputMessage.current.style.height = "5px";
      inputMessage.current.style.height = `${inputMessage.current.scrollHeight}px`;
    }
  };

  const handleDeleteUploadFile = () => {
    setUploadFile(null);
    setTimeout(() => {
      messageInputCalHeight();
    }, 0);
  };

  useEffect(() => {
    messageInputCalHeight();
    handleListMessageMarginBottom(messageInputRef.current.clientHeight);
  }, [handleListMessageMarginBottom, messageInputRef]);

  const validateInputMessage = () => {
    messageInputCalHeight();

    const messageText = inputMessage.current.value.trim();

    if (!messageText) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }

    if (messageText.length > MAX_LIMIT.INPUT_TEXT_AREA) {
      setMessageError(
        t("validate_message.max_length", {
          path: t("validation_field.replyMessage"),
          max: MAX_LIMIT.INPUT_TEXT_AREA,
        })
      );
      return;
    }

    // if (
    //   messageText.includes("<script") ||
    //   messageText.includes("<?") ||
    //   messageText.includes("<%")
    // ) {
    //   setMessageError(t("chat.validate.xxs_error"));
    //   return;
    // }

    setMessageError("");
  };

  const handleInputFocus = () => {
    setIsFocus(true);
  };

  const handleInputBlur = () => {
    setIsFocus(false);
    validateInputMessage();
  };

  const handleSendMessage = () => {
    if (uploadFile) {
      if (isUpload) return;

      setIsUpload(true);
      CommonService.uploadFile(
        uploadFile,
        isPdf ? FILE_UPLOAD_TYPE.PDF : FILE_UPLOAD_TYPE.IMAGE
      )
        .then((res) => {
          if (res.data.data) {
            const { data } = res.data;
            handleCallUploadFile({
              thumbnailUrl: data.thumbnail_url || null,
              mediaUrl: data.media_url,
              fileName: uploadFile.name,
              mediaType: isPdf ? MEDIA_TYPE.VIDEO : MEDIA_TYPE.IMAGE,
            });
            handleUploadTooltipOpen({ message: t("chat.message.uploaded") });
          }
        })
        .catch(() => {
          handleUploadTooltipOpen({
            message: t("chat.message.upload_failed"),
            isToolTipError: true,
          });
        })
        .finally(() => {
          setUploadFile(null);
          setIsUpload(false);
          messageInputCalHeight();
        });
    } else if (!messageError && !isEmpty) {
      if (isMobile) {
        inputMessage.current.blur();
      }

      setTimeout(() => {
        handleCallSendMessage(inputMessage.current.value.trim());
        inputMessage.current.value = "";
        messageInputCalHeight();
        setIsEmpty(true);
      }, 0);
    }
  };

  const handleInputKeydown = (event) => {
    if (
      event.key === "Enter" &&
      (event.shiftKey || event.altKey || event.ctrlKey)
    ) {
      return;
    }
    if (event.key === "Enter") {
      event.preventDefault();
      handleSendMessage();
    }
    setTimeout(() => {
      handleListMessageMarginBottom(messageInputRef.current.clientHeight);
    }, 0);
  };

  const inputBorderColor = () => {
    if (messageError) return "border-2 border-red-400";
    if (isFocus) return "border-2 border-blue-400";
    return "border border-gray-300";
  };

  const textAreaDisabledBackground = () => {
    let background = "";
    if (statusText === APPLIED_PROJECT_STATUS.COMPLETED) {
      background = "bg-[#f1f1f1]";
    }
    return background;
  };

  return (
    <div
      ref={messageInputRef}
      className={`fixed bottom-0 right-0 w-full z-[11] ${messageInputClassName}`}
    >
      <div
        role="presentation"
        className={`flex justify-center mb-1 cursor-pointer ${
          showScrollToBottom ? "scale-up h-auto" : "scale-down h-0"
        }`}
        onClick={handleScrollToBottom}
      >
        <div className="flex justify-center items-center h-10 w-10 bg-purple rounded-full">
          <i className="fa-solid fa-arrow-down pt-px text-white" />
        </div>
      </div>

      <div className="pt-4 md:pt-3 px-3 pb-6 md:pb-3 bg-white">
        <div className="message-input-wrapper flex">
          {/* upload button */}
          <UploadButton
            isUpload={!!uploadFile}
            openUploadedMessage={openUploadedMessage}
            uploadedMessage={uploadedMessage}
            isOpenUploadedMessageError={isOpenUploadedMessageError}
            handleUploadTooltipOpen={handleUploadTooltipOpen}
            statusText={statusText}
            onUploadFile={handleUploadFile}
          />
          {/* message input */}
          <Tooltip
            open={!!messageError}
            title={<h1 className="text-[14px]">{messageError}</h1>}
            placement="top"
            arrow
          >
            <div
              className={`rounded-[1rem] w-full py-2 px-3 min-h-[48px] flex items-center ${textAreaDisabledBackground()} ${inputBorderColor()}`}
            >
              {uploadFile ? (
                <div className="relative">
                  <button
                    type="button"
                    onClick={handleDeleteUploadFile}
                    className="cursor-pointer absolute right-0 top-0 p-[2px] bg-white rounded-full mt-1 mr-1 h-7 w-7 flex justify-center items-center hover:bg-gray-300"
                  >
                    <CloseIcon className="text-[15px]" />
                  </button>
                  {isPdf ? (
                    <div className="h-28 w-28 object-cover rounded-md">
                      <i className="fa-solid text-blue-600 fa-file-pdf text-[85px] py-1 pl-1 pr-2" />
                      <Tooltip title={uploadFile.name} placement="top" arrow>
                        <div className="flex items-start justify-between">
                          <p className="text-[15px] truncate">
                            {uploadFile.name}
                          </p>
                        </div>
                      </Tooltip>
                    </div>
                  ) : (
                    <img
                      src={URL.createObjectURL(uploadFile)}
                      alt="preview-img"
                      className="h-28 w-28 object-cover rounded-md"
                    />
                  )}
                </div>
              ) : (
                <textarea
                  disabled={statusText === APPLIED_PROJECT_STATUS.COMPLETED}
                  ref={inputMessage}
                  className={`outline-0 border-0 resize-none w-full break-all max-h-[20vh] overflow-y-auto focus:outline-none ${textAreaDisabledBackground()}`}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  onKeyDown={handleInputKeydown}
                  onInput={validateInputMessage}
                  placeholder={
                    statusText === APPLIED_PROJECT_STATUS.COMPLETED
                      ? t("chat.message.cannot_send_to_completed")
                      : ""
                  }
                />
              )}
            </div>
          </Tooltip>

          {/* sent button */}
          <button
            type="button"
            disabled={
              isUpload ||
              statusText === APPLIED_PROJECT_STATUS.COMPLETED ||
              (isEmpty && !uploadFile) ||
              messageError
            }
            className={`flex flex-none justify-center items-center h-12 w-12 rounded-full ml-2 ${
              (isEmpty && !uploadFile) || messageError || isUpload
                ? "bg-gray-200"
                : "bg-blue-100 hover:bg-blue-300"
            }`}
            onClick={handleSendMessage}
          >
            {isUpload ? (
              <CircularProgress size={15} />
            ) : (
              <i
                className={`fa-solid fa-paper-plane pr-1 ${
                  (isEmpty && !uploadFile) || messageError
                    ? "text-gray-500"
                    : "text-blue-600"
                }`}
              />
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default MessageInput;
