/* eslint-disable react/no-array-index-key */
import React from "react";
import Drawer from "@mui/material/Drawer";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import ictlPath from "app/ictl/route/Path";
import { logout } from "app/utils";
import { useSelector } from "react-redux";

function HeaderDrawer({ isDrawerOpen, setDrawerState }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { unreadForV, pingForV } = useSelector((state) => state?.projectBatch);
  const lstMenu = [
    {
      id: 1,
      name: t("ictl_header.home"),
      path: ictlPath.home,
    },
    {
      id: 2,
      name: t("ictl_header.business_person"),
      path: ictlPath.business_person,
    },
    {
      id: 4,
      name: t("ictl_header.influencer"),
      path: ictlPath.influencer_list,
    },
    {
      id: 11,
      name: t("operator_header.jobs"),
      path: ictlPath.ad_project,
    },
    {
      id: 5,
      name: t("ictl_header.operation"),
      path: ictlPath.management_information,
    },
    {
      id: 6,
      name: t("ictl_header.claim"),
      path: ictlPath.pay_list,
    },
    {
      id: 7,
      name: t("ictl_header.payment"),
      path: ictlPath.payment,
    },
    {
      id: 8,
      name: t("ictl_header.notification"),
      path: ictlPath.notification,
    },
    {
      id: 9,
      name: t("ictl_header.banner"),
      path: ictlPath.banner,
    },
    {
      id: 10,
      name: t("ictl_header.account"),
      path: ictlPath.account,
    },
  ];

  const lstAccountMenu = [
    {
      path: ictlPath.profile,
      name: t("common_header.profile"),
    },
    {
      path: ictlPath.account_settings,
      name: t("common_header.account_setting"),
    },
  ];

  return (
    <Drawer
      anchor="top"
      open={isDrawerOpen}
      onClose={() => setDrawerState(false)}
      className="!z-[1029]"
      classes={{ paper: "mt-20 !h-full" }}
      transitionDuration={600}
      hideBackdrop
    >
      <div className="container px-6">
        <div className="py-10 border-b border-solid border-secondary-300">
          <p className="text-sm text-secondary-200 font-bold mb-4">
            {t("operator_header.other_menu")}
          </p>
          {lstMenu.map((item, index) => {
            return (
              <NavLink
                key={index}
                to={item.path}
                className="hover:bg-secondary flex items-center py-2"
              >
                {item.path === ictlPath.ad_project ? (
                  <div className="flex items-center">
                    <span className="mr-[2px] font-bold">{item.name}</span>
                    <div className="flex flex-col gap-[2px]">
                      {unreadForV > 0 && (
                        <div className="text-[10px] bg-[#8F0B6A] text-white min-w-[20px] min-h-[20px] px-1 flex items-center justify-center rounded-full self-start">
                          {unreadForV}
                        </div>
                      )}
                      {pingForV > 0 && (
                        <div className="text-[10px] bg-[#FFC000] text-white min-w-[20px] min-h-[20px] px-1 flex items-center justify-center rounded-full self-start">
                          {pingForV}
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <span className="font-bold">{item.name}</span>
                )}
              </NavLink>
            );
          })}
        </div>

        <div className="py-10 border-b border-solid border-secondary-300">
          <p className="text-sm text-secondary-200 font-bold mb-4">
            {t("common_header.account_setting")}
          </p>
          {lstAccountMenu.map((item, index) => {
            return (
              <NavLink
                key={index}
                to={item.path}
                className="hover:bg-secondary flex items-center py-2"
              >
                <span className="font-bold">{item.name}</span>
              </NavLink>
            );
          })}
        </div>

        <div className="pt-10 mb-40 font-bold">
          <button
            type="button"
            className="w-full border border-solid border-secondary-200 rounded-lg p-2"
          >
            <NavLink
              to=""
              onClick={(e) => {
                navigate("/");
                logout(e);
              }}
            >
              {t("common_header.log_out")}
            </NavLink>
          </button>
        </div>
      </div>
    </Drawer>
  );
}

export default HeaderDrawer;
