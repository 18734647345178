// v-008-003
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import LayoutDefault from "app/ictl/layouts/LayoutDefault";
import { PER_PAGE } from "../../../constant";
import { getListData } from "../../../utils/page/notification";
import {
  SortedAscendingIcon,
  SortedDescendingIcon,
  UnsortIcon,
} from "../../../components/DatagridIcon";

function NotificationList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const paramSearch = new URLSearchParams(location.search);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(paramSearch.get("page") || 1);
  const [results, setResults] = useState([]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getListData(page, PER_PAGE, setTotal, setResults);
    navigate(`?page=${page}&per_page=${PER_PAGE}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const onPaginationModelChange = (model) => {
    const newPage = model?.page;
    setPage(newPage + 1);
  };

  const columns = [
    {
      field: "title",
      headerName: t("admin.notification.list.title"),
      headerClassName: "custom",
      sortable: false,
      minWidth: 400,
      flex: 1,
      renderCell: (params) => {
        const value = params?.formattedValue;
        const id = params?.row?.id;
        return (
          <div
            role="presentation"
            onClick={() => navigate(`/admin/notification/${id}`)}
            className="flex items-center truncate cursor-pointer ml-3"
          >
            <div className="truncate">{value}</div>
          </div>
        );
      },
    },
    {
      field: "date",
      headerName: t("admin.notification.list.date"),
      headerClassName: "custom",
      sortable: false,
      width: 450,
      renderCell: (params) => {
        return (
          <div className="flex items-center truncate">
            <div className="truncate font-[700]">{params.value}</div>
          </div>
        );
      },
    },
    {
      field: "notification_group",
      headerName: t("admin.notification.list.notification_group"),
      headerClassName: "custom",
      sortable: false,
      width: 300,
      renderCell: (params) => {
        return (
          <div className="flex items-center truncate">
            <div className="truncate font-[700]">{params.value}</div>
          </div>
        );
      },
    },
  ];

  return (
    <LayoutDefault showFooter showHeader pin={8}>
      <div className="px-6 flex-1">
        <div className="flex flex-wrap justify-between items-center mt-5 gap-3">
          <p className="text-3xl font-bold">
            {t("admin.notification.list.total", { value: total })}
          </p>
          <Button
            variant="outlined"
            className="ml-3"
            color="inherit"
            sx={{ marginRight: 2 }}
            onClick={() => navigate(`/admin/notification/create`)}
          >
            <i className="fa-solid fa-plus mr-1" />
            {t("admin.notification.list.create_notification")}
          </Button>
        </div>
        <div className="w-full py-6">
          <DataGrid
            autoHeight
            rows={results}
            columns={columns}
            getRowClassName={() => "cursor-pointer"}
            onRowClick={(params, event) => {
              event.preventDefault();
              navigate(`/admin/notification/${params.id}`);
            }}
            rowCount={total}
            paginationMode="server"
            disableColumnMenu
            slots={{
              columnUnsortedIcon: UnsortIcon,
              columnSortedDescendingIcon: SortedDescendingIcon,
              columnSortedAscendingIcon: SortedAscendingIcon,
            }}
            paginationModel={{
              pageSize: PER_PAGE,
              page: page - 1,
            }}
            pageSizeOptions={[PER_PAGE]}
            onPaginationModelChange={(model) => {
              onPaginationModelChange(model);
            }}
            localeText={{
              noResultsOverlayLabel: t("common.message.no_data_display"),
              noRowsLabel: t("common.message.no_data_display"),
            }}
          />
        </div>
      </div>
    </LayoutDefault>
  );
}

export default NotificationList;
