export default {
  list: {
    total_item: "アカウント {{value}}名",
    account_name: "アカウント名",
    account_phone: "電話番号",
    account_last_login: "最終ログイン日",
    create_account: "アカウント作成",
    find_account_placeholder: "スタッフを検索",
  },
  form: {
    title: "アカウント情報",
    delete_notice: "削除したアカウントは復活出来ませんが、本当に削除しますか？",
    family_name: "姓",
    first_name: "名",
  },
};
