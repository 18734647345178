import { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import CommonService from "../../../../services/api/CommonService";
import { ICTL_API_ENDPOINT } from "../../../../services/constant";

export default function useCoreComponetDetail() {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const dateUrl = searchParams.get("date");
  const pageUrl = searchParams.get("page");
  const [item, setItem] = useState({});
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(pageUrl || 1);
  const [total, setTotal] = useState();
  const [totalPage, setTotalPage] = useState();
  const [perPage, setPerPage] = useState(20);
  const [startDate, setStartDate] = useState(
    dateUrl ? new Date(dateUrl) : new Date()
  );
  const params = useParams();
  // eslint-disable-next-line no-unused-vars
  const [paramUrl, setParamUrl] = useState({
    invoice_id: params.payId,
    category_id: params.categoryId,
  });

  const paramData = {
    page: 1,
    date: startDate,
    page_table: page,
  };
  const getDetail = useCallback(async () => {
    await CommonService.getModelList(
      `${ICTL_API_ENDPOINT.GET_LIST_PAY}/${paramUrl.invoice_id}/${paramUrl.category_id}`,
      paramData
    )
      .then((response) => {
        const result = response.data.data;
        setItem(result.result);
        setRows(result.row);
        setTotal(result.total);
        setTotalPage(result.totalPage);
        setPerPage(result.perPage);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, startDate, paramUrl]);

  useEffect(() => {
    navigate(`?page=${page}&date=${startDate}`);
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, startDate, paramUrl]);
  return [
    item,
    rows,
    page,
    total,
    totalPage,
    setPage,
    setStartDate,
    startDate,
    paramUrl,
    setPerPage,
    perPage,
  ];
}
