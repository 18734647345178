import { CircularProgress } from "@mui/material";
import React from "react";
import cx from "classnames";

function Loading({ open, className }) {
  return (
    <div
      className={cx(
        "fixed top-0 left-0 w-full h-full z-50 bg-black bg-opacity-50",
        className,
        { hidden: !open, block: !!open }
      )}
    >
      <div className="flex h-full justify-center items-center">
        <CircularProgress />
      </div>
    </div>
  );
}

export default Loading;
