export default {
  list: {
    customer_name: "Business Name",
    address: "Address",
    count_matching: "Number of Recruitment Cases",
    created: "Contract Date",
    contract_date: "Contract Date",
    find_customer: "Search for a Business",
    total_business: "Business {{value}} items",
    create_staff: "Create a Business",
    warning: "Unpaid Alert",
    invoice_number: "Invoice Number",
  },
  add: {
    cancel_create:
      "保存されていません。編集内容はキャンセルされますがよろしいですか？",
    confirm_create_mess:
      "新規登録の為、該当事業者の代表管理者のメールアドレスにアカウント発行メールを通知します。",
    lat_long_notice:
      "Place the cursor on the pin location of the business address on Google Maps and right-click. Once latitude and longitude are displayed, click again to copy it to the clipboard and paste it.",
  },
};