export default {
  title: "Business Operator Information",
  basic_info: "Basic Information",
  service_name: "Business Name",
  address: "Address",
  phone: "Phone Number",
  admin_basic_info: "Representative Administrator",
  admin_name: "Name",
  invoice_info: "Invoice",
  invoice_number: "Invoice Number",
  long_lat: "Longitude/Latitude",
};
