/* eslint-disable camelcase */
import {
  convertDynamicPath,
} from "app/utils";
import Path from "app/operator/route/Path";

export const DEFAULT_OPENING_HOUR = {
  all_day: true,
  opened_at: "00:00",
  closed_at: "23:59",
  checkin: "00:00",
  checkout: "23:59",
};

export const KEYWORD_EDIT_FORM_FIELDS = {
  addItemFieldName: "add_keyword",
  activeListFieldName: "keyword",
  allListFieldName: "all_keyword",
};

// facility detail navigation

export const FACILITY_DETAIL_COMMON_INFO_KEY_LIST = {
  IMAGE: 1,
  COMMON_INFO: 2,
  OPENING_TIME: 3,
  MENU_LAYOUT: 4,
  PAYMENT_METHOD: 5,
  SETTING_FEE: 6,
  AMENITY: 7,
};

export const FACILITY_DETAIL_SECTION_ID_LIST = (t) => ({
  [FACILITY_DETAIL_COMMON_INFO_KEY_LIST.IMAGE]: {
    navTitle: t("operator.advertisement.detail.image"),
    sectionId: "image",
  },
  [FACILITY_DETAIL_COMMON_INFO_KEY_LIST.COMMON_INFO]: {
    navTitle: t("operator.facility.detail.basic_info"),
    sectionId: "common-info",
    childSectionId: {
      visibility_and_status: "visibility-and-status",
    },
  },
  [FACILITY_DETAIL_COMMON_INFO_KEY_LIST.OPENING_TIME]: {
    navTitle: t("operator.facility.detail.open_hours"),
    sectionId: "opening-time",
  },
  [FACILITY_DETAIL_COMMON_INFO_KEY_LIST.MENU_LAYOUT]: {
    navTitle: t("operator.facility.detail.menu_layout"),
    sectionId: "menu-layout",
  },
  [FACILITY_DETAIL_COMMON_INFO_KEY_LIST.PAYMENT_METHOD]: {
    navTitle: t("operator.facility.detail.payment_method"),
    sectionId: "payment-method",
  },
  [FACILITY_DETAIL_COMMON_INFO_KEY_LIST.SETTING_FEE]: {
    navTitle: t("operator.facility.detail.setting_fee"),
    sectionId: "setting-fee",
  },
  [FACILITY_DETAIL_COMMON_INFO_KEY_LIST.AMENITY]: {
    navTitle: t("operator.inventory.detail.equip"),
    sectionId: "amenity",
  },
});

export const FACILITY_DETAIL_NAV_LIST = ({ t, facilityId }) => [
  {
    key: 1,
    title: t("operator.facility.detail.facility_detail"),
    url: convertDynamicPath(Path.facility_detail, { facilityId }),
    end: true,
  },
  {
    key: 2,
    title: t("operator.inventory.title"),
    url: convertDynamicPath(Path.inventory, { facilityId }),
  },

  {
    key: 3,
    title: t("common.commodity"),
    url: convertDynamicPath(Path.product, { facilityId }),
  },

  {
    key: 4,
    title: t("operator.product.list.tag"),
    url: convertDynamicPath(Path.tags_list, { facilityId }),
  },

  // {
  //   key: 5,
  //   title: t("operator.privacyPolicy.title"),
  //   url: convertDynamicPath(Path.privacy_policy, { facilityId }),
  // },

  // {
  //   key: 6,
  //   title: t("operator.termOfUse.title"),
  //   url: convertDynamicPath(Path.term_of_use, { facilityId }),
  // },

  {
    key: 7,
    title: t("operator.layout.header.embeddedCode"),
    url: Path.embed_code(facilityId),
  },
];

// amenities navigation

export const AMENITIES_SECTION_ID_PREFIX = "amenities_section";

export const AMENITIES_SECTION_ID_LIST = (amenitiesList) => {
  return amenitiesList?.map(({ title }, index) => ({
    navTitle: title,
    sectionId: `${AMENITIES_SECTION_ID_PREFIX}-${index}`,
  }));
};

// edit image navigation

export const EDIT_IMAGE_ID_LIST = (t) => [
  {
    navTitle: t("operator.advertisement.detail.cover_photo"),
    sectionId: "cover-photo",
  },
  {
    navTitle: t("operator.advertisement.detail.all_photos"),
    sectionId: "all-photo",
  },
];
