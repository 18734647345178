/* eslint-disable class-methods-use-this */
import CommonService from "../CommonService";
import client from "../client";

const baseUrl = "cms/facility-booking";
const staffUrl = "cms/business-operator";

const inventoryUrl = "cms/inventory";

class StaffService {
  getListFacilityByUser() {
    return CommonService.getModelList(`${baseUrl}/list-facility-by-user`);
  }

  getDetailFacility(facilityId) {
    return CommonService.getModelList(`${baseUrl}/by-facilityId`, {
      facility_id: facilityId,
    });
  }

  getOneNearestFutureBooking(facilityId, inventoryId) {
    return CommonService.getModelList(`${baseUrl}/get-nearest-future-booking`, {
      facility_id: facilityId,
      inventory_id: inventoryId,
    });
  }

  getListOrderByFacility(facilityId, params) {
    return CommonService.getModelList(
      `${baseUrl}/list-order/${facilityId}`,
      params
    );
  }

  updateOrderStatus(body) {
    return client.put(`${baseUrl}/update-order`, body);
  }

  // updateInventory(data) {
  //     return client.put(`${baseUrl}/update-inventory-checkin`, data)
  // }

  updateCheckInDeviceByStaff({ checkInDeviceId, inventoryName, description }) {
    return client.put(`${inventoryUrl}/check-in-device/update-by-staff`, {
      check_in_device_id: checkInDeviceId,
      inventory_name: inventoryName,
      description,
    });
  }

  updateInventoryToReady(facilityId, inventoryId) {
    return client.put(
      `${baseUrl}/update-inventory-to-ready?inventory_id=${inventoryId}&facility_id=${facilityId}`
    );
  }

  getInventoryById(id, availability) {
    return CommonService.getModelList(
      `${inventoryUrl}/detail/${id}?availability=${availability}`
    );
  }

  getListBookingByFacility(data) {
    return CommonService.getModelList(
      `cms/booking`,
      data.params,
      data.cancelToken
    );
  }

  getAvailableInventory(params) {
    return client.get(`${baseUrl}/product-available-by-date`, {
      params,
    });
  }

  getDetailFacilityInfo(facilityId) {
    return CommonService.getModelList(
      `${baseUrl}/facility-by-id/${facilityId}`
    );
  }

  getDetailProductInfo(productId) {
    return client.get(`/pms/product/get-one/${productId}`);
  }

  createNewBooking(data) {
    return client.post(`/cms/booking/create`, data);
  }

  getDetailBookingInfo(bookingId) {
    return client.get(`/cms/booking/${bookingId}`);
  }

  cancelBooking(statementId, inventoryId) {
    return client.put(`/cms/facility-booking/cancel-booking`, {
      statement_id: statementId,
      inventory_id: inventoryId,
    });
  }

  editBooking(bookingId, data) {
    return client.put(`/cms/booking/${bookingId}`, data);
  }

  deleteCheckInDevice(checkInDeviceId) {
    return client.delete(
      `${inventoryUrl}/check-in-device/${checkInDeviceId}/delete`
    );
  }

  findCustomerByName(facilityId, keyWord) {
    return CommonService.getModelList(
      `${baseUrl}/get-customer-booking/${facilityId}?key_word=${keyWord}`
    );
  }

  findProductByName(facilityId, keyWord, inventoryIds) {
    return client.post(`${baseUrl}/product-by-status`, {
      facility_id: facilityId,
      key_word: keyWord,
      inventory_ids: inventoryIds,
    });
  }

  createBookingInstantlyByStaff(body) {
    return CommonService.postModel(
      `${baseUrl}/create-booking-instantly-by-staff`,
      body
    );
  }

  inventoryCheckout(body) {
    return client.put(`${baseUrl}/user-check-out`, body);
  }

  inventoryCheckin(body) {
    return client.put(`${baseUrl}/user-check-in`, body);
  }

  getInventoryCheckingInInfo({ facilityId, inventoryId, statementId }) {
    return client.get(
      `${baseUrl}/get-checking-in-inventory-information/${facilityId}/${inventoryId}?statement_id=${statementId}`
    );
  }

  getBookingInformation(bookingId) {
    return client.get(`${baseUrl}/info-booking?reservation_id=${bookingId}`);
  }

  // updateBookingBreakDownOfUser({
  //   reservationId,
  //   statementId,
  //   breakdownOfUsers,
  // }) {
  //   return client.put(`${baseUrl}/update-breakdown-of-user`, {
  //     reservation_id: reservationId,
  //     statement_id: statementId,
  //     breakdown_of_users: breakdownOfUsers,
  //   });
  // }

  updateStatementDetailStatus(statementId, inventoryId, status) {
    return client.put(`${baseUrl}/update-statement-detail-status`, {
      statement_id: statementId,
      inventory_id: inventoryId,
      statement_detail_status: status,
    });
  }

  updateStatementDetailMemo(statementId, memo) {
    return client.put(`${baseUrl}/update-statement-detail-memo`, {
      statement_id: statementId,
      memo,
    });
  }

  updateSalesSlipStatusToCheck(statementId, inventoryId, bookingUserId) {
    return client.put(`${baseUrl}/update-sales-slip-status-to-check`, {
      statement_id: statementId,
      inventory_id: inventoryId,
      booking_user_id: bookingUserId,
    });
  }

  updateSalesSlipUsePoint(salesSlipId, usePoint) {
    return client.put(`${baseUrl}/update-sales-slip-use-point`, {
      sales_slip_id: salesSlipId,
      use_point: usePoint,
    });
  }

  forceCancelBooking(body) {
    return client.put(`${baseUrl}/cancel-booking`, body);
  }

  getInventoryReadyByProductId({
    facilityId,
    productId,
    inventoryId,
    statementId,
    includesNoLink
  }) {
    const url = `${baseUrl}/list-inventory-ready-by-product-id?facility_id=${facilityId}&statement_id=${statementId}&inventory_id=${inventoryId}&product_id=${productId}&includes_no_link=${includesNoLink ? 1 : 0}`;
    return client.get(url);
  }

  changeInventoryToUse({
    statementId,
    currentInventoryId,
    newInventoryId,
    statementStatusStaying,
  }) {
    return client.put(`${baseUrl}/change-inventory-by-statement-id`, {
      statement_id: statementId,
      current_inventory_id: currentInventoryId,
      new_inventory_id: newInventoryId,
      statement_status_staying: statementStatusStaying,
    });
  }

  updateSalesSlipExtraCost(salesSlipId, extraCost) {
    return client.put(`${baseUrl}/update-sales-slip-extra-cost`, {
      sales_slip_id: salesSlipId,
      extra_cost: extraCost,
    });
  }

  createStaff(data) {
    return client.post(`${staffUrl}/staff/create`, data);
  }

  gettStaffDetail(staffId) {
    return client.get(`${staffUrl}/staff/${staffId}`);
  }

  updateStaff(data) {
    return client.put(`${staffUrl}/staff/update`, data);
  }

  deleteStaff(data) {
    return client.put(`${staffUrl}/staff/inactive`, data);
  }

  getListStaff(data) {
    return client.post(`${staffUrl}/list-staff`, data);
  }

  exportBookingsCsv(params) {
    return client.get("cms/booking/export-csv", { params });
  }
}

export default new StaffService();
