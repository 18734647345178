export default {
  personal_info: {
    personal_info: "Personal Information",
    name_label: "Full Name (real name)",
    first_name: "Given name",
    last_name: "Surname",
    edit_btn: "Edit",
    dob_label: "Date of Birth",
    country_region: "Country",
    phone_label: "Phone Number",
    goverment_label: "Government Issued ID",
    address_label: "Address",
    postcode: "Postal Code",
    state_provine_country: "Prefecture",
    municipaliites: "City, Town, Village",
    address: "Street Address",
    apartment_name: "Apartment Name, Room Number, etc.",
    invalid_birthday: "Please enter a valid date of birth.",
    go_to_profile: "Go to profile",
    personal_info_notice:
      "Please enter your personal information and contact details",
    login_security: "Login & Security",
    login_security_notice: "Password change is required for account protection",
    global_setting: "Global Settings",
    global_setting_notice: "Set default language, currency, and timezone.",
    withdraw: "Withdraw",
  },
  login_security: {
    auth_form: "Login",
    password: "Password",
    password_last_update: "Last updated: ",
    current_password: "Current Password",
    new_password: "New Password",
    confirm_password: "Confirm Password",
    change_password: "Change Password",
    change_email: "Change Email Address",
    social_account: "Social Media Accounts",
    connected: "Connected",
    not_connect: "Not Connected",
    connecting: "Connect",
    disconnect: "Disconnect",
    linked_success: "Connection successful.",
    linked_fail: "Connection failed. Please try again.",
    unlink_success: "Successfully disconnected.",
    unlink_fail: "Failed to disconnect. Please try again.",
    email_exist: "Account already exists.",
    unlink_notice:
      "Would you like to disconnect the {{sns}} account linked to this account? ",
    logout_notice: "Updated. Logging out because login information has changed.",
  },
  widthdraw: {
    step_1: "Select a reason",
    step_2: "Confirm",
    step_3: "Done",
    step_1_title: "Reason for withdrawal",
    step_1_first_reason: "I have safety or privacy concerns.",
    step_1_second_reason: "I can't adhere to Buzz Traveler's terms of service.",
    step_1_third_reason: "It wasn't what I expected.",
    step_1_fourth_reason: "Other",
    step_2_title: "Are you sure you want to withdraw?",
    step_2_first_notice:
      "All the information in this account will be permanently deleted.",
    step_2_second_notice: "Deactivated accounts cannot be restored.",
    step_3_title: "Deactivation Process Completed",
    go_to_top: "Back to the TOP screen",
    widthdraw_success: "Withdrawal process has been completed successfully.",
    widthdraw_fail:
      "An error occurred during withdrawal process. Please try again after some time.",
  },
};
