/* eslint-disable class-methods-use-this */
import CommonService from '../CommonService';

const baseUrl = "cms/pay";

class PayService {
  updatePayStatus(invoiceNumber) {
    return CommonService.postModel(
      `${baseUrl}/update-status/${invoiceNumber}`
    );
  }

  exportCSV(params) {
    return CommonService.getModelList(`${baseUrl}/export`, params);
  }
}

export default new PayService();
