import React from "react";
import { useTranslation } from "react-i18next";

const ImageTutorial = require("app/assets/images/img1.jpg");

function Tab0(props) {
  const { currentTab, setCurrentTab } = props;
  const { t } = useTranslation();

  return (
    <div className="w-screen h-screen overflow-hidden p-0 m-0">
      <div className="w-full h-full bg-gradient-to-r from-[#41299d] to-[#d02278]">
        <div className="w-full h-full">
          <div className="w-full h-full flex flex-col md:flex-row overflow-y-auto">
            <div className="flex-1 flex-shrink-0 md:h-full md:w-1/2 md:bg-gradient-to-b md:from-[#41299d] md:to-[#d02278] min-h-[50vh]">
              <div className="w-full h-full">
                <img src={ImageTutorial} className="w-full h-full " alt="" />
              </div>
            </div>
            <div className="w-full h-auto relative md:h-full md:w-1/2 md:overflow-y-auto bg-black min-h-[50vh]">
              <div className="absolute -top-3 inset-0 h-3 rounded-t-xl bg-black md:hidden " />

              <div
                className="w-full flex"
                style={{
                  minHeight: "calc(100% - 80px)",
                }}
              >
                <div className="w-full bg-black px-6 md:px-10 flex items-center justify-center">
                  <div className="max-w-[480px] flex flex-col ">
                    <h1 className="w-full text-[32px] md:text-[48px] leading-10 md:leading-[60px] font-semibold text-white text-start md:text-center mb-4 md:mb-9">
                      {t("operator.host.tab0.become_a_host_in_10_easy_steps")}
                    </h1>

                    <h3 className="w-full text-[18px] leading-5 font-semibold text-white text-start md:text-center mx-0 lg:px-10">
                      <span>{t("operator.host.tab0.please_join_us")}</span>
                      <span>
                        {t("operator.host.tab0.we_will_support_each_step")}
                      </span>
                    </h3>
                  </div>
                </div>
              </div>

              <div className="sticky inset-x-0 bottom-0 h-20 bg-black flex flex-col">
                <div className="w-full h-0.5 bg-[#222]" />

                <div className="w-full flex-1 px-6 md:px-10">
                  <div className="w-full h-full flex items-center justify-center md:justify-end">
                    <button
                      type="button"
                      className="text-base font-semibold leading-5 p-2.5 rounded-md colorButtonCommon hover:bg-[#f7f7f7] "
                      onClick={() => setCurrentTab(currentTab + 1)}
                    >
                      {t("operator.host.common.start")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tab0;
