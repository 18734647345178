export default {
  common: {
    back: "戻る",
    next: "次へ",
    start: "はじめる",
    drag_to_change: "ピンの位置は正しいですか？",
  },

  tab0: {
    become_a_host_in_10_easy_steps: "簡単10ステップで事業者になろう",
    please_join_us: "ぜひご参加ください。",
    we_will_support_each_step: "Buzz Travelerが、各ステップをサポートいたします。",
  },

  tab1: {
    In_10_easy_steps: "簡単10ステップで",
    Become_host: "ホストになろう",
    Please_join_us_Mashup_does_each_step:
      "ぜびご参加ください。Buzz Travelerが、各ステップを",
    We_will_support_you: "にサポートいたします。",
    Get_started: "はじめる",

    select_facility_type_title: "掲載する施設の種類をお選びください。",
    hotel: "宿泊施設",
    restaurant: "飲食店",
  },
  tab2: {
    where_is_your_accommodation_located: "施設の所在地はどちらですか？",
    right_place: "ピンは正しい位置にありますか？",
    enter_your_address: "住所を入力してください",
    confirm_your_address: "住所を確認する",
    use_my_current_location: "現在地を使用",
    street: "番地",
    apartment_room_etc: "アパート名、部屋番号など（任意）",
    city: "市区町村",
    state_or_province: "都道府県",
    postal_code: "郵便番号",
    countr_or_region: "国",
    looks_fine: "次へ",
    AreThePinPositionsCorrect: "ピンの位置は正しいですか？",
  },
  tab3: {
    select_amenities_title:
      "施設でゲストに提供するアメニティ・設備を選択してください。",
  },
  amenities: {
    wifi: "WI-FI",
    tivi: "テレビ",
    kitchen: "キッチン",
    washing_machine: "洗濯機",
    free_parking_on_site: "敷地内無料駐車場",
    paid_parking_on_site: "敷地内有料駐車場",
  },
  keywords: {
    quiet: "テレビ",
    unique: "テレビ",
    family_friendly: "テレビ",
    stylish: "テレビ",
    central: "テレビ",
    spacious: "テレビ",
  },
  tab4: {
    upload_photo_tab_title: "施設の写真をアップロードしてください。",

    drag_your_photos_here: "写真をここにドラッグしてください",
    choose_at_least_5_photos: "写真は最低5枚掲載してください",
    upload_from_your_device: "端末からアップロード",

    how_does_this_look: "こちらでよろしいですか？",
    upload: "アップロード",
    drag_to_reorder: "写真はドラッグして並べ替えることができます。",
    cover_photo: "カバー写真",

    move_backward: "前方に移動",
    move_forward: "後方に移動",
    make_cover_photo: "カバー写真にする",
    delete: "削除",

    delete_this_photo: "こちらの写真を削除しますか？",
    delete_photo_warning: "削除すると、元に戻すことはできません。",
    cancel: "キャンセル",
    delete_photo: "削除する",

    max_file:
      "最大でアップロードできるのは10個の画像が、または8個の画像と2個の動画になります。",
    max_size_video: "動画サイズは許可サイズ50MBを超えました。",
    max_size_image: "イメージサイズは許可サイズ5MBを超えました。",
    min_size_image: "50KBより大きいサイズの画像をアップロードしてください。",
    file_type_error:
      "アップロード可能なファイルは以下のファイルです。PNG、JPEG、JPG、HEIC",
  },
  tab5: {
    facility_name_tab_title: "施設について紹介しましょう。",
    facility_name_field_title: "タイトルを作成",
    maximum_characters_allowed_err: "タイトルは50文字以内で入力してください。",
    title_field_new: "施設のタイトルを作成",
  },
  tab6: {
    facility_keyword_tab_title: "施設について紹介しましょう。",
    facility_keyword_field_title: "キーワードを２つまで選んで下さい",
    quiet: "静か",
    unique: "ユニーク",
    family_friendly: "ファミリー向け",
    stylish: "スタイリッシュ",
    central: "中心地",
    spacious: "広い",
    couples: "カップル向け",
  },
  tab7: {
    facility_description_tab_title: "施設について紹介しましょう。",
    facility_description_field_title: "説明文を作成",
    maximum_characters_allowed_err: "説明文は500文字以内で入力してください。",
  },
  tab8: {
    check_setting_tab_title: "プラン購入",
    save: "施設を保存",
    location: "リスティング所在地",
  },
  tab9: {
    welcome: "ようこそ",
    save: "保存",
  },
};
