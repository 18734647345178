import React from "react";
import { useTranslation } from "react-i18next";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ExpandMore } from "@mui/icons-material/";
import FormItem, { TYPE_INPUT } from "app/operator/components/FormItem";
import { EProjectPrMethod } from "../../../../constant";

function AdMethod({ values: formValues }) {
  const { t } = useTranslation();

  const prMethodLst = Object.values(EProjectPrMethod)?.map((type) => ({
    value: type,
    optionName: t(`operator.advertisement.detail.${type}`),
  }));

  return (
    <div className="mb-[16px]">
      <div className="mb-[8px] font-semibold">
        {t("operator.advertisement.detail.ad_method")}
        <span className="required" />
      </div>
      <Accordion
        sx={{
          borderBottom: "2px solid #e5e7eb",
          boxShadow: "none",
          ":before": {
            backgroundColor: "transparent",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className=" text-gray font-semibold">
            {t(`operator.advertisement.detail.${formValues.adMethod}`)}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <FormItem
            stringValue
            name="adMethod"
            containerClassName="mb-[20px]"
            typeInput={TYPE_INPUT.RADIO}
            componentProps={{
              defaultValue: formValues?.adMethod,
              optionsList: prMethodLst,
            }}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default AdMethod;
