// v-009-002
import React, { useCallback, useEffect, useRef, useState } from "react";
import ictlPath from "app/ictl/route/Path";
import MainContent from "app/components/MainContent";
import DetailListContent from "app/components/DetailListContent";
import LayoutDefault from "app/ictl/layouts/LayoutDefault";
import { Form, Formik, ErrorMessage } from "formik";
import FormItem, { TYPE_INPUT } from "app/operator/components/FormItem";
import { useTranslation } from "react-i18next";
import { checkValueChange, isNewFileUploading } from "app/utils";
import { useNavigate, useParams } from "react-router-dom";
import AppMessage from "app/components/AppMessage";
import Button, { BUTTON_STYLE_TYPE } from "app/operator/components/Button";
import {
  createBannerSchema,
  updateOrCreateBanner,
  formatGetBannerDetailData,
  BANNER_ACTION,
} from "app/utils/page/banner";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import {
  EResponseStatus,
  FORMAT_DATE,
  MAX_BANNER_QUANTITY,
  MAX_FILE_SIZE,
} from "app/constant";
import TextField from "@mui/material/TextField";
import SelectComponent from "app/ictl/components/SelectComponent";
import i18n from "app/langs";
import { dateMinute, dateSecond } from "app/utils/page/notification";
import SuccessDialog from "app/components/SuccessDialog";
import ErrorDialog from "app/components/ErrorDialog";
import CommonService from "app/services/api/CommonService";
import { ICTL_API_ENDPOINT } from "app/services/constant";
import { useDispatch } from "react-redux";
import { setActive } from "app/redux/blockUIReducer";
import { Checkbox } from "@mui/material";
import { MAX_LENGTH_100, MAX_LENGTH_1000 } from "app/utils/schema/operator";

const dropdownTime = dateMinute([]);
const dropdownSecond = dateSecond([]);

function BannerForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openCancel, setOpenCancel] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [message, setMessage] = useState("");
  const [action, setAction] = useState("");
  const locale = i18n.resolvedLanguage;
  const params = useParams();
  const { bannerId } = params;
  const formikRef = useRef(null);

  const initialBannerValues = {
    banner_title: "",
    banner_files: "",
    banner_media_url: "",
    banner_delivery_date: dayjs().format(FORMAT_DATE.YYYY_MM_DDTM),
    banner_end_date: dayjs().add(1, "days").format(FORMAT_DATE.YYYY_MM_DDTM),
    start_minute: dayjs().format(FORMAT_DATE.HH),
    end_minute: dayjs().add(1, "days").format(FORMAT_DATE.HH),
    start_second: dayjs().format(FORMAT_DATE.MM),
    end_second: dayjs().add(1, "days").format(FORMAT_DATE.MM),
    banner_address_url: "",
    banner_is_new_tab: "0",
  };

  const [formValues, setFormValues] = useState(initialBannerValues);

  // get banner detail
  useEffect(() => {
    if (formikRef && formikRef.current) {
      const { setValues } = formikRef.current;
      if (bannerId) {
        CommonService.getModelList(
          ICTL_API_ENDPOINT.GET_BANNER_DETAIL(bannerId)
        ).then((response) => {
          if (response.status === EResponseStatus.SUCCESS) {
            const items = response?.data?.data;
            setValues(formatGetBannerDetailData(items));
            setFormValues(formatGetBannerDetailData(items));
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteBanner = () => {
    dispatch(setActive(true));
    CommonService.postModel(ICTL_API_ENDPOINT.DELETE_BANNER(bannerId))
      .then((response) => {
        if (response.status === EResponseStatus.CREATED) {
          navigate(ictlPath.banner);
        }
      })
      .catch(() => {
        setOpenError(true);
        setMessage(t("common.message.delete_fail"));
      })
      .finally(() => dispatch(setActive(false)));
  };

  const onBannerCreateOrUpdate = (values) => {
    updateOrCreateBanner({
      bannerId,
      data: values,
      callbackSuccess: (type) => {
        setOpenSuccess(true);
        setMessage(
          type === BANNER_ACTION.STORE
            ? t("common.message.createSuccess")
            : t("common.message.update_success")
        );
      },
      callbackError: (type, error) => {
        setOpenError(true);
        const errorMessage = error?.response?.data?.message;
        if (type === BANNER_ACTION.UPDATE) {
          setMessage(t("common.message.update_fail"));
        } else if (errorMessage === "DATA_MAX_RECORD") {
          setMessage(
            t(`common.message.${errorMessage}`, {
              max: MAX_BANNER_QUANTITY,
            })
          );
        } else {
          setMessage(t("common.message.createFailed"));
        }
      },
    });
  };

  const validateBanner = (file, setFieldValue, setFieldError) => {
    // Allowing file type
    const allowedExtensions = /[^\\/.]+(?=(\.png|\.jpeg|\.jpg)$)/;

    const errorImageSizeMess = t("common.message.oversize_file", {
      size: MAX_FILE_SIZE / 1024 / 1024,
    });
    const errorFormatMess = t("common.message.wrong_format_file", {
      format: "PNG、JPEG、JPG",
    });

    const dimensionRequire = {
      width: 500,
      height: 250,
    };

    const errorDimensionsMess = t(
      "common.message.wrong_dimension",
      dimensionRequire
    );

    const image = new Image();
    const objectURL = URL.createObjectURL(file);
    image.onload = () => {
      if (
        image.width !== dimensionRequire.width ||
        image.height !== dimensionRequire.height
      ) {
        setFieldError("banner_files", errorDimensionsMess);
      } else if (!allowedExtensions.test(file.name.toLowerCase())) {
        setFieldError("banner_files", errorFormatMess);
      } else if (file.size > MAX_FILE_SIZE) {
        setFieldError("banner_files", errorImageSizeMess);
      } else {
        setFieldValue("banner_files", file);
      }
      URL.revokeObjectURL(objectURL);
    };
    image.src = objectURL;
  };

  const onBannerImageChange = (event, setFieldValue, setFieldError) => {
    const files = event.target.files || event.dataTransfer.files;
    if (!files.length) return;
    validateBanner(files[0], setFieldValue, setFieldError);
  };

  const onConfirm = () => {
    if (action === BANNER_ACTION.CANCEL) {
      navigate(ictlPath.banner);
    }
    if (action === BANNER_ACTION.DELETE) {
      deleteBanner();
    }
  };

  const onBannerNewTabChange = (event, setFieldValue) => {
    if (event.target.checked) {
      setFieldValue("banner_is_new_tab", "1");
    } else {
      setFieldValue("banner_is_new_tab", "0");
    }
  };

  const renderRightContent = useCallback(() => {
    return (
      <div className="banner-form text-[14px]">
        <Formik
          innerRef={formikRef}
          initialValues={initialBannerValues}
          validationSchema={createBannerSchema()}
          onSubmit={onBannerCreateOrUpdate}
        >
          {({
            values,
            handleSubmit,
            setFieldValue,
            setFieldError,
            handleBlur,
          }) => {
            const isValuesHasChanged = checkValueChange(
              formValues,
              values,
              false
            );
            return (
              <Form>
                {/* delete banner */}
                {bannerId && (
                  <div className="flex justify-end mb-[16px]">
                    <button
                      type="button"
                      onClick={() => {
                        setOpenCancel(true);
                        setAction(BANNER_ACTION.DELETE);
                        setMessage(t("admin.banner.form.delete_notice"));
                      }}
                      className="font-bold text-[#ff0000] text-base underline"
                    >
                      {t("common.action.delete_2")}
                    </button>
                  </div>
                )}
                <FormItem
                  required
                  typeInput={TYPE_INPUT.TEXT}
                  name="banner_title"
                  label={t("admin.banner.form.title")}
                  labelClassName="font-semibold mb-[12px]"
                  placeholder={t("common.message.no_input_yet")}
                  containerClassName="mb-[24px]"
                  inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
                  errorsClassName="mt-1"
                  showCharCount
                  maxLength={MAX_LENGTH_100}
                  defaultLength={values.banner_title?.length}
                />

                <FormItem
                  required
                  typeInput={TYPE_INPUT.TEXT}
                  name="banner_address_url"
                  label={t("admin.banner.form.address_url")}
                  labelClassName="font-semibold mb-[12px]"
                  placeholder={t("common.message.no_input_yet")}
                  containerClassName="mb-[24px]"
                  inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
                  errorsClassName="mt-1"
                  showCharCount
                  maxLength={MAX_LENGTH_1000}
                  defaultLength={values.banner_address_url?.length}
                />

                <div className="mb-6 flex items-center gap-2">
                  <div className="font-bold">
                    {t("admin.banner.form.open_new_tab")}
                  </div>
                  <Checkbox
                    name="banner_is_new_tab"
                    checked={values?.banner_is_new_tab === "1"}
                    onChange={(e) => onBannerNewTabChange(e, setFieldValue)}
                  />
                </div>

                <div className="banner-upload mb-6">
                  <div className="font-semibold mb-[12px]">
                    <span className="text-[14px]">
                      {t("admin.banner.form.files")}
                    </span>
                    <span className="text-red pl-1">{t("validate_mark")}</span>
                  </div>

                  <div className="flex flex-col gap-1">
                    <div className="flex flex-col xl:flex-row gap-5 xl:gap-10 items-stretch">
                      <div className="w-[216px]">
                        <label
                          htmlFor="banner_files"
                          className="block w-full text-base font-bold text-center flex-shrink-0 border-border border rounded-[8px] px-3 py-1 cursor-pointer"
                        >
                          <span>{t("admin.banner.form.upload")}</span>
                          <input
                            className="w-0 h-0 overflow-hidden"
                            onClick={(e) => {
                              e.target.value = null;
                            }}
                            onChange={(e) => {
                              onBannerImageChange(
                                e,
                                setFieldValue,
                                setFieldError
                              );
                            }}
                            onBlur={handleBlur}
                            id="banner_files"
                            type="file"
                            name="banner_files"
                          />
                        </label>
                      </div>

                      <div className="border-border border rounded-[8px] max-w-full w-[500px] overflow-hidden">
                        <div className="relative w-full pt-[50%]">
                          {values?.banner_files ? (
                            <img
                              src={
                                isNewFileUploading(values?.banner_files)
                                  ? URL.createObjectURL(values?.banner_files)
                                  : values?.banner_files
                              }
                              alt=""
                              loading="lazy"
                              className="w-full h-full object-cover absolute top-0 left-0"
                            />
                          ) : (
                            <span className="font-bold text-base absolute top-0 left-0 w-full h-full flex items-center justify-center">
                              {t("admin.banner.form.preview")}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <ErrorMessage
                      name="banner_files"
                      component="div"
                      className="errors-msg"
                    />
                  </div>
                </div>

                <div className="banner-period">
                  <div className="font-semibold mb-[12px]">
                    <span className="text-[14px]">
                      {t("admin.banner.form.period")}
                    </span>
                    <span className="text-red pl-1">{t("validate_mark")}</span>
                  </div>

                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={locale}
                  >
                    <div className="flex flex-col xl:flex-row xl:items-baseline gap-2 xl:gap-4">
                      <div className="flex flex-col gap-1">
                        <div className="flex items-center gap-4">
                          <DesktopDatePicker
                            inputFormat={FORMAT_DATE.YYYY_MM_DD}
                            value={values?.banner_delivery_date}
                            onChange={(value) => {
                              if (value) {
                                const date = dayjs(value).format(
                                  `YYYY-MM-DD[T]${values?.start_minute}:${values?.start_second}`
                                );
                                setFieldValue("banner_delivery_date", date);
                              } else {
                                setFieldValue("banner_delivery_date", "");
                              }
                            }}
                            className="date-picker xl:flex-1"
                            renderInput={(res) => (
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              <TextField
                                size="small"
                                sx={{ width: "50%" }}
                                {...res}
                                name="banner_delivery_date"
                                onBlur={handleBlur}
                              />
                            )}
                            componentsProps={{
                              actionBar: {
                                actions: ["today"],
                                className: "!justify-center",
                              },
                            }}
                          />
                          <SelectComponent
                            lstOption={dropdownTime}
                            name="start_minute"
                            handleChange={(e) => {
                              setFieldValue("start_minute", e);
                              const date = dayjs(
                                values?.banner_delivery_date
                              ).format(
                                `YYYY-MM-DD[T]${e}:${values?.start_second}`
                              );
                              setFieldValue("banner_delivery_date", date);
                            }}
                            selectClass="!w-[72px] !max-w-full !rounded-xl h-10"
                            optionSelected={values?.start_minute}
                          />
                          <SelectComponent
                            lstOption={dropdownSecond}
                            name="start_second"
                            handleChange={(e) => {
                              setFieldValue("start_second", e);
                              const date = dayjs(
                                values?.banner_delivery_date
                              ).format(
                                `YYYY-MM-DD[T]${values?.start_minute}:${e}`
                              );
                              setFieldValue("banner_delivery_date", date);
                            }}
                            selectClass="!w-[72px] !max-w-full !rounded-xl h-10"
                            optionSelected={values?.start_second}
                          />
                        </div>
                        <ErrorMessage
                          name="banner_delivery_date"
                          component="div"
                          className="errors-msg"
                        />
                      </div>

                      <p className="text-center">～</p>

                      <div className="flex flex-col gap-1">
                        <div className="flex items-center gap-4">
                          <DesktopDatePicker
                            inputFormat={FORMAT_DATE.YYYY_MM_DD}
                            className="date-picker xl:flex-1"
                            value={values?.banner_end_date}
                            onChange={(value) => {
                              if (value) {
                                const date = dayjs(value).format(
                                  `YYYY-MM-DD[T]${values?.end_minute}:${values?.end_second}`
                                );
                                setFieldValue("banner_end_date", date);
                              } else {
                                setFieldValue("banner_end_date", "");
                              }
                            }}
                            renderInput={(res) => (
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              <TextField
                                {...res}
                                size="small"
                                sx={{ width: "50%" }}
                                name="banner_end_date"
                                onBlur={handleBlur}
                              />
                            )}
                            componentsProps={{
                              actionBar: {
                                actions: ["today"],
                                className: "!justify-center",
                              },
                            }}
                          />
                          <SelectComponent
                            lstOption={dropdownTime}
                            name="end_minute"
                            handleChange={(e) => {
                              setFieldValue("end_minute", e);
                              const date = dayjs(
                                values?.banner_end_date
                              ).format(
                                `YYYY-MM-DD[T]${e}:${values?.end_second}`
                              );
                              setFieldValue("banner_end_date", date);
                            }}
                            selectClass="!w-[72px] !max-w-full !rounded-xl h-10"
                            optionSelected={values?.end_minute}
                          />
                          <SelectComponent
                            lstOption={dropdownSecond}
                            name="end_second"
                            handleChange={(e) => {
                              setFieldValue("end_second", e);
                              const date = dayjs(
                                values?.banner_end_date
                              ).format(
                                `YYYY-MM-DD[T]${values?.end_minute}:${e}`
                              );
                              setFieldValue("banner_end_date", date);
                            }}
                            selectClass="!w-[72px] !max-w-full !rounded-xl h-10"
                            optionSelected={values?.end_second}
                          />
                        </div>
                        <ErrorMessage
                          name="banner_end_date"
                          component="div"
                          className="errors-msg"
                        />
                      </div>
                    </div>
                  </LocalizationProvider>
                </div>

                {/* Seperate line */}
                {/* <div className="border-b-[2px] w-full border-b-gray-300 mt-10 mb-6" /> */}

                <div className="flex justify-between mt-12">
                  <button
                    type="button"
                    className="font-bold underline"
                    onClick={() => {
                      if (!isValuesHasChanged) {
                        navigate(ictlPath.banner);
                      }
                      setOpenCancel(true);
                      setMessage(t("common.message.cancel_create"));
                      setAction(BANNER_ACTION.CANCEL);
                    }}
                  >
                    {t("common.action.cancel")}
                  </button>
                  <Button
                    onClick={handleSubmit}
                    styleType={BUTTON_STYLE_TYPE.PRIMARY}
                    disabled={bannerId && !isValuesHasChanged}
                  >
                    {t("common.action.save")}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  return (
    <LayoutDefault showFooter showHeader pin={9}>
      <MainContent showBackButton path={ictlPath.banner}>
        <DetailListContent rightContent={renderRightContent()} />
      </MainContent>
      <SuccessDialog
        message={message}
        open={openSuccess}
        onClose={() => navigate(ictlPath.banner)}
        onOK={() => navigate(ictlPath.banner)}
      />
      <ErrorDialog
        message={message}
        open={openError}
        onClose={() => setOpenError(false)}
        onOK={() => setOpenError(false)}
      />
      <AppMessage
        open={openCancel}
        setOpen={setOpenCancel}
        onSubmit={(e) => onConfirm(e)}
      >
        {message}
      </AppMessage>
    </LayoutDefault>
  );
}

export default BannerForm;
