// m-007-002 create
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { createAdProject } from "app/utils/page/project";
import { Form, Formik } from "formik";
import Button, { BUTTON_STYLE_TYPE } from "app/operator/components/Button";
import {
  createProjectSchema,
  MAX_LENGTH_100,
  MAX_LENGTH_200,
  MAX_LENGTH_2000,
  MAX_LENGTH_5000,
} from "app/utils/schema/operator";
import AppMessage from "app/components/AppMessage";
import {
  EProjectStatus,
  EProjectPrMethod,
  EProjectReward,
  GENDER,
  onlyNumberRegex,
  FORMAT_DATE,
} from "app/constant";
import { checkValueChange } from "app/utils";
import DetailListContent from "app/components/DetailListContent";
import MainContent from "app/components/MainContent";
import SuccessDialog from "app/components/SuccessDialog";
import ErrorDialog from "app/components/ErrorDialog";
import DetailCollapse from "app/components/DetailCollapse";
import moment from "moment";
import ImageForm from "../../../components/ImageCreate";
import LayoutDefault from "../../../layouts/LayoutDefault";
import Path from "../../../route/Path";
import FormItem, { TYPE_INPUT } from "../../../components/FormItem";
import RewardTypeForm from "./RewardTypeForm";
import AdMethod from "./AdMethod";
import GenderForm from "./GenderForm";
import AdMedia from "./AdMedia";
import DateForm from "../DateForm";
import AgeGroupForm from "./AgeGroupForm";
import StatusForm from "./StatusForm";


function AdvertisementCreate() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [openCancel, setOpenCancel] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [filesValues, setFilesValues] = useState({
    files: [],
    coverImg: { url: "", id: "" },
  });
  const [filesErrorsMsg, setFilesErrorsMsg] = useState(false);

  const initialValues = {
    projectTitle: "",
    projectDescription: "",
    visibility_and_status: EProjectStatus.PRIVATE,
    projectFacilityId: "",
    projectFacilityName: "",
    projectFacilityType: "",
    other_facility_information: null,
    projectRewardType: EProjectReward.FIXED_PRICE,
    projectReward: "",
    desiredCondition: "",
    adMethod: EProjectPrMethod.IMAGE,
    tagInclude: "",
    targetUrl: "",
    gender: GENDER.OTHER,
    adMedia: "",
    minFollowers: "",
    targetUsername: "",
    textInclude: "",
    recuitmentDate: {
      startDate: moment().tz("Asia/Tokyo").format(FORMAT_DATE.YYYY_MM_DDT),
      endDate: moment().tz("Asia/Tokyo").format(FORMAT_DATE.YYYY_MM_DDTE),
      unspecified_date: true,
    },
    ageGroupStart: "",
    ageGroupEnd: "",
    offerPrice: "",
    projectTitleEN: "",
    projectTitleCN: "",
    projectDescriptionEN: "",
    projectDescriptionCN: "",
    desiredConditionEN: "",
    desiredConditionCN: "",
    textIncludeEN: "",
    textIncludeCN: "",
  };

  const onSubmit = (data) => {
    const payload = {
      ...filesValues,
      ...data,
    };
    if (!filesErrorsMsg) {
      createAdProject({
        data: payload,
        callbackSuccess: () => {
          setOpenSuccess(true);
          setMessage(t("common.message.createSuccess"));
        },
        callbackError: () => {
          setOpenError(true);
          setMessage(t("common.message.createFailed"));
        },
      });
    }
  };

  const renderRightContent = () => {
    return (
      <>
        <ImageForm
          limit={5}
          values={filesValues}
          setFieldValue={(field, values) => {
            if (field === "files") {
              setFilesValues((prev) => ({
                ...prev,
                files: values,
              }));
            }
            if (field === "coverImg") {
              setFilesValues((prev) => ({
                ...prev,
                coverImg: values,
              }));
            }
          }}
          setError={setFilesErrorsMsg}
          error={filesErrorsMsg}
        />

        <div className="facility-info mb-10 text-[14px]">
          <Formik
            initialValues={initialValues}
            validationSchema={createProjectSchema()}
            onSubmit={onSubmit}
          >
            {({ values, setFieldValue, handleSubmit, errors }) => {
              return (
                <Form>
                  <p className="font-bold text-[18px] mb-[16px]">
                    {t("operator.advertisement.detail.adsGeneralInfo")}
                  </p>
                  <FormItem
                    required
                    showCharCount
                    maxLength={MAX_LENGTH_100}
                    typeInput={TYPE_INPUT.TEXT}
                    defaultLength={values.projectTitle?.length}
                    name="projectTitle"
                    label={t("operator.advertisement.detail.project_title")}
                    description={t(
                      "operator.advertisement.detail.title_description"
                    )}
                    descriptionClassName="text-gray mb-2"
                    labelClassName="font-semibold mb-[8px]"
                    placeholder={t("common.message.no_input_yet")}
                    inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
                    showDetailCollapse
                  />
                  <DetailCollapse
                    collapseLabel={t(
                      "operator.advertisement.detail.collapse_en_title"
                    )}
                    containerClassName="mb-5"
                  >
                    <FormItem
                      showCharCount
                      maxLength={MAX_LENGTH_100}
                      typeInput={TYPE_INPUT.TEXT}
                      defaultLength={values.projectTitleEN?.length}
                      name="projectTitleEN"
                      label={t(
                        "operator.advertisement.detail.collapse_en_label"
                      )}
                      description={t(
                        "operator.advertisement.detail.title_description"
                      )}
                      descriptionClassName="text-gray mb-2"
                      containerClassName="p-[9px]"
                      labelClassName="font-semibold mb-[8px]"
                      placeholder={t("common.message.no_input_yet")}
                      inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
                    />
                  </DetailCollapse>
                  <DetailCollapse
                    collapseLabel={t(
                      "operator.advertisement.detail.collapse_cn_title"
                    )}
                    containerClassName="mb-5"
                  >
                    <FormItem
                      showCharCount
                      maxLength={MAX_LENGTH_100}
                      typeInput={TYPE_INPUT.TEXT}
                      defaultLength={values.projectTitleCN?.length}
                      name="projectTitleCN"
                      label={t(
                        "operator.advertisement.detail.collapse_cn_label"
                      )}
                      description={t(
                        "operator.advertisement.detail.title_description"
                      )}
                      descriptionClassName="text-gray mb-2"
                      containerClassName="p-[9px]"
                      labelClassName="font-semibold mb-[8px]"
                      placeholder={t("common.message.no_input_yet")}
                      inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
                    />
                  </DetailCollapse>

                  <FormItem
                    required
                    name="projectDescription"
                    showCharCount
                    maxLength={MAX_LENGTH_5000}
                    typeInput={TYPE_INPUT.TEXT_AREA}
                    textAreaClassName="resize-none h-[200px]"
                    defaultLength={values.projectDescription?.length}
                    label={t(
                      "operator.advertisement.detail.project_description"
                    )}
                    labelClassName="font-semibold mb-[8px]"
                    placeholder={t("common.message.no_input_yet")}
                    inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
                  />
                  <DetailCollapse
                    collapseLabel={t(
                      "operator.advertisement.detail.collapse_en_title"
                    )}
                    containerClassName="mb-5"
                  >
                    <FormItem
                      name="projectDescriptionEN"
                      showCharCount
                      maxLength={MAX_LENGTH_5000}
                      typeInput={TYPE_INPUT.TEXT_AREA}
                      textAreaClassName="resize-none h-[200px]"
                      defaultLength={values.projectDescriptionEN?.length}
                      label={t(
                        "operator.advertisement.detail.en_descrip_label"
                      )}
                      labelClassName="font-semibold mb-[8px]"
                      placeholder={t("common.message.no_input_yet")}
                      inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
                    />
                  </DetailCollapse>
                  <DetailCollapse
                    collapseLabel={t(
                      "operator.advertisement.detail.collapse_cn_title"
                    )}
                    containerClassName="mb-5"
                  >
                    <FormItem
                      name="projectDescriptionCN"
                      showCharCount
                      maxLength={MAX_LENGTH_5000}
                      typeInput={TYPE_INPUT.TEXT_AREA}
                      textAreaClassName="resize-none h-[200px]"
                      defaultLength={values.projectDescriptionCN?.length}
                      label={t(
                        "operator.advertisement.detail.cn_descrip_label"
                      )}
                      labelClassName="font-semibold mb-[8px]"
                      placeholder={t("common.message.no_input_yet")}
                      inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
                    />
                  </DetailCollapse>

                  <StatusForm values={values} />
                  <RewardTypeForm
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                  <FormItem
                    required
                    disabled={
                      values?.projectRewardType !== EProjectReward.FIXED_PRICE
                    }
                    name="projectReward"
                    typeInput={TYPE_INPUT.NUMBER}
                    showCharCount
                    regex={onlyNumberRegex}
                    maxLength={8}
                    defaultLength={values.projectReward?.length}
                    label={t("operator.advertisement.detail.reward")}
                    labelClassName="font-semibold mb-[8px]"
                    placeholder={t("common.message.no_input_yet")}
                    containerClassName="mb-[20px]"
                    inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
                  />
                  <FormItem
                    required
                    name="desiredCondition"
                    showCharCount
                    maxLength={MAX_LENGTH_200}
                    typeInput={TYPE_INPUT.TEXT_AREA}
                    textAreaClassName="resize-none h-[200px]"
                    defaultLength={values.desiredCondition?.length}
                    label={t("operator.advertisement.detail.desired_condition")}
                    labelClassName="font-semibold mb-[8px]"
                    placeholder={t("common.message.no_input_yet")}
                    containerClassName="mb-[20px]"
                    inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
                  />
                  <DetailCollapse
                    collapseLabel={t(
                      "operator.advertisement.detail.collapse_en_title"
                    )}
                    containerClassName="mb-5"
                  >
                    <FormItem
                      name="desiredConditionEN"
                      showCharCount
                      maxLength={MAX_LENGTH_200}
                      typeInput={TYPE_INPUT.TEXT_AREA}
                      textAreaClassName="resize-none h-[200px]"
                      defaultLength={values.desiredConditionEN?.length}
                      label={t(
                        "operator.advertisement.detail.en_condition_label"
                      )}
                      labelClassName="font-semibold mb-[8px]"
                      placeholder={t("common.message.no_input_yet")}
                      containerClassName="mb-[20px]"
                      inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
                    />
                  </DetailCollapse>
                  <DetailCollapse
                    collapseLabel={t(
                      "operator.advertisement.detail.collapse_cn_title"
                    )}
                    containerClassName="mb-5"
                  >
                    <FormItem
                      name="desiredConditionCN"
                      showCharCount
                      maxLength={MAX_LENGTH_200}
                      typeInput={TYPE_INPUT.TEXT_AREA}
                      textAreaClassName="resize-none h-[200px]"
                      defaultLength={values.desiredConditionCN?.length}
                      label={t(
                        "operator.advertisement.detail.cn_condition_label"
                      )}
                      labelClassName="font-semibold mb-[8px]"
                      placeholder={t("common.message.no_input_yet")}
                      containerClassName="mb-[20px]"
                      inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
                    />
                  </DetailCollapse>

                  <DateForm
                    values={values}
                    setFieldValue={setFieldValue}
                    required
                    showCheckbox
                  />
                  <AdMethod values={values} />
                  <FormItem
                    name="tagInclude"
                    showCharCount
                    maxLength={MAX_LENGTH_100}
                    typeInput={TYPE_INPUT.TEXT}
                    defaultLength={values.tagInclude?.length}
                    label={t("operator.advertisement.detail.tagInclude")}
                    labelClassName="font-semibold mb-[8px]"
                    placeholder={t("common.message.no_input_yet")}
                    containerClassName="mb-[20px]"
                    inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
                  />
                  <FormItem
                    name="targetUrl"
                    showCharCount
                    maxLength={MAX_LENGTH_2000}
                    typeInput={TYPE_INPUT.TEXT_AREA}
                    defaultLength={values.targetUrl?.length}
                    textAreaClassName="resize-none h-[200px]"
                    label={t("operator.advertisement.detail.targetUrl")}
                    labelClassName="font-semibold mb-[8px]"
                    placeholder={t("common.message.no_input_yet")}
                    containerClassName="mb-[20px]"
                    inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
                  />
                  <GenderForm values={values} />
                  <AdMedia values={values} />
                  <FormItem
                    name="minFollowers"
                    typeInput={TYPE_INPUT.NUMBER}
                    showCharCount
                    maxLength={8}
                    regex={onlyNumberRegex}
                    defaultLength={values.minFollowers?.length}
                    label={t("operator.advertisement.detail.min_followers")}
                    labelClassName="font-semibold mb-[8px]"
                    placeholder={t("common.message.no_input_yet")}
                    containerClassName="mb-[20px]"
                    inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
                  />
                  <FormItem
                    name="targetUsername"
                    showCharCount
                    maxLength={MAX_LENGTH_100}
                    typeInput={TYPE_INPUT.TEXT}
                    defaultLength={values.targetUsername?.length}
                    label={t("operator.advertisement.detail.targetUsername")}
                    labelClassName="font-semibold mb-[8px]"
                    placeholder={t("common.message.no_input_yet")}
                    containerClassName="mb-[20px]"
                    inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
                  />
                  <FormItem
                    name="textInclude"
                    showCharCount
                    maxLength={MAX_LENGTH_5000}
                    typeInput={TYPE_INPUT.TEXT_AREA}
                    defaultLength={values.textInclude?.length}
                    textAreaClassName="resize-none h-[200px]"
                    label={t("operator.advertisement.detail.textInclude")}
                    labelClassName="font-semibold mb-[8px]"
                    placeholder={t("common.message.no_input_yet")}
                    containerClassName="mb-[20px]"
                    inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
                  />
                  <DetailCollapse
                    collapseLabel={t(
                      "operator.advertisement.detail.collapse_en_title"
                    )}
                    containerClassName="mb-5"
                  >
                    <FormItem
                      name="textIncludeEN"
                      showCharCount
                      maxLength={MAX_LENGTH_5000}
                      typeInput={TYPE_INPUT.TEXT_AREA}
                      textAreaClassName="resize-none h-[200px]"
                      defaultLength={values.textIncludeEN?.length}
                      label={t(
                        "operator.advertisement.detail.en_include_label"
                      )}
                      labelClassName="font-semibold mb-[8px]"
                      placeholder={t("common.message.no_input_yet")}
                      containerClassName="mb-[20px]"
                      inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
                    />
                  </DetailCollapse>
                  <DetailCollapse
                    collapseLabel={t(
                      "operator.advertisement.detail.collapse_cn_title"
                    )}
                    containerClassName="mb-5"
                  >
                    <FormItem
                      name="textIncludeCN"
                      showCharCount
                      maxLength={MAX_LENGTH_5000}
                      typeInput={TYPE_INPUT.TEXT_AREA}
                      textAreaClassName="resize-none h-[200px]"
                      defaultLength={values.textIncludeCN?.length}
                      label={t(
                        "operator.advertisement.detail.cn_include_label"
                      )}
                      labelClassName="font-semibold mb-[8px]"
                      placeholder={t("common.message.no_input_yet")}
                      containerClassName="mb-[20px]"
                      inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
                    />
                  </DetailCollapse>

                  <AgeGroupForm values={values} errors={errors} />
                  <FormItem
                    disabled={values?.projectRewardType !== EProjectReward.FREE}
                    name="offerPrice"
                    typeInput={TYPE_INPUT.NUMBER}
                    showCharCount
                    regex={onlyNumberRegex}
                    maxLength={8}
                    defaultLength={values.offerPrice?.length}
                    description={t(
                      "operator.advertisement.detail.offer_price_description"
                    )}
                    descriptionClassName="text-gray mb-2"
                    label={t("operator.advertisement.detail.offer_price")}
                    labelClassName="font-semibold mb-[8px]"
                    placeholder={t("common.message.no_input_yet")}
                    containerClassName="mb-[20px]"
                    inputClassName="border-border border rounded-[8px] overflow-hidden w-full"
                  />
                  <div className="flex justify-between">
                    <button
                      type="button"
                      className="font-bold underline"
                      onClick={() => {
                        const isValuesHasChanged = checkValueChange(
                          initialValues,
                          values,
                          false
                        );
                        if (!isValuesHasChanged) {
                          navigate(Path.ad_project);
                        }
                        setOpenCancel(true);
                        setMessage(t("common.message.cancel_create"));
                      }}
                    >
                      {t("common.action.cancel")}
                    </button>
                    <Button
                      onClick={() => {
                        if (!filesValues?.files?.length) {
                          setFilesErrorsMsg(true);
                        }
                        handleSubmit();
                      }}
                      styleType={BUTTON_STYLE_TYPE.PRIMARY}
                    >
                      {t("common.action.save")}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </>
    );
  };

  return (
    <LayoutDefault showFooter showHeader>
      <SuccessDialog
        message={message}
        open={openSuccess}
        onClose={() => navigate(Path.ad_project)}
        onOK={() => navigate(Path.ad_project)}
      />
      <ErrorDialog
        message={message}
        open={openError}
        onClose={() => setOpenError(false)}
        onOK={() => setOpenError(false)}
      />
      <AppMessage
        open={openCancel}
        setOpen={setOpenCancel}
        onSubmit={() => navigate(Path.ad_project)}
      >
        {message}
      </AppMessage>
      <MainContent showBackButton path={Path.ad_project}>
        <DetailListContent
          rightContent={renderRightContent()}
          title={t("operator.advertisement.detail.leftTitle")}
        />
      </MainContent>
    </LayoutDefault>
  );
}

export default AdvertisementCreate;
