/* eslint-disable jsx-a11y/label-has-associated-control */
import { Breadcrumbs } from "@mui/material";
import React, { useState } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { EResponseStatus } from "app/constant";
import { setActive } from "app/redux/blockUIReducer";
import MainContent from "app/components/MainContent";
import { useTranslation } from "react-i18next";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import UserService from "../../../../services/api/UserService";
import LayoutDefault from "../../../layouts/LayoutDefault";
import Path from "../../../route/Path";

const pageLogo = require("app/assets/images/buzz-logo.png");

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.palette.mode === "light" ? "#008489" : "#308fe8",
  },
}));

function Withdraw() {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [withdrawMessage, setWidthdrawMessage] = useState("");
  const { user_id: userId } = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);

  const validationSchema = Yup.object().shape({
    reason: Yup.string().required(),
  });

  const initialValues = {
    reason: "",
    selected: "",
  };

  const breadcrumbs = [
    <div
      role="presentation"
      key={1}
      type="button"
      className={`text-[#222] ${step !== 1 ? "" : "font-bold"} ${
        step === 2 ? "cursor-pointer" : ""
      }`}
      onClick={() => {
        if (step === 2) {
          setStep(1);
        }
      }}
    >
      {`1: ${t("operator.accountSetting.widthdraw.step_1")}`}
    </div>,
    <div
      key={2}
      type="button"
      className={`text-[#222] ${step !== 2 ? " " : "font-bold"}`}
    >
      {`2: ${t("operator.accountSetting.widthdraw.step_2")}`}
    </div>,
    <div
      key={3}
      type="button"
      className={`text-[#222] ${step !== 3 ? " " : "font-bold"}`}
    >
      {`3: ${t("operator.accountSetting.widthdraw.step_3")}`}
    </div>,
  ];

  const withdrawUser = (values) => {
    dispatch(setActive(true));
    const body = {
      user_id: userId,
      comment: values.reason,
    };
    UserService.withdrawUser(body)
      .then((res) => {
        if (res.status === EResponseStatus.SUCCESS) {
          setWidthdrawMessage(
            t("operator.accountSetting.widthdraw.widthdraw_success")
          );
          setSuccess(true);
        }
      })
      .catch(() =>
        setWidthdrawMessage(
          t("operator.accountSetting.widthdraw.widthdraw_fail")
        )
      )
      .finally(() => {
        dispatch(setActive(false));
        setStep(3);
      });
  };

  return (
    <LayoutDefault showFooter>
      <header className="md:block fixed top-0 left-0 right-0 z-[1031] bg-white">
        <div className="flex flex-col gap-1 px-5">
          <div className="flex flex-wrap items-center gap-4 py-5">
            {/* Logo */}
            <Link
              to={Path.operator}
              className="block logo flex-shrink-0 cursor-pointer relative"
            >
              <img src={pageLogo} alt="" className="h-[50px] lg:h-[60px]" />
            </Link>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </div>
          <BorderLinearProgress
            variant="determinate"
            value={100 * (step / 3)}
            valueBuffer={0.1}
          />
        </div>
      </header>

      <MainContent alignCenter>
        <div className="max-w-[1080px] mx-auto">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={withdrawUser}
          >
            {(props) => {
              const { setFieldValue, values, handleSubmit } = props;
              return (
                <Form className="w-full">
                  {step === 1 && (
                    <Step1
                      setFieldValue={setFieldValue}
                      values={values}
                      setStep={setStep}
                    />
                  )}
                  {step === 2 && (
                    <Step2 setStep={setStep} handleSubmit={handleSubmit} />
                  )}
                  {step === 3 && (
                    <Step3
                      withdrawMessage={withdrawMessage}
                      success={success}
                    />
                  )}
                </Form>
              );
            }}
          </Formik>
        </div>
      </MainContent>
    </LayoutDefault>
  );
}

export default Withdraw;
