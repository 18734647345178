export default {
  influencer_info: "網紅資訊",
  number_apply: "招募案件數量",
  number_approved: "交付批准數量",
  approval_history: "批准歷史",
  self_introduction: "自我介紹（專業領域介紹）",
  nickname: "顯示名稱",
  send_offer: "委託案件",
  self_pr: "針對招募案件的自我推薦",
  url_pr: "針對招募案件的自我推薦網址",
  file_pr: "針對招募案件的自我推薦檔案",
};
