import { Button, FormControl, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";

const languages = [
  {
    code: "en",
    title: "English",
  },
  {
    code: "zh",
    title: "中文",
  },
  {
    code: "ja",
    title: "日本語",
  },
  {
    code: "ko",
    title: "한국어",
  },
];

function Timezone({ inputComponent }) {
  const [showTimezone, setShowTimezone] = useState(false);
  const [timezone, setTimezone] = useState("ja");

  return (
    <div className="w-full h-auto text-[#484848]">
      <div className="w-full h-auto py-6 border-b border-border">
        <div className="w-full flex items-start justify-between mb-3">
          <div className="flex-1 flex flex-col text-base">
            <div className="w-full font-semibold">標準時間帯</div>
          </div>

          <button
            type="button"
            onClick={() => setShowTimezone(!showTimezone)}
            className="text-[#008489] font-semibold hover:underline cursor-pointer"
          >
            {showTimezone ? "キャンセル" : "更新する"}
          </button>
        </div>

        {!showTimezone ? (
          <div className="w-full font-normal mt-2 ">日本語</div>
        ) : (
          <div className="w-full h-auto flex flex-col">
            <FormControl>
              <Select
                value={timezone}
                onChange={(e) => setTimezone(e.target.value)}
                input={inputComponent}
              >
                {languages.map((item) => {
                  return (
                    <MenuItem key={item.code} value={item.code}>
                      {item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <div className="w-full mt-4">
              <Button
                variant="contained"
                className="!px-6 !py-2.5 !rounded-md !bg-[#008489] !text-base !font-extrabold !text-white"
              >
                保存
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Timezone;
