import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Popover from "@mui/material/Popover";
import ictlPath from "app/ictl/route/Path";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useTranslation } from "react-i18next";
import LanguageIcon from "@mui/icons-material/Language";
import ChatService from "app/services/chat/api/ChatService";
import { EResponseStatus } from "app/constant";
import { setProjectBatchForV } from "app/redux/projectBatch";
import HeaderDrawer from "./HeaderDrawer";
import ImageItem from "../../components/ImageItem";
import { LayoutContext, logout } from "../../utils";

const pageLogo = require("app/assets/images/buzz-logo.png");

function Header({ pin }) {
  const avatar = useSelector((state) => state.profile?.avatar_url);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // const [anchorEl, setAnchorEl] = useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
  const { isChangeLangOpen, setIsChangeLangOpen } = useContext(LayoutContext);
  const dispatch = useDispatch();
  const { unreadForV, pingForV } = useSelector((state) => state?.projectBatch);

  const handleDrawerOpen = () => {
    if (window.innerWidth >= 1024) {
      setIsDrawerOpen(false);
    } else {
      setSubMenuAnchorEl(null);
      // setAnchorEl(null);
    }
  };

  const lstNaviagtion = [
    {
      id: 1,
      name: t("ictl_header.home"),
      path: ictlPath.home,
    },
    {
      id: 2,
      name: t("ictl_header.business_person"),
      path: ictlPath.business_person,
    },
    {
      id: 4,
      name: t("ictl_header.influencer"),
      path: ictlPath.influencer_list,
    },
    {
      id: 11,
      name: t("operator_header.jobs"),
      path: ictlPath.ad_project,
    },
    {
      id: 5,
      name: t("ictl_header.operation"),
      path: ictlPath.management_information,
    },
    {
      id: 6,
      name: t("ictl_header.claim"),
      path: ictlPath.pay_list,
    },
    {
      id: 7,
      name: t("ictl_header.payment"),
      path: ictlPath.payment,
    },
    {
      id: 8,
      name: t("ictl_header.notification"),
      path: ictlPath.notification,
    },
    {
      id: 9,
      name: t("ictl_header.banner"),
      path: ictlPath.banner,
    },
    {
      id: 10,
      name: t("ictl_header.account"),
      path: ictlPath.account,
    },
  ];
  useEffect(() => {
    window.addEventListener("resize", handleDrawerOpen);
    return () => window.removeEventListener("resize", handleDrawerOpen);
  }, []);

  useEffect(() => {
    ChatService.countUnreadAndMarkActionForV().then((response) => {
      if (response.status === EResponseStatus.SUCCESS) {
        const { ping_count: pingCount, unread_count: unreadCount } =
          response.data;
        dispatch(
          setProjectBatchForV({
            pingCount,
            unreadCount,
          })
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDrawerState = (state) => {
    setIsDrawerOpen(state);
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        // setAnchorEl(null);
        setSubMenuAnchorEl(null);
      }}
    >
      <header
        style={{ boxShadow: "rgba(0, 0, 0, 0.08) 0 1px 0" }}
        className="top-0 left-0 right-0 z-[1030] bg-white sticky"
      >
        <div className="px-4">
          <div className="flex items-center justify-between h-20">
            <div
              role="presentation"
              onClick={() => navigate(ictlPath.home)}
              className="logo flex-shrink-0 cursor-pointer relative"
            >
              <img src={pageLogo} alt="" className="h-[50px] lg:h-[60px]" />
            </div>

            <HeaderDrawer
              isDrawerOpen={isDrawerOpen}
              setDrawerState={setDrawerState}
            />

            <div className="hidden lg:flex items-center text-sm mx-3">
              {lstNaviagtion.map((item) => {
                return (
                  <NavLink
                    key={item.id}
                    to={item.path}
                    className="relative group truncate hover:bg-secondary hover:rounded-3xl -mb-2 px-4 py-2 mx-2 cursor-pointer"
                  >
                    {item.path === ictlPath.ad_project ? (
                      <div className="flex items-center">
                        <span className="mr-[2px]">{item.name}</span>
                        <div className="flex flex-col gap-[2px]">
                          {unreadForV > 0 && (
                            <div className="text-[10px] bg-[#8F0B6A] text-white min-w-[20px] min-h-[20px] px-1 flex items-center justify-center rounded-full self-start">
                              {unreadForV}
                            </div>
                          )}
                          {pingForV > 0 && (
                            <div className="text-[10px] bg-[#FFC000] text-white min-w-[20px] min-h-[20px] px-1 flex items-center justify-center rounded-full self-start">
                              {pingForV}
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <span>{item.name}</span>
                    )}
                    {pin === item.id ? (
                      <div className="group-hover:hidden absolute left-1/2 -translate-x-1/2 bg-black w-7 h-0.5 bottom-0" />
                    ) : null}
                  </NavLink>
                );
              })}
            </div>

            <div className="flex items-center">
              <button
                type="button"
                onClick={() => setIsChangeLangOpen(!isChangeLangOpen)}
                className="hover:bg-secondary flex items-center justify-center h-10 w-10 mr-3 rounded-full cursor-pointer transition-all duration-200 ease-linear"
              >
                <LanguageIcon fontSize="small" />
              </button>

              <div className="hidden lg:flex items-center">
                <div className="hover:bg-secondary w-10 h-10 rounded-full border border-solid border-secondary-300 flex items-center justify-center mr-4 cursor-pointer">
                  <i className="fa-regular fa-bell" />
                </div>
                <button
                  type="button"
                  onClick={(event) =>
                    setSubMenuAnchorEl(
                      subMenuAnchorEl ? null : event.currentTarget
                    )
                  }
                  className="w-12 h-12 rounded-full border border-solid border-secondary-300 flex items-center justify-center p-0.5 cursor-pointer"
                >
                  <ImageItem
                    avatar="true"
                    containerclassname="!rounded-full"
                    role="presentation"
                    imagesrc={avatar}
                    loadavatar="true"
                    alt=""
                    imgclassname="w-full h-full object-cover rounded-full overflow-hidden"
                  />
                  {/* Sub-menu */}
                  <Popover
                    id={subMenuAnchorEl ? "menu-popover" : undefined}
                    open={!!subMenuAnchorEl}
                    anchorEl={subMenuAnchorEl}
                    className="z-[1032]"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    PaperProps={{
                      classes: {
                        root: "sub-menu z-10 w-56 text-left font-bold !rounded-3xl py-4 bg-white shadow-2xl",
                      },
                    }}
                  >
                    <Link
                      to={ictlPath.profile}
                      className="py-2 px-4 hover:bg-secondary"
                    >
                      {t("common_header.profile")}
                    </Link>
                    <Link
                      to={ictlPath.account_settings}
                      className="py-2 px-4 hover:bg-secondary"
                    >
                      {t("common_header.account_setting")}
                    </Link>
                    <button
                      type="button"
                      onClick={(e) => {
                        navigate("/");
                        logout(e);
                      }}
                      className="py-2 px-4 hover:bg-secondary w-full text-left"
                    >
                      {t("common_header.log_out")}
                    </button>
                  </Popover>
                </button>
              </div>

              <button
                type="button"
                onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                className="lg:hidden hover:bg-secondary w-12 h-12 rounded-full border border-solid border-secondary-300 flex items-center justify-center cursor-pointer"
              >
                <i
                  className={`fa-solid ${
                    isDrawerOpen ? "fa-xmark" : "fa-bars"
                  }`}
                />
              </button>
            </div>
          </div>
        </div>
      </header>
    </ClickAwayListener>
  );
}

export default Header;
